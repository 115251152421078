import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A, StyledH1 as H1} from './ui';
import {PinkLight} from 'styles/color';
import pdf from '../../../pdfs/proginova/ProblemyReproduktsii_2015_04_048.pdf'

export const ProginovaSecondLinkOne = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>
        The effects of E2 supplementation from the early proliferative phase to the late secretory phase of the endometrium in hMG-stimulated IVF-ET
      </H1>
      <P><b>Purpose:</b> Our purpose was to determine if pregnancy rates (PRs) for hMG (human menopausal gonadotropin)-stimulated IVF-ET (in vitro fertilization--embryo transfer) can be increased by estradiol (E2) supplementation from the early proliferative phase to the late secretory phase of the endometrium.</P>
      <P><b>Results:</b> In 85 cycles, at least one embryo was transferred. Compared with the control group (n = 27 cycles), the E2 supplementation group (n = 58 cycles) had a significantly higher PR (control, 25.9%, versus E2 supplementation, 48.3%) and IR per ET (control, 10%, versus E2 supplementation, 26%), but FRs per retrieved oocytes were not statistically different between the two groups (control, 74%, versus E2 supplementation group, 73%). Four spontaneous abortions occurred in the E2 supplementation group, and one case in the control group. Ectopic pregnancy occurred in one case in the control group.</P>
      <P><b>Method:</b> Eighty-one infertile women with pure tubal factor were randomized into two groups. One group received no E2 supplementation (control group) and the other received oral E2 supplementation (2 mg two times daily) from the early proliferative phase starting on the third day of the menstrual cycle to the late secretory phase of the endometrium, with hMG stimulation for ovulation induction starting on the sixth day of the menstrual cycle.</P>
      <P><b>Conclusions:</b> Clinical PRs and IRs in the E2 supplementation group were significantly higher than in the control group, while FRs in the control group did not differ statistically from the E2 supplementation group. This suggests that E2 supplementation from the early proliferative phase to the late secretory phase of the endometrium in hMG-stimulated IVF-ET increases the receptivity of the endometrium for transferred embryos and clinical PRs.</P>
      <P sx={{mb: 6}}>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/10754780/">Jung H, Roh HK. The effects of E2 supplementation from the early proliferative phase to the late secretory phase of the endometrium in hMG-stimulated IVF-ET. J Assist Reprod Genet. 2000 Jan;17(1):28-33.</A>]
      </P>

      <H1 sx={{color: PinkLight}}>Meta-analysis of estradiol for luteal phase support in in vitro fertilization/intracytoplasmic sperm injection</H1>
      <P><b>Objective:</b> To evaluate whether the addition of E(2) for luteal phase support (LPS) in IVF/intracytoplasmic sperm injection (ICSI) could improve the outcome of clinical pregnancy.</P>
      <P><b>Design:</b> Meta-analysis.</P>
      <P><b>Setting:</b> University hospital center.</P>
      <P><b>Patient(s):</b> Women underwent IVF or ICSI using the GnRH agonist or GnRH antagonist protocol.</P>
      <P><b>Intervention(s):</b> Progesterone alone or combined with E(2) for LPS.</P>
      <P><b>Main outcome measure(s):</b> Clinical pregnancy rate per patient (CPR/PA), clinical pregnancy rate per ET, implantation rate, ongoing pregnancy rate per patient, clinical abortion rate, and ectopic pregnancy rate.</P>
      <P><b>Result(s):</b> Fifteen relevant randomized controlled trials (RCTs) were identified that included a total of 2,406 patients. There was no statistical difference between E(2) + P group and P-only group regarding the primary outcome of CPR/PA for different routes of administration of E(2) (oral, vaginal, and transdermal) or other relevant outcome measures. No significant effect was observed for different daily doses of E(2) (6, 4, and 2 mg), even through oral medication in CPR/PA.</P>
      <P><b>Conclusion(s):</b> The best available evidence suggests that E(2) addition during the luteal phase does not improve IVF/ICSI outcomes through oral medication, even with different daily doses. Furthermore, RCTs that study other administration routes are needed.</P>
      <P sx={{mb: 6}}>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/25492682/">Huang N, et al. Meta-analysis of estradiol for luteal phase support in in vitro fertilization/intracytoplasmic sperm injection. Fertil Steril.2015 Feb;103(2):367-373.e5.</A>]
      </P>

      <H1 sx={{color: PinkLight}}>Meta-analysis of estradiol for luteal phase support in in vitro fertilization/intracytoplasmic sperm injection</H1>
      <P><b>Objective:</b> To evaluate whether the addition of E(2) for luteal phase support (LPS) in IVF/intracytoplasmic sperm injection (ICSI) could improve the outcome of clinical pregnancy.</P>
      <P><b>Design:</b> Meta-analysis.</P>
      <P><b>Setting:</b> University hospital center.</P>
      <P><b>Patient(s):</b> Women underwent IVF or ICSI using the GnRH agonist or GnRH antagonist protocol.</P>
      <P><b>Intervention(s):</b> Progesterone alone or combined with E(2) for LPS.</P>
      <P><b>Main outcome measure(s):</b> Clinical pregnancy rate per patient (CPR/PA), clinical pregnancy rate per ET, implantation rate, ongoing pregnancy rate per patient, clinical abortion rate, and ectopic pregnancy rate.</P>
      <P><b>Result(s):</b> Fifteen relevant randomized controlled trials (RCTs) were identified that included a total of 2,406 patients. There was no statistical difference between E(2) + P group and P-only group regarding the primary outcome of CPR/PA for different routes of administration of E(2) (oral, vaginal, and transdermal) or other relevant outcome measures. No significant effect was observed for different daily doses of E(2) (6, 4, and 2 mg), even through oral medication in CPR/PA.</P>
      <P><b>Conclusion(s):</b> The best available evidence suggests that E(2) addition during the luteal phase does not improve IVF/ICSI outcomes through oral medication, even with different daily doses. Furthermore, RCTs that study other administration routes are needed.</P>
      <P sx={{mb: 6}}>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/25492682/">Huang N, et al. Meta-analysis of estradiol for luteal phase support in in vitro fertilization/intracytoplasmic sperm injection. Fertil Steril.2015 Feb;103(2):367-373.e5.</A>]
      </P>

      <H1 sx={{color: PinkLight}}>Hormonal replacement treatment improves clinical pregnancy in frozen-thawed embryos transfer cycles: a retrospective cohort study</H1>
      <P>
        This study aimed to assess frozen-thawed embryo transfer (FET) outcomes in natural, hormone replacement treatment (HRT) and semi-HRT cycles. This was a retrospective cohort study of 5414 cycles of patients in an academic hospital. Patients were grouped as 2216 natural cycles, 1180 semi-HRT cycles, and 2018 HRT cycles. Primary outcome measures were implantation rate, clinical pregnancy rate and live birth rate. Other parameters, such as peak endometria-thickness, were also analyzed. Patients undergoing FET with HRT obtained higher implantation rate and clinical pregnancy rate than patients with natural or semi-HR cycles (29.3% vs. 21.5% vs. 25.6%, P=0.01, and 48.7% vs. 42.7% vs. 36.1%, P=0.01, respectively). This finding was not changed in patients with thin endometrium (≤8 mm). A Subanalysis in patients with HRT showed that the implantation and clinical pregnancy rate was higher in patients without ovulation than ovulatory patients (29.8% vs. 16.9%, P{'<'}0.01, and 49.5% vs. 26.3%, P{'<'}0.01, respectively). This study suggests that HRT increases the possibility of pregnancy. Further, our data showed that ovulation in HRT cycle has a detrimental effect on pregnancy. Therefore, we recommend that HRT should be used in FET cycles, and ovulation of patients should be evaluated during the treatment.
      </P>
      <P sx={{mb: 6}}>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/24349625/">Zheng Y, Li Z, Xiong M, Luo T, Dong X, Huang B, Zhang H, Ai J. Hormonal replacement treatment improves clinical pregnancy in frozen-thawed embryos transfer cycles: a retrospective cohort study. Am J Transl Res. 2013 Dec 1;6(1):85-90.</A>]
      </P>

      <H1 sx={{color: PinkLight}}>Obstetric outcome in donor oocyte pregnancies: a matched-pair analysis</H1>
      <P><b>Background:</b> To investigate the obstetrical and perinatal impact of oocyte donation, a cohort of women who conceived after OD was compared with a matched control group of women who became pregnant through in vitro fertilisation with autologous oocytes (AO).</P>
      <P><b>Methods:</b> A matched-pair analysis has been performed at the Centre for Reproductive Medicine of the UZ Brussel, Dutch speaking Free University of Brussel. A total of 410 pregnancies resulted in birth beyond 20 weeks of gestation occurring over a period of 10 years, including 205 oocyte donation pregnancies and 205 ICSI pregnancies with autologous oocytes (AO). Patients in the OD group were matched on a one-to-one basis with the AO group in terms of age, ethnicity, parity and plurality. Matched groups were compared using paired t-tests for continuous variables and McNemar test for categorical variables. A conditional logistic regression analyses was performed adjusting for paternal age, age of the oocyte donor, number of embryos transferred, and singleton/twin pregnancy.</P>
      <P><b>Results:</b> Oocyte donation was associated with an increased risk of pregnancy induced hypertension (PIH) (matched OR: 1.502 CI: 1.024-2.204), and first trimester bleeding (matched OR: 1.493 CI: 1.036-2.15). No differences were observed between the two matched groups with regard to gestational age, mean birth weight and length, head circumference and Apgar scores.
      </P>
      <P><b>Conclusions:</b> Oocyte donation is associated with an increased risk for PIH and first trimester bleeding independent of the recipients' age, parity and plurality, and independent of the age of the donor or the partner. However, oocyte donation has no impact on the overall perinatal outcome.</P>
      <P sx={{mb: 6}}>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/22672289/">Stoop D, et al. Obstetric outcomes in donor oocyte pregnancies: a matched-pair analysis. Reprod Biol Endocrinol. 2012;6;10:42.</A>]
      </P>

      <H1 sx={{color: PinkLight}}>Применение эстрогенов в программах ВРТ. Научно-практические рекомендации РАРЧ. 2015 г.</H1>
      <P>Научно-практические рекомендации посвящены использованию эстрогенов в практике ВРТ.
        В них рассмотрены вопросы, связанные с особенностями применения эстрогенов в качестве монотерапии и в сочетании с гестагенами на этапе стимуляции суперовуляции, в программах донорства
        ооцитов, в циклах переноса криоконсервированных/размороженных эмбрионов, для поддержки
        имплантации и ранних сроков беременности. </P>

      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Статья PDF
      </a>
    </Page>
  )
}
