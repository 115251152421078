import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const VelledienLinkTen = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Tibolone: a selective tissue estrogenic activity regulator (STEAR)</H1>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/15337241/"> Reed MJ, Kloosterboer HJ 2004 Tibolone: a selective tissue estrogenic activityregulator (STEAR). Maturitas 48(Suppl 1):S4 –S6.</A>]
      </P>
    </Page>
  )
}
