import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import clsx from 'clsx';

import Slider from 'shared/ui/Slider';
import PageWrap from 'shared/ui/Page';
import Loader from 'shared/ui/Loader';

import {getFile} from 'helpers/instruments/fileSaver';
import Header from './Header';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './style.scss';

import arr from './arr.svg';
import {pdfType} from 'components/Product';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

export default () => {
  const {id, type, name} = useParams<{
    id: string;
    type: pdfType;
    name: string;
    forceVertical: string;
  }>();

  const [file, setFile] = useState<null | string | any>(null);
  const [numPages, setNumPages] = useState(0);
  const [pagesRendered, setPagesRendered] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<Swiper | null>(null);
  const [activePage, setActivePage] = useState(1);
  const [copied, setCopied] = useState(false);

  const [showInstruments, setShowInstruments] = useState(false);

  useEffect(() => {
    getFile(id)
      .then((dec: string) => {
        setFile(dec);
      })
      .catch(() => {
        setFile(`${window.location.origin}/prezentation/${id}.pdf`);
      });
  }, []);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  useEffect(() => {
    if (swiperInstance) {
      // @ts-ignore
      swiperInstance.on('slideChange', function () {
        // @ts-ignore
        setActivePage(this.activeIndex + 1);
      });
    }
  }, [swiperInstance]);

  const toggleHeader = () => {
    setShowInstruments(!showInstruments);
  };

  const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
    setNumPages(numPages);
  };

  const onRenderSuccess = () => {
    setPagesRendered(pagesRendered + 1);
  };

  const setError = () => {
    setFile(null);
  };

  const onSwiper = (swiper: Swiper) => {
    setSwiperInstance(swiper);
  };

  const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages);

  return (
    <PageWrap fullScreen noMargin>
      <Header
        show={showInstruments}
        name={name}
        type={type}
        onCopy={() => {
          setCopied(true);
          navigator.clipboard.writeText(window.location.href);
        }}
      />
      {copied && <div className="copied">Скопировано</div>}
      <div onClick={toggleHeader}>
        {numPages === 0 && <Loader/>}
        {file !== null && (
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            onLoadError={setError}
            onSourceError={setError}
            loading={''}
            className={'react-pdf__document'}
          >
            <Slider cssMode={false} onSwiper={onSwiper}>
              {Array.from(new Array(pagesRenderedPlusOne), (el, index) => {
                return (
                  <SwiperSlide
                    virtualIndex={index}
                    key={`page_${index + 1}`}
                  >
                    <Page
                      width={window.innerWidth}
                      key={`page_${index + 1}`}
                      onRenderSuccess={onRenderSuccess}
                      pageNumber={index + 1}
                      className={'horizontalPDF'}
                    />
                  </SwiperSlide>
                );
              })}
            </Slider>
          </Document>
        )}
        <div className={clsx('counter', showInstruments && 'showCounter')}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '3px 5px',
            }}
          >
            <img
              style={{
                marginRight: 10,
                transform: 'rotate(180deg)',
              }}
              src={arr}
              alt=""
            />
            {activePage} / {numPages}
            <img
              style={{
                marginLeft: 10,
              }}
              src={arr}
              alt=""
            />
          </div>
        </div>
      </div>
    </PageWrap>
  );
};
