import main from './main.png'
import warn from './warn.png'
import chartThree from './chart4.png'
import plus from './plus.png'
import approveRed from './approve-red.png'
import mainBottom from './main-bottom.png'
import quotation from './quotation.png'
import warnRed from './warn-red.png'
import approve from './approve.png'
import cross from './cross.png'
import ellips from './ellips.png'
import ellipsSmall from './ellips-small.png'
import minus from './minus.png'
import quotationLarge from './quotation-large.png'
import plusSmall from './plus-small.png'
import approveMin from './approve-min.png'
import main2 from './main2.png'
import hublot from './hublot.png'
import girl from './depressed-girl.png'
import body from './body.png'
import plusRed from './plus-red.png'
import control from './control.png'
import redMark from './redMark.png'

export default {
  main,
  warn,
  chartFour: chartThree,
  plus,
  approveRed,
  mainBottom,
  quotation,
  warnRed,
  approve,
  cross,
  ellips,
  minus,
  quotationLarge,
  plusSmall,
  ellipsSmall,
  approveMin,
  main2,
  hublot,
  girl,
  body,
  plusRed,
  control,
  redMark,
}