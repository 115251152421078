import {styled} from '@mui/system';
import {Box} from '@mui/material';
import {Black, PinkLight} from '../../styles/color';

export const StyledBoxShadow = styled(Box)({
  width: '100%',
  fontFamily:  'SF Pro Text',
  color: '#FFFFFF',
  backgroundColor: Black,
  boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(249, 210, 214, 0.2)',
  borderRadius: 12,
  padding: 20,
  boxSizing: 'border-box',
})

export const StyledBoxInsetShadow = styled(Box)({
  width: '100%',
  fontFamily:  'SF Pro Text',
  color: '#FFFFFF',
  backgroundColor: Black,
  boxShadow: 'inset -2px -3px 3px rgba(249, 210, 214, 0.2), inset 2px 3px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: 12,
  padding: 20,
  boxSizing: 'border-box',
})

export const StyledBoxBorder = styled(Box)`
  border: 1px solid ${PinkLight};
  box-sizing: border-box;
  padding: 20px;
  border-radius: 12px;
  
  & .rubric {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }
`;

export const StyledHeader = styled('h1')`
  font-size: 36px;
  line-height: 43px;
  color: ${PinkLight};
`

export const StyledCircle = styled('div')`
  width: 40px;
  min-width: 40px;
  height: 40px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(249, 210, 214, 0.2);
  background-color: ${Black};
  border-radius: 50%;
  box-sizing: border-box;
  
  font-size: 28px;
  line-height: 34px;
  color: ${PinkLight};
  text-align: center;
  
  &.border {
    border: 2px solid ${PinkLight};
    
    &--white {
      border-color: #FFFFFF;
    }
  }
`;

export const StyledInsetCircle = styled('div')`
  width: 40px;
  min-width: 40px;
  height: 40px;
  box-shadow: inset -2px -3px 3px rgba(249, 210, 214, 0.2), inset 2px 3px 4px rgba(0, 0, 0, 0.25);
  background-color: ${Black};
  border-radius: 50%;
  box-sizing: border-box;
  
  font-size: 28px;
  line-height: 34px;
  color: ${PinkLight};
  text-align: center;
  
  &.border {
    border: 2px solid ${PinkLight};
    
    &--white {
      border-color: #FFFFFF;
    }
  }
`;