import {LinkNavigate, Paragraph, Sup} from 'shared/ui';

import group from './img/group.png';
import ItemPlus from './ItemPlus';
import {Black} from '../../styles/color';

export default () => {
  return (
    <div className="box" style={{
      marginTop: 20
    }}>
      <h3
        style={{
          fontSize: 36,
          fontWeight: 600,
          color: '#F9D2D6',
          marginTop: 12
        }}
      >
        Номегэстрол — высокоселективный прогестаген, являющийся производным естественного стероидного гормона
        прогестерона
      </h3>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '20px',
          marginTop: 40,
        }}
      >
        <div
          style={{
            background: '#F9D2D6',
            border: '1px solid #F7E5E0',
            boxSizing: 'border-box',
            padding: '20px',
            borderRadius: 12,
            minHeight: 270,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img width={'100%'} src={group} alt=""/>
        </div>
        <div
          style={{
            background: '#fff',
            border: '1px solid #F9D2D6',
            boxSizing: 'border-box',
            padding: '20px',
            borderRadius: 12,
            minHeight: 270,
          }}
        >
          <div
            style={{
              marginTop: 20,
            }}
          >
            <ItemPlus
              text={
                <LinkNavigate to="">
                  <Paragraph sx={{fontSize: 16, color: Black}}>
                    Применение селективных прогестагенов способствует улучшению переносимости и профиля
                    сердечно-сосудистого риска<Sup>1</Sup>.
                  </Paragraph>
                </LinkNavigate>
              }
            />
          </div>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <ItemPlus
              text={
                <LinkNavigate to="">
                  <Paragraph sx={{fontSize: 16, color: Black}}>
                    Прогестероноподобные производные способствуют сохранению благоприятного влияния эстрогена на
                    липидный обмен – в отличие от прогестагенов с андрогенными свойствами, которые нейтрализуют
                    положительные эффекты эстрогенов<Sup>1</Sup>.
                  </Paragraph>
                </LinkNavigate>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
