import {Box} from '@mui/material';

import {StyledHeader} from '../ui';
import {StyledBoxShadow} from '../../ui';
import {Message} from './message';

export const SubpageAlvovizan = () => {
  return (
    <>
      <StyledHeader>Алвовизан vs Визанны, Зафриллы и ДляЖенс метри</StyledHeader>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день! Медицинский представитель компании Зентива (имя, фамилия). Уделите мне
            несколько минут, пожалуйста, для обсуждения пациенток с эндометриозом.
          </Message>
          <Message isQuestion={false}>
            Заходите, только недолго.
          </Message>

          <Message>
            Спасибо. Ольга Ивановна, скажите пожалуйста, как часто к Вам обращаются пациентки с эндометриозом?
          </Message>
          <Message isQuestion={false}>
            Достаточно часто, 3 пациентки в неделю точно есть!
          </Message>

          <Message>
            Что Вы обычно назначаете таким пациенткам?
          </Message>
          <Message isQuestion={false}>
            Чаще всего Визанна, так как оригинальный ЛП, Зафриллу тоже люблю. Ну и сейчас раз идет активное
            импортозамещение, то и ДляЖенсМетри — «отечественный» препарат.
          </Message>

          <Message>
            Да, соглашусь, что первая линия по лечению Эндометриоза — Диеногест. А чем руководствуетесь кому какой
            Диеногест назначить?
          </Message>
          <Message isQuestion={false}>
            Ну, Визанна — хороший препарат, ЭФФЕКТИВНЫЙ, Оригинальный, но по цене дороговато обходится пациенткам,
            поэтому могу порекомендовать — Зафриллу, доступный Дженерик. Так как терапия длительная и для мня важно,
            чтобы пациентка принимала эффективный и доступный препарат. На худой конец могу и «отечественный
            порекомендовать».
          </Message>

          <Message>
            Правильно ли я Вас поняла, что для Вас главное эффективность ЛП, чтобы он работал и как можно меньше
            побочных реакций и доступность по цене. Так как на приеме не каждый пациент может сказать о своих финансовых
            трудностях.
          </Message>
          <Message isQuestion={false}>
            Да, тут я с Вами согласна. Поэтому могу порекомендовать и Зафриллу.
          </Message>

          <Message>
            В современных экономических условиях очень важно, чтобы пациентки не отказывались от лечения эндометриоза
            из-за высокой стоимости ЛП, НО ПРИ ЭТОМ получали терапию максимального качества. Это есть только у
            Алвовизана. Был у ВАС опыт с таким препаратом?
          </Message>
          <Message isQuestion={false}>
            Нет, я забыла о нем. Хотя опыт ранее был. А ЧЕМ ОН ЛУЧШЕ Визанны?
          </Message>

          <Message>
            Алвовизан при сопоставимой с оригинальным препаратом биоэквивалентностью по цене на 30% выгоднее. Алвовизан
            — это первый дженерик Визанны. Производство Германия. Поэтому, рекомендуя Алвовизан своим пациенткам с
            эндометриозом, Вы гарантируете эффективное и безопасное лечение для своих пациентов.
          </Message>
          <Message isQuestion={false}>
            Зафрилла ходит, так же говорят про себя. Что доступны, чем оригинал.
          </Message>

          <Message>
            Только у Алвовизана есть в показаниях возраст с 12 лет как и у оригинального ЛС. Это говорит о безопасности
            Алвовизана, которая подтверждена клиническими исследованиями. Когда Зафрилла только с 18 лет. И об этом они
            молчат. Алвовизан даже с дженериком экономически выгоднее для пациента. Разница в курсовом лечении в пользу
            Алвовизана более 2 000 ₽
          </Message>
          <Message isQuestion={false}>
            (перебивает)
            <Box sx={{mt: '12px', mb: '12px'}}>
              Отечественный ДляЖенсМетри еще дешевле.
            </Box>
          </Message>

          <Message>
            От отечественного тут к сожалению ничего нет, только площадка от производителя, когда субстанция 100% —
            Китай. Нет ни одного подтвержденного исследования о биоэквивалентности с оригинальным ЛС.
          </Message>
          <Message isQuestion={false}>
            Да Вы что?! Я Даже не знала!
          </Message>

          <Message>
            Ольга Ивановна! Вы обеспечиваете эффективную терапию своим пациенткам. Алвовизан является эффективным
            препаратом для долговременной терапии эндометриоза, эффективно купируя боль, а при длительном лечении —
            интенсивность менструальных кровотечений. А при Европейском качестве обладает самой социально-ответственной
            ценой!
          </Message>
          <Message isQuestion={false}>
            Да… Это тоже очень важно! Спасибо за такую полную информацию!
          </Message>

          <Message>
            Ольга Ивановна, я буду рада, если Алвовизан вновь займет место в Вашем сердце! И как мы говорили ранее, как
            раз 3 пациентки с эндометриозом в неделю получат самую достойную терапию от Вас!
          </Message>
          <Message isQuestion={false}>
            Ну, давайте попробую назначить трем новым пациенткам в неделю и тем, кому не подходит Зафрилла ! Китайский
            вообще страшно назначать!
          </Message>

          <Message>
            Спасибо, Ольга Ивановна. На следующем визите более подробно обсудим терапию у новых пациентках.
          </Message>
          <Message isQuestion={false}>
            Хорошо.
          </Message>
        </Box>
      </StyledBoxShadow>
    </>
  )
}
