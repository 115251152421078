import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanSecondLinkTen = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>Consensus on current management of endometriosis</H1>
      <P><b>Study question:</b> Is there a global consensus on the management of endometriosis that considers the views of
        women with endometriosis?
      </P>
      <P><b>Summary answer:</b> It was possible to produce an international consensus statement on the current management of
        endometriosis through engagement of representatives of national and international, medical and non-medical
        societies with an interest in endometriosis.
      </P>
      <P><b>What is known already:</b> Management of endometriosis anywhere in the world has been based partially on
        evidence-based practices and partially on unsubstantiated therapies and approaches. Several guidelines have been
        developed by a number of national and international bodies, yet areas of controversy and uncertainty remain, not
        least due to a paucity of firm evidence.
      </P>
      <P><b>Study design, size, duration:</b> A consensus meeting, in conjunction with a pre- and post-meeting process, was
        undertaken.
      </P>
      <P>Participants/materials, setting, methods: A consensus meeting was held on 8 September 2011, in conjunction with
        the 11th World Congress on Endometriosis in Montpellier, France. A rigorous pre- and post-meeting process,
        involving 56 representatives of 34 national and international, medical and non-medical organizations from a
        range of disciplines, led to this consensus statement.
      </P>
      <P><b>Main results and the role of chance:</b> A total of 69 consensus statements were developed. Seven statements had
        unanimous consensus; however, none of the statements were made without expression of a caveat about the strength
        of the statement or the statement itself. Only two statements failed to achieve majority consensus. The
        statements covered global considerations, the role of endometriosis organizations, support groups, centres or
        networks of expertise, the impact of endometriosis throughout a woman's life course, and a full range of
        treatment options for pain, infertility and other symptoms related to endometriosis.
      </P>
      <P>Limitations, reasons for caution: This consensus process differed from that of formal guideline development. A
        different group of international experts from those participating in this process would likely have yielded
        subtly different consensus statements.
      </P>
      <P><b>Wider implications of the findings:</b> This is the first time that a large, global, consortium, representing 34
        major stake-holding organizations from five continents, has convened to systematically evaluate the best
        available current evidence on the management of endometriosis, and to reach consensus. In addition to 18
        international medical organizations, representatives from 16 national endometriosis organizations were involved,
        including lay support groups, thus generating input from women who suffer from endometriosis.
      </P>
      <P>
        [ссылка: <A
        href="https://pubmed.ncbi.nlm.nih.gov/23528916/">Park,
        Johnson N. et al. Consensus on current management of endometriosis // Hum. Reprod. 2013. Vol. 28 (6). P.
        1552–1568.</A>]
      </P>
    </Page>
  )
}
