import {styled} from '@mui/system';

export const H2 = styled('h2')({
  fontSize: 28,
  fontFamily: 'SF-Pro-Text-Bold',
  color: '#E482AE',
  marginBottom: 10,

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const H1 = styled('h1')({
  fontSize: 36,
  fontFamily: 'SF-Pro-Text-Bold',
  lineHeight: '43px',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const Paragraph = styled('p')({
  fontSize: 20,
  fontFamily: 'SF-Pro-Text-Regular',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {}
})
export const Div = styled('div')({})

export const Span = styled('span')({
  wordBreak: 'normal'
})

export const Sup = styled('sup')({
  color: '#0076BD',
  fontWeight: 'bold',
  fontSize: '0.688em',
  left: '2px',
  verticalAlign: 'top',
  position: 'relative',
  top: '-0.2em',
})