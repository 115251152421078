import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const VelledienLinkEight = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Beneficial effect of tibolone on mood, cognition, well-being, and sexuality in menopausal women</H1>
      <P>Tibolone is a synthetic molecule used extensively for the management of menopausal symptoms, with the proposed additional advantage of enhanced mood and libido. Tibolone, after oral administration, is rapidly converted into 3 major metabolites: 3alpha-hydroxytibolone and 3beta-hydroxytibolone, which have estrogenic effects, and the Delta(4)-isomer, which has progestogenic and androgenic effects. The tissue-selective effects of tibolone are the result of metabolism, of enzyme regulation, and of receptor activation which vary in different tissues. Tibolone seems to be effective on estrogen-withdrawal symptoms such as hot flushes, sweating, insomnia, headache, and vaginal dryness, with results generally comparable to the effects exerted by estrogen-based treatments, and the additional property of a progestogenic activity on the endometrium. As well as relieving vasomotor symptoms, tibolone has positive effects on sexual well-being and mood, and improves dyspareunia and libido. These effects may depend on both estrogenic and androgenic actions exerted at the genital level and in the central nervous system, and on a reduction of sex-hormone-binding globulin and an increase of free testosterone, without affecting Delta-5 androgens levels. Based on the evidence available, tibolone is a valuable treatment option to relieve menopausal complaints, especially in women suffering persistent fatigue, blunted motivation, and loss of sexual desire despite an adequate estrogen replacement.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/19412477/">Genazzani AR, et al. Beneficial effect of tibolone on mood, cognition, well-being, and sexuality in menopausal women. Neuropsychiatr Dis Treat. 2006 Sep;2(3):299-307.</A>]
      </P>
    </Page>
  )
}
