import approveLarge from './approve-large.png';
import chart from './chart.png';
import chart2 from './chart2.png';
import flasks from './flasks.png';
import girl1 from './girl1.png';
import girl2 from './girl2.png';
import girl3 from './girl3.png';
import girl4 from './girl4.png';
import girl5 from './girl5.png';
import girl6 from './girl6.png';
import main from './main.png';
import plus from './plus.png';
import plusLarge from './plus-large.png';
import plusMedium from './plus-medium.png';
import pregnancy from './pregnancy.png';
import proginova from './proginova.png';

export default {
  main,
  approveLarge,
  chart,
  chart2,
  flasks,
  girl1,
  girl2,
  girl3,
  girl4,
  girl5,
  girl6,
  plus,
  plusLarge,
  plusMedium,
  pregnancy,
  proginova,
}