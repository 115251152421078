import {styled} from '@mui/system';
import {PinkLight} from '../../styles/color';
import vector from './img/vector.png';

export const Tape = styled('div')({
  padding: '12px 20px 12px 62px',
  color: PinkLight,
  fontSize: 20,
  backgroundColor: 'rgba(249, 210, 214, 0.1)',
  fontFamily: 'SF-Pro-Text-Semibold',

  '&::before': {
    position: 'absolute',
    content: `url(${vector})`,
    marginLeft: -42,
    marginTop: 3,
  }
})

export const Button = styled('button')({
  padding: '8px 20px',
  borderRadius: 6,
  color: '#FFFFFF',
  fontSize: 16,
  textAlign: 'center',
  minWidth: 160,
  border: `1px solid ${PinkLight}`,
  backgroundColor: 'rgba(0,0,0,0)',
  cursor: 'pointer',
})