import {Box} from '@mui/material';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Black, PinkLight} from '../../../../styles/color';
import Page from 'shared/ui/Page';
import {
  Wrapper,
  StyledCardHeader
} from './ui';
import {
  StyledBtn,
  StyledTab,
  StyledCardBorderLine,
  StyledCircle60,
  StyledCircle160,
  StyledBoxShadow,
  StyledCircle,
  Sup
} from '../ui';
import {ListItem, List, Tables} from 'shared/ui';
import scene1 from './img/scene1.png';
import icon1 from './img/icon1.png';
import icon2 from './img/icon2.png';
import iconMessage from './img/iconMessage.png';
import iconEdit from './img/iconEdit.png';
import w1 from './img/w1.png';
import w2 from './img/w2.png';
import w3 from './img/w3.png';
import w4 from './img/w4.png';
import w5 from './img/w5.png';
import w6 from './img/w6.png';
import iconPersonWomen from './img/iconPersonWomen.png';
import iconPlus from './img/iconPlus.png';
import chart1 from './img/chart1.png';
import chart2 from './img/chart2.png';
import scene2 from './img/scene2.png';
import plusPink from './img/plusPink.png';
import iconCheck from './img/iconCheck.png';
import iconCheckBlack from './img/iconCheckBlack.png';
import iconMessage40 from './img/iconMessage40.png';
import chart3 from './img/chart3.png';
import chart6 from './img/chart6.png';
import chart7 from './img/chart7.png';
import chart8 from './img/chart8.png';
import {ReactComponent as ArrowIcon} from '../img/arrow.svg';
import {ProginovaListModal} from '../../../../components/proginova';

export const EffectiveSalesVisitsAprilJulyProginova = () => {
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleRedirectToBack = () => {
    history.push('/effective-sales/to-doctor');
  }

  return (
    <>
      <Page
        head={'ВИЗИТЫ: АПРЕЛЬ-ИЮЛЬ'}
        backText="Назад"
        onClose={handleRedirectToBack}
      >
        <Box sx={{display: 'flex', gap: '20px', mb: '50px'}}>
          <StyledTab className="active" to="/effective-sales/visits-april-july-proginova">Прогинова</StyledTab>
          <StyledTab to="/effective-sales/visits-april-july-alvovizan">Алвовизан</StyledTab>
          <StyledTab to="/effective-sales/visits-april-july-dydrogesterone">Исследование: дидрогестерон</StyledTab>
        </Box>
        <Wrapper sx={{display: 'flex', gap: '40px', flexDirection: 'column'}}>
          <Box sx={{display: 'flex', gap: '20px'}}>
            <StyledBoxShadow sx={{maxWidth: 335, overflow: 'hidden', padding: 0}}>
              <img src={scene1} style={{height: '100%'}}/>
            </StyledBoxShadow>
            <StyledBoxShadow>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{mb: 2}}>
                  <img src={iconMessage} style={{width: '25px', height: '24px'}}/>
                </StyledCircle>
                <Box className="pink">
                  — На сегодняшний день существует только один моноэстроген с доказанной эффективностью в ВРТ — это
                  Прогинова. Мы, представители компании Zentiva, разделяем классические подходы в работе с гормональными
                  препаратами, где основанием для назначения являются только результаты проведенных исследований и
                  доказанные эффекты препарата у конкретных групп пациенток. Все мировые и российские эксперты разделяют
                  именно этот подход. Таким образом, считаю своим долгом проинформировать Вас, доктор, о том, что все
                  исследования в репродуктологии проводились только на таблетированной форме эстрадиола валерата, а это
                  препарат Прогинова.
                </Box>
              </Box>

              <Box sx={{display: 'flex', gap: 2, mt: 5}}>
                <StyledCircle sx={{mb: 2}}>
                  <img src={icon2} style={{width: '25px', height: '24px'}}/>
                </StyledCircle>
                <Box className="white">
                  <Box sx={{fontSize: 20, lineHeight: '24px', mb: 1, fontWeight: 500}}>СОСТАВ:</Box>
                  21 драже белого цвета: 2 мг эстрадиола валерата
                </Box>
              </Box>
            </StyledBoxShadow>
          </Box>

          <StyledBoxShadow sx={{display: 'flex', gap: '20px'}}>
            <StyledCircle>
              <img src={iconMessage}/>
            </StyledCircle>
            <Box className="pink">
              — Согласно клиническим исследованиям, эстрадиола валерат (Прогинова) повышает вероятность наступления
              беременности в программах ЭКО. Доктор, согласитесь, что трансдермальные формы не всегда работают, они не
              выключают овуляцию, пиноподий образуется мало. А за этим стоит результат программ ЭКО!
            </Box>
          </StyledBoxShadow>


          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <Box className="header pink">ПОРТРЕТЫ ПАЦИЕНТОК</Box>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '20px', paddingLeft: 1}}>
              <Box className="card">
                <StyledCircle160 className="border">
                  <StyledCircle className="border badge">1</StyledCircle>
                  <img src={w1}/>
                </StyledCircle160>
                <Box className="pink" sx={{fontSize: 20, lineHeight: '24px'}}>
                  Пациентки на стимуляции овуляции, когда необходима поддержка лютеиновой фазы
                </Box>
              </Box>
              <Box className="card">
                <StyledCircle160 className="border">
                  <StyledCircle className="border badge">2</StyledCircle>
                  <img src={w2}/>
                </StyledCircle160>
                <Box className="pink" sx={{fontSize: 20, lineHeight: '24px'}}>
                  Пациентки с кровотечениями в цикле ЭКО
                </Box>
              </Box>
              <Box className="card">
                <StyledCircle160 className="border">
                  <StyledCircle className="border badge">3</StyledCircle>
                  <img src={w3}/>
                </StyledCircle160>
                <Box className="pink" sx={{fontSize: 20, lineHeight: '24px'}}>
                  Пациентки, у которых наступила беременность после переноса размороженных эмбрионов
                </Box>
              </Box>
              <Box className="card">
                <StyledCircle160 className="border">
                  <StyledCircle className="border badge">4</StyledCircle>
                  <img src={w4}/>
                </StyledCircle160>
                <Box className="pink" sx={{fontSize: 20, lineHeight: '24px'}}>
                  Пациентки с тонким эндометрием
                </Box>
              </Box>
              <Box className="card">
                <StyledCircle160 className="border">
                  <StyledCircle className="border badge">5</StyledCircle>
                  <img src={w5}/>
                </StyledCircle160>
                <Box className="pink" sx={{fontSize: 20, lineHeight: '24px'}}>
                  Пациентки, которым Вы проводите эстрогенный прайминг
                </Box>
              </Box>
              <Box className="card">
                <StyledCircle160 className="border">
                  <StyledCircle className="border badge">6</StyledCircle>
                  <img src={w6}/>
                </StyledCircle160>
                <Box className="pink" sx={{fontSize: 20, lineHeight: '24px'}}>
                  Пациентки в программе «Донорство ооцитов»
                </Box>
              </Box>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <StyledCardHeader>
              <StyledCircle60 className="head-circle">
                <img src={iconPersonWomen}/>
              </StyledCircle60>
              <Box>
                Пациентки на стимуляции овуляции, когда необходима поддержка лютеиновой фазы
              </Box>
            </StyledCardHeader>

            <Box className="pink" sx={{mt: 5, fontSize: '20px'}}>
              Эстрадиола валерат повышает вероятность наступления беременности в программе ЭКО
            </Box>
            <Box sx={{mt: 2}}>
              Мета-анализ 15 РКИ. Исходы 2406 циклов ЭКО. Применение эстрадиола для поддержки лютеиновой фазы
            </Box>

            <Box sx={{mt: 2, display: 'flex', gap: '20px'}}>
              <StyledCardBorderLine sx={{maxWidth: 330}}>
                <StyledCircle sx={{backgroundColor: PinkLight, mb: 2}}>
                  <img src={iconPlus} width={42} height={42}/>
                </StyledCircle>
                <Box sx={{fontSize: 20, lineHeight: '24px', fonWeight: 600}}>
                  Относительная вероятность наступления беременности в группах женщин, принимавших эстрадиол +
                  прогестерон, по сравнению с женщинами, принимавшими только прогестерон, составила 1,18 (95% CI
                  0,98—1,41; p=0,07) для перорального приема
                </Box>
              </StyledCardBorderLine>
              <StyledCardBorderLine sx={{padding: 0}}>
                <img src={chart1}/>
              </StyledCardBorderLine>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <StyledCardHeader>
              <StyledCircle60 className="head-circle">
                <img src={iconPersonWomen}/>
              </StyledCircle60>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                Пациентки с кровотечениями в цикле ЭКО
              </Box>
            </StyledCardHeader>

            <Box sx={{display: 'flex', gap: '20px', mt: 5}}>
              <Box>
                <Box className="header pink" sx={{mb: 3}}>Прогинова уменьшает риск кровотечения в цикле ЭКО</Box>
                <StyledCardBorderLine>
                  <StyledCircle sx={{backgroundColor: PinkLight, mb: 2}}>
                    <img src={iconPlus} width={42} height={42}/>
                  </StyledCircle>
                  <Box sx={{fontSize: 20, lineHeight: '24px'}}>
                    Прием 4 мг Прогинова® совместно с прогестероном* в лютеиновую фазу стимулированного цикла:
                    <List>
                      <ListItem>
                        статистически значимо повышает частоту имплантации (p=0,035)
                      </ListItem>
                      <ListItem>
                        снижает частоту кровяных выделений (p=0,01)
                      </ListItem>
                    </List>
                    <Box className="pink" sx={{fontSize: 16, lineHeight: '19px'}}>
                      *Со дня забора ооцита до 11 дня после переноса эмбрионов в протоколе с антагонитами ГнРГ.
                    </Box>
                  </Box>
                </StyledCardBorderLine>
              </Box>
              <StyledBoxShadow sx={{padding: 0, maxWidth: 372}}>
                <img src={scene2} style={{height: '100%', maxWidth: '100%'}}/>
              </StyledBoxShadow>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <StyledCardHeader>
              <StyledCircle60 className="head-circle">
                <img src={iconPersonWomen}/>
              </StyledCircle60>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                Пациентки, у которых наступила беременность после переноса размороженных эмбрионов
              </Box>
            </StyledCardHeader>
            <Box className="pink" sx={{fontSize: 20, mt: '40px', mb: '20px'}}>
              Прогинова® существенно повышает вероятность наступления беременности после переноса размороженных
              эмбрионов
            </Box>
            <Box sx={{fontSize: 20, fontWeight: '600', mb: '20px'}}>
              Исходы 5114 циклов переноса размороженных эмбрионов
            </Box>
            <Box sx={{fontSize: 20}}>Ретроспективное когортное исследование</Box>
            <Tables.WrapperTable sx={{mt: 3}}>
              <Tables.Table className="align-left" sx={{fontSize: 18, minWidth: 900, color: PinkLight}}>
                <Tables.Thead>
                  <Tables.Tr sx={{fontWeight: '600'}}>
                    <Tables.Td sx={{width: '400px'}}></Tables.Td>
                    <Tables.Td sx={{width: '180px'}}>ЕЦ</Tables.Td>
                    <Tables.Td sx={{width: '180px'}}>полу-ЕЦ</Tables.Td>
                    <Tables.Td>ЗГТ* (Прогинова)</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td>Кол-во циклов</Tables.Td>
                    <Tables.Td>2216</Tables.Td>
                    <Tables.Td>1180</Tables.Td>
                    <Tables.Td>2018</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Частота имплантации (%)</Tables.Td>
                    <Tables.Td>21,5</Tables.Td>
                    <Tables.Td>25,6</Tables.Td>
                    <Tables.Td>29,3 (p=0,01)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Частота наступления беременности (%)</Tables.Td>
                    <Tables.Td>36,1</Tables.Td>
                    <Tables.Td>42,7</Tables.Td>
                    <Tables.Td>48,7 (p=0,01)</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>

            <Box sx={{fontSize: 16, mt: '20px', mb: 1}}>
              ЕЦ — естественный цикл.
            </Box>
            <Box sx={{fontSize: 16}}>
              *Прием эстрогенов начинали с 1–4 дня цикла. Доза Прогиновы 2 мг/сутки, 4 мг — 5–8 день, 6 мг — 9–12 день
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <StyledCardHeader>
              <StyledCircle60 className="head-circle">
                <img src={iconPersonWomen}/>
              </StyledCircle60>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                Пациентки с тонким эндометрием
              </Box>
            </StyledCardHeader>

            <Box className="pink" sx={{mt: 5, fontSize: '20px'}}>
              Назначение эстрогенов пациентам с тонким эндометрием
            </Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', alignItems: 'center', mt: '20px'}}>
              <StyledCircle>
                <img src={icon1}/>
              </StyledCircle>
              <Box sx={{fontSize: 20, lineHeight: '24px', fonWeight: 600}}>
                Актуальной и до конца не решенной проблемой является недостаточная толщина эндометрия, с которой
                связывают
                неудачи имплантации и невынашивание беременности после ЭКО. Большинство исследователей сходятся во
                мнении,
                что частота наступления беременности существенно снижается при толщине эндометрия менее 7 мм в день
                переноса
                эмбрионов.
              </Box>
            </StyledCardBorderLine>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', alignItems: 'center', mt: '20px'}}>
              <StyledCircle>
                <img src={iconMessage}/>
              </StyledCircle>
              <Box sx={{fontSize: 20, lineHeight: '24px', fonWeight: 600}}>
                Учитывая прямую связь толщины эндометрия с концентрацией эстрадиола у большинства пациентов, провели
                проспективное рандомизированное исследование дополнительного назначения 4 мг эстрадиола перорально,
                начиная с 3-го
                дня цикла, на исход 85 программ ЭКО и обнаружили статистически значимое повышение частоты наступления
                беременности и частоты имплантации в группе женщин, принимавших эстрадиол, по сравнению с группой не
                принимавших
                эстрадиол женщин.
              </Box>
            </StyledCardBorderLine>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', alignItems: 'center', mt: '20px'}}>
              <StyledCircle sx={{backgroundColor: PinkLight}}>
                <img src={iconCheckBlack} width={42} height={42}/>
              </StyledCircle>
              <Box sx={{fontSize: 20, lineHeight: '24px', fonWeight: 600}}>
                При назначении Прогиновы клинически доказано увеличение толщины эндометрия, что, в свою очередь, повысит
                частоту наступления беременности. А именно это является конечной целью любого протокола. Прогинова уже
                на протяжении более 25 лет входит в клинические протоколы всех без исключения клиник ЭКО.
              </Box>
            </StyledCardBorderLine>


            <Box className="pink" sx={{mt: 5, fontSize: '20px'}}>
              Нужно ли дополнительно назначать эстрогены пациенткам с тонким эндометрием?
            </Box>
            <Box sx={{mt: 2, display: 'flex', alignItems: 'center'}}>
              Сравнение исходов беременностей в 85 циклах ЭКО
            </Box>

            <Tables.WrapperTable sx={{mt: 3}}>
              <Tables.Table className="align-default" sx={{fontSize: 18, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr sx={{fontWeight: 'bold', color: PinkLight}}>
                    <Tables.Td></Tables.Td>
                    <Tables.Td sx={{width: '150px'}}>P</Tables.Td>
                    <Tables.Td sx={{width: '150px'}}>P + EV</Tables.Td>
                    <Tables.Td sx={{width: '150px'}}>p</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td>Толщина эндометрия в день введения ЧХГ(мм)</Tables.Td>
                    <Tables.Td>10,74±2,64</Tables.Td>
                    <Tables.Td>12,03±2,81</Tables.Td>
                    <Tables.Td>{`<`}0,05</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Частота имплантаций</Tables.Td>
                    <Tables.Td>10%</Tables.Td>
                    <Tables.Td>26%</Tables.Td>
                    <Tables.Td> {`<`}0,05</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Частота наступления беременности</Tables.Td>
                    <Tables.Td>25,9%</Tables.Td>
                    <Tables.Td>48,3%</Tables.Td>
                    <Tables.Td>{`<`}0,05</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', alignItems: 'center', mt: '20px'}}>
              <StyledCircle sx={{backgroundColor: PinkLight}}>
                <img src={iconPlus} width={42} height={42}/>
              </StyledCircle>
              <Box sx={{fontSize: 20, lineHeight: '24px', fonWeight: 600}}>
                Эстрадиола валерат достоверно увеличивает толщину и чувствительность эндометрия к переносу эмбрионов,
                что увеличивает частоту наступления беременности<Sup>6</Sup>.
              </Box>
            </StyledCardBorderLine>
          </StyledBoxShadow>

          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <StyledCardHeader>
              <StyledCircle60 className="head-circle">
                <img src={iconPersonWomen}/>
              </StyledCircle60>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                Пациентки, которым вы проводите эстрогенный прайминг
              </Box>
            </StyledCardHeader>

            <Box className="pink" sx={{mt: 5, fontSize: '20px'}}>
              Эстрогенный прайминг у пациенток со слабым ответом яичников на стимуляцию снижает риск отмены цикла ЭКО и
              приводит к повышению частоты наступления беременности
            </Box>
            <Box sx={{mt: 2, mb: 2}}>
              Ретроспективное исследование 155 циклов ЭКО у пациенток с предыдущим недостаточным ответом на стимуляцию
            </Box>

            <Box>
              <img src={chart2} style={{maxWidth: '100%'}}/>
            </Box>

            <Box className="pink" sx={{fontSize: 16, mb: '10px', mt: 2}}>
              *Прогинова® назначалась в дозе 4 мг, начиная с 21-го дня предыдущего цикла стимуляции и до 3-го дня цикла
              стимуляции (28 циклов) либо до дня введения чХГ.
            </Box>
          </StyledBoxShadow>


          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <StyledCardHeader>
              <StyledCircle60 className="head-circle">
                <img src={iconPersonWomen}/>
              </StyledCircle60>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                Пациентки в программе «Донорство ооцитов»
              </Box>
            </StyledCardHeader>

            <Box className="header pink" sx={{mt: 3}}>Донорство ооцитов и эмбрионов</Box>

            <Box sx={{display: 'flex', gap: '20px', mt: 3}}>
              <StyledCardBorderLine sx={{display: 'flex', gap: 1}}>
                <StyledCircle sx={{mb: 2}}>
                  <img src={plusPink} width={42} height={42}/>
                </StyledCircle>
                <Box sx={{fontSize: 18, lineHeight: '21px', color: PinkLight}}>
                  В 2012 г. Изучили исходы 205 беременностей после донации ооцитов и 205 беременностей после ИКСИ с
                  аутологичными ооцитами.
                </Box>
              </StyledCardBorderLine>
              <StyledCardBorderLine sx={{display: 'flex', gap: 1}}>
                <StyledCircle sx={{mb: 2}}>
                  <img src={plusPink} width={42} height={42}/>
                </StyledCircle>
                <Box sx={{fontSize: 18, lineHeight: '21px', color: PinkLight}}>
                  Не выявлено различий между группами в отношении срока родов, массы плода, длины тела, окружности
                  головы и состояния новорожденных по шкале Апгар.
                </Box>
              </StyledCardBorderLine>
            </Box>

            <Box sx={{display: 'flex', gap: '20px', mt: 3}}>
              <StyledCardBorderLine sx={{display: 'flex', gap: 1}}>
                <StyledCircle sx={{mb: 2}}>
                  <img src={iconCheck} width={42} height={42}/>
                </StyledCircle>
                <Box sx={{fontSize: 18, lineHeight: '21px', color: PinkLight}}>
                  До и после переноса эмбрионов, полученных из донорских ооцитов, требуется ЗГТ препаратами эстрогенов.
                </Box>
              </StyledCardBorderLine>
              <StyledCardBorderLine sx={{display: 'flex', gap: 1}}>
                <StyledCircle sx={{mb: 2}}>
                  <img src={iconCheck} width={42} height={42}/>
                </StyledCircle>
                <Box sx={{fontSize: 18, lineHeight: '21px', color: PinkLight}}>
                  Без адекватной ЗГТ прегравидарная трансформация эндометрия и имплантация эмбрионов невозможны.
                </Box>
              </StyledCardBorderLine>
              <StyledCardBorderLine sx={{display: 'flex', gap: 1}}>
                <StyledCircle sx={{mb: 2}}>
                  <img src={iconCheck} width={42} height={42}/>
                </StyledCircle>
                <Box sx={{fontSize: 18, lineHeight: '21px', color: PinkLight}}>
                  Необходимость и эффективность применения эстрогенов при беременности, наступившей в программе
                  донорства ооцитов, не вызывает сомнения.
                </Box>
              </StyledCardBorderLine>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <Box className="pink" sx={{textTransform: 'uppercase', fontSize: 36, mb: 6}}>Возможные возражения</Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px'}}>
              <StyledCircle60>
                <img src={iconMessage40} width={37} height={37}/>
              </StyledCircle60>
              <Box>
                <Box className="pink" sx={{mb: 2}}>Д: Трансдермальные формы менее безопасны в отношении риска ВТЭ</Box>
                <Box sx={{fontWeight: 600}}>
                  М.П: Доктор, согласна с вами, это действительно важно для особых групп пациенток, но хочу вам
                  продемонстрировать клиническое исследование-Сравнение влияния различных факторов на риск венозной
                  тромбоэмболии (ВТЭ), которое показывает, что риск при применении пероральной и трансдермальных форм Е2
                  был сопоставим.
                </Box>
              </Box>
            </StyledCardBorderLine>

            <Box className="header pink" sx={{mt: 4, mb: 3}}>
              Сравнение влияния различных факторов на риск венозной тромбоэмболии (ВТЭ)
            </Box>

            <Box sx={{display: 'flex', gap: '20px'}}>
              <img src={chart3}/>
              <Box>
                <StyledCardBorderLine>
                  <Box className="pink" sx={{fontSize: 20, fontWeight: 600}}>
                    Риск ВТЭ пероральной и трансдермальной форм был сопоставим
                  </Box>
                </StyledCardBorderLine>
              </Box>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <StyledCardBorderLine sx={{display: 'flex', gap: '20px'}}>
              <StyledCircle60>
                <img src={iconMessage40} width={37} height={37}/>
              </StyledCircle60>
              <Box>
                <Box className="pink" sx={{mb: 2}}>Д: Биодоступность трансдермальных форм выше, чем
                  таблетированной.</Box>
                <Box sx={{fontWeight: 600}}>
                  М.П: Доктор, соглашусь с Вами действительно биодоступность пероральной формы составляет 3-5%, а
                  биодоступность трансдермальных формы 80%. Тем не менее, по данным клинического исследования при
                  сравнении пероральной и трансдермальной форм биодоступность Е2 Прогинова 2 мг, выше, чем при нанесении
                  1.5 мг Е2 трансдермального геля.
                </Box>
              </Box>
            </StyledCardBorderLine>

            <Box className="header pink" sx={{mt: 4}}>
              Согласно инструкциям по применению препаратов Прогинова® и Эстражель®/Дивигель®:
            </Box>

            <List>
              <ListItem sx={{mb: 1}}>
                биодоступность пероральной формы (ПФ) Е2 составляет 3-5%
              </ListItem>
              <ListItem>
                биодоступность трансдермальной формы (ТФ) Е2 – 80%
              </ListItem>
            </List>

            <Box sx={{fontWeight: 600}}>
              Тем не менее, по данным клинического исследования при сравнении пероральной и трансдермальной форм Е2 [1]:
            </Box>

            <List>
              <ListItem sx={{mb: 1}}>
                при однократном приёме биодоступность Е2 при 2 мг Прогинова® выше, чем при нанесении 1,5 мг препарата
                Дивигель®
              </ListItem>
              <ListItem>
                при приёме в течение 2 недель биодоступность Е2 при 2 мг Прогинова® выше, чем при нанесении 1,5 мг
                препарата Дивигель®
              </ListItem>
            </List>

            <Box sx={{display: 'flex', gap: '20px'}}>
              <StyledCardBorderLine>
                <Box className="header pink" sx={{mb: '20px'}}>
                  <span className="white">Однократный прием</span> 2 мг эстрадиола валерат* и 1,5 мг трансдермального
                  эстрадиола**
                </Box>
                <img src={chart6} style={{maxWidth: 428}}/>
              </StyledCardBorderLine>
              <StyledCardBorderLine>
                <Box className="header pink" sx={{mb: '20px'}}>
                  Прием 2 мг эстрадиола валерат* и 1,5 мг трансдермального эстрадиола** <span className="white">в течение 2 недель</span>
                </Box>
                <img src={chart7} style={{maxWidth: 428}}/>
              </StyledCardBorderLine>
            </Box>

            <Box sx={{fontSize: 12, fontWeight: 400, lineHeight: '11px', mt: '30px'}}>
              * 2 mg oestradiolvalerate tablet (corresponds to 1.53 mg oestradiol,Progynova® 2 mg tablet, Leiras, Turku,
              Finland,licensed from Schering<br/><br/>
              ** 3 sachets of transdermal gel (Divigel® 0.1% gel in single dose sachets of 0.5 g, Orion Pharmaceutica,
              Espoo, Finland/OrganonOss, the Netherlands)<br/><br/>
              <ol style={{margin: 0, padding: 0, paddingLeft: '15px'}}>
                <li style={{lineHeight: '14px'}}>
                  Järvinen A, Nykänen S, Paasiniemi L. Absorption and bioavailability of oestradiol from a gel, a patch
                  and a tablet. Maturitas. 1999 Jun 21;32(2):103-13. doi: 10.1016/s0378-5122(99)00021-3. PMID: 10465378.
                </li>
              </ol>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <StyledCardBorderLine sx={{display: 'flex', gap: '20px'}}>
              <StyledCircle60>
                <img src={iconMessage40} width={37} height={37}/>
              </StyledCircle60>
              <Box sx={{fontWeight: 600, fonWeight: 600}}>
                М.П: Кроме того, доктор хочу вам продемонстрировать еще два новых исследования. Первое — зависимость
                фармакокинетического профиля пероральной и трансдермальной форм Е2 от дозы. Оно показывает что,
                пероральная форма Е2 независимо от дозы имеет стабильный фармакокинетический профиль
              </Box>
            </StyledCardBorderLine>

            <Box className="header pink" sx={{mt: 4}}>
              Зависимость фармакокинетического профиля пероральной и трансдермальной форм Е2 от дозы
            </Box>

            <StyledCardBorderLine sx={{mt: 4}}>
              <Box className="header pink" sx={{mb: '20px'}}>
                <span className="white">Однократный прием</span> 2 мг эстрадиола валерат* и 1,5 мг трансдермального
                эстрадиола**
              </Box>
              <img src={chart8} style={{maxWidth: '100%'}}/>

              <List sx={{mb: 0}}>
                <ListItem sx={{mb: 1}}>
                  Пероральная форма Е2 независимо от дозы* имеет стабильный фармакокинетический профиль
                </ListItem>
                <ListItem>
                  Высокие дозы* трансдермальной формы Е2 способствуют развитию резкого «скачка» Е2
                </ListItem>
              </List>
            </StyledCardBorderLine>

            <Box sx={{fontSize: 12, fontWeight: 400, lineHeight: '11px', mt: '30px'}}>
              * Дозы: НДПО – 0,5 мг, ВДПО – 2 мг, НДТД - 0.0375 мг, ВДТД - 0.075 м<br/><br/>
              Taboada M, Santen R, Lima J, Hossain J, Singh R, Klein KO, Mauras N. Pharmacokinetics and pharmacodynamics
              of oral and transdermal 17β estradiol in girls with Turner syndrome. J Clin Endocrinol Metab. 2011
              Nov;96(11):3502-10. doi: 10.1210/jc.2011-1449. Epub 2011 Aug 31. PMID: 21880799; PMCID: PMC3205885.
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow sx={{padding: '40px 20px'}}>
            <StyledCardBorderLine sx={{display: 'flex', gap: '20px'}}>
              <StyledCircle60>
                <img src={iconMessage40} width={37} height={37}/>
              </StyledCircle60>
              <Box>
                <Box sx={{mb: 2, fontWeight: 600}}>
                  М.П: И еще одно исследование — Снижение риска рака молочной железы при применении перорального Е2 за
                  счет выработки ГСПГ печенью.
                </Box>
                <Box sx={{fontWeight: 600}}>
                  Таким образом, доктор, единственным правильным решением будет вести всех Ваших пациенток, которые
                  нуждаются в эстрогеновой поддержке в программах ЭКО, на препарате Прогинова. Только это эффективно и
                  безопасно. И доказано временем.
                </Box>
              </Box>
            </StyledCardBorderLine>

            <Box className="header pink" sx={{mt: 5}}>
              Снижение риска рака молочной железы при применении перорального E2 за счет выработки ГСПГ*
            </Box>

            <List>
              <ListItem sx={{fontWeight: 500, mb: '12px'}}>
                В отличие от трансдермального Е2, пероральный Е2 приводит к индукции синтеза ГСПГ печенью.
              </ListItem>
              <ListItem sx={{mb: '12px'}}>
                ГСПГ способствует подавлению роста и пролифиреции клеток рака молочной железы, индуцировнного
                эстрогенами
              </ListItem>
              <ListItem sx={{mb: '12px'}}>
                ГСПГ связан со сниженным риском развития новообразования рака молочной железы
              </ListItem>
              <ListItem sx={{mb: '12px'}}>
                Показана обратная связь между концентрациями ГСПГ и риском развития рака молочной железы
              </ListItem>
              <ListItem sx={{mb: '12px'}}>
                Связывание эстрадиола с ГСПГ приводит к апоптозу клеток рака молочной железы и подавлению его роста
              </ListItem>
              <ListItem sx={{mb: '12px'}}>
                ГСПГ играет защитную роль в воздействии эстрогена на клетки молочной железы
              </ListItem>
            </List>

            <Box sx={{fontSize: 12, lineHeight: '14px'}}>
              *ГСПГ — гормон, связывающий половые гормоны. Контролирует количество половых гормонов, доступных
              организму. Помогает поддерживать в равновесии процессы, связанные с половыми гормонами
            </Box>

            <Box className="header pink" sx={{mt: 5, mb: 2}}>
              Сравнительная характеристика препаратов перорального и трансдермального эстрадиола
            </Box>

            <Tables.WrapperTable sx={{mt: 3}}>
              <Tables.Table className="align-left" sx={{fontSize: 16, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr sx={{fontWeight: 'bold', color: Black, backgroundColor: PinkLight, fontSize: 20}}>
                    <Tables.Td  sx={{width: '250px'}}>Влияние на факторы риска ССЗ*</Tables.Td>
                    <Tables.Td sx={{width: '370px'}}>Пероральный эстрадиол</Tables.Td>
                    <Tables.Td sx={{width: '370px'}}>Трансдермальный эстрадиол</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td rowSpan={2}>Влияние на липидный обмен и риск ССЗ*</Tables.Td>
                    <Tables.Td>
                      Снижает уровень «вредных» липопротеинов ЛПНП<Sup sx={{color: '#FFF'}}>1,8</Sup> Не отмечено
                      статистически значимого
                      снижения концентраций ЛПНП<Sup sx={{color: '#FFF'}}>4-6</Sup>
                    </Tables.Td>
                    <Tables.Td>
                      Не отмечено статистически значимого снижения концентраций ЛПНП<Sup sx={{color: '#FFF'}}>4-6</Sup>
                    </Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>
                      Увеличивает концентрацию «полезных» липопротеинов ЛПВП<Sup sx={{color: '#FFF'}}>1,8</Sup>
                    </Tables.Td>
                    <Tables.Td>
                      Не оказывает значительного влияния на уровни ЛПВП<Sup sx={{color: '#FFF'}}>1</Sup>
                    </Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: '#f9d2d633'}}>
                    <Tables.Td>
                      Влияние на углеводный обмен и риск ССЗ*
                    </Tables.Td>
                    <Tables.Td>
                      <List sx={{margin: 0}}>
                        <ListItem sx={{mb: 1}}>
                          Более выраженное снижение инсулинорезистентности у женщин без диабета<Sup
                          sx={{color: '#FFF'}}>1</Sup>
                        </ListItem>
                        <ListItem>
                          Более выраженное снижение риска диабета<Sup sx={{color: '#FFF'}}>7</Sup>
                        </ListItem>
                      </List>
                    </Tables.Td>
                    <Tables.Td>
                      <List sx={{margin: 0}}>
                        <ListItem sx={{mb: 1}}>
                          Незначительноое снижение инсулинорезистентности у женщин без диабета<Sup
                          sx={{color: '#FFF'}}>1</Sup>
                        </ListItem>
                        <ListItem>
                          Менее выраженное снижение риска диабета<Sup sx={{color: '#FFF'}}>7</Sup>
                        </ListItem>
                      </List>
                    </Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>
                      Влияние на артериальной давление, массу тела и риск ССЗ*
                    </Tables.Td>
                    <Tables.Td>
                      Достоверное увеличение уровня грелина** в плазме (p=0,001)<Sup sx={{color: '#FFF'}}>8</Sup>
                    </Tables.Td>
                    <Tables.Td>
                      Не достоверное увеличение уровня грелина в плазме8<Sup sx={{color: '#FFF'}}>8</Sup>
                    </Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>

            <Box sx={{mt: '20px', fontWeight: 500}}>
              Прием ПЕРОРАЛЬНОГО эстрадиола связан с более выраженным снижением риска ССЗ* за счет более выраженного
              влияния на факторы липидного, углеводного обмена и грелин, способствующий нормализации массы тела,
              расширению сосудов<Sup sx={{color: '#FFF'}}>1,7,8</Sup>
            </Box>
            <Box sx={{fontSize: 12, fontWeight: 400, lineHeight: '11px', mt: '20px'}}>
              *ССЗ- сердечно-сосудистые заболевания, ВТЭ – венозная тромбоэмболия<br/><br/>
              **ГРЕЛИН СВЯЗАН С НОРМАЛИЗАЦИЕЙ ВЕСА, ОКАЗЫВАЕТ ПРЯМОЕ СОСУДОРАСШИРЯЮЩЕЕ ДЕЙСТВИЕ<br/><br/>
              1.Goldštajn MŠ, et al. Arch Gynecol Obstet. 2023 Jun;307(6):1727-1745.<br/><br/>
              2.Varas-Lorenzo C, et al. Circulation. 2000;101:2572–2578.<br/><br/>
              3.Daly E, et al. Lancet. 1996;348:977–980.<br/><br/>
              4.Perrone G, et al. Int J Fertil Menopausal Stud. 1996;41:509–515.<br/><br/>
              5.Wakatsuki A, et al. Circulation. 2002;106:1771–1776.<br/><br/>
              6.Lee J-Y, et al. J Menopausal Med. 2015;21:104–111.<br/><br/>
              7.de Lauzon-Guillain B, et al. Diabetologia. 2009;52:2092–2100.<br/><br/>
              8.Kellokoski E, et al. J Clin Endocrinol Metab. 2005 May;90(5):2954-63<br/><br/>
            </Box>

            <Box className="header pink" sx={{mt: '30px', mb: '20px'}}>
              Сравнение риска рака молочной железы в зависимости от длительности применения эстрогенов
            </Box>

            <Tables.WrapperTable sx={{mt: 3}}>
              <Tables.Table className="align-left" sx={{fontSize: 18, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr sx={{fontWeight: 'bold', color: Black, backgroundColor: PinkLight, fontSize: 20}}>
                    <Tables.Td sx={{width: '250px'}}>Длительность применения</Tables.Td>
                    <Tables.Td>Трансдермальный Е2</Tables.Td>
                    <Tables.Td>Пероральный Е2</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td sx={{fontSize: 16}}>Применение эстрогена менее 2 лет</Tables.Td>
                    <Tables.Td sx={{fontSize: 36}} style={{textAlign: 'center', verticalAlign: 'middle'}}>+</Tables.Td>
                    <Tables.Td sx={{fontSize: 36}} style={{textAlign: 'center', verticalAlign: 'middle'}}>—</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: '#f9d2d633'}}>
                    <Tables.Td sx={{fontSize: 16}}>Применение эстрогена более 2 лет</Tables.Td>
                    <Tables.Td sx={{fontSize: 36}} style={{textAlign: 'center', verticalAlign: 'middle'}}>+</Tables.Td>
                    <Tables.Td sx={{fontSize: 36}} style={{textAlign: 'center', verticalAlign: 'middle'}}>+</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>

            <Box sx={{fontSize: 12, fontWeight: 400, lineHeight: '14px', mt: '20px'}}>
              Fournier A, Berrino F, Riboli E, Avenel V, Clavel-Chapelon F. Breast cancer risk in relation to different
              types of hormone replacement therapy in the E3N-EPIC cohort. Int J Cancer. 2005 Apr 10;114(3):448-54.
            </Box>
          </StyledBoxShadow>
        </Wrapper>

        <StyledBtn onClick={() => setIsOpenModal(true)}>
          ПРЕЗЕНТАЦИЯ ПРЕПАРАТА
          <ArrowIcon/>
        </StyledBtn>
      </Page>

      <ProginovaListModal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)}/>
    </>
  )
}
