import {useState} from 'react';
import {useHistory} from 'react-router-dom'
import cn from 'classnames';

import {Tab, TabsGroup} from 'components/tabs';
import {ProductModal} from 'components/product-modal/product-modal';
import {ProductModalType} from 'components/product-modal/types';
import {WrapperCouple} from 'components/wrapper-couple';
import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import Page from 'shared/ui/Page';
import {Img, List as UL, ListItem as LI, Tables, LinkNavigate} from 'shared/ui';
import {
  Black,
  GrayBlack,
  OpacityRed,
  PinkLight,
  Red,
} from 'styles/color';

import {H2, Paragraph as P, H1, Span, Div, Sup} from './ui';
import images from './img'
import {addLight, approve, downArrow, upperArrow} from '../diecyclen/img';

import {bem} from 'shared/bem';
import './styles.scss';

const b = bem('alvovizan-thirteen');

export const AlvovizanThirteen = () => {
  const history = useHistory();

  const [modal, setModal] = useState<ProductModalType>(null);
  const handleClosePopup = () => {
    setModal(null);
  }

  const handleOpenModal = () => {
    setModal('Материалы');
  }

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  const handleOpenVideo = () => {
    history.push('/video/Видео/QjffUXwlqsQ');
  }

  return (
    <>
      <Page head={'Алвовизан'} backText="Продукты" onClose={handleRedirectToProducts}>
        <TabsGroup>
          <Tab>
            <LinkNavigate to="/product/alvovizan/instruction">Инструкция</LinkNavigate>
          </Tab>
          <Tab onClick={handleOpenModal}>Материалы</Tab>
          <Tab onClick={handleOpenVideo}>Видео</Tab>
        </TabsGroup>

        <Div className="text-light" sx={{display: 'flex', flexDirection: 'column', gap: '20px', mb: '150px'}}>
          <WrapperShell sx={{backgroundImage: `url(${images.main})`, height: 500}}>
            <H1 sx={{color: '#FFF', fontSize: 48, mt: 14, ml: 6, lineHeight: '60px'}}>
              Социально-<br/>
              ответственная<br/>
              терапия <br/>
              <Span sx={{color: Red}}>эндометриоза</Span>
            </H1>
          </WrapperShell>

          <WrapperShellBox>
            <H1 sx={{color: Red}}>
              Ключевые принципы долговременной терапии эндометриоза
            </H1>
          </WrapperShellBox>

          <WrapperShell sx={{
            backgroundImage: `url(${images.main2})`,
            height: 500,
            display: 'flex',
            justifyContent: 'space-between',
            p: 6,
            alignItems: 'center'
          }}>
            <Div sx={{flexDirection: 'column', display: 'flex', gap: '40px'}}>
              <Div
                sx={{backgroundColor: GrayBlack, border: `1px solid ${PinkLight}`, borderRadius: '12px', width: 350}}>
                <LinkNavigate to="/product/alvovizan-thirteen/link1">
                  <H2 sx={{fontSize: 20, mt: 1, mb: 1, p: '20px'}}>Купирование боли<Sup>1</Sup></H2>
                </LinkNavigate>
              </Div>
              <Div
                sx={{backgroundColor: GrayBlack, border: `1px solid ${PinkLight}`, borderRadius: '12px', width: 350}}>
                <LinkNavigate to="/product/alvovizan-thirteen/link2">
                  <H2 sx={{fontSize: 20, mt: 1, mb: 1, p: '20px'}}>Сохранение фертильности<Sup>2</Sup></H2>
                </LinkNavigate>
              </Div>
              <Div
                sx={{backgroundColor: OpacityRed, border: `1px solid ${PinkLight}`, borderRadius: '12px', width: 350}}>
                <H2 sx={{fontSize: 20, mt: 1, mb: 1, p: '20px'}}>Приверженность лечению</H2>
              </Div>
            </Div>
            <Div sx={{flexDirection: 'column', display: 'flex', gap: '40px'}}>
              <Div
                sx={{backgroundColor: GrayBlack, border: `1px solid ${PinkLight}`, borderRadius: '12px', width: 350}}>
                <H2 sx={{fontSize: 20, mt: 1, mb: 1, p: '20px'}}>Профилактика рецидивов</H2>
              </Div>
              <Div
                sx={{backgroundColor: GrayBlack, border: `1px solid ${PinkLight}`, borderRadius: '12px', width: 350}}>
                <H2 sx={{fontSize: 20, mt: 1, mb: 1, p: '20px'}}>Предотвращение потери костной ткани</H2>
              </Div>
              <Div
                sx={{backgroundColor: OpacityRed, border: `1px solid ${PinkLight}`, borderRadius: '12px', width: 350}}>
                <H2 sx={{fontSize: 20, mt: 1, mb: 1, p: '20px'}}>Улучшение качества жизни</H2>
              </Div>
            </Div>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell>
              <H1 sx={{color: Red, mb: 5}}>Правильное консультирование</H1>
              <Div sx={{position: 'relative', pl: 6}}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: -6,
                    top: -3,
                    backgroundImage: `url(${images.ellips})`,
                    width: 50,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <H2 sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>1</H2>
                </Div>
                <P>Проинформировать пациентку о том, что при эндометриозе требуется долгосрочная терапия.</P>
              </Div>

              <Div sx={{position: 'relative', pl: 6}}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: -6,
                    top: -3,
                    backgroundImage: `url(${images.ellips})`,
                    width: 50,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <H2 sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>2</H2>
                </Div>
                <P>Оценить эффективность и переносимость текущей терапии Диеногестом , проинформировать о том, что уже
                  есть успешный опыт применения свыше 15 месяцев.</P>
              </Div>

              <Div sx={{position: 'relative', pl: 6}}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: -6,
                    top: -3,
                    backgroundImage: `url(${images.ellips})`,
                    width: 50,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <H2 sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>3</H2>
                </Div>
                <P>Предупредить о том, что после прекращения приема Диеногеста может быть возврат симптомов.</P>
              </Div>
            </WrapperShell>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.body})`}}/>
          </WrapperCouple>

          <WrapperCouple>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.girl})`}}/>
            <WrapperShell>
              <Img src={images.warnRed}/>
              <H2 sx={{color: PinkLight, mt: 2}}>В настоящее время в России наблюдается постоянный рост цен.</H2>
              <P> Многие пациентки не могут позволить себе терапию оригинальным препаратом.</P>
              <P>Назначая оригинальный препарат доктор в половине случаев не назначает ничего, так как пациентка,
                услышав в аптеке цену, не купит его.</P>
            </WrapperShell>
          </WrapperCouple>

          <WrapperCouple>
            <WrapperShellBox>
              <H2 sx={{color: Red}}>Доктору в арсенале необходим препарат, который обладал бы двумя
                характеристиками:</H2>
              <H2 sx={{position: 'relative', paddingLeft: '60px', color: PinkLight}}>
                <Img sx={{position: 'absolute', left: 0, top: 0}} src={images.plusRed}/>
                Эффективностью оригинального препарата
              </H2>
              <H2 sx={{position: 'relative', paddingLeft: '60px', color: PinkLight, mb:2}}>
                <Img sx={{position: 'absolute', left: 0, top: 0}} src={images.plusRed}/>
                Социально-ответственной ценой
              </H2>
            </WrapperShellBox>
            <WrapperShellBox className="small"
                             sx={{backgroundImage: `url(${images.hublot})`, display: 'flex', alignItems: 'end'}}
            >
              <Img src={images.control}/>
            </WrapperShellBox>
          </WrapperCouple>

          <WrapperShellBox>
            <LinkNavigate to="/product/alvovizan-thirteen/link7">
              <H1 sx={{color: PinkLight}}>
                Основное достоинство дженериков — <br/> относительно низкая стоимость при той же
                терапевтической эффективности, что и у оригинального препарата<Sup>7</Sup>
              </H1>
            </LinkNavigate>
          </WrapperShellBox>

          <WrapperCouple sx={{marginTop: '20px'}}>
            <WrapperShell className="small" sx={{height: 464, minWidth: 412}}>
              <div style={{display: 'flex'}}>
              <span style={{marginTop: 12, marginRight: 12}}>
                <img src={images.redMark}/>
              </span>
                <div className={cn(b('text-large'), b('mx-15'))}>
                  <h2 style={{marginTop: 0}} className={cn(b('text-large'), b('text-pink'), b('mb-10'))}>
                    ДЖЕНЕРИК
                  </h2>
                  <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
                    Препарат, который является терапевтическим эквивалентом бренда.
                  </p>
                  <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
                    Выпускается только после истечения срока действия патента на оригинальный препарат.
                  </p>
                </div>
              </div>
              <div className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
                <div style={{marginTop: 0}} className={cn(b('mb-20'), b('mt-20'))}>
               <span
                 style={{fontSize: 60, fontFamily: 'SF Pro Bold', color: Red}}
               >
                80
              </span>
                  <span style={{color: Red}} className={cn(b('text-large'))}>% рынка</span> <br/>
                </div>
                лекарственных препаратов в нашей стране составляют <strong>дженерики</strong>
              </div>
            </WrapperShell>

            <WrapperShell sx={{boxShadow: 'none', p: 0, borderRadius: 0, textAlign: 'center'}}>
              <WrapperCouple>
                <WrapperShell
                  sx={{
                    boxShadow: 'none',
                    p: 0,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                  }}>
                  <WrapperShellBox sx={{
                    backgroundColor: Red,
                    height: 64,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <P sx={{textTransform: 'uppercase', m: 0}}>Дженерик</P>
                  </WrapperShellBox>
                  <WrapperShellBox
                    sx={{height: '190px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div>
                      <Img sx={{m: '0px 0 8px'}} src={approve}/>
                      <P sx={{m: 0}}>НЕТ ЗАТРАТ НА ДОПОЛНИТЕЛЬНЫЕ ИССЛЕДОВАНИЯ</P>
                    </div>
                  </WrapperShellBox>
                  <WrapperShellBox
                    sx={{height: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <P sx={{m: 0}}>Исследования биоэквивалентности</P>
                  </WrapperShellBox>
                  <WrapperShellBox sx={{backgroundColor: Red, p: 1}}>
                    <P sx={{textTransform: 'uppercase', m: 0, mb: '6px'}}>Стоимость</P>
                    <Img src={downArrow}/>
                  </WrapperShellBox>
                </WrapperShell>

                <WrapperShell
                  sx={{
                    boxShadow: 'none',
                    p: 0,
                    borderRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                  }}>
                  <WrapperShellBox sx={{
                    backgroundColor: PinkLight,
                    p: '0 20px',
                    height: 64,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <P className="text-dark" sx={{textTransform: 'uppercase', m: 0, fontWeight: 'bold'}}>Оригинальный
                      препарат</P>
                  </WrapperShellBox>
                  <WrapperShellBox
                    sx={{height: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <P sx={{m: 0}}>Синтез молекулы</P>
                  </WrapperShellBox>
                  <WrapperShellBox sx={{
                    height: '90px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <Img sx={{position: 'absolute', top: '-23px'}} src={addLight}/>
                    <P sx={{m: 0}}>Разработка лекарственной формы</P>
                  </WrapperShellBox>
                  <WrapperShellBox sx={{
                    height: '90px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <Img sx={{position: 'absolute', top: '-22px'}} src={addLight}/>
                    <P sx={{m: 0}}>Доклинические и клинические исследования</P>
                  </WrapperShellBox>
                  <WrapperShellBox sx={{backgroundColor: PinkLight, p: 1}}>
                    <Img src={upperArrow}/>
                    <P className="text-dark"
                       sx={{textTransform: 'uppercase', m: 0, mb: '6px', fontWeight: 'bold'}}>Стоимость</P>
                  </WrapperShellBox>
                </WrapperShell>
              </WrapperCouple>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShell>
            <H1 sx={{color: Red}}>Алвовизан — немецкий препарат, на 30% дешевле оригинального</H1>
            <H2 sx={{color: PinkLight, mb: 3}}>
              Назначая Алвовизан, доктор гарантирует своим пациенткам эффективное и доступное по цене лечение.
            </H2>

            <Tables.WrapperTable sx={{overflowX: 'auto', mb: 2}}>
              <Tables.Table className="align-default" sx={{fontSize: 18, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr>
                    <Tables.Td sx={{width: 250}}>Действующее вещество</Tables.Td>
                    <Tables.Td colSpan={2}>Диеногест 2 мг</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: PinkLight}}>
                    <Tables.Td sx={{color: Black}}>Препарат</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Red, width: 230}}>Алвовизан №28</Tables.Td>
                    <Tables.Td sx={{color: Black, width: 230}}>Визанна №28</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td>Средняя стоимость<br/> в аптеке, ₽</Tables.Td>
                    <Tables.Td>2 500</Tables.Td>
                    <Tables.Td>3 500</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / месяц, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>1 080</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / год — 12 циклов, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>12 000</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
          </WrapperShell>

          <WrapperShell>
            <H2 sx={{color: PinkLight}}>Исследование сравнительной фармакокинетики и биоэквивалентности препаратов
              Алвовизан и Визанна показало,
              что препараты
            </H2>
            <LinkNavigate to="/product/alvovizan-thirteen/link8">
              <H1 sx={{color: Red, fontSize: 35}}>Алвовизан и Визанна являются биоэквивалентными<Sup>8</Sup></H1>
            </LinkNavigate>
            <Img sx={{mb: 5}} src={images.chartFour}/>

            <WrapperShellBox
              sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative', mb: 2}}>
              <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.approve}/>
              <P sx={{fontSize: 24, fontWeight: 'bold', mt: 1, color: Red, mb: 2}}>ВЫВОДЫ:</P>
              <UL>
                <LI><P>Исследуемые препараты характеризуются высокой степенью сходства показателей фармакокинетики.</P></LI>
                <LI><P>Профили фармакокинетических кривых препаратов Алвовизин и Визанна имеют совпадающие
                  формы.</P></LI>
                <LI><P>Препараты характеризуются близкими значениями относительной биодоступности, максимальной
                  концентрации и относительной скорости всасывания.</P></LI>
                <LI><P>Доверительные интервалы для AUC, Cmax и Cmax/AUC соответствуют допустимым пределам
                  80–125%.</P></LI>
                <LI><P>Препараты имеют сопоставимый профиль безопасности.</P></LI>
              </UL>
            </WrapperShellBox>
          </WrapperShell>

          <WrapperShell>
            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none  '}}>
                <H1 sx={{color: Red, mt: 0}}>АЛВОВИЗАН</H1>
                <P>Диеногест микронизированный 2 мг</P>
                <UL>
                  <LI><P sx={{mt: 1, mb: 1}}>Лекарственная форма: таблетки 2 мг</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Фармакотерапевтическая группа: гестаген</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Форма выпуска: таблетки № 28, № 84 и № 168</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Срок годности: 2 года</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Условия отпуска: по рецепту</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Производитель: Хаупт Фарма Мюнстер ГмбХ (Германия).</P></LI>
                </UL>
                <LinkNavigate to="/product/alvovizan/instruction">
                  <H2 sx={{color: Red}}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ:<Sup>9</Sup></H2>
                </LinkNavigate>
                <P sx={{mt: 1}}>Лечение эндометриоза</P>
              </WrapperShell>
              <WrapperShell className="small" sx={{
                boxShadow: 'none',
                border: `1px solid ${PinkLight}`,
                pl: '80px',
                position: 'relative',
                mb: 2
              }}>
                <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.approveRed}/>
                <P sx={{mt: 0}}>Применение препарата в особых клинических группах пациенток.</P>
                <P>Пациентки детского возраста.</P>
                <P>Препарат не показан к применению у детей до наступления менархе.</P>
                <P sx={{mb: 0}}>Эффективность препарата была продемонстрирована при лечении эндометриозассоциированной
                  тазовой боли у
                  подростков (12–18 лет) с общей благоприятной безопасностью и переносимостью.</P>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell sx={{backgroundImage: `url(${images.mainBottom})`, height: 500}}/>
        </Div>
      </Page>
      <ProductModal product="alvovizan/modal" modal={modal} onClose={handleClosePopup}/>
    </>
  )
}
