import {styled} from '@mui/system';

export const H1 = styled('h1')({
  fontSize: 36,
  fontFamily: 'SF-Pro-Text-Bold',
  lineHeight: '43px',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const H2 = styled('h2')({
  fontSize: 28,
  fontFamily: 'SF-Pro-Text-Semibold',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const Paragraph = styled('p')({
  color: '#FFF',
  fontSize: 16,
  fontFamily: 'SF-Pro-Text-Regular',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {}
})

export const LinkA = styled('a')({
  display: 'inline',
  color: '#0076BD',
  margin: '10px auto',
  letterSpacing: '0.1px',
  fontSize: 16,
  fontFamily: 'SF-Pro-Text-Semibold',
  textDecoration: 'underline',
})

export const Div = styled('div')({})

export const Span = styled('span')({
  wordBreak: 'normal'
})