import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanThirteenLinkOne = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>Efficacy and safety of dienogest in patients with endometriosis: A
        single-center observational study over 12 months</H1>
      <P>Objective To evaluate the efficacy and safety of dienogest treatment in patients who had received dienogest for
        12 months or more to treat endometriosis. Methods We analyzed the clinical data of 188 women with endometriosis
        who had been treated with 2 mg of dienogest once a day for 12 months or more at a single institute. We evaluated
        changes in endometriosis-associated pain and endometrioma size, recurrence rate, and adverse events following
        dienogest administration. Bone mineral density (BMD) was measured in patients who were prescribed dienogest for
        more than 18 months. Results Pain was significantly reduced at 12 months after dienogest medication. In those
        treated with dienogest due to recurrent endometrioma, the size of the endometrioma was significantly decreased
        at the 12-month and 18-month follow-ups. We found only one case of sonographic recurrence during dienogest
        administration among those who were treated postoperatively to prevent recurrence (1 of 114, 0.9%). The most
        common adverse drug reaction was uterine bleeding (3.2%), and other adverse events were generally tolerable and
        associated with low discontinuation rates (5.2%). Among the 50 patients in whom BMD was measured, 10 patients
        (20%) had a Z-score below the expected range for age. Conclusion The administration of dienogest for a year or
        more seems to be highly effective in preventing recurrence after surgery, reducing endometriosis-associated
        pain, and decreasing the size of recurrent endometrioma, with a favorable safety and tolerability profile.
        However, BMD should be checked in patients on long-term medication due to possible bone loss in some women.
      </P>
      <P>
        [ссылка: <A
        href="https://www.researchgate.net/publication/312162236_Efficacy_and_safety_of_dienogest_in_patients_with_endometriosis_A_single-center_observational_study_over_12_months">Park,
        So Yun et al. “Efficacy and safety of dienogest in patients with endometriosis: A single-center observational
        study over 12 months” Clinical and experimental reproductive medicine vol. 43,4 (2016): 215-220.</A>]
      </P>
    </Page>
  )
}
