import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const Quinque = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>
        A comparative study of breast cell proliferation during hormone replacement therapy: effects of tibolon and
        continuous combined estrogen-progestogen treatment
      </H1>
      <P>
        <b>Objective:</b> To use the fine-needle aspiration (FNA) biopsy technique to compare the effects of tibolone,
        conventional hormone replacement therapy (HRT) and placebo on breast cell proliferation in postmenopausal women.
      </P>
      <P>
        <b>Methods:</b> A total of 91 women were randomized to receive either estradiol 2 mg plus norethisterone acetate 1 mg
        (E2/NETA), tibolone 2.5 mg or placebo for 6 months in a prospective double-blind trial. Breast cell
        proliferation was assessed using the Ki-67/MIB-1 monoclonal antibody.
      </P>
      <P>
        <b>Results:</b> From the 83 women who completed the study, a total of 166 FNA biopsies were obtained, and 118 of these
        aspirates (71%) were evaluable for MIB-1 content. Women with assessable biopsies were younger, had a lower body
        mass index, and had higher levels of sex hormone binding globulin and insulin-like growth factor-I than women in
        whom the cell yield was insufficient. During treatment with E2/NETA, there was an increase in proliferation
        (percentage of MIB-1) from a mean value of 2.2 to 6.4% after 6 months (p {'<'} 0.01). No significant changes were
        recorded during treatment with tibolone or placebo. There was a negative association between proliferation and
        serum levels of total (r(s) = -0.29, p {'<'} 0.05) and free (rs = -0.31, p {'<'} 0.03) testosterone.
      </P>
      <P><b>Conclusions:</b> Tibolone seems to have little influence on breast cell proliferation.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/15259283/">https://pubmed.ncbi.nlm.nih.gov/15259283/</A>]
      </P>
    </Page>
  )
}
