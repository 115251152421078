import {styled} from '@mui/system';

export const H2 = styled('h2')({
  fontSize: 28,
  fontFamily: 'SF-Pro-Text-Bold',
  color: '#2F2F34',
  marginBottom: 10,

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})