import {Box} from '@mui/material';
import {StyledAnchorLink} from '../../ui';

type Props = {
  tabActive: number;
  tabLength: number;
}

export const HeaderTabs = ({tabActive, tabLength}: Props) => {
  return (
    <Box sx={{display: 'flex', gap: 5, mb: 3}}>
      <StyledAnchorLink className="no-border" href="#theme1">
        <Box className={`title-tab ${tabActive === 0 ? 'title-tab--active' : ''}`}>Тема #1</Box>
      </StyledAnchorLink>
      <StyledAnchorLink className="no-border" href="#theme2">
        <Box className={`title-tab ${tabActive === 1 ? 'title-tab--active' : ''}`}>Тема #2</Box>
      </StyledAnchorLink>
      {
        tabLength > 2 && (
          <StyledAnchorLink className="no-border" href="#theme3">
            <Box className={`title-tab ${tabActive === 2 ? 'title-tab--active' : ''}`}>Тема #3</Box>
          </StyledAnchorLink>
        )
      }
      {
        tabLength > 3 && (
          <StyledAnchorLink className="no-border" href="#theme4">
            <Box className={`title-tab ${tabActive === 3 ? 'title-tab--active' : ''}`}>Тема #4</Box>
          </StyledAnchorLink>
        )
      }
    </Box>
  )
}
