import {Box} from '@mui/material';

import {StyledAnchorLink, StyledHeader} from '../ui';
import {StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {HeaderTabs as Tabs} from './header-tabs';
import {Message} from './message';

type HeaderTabsProps = {
  tabActive: number
}
const HeaderTabs = ({tabActive}: HeaderTabsProps) => <Tabs tabActive={tabActive} tabLength={2}/>

export const SubpageVelledien = () => {
  return (
    <>
      <StyledHeader>Велледиен</StyledHeader>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <StyledAnchorLink href="#theme1">
          <img src={icons.arrow} alt="*"/>
          Сценарий #1: Велледиен vs Анжелик, Фемостон Конти
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme2">
          <img src={icons.arrow} alt="*"/>
          Сценарий #2: Велледиен vs Ливиал, Анжелик
        </StyledAnchorLink>
      </Box>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <HeaderTabs tabActive={0}/>
        <Box className="title" sx={{mb: 3}}>
          Велледиен vs Анжелик, Фемостон Конти
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день! Медицинский представитель компании Зентива (имя, фамилия). Уделите мне,
            пожалуйста, несколько минут для обсуждения пациенток в постменопаузе.
          </Message>
          <Message isQuestion={false}>
            Заходите, только недолго.
          </Message>

          <Message>
            Спасибо. Ольга Ивановна, скажите пожалуйста, как часто к Вам обращаются пациентки в постменопаузе с
            климактерическим симптомами?
          </Message>
          <Message isQuestion={false}>
            Достаточно часто. 3-4 пациентки в неделю.
          </Message>

          <Message>
            Что Вы обычно назначаете таким пациенткам?
          </Message>
          <Message isQuestion={false}>
            Чаще всего Анжелик, иногда Фемостон конти.
          </Message>

          <Message>
            А бывает, что на фоне МГТ наблюдается нагрубание молочных желез? Что вы в этом случае назначаете?
          </Message>
          <Message isQuestion={false}>
            Да, бывает, я отменяю препарат, назначаю Прожестожель.
          </Message>

          <Message>
            Я услышала вас, спасибо за информацию. А вы когда-нибудь работали с препаратами содержащими Тиболон?
          </Message>
          <Message isQuestion={false}>
            Да, раньше работала. Был очень дорогой Ливиал, как раз на него переводила этих пациенток. Но сейчас его нет.
            Еще был Ледибон, но и он пропал в аптеках.
          </Message>

          <Message>
            Велледиен единственный препарат с тиболоном, который остался на российском рынке. Это доступный аналог
            Ливиала и производится в Центральной Европе. Тиболон, который распадается на 3 активных метаболита, которые
            стимулируют те же рецепторы, что и стандартные МГТ с эстроген/гестогеновыми комбинациями+андрогеновые
            рецепторы. В результате чего мы получаем Улучшение сексуальности, улучшение настроения на фоне приема
            Веллдиена за счет повышения уровня эндорфинов и андрогенного действия метаболита тиболона. Этого нет ни у
            Фемостона и Анжелик. Более того Велледиен как раз для пациенток, которые не могут принимать стандартную
            эстроген-гестагенную МГТ за счет болезненности молочных желез. Когда у Велледина проведены рад клинических
            исследований, в которых не наблюдалось увеличения плотности ткани на маммограмме на фоне его приема.
          </Message>
          <Message isQuestion={false}>
            Точно, спасибо за информацию. Я с удовольствием переведу таких пациенток на Велледиен, чтобы улучшить их
            качество жизни.
          </Message>

          <Message>
            Спасибо за доверие, Ольга Ивановна, также Велледиен прекрасно подойдет пациенткам, которые были на
            Климонорме, достигли возраста 55 лет и им необходим плавный переход в постменопаузу с сохранением всех
            преимуществ проандрогенного компонента в составе. Это профилактика астено-депрессивной симптоматики,
            урогенитальных и когнитивных расстройств, перепадов настроения и низкого уровня сексуального влечения. Всем
            этим пациенткам показан Велледиен!!! Ольга Ивановна, в Ваших руках есть уникальный препарат европейского
            качества, который доступен многим пациенткам!
          </Message>
          <Message isQuestion={false}>
            Спасибо, это правда очень классный препарат!
          </Message>

          <Message>
            Ольга Ивановна, таким образом, мы с Вами договорились о выписке Велледиена 3-4 пациенткам в неделю с
            симптомами ухудшающих качество жизни пациенток в постменопаузальном периоде!
          </Message>
          <Message isQuestion={false}>
            Да, спасибо огромное за информацию! Договорились! До свидания!
          </Message>
        </Box>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme2" sx={{mt: 5}}>
        <HeaderTabs tabActive={1}/>
        <Box className="title" sx={{mb: 3}}>
          Велледиен vs Ливиал, Анжелик
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день! Медицинский представитель компании Зентива (имя, фамилия). Позвольте обсудить с
            Вами терапию для пациенток в период постменопаузы — 5-7 минут.
          </Message>
          <Message isQuestion={false}>
            Да.
          </Message>

          <Message>
            Скажите какой препарат Вы чаще всего рекомендуете женщинам после 1 года, но не более 10 лет после последней
            менструации в период менопаузы?
          </Message>
          <Message isQuestion={false}>
            По-разному, но чаще я назначаю Ливиал.
          </Message>

          <Message>
            Примерно какое количество в неделю?
          </Message>
          <Message isQuestion={false}>
            В среднем 1-2.
          </Message>

          <Message>
            Мы с вами знаем все преимущества Тиболона, для таких пациенток. При использовании Тиболона длительно не
            наблюдается увеличение плотности ткани на мамограмме, и не повышается риск пролиферации ткани молочной
            железы, что подтверждено клиническими исследованиями. Это позволяет использовать Тиболон длительно. В
            настоящее время наблюдается постоянный рост цен. Многие не могут позволить себе длительную терапию
            оригинальным препаратом. Назначая оригинальный препарат в половине случаев не назначается ничего, так как
            пациентка, услышав аптечную цену не купит его. Велледиен – это Тиболон по доступной цене! Разница в
            стоимости с Ливиалом в год составит 16 800 ₽.
            <Box sx={{mt: '12px'}}>
              Назначая Велледиен Вы обеспечиваете максимальное количество пациенток эффективной МГТ без риска для
              молочной
              железы с гарантией цены на длительный период.
            </Box>
          </Message>
          <Message isQuestion={false}>
            Да я часто слышала, что цена высокая, Велледиен прекрасный препарат, но мы не всегда знаем есть ли он в
            аптеках.
          </Message>

          <Message>
            (Демонстрируем Аптечную карту, сайт Аптека.ру… Цена…)
            Рекомендуете ли Вы еще какие-нибудь препараты пациенткам которых мы обсуждали?
          </Message>
          <Message isQuestion={false}>
            Да. Иногда Анжелик. Там микро дозы. На мой взгляд тоже хороший препарат.
          </Message>

          <Message>
            В среднем в неделю такое же количество или больше 2-3?
          </Message>
          <Message isQuestion={false}>
            Нет, в равной степени, 1-2.
          </Message>

          <Message>
            Хочу обратить Ваше внимание, что часто слышу, что Дроспиренон входящий в состав Анжелика снижает либидо, так
            как это гестаген с антиандрогенной активностью.
            <Box sx={{mt: '12px', mb: '12px'}}>
              Так же среди негативных явлений наблюдается нагрубание молочных желез, снижение качества сна у пациенток
              на
              Анжелике.
            </Box>
            Велледиен в связи с уникальным механизмом действия отнесен в отдельный класс терапии, тканеселективный
            регулятор эстрогенной активности. Распадается на три метаболита. Он надежно восполнить дефицит эстрогенов в
            постменопаузу, купирует приливы, снимет депрессию. Улучшит качество сна. Окажет положительное влияние на
            либидо и настроение, а так же не изменит плотность молочной железы.
          </Message>
          <Message isQuestion={false}>
            Я никогда так не думала. А действительно нужно посмотреть кому я смогу заменить Анжелик на Велледиен.
          </Message>

          <Message>
            К следующей встречи я буду готовится, и для меня важно ориентироваться. Примерно скажите какому количеству
            пациенток Вы порекомендуете Велледиен? Если я правильно помню то 1-2 Велледиен взамен Ливиала и 1-2
            Велледиен взамен Анжелика?
          </Message>
          <Message isQuestion={false}>
            Да 3-4 пациенткам так и получается.
          </Message>

          <Message>
            Спасибо большое. До встречи.
          </Message>
        </Box>
      </StyledBoxShadow>
    </>
  )
}
