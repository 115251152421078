import Page from 'shared/ui/Page';
import {H1} from '../ui';

import {StyledParagraph as P, Link as A} from './ui';
import {PinkLight} from '../../../styles/color';

export const ProginovaLinkEight = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Obstetric outcome in donor oocyte pregnancies: a matched-pair analysis</H1>
      <P><b>Background:</b> To investigate the obstetrical and perinatal impact of oocyte donation, a cohort of women who conceived after OD was compared with a matched control group of women who became pregnant through in vitro fertilisation with autologous oocytes (AO).</P>
      <P><b>Methods:</b> A matched-pair analysis has been performed at the Centre for Reproductive Medicine of the UZ Brussel, Dutch speaking Free University of Brussel. A total of 410 pregnancies resulted in birth beyond 20 weeks of gestation occurring over a period of 10 years, including 205 oocyte donation pregnancies and 205 ICSI pregnancies with autologous oocytes (AO). Patients in the OD group were matched on a one-to-one basis with the AO group in terms of age, ethnicity, parity and plurality. Matched groups were compared using paired t-tests for continuous variables and McNemar test for categorical variables. A conditional logistic regression analyses was performed adjusting for paternal age, age of the oocyte donor, number of embryos transferred, and singleton/twin pregnancy.</P>
      <P><b>Results:</b>Oocyte donation was associated with an increased risk of pregnancy induced hypertension (PIH) (matched OR: 1.502 CI: 1.024-2.204), and first trimester bleeding (matched OR: 1.493 CI: 1.036-2.15). No differences were observed between the two matched groups with regard to gestational age, mean birth weight and length, head circumference and Apgar scores.</P>
      <P><b>Conclusions:</b> Oocyte donation is associated with an increased risk for PIH and first trimester bleeding independent of the recipients' age, parity and plurality, and independent of the age of the donor or the partner. However, oocyte donation has no impact on the overall perinatal outcome.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/22672289/"> Stoop D, et al. Obstetric outcomes in donor oocyte pregnancies: a matched-pair analysis. Reprod Biol Endocrinol. 2012;6;10:42.</A>]
      </P>
    </Page>
  )
}
