import {styled} from '@mui/system';

// type StyledH1Props = {
//   backgroundColor?: string;
// };
//
// const StyledH1Options = {
//   shouldForwardProp: (prop: string) => prop !== "backgroundColor"
// }

export const StyledH1 = styled('h1')({
  color: '#F9D2D6',
  fontSize: 36,
  fontFamily: 'SF-Pro-Text-Semibold',
  lineHeight: '43px',
  letterSpacing: '0.1px',
  marginTop: 0,

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})