import {styled} from '@mui/system';

export const StyledParagraph = styled('p')({
  color: '#FFFFFF',
  fontSize: 20,
  fontFamily: 'SF-Pro-Text-Regular',
  lineHeight: '24px',
  letterSpacing: '0.1px',
  margin: '5px 0',

  '@media only screen and (max-width: 600px)': {
    //wordBreak: 'break-all',
  }
})