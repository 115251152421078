import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const Quattuor = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>
        Effects of tibolone and continuous combined hormone therapy on mammographic breast density and breast
        histochemical markers in postmenopausal women
      </H1>
      <P>
        Objective: To compare changes in mammographic density and the expression of markers of proliferation (Ki67) and
        apoptosis (Bcl-2) after 1 year of treatment with tibolone and continuous conjugated equine estrogens combined
        with medroxyprogesterone acetate (CEE-MPA).
      </P>
      <P>
        Design: Comparative, randomized, evaluator-blinded study.
      </P>
      <P>
        Setting: City research hospital.
      </P>
      <P>
        Patient(s): Thirty-seven postmenopausal women.
      </P>
      <P>
        Intervention(s): Tibolone (2.5 mg; n = 18) or continuous conjugated estrogens (0.625 mg) combined with
        medroxyprogesterone acetate (5 mg; n = 19) for 1 year.</P>
      <P>
        Main outcome measure(s): Mammographic density (BI-RADS density score), expression of immunohistochemical markers
        Ki67 and Bcl-2.
      </P>
      <P>
        Result(s): Mean breast density score decreased significantly from 2.22 to 1.67 in the tibolone group, compared
        with a significant increase in the CEE-MPA-treated group from 1.84 to 2.63. Ki67 expression decreased in 12 of
        15, increased in 2 of 15, and remained unchanged in 1 of 15 subjects in the tibolone group, compared with 1 of
        19, 15 of 19, and 3 of 19 subjects, respectively, in the CEE-MPA group. Bcl-2 expression decreased in 12 of 15,
        increased in 2 of 15, and remained unchanged in 1 of 15 subjects in the tibolone group, compared with 5 of 19, 9
        of 19, and 5 of 19 subjects, respectively, in the CEE-MPA group.
      </P>
      <P>
        Conclusion(s): One-year treatment with tibolone induced a decrease in breast density, with a reduction in
        proliferation and a stimulation of apoptosis, whereas 1-year treatment with CEE-MPA induced an increase in
        breast density, with stimulation of proliferation and inhibition of apoptosis, indicating that tibolone effects
        on the breast are different from those of CEE-MPA.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/15037411/">https://pubmed.ncbi.nlm.nih.gov/15037411/</A>]
      </P>
    </Page>
  )
}
