import {useHistory} from 'react-router-dom';

import {WrapperShell} from 'components/wrapper-shell';
import {WrapperCouple} from 'components/wrapper-couple';
import Page from 'shared/ui/Page';
import {Div, Img, List as UL, ListItem as LI, Span, Tables} from 'shared/ui';
import {PinkLight} from 'styles/color';

import {StyledH2 as H2, StyledH1 as H1, StyledParagraph as P, LinkA as A} from './ui';
import pdf from '../../../pdfs/microginon/ИМП_Микрогинон.pdf';
import images from '../img'

export const MicroginonInstruction = () => {
  const history = useHistory();

  const handlerRedirectToDiecyclen = () => {
    history.push('/product/microginon');
  }

  return (
    <Page onClose={handlerRedirectToDiecyclen} isCross>
      <Div sx={{textAlign: 'center'}}>
        <H2 sx={{fontSize: 20, mb: 3}}>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</H2>
        <H1>ИНСТРУКЦИЯ</H1>
        <H2 sx={{fontSize: 20, textTransform: 'none', mb: 3, mt: 2}}>по применению лекарственного препарата для
          медицинского применения</H2>
        <H1 sx={{marginBottom: 6}}>МИКРОГИНОН®</H1>
      </Div>
      <H2 sx={{fontSize: 20}}>РЕГИСТРАЦИОННЫЙ НОМЕР: <Span sx={{color: '#FFF'}}>П N015604/01</Span></H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ТОРГОВОЕ НАИМЕНОВАНИЕ: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>Микрогинон®</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        МЕЖДУНАРОДНОЕ НЕПАТЕНТОВАННОЕ НАИМЕНОВАНИЕ: <Span
        sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>Левоноргестрел + Этинилэстрадиол</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ЛЕКАРСТВЕННАЯ ФОРМА: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>аблетки, покрытые
        оболочкой</Span>
      </H2>

      <H2 style={{marginTop: 50}}>СОДЕРЖАНИЕ</H2>
      <WrapperCouple sx={{display: 'flex', justifyContent: 'space-between'}}>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#compound'}>СОСТАВ</A>
          <A href={'#description'}>ОПИСАНИЕ</A>
          <A href={'#farm-group'}>ФАРМАКОТЕРАПЕВТИЧЕСКАЯ ГРУППА</A>
          <A href={'#farm-property'}>ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА</A>
          <A href={'#ind-for-use'}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ</A>
          <A href={'#dication'}>ПРОТИВОПОКАЗАНИЯ</A>
          <A href={'#warning'}>С ОСТОРОЖНОСТЬЮ</A>
          <A href={'#for-use-feed'}>ПРИМЕНЕНИЕ В ПЕРИОД БЕРЕМЕННОСТИ И ГРУДНОГО<br/> ВСКАРМЛИВАНИЯ</A>
          <A href={'#dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</A>
          <A href={'#effect-action'}>ПОБОЧНОЕ ДЕЙСТВИЕ</A>
          <A href={'#over-dozing'}>ПЕРЕДОЗИРОВКА</A>
        </WrapperShell>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#another-medicines'}>ВЗАИМОДЕЙСТВИЕ С ДРУГИМИ ЛЕКАРСТВЕННЫМИ <br/> СРЕДСТВАМИ</A>
          <A href={'#special-instructions'}>ОСОБЫЕ УКАЗАНИЯ</A>
          <A href={'#managment-transport'}>ВЛИЯНИЕ НА СПОСОБНОСТЬ УПРАВЛЕНИЯ <br/>ТРАНСПОРТНЫМИ СРЕДСТВАМИ,
            МЕХАНИЗМАМИ</A>
          <A href={'#form-production'}>ФОРМА ВЫПУСКА</A>
          <A href={'#keeper'}>УСЛОВИЯ ХРАНЕНИЯ</A>
          <A href={'#deadline'}>СРОК ГОДНОСТИ</A>
          <A href={'#holiday'}>УСЛОВИЯ ОТПУСКА</A>
          <A href={'#owner'}>ВЛАДЕЛЕЦ (ДЕРЖАТЕЛЬ) РЕГИСТРАЦИОННОГО<br/>УДОСТОВЕРЕНИЯ</A>
          <A href={'#producer'}>ПРОИЗВОДИТЕЛЬ</A>
        </WrapperShell>
      </WrapperCouple>

      <H2 id={'compound'}>СОСТАВ</H2>
      <P>Каждая таблетка содержит:</P>
      <P><b>Ядро:</b></P>
      <P>Активные вещества: 0,150 мг левоноргестрела и 0,030 мг этинилэстрадиола.</P>
      <P>Вспомогательные вещества: лактозы моногидрат 32,970 мг, крахмал кукурузный 18,000 мг, повидон-25 тыс. 2,100 мг,
        тальк 1,650 мг, магния стеарат 0,100 мг.</P>
      <P>
        <b>Оболочка:</b> сахароза 19,371 мг, повидон-700 тыс. 0,189 мг, макрогол-6000 2,148 мг, кальция карбонат 8,606
        мг,
        тальк 4,198 мг, титана диоксид (Е171) 0,274 мг, глицерол 85% 0,137 мг, воск горный гликолиевый 0,050 мг,
        краситель железа оксид желтый (Е172, C.I. 77 492) 0,027 мг.
      </P>

      <H2 id={'description'}>ОПИСАНИЕ</H2>
      <P>Таблетки покрытые оболочкой светло-желтого цвета круглой формы. Вид на изломе: ядро от белого до почти белого
        цвета, оболочка - светло-желтая.</P>

      <H2 id={'farm-group'}>Фармакотерапевтическая группа: </H2>
      <P>Контрацептивное средство комбинированное (эстроген + гестаген)</P>
      <P><b>Код ATX</b>: G03AA07</P>

      <H2 id={'farm-property'}>Фармакологические свойства</H2>
      <P><b>Фармакодинамика</b></P>
      <P>
        Препарат избирательно регулирует эстрогеноподобную активность в тканях и является тканеселективным регулятором.
        Его фармакодинамические свойства определяются действием трех фармакологически активных метаболитов тиболона:
        3-альфа-гидрокситиболон и 3-бета-гидрокситиболон обладают эстрогеноподобной активностью, дельта-4-изомеру
        свойственна прогестагеноподобная и слабая андрогеноподобная активность. Препарат восполняет дефицит эстрогенов в
        постменопаузальном периоде, облегчая связанные с их дефицитом симптомы - «приливы», депрессия, повышенное
        потоотделение ночью, головная боль. Положительно влияет на либидо и настроение (повышает концентрацию
        центральных и периферических опиоидов). Оказывает трофическое действие па слизистую оболочку влагалища, не
        вызывая пролиферацию эндометрия. Предотвращает потерю костной массы после наступления менопаузы или удаления
        яичников. Снижает концентрацию фосфатов и кальция в плазме крови.
      </P>
      <P><b>Фармакокинетика</b></P>
      <P>
        Препарат Микрогинон® - низкодозированный монофазный пероральный комбинированный эстрогеи-гестагенный
        контрацептивный препарат.
      </P>
      <P>
        Контрацептивный эффект препарата Микрогинон® осуществляется посредством взаимодополняющих механизмов, к наиболее
        важным из которых относятся подавление овуляции и повышение вязкости секрета шейки матки, в результате чего она
        становится непроницаемой для сперматозоидов.
      </P>
      <P>
        У женщин, принимающих комбинированные пероральные контрацептивы (КОК), цикл становится более регулярным,
        уменьшаются болезненность и интенсивность менструальноподобных кровотечений, в результате чего снижается риск
        железодефицитной анемии. Кроме того, есть данные о том, что при применении КОК снижается риск развития рака
        эндометрия и рака яичников.
      </P>
      <P>
        При правильном применении индекс Перля (показатель, отражающий частоту наступления беременности у 100 женщин в
        течение года применения контрацептива) составляет менее 1. При пропуске таблеток или неправильном применении
        индекс Перля может возрастать.
      </P>
      <P><b>Фармакокинетика</b></P>
      <P><b><u>Левоноргестрел</u></b></P>
      <P><b>Абсорбция.</b> После приема внутрь левоноргестрел быстро и полностью абсорбируется, его максимальная
        концентрация в
        плазме крови, равная 3-4 нг/мл, достигается примерно через 1 час. Биодоступность левоноргестрела при пероральном
        приеме почти полная.</P>
      <P>
        <b>Распределение.</b> Левоноргестрел связывается с альбумином плазмы крови и с глобулином, связывающим половые
        гормоны
        (ГСПГ). В свободном виде в плазме крови находится только около 1,3% от общей концентрации вещества; около 64% -
        специфически связаны с ГСПГ и около 35% - неспецифически связаны с альбумином.
      </P>
      <P>
        Индукция этинилэстрадиолом синтеза ГСПГ влияет на связывание левоноргестрела с белками плазмы крови, вызывая
        увеличение фракции, связанной с ГСПГ и уменьшение фракции, связанной с альбумином. Кажущийся объем распределения
        левоноргестрела составляет около 184 л после однократного приема.
      </P>

      <P>
        <b>Метаболизм.</b> Левоноргестрел подвергается экстенсивному метаболизму. Основными метаболитами в плазме крови
        являются неконъюгированные и конъюгированные формы За, 5р-тетрагидролевоноргестрела. На основании исследований
        in vitro и in vivo основным ферментом, участвующим в метаболизме левоноргестрела, является CYP3A4. Клиренс из
        плазмы крови составляет примерно 1,3-1,6 мл/мин/кг.
      </P>
      <P><b>Выведение.</b> Концентрация левоноргестрела в плазме крови снижается двухфазно. Период полувыведения в
        терминальную
        фазу составляет около 20-23 ч. В неизмененном виде левоноргестрел не выводится, а только в виде метаболитов,
        которые выводятся почками и через кишечник в соотношении примерно 1:1 с периодом полувыведения около 24 ч.</P>
      <P><b>Равновесная концентрация.</b> При ежедневном приеме препарата концентрация вещества в плазме крови
        увеличивается
        примерно в 3-4 раза, достигая равновесной концентрации во второй половине цикла приема препарата. На
        фармакокинетику левоноргестрела влияет концентрация ГСПГ в плазме крови, которая при применении левоноргестрела
        вместе с этинилэстрадиолом возрастает примерно в 1,7 раза. При равновесной концентрации скорость клиренса
        сокращается до примерно 0,7 мл/мин/кг.</P>
      <P><b>
        <ul>Этинилэстрадиол</ul>
      </b></P>
      <P>
        <b>Абсорбция.</b> После приёма внутрь этинилэстрадиол быстро и полностью абсорбируется. Максимальная
        концентрация в
        плазме крови, равная примерно 95 пг/мл, достигается за 1-2 ч. Во время всасывания и «первичного прохождения»
        через печень этинилэстрадиол метаболизируется, в результате чего его биодоступность при приеме внутрь составляет
        в среднем около 45% (индивидуальные различия в пределах 20-65%).
      </P>

      <P>
        <b>Распределение.</b> Этинилэстрадиол практически полностью (приблизительно 98%), хотя и неспецифично,
        связывается
        альбумином. Этинилэстрадиол индуцирует синтез ГСПГ. Кажущийся объем распределения этииилэстрадиола составляет
        2,8-8,6 л/кг.
      </P>
      <P><b>Метаболизм.</b> Этинилэстрадиол подвергается пресистемной конъюгации как в слизистой тонкой кишки, так и в
        печени.
        Основной путь метаболизма - ароматическое гидроксилирование. Скорость клиренса из плазмы крови составляет 2,3-7
        мл/мин/кг.</P>
      <P><b>Выведение.</b> Уменьшение концентрации этинилэстрадиола в плазме крови носит двухфазный характер; первая
        фаза
        характеризуется периодом полувыведения около 1 часа, вторая - 10-20 часов. В неизмененном виде из организма не
        выводится. Метаболиты этинилэстрадиола выводятся почками и через кишечник в соотношении 4:6 с периодом
        полувыведения около 24 ч.</P>
      <P>
        <b>Равновесная концентрация.</b> При ежедневном пероральном приеме препарата Микрогинон® концентрация
        этинилэстрадиола
        в плазме крови немного увеличивается, достигая максимального значения 114 пг/мл в конце цикла. Учитывая
        изменчивый период полувыведения в терминальной фазе и ежедневный пероральный прием, равновесная концентрация
        достигается приблизительно через одну неделю.
      </P>

      <H2 id={'ind-for-use'}>Показания к применению</H2>
      <P>Пероральная контрацепция.</P>

      <H2 id={'dication'}>Противопоказания</H2>
      <P>
        Применение препарата Микрогинон® противопоказано при наличии любого из состояний/заболеваний, перечисленных
        ниже. Если какие-либо из этих состояний/заболеваний развиваются впервые на фоне приема препарата, то препарат
        должен быть немедленно отменен.
      </P>
      <UL>
        <LI><P>Тромбозы (венозные и артериальные), тромбоэмболии (в том числе тромбоз глубоких вен, тромбоэмболия
          легочной артерии, инфаркт миокарда), цереброваскулярные нарушения - в настоящее время или в анамнезе.</P></LI>
        <LI><P>
          Выявленная приобретенная или наследственная предрасположенность к венозному или артериальному тромбозу,
          включая резистентность к активированному протеину С, дефицит антитромбина III, дефицит протеина С, дефицит
          протеина S, гипергомоцистеинемия, антитела к фосфолипидам (антитела к кардиолипину, волчаночный
          антикоагулянт).
        </P></LI>
        <LI><P>
          Состояния, предшествующие тромбозу (в том числе транзиторные ишемические атаки, стенокардия) в настоящее время
          или в анамнезе.
        </P></LI>
        <LI><P>
          Наличие высокого риска венозного или артериального тромбоза (см. раздел «Особые указания»).Наличие высокого
          риска венозного или артериального тромбоза (см. раздел «Особые указания»).
        </P></LI>
        <LI><P>
          Мигрень с очаговыми неврологическими симптомами в настоящее время или в анамнезе.
        </P></LI>
        <LI><P>Сахарный диабет с сосудистыми осложнениями.</P></LI>
        <LI><P>Панкреатит с выраженной гипертриглицеридемией в настоящее время или в анамнезе.</P></LI>
        <LI><P>Печеночная недостаточность и тяжелые заболевания печени (до тех пор пока печеночные тесты не придут в
          норму).</P></LI>
        <LI><P>Опухоли печени (доброкачественные или злокачественные) в настоящее время или в анамнезе.</P></LI>
        <LI><P>Выявленные гормонозависимые злокачественные новообразования (в том числе половых органов или молочных
          желез) или подозрение на них.</P></LI>
        <LI><P>Кровотечение из влагалища неясного генеза.</P></LI>
        <LI><P>Беременность или подозрение на нее.</P></LI>
        <LI><P>Период грудного вскармливания.</P></LI>
        <LI><P>Повышенная чувствительность к любому из компонентов препарата Микрогинон®.</P></LI>
        <LI><P>Дефицит лактазы, непереносимость лактозы, дефицит сахаразы/изомальтазы, непереносимость фруктозы,
          глюкозо-галактозная мальабсорбция.</P></LI>
      </UL>

      <H2 id={'warning'}>С осторожностью</H2>
      <P>Следует тщательно взвешивать потенциальный риск и ожидаемую пользу применения КОК в каждом случае индивидуально
        при наличии следующих заболеваний/состояний и факторов риска:</P>
      <UL>
        <LI><P>
          Факторы риска развития тромбоза и тромбоэмболий: курение; тромбозы, инфаркт миокарда или нарушение мозгового
          кровообращения в молодом возрасте у кого- либо из ближайших родственников; ожирение с индексом массы тела
          менее 30 кг/м2; дислипопротеинемия, контролируемая артериальная гипертензия; мигрень без очаговой
          неврологической симптоматики; заболевания клапанов сердца; нарушения сердечного ритма.
        </P></LI>
        <LI><P>
          Другие заболевания, при которых могут отмечаться нарушения периферического кровообращения: сахарный диабет без
          сосудистых осложнений; системная красная волчанка; гемолитико-уремический синдром; болезнь Крона и язвенный
          колит; серповидно-клеточная анемия; флебит поверхностных вен.
        </P></LI>
        <LI><P>Гипертриглицеридемия.</P></LI>
        <LI><P>Заболевания печени, не указанные в разделе «Противопоказания» (см. раздел «Противопоказания»).</P></LI>
        <LI><P>Заболевания, впервые возникшие или усугубившиеся во время беременности или па фоне предыдущего приема
          половых гормонов (например, желтуха и/или зуд, </P></LI>
        <LI><P>связанные с холестазом, заболевания желчного пузыря, отосклероз с ухудшением слуха, порфирия, герпес во
          время беременности, хорея Сиденгама).</P></LI>
        <LI><P>У женщин с наследственным ангионевротическим отеком экзогенные эстрогены могут вызвать или усилить
          симптомы ангионевротического отека.</P></LI>
      </UL>

      <H2 id={'for-use-feed'}>Применение в период беременности и грудного вскармливания</H2>
      <P>Препарат Микрогинон® противопоказан к применению во время беременности и в период грудного вскармливания.</P>
      <P>Если беременность выявляется во время приема препарата Микрогинон®, препарат следует сразу же отменить.
        Многочисленные эпидемиологические исследования не выявили ни повышения риска дефектов развития у детей,
        рожденных женщинами, получавшими половые гормоны до беременности, ни наличия тератогенного действия, когда
        половые гормоны принимались по неосторожности в ранние сроки беременности.</P>
      <P>Прием препарата Микрогинон®, как и других КОК, может уменьшать количество грудного молока и изменять его
        состав, поэтому применение препарата противопоказано до прекращения грудного вскармливания. Небольшое количество
        половых гормонов и/или их метаболитов может проникать в грудное молоко и оказывать влияние на здоровье
        ребенка.</P>

      <H2 id={'dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</H2>
      <P>
        Календарная упаковка препарата Микрогинон® содержит 21 таблетку. Таблетки следует принимать внутрь по одной
        каждый день в течение 21 дня примерно в одно и то же время, запивая небольшим количеством воды. Каждую таблетку
        необходимо принимать в соответствующий день недели, указанный на упаковке, следуя стрелкам. Прием таблеток из
        следующей упаковки начинается после 7-дневного перерыва в приеме таблеток, во время которого обычно наступает
        менструальноподобное кровотечение (кровотечение «отмены»). Как правило, оно начинается на 2-3 день после приема
        последней таблетки и может не закончиться до начала приема таблеток из новой упаковки. После 7-дневного перерыва
        необходимо начать прием таблеток из новой упаковки даже в случае, если менструальноподобное кровотечение еще не
        прекратилось. Это означает, что начинать прием таблеток из новой упаковки необходимо в один и тот же день
        недели, и что каждый месяц кровотечение «отмены» будет наступать примерно в один и тот же день недели.
      </P>
      <P><b>Прием таблеток из первой упаковки препарата Микрогинон®</b></P>
      <P>• Когда никакое гормональное контрацептивное средство не применялось в предыдущем месяце</P>
      <P>
        Прием препарата Микрогинон® следует начать в первый день менструального цикла (т.е. в первый день менструального
        кровотечения). Необходимо принять таблетку, согласно дню недели, указанному на упаковке. Затем следует принимать
        таблетки по порядку. Допускается начало приема на 2-5 день менструального цикла, по в этом случае в течение
        первых 7 дней приема таблеток из первой упаковки рекомендуется дополнительно использовать барьерный метод
        контрацепции (например, презерватив).
      </P>
      <P>• При переходе с других комбинированных контрацептивных препаратов (КОК, вагинального кольца или
        трансдермалъного пластыря)</P>
      <P>Предпочтительно - начать прием препарата Микрогинон® на следующий день после приема последней таблетки из
        предыдущей упаковки, но ни в коем случае не позднее следующего дня после обычного 7-дневного перерыва (для
        препаратов, содержащих 21 таблетку) или на следующий день после приема последней таблетки из предыдущей упаковки
        (для препаратов, содержащих 28 таблеток в упаковке). Прием препарата Микрогинон® следует начинать в день
        удаления вагинального кольца или пластыря, но не позднее дня, когда должно быть введено новое кольцо или наклеен
        новый пластырь.</P>
      <P>• При переходе с контрацептивов, содержащих только гестагены («мини-пили», инъекционные формы, имплантат), или
        с внутриматочной терапевтической системы с высвобождением гестагена</P>
      <P>Перейти с «мини-пили» на препарат Микрогинон® можно в любой день (без перерыва), с имплантата или
        внутриматочного контрацептива с гестагеном - в день его удаления, с инъекционной формы - со дня, когда должна
        быть сделана следующая инъекция. Во всех случаях в течение первых 7 дней приема таблеток необходимо использовать
        дополнительно барьерный метод контрацепции (например, презерватив).</P>
      <P>• После аборта (в том числе самопроизвольного) в первом триместре беременности Начать прием препарата можно
        немедленно. При соблюдении этого условия дополнительных мер контрацепции не требуется.</P>
      <P>• После родов (при отсутствии грудного вскармливания) или аборта (в том числе самопроизвольного) во втором
        триместре беременности</P>
      <P>Начать прием препарата рекомендуется на 21-28 день после родов (при отсутствии грудного вскармливания) или
        аборта (в том числе самопроизвольного) во втором триместре беременности. Если прием препарата начат позднее,
        необходимо использовать дополнительно барьерный метод контрацепции в течение первых 7 дней приема таблеток. Если
        половой контакт имел место до начала приема препарата Микрогинон®, необходимо исключить беременность.</P>
      <P><b>Прием пропущенных таблеток</b></P>
      <P>Если опоздание в приеме препарата составило менее 12 часов, контрацептивная защита не снижается. Нужно принять
        пропущенную таблетку как можно скорее, следующая таблетка принимается в обычное время.</P>
      <P>Если опоздание в приеме препарата составило более 12 часов, контрацептивная защита может быть снижена. Чем
        больше таблеток пропущено, и чем ближе пропуск к 7- дневному перерыву в приеме таблеток, тем выше вероятность
        наступления беременности.</P>
      <P>При этом необходимо помнить:</P>
      <P>• Прием препарата никогда не должен быть прерван более чем на 7 дней.</P>
      <P>• 7 дней непрерывного приема таблеток требуются для достижения адекватного подавления
        гипоталамо-гипофизарно-яичниковой системы.</P>
      <P>Соответственно могут быть даны следующие рекомендации:</P>
      <P>• Первая неделя приема препарата</P>
      <P>Необходимо принять последнюю пропущенную таблетку как можно скорее, как только женщина вспомнит (даже если это
        означает прием двух таблеток одновременно). Следующую таблетку необходимо принять в обычное время. В течение
        последующих 7 дней необходимо дополнительно использовать барьерный метод контрацепции (например, презерватив).
        Если половой контакт имел место в течение недели перед пропуском таблетки, необходимо учитывать возможность
        наступления беременности. </P>
      <P>• Вторая неделя приема препарата</P>
      <P>Необходимо принять последнюю пропущенную таблетку как можно скорее, как только женщина вспомнит об этом (даже
        если это означает прием двух таблеток одновременно). Следующую таблетку необходимо принять в обычное время.</P>
      <P>
        При условии, что женщина принимала таблетки правильно в течение 7 дней, предшествующих первой пропущенной
        таблетке, нет необходимости в использовании дополнительных методов контрацепции. В противном случае, а также при
        пропуске двух и более таблеток необходимо дополнительно использовать барьерные методы контрацепции (например,
        презерватив) в течение последующих 7 дней.
      </P>
      <P>• Третья неделя приема препарата</P>
      <P>Риск снижения контрацептивной надежности неизбежен из-за предстоящего перерыва в приеме таблеток. В этом случае
        необходимо придерживаться следующих алгоритмов:</P>
      <P>
        - если в течение 7 дней, предшествующих первой пропущенной таблетке, все таблетки принимались правильно, нет
        необходимости использовать дополнительные методы контрацепции. При приеме пропущенных таблеток руководствуйтесь
        пунктами 1 или 2. - если в течение 7 дней, предшествующих первой пропущенной таблетке, таблетки принимались
        неправильно, то в течение последующих 7 дней необходимо дополнительно использовать барьерный метод контрацепции
        (например, презерватив) и в этом случае следует руководствоваться пунктом 1 для приема пропущенных таблеток.
      </P>
      <P>1. Необходимо принять последнюю пропущенную таблетку как можно скорее, как только женщина вспомнит об этом
        (даже если это означает прием двух таблеток одновременно). Следующие таблетки принимают в обычное время, пока не
        закончатся таблетки из текущей упаковки. Прием таблеток из следующей упаковки следует начать сразу же без
        обычного 7-дневного перерыва. Кровотечение «отмены» маловероятно, пока не закончатся таблетки из второй
        упаковки, но могут отмечаться «мажущие» выделения и/или «прорывные» кровотечения в дни приема препарата.</P>
      <P>2. Можно также прервать прием таблеток из текущей упаковки, сделать перерыв па 7 или менее дней (включая дни
        пропуска таблеток), после чего начать прием таблеток из новой упаковки.</P>
      <P>Если женщина пропустила прием таблеток, и затем во время перерыва в приеме у нее нет кровотечения «отмены»,
        необходимо исключить беременность.</P>

      <P><b>Схема действий пациентки при нарушении режима приема таблеток:</b></P>
      <Div sx={{overflowX: 'auto'}}>
        <Img src={images.schema} sx={{minWidth: 1040}}/>
      </Div>
      <P><b>Допускается принимать не более двух таблеток в один день.</b></P>
      <P><b>Рекомендации при желудочно-кишечных расстройствах</b></P>
      <P>При тяжелых желудочно-кишечных расстройствах всасывание препарата может быть неполным, поэтому следует
        использовать дополнительные методы контрацепции.</P>
      <P>Если в течение 3-4 часов после приема таблеток отмечается рвота или диарея, в зависимости от недели приема
        препарата следует ориентироваться на рекомендации при пропуске таблеток, указанные выше. Если женщина не хочет
        менять свою обычную схему приема и переносить начало кровотечения «отмены» на другой день недели, дополнительную
        таблетку следует принять из другой упаковки.</P>
      <P><b>Прекращение приема препарата Микрогинон®</b></P>
      <P>Прием препарата Микрогинон® можно прекратить в любое время. Если женщина не планирует беременность, следует
        позаботиться о других методах контрацепции. Если планируется беременность, следует просто прекратить прием
        препарата Микрогинон® и дождаться естественного менструального кровотечения.</P>
      <P><b>Отсрочка начала менструальноподобного кровотечения</b></P>
      <P>Для того, чтобы отсрочить начало менструальноподобного кровотечения, необходимо продолжить дальнейший прием
        таблеток из новой упаковки препарата Микрогинон® без 7-дневного перерыва. Таблетки из новой упаковки могут
        приниматься так долго, как это необходимо, в том числе до тех пор, пока таблетки в упаковке не закончатся. На
        фоне приема препарата из второй упаковки могут отмечаться «мажущие» кровянистые выделения из влагалища и/или
        «прорывные» маточные кровотечения. Возобновить прием препарата Микрогинон® из очередной упаковки следует после
        обычного 7- дневного перерыва.</P>
      <P><b>Изменение дня начала менструальноподобного кровотечения</b></P>
      <P>
        Для того, чтобы перенести день начала менструальноподобного кровотечения па другой день недели, женщине следует
        сократить (но не удлинять) ближайший 7- дневный перерыв в приеме таблеток на столько дней, на сколько женщина
        хочет. Например, если цикл обычно начинается в пятницу, а в будущем женщина хочет, чтобы он начинался во вторник
        (3 днями ранее), прием таблеток из следующей упаковки необходимо начать на 3 дня раньше, чем обычно. Чем короче
        перерыв в приеме таблеток, тем выше вероятность, что менструальноподобное кровотечение не наступит, и во время
        приема таблеток из второй упаковки будут наблюдаться «мажущие» выделения и/или «прорывные» кровотечения.
      </P>
      <P><b><u>Применение у отдельных групп пациенток</u></b></P>
      <P><b>У детей и подростков</b></P>
      <P>Эффективность и безопасность препарата Микрогинон® в качестве контрацептивного средства изучены у женщин
        репродуктивного возраста. Предполагается, что в постпубертатном периоде до 18 лет эффективность и безопасность
        препарата аналогичны таковым у женщин после 18 лет. Применение препарата до наступления менархе не показано.</P>
      <P><b>У пожилых</b></P>
      <P>Не применимо. Препарат Микрогинон® не показан после наступления менопаузы.</P>
      <P><b>При нарушениях функции печени</b></P>
      <P>Препарат Микрогинон® противопоказан женщинам с тяжелыми заболеваниями печени до тех пор, пока показатели
        функции печени не придут в норму. См. также раздел «Противопоказания».</P>
      <P><b>При нарушениях функции почек</b></P>
      <P>Препарат Микрогинон® специально не изучался у пациенток с нарушениями функции почек. Имеющиеся данные не
        предполагают коррекции режима дозирования у таких пациенток.</P>

      <H2 id={'effect-action'}>Побочное действие</H2>
      <P>Наиболее часто описываемыми нежелательными явлениями на фоне приема препарата Микрогинон® являются тошнота,
        боли в животе, повышение массы тела, головная боль, снижение -настроения, изменение настроения, боль в молочных
        железах, нагрубание молочных желез. Они встречаются у {'>'} 1 % пациенток.</P>
      <P>
        Серьезными нежелательными явлениями являются артериальные и венозные тромбоэмболии.
      </P>
      <P>На фоне приема КОК у женщин наблюдались и другие нежелательные явления, связь которых с приемом препаратов не
        подтверждена, но и не опровергнута.</P>

      <Tables.WrapperTable>
        <Tables.Table>
          <Tables.Thead>
            <Tables.Tr>
              <Tables.Td>Системно-органные классы (MedDRA)</Tables.Td>
              <Tables.Td>Часто </Tables.Td>
              <Tables.Td>Нечасто</Tables.Td>
              <Tables.Td>Редко</Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны органа зрения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Непереносимость контактных линз (неприятные ощущения при их ношении)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны желудочно- кишечного тракта</Tables.Td>
              <Tables.Td>тошнота, боль в животе</Tables.Td>
              <Tables.Td>рвота, диарея</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Общие расстройства</Tables.Td>
              <Tables.Td>увеличение массы тела</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>снижение массы тела</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны иммунной системы</Tables.Td>
              <Tables.Td>гиперчувствительность</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны обмена веществ и питания</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>задержка жидкости</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны нервной системы</Tables.Td>
              <Tables.Td>головная боль</Tables.Td>
              <Tables.Td>мигрень</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения психики</Tables.Td>
              <Tables.Td>снижение настроения, перепады настроения</Tables.Td>
              <Tables.Td>снижение либидо</Tables.Td>
              <Tables.Td>повышение либидо</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны половых органов и молочной железы</Tables.Td>
              <Tables.Td>болезненность молочных желез, нагрубание молочных желез</Tables.Td>
              <Tables.Td>гипертрофия молочных желез</Tables.Td>
              <Tables.Td>выделения из половых путей, выделения из молочных желез</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны кожи и подкожных тканей</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>сыпь, крапивница</Tables.Td>
              <Tables.Td>узловатая эритема, многоформная эритема</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны сосудов</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>венозные и артериальные тромбоэмболические осложнения*</Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>
      <P sx={{color: PinkLight}}>* - Расчетная частота по данным эпидемиологических исследований, охватывавших группу
        женщин, принимавших КОК.</P>

      <P>- Венозные и артериальные тромбоэмболические осложнения объединяют следующие нозологические формы: окклюзия
        периферических глубоких вен, тромбоз и тромбоэмболия /окклюзия легочных сосудов, тромбоз, эмболия и
        инфаркт/инфаркт миокарда/церебральный инфаркт и инсульт пе классифицированный как геморрагический.</P>

      <P>
        Ниже перечислены нежелательные явления с очень низкой частотой или отсроченным развитием симптомов, которые
        предположительно связаны с приемом КОК (см. также V разделы ’’Противопоказания”, “Особые указания”):
      </P>

      <P>Опухоли</P>

      <UL>
        <LI><P>У женщин, применяющих КОК, очень незначительно повышена частота выявления рака молочной железы. Поскольку
          рак молочной железы редко встречается у женщин моложе 40 лет, повышение частоты рака у женщин, применяющих
          КОК, незначительно по отношению к общему риску возникновения рака молочной железы.Причинно-следственная связь
          с применением КОК не выявлена. </P></LI>
        <LI><P>Опухоли печени (доброкачественные и злокачественные).</P></LI>
      </UL>
      <P><b>Прочие состояния</b></P>
      <UL>
        <LI><P>Женщины с гипертриглицеридемией (повышенный риск панкреатита при применении КОК).</P></LI>
        <LI><P>Повышение артериального давления.</P></LI>
        <LI><P>
          Наступление или ухудшение состояний, при которых связь с применением КОК не является неоспоримой: желтуха
          и/или зуд, связанные с холестазом; образование камней желчного пузыря; порфирия; системная красная волчанка;
          гемолитико- уремический синдром; хорея Сиденгама; герпес во время беременности; потеря слуха, связанная с
          отосклерозом.
        </P></LI>
        <LI><P>У женщин с наследственным ангионевротическим отеком экзогенные эстрогены могут вызвать или усилить
          симптомы ангионевротического отека.</P></LI>
        <LI><P>Нарушения функции печени.</P></LI>
        <LI><P>Нарушения толерантности к глюкозе или влияние на периферическую инсулинорезистентность.</P></LI>
        <LI><P>Болезнь Крона, язвенный колит.</P></LI>
        <LI><P>Хлоазма</P></LI>
      </UL>

      <H2 id={'over-dozing'}>Передозировка</H2>
      <P>
        О серьезных нарушениях при передозировке не сообщалось. Симптомы, которые могут отмечаться при передозировке:
        тошнота, рвота, «мажущие» кровянистые выделения. Специфического антидота нет, следует проводить симптоматическое
        лечение.
      </P>

      <H2 id={'another-medicines'}>Взаимодействие с другими лекарственными средствами</H2>
      <P><b>Влияние других лекарственных средств на препарат Микрогинон®</b></P>
      <P>Возможно взаимодействие с лекарственными средствами, индуцирующими микросомальные ферменты, в результате чего
        может увеличиваться клиренс половых гормонов, что, в свою очередь, может приводить к «прорывным» маточным
        кровотечениям и/или снижению контрацептивного эффекта.</P>
      <P>Женщинам, которые получают лечение препаратами-индукторами микросомальных ферментов в дополнение к препарату
        Микрогинон®, рекомендуется временно использовать барьерный метод контрацепции или выбрать иной негормональный
        метод контрацепции. Барьерный метод контрацепции следует использовать в течение всего периода приема
        сопутствующих препаратов и еще в течение 28 дней после их отмены. Если применение препарата-индуктора
        микросомальных ферментов печени продолжается после приема последней таблетки препарата Микрогинон® из текущей
        упаковки, следует начать прием таблеток из новой упаковки без обычного перерыва в приеме таблеток.</P>
      <P>Вещества, увеличивающие клиренс препарата Микрогинон® (ослабляющие эффективность путем индукции ферментов):
        фенитоин, барбитураты, примидон, карбамазепин, рифампицин и, возможно, также окскарбазепин, топирамат, фелбамат,
        гризеофульвин, а также препараты, содержащие Зверобой продырявленный.</P>
      <P>Вещества с различным влиянием на клиренс препарата Микрогинон® При совместном применении с препаратом
        Микрогинон® многие ингибиторы протеаз ВИЧ или вируса гепатита С и ненуклиозидные ингибиторы обратной
        транскриптазы могут как увеличивать, так и уменьшать концентрацию эстрогенов или прогестинов в плазме крови. В
        некоторых случаях такое влияние может быть клинически значимо.</P>
      <P>Вещества, снижающие клиренс препарата Микрогинон® (ингибиторы ферментов) Сильные и умеренные ингибиторы CYP3A4,
        такие как азольные антимикотики (например, итраконазол, вориконазол, флуконазол), верапамил, макролиды
        (например, кларитромицин, эритромицин), дилтиазсм и грейпфрутовый сок могут повышать плазменные концентрации
        эстрогена или прогестина, или их обоих.</P>
      <P>Было показано, что эторикоксиб в дозах 60 и 120 мг/сутки при совместном приеме с КОК, содержащими 0,035 мг
        этинилэстрадиола, повышает концентрацию этииилэстрадиола в плазме крови в 1,4 и 1,6 раза, соответственно.</P>
      <P><b>Влияние КОК на другие лекарственные препараты</b></P>
      <P>КОК могут влиять на метаболизм других препаратов, что приводит к повышению (например, циклоспорин) или снижению
        (например, ламотриджин) их концентрации в плазме крови и тканях.</P>
      <P>
        In vitro этинилэстрадиол является обратимым ингибитором CYP2C19, CYP1A1 и CYP1A2, а также необратимым
        ингибитором CYP3A4/5, CYP2C8 и CYP2J2. В клинических исследованиях назначение гормонального контрацептива,
        содержащего этинилэстрадиол, не приводило к какому-либо повышению или приводило лишь к слабому повышению
        концентраций субстратов CYP3A4 в плазме крови (например, мидазолама), в то время как концентрации субстратов
        CYP1A2 в плазме крови могут возрастать слабо (например, теофиллин) или умеренно (например, мелатонин и
        тизанидин).
      </P>

      <H2 id={'special-instructions'}>Особые указания</H2>
      <P>Если какие-либо* из состояний, заболеваний или факторов риска, указанных ниже, имеются в настоящее время, то
        следует тщательно взвешивать потенциальный риск и ожидаемую пользу применения КОК, в том числе препарата
        Микрогинон®, в каждом индивидуальном случае и обсудить его с женщиной до того, как она решит начать прием
        препарата. В случае утяжеления, усиления или первого проявления любого из этих состояний или факторов риска,
        женщина должна проконсультироваться со своим врачом для решения вопроса о прекращении приема препарата.</P>
      <P><b>Заболевания сердечно-сосудистой системы</b></P>
      <P>Результаты эпидемиологических исследований указывают на наличие взаимосвязи между применением КОК и повышением
        частоты развития венозных и артериальных тромбозов и тромбоэмболий (таких как тромбоз глубоких вен,
        тромбоэмболия легочной артерии, инфаркт миокарда, цереброваскулярные нарушения). Данные заболевания отмечаются
        редко.</P>
      <P>Риск развития венозной тромбоэмболии (ВТЭ) максимален в первый год приема таких препаратов. Повышенный риск
        присутствует после первоначального применения КОК или возобновления применения одного и того же или разных КОК
        (после перерыва между приемами препарата в 4 недели и более). Данные крупного проспективного исследования с
        участием 3 групп пациенток показывают, что этот повышенный риск присутствует преимущественно в течение первых 3
        месяцев.</P>
      <P>Общий риск ВТЭ у пациенток, принимающих низкодозированные КОК (содержащие менее 0,05 мг этинилэстрадиола) в
        два-три раза выше, чем у небеременных пациенток, которые не принимают КОК, тем не менее, этот риск остается
        более низким по сравнению с риском ВТЭ при беременности и родах.</P>
      <P>ВТЭ может оказаться жизнеугрожающей или привести к летальному исходу (в 1-2 % случаев).</P>
      <P>ВТЭ, проявляющаяся в виде тромбоза глубоких вен или тромбоэмболии легочной артерии, может произойти при
        применении любых КОК.</P>
      <P>Крайне редко при применении КОК возникает тромбоз других кровеносных сосудов, например, печеночных, брыжеечных,
        почечных, мозговых вен и артерий или сосудов сетчатки.</P>
      <P>Симптомы тромбоза глубоких вен (ТГВ): односторонний отек нижней конечности или по ходу вены на нижней
        конечности, боль или дискомфорт в нижней конечности только в вертикальном положении или при ходьбе, локальное
        повышение температуры, покраснение или изменение окраски кожных покровов в пораженной нижней конечности.</P>
      <P>Симптомы тромбоэмболии легочной артерии (ТЭЛА): затрудненное или учащенное дыхание; внезапный кашель, в том
        числе с кровохарканием; острая боль в грудной клетке, которая может усиливаться при глубоком вдохе; чувство
        тревоги; сильное головокружение; учащенное или нерегулярное сердцебиение. Некоторые из этих симптомов (например,
        одышка, кашель) являются неспецифическими и могут быть истолкованы неверно как признаки других более или менее
        тяжелых осложнений (например, инфекция дыхательных путей).</P>
      <P>Артериальная тромбоэмболия может привести к инсульту, окклюзии сосудов или инфаркту миокарда. Симптомы
        инсульта: внезапная слабость или потеря чувствительности в области лица, конечностей, особенно с одной стороны
        тела, внезапная спутанность сознания, проблемы с речью и пониманием; внезапная одно- или двухсторонняя потеря
        зрения; внезапное нарушение походки, головокружение, потеря равновесия или координации движений; внезапная
        сильная или продолжительная головная боль без видимой причины; потеря сознания или обморок с эпилептическим
        припадком или без него. Другие признаки окклюзии сосудов: внезапная боль, отечность и слабое посинение
        конечностей, «острый» живот.</P>
      <P>Симптомы инфаркта миокарда: боль, дискомфорт, давление, тяжесть, чувство сжатия или распирания в груди или за
        грудиной, с иррадиацией в спину, челюсть, верхнюю конечность, область эпигастрия; холодный пот, тошнота, рвота
        или головокружение, сильная слабость, тревога или одышка; учащенное или нерегулярное сердцебиение.</P>
      <P>Артериальная тромбоэмболия может оказаться жизнеугрожающей или привести к летальному исходу.</P>
      <P>У женщин с сочетанием нескольких факторов риска или высокой выраженностью одного из них следует рассматривать
        возможность их взаимоусиления. В подобных случаях степень повышения риска может оказаться более высокой, чем при
        простом суммировании факторов. В этом случае прием препарата Микрогинон® противопоказан (см. раздел
        «Противопоказания»).</P>
      <P>Риск развития тромбоза (венозного и/или артериального) и тромбоэмболии или цереброваскулярных нарушений
        повышается:</P>
      <UL>
        <LI><P>с возрастом;</P></LI>
        <LI><P>у курящих (с увеличением количества сигарет или повышением возраста риск нарастает, особенно у женщин
          старше 35 лет);
        </P></LI>
        <LI><P>
          при наличии семейного анамнеза (например, венозной или артериальной тромбоэмболии когда-либо у близких
          родственников или родителей в относительно молодом возрасте). В случае наследственной или приобретенной
          предрасположенности, женщина должна быть осмотрена соответствующим специалистом для решения вопроса о
          возможности приема КОК;
        </P></LI>
        <LI><P>при ожирении (индекс массы тела более чем 30 кг/м2);
        </P></LI>
        <LI><P>при дислипопротеинемии;
        </P></LI>
        <LI><P>при артериальной гипертензии;
        </P></LI>
        <LI><P>при мигрени;
        </P></LI>
        <LI><P>при заболеваниях клапанов сердца;
        </P></LI>
        <LI><P>при фибрилляции предсердий;
        </P></LI>
        <LI><P>в случае длительной иммобилизации, серьезного хирургического вмешательства, любой операции на нижних
          конечностях или обширной травмы. В этих ситуациях рекомендуется прекратить применение КОК (в случае
          планируемой операции, по крайней мере, за четыре недели до нее) и не возобновлять прием в течение двух недель
          после окончания иммобилизации. Временная иммобилизация (например, авиаперелет длительностью более 4 часов)
          может также являться фактором риска развития венозной тромбоэмболии, особенно при наличии других факторов
          риска.
        </P></LI>
      </UL>
      <P>Вопрос о возможной роли варикозного расширения вен и поверхностного тромбофлебита в развитии ВТЭ остается
        спорным.
      </P>
      <P>Следует учитывать повышенный риск развития тромбоэмболий в послеродовом периоде.
      </P>
      <P>Нарушения периферического кровообращения также могут отмечаться при сахарном диабете, системной красной
        волчанке, гемолитико-уремическом синдроме, хронических воспалительных заболеваниях кишечника (болезнь Крона или
        язвенный колит) и серповидно-клеточной анемии.
      </P>
      <P>Увеличение частоты и тяжести мигрени (что может предшествовать цереброваскулярным нарушениям) во время
        применения КОК может быть основанием для немедленного прекращения приема этих препаратов.
      </P>
      <P>К биохимическим показателям, указывающим на наследственную или приобретенную предрасположенность к развитию
        венозного или артериального тромбоза относятся: резистентность к активированному протеину С,
        гипергомоцистеинемия, дефицит антитромбина III, дефицит протеина С, дефицит протеина S, антифосфолипидные
        антитела (антитела к кардиолипину, волчаночный антикоагулянт).
      </P>
      <P>При оценке соотношения риска и пользы следует учитывать, что адекватное лечение соответствующего состояния
        может уменьшить связанный с ним риск тромбоза. Также следует учитывать, что риск тромбозов и тромбоэмболий при
        беременности выше, чем при приёме иизкодозированных КОК (содержащих менее 0,05 мг этипилэстрадиола).
      </P>
      <P><b>Опухоли</b></P>
      <P>Наиболее существенным фактором риска развития рака шейки матки, является персистирующая папилломавирусная
        инфекция. Имеются сообщения о некотором повышении риска развития рака шейки матки при длительном применении КОК.
        Связь с приемом КОК не доказана. Сохраняются противоречия относительно того, в какой степени эти данные связаны
        со скринингом на предмет патологии шейки матки или с особенностями полового поведения (более редкое применение
        барьерных методов контрацепции).
      </P>
      <P>Мета-анализ 54 эпидемиологических исследований показал, что имеется несколько повышенный относительный риск
        развития рака молочной железы, диагностированного у женщин, принимающих КОК в настоящее время (относительный
        риск 1,24). Повышенный риск постепенно исчезает в течение 10 лет после прекращения приёма этих препаратов. В
        связи с тем, что рак молочной железы отмечается редко у женщин до 40 лет, увеличение количества случаев рака
        молочной железы у женщин, принимающих КОК в настоящее время или принимавших недавно, является незначительным по
        отношению к общему риску этого заболевания. Его связь с приемом КОК не доказана. Наблюдаемое повышение риска
        может быть также следствием более ранней диагностики рака молочной железы у женщин, применяющих КОК (у них
        диагностируются более ранние клинические формы рака молочной железы, чем у женщин, не применявших КОК),
        биологическим действием КОК или сочетанием обоих этих факторов.
      </P>
      <P>В редких случаях на фоне применения КОК наблюдалось развитие доброкачественных, а в крайне редких -
        злокачественных опухолей печени, которые в отдельных случаях приводили к угрожающему жизни внутрибрюшному
        кровотечению. В случае появления сильных болей в области живота, увеличения печени или признаков внутрибрюшного
        кровотечения это следует учитывать при проведении дифференциального диагноза.
      </P>
      <P><b>Другие состояния</b></P>
      <P>У женщин с гипертриглицеридемией (или наличием этого состояния в семейном анамнезе) во время приема КОК
        возможно повышение риска развития панкреатита.
      </P>
      <P>Несмотря па то, что незначительное повышение артериального давления было описано у многих женщин, принимающих
        КОК, клинически значимое повышение отмечалось редко. Тем не менее, если во время приема КОК развивается стойкое
        клинически значимое повышение артериального давления, следует отменить эти препараты и начать лечение
        артериальной гипертензии. Прием КОК может быть продолжен, если с помощью гипотензивной терапии достигнуты
        нормальные значения артериального давления.
      </P>
      <P>Следующие состояния, как сообщалось, развиваются или ухудшаются как во время беременности, так и при приеме
        КОК, но их связь с приемом КОК не доказана: холестатическая желтуха и/или зуд, связанный с холестазом;
        формирование камней в желчном пузыре; порфирия; системная красная волчанка; гемолитико-уремический синдром;
        хорея Сиденгама; герпес во время беременности; потеря слуха, связанная с отосклерозом. Также описаны случаи
        болезни Крона и язвенного колита на фоне применения КОК.
      </P>
      <P>У женщин с наследственными формами ангионевротического отека экзогенные эстрогены могут вызывать или ухудшать
        симптомы ангионевротического отека.
      </P>
      <P>Острые или хронические нарушения функции печени могут потребовать отмены КОК до тех пор, пока показатели
        функции печени не вернутся в норму. Рецидив холестатической желтухи, развившейся впервые во время предшествующей
        беременности или предыдущего приема половых гормонов, требует прекращения приема КОК.
      </P>
      <P>Хотя КОК могут оказывать влияние на инсулинорезистентность и толерантность к глюкозе, как правило, нет
        необходимости в коррекции дозы гипогликемических препаратов у пациенток с сахарным диабетом, применяющих
        низкодозированные КОК (содержащие менее 0,05 мг этинилэстрадиола). Тем не менее, женщины с сахарным диабетом
        должны тщательно наблюдаться во время приема КОК.
      </P>
      <P>Иногда может развиваться хлоазма, особенно у женщин с наличием в анамнезе хлоазмы беременных. Женщины со
        склонностью к хлоазме во время приема КОК должны избегать длительного пребывания на солнце и воздействия
        ультрафиолетового излучения.
      </P>
      <P><b>Лабораторные тесты</b></P>
      <P>Применение таких препаратов, как Микрогинон®, может влиять на результаты некоторых лабораторных тестов, включая
        биохимические показатели функции печени, щитовидной железы, почек и надпочечников, концентрацию транспортных
        протеинов в плазме (например, глобулин, связывающий кортикостероиды, фракции липидов/липопротеидов, параметры
        углеводного метаболизма, коагуляции и фибринолиза). Эти изменения, как правило, остаются в пределах нормальных
        физиологических значений.
      </P>
      <P><b>Снижение эффективности</b></P>
      <P>Эффективность КОК может быть снижена в следующих случаях: при пропуске таблеток, желудочно-кишечных
        расстройствах или в результате лекарственного взаимодействия.
      </P>
      <P><b>Влияние на характер кровотечения</b></P>
      <P>На фоне приема КОК могут отмечаться нерегулярные кровотечения («мажущие» кровянистые выделения и/или
        «прорывные» кровотечения), особенно в течение первых месяцев применения. Поэтому оценка любых нерегулярных
        кровотечений должна проводиться только после периода адаптации, составляющего приблизительно три цикла.
      </P>
      <P>Если нерегулярные кровотечения повторяются или развиваются после предшествующих регулярных циклов, следует
        провести тщательное обследование для исключения злокачественных новообразований или беременности.
      </P>
      <P>У некоторых женщин во время перерыва в приеме таблеток может не развиться кровотечение «отмены». Если препарат
        Микрогинон® принимался согласно указаниям, маловероятно, что женщина беременна. Тем не менее, если до этого
        препарат Микрогинон® принимался нерегулярно, или если отсутствуют подряд два кровотечения «отмены», до
        продолжения приема препарата должна быть исключена беременность.
      </P>
      <P><b>Медицинские осмотры</b></P>
      <P>Перед началом или возобновлением приема препарата Микрогинон® необходимо ознакомиться с анамнезом жизни,
        семейным анамнезом женщины, провести общемедицинское (включая измерение артериального давления, определение
        индекса массы тела) и гинекологическое обследование (включая исследование молочных желез и цитологическое
        исследование эпителия шейки матки), исключить беременность. Частота и характер таких обследований должны
        основываться на существующих нормах медицинской практики при необходимом учете индивидуальных особенностей
        каждой пациентки (но не реже чем 1 раз в 6 месяцев).
      </P>
      <P>Необходимо помнить, что препарат Микрогинон® не предохраняет от ВИЧ-инфекции (СПИД) и других заболеваний,
        передающихся половым путем!
      </P>
      <P><b><u>Состояния, требующие консультации врача</u></b></P>
      <UL>
        <LI><P>Какие-либо изменения в здоровье, особенно возникновение состояний, перечисленных в разделах
          «Противопоказания» и «Применение с осторожностью»;
        </P></LI>
        <LI><P>Локальное уплотнение в молочной железе;
        </P></LI>
        <LI><P>Одновременный прием других лекарственных препаратов (см. также «Взаимодействие с другими лекарственными
          средствами»);
        </P></LI>
        <LI><P>Если ожидается длительная иммобилизация (например, на нижнюю конечность наложен гипс), планируется
          госпитализация или операция (по крайней мере, за четыре недели до предполагаемой операции);
        </P></LI>
        <LI><P>Необычно сильное кровотечение из влагалища;
        </P></LI>
        <LI><P>Пропущена таблетка в первую неделю приёма препарата и был половой контакт за семь или менее дней до
          этого;
        </P></LI>
        <LI><P>Отсутствие очередного менструальноподобного кровотечения два раза подряд или подозрение на беременность
          (не следует начинать приём таблеток из следующей упаковки до консультации с врачом).
        </P></LI>
      </UL>
      <P>Следует прекратить приём таблеток и немедленно проконсультироваться с врачом, если имеются возможные признаки
        тромбоза, инфаркта миокарда или инсульта: необычный кашель; необычно сильная боль за грудиной, отдающая в левую
        руку; неожиданно возникшая одышка, необычная, сильная и длительная головная боль или приступ мигрени; частичная
        или полная потеря зрения или двоение в глазах; нечленораздельная речь; внезапные изменения слуха, обоняния или
        вкуса; головокружение или обморок; слабость или потеря чувствительности в любой части тела; сильная боль в
        животе; сильная боль в нижней конечности или внезапно возникший отёк любой из нижних конечностей.</P>

      <H2 id={'managment-transport'}>
        Влияние на способность управления транспортными
        средствами, механизмами
      </H2>
      <P>
        Не выявлено.
      </P>

      <H2 id={'form-production'}>Форма выпуска</H2>
      <P>
        Таблетки покрытые оболочкой, 150 мкг + 30 мкг. По 21 таблетке в блистер из ПВХ и алюминиевой фольги. По одному
        или три блистера вместе с инструкцией по применению помещают в картонную пачку.
      </P>

      <H2 id={'keeper'}>Условия хранения</H2>
      <P>Хранить при температуре не выше 30 C</P>
      <P>Хранить в недоступном для детей месте.</P>

      <H2 id={'deadline'}>Срок годности</H2>
      <P>5 лет.</P>
      <P>Не использовать по истечении срока годности.</P>

      <H2 id={'holiday'}>Условия отпуска</H2>
      <P>Отпускают по рецепту.</P>

      <H2 id={'owner'}>Владелец (держатель) Регистрационного удостоверения</H2>
      <P>Байер Фарма АГ, Мюллерштрассе 178, 13353 Берлин, Германия</P>
      <P>Bayer Pharma AG, Mullerstrasse 178, 13353 Berlin, Germany</P>

      <H2 id={'producer'}>Производитель</H2>
      <P>
        Байер Веймар ГмбХ и Ко. КГ, Деберайнерштрассе 20, D-99427 Веймар, Германия<br/> Bayer Weimar GmbH & Со. KG,
        Dobereinerstrasse 20, D-99427 Weimar, Germany
      </P>
      <P><b>Организация, принимающая претензии потребителей</b></P>
      <P>107113 Москва, 3-я Рыбинская ул., д. 18 стр. 2; Тел.:+7 (495) 231 12 00; Факс:+7 (495) 231 12 02</P>
      <P>www.bayerpharma.ru</P>

      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Инструкция PDF
      </a>
    </Page>
  )
}
