import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanSecondLinkSix = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>Congress Report from 2nd Society of Endometriosis and Uterine Disorders
        Congress</H1>
      <P>Adenomyosis was the main topic in the 2016 Society of Endometriosis and Uterine Disorders (SEUD) Congress.
        Latest evidence in endometriosis and other uterine disorders, such as fibroids and abnormal uterine bleeding
        (AUB) were also covered. About 106 oral communications, practical sessions, and debates on etiology,
        pathogenesis, diagnosis and treatment of the different themes were presented. Posters on the same topics were on
        view. Live surgical sessions and video sessions were also held. Six “tips and tricks” sessions in endometriosis
        and uterine disorders were scheduled at the beginning of each day throughout the congress. Monographic
        own-language sessions comprising Canadian, Spanish, Asian, Russian and Latin American participants were held
        with presenters contributing their own vision of the congress topics. Finally, the congress ended with a patient
        session: dedicated to those who are affected by endometriosis: pain, quality of life, sex-life, and environment
        were issues covered by specialists and the testimonies of the patients also had their place in the congress.
      </P>
      <P>
        [ссылка: <A
        href="https://www.researchgate.net/publication/306031255_Congress_Report_from_2nd_Society_of_Endometriosis_and_Uterine_Disorders_Congress">2nd
        Congress of the Society of Endometriosis and Uterine Disorders. Barcelona, Spain, 12–14th May 2016</A>]
      </P>
    </Page>
  )
}
