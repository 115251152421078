import {useHistory} from 'react-router-dom';

import {WrapperShell} from 'components/wrapper-shell';
import {WrapperCouple} from 'components/wrapper-couple';
import Page from 'shared/ui/Page';
import {List as UL, ListItem as LI, Tables} from 'shared/ui';
import {PinkLight} from 'styles/color';

import {StyledH2 as H2, StyledH1 as H1, StyledParagraph as P, LinkA as A} from './ui';
import {Span, Div} from '../ui';
import pdf from '../../../pdfs/velledien/PIL_Инструкция_Велледиен.pdf';

export const VelledienInstruction = () => {
  const history = useHistory();

  const handlerRedirectToDiecyclen = () => {
    history.push('/product/velledien');
  }

  return (
    <Page onClose={handlerRedirectToDiecyclen} isCross>
      <Div sx={{textAlign: 'center'}}>
        <H2 sx={{fontSize: 20, mb: 3}}>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</H2>
        <H1>ИНСТРУКЦИЯ</H1>
        <H2 sx={{fontSize: 20, textTransform: 'none', mb: 3, mt: 2}}>по применению лекарственного препарата для
          медицинского применения</H2>
        <H1 sx={{marginBottom: 20}}>ВЕЛЛЕДИЕН</H1>
      </Div>
      <H2 sx={{fontSize: 20}}>РЕГИСТРАЦИОННЫЙ НОМЕР:</H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ТОРГОВОЕ НАИМЕНОВАНИЕ: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>Велледиен</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        МЕЖДУНАРОДНОЕ НЕПАТЕНТОВАННОЕ НАИМЕНОВАНИЕ: <Span
        sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>Тиболон</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ЛЕКАРСТВЕННАЯ ФОРМА: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>таблетки</Span>
      </H2>

      <H2 style={{marginTop: 50}}>СОДЕРЖАНИЕ</H2>
      <WrapperCouple sx={{display: 'flex', justifyContent: 'space-between'}}>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#compound'}>СОСТАВ</A>
          <A href={'#description'}>ОПИСАНИЕ</A>
          <A href={'#farm-group'}>ФАРМАКОТЕРАПЕВТИЧЕСКАЯ ГРУППА</A>
          <A href={'#farm-property'}>ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА</A>
          <A href={'#ind-for-use'}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ</A>
          <A href={'#dication'}>ПРОТИВОПОКАЗАНИЯ</A>
          <A href={'#warning'}>С ОСТОРОЖНОСТЬЮ</A>
          <A href={'#for-use-feed'}>ПРИМЕНЕНИЕ В ПЕРИОД БЕРЕМЕННОСТИ И ГРУДНОГО<br/> ВСКАРМЛИВАНИЯ</A>
          <A href={'#dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</A>
          <A href={'#effect-action'}>ПОБОЧНОЕ ДЕЙСТВИЕ</A>
          <A href={'#over-dozing'}>ПЕРЕДОЗИРОВКА</A>
        </WrapperShell>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#another-medicines'}>ВЗАИМОДЕЙСТВИЕ С ДРУГИМИ ЛЕКАРСТВЕННЫМИ <br/> СРЕДСТВАМИ</A>
          <A href={'#special-instructions'}>ОСОБЫЕ УКАЗАНИЯ</A>
          <A href={'#managment-transport'}>ВЛИЯНИЕ НА СПОСОБНОСТЬ УПРАВЛЕНИЯ <br/>ТРАНСПОРТНЫМИ СРЕДСТВАМИ,
            МЕХАНИЗМАМИ</A>
          <A href={'#form-production'}>ФОРМА ВЫПУСКА</A>
          <A href={'#keeper'}>УСЛОВИЯ ХРАНЕНИЯ</A>
          <A href={'#deadline'}>СРОК ГОДНОСТИ</A>
          <A href={'#holiday'}>УСЛОВИЯ ОТПУСКА</A>
          <A href={'#owner'}>ВЛАДЕЛЕЦ (ДЕРЖАТЕЛЬ) РЕГИСТРАЦИОННОГО<br/>УДОСТОВЕРЕНИЯ</A>
          <A href={'#producer'}>ПРОИЗВОДИТЕЛЬ</A>
        </WrapperShell>
      </WrapperCouple>

      <H2 id={'compound'}>СОСТАВ</H2>
      <P>Каждая таблетка содержит:</P>
      <P>Действующее вещество: Тиболон - 2,50 мг;</P>
      <P>Вспомогательные вещества: лактозы моногидрат - 69,44 мг, целлюлоза микрокристаллическая - 17,36 мг, аскорбил
        пальмитат - 0,20 мг, крахмал кукурузный - 10,00 мг, магния стеарат- 0,50 мг.</P>

      <H2 id={'description'}>ОПИСАНИЕ</H2>
      <P>Круглые, плоскоцилиндрические таблетки белого цвета.</P>

      <H2 id={'farm-group'}>Фармакотерапевтическая группа: </H2>
      <P>прочие эстрогены.</P>
      <P><b>Код ATX</b>: G03CX01</P>

      <H2 id={'farm-property'}>Фармакологические свойства</H2>
      <P><b>Фармакодинамика</b></P>
      <P>
        Препарат избирательно регулирует эстрогеноподобную активность в тканях и является тканеселективным регулятором.
        Его фармакодинамические свойства определяются действием трех фармакологически активных метаболитов тиболона:
        3-альфа-гидрокситиболон и 3-бета-гидрокситиболон обладают эстрогеноподобной активностью, дельта-4-изомеру
        свойственна прогестагеноподобная и слабая андрогеноподобная активность. Препарат восполняет дефицит эстрогенов в
        постменопаузальном периоде, облегчая связанные с их дефицитом симптомы - «приливы», депрессия, повышенное
        потоотделение ночью, головная боль. Положительно влияет на либидо и настроение (повышает концентрацию
        центральных и периферических опиоидов). Оказывает трофическое действие па слизистую оболочку влагалища, не
        вызывая пролиферацию эндометрия. Предотвращает потерю костной массы после наступления менопаузы или удаления
        яичников. Снижает концентрацию фосфатов и кальция в плазме крови.
      </P>
      <P><b>Фармакокинетика</b></P>
      <P>
        После приема внутрь тиболон быстро и интенсивно всасывается. Прием пищи не оказывает заметного влияния на
        всасывание препарата.
      </P>
      <P>
        В результате быстрого метаболизма тиболона его концентрация в плазме крови очень низкая. Концентрация
        дельта-4-изомера в плазме крови также очень низкая, поэтому ряд фармакокинетических параметров невозможно
        определить. Максимальные концентрации в плазме крови метаболитов 3-альфа-гидрокситиболона (Зɑ-ОН) и 3-бета-
        гидрокситиболона (З-β-OH) выше, но кумуляции не происходит.
      </P>

      <P sx={{mt: 4}}><b>Таблица 1. Фармакокинетические параметры тиболона (доза 2,5 мг)</b></P>

      <Tables.WrapperTable>
        <Tables.Table className="align-left" sx={{m: '10px 0', minWidth: 900}}>
          <Tables.Thead sx={{backgroundColor: 'transparent'}}>
            <Tables.Tr sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Bold'}}>
              <Tables.Td/>
              <Tables.Td colSpan={2}>
                <Span sx={{width: 185, display: 'block'}}>Тиболон</Span>
              </Tables.Td>
              <Tables.Td colSpan={2}>
                <Span sx={{width: 185, display: 'block'}}>3-альфа- гидрокситиболон</Span>
              </Tables.Td>
              <Tables.Td colSpan={2}>
                <Span sx={{width: 185, display: 'block'}}>3-бета- гидрокситиболон</Span>
              </Tables.Td>
              <Tables.Td colSpan={2}>
                <Span sx={{width: 185, display: 'block'}}>дельта-4-изомер</Span>
              </Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td sx={{minWidth: 185}}/>
              <Tables.Td>ОД</Tables.Td>
              <Tables.Td>МД</Tables.Td>
              <Tables.Td>ОД</Tables.Td>
              <Tables.Td>МД</Tables.Td>
              <Tables.Td>ОД</Tables.Td>
              <Tables.Td>МД</Tables.Td>
              <Tables.Td>ОД</Tables.Td>
              <Tables.Td>МД</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Максимальная концентрация Сmах (НГ/МЛ)</Tables.Td>
              <Tables.Td>1,37</Tables.Td>
              <Tables.Td>1,72</Tables.Td>
              <Tables.Td> 14,23</Tables.Td>
              <Tables.Td>14,15</Tables.Td>
              <Tables.Td>3,43</Tables.Td>
              <Tables.Td>3,75</Tables.Td>
              <Tables.Td>0,47</Tables.Td>
              <Tables.Td>0,43</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Средняя концентрация Ссредняя</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>1,88</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Время достижения максимальной концентрации Тmах (ч)</Tables.Td>
              <Tables.Td>1,08</Tables.Td>
              <Tables.Td>1,19</Tables.Td>
              <Tables.Td>1,21</Tables.Td>
              <Tables.Td>1,15</Tables.Td>
              <Tables.Td>1,37</Tables.Td>
              <Tables.Td>1,35</Tables.Td>
              <Tables.Td>1,64</Tables.Td>
              <Tables.Td>1,65</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Период полувыведения Т1/2 (ч)</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>5,78</Tables.Td>
              <Tables.Td>7,71</Tables.Td>
              <Tables.Td>5,87</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Минимальная концентрация Cmin (НГ/МЛ)</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>0,23</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Площадь под кривой AUC0-24 (НГ/МЛХЧ)</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>53,23</Tables.Td>
              <Tables.Td>44,73</Tables.Td>
              <Tables.Td>16,23</Tables.Td>
              <Tables.Td>9,20</Tables.Td>
              <Tables.Td>—</Tables.Td>
              <Tables.Td>—</Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P>ОД - однократная доза; МД - многократная доза.</P>
      <P>
        Выведение тиболона в основном происходит в форме конъюгированных метаболитов (в основном сульфатированных).
        Часть принятого тиболона выводится почками, большая часть - через кишечник.
      </P>
      <P> Фармакокинетические параметры тиболона не зависят от функции почек. </P>

      <H2 id={'ind-for-use'}>Показания к применению</H2>
      <UL>
        <LI><P>
          Лечение симптомов эстрогенной недостаточности у женщин в постменопаузальном периоде (не ранее чем через 1 год
          после последней менструации при наступлении естественной менопаузы или сразу после хирургической менопаузы);
        </P></LI>
        <LI><P>
          профилактика постменопаузального остеопороза у женщин с высоким риском переломов при непереносимости или
          противопоказаниях к применению других лекарственных препаратов, предназначенных для лечения остеопороза.
        </P></LI>
      </UL>

      <H2 id={'dication'}>противоПоказания</H2>
      <UL>
        <LI><P>Беременность и период грудного вскармливания;</P></LI>
        <LI><P>диагностированный (в том числе, в анамнезе) рак молочной железы или подозрение па пего и
          диагностированные (в том числе, в анамнезе) эстрогензависимые злокачественные опухоли (например, рак
          эндометрия) или подозрение на них;</P></LI>
        <LI><P>кровотечение из влагалища неясной этиологии;</P></LI>
        <LI><P>тромбозы (венозные и артериальные) и тромбоэмболии в настоящее время или в анамнезе (в том числе, тромбоз
          и тромбофлебит глубоких вен, тромбоэмболия легочной артерии), ишемическая болезнь сердца, инфаркт миокарда,
          ишемические и геморрагические цереброваскулярные нарушения;</P></LI>
        <LI><P>состояния, предшествующие тромбозу (в том числе, транзиторные ишемические атаки, стенокардия в настоящее
          время или в анамнезе);</P></LI>
        <LI><P>выявленная предрасположенность к венозному или артериальному тромбозу, включая резистентность к
          активированному протеину С, дефицит протеина С. дефицит протеина S или антитромбина III, антитела к
          фосфолипидам (антитела к кардиолипину, волчаночный антикоагулянт);</P></LI>
        <LI><P>множественные или выраженные факторы риска венозного или артериального тромбоза, в том числе осложненные
          поражения клапанного аппарата сердца, фибрилляция предсердий, заболевания сосудов головного мозга или
          коронарных артерий; неконтролируемая артериальная гипертензия;</P></LI>
        <LI><P>расширенное оперативное вмешательство с длительной иммобилизацией, обширная травма, курение в возрасте
          старше 35 лет, ожирение с индексом массы тела {''}30 кг/м2;</P></LI>
        <LI><P>злокачественные или доброкачественные опухоли (в том числе - аденома печени); в настоящее время или в
          анамнезе;</P></LI>
        <LI><P>печеночная недостаточность, острое заболевание печени или заболевание печени в анамнезе, после которого
          показатели функциональных проб печени не нормализовались;</P></LI>
        <LI><P>повышенная чувствительность к препарату или любому из его компонентов;</P></LI>
        <LI><P>порфирия;</P></LI>
        <LI><P>отосклероз, возникший во время предыдущей беременности или при применении гормональных контрацептивных
          препаратов в анамнезе;</P></LI>
        <LI><P>
          хроническая сердечная недостаточность (III-IV ФК), цереброваскулярные расстройства;</P></LI>
        <LI><P>
          период менее 1 года после последней менструации;</P></LI>
        <LI><P>
          нелеченая гиперплазия эндометрия;</P></LI>
        <LI><P>
          редкие наследственные заболевания: непереносимость галактозы, недостаточность лактазы или глюкозо-галактозная
          мальабсорбция.</P></LI>
      </UL>

      <H2 id={'warning'}>С осторожностью</H2>
      <P>Если какие-либо из приведенных ниже состояний/заболеваний имеются в настоящее время, наблюдались ранее и/или
        обострялись во время беременности или предыдущей гормонотерапии, пациентка должна находиться под тщательным
        наблюдением врача.</P>
      <P>Следует принимать во внимание, что эти состояния/заболевания могут рецидивировать или обостряться во время
        лечения препаратом Велледиен, в частности:</P>
      <UL>
        <LI><P>лейомиома (фиброма матки) или эндометриоз;</P></LI>
        <LI><P>сердечно-сосудистая недостаточность без признаков декомпенсации;</P></LI>
        <LI><P>наличие факторов риска эстрогензависимых опухолей (например, рак молочной железы у родственников первой
          степени родства);</P></LI>
        <LI><P>контролируемая артериальная гипертензия;</P></LI>
        <LI><P>гиперхолестеринемия;</P></LI>
        <LI><P>нарушения углеводного обмена, сахарный диабет, как при наличии, так и при отсутствии осложнений;</P></LI>
        <LI><P>желчнокаменная болезнь;</P></LI>
        <LI><P>мигрень или сильная головная боль;</P></LI>
        <LI><P>системная красная волчанка;</P></LI>
        <LI><P>гиперплазия эндометрия в анамнезе;</P></LI>
        <LI><P>эпилепсия;</P></LI>
        <LI><P>бронхиальная астма;</P></LI>
        <LI><P>почечная недостаточность;</P></LI>
        <LI><P>отосклероз, не связанный с беременностью или предшествующим применением гормональных контрацептивных
          препаратов.</P></LI>
      </UL>

      <H2 id={'for-use-feed'}>Применение в период беременности и грудного вскармливания</H2>
      <P>Прием препарата противопоказан при беременности и в период грудного вскармливания.</P>

      <H2 id={'dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</H2>
      <P>
        Препарат Велледиен принимают внутрь по 1 таблетке в сутки, не разжевывая, запивая водой, желательно в одно и то
        же время, непрерывно. Первой принимается таблетка из ячейки верхнего ряда, отмеченной днем недели,
        соответствующим дню начала приема. Все остальные таблетки принимаются последовательно из ячеек в направлении
        стрелки на календарной упаковке, до тех пор, пока не будут приняты все таблетки.
      </P>
      <P><b>Начало приема препарата Велледиен</b></P>
      <P>
        Для лечения постменопаузальных симптомов препарат Велледиен необходимо применять только в отношении симптомов,
        неблагоприятно влияющих на качество жизни женщины. Прием препарата Велледиен начинают не ранее, чем через 12
        месяцев после последней менструации при наступлении естественной менопаузы. Пациентки с менопаузой,
        обусловленной оперативным вмешательством, могут начинать прием препарата сразу же. Во всех случаях, не менее 1
        раза в 6 месяцев необходимо проводить тщательную оценку риска и пользы лечения и продолжать применение препарата
        в период времени, когда польза от терапии превышает риск.
      </P>
      <P>
        <b><u>Переход на прием препарата Велледиен после другого препарата для заместительной гормональной терапии
          (ЗГТ)</u></b>
      </P>
      <P>
        Женщинам с интактной маткой, при переходе на прием препарата Велледиен <b>после применения другого препарата
        ЗГТ,
        содержащего только эстрогены</b>, рекомендуется сначала вызвать менструальноподобное кровотечение «отмены» путем
        применения прогестагена для устранения вероятно существующей гиперплазии эндометрия.
      </P>
      <P>
        <b>При переходе с препарата для ЗГТ с циклическим режимом приема</b>, прием препарата Велледиен должен быть
        начат на
        следующий день после завершения применения прогестагена.
      </P>
      <P>
        Если переход осуществляется <b>с комбинированного препарата ЗГТ с непрерывным режимом приема</b>, прием
        препарата
        Велледиен можно начинать в любое время.
      </P>
      <P><b>Нарушение схемы приема препарата</b></P>
      <P>
        Пропущенную таблетку, если с момента пропуска прошло менее 12 ч, женщина должна принять как можно скорее в тот
        же день. Следующая таблетка принимается в обычное время суток.
      </P>
      <P>
        Если опоздание в приеме таблетки составило более 12 ч (интервал с момента приема последней таблетки более 36 ч),
        принимать пропущенную таблетку не нужно, а следующую таблетку необходимо выпить в обычное время.
      </P>

      <H2 id={'effect-action'}>Побочное действие</H2>
      <P>Для определения частоты возникновения побочных эффектов препарата применяют следующую классификацию:</P>
      <P>Очень часто (≥ 1/10)</P>
      <P>Часто (≥ 1/100 и {'<'} 1/10)</P>
      <P>Нечасто (≥ 1/1000 и {'<'} 1/100)</P>
      <P>Редко (≥ 1/10 000 и {'<'} 1/1000)</P>
      <P>Очень редко ({'<'}1/10 000).</P>
      <P><b>Нарушения со стороны желудочно-кишечного тракта:</b> часто - боль внизу живота.</P>
      <P><b>Нарушения со стороны кожи и подкожных тканей:</b> часто - усиление роста волос, в том числе на лице; нечасто
        -
        акне.</P>
      <P>
        Нарушения со стороны репродуктивной системы и молочных желез: часто - выделения из влагалища, увеличение толщины
        эндометрия, кровянистые выделения или кровотечение из влагалища, боль в молочных железах, генитальный зуд,
        кандидозный вульвовагинит, боль в области таза, дисплазия шейки матки, вульвовагинит; нечасто - микоз,
        нагрубание молочных желез, болезненность сосков.
      </P>
      <P>
        <b>Лабораторные и инструментальные данные:</b> нечасто — увеличение массы тела; отклонения результатов мазка из
        шейки
        матки (отклонение от нормальных значений цитологических характеристик цервикального эпителия).
      </P>
      <P>
        Большинство побочных эффектов носило слабовыраженный характер. Число случаев патологии шейки матки (рак шейки
        матки) не увеличивалось при приеме тиболона по сравнению с плацебо.
      </P>
      <P>Другими возможными побочными эффектами могут быть (частота не установлена):</P>
      <UL>
        <LI><P>головокружение, головная боль, мигрень;</P></LI>
        <LI><P>депрессия;</P></LI>
        <LI><P>кожные высыпания, зуд кожи, себорейный дерматит;</P></LI>
        <LI><P>нарушения зрения (включая нечеткость зрения);</P></LI>
        <LI><P>желудочно-кишечные расстройства (диарея, метеоризм);</P></LI>
        <LI><P>задержка жидкости в организме, периферические отеки;</P></LI>
        <LI><P>боль в суставах и мышцах;</P></LI>
        <LI><P>нарушения функции печени (включая повышение активности трансаминаз).</P></LI>
      </UL>

      <P><b><u>Риск развития рака молочной железы</u></b></P>
      <P>У женщин, получающих терапию комбинированными (эстроген/гестаген) препаратами более 5 лет, отмечалось
        двукратное увеличение частоты диагностирования рака молочной железы.</P>
      <P>
        Любой повышенный риск у пациенток, получающих только эстроген или тиболон, существенно ниже, чем риск,
        наблюдаемый у пациенток, получающих терапию комбинированными (эстроген/гестаген) препаратами.
      </P>
      <P>Уровень риска зависит от продолжительности применения (см. раздел «Особые указания»).</P>

      <P sx={{mt: 4}}>
        <b>
          Таблица 2. Предполагаемый дополнительный риск развития рака молочной железы после 5 лет
          применения (по данным «Исследования миллиона женщин»).
        </b>
      </P>

      <Tables.WrapperTable>
        <Tables.Table className="align-left" sx={{m: '10px 0', tableLayout: 'fixed', minWidth: 900}}>
          <Tables.Thead>
            <Tables.Tr sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Bold'}}>
              <Tables.Td sx={{width: 108}}>Возрастная группа (годы)</Tables.Td>
              <Tables.Td sx={{width: 108}}>
                Дополнительные случаи на 1000 пациенток, ранее не получавших ЗГТ, за период 5 лет
              </Tables.Td>
              <Tables.Td sx={{width: 108}}>Отношение риска* (95 % ДИ) </Tables.Td>
              <Tables.Td sx={{width: 108}}>
                Дополнительные случаи на 1000 пациенток, ранее получавших ЗГТ, за период свыше 5 лет (95 % ДИ)
              </Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td colSpan={4}>ЗГТ только эстрогеном</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-65</Tables.Td>
              <Tables.Td>9-12</Tables.Td>
              <Tables.Td>1,2</Tables.Td>
              <Tables.Td>1-2 (0-3)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td colSpan={4}>Терапия комбинированными (эстроген/гестаген) препаратами</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-65</Tables.Td>
              <Tables.Td>9-12</Tables.Td>
              <Tables.Td>1,7</Tables.Td>
              <Tables.Td>6 (5-7)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td colSpan={4}>Тиболон</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-65</Tables.Td>
              <Tables.Td>9-12</Tables.Td>
              <Tables.Td>1,3</Tables.Td>
              <Tables.Td>3 (0-6)</Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P>ДИ - доверительный интервал;</P>
      <P>
        Суммарное отношение рисков. Отношение рисков не является постоянным, оно возрастает с увеличением
        продолжительности применения.
      </P>

      <P><b><u>Риск развития рака эндометрия</u></b></P>
      <P>Риск развития рака эндометрия составляет около 5 случаев на 1000 женщин с неудаленной маткой, не получающих ЗГТ
        или тиболон.</P>
      <P>
        Самый высокий риск развития рака эндометрия наблюдался в рандомизированном плацебо-контролируемом исследовании,
        включавшем женщин, которые на начальном этапе не были обследованы на наличие патологии эндометрия, таким
        образом, дизайн исследования был приближен к условиям клинической практики (исследование LIFT, средний возраст
        68 лет). В данном исследовании не было выявлено случаев рака эндометрия, диагностированного в группе плацебо (n
        = 1746) после наблюдения в течение 2,9 лет, по сравнению с 4 случаями рака эндометрия в группе, получавшей
        тиболон (п = 1746), что соответствует диагностике 0,8 дополнительного случая рака эндометрия па 1000 женщин,
        получавших тиболон в течение 1 года в данном исследовании (см. раздел «Особые указания»).
      </P>
      <P><b><u>Риск развития ишемического инсульта</u></b></P>
      <P>
        Относительный риск развития ишемического инсульта не зависит от возраста или длительности приема препарата, но
        абсолютный риск сильно зависит от возраста. Общий риск развития ишемического инсульта у женщин, принимающих
        тиболон, будет увеличиваться с возрастом (см. радел «Особые указания»).
      </P>
      <P>
        Рандомизированное контролируемое исследование в течение 2,9 лет установило 2,2- кратное увеличение риска
        развития инсульта у женщин (средний возраст 68 лет), принимавших тиболон в дозе 1,25 мг (28/2249) по сравнению с
        плацебо (13/2257). Большинство (80 %) инсультов были ишемическими.
      </P>
      <P>
        Абсолютный риск развития инсульта сильно зависит от возраста. Так абсолютный риск за 5 лет составляет 3 случая
        на 1000 женщин в возрасте 50-59 лет и 11 случаев на 1000 женщин в возрасте 60-69 лет.
      </P>
      <P>
        Для женщин, принимающих тиболон в течение 5 лет, можно ожидать около 4 дополнительных случаев на 1000 пациенток
        в возрасте 50-59 лет и 13 дополнительных случаев на 1000 пациенток в возрасте 60-69 лет.
      </P>
      <P>
        Были отмечены и другие нежелательные явления, связанные с применением препаратов для ЗГТ, содержащих только
        эстроген, и комбинированными (эстроген/гестаген) препаратами:
      </P>
      <UL>
        <LI><P>
          продолжительное применение препаратов для ЗГТ, содержащих только эстроген, и комбинированных препаратов,
          содержащих эстроген/гестаген, было связано с незначительным повышением риска развития рака яичников. По данным
          «Исследования миллиона женщин» ЗГТ в течение 5 лет приводила к 1 дополнительному случаю рака на 2500
          пациенток. Это исследование показало, что относительный риск возникновения рака яичников при приеме тиболона
          аналогичен риску при применении других препаратов для ЗГТ;
        </P></LI>
        <LI><P>
          прием тиболона связан с увеличением относительного риска развития венозной тромбоэмболии (ВТЭ), т.е. тромбоза
          глубоких вен и тромбоэмболии легочной артерии, в 1,3-3 раза. Подобное явление чаще происходит во время первого
          года применения препарата (см. раздел «Особые указания»).
        </P></LI>
      </UL>


      <P sx={{mt: 4}}>
        <b>
          Таблица 3. Дополнительный риск развития ВТЭ при применении свыше 5 лет по результатам исследования «Инициатива
          здоровья женщин»
        </b>
      </P>

      <Tables.WrapperTable>
        <Tables.Table className="align-left" sx={{m: '10px 0', minWidth: 900, tableLayout: 'fixed'}}>
          <Tables.Thead>
            <Tables.Tr sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Bold'}}>
              <Tables.Td sx={{width: 108}}>Возрастная группа (годы)</Tables.Td>
              <Tables.Td sx={{width: 108}}>
                Частота заболеваний на 1000 женщин, в группе плацебо свыше 5 лет
              </Tables.Td>
              <Tables.Td sx={{width: 108}}>
                Отношение рисков (95% ДИ)
              </Tables.Td>
              <Tables.Td sx={{width: 108}}>
                Дополнительные случаи на 1000 пациенток, ранее получающих ЗГТ
              </Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td colSpan={4}>Только эстроген перорально*</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-59</Tables.Td>
              <Tables.Td>7</Tables.Td>
              <Tables.Td>1,2 (0,6-2,4)</Tables.Td>
              <Tables.Td>1 (3-10)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td colSpan={4}>Комбинация эстроген/прогестаген перорально</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-59</Tables.Td>
              <Tables.Td>4</Tables.Td>
              <Tables.Td>2,3 (1,2-4,3)</Tables.Td>
              <Tables.Td>5 (1-13)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td colSpan={4}>*У женщин с удаленной маткой</Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P>ДИ - доверительный интервал.</P>
      <UL>
        <LI><P>
          Отмечается незначительное увеличение риска развития ишемической болезни сердца (ИБС) у пациенток старше 60
          лет, получающих ЗГТ комбинированными (эстроген/гестаген) препаратами. Нет никаких оснований полагать, что риск
          развития инфаркта миокарда при приеме тиболона отличается от риска применения других видов ЗГТ.
        </P></LI>
        <LI><P>Заболевания желчного пузыря (желчнокаменная болезнь, холецистит).</P></LI>
        <LI><P>Кожные заболевания: хлоазма, многоформная эритема, узловатая эритема, сосудистая пурпура.</P></LI>
        <LI><P>Деменция при начале терапии в возрасте старше 65 лет (см. радел «Особые указания»).</P></LI>
        <LI><P>Панкреатит.</P></LI>
        <LI><P>Повышение артериального давления.</P></LI>
      </UL>

      <H2 id={'over-dozing'}>Передозировка</H2>
      <P>
        Острая токсичность тиболона у животных очень низкая, поэтому токсических симптомов можно не ожидать, даже если
        пациентка приняла несколько таблеток одновременно. В случаях острой передозировки могут развиться тошнота, рвота
        и влагалищное кровотечение. Антидот неизвестен. Рекомендована симптоматическая терапия.
      </P>

      <H2 id={'another-medicines'}>Взаимодействие с другими лекарственными средствами</H2>
      <P>
        Тиболон усиливает фибринолитическую активность крови, что может привести к усилению противосвертывающего
        действия антикоагулянтов, в частности варфарина, поэтому доза варфарина должна быть соответствующим образом
        откорректирована по МНО (международное нормализованное отношение).
      </P>
      <P>
        Одновременное применение тиболона и антикоагулянтов необходимо контролировать, особенно в начале и в конце
        лечения тиболоном.
      </P>
      <P>
        Существует лишь ограниченная информация относительно фармакокинетического взаимодействия при лечении тиболоном.
        Исследование in vivo продемонстрировало, что совместное применение с тиболоном в небольшой степени влияет на
        фармакокинетику субстрата цитохрома Р4503А4 мидазолама. Исходя из этого, возможно наличие лекарственного
        взаимодействия с другими субстратами CYP3A4. Лекарственные препараты-индукторы CYP3A4 (барбитураты,
        карбамазепин, производные гидантоина, рифампицин) при одновременном применении могут повышать метаболизм
        тиболона и, следовательно, повлиять на его терапевтический эффект.
      </P>
      <P>
        Препараты, содержащие зверобой продырявленный (Hypericum perforatum) могут усиливать метаболизм эстрогенов и
        прогестагенов посредством индукции изофермента CYP3A4. Повышенный метаболизм эстрогенов и прогестагенов может
        привести к снижению их клинического эффекта и изменению профиля маточных кровотечений.
      </P>

      <H2 id={'special-instructions'}>Особые указания</H2>
      <P>Препарат Велледиен не предназначен для применения в качестве контрацептивного средства и не защищает от
        нежелательной беременности.</P>
      <P>
        Решение о начале приема препарата Велледиен должно быть основано на соотношении «польза/риск» с учетом всех
        индивидуальных факторов риска, а у женщин старше 60 лет следует также принять во внимание повышение риска
        развития инсультов.
      </P>
      <P>
        Для лечения постменопаузальных симптомов препарат Велледиен необходимо назначать только в отношении симптомов,
        которые неблагоприятно влияют на качество жизни. Во всех случаях необходимо не менее одного раза в год проводить
        тщательную оценку риска и пользы терапии, и следует продолжать прием препарата Велледиен только в случае, когда
        польза от терапии превышает риск.
      </P>
      <P>
        Необходимо тщательно оценить риск развития инсульта, риск развития рака молочной железы и рака эндометрия у
        каждой женщины с интактной маткой (см. раздел «Побочное действие»), учитывая все индивидуальные факторы риска,
        частоту возникновения и особенности обоих видов рака и инсульта с точки зрения излечиваемости, заболеваемости и
        смертности.
      </P>
      <P>
        Доказательства относительного риска, связанного с заместительной гормонотерапией (ЗГТ) или применением тиболона
        для лечения преждевременной менопаузы ограничены. Вместе с тем, соотношение польза/риск у женщин с
        преждевременной менопаузой может быть более благоприятным, чем у женщин старшего возраста, из-за низкого уровня
        абсолютного риска у более молодых женщин.
      </P>
      <P><b><u>Медицинское обследование/наблюдение</u></b></P>
      <P>До начала или возобновления приема Велледиена следует собрать индивидуальный и семейный медицинский
        анамнез.</P>
      <P>
        Физикальное обследование (включая обследование органов малого таза и молочных желез) должно проводиться с учетом
        данных анамнеза, абсолютных и относительных противопоказаний. Во время приема препарата рекомендованы
        профилактические повторные осмотры, частота и характер которых определяются индивидуальными особенностями
        пациентки, но нс реже 1 раза в 6 месяцев. В частности, женщина должна быть информирована о необходимости
        сообщения врачу об изменениях в молочных железах.
      </P>
      <P>
        Обследования, включая соответствующие методы визуализации, например, маммографию, необходимо проводить в
        соответствии с принятой в настоящее время схемой обследования, адаптированной к клиническим потребностям каждой
        пациентки, но не реже 1 раза в 6 месяцев.
      </P>
      <P>
        Причины для немедленной отмены приема препарата и незамедлительного обращения к врачу
      </P>
      <P>Прием препарата следует прекратить в случае выявления противопоказания и/или при следующих
        состояниях/заболеваниях:</P>
      <UL>
        <LI><P>желтуха или ухудшение функции печени;</P></LI>
        <LI><P>внезапное повышение артериального давления, отличающееся от обычных показателей артериального давления,
          характерных для пациентки;</P></LI>
        <LI><P>возникновение головной боли типа мигрени.</P></LI>
      </UL>
      <P><b><u>Гиперплазия и рак эндометрия</u></b></P>
      <P>
        Данные наблюдательных исследований показали увеличение риска развития гиперплазии или рака эндометрия у женщин,
        принимающих тиболон. Риск развития рака эндометрия повышается с увеличением продолжительности применения
        препарата.
      </P>
      <P>Тиболон может увеличивать толщину эндометрия, измеряемую с помощью трансвагинального ультразвукового
        исследования.</P>
      <P>В течение первых месяцев приема тиболона могут наблюдаться «прорывные» кровотечения и кровянистые
        выделения.</P>
      <P>При появлении кровянистых выделений/кровотечения на фоне приема препарата Велледиен, которые</P>
      <P>- продолжаются более 6 месяцев от начала приема препарата,</P>
      <P>- начинаются через 6 месяцев после начала приема препарата и продолжаются даже после его отмены,</P>
      <P>женщине необходимо обратиться к врачу - это может быть признаком гиперплазии эндометрия.</P>
      <P><b><u>Рак молочной железы</u></b></P>
      <P>Данные разных клинических исследований с точки зрения доказательной медицины в отношении риска развития рака
        молочной железы при приеме тиболона противоречивы и требуется проведение дальнейших исследований.</P>
      <P>
        По данным «Исследования миллиона женщин» было выявлено значимое увеличение риска развития рака молочной железы
        при применении тиболона в дозе 2,5 мг (см. раздел «Побочное действие»). Этот риск становился явным через
        несколько лет применения препарата и возрастал с увеличением продолжительности применения, возвращаясь к
        исходному уровню через несколько лет (чаще через 5 лет) после прекращения применения препарата.
      </P>
      <P>Эти результаты не были подтверждены в ходе исследования с использованием Базы Данных Общей (врачебной) Практики
        (GPRD).</P>
      <P><b><u>Рак яичников</u></b></P>
      <P>Рак яичников распространен значительно меньше, чем рак молочной железы. Длительная (не менее 5-10 лет)
        заместительная монотерапия эстрогенами связывалась с незначительным повышением риска развития рака яичников.</P>
      <P>
        Некоторые исследования, включая исследование «Инициатива здоровья женщин» (WHI) свидетельствуют о том, что
        длительная терапия комбинированными препаратами для ЗГТ может обладать аналогичным или несколько более низким
        риском.
      </P>
      <P>В «Исследовании миллиона женщин» было показано, что относительный риск развития рака яичников при применении
        тиболона был аналогичен риску, связанному с применением других видов ЗГТ.</P>
      <P><b><u>Венозная тромбоэмболия</u></b></P>
      <P>
        Препараты для ЗГТ, содержащие только эстрогены, или комбинированные препараты для ЗГТ, содержащие эстроген и
        гестаген, могут увеличивать риск возникновения ВТЭ (тромбоза глубоких вен или тромбоэмболии легочной артерии) в
        1,3-3 раза, особенно в течение первого года применения препаратов для ЗГТ (см. раздел «Побочное действие»).
      </P>
      <P>
        Данные об увеличении риска развития ВТЭ при применении тиболона недостаточны, однако нельзя исключить
        незначительное увеличение риска по сравнению с женщинами, не принимавшими тиболон.
      </P>
      <P>Пациентки с известными тромбофилическими состояниями имеют повышенный риск развития ВТЭ и прием препарата
        Велледиен может увеличить этот риск, поэтому применение препарата данной популяцией пациенток противопоказано
        (см. раздел «Противопоказания»).</P>
      <P>
        Факторами риска развития ВТЭ являются применение эстрогенов, пожилой возраст, обширное оперативное
        вмешательство, длительная иммобилизация, ожирение (индекс массы тела (ИМТ) {'>'} 30 кг/м2), беременность и
        послеродовой период, системная красная волчанка и рак.
      </P>
      <P>
        Особое внимание следует уделить мерам профилактики, предотвращающим развитие тромбоза, ВТЭ в послеоперационном
        периоде. Рекомендуется прекращение терапии препаратом Велледиен за 4-6 недель перед хирургическим
        вмешательством, если в дальнейшем предполагается длительное соблюдение постельного режима. Лечение не должно
        возобновляться, пока у женщины не восстановится двигательная активность.
      </P>
      <P>
        Женщинам, у которых ВТЭ в анамнезе отсутствует, ио которые имеют родственников первой степени родства, имеющих в
        анамнезе тромбоз в молодом возрасте, может быть предложен скрининг (следует информировать женщину, что при
        скрининге выявляется лишь часть тромбофилических состояний). Если выявлено тромбофилическое состояние, которое
        обособлено от тромбоза у родственников, или серьезное нарушение (например, дефицит антитромбина III, протеина S,
        протеина С или комбинация нарушений), прием препарата Велледиен противопоказан.
      </P>
      <P>
        Перед назначением тиболона женщинам, получающим антикоагулянты, врач должен тщательно оценить соотношение
        польза/риск применения ЗГТ или тиболона.
        Если после начала лечения развивается ВТЭ прием препарата необходимо прекратить. Женщина должна быть
        информирована о необходимости немедленного обращения к врачу, если появятся симптомы потенциальной тромбоэмболии
        (боль и односторонний отек нижней конечности, внезапная боль в груди, одышка).
      </P>
      <P>
        Если после начала лечения развивается ВТЭ прием препарата необходимо прекратить. Женщина должна быть
        информирована о необходимости немедленного обращения к врачу, если появятся симптомы потенциальной тромбоэмболии
        (боль и односторонний отек нижней конечности, внезапная боль в груди, одышка).
      </P>
      <P><b><u>Ишемическая болезнь сердца</u></b></P>
      <P>
        В рандомизированных контролируемых исследованиях не получено доказательств защиты от инфаркта миокарда у женщин
        с наличием или отсутствием ИБС, которые получали ЗГТ комбинированными препаратами (эстроген/гестаген) или
        препаратами для ЗГТ, содержащими только эстроген. В эпидемиологических исследованиях с использованием базы GPR.D
        не было получено доказательств защиты от инфаркта миокарда у женщин в постменопаузе, которые получали тиболон.
      </P>
      <P>
        Велледиен или какие-либо другие препараты для ЗГТ не должны применяться для профилактики сердечно-сосудистых
        заболеваний.</P>
      <P><b><u>Ишемический инсульт</u></b></P>
      <P>
        Прием тиболона увеличивает риск возникновения ишемического инсульта, начиная с первого года применения (см.
        раздел «Побочное действие»). Абсолютный риск возникновения инсульта строго зависит от возраста, и,
        следовательно, данный эффект тиболона тем больше, чем больше возраст.
      </P>
      <P>
        При возникновении необъяснимых мигренеподобных головных болей с или без нарушения зрения необходимо как можно
        быстрее обратиться к врачу. В этом случае нельзя принимать препарат до тех пор, пока врач не подтвердит
        безопасность продолжения ЗГТ, поскольку такие головные боли могут быть ранним диагностическим признаком
        возможного инсульта.
      </P>
      <P><b><u>Другие состояния</u></b></P>
      <UL>
        <LI><P>
          По имеющимся данным применение тиболона приводило к значительному дозозависимому снижению холестерина ЛПВП
          (липопротеинов высокой плотности) (с 16,7 % при дозе 1,25 мг до 21,8 % при дозе 2,5 мг после двух лет
          применения).
        </P></LI>
        <LI><P>
          Снижалась общая концентрация триглицеридов и липопротеинов. Снижение концентрации общего холестерина и
          холестерина ЛПОНП (липопротеинов очень низкой плотности) не являлось дозозависимым. Концентрация холестерина
          ЛПНП (липопротеинов низкой плотности) не изменялась. Клиническое значение этих данных пока неизвестно.
        </P></LI>
        <LI><P>
          Эстрогены могут вызывать задержку жидкости, поэтому пациентки с сердечной или почечной недостаточностью должны
          находиться под тщательным наблюдением врача.
        </P></LI>
        <LI><P>
          Женщины с уже имеющейся гипертриглицеридемией должны находиться под тщательным наблюдением врача во время
          терапии тиболоном, так как редкие случаи значительного повышения концентрации триглицеридов в плазме крови,
          способствующие развитию панкреатита, отмечались во время терапии эстрогенами при данном состоянии.
        </P></LI>
        <LI><P>
          Прием тиболона может вызвать незначительное снижение концентрации в плазме крови тироксинсвязывающего
          глобулина (ТСГ) и общего Т4. Концентрация общего ТЗ не изменяется. Тиболон снижает концентрацию глобулина,
          связывающего половые гормоны (ГСПГ), но не влияет на концентрацию кортикостероидсвязывающего глобулина (КГС) и
          свободного кортизола.
        </P></LI>
        <LI><P>
          Применение препаратов для ЗГТ не улучшает когнитивную функцию. Имеются данные о повышенном риске возможного
          развития деменции у женщин при начале непрерывной терапии препаратами для ЗГТ, содержащими только эстрогены, в
          возрасте после 65 лет (см. раздел «Побочное действие»).
        </P></LI>
      </UL>

      <H2 id={'managment-transport'}>
        Влияние на способность управления транспортными
        средствами, механизмами
      </H2>
      <P>
        Не отмечено какого-либо действия тиболона на концентрацию внимания и способность управлять транспортными
        средствами и другими механизмами.
      </P>

      <H2 id={'form-production'}>Форма выпуска</H2>
      <P>
        Таблетки 2,5 мг. По 28 таблеток в блистере из ПВХ/Ал фольги. По 1 блистеру вместе с инструкцией по применению
        помещают в пачку картонную.
      </P>

      <H2 id={'keeper'}>Условия хранения</H2>
      <P>Хранить при температуре не выше 25 C</P>
      <P>Хранить в недоступном для детей месте.</P>

      <H2 id={'deadline'}>Срок годности</H2>
      <P>3 года.</P>
      <P>Не применять препарат по истечении срока годности.</P>

      <H2 id={'holiday'}>Условия отпуска</H2>
      <P>Отпускают по рецепту.</P>

      <H2 id={'owner'}>Владелец (держатель) Регистрационного удостоверения</H2>
      <P>Эксэлтис Хелскеа С.Л.</P>
      <P>Авенида Миралкампо, 7, Полигоно Индустриаль Миралкампо, 19200 Асукеко де Энарес, Гвадалахара, Испания.</P>

      <H2 id={'producer'}>Владелец (держатель) Регистрационного удостоверения</H2>
      <P>
        Лабораторное Леон Фарма С.А., <br/> Провинция Леон, г. Виллаквиламбре 24008, ул. Ла-Валлина, индустриальный
        полигон<br/> Наватехэра, Испания.
      </P>
      <P><b>Получить дополнительную информацию о препарате, а также направить свои претензии информацию о нежелательных
        явлениях можно по следующему адресу в России:</b></P>
      <P>
        Московское Представительство компании с ограниченной ответственностью «Эксэлтис Хелскеа С.Л.» 119435, Москва,
        Большой Саввинский пер., д.11, офис II. <br/>Тел./факс: +7 (495) 648 3947, +7 (495) 648 6354.</P>

      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Инструкция PDF
      </a>
    </Page>
  )
}
