import { useEffect } from 'react';
import Popup from '../../shared/ui/Popup';
import PrezentationItem from '../../pages/vidora/PrezentationItem';

type ProginovaListModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const ESCAPE_KEY = 'Escape';

export const ProginovaListModal = ({isOpen, onClose}: ProginovaListModalProps) => {
  useEffect(() => {
    const handleKeyDown = ({key}: KeyboardEvent) => {
      if (key === ESCAPE_KEY) {
        handleClosePopup();
      }
    };

    window.document.addEventListener('keyup', handleKeyDown);
    return () => {
      window.document.removeEventListener('keyup', handleKeyDown);
    };
  });

  const handleClosePopup = () => {
    if(onClose) {
      onClose();
    }
  }

  return (
    <Popup opened={isOpen} togglePopup={handleClosePopup} title={'Прогинова'}>
      <PrezentationItem icon="plus" name="Помощь новой жизни" link="/product/proginova"/>
      <PrezentationItem icon="plus" name="Помощь при эстрогенной недостаточности" link="/product/proginova-second"/>
    </Popup>
  )
}
