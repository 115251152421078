import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';
import {Span} from '../../../shared/ui';

export const AlvovizanSecondLinkTwo = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>3RD CONGRESS OF THE SOCIETY 3 OF ENDOMETRIOSIS AND UTERINE DISORDERS <br/>
        <Span sx={{fontSize: 24}}>APRIL 6, 7, 8  SINGAPORE</Span>
      </H1>
      <P>
        [ссылка: <A href="https://seud.org/call-abstracts-3rd-congress-society-endometriosis-uterine-disorders/">Charles Chapron, 3 сongress of the society of endometriosis and uterine disorders. April 6-8, Singapore, 2017</A>]
      </P>
    </Page>
  )
}
