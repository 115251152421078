import Page from 'shared/ui/Page';
import {H1} from '../ui';

import {StyledParagraph as P} from './ui';
import pdf from '../../../pdfs/proginova/Письмо Минздрава РФ от 05.03.2019 N 15-4 И 2-1913_518_L1913MZ050319.pdf';
import {PinkLight} from '../../../styles/color';

export const ProginovaLinkThree = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Письмо Минздрава РФ от 05.03.2019 N 15-4/И/2-1913 – О направлении клинических рекомендаций (протокола лечения)
        ''Женское бесплодие (современные подходы к диагностике и лечению)'' – Вместе с
        Клиническими рекомендациями (протоколом лечения) ''Женское бесплодие (современные подходы к диагностике и
        лечению)''</H1>
      <P>Министерство здравоохранения Российской Федерации направляет для использования в работе клинические
        рекомендации (протокол лечения) "Женское бесплодие (современные подходы к диагностике и лечению)", разработанные
        в соответствии со статьей 76 Федерального закона от 21 ноября 2011 г. N 323-ФЗ "Об основах охраны здоровья
        граждан в Российской Федерации", взамен одноименных клинических рекомендаций, направленных в субъекты Российской
        Федерации письмом Министерства здравоохранения Российской Федерации от 15 февраля 2019 г. N 15-4/И/2-1218.</P>
      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Письмо PDF
      </a>
    </Page>
  )
}
