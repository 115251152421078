import Page from 'shared/ui/Page';
import LinkContainer from 'shared/ui/LinkContainer';

export default () => {
  return (
    <Page isCross>
      <div
        style={{
          fontSize: 36,
          fontWeight: 600,
          color: '#F9D2D6',
          marginBottom: 20,
        }}
      >
        Preclinical pharmacological profile of nomegestrol acetate, a synthetic 19-nor-progesterone derivative
      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
          color: '#fff',
          lineHeight: '22px',
        }}
      >
        <b>Background:</b> Nomegestrol acetate (NOMAC), a synthetic progestogen derived from 19-nor-progesterone, recently
        completed clinical trials for use with 17beta-estradiol in a new monophasic combined oral contraceptive. In this
        review, published as well as previously unpublished preclinical studies that detail the effects of NOMAC on
        estrogenic, progestogenic, and androgenic systems, as well as mineralocorticoid, glucocorticoid, bone, and
        metabolic indices are described.
      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
          color: '#fff',
          lineHeight: '22px',
        }}
      >
        <b>Methods:</b> In vitro assays to determine NOMAC structure-activity relationships used tissue derived from rat uteri.
        Transactivation profiles were performed using Chinese hamster ovary (CHO) cells transfected with cDNAs encoding
        human steroid receptors. Estrogenic and anti-estrogenic activities were monitored in vivo in rats as well as in
        vitro in human breast cancer cells. Standard in vivo techniques were used in rats to determine progestational
        activity; antigonadotropic, androgenic, mineralocorticoid, and glucocorticoid activities; as well as effects on
        bone and other metabolic indices. Ovulation inhibition was monitored in rats and primates. NOMAC's effects on
        cardiovascular systems were determined in dogs and primates.
      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
          color: '#fff',
          lineHeight: '22px',
        }}
      >
        <b>Results:</b> NOMAC was without significant agonistic or antagonistic activity for estrogen receptor alpha or beta in
        vitro, and inhibited ovulation in rats and monkeys (2.5 mg/kg and 1 mg/kg, respectively). NOMAC lacked
        androgenic, antimineralocorticoid, glucocorticoid, and metabolic activity and exhibited moderate anti-androgenic
        activity in rats. NOMAC did not affect bone mineral density (BMD) in rats or hemodynamic and electrophysiologic
        parameters in dogs and primates.

      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
          color: '#fff',
          lineHeight: '22px',
        }}
      >
        <b>Conclusions:</b> NOMAC is a selective progestogen structurally similar to progesterone that has modest
        anti-androgenic activity and does not affect lipid or carbohydrate metabolism, BMD, or many cardiovascular
        parameters in selected animal models.
      </div>

      <LinkContainer
        text="van Diepen, H.A. Preclinical pharmacological profile of nomegestrol acetate, a synthetic 19-nor-progesterone derivative. Reprod Biol Endocrinol 10, 85 (2012)."
        link={'https://pubmed.ncbi.nlm.nih.gov/23043680/'}
      />
    </Page>
  );
};
