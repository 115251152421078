import {useState} from 'react';
import {useHistory} from 'react-router-dom'

import {Tab, TabsGroup} from 'components/tabs';
import {ProductModal} from 'components/product-modal/product-modal';
import {ProductModalType} from 'components/product-modal/types';
import {WrapperCouple} from 'components/wrapper-couple';
import {WrapperShell} from 'components/wrapper-shell';
import {Notice} from 'components/velledien/notice';
import Page from 'shared/ui/Page';
import {Img, List as UL, ListItem as LI, Tables, LinkNavigate} from 'shared/ui';
import {Black, Pink, PinkLight} from 'styles/color';

import {H2, Paragraph as P, H1, Span, Div, Sup} from './ui';
import images from './img'

export const Velledien = () => {
  const history = useHistory();

  const [modal, setModal] = useState<ProductModalType>(null);
  const handleClosePopup = () => {
    setModal(null);
  }

  const handleOpenModal = () => {
    setModal('Материалы');
  }

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  const handleOpenVideo = () => {
  }

  const docImages = [images.doc1, images.doc2, images.doc3, images.doc4, images.doc5];

  return (
    <>
      <Page head={'Велледиен'} backText="Продукты" onClose={handleRedirectToProducts}>
        <TabsGroup>
          <Tab>
            <LinkNavigate to="/product/velledien/instruction">Инструкция</LinkNavigate>
          </Tab>
          <Tab onClick={handleOpenModal}>Материалы</Tab>
          <Tab onClick={handleOpenVideo}>Видео</Tab>
        </TabsGroup>

        <Div sx={{display: 'flex', flexDirection: 'column', gap: '20px', mb: '150px'}}>
          <WrapperCouple>
            <WrapperShell
              //TODO change image
              sx={{backgroundImage: `url(${images.smilingWoman})`, backgroundColor: '#FFF'}}
            >
              <H2 sx={{color: Black, fontSize: 42, mt: 25, pl: 2}}>ВЕЛЛЕДИЕН</H2>
              <P sx={{color: Black, fontSize: 28, pl: 2}}>Тиболон 2,5 мг</P>
            </WrapperShell>
            <WrapperShell
              sx={{backgroundColor: '#F0EBED', position: 'relative'}}
              className="small"
            >
              <H2 sx={{mt: 0}}>Россия, 2020 г</H2>
              <P
                className="text-dark"
                sx={{fontSize: 16, lineHeight: '19px'}}
              >почти половину женского населения составляли лица в возрасте</P>
              <H2 sx={{mt: 1}}>45-65 лет</H2>
              <Img
                src={images.family}
                sx={{position: 'absolute', left: 0, bottom: 0}}
                className="image-abroad"
              />
            </WrapperShell>
          </WrapperCouple>

          <WrapperShell>
            <LinkNavigate to="/product/velledien/link6">
              <H1 sx={{color: PinkLight, mt: 2}}>Раннее начало МГТ снижает общую смертность<Sup>6</Sup></H1>
            </LinkNavigate>
            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none', backgroundColor: PinkLight}}>
                <Img src={images.add}/>
                <H2 className="text-dark">WOMAN HEALTH INITIATIVE (WHI)</H2>
                <P className="text-dark">
                  МГТ начата в возрасте <b>{'<'} 60 лет</b> <br/>
                  Длительность менопаузы <b>{'<'} 10 лет</b>
                </P>
                <H2 sx={{mt: 6, mb: 2, lineHeight: '32px'}}>
                  на
                  <Span sx={{fontSize: 60}}>30</Span>
                  % снижение общей смертности*
                </H2>
                <LinkNavigate to={'/product/velledien/link6'}>
                  <P sx={{m: 0, fontSize: 16, mt: 5}}>*в группе женщин 50-59 лет<Sup>6</Sup></P>
                </LinkNavigate>
              </WrapperShell>
              <WrapperShell sx={{boxShadow: 'none', backgroundColor: PinkLight}}>
                <Img src={images.add}/>
                <H2 className="text-dark">DANISH OSTEOPOROSIS PREVENTION STUDY</H2>
                <P className="text-dark">
                  Средний возраст начала МГТ — <b>около 50 лет,</b>
                  в течение семи месяцев после наступления менопаузы, <b>10 лет</b> — наблюдение.
                </P>
                <LinkNavigate to="/product/velledien/link7">
                  <H2 sx={{mt: 5, mb: 2, lineHeight: '32px'}}>
                    на
                    <Span sx={{fontSize: 60}}>43</Span>
                    % снижение общей смертности<Sup>7</Sup>
                  </H2>
                </LinkNavigate>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell>
            <H1 sx={{color: PinkLight, mb: 0}}>
              Возможности использования заместительной гормональной терапии с позиции современных международных
              исследований
            </H1>

            <WrapperCouple className="text-light">
              <WrapperShell sx={{boxShadow: 'none', p: '0!important'}}>
                <P sx={{mb: 2}}>
                  Несмотря на хорошо известные данные о положительном эффекте препаратов ЗГТ в отношении уменьшения
                  общей смертности женщин, увеличения продолжительности и качества жизни, ситуация с их использованием в
                  России далека от оптимальной.
                </P>
                <P sx={{mb: 2}}>
                  По данным на 2008 г., среди российских женщин в возрасте 45-65 лет только 0,6% получали ЗГТ, в то
                  время как в США – 10%, Германии – 11%, Англии – 10%, в небольшой Швейцарии – 15% женщин (IMS,
                  2008).
                </P>
                <P sx={{mb: 2}}>
                  При этом следует отметить, что в Европе неуклонно растет число самих женщин-врачей, в первую очередь,
                  гинекологов, использующих ЗГТ (например, в Англии еще в конце 90-х годов они составляли 55% в
                  популяции женщин-врачей).
                </P>
              </WrapperShell>
              <WrapperShell sx={{boxShadow: 'none', textAlign: 'center'}}>
                <Img src={images.market}/>
              </WrapperShell>
            </WrapperCouple>

            <P sx={{color: PinkLight, fontSize: 16, m: '22px 0 15px'}}>
              РЕДАКЦИЯ | 2012, АКУШЕРСТВО И ГИНЕКОЛОГИЯ, ЛЕКЦИИ ДЛЯ ВРАЧЕЙ ОБЩЕЙ ПРАКТИКИ, ПРАКТИЧЕСКАЯ МЕДИЦИНА 09 (12)
              АКУШЕРСТВО. ГИНЕКОЛОГИЯ. ЭНДОКРИНОЛОГИЯ. ДЕРМАТОВЕНЕРОЛОГИЯ, ЭНДОКРИНОЛОГИЯ | 28 ДЕКАБРЯ, 2012
            </P>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell
              sx={{backgroundImage: `url(${images.doctorBlonde})`}}
              className="small"
            />
            <WrapperShell sx={{pb: 4}} className="text-light">
              <H1 sx={{color: PinkLight}}>Именно государство несет ответственность за патронаж пациенток 40+</H1>
              <H2>ШВЕЦИЯ</H2>
              <P>98% женщин получают МГТ за счет государства</P>
              <H2>ФИНЛЯНДИЯ</H2>
              <P>
                все пациентки, отказавшиеся от приема МГТ, подписывают Информированное согласие об отказе от МГТ и с
                этого момента лечат все заболевания за свой счет
              </P>
              <H2>РОССИЯ</H2>
              <P>рецепты на многие препараты, кроме МГТ</P>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShell>
            <H1 sx={{color: PinkLight}}>
              Гормональные препараты на сегодняшний день входят в стандарты лечения большинства экономически развитых
              стран мира
            </H1>
            <H2 sx={{fontSize: 48, mt: 2, mb: 3}}>А ЧТО У НАС?</H2>
            <Div sx={{display: 'flex', overflowX: 'auto', gap: '20px', pb: 1}}>
              {docImages.map((item, index) => <Img key={index} src={item}/>)}
            </Div>
          </WrapperShell>

          <WrapperShell>
            <H1 sx={{color: PinkLight}}>Возрастно-половой состав населения Российской Федерации 2017-2020 гг</H1>
            <WrapperCouple className="text-light" sx={{gap: 5}}>
              <WrapperShell sx={{boxShadow: 'none', p: '0!important'}}>
                <P sx={{mb: 4}}>
                  В РФ сохраняется один из самых высоких гендерных разрывов показателя ожидаемой продолжительности жизни
                  при рождении. Женщины живут на 10,8 года дольше, чем мужчины, что влечет за собой одиночество женщин в
                  старших возрастах (на 1 тысячу мужчин в возрастной группе 60-64 года приходится 1430 женщин, в группе
                  65-69 лет – 1594 женщины, а в возрастной группе старше 70 лет – 2441 женщин).
                </P>
                <Notice>
                  <P>
                    В процессе старения у женщин наблюдается угнетение функции иммунной системы, снижение скорости
                    обмена веществ, изменение функций системы пищеварения, ухудшение памяти, развитие нервно-психических
                    болезней, деменции, заболеваний опорно-двигательного аппарата.
                  </P>
                </Notice>
              </WrapperShell>
              <WrapperShell sx={{boxShadow: 'none', p: '0!important', textAlign: 'center'}}>
                <Img src={images.people}/>
                <P sx={{fontSize: 16, textAlign: 'left'}}>
                  По вертикальной оси возрастные группы (по возрастанию снизу вверх). По обе стороны от центральной оси
                  откладываются отрезки, длина которых отвечает численности лиц каждого возраста (влево - мужчин, вправо
                  - женщин).
                </P>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell className="text-light" sx={{display: 'flex', flexDirection: 'column', gap: '20px;'}}>
            <H1 sx={{color: PinkLight}}>
              <LinkNavigate to="/product/velledien/link1">Для кого тиболон<Sup>1</Sup>?</LinkNavigate>
            </H1>
            <Notice>
              <P>
                Пациентки в постменопаузе с острым климактерическим синдромом:
              </P>
              <UL>
                <LI>
                  <P sx={{m: 0, mb: '4px'}}>
                    женщины с низким уровнем сексуального влечения или так называемой женской сексуальной дисфункцией;
                  </P>
                </LI>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с перепадами настроения;</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с риском остеопороза в раннем постменопаузальном периоде;</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с пременопаузальной масталгией или напряжением в молочной железе в
                  анамнезе;</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с повышенной рентгенологической плотностью молочных
                  желез;</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с миомой матки;</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с урогенитальными жалобами.</P></LI>
              </UL>
            </Notice>

            <Notice>
              <P>Пациентки, у которых может оказаться благоприятным переход с комбинированной МГТ на тиболон:</P>
              <UL>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины, испытывающие боль или напряжение в молочной железе;</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>
                  повышенная плотность груди с необходимостью направления на повторную маммографию в динамике
                  или с получением «нечитаемых» маммограмм;
                </P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с низким уровнем сексуального влечения;</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с перепадами настроения;</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>
                  женщины с кровотечениями (при условии отсутствия гистопатологических причин для кровотечения).
                </P></LI>
              </UL>
            </Notice>

            <Notice>
              <P>
                Пациентки без климактерических симптомов, которых следует иметь в виду (некоторые постменопаузальные
                женщины без климактерических симптомов или со слабо выраженной симптоматикой могут получить преимущество
                от лечения тиболоном:
              </P>
              <UL>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с низким уровнем сексуального влечения</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с перепадами настроения</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>женщины с остеопенией</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>более молодые женщины </P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>преждевременная недостаточность яичников</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>с сексуальной дисфункцией и перепадами настроения</P></LI>
                <LI><P sx={{m: 0, mb: '4px'}}>длительная дополнительная терапия агонистами ГнРГ</P></LI>
              </UL>
            </Notice>
          </WrapperShell>

          <WrapperShell className="text-light">
            <H1 sx={{color: PinkLight, mb: 0}}>В отличие от стандартной МГТ при использовании Тиболона:</H1>
            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none', p: '0!important', minHeight: 0}}>
                <UL sx={{listStyle: 'none'}}>
                  <LI className="prefix">
                    <LinkNavigate to="/product/velledien/link2">
                      <P sx={{mt: 1}}>
                        Улучшение сексуальности при приеме тиболона связано с центральным и с периферическим эффектом:
                        тиболон улучшает вагинальный кровоток<Sup>2</Sup>
                      </P>
                    </LinkNavigate>
                  </LI>
                  <LI className="prefix">
                    <LinkNavigate to="/product/velledien/link8">
                      <P sx={{mt: 1}}>
                        Улучшение настроения на фоне приема тиболона развивается за счет повышения уровня эндорфинов
                        и андрогенного действия метаболита тиболона<Sup>8</Sup>
                      </P>
                    </LinkNavigate>
                  </LI>
                  <LI className="prefix">
                    <LinkNavigate to="/product/velledien/link3">
                      <P sx={{mt: 1}}>
                        Значительно реже наблюдается болезненность молочных желез и масталгия<Sup>3</Sup>
                      </P>
                    </LinkNavigate>
                  </LI>
                </UL>
              </WrapperShell>
              <WrapperShell sx={{boxShadow: 'none', p: '0!important', minHeight: 0}}>
                <UL sx={{listStyle: 'none'}}>
                  <LI className="prefix">
                    <LinkNavigate to="/product/velledien/link4">
                      <P sx={{mt: 1}}>
                        Не наблюдается увеличения плотности ткани на маммограмме<Sup>4</Sup>
                      </P>
                    </LinkNavigate>
                  </LI>
                  <LI className="prefix">
                    <LinkNavigate to="/product/velledien/link5">
                      <P sx={{mt: 1}}>
                        Не повышается риск пролиферации ткани молочной железы<Sup>5</Sup>
                      </P>
                    </LinkNavigate>
                  </LI>
                  <LI className="prefix">
                    <P sx={{mt: 1}}>
                      <LinkNavigate to="/product/velledien/link9">
                        Не стимулируется пролиферация эндометрия<Sup>9</Sup>
                      </LinkNavigate>
                      <LinkNavigate to="/product/velledien/link3">
                        &nbsp;и снижает частоту кровотечений<Sup>2</Sup>
                      </LinkNavigate>
                    </P>
                  </LI>
                </UL>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell>
            <LinkNavigate to="/product/velledien/link10">
              <H1 sx={{color: PinkLight}}>
                Уникальные свойства и положительные клинические эффекты Тиболона<Sup>10</Sup>
              </H1>
            </LinkNavigate>
            <Tables.WrapperTable>
              <Tables.Table className="align-default" sx={{fontSize: 20}}>
                <Tables.Thead sx={{backgroundColor: Pink}}>
                  <Tables.Tr sx={{fontFamily: 'SF-Pro-Text-Bold', textAlign: 'left'}}>
                    <Tables.Td sx={{width: 581}}>Свойства</Tables.Td>
                    <Tables.Td sx={{width: 208}}>Тиболон</Tables.Td>
                    <Tables.Td sx={{width: 208}}>Комбинированная МГТ</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td>
                      <LinkNavigate to="/product/velledien/link2">
                        Тканеселективный механизм действия<Sup>2</Sup>
                      </LinkNavigate>
                    </Tables.Td>
                    <Tables.Td>+</Tables.Td>
                    <Tables.Td>-</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Устраняет климактерические симптомы</Tables.Td>
                    <Tables.Td>+</Tables.Td>
                    <Tables.Td>+</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Устраняет симптомы урогенитальных расстройств</Tables.Td>
                    <Tables.Td>+</Tables.Td>
                    <Tables.Td>+</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Предотвращает развитие остеопороза</Tables.Td>
                    <Tables.Td>+</Tables.Td>
                    <Tables.Td>+</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>
                      <LinkNavigate to="/product/velledien/link2">
                        Положительно влияет на сексуальную функцию<Sup>2</Sup>
                      </LinkNavigate>
                    </Tables.Td>
                    <Tables.Td>+</Tables.Td>
                    <Tables.Td>-</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>
                      <LinkNavigate to="/product/velledien/link2">
                        Низкая частота болезненности молочных желез<Sup>2</Sup>
                      </LinkNavigate>
                    </Tables.Td>
                    <Tables.Td>+</Tables.Td>
                    <Tables.Td>-</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Не влияет на маммографическую плотность</Tables.Td>
                    <Tables.Td>+</Tables.Td>
                    <Tables.Td>-</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>
                      <LinkNavigate to="/product/velledien/link11">
                        Низкая частота кровотечений прорыва<Sup>11</Sup>
                      </LinkNavigate>
                    </Tables.Td>
                    <Tables.Td>+</Tables.Td>
                    <Tables.Td>-</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Не повышает риск венозной тромбоэмболии</Tables.Td>
                    <Tables.Td>+</Tables.Td>
                    <Tables.Td>-</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
          </WrapperShell>

          <WrapperShell className="text-light">
            <H1 sx={{color: PinkLight}}>Велледиен – социально-ответственный препарат</H1>
            <P sx={{mb: 2}}>В настоящее время в России наблюдается постоянный рост цен.</P>
            <P sx={{mb: 2}}>Многие пациентки не могут позволить себе терапию оригинальным препаратом.</P>
            <P sx={{mb: 2}}>
              Назначая оригинальный препарат доктор в половине случаев не назначает ничего, так как пациентка, услышав в
              аптеке цену, не купит его.
            </P>
            <P sx={{backgroundColor: Pink, p: '20px', borderRadius: '12px', mt: 5}}>
              Поэтому Велледиен – социально-ответственный препарат, позволяющий максимальному количеству пациенток
              получать эффективную МГТ в постменопаузе без риска для молочной железы.
            </P>
            <Div sx={{overflowX: 'auto'}}>
              <Tables.Table sx={{textAlign: 'center', m: '20px 0', fontSize: 20}}>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td colSpan={2} sx={{fontSize: 28, lineHeight: '22px'}}>Тиболон 2,5 мг</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Велледиен №28</Tables.Td>
                    <Tables.Td>Ливиал №28</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>
                      <Img sx={{m: 2}} src={images.product}/>
                    </Tables.Td>
                    <Tables.Td>
                      <Img sx={{m: 2}} src={images.livial}/>
                    </Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>
                      <Span sx={{color: PinkLight, fontSize: 28, fontWeight: 700}}>803 ₽</Span>
                      <br/>средняя стоимость в аптеках
                    </Tables.Td>
                    <Tables.Td>
                      <Span sx={{color: PinkLight, fontSize: 28, fontWeight: 700}}>2 500 ₽</Span>
                      <br/> средняя стоимость в аптеках
                    </Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td colSpan={2}>
                      <Span sx={{color: PinkLight, fontSize: 28, fontWeight: 700}}>1 697 ₽</Span>
                      <br/> разница в стоимости / месяц
                    </Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td colSpan={2}>
                      <Span sx={{color: PinkLight, fontSize: 28, fontWeight: 700}}>20 364₽</Span><br/>
                      разница в стоимости / год — 12 циклов
                    </Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Div>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell className="text-light">
              <H2>СОСТАВ</H2>
              <P>28 таблеток белого цвета.</P>
              <P>1 таблетка содержит: тиболон — 2,50 мг</P>
              <H2><LinkNavigate to="/product/velledien/instruction">ПОКАЗАНИЯ К
                ПРИМЕНЕНИЮ<Sup>6</Sup></LinkNavigate></H2>
              <UL>
                <LI>
                  <P>
                    Лечение симптомов эстрогенной недостаточности у женщин в пост- менопаузальном периоде (не ранее чем
                    через 1 год после последней менструации при наступлении естественной менопаузы или сразу после
                    хирургической менопаузы);
                  </P>
                </LI>
                <LI>
                  <P>
                    Профилактика постменопаузального остеопороза у женщин с высоким риском переломов при непереносимости
                    или противопоказаниях к применению других лекарственных препаратов, предназначенных для лечения
                    остеопороза.
                  </P>
                </LI>
              </UL>
            </WrapperShell>
            <WrapperShell
              sx={{backgroundColor: PinkLight, position: 'relative'}}
              className="small"
            >
              <Img sx={{width: '100%', mt: 5}} src={images.productLarge}/>
              <H2 sx={{mt:5}}>
                <LinkNavigate to="/product/velledien/link3">
                  Влияние тиболона* на молочную железу сопоставимо с плацебо<Sup>3</Sup>
                </LinkNavigate>
              </H2>
              <P sx={{fontSize: 16, mt:3}}>*в течение 6 месяцев</P>
            </WrapperShell>
          </WrapperCouple>
        </Div>
      </Page>
      <ProductModal product="velledien" modal={modal} onClose={handleClosePopup}/>
    </>
  )
}
