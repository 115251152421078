import Page from 'shared/ui/Page';
import LinkContainer from 'shared/ui/LinkContainer';

export default () => {
  return (
    <Page isCross>
      <div
        style={{
          fontSize: 36,
          fontWeight: 600,
          color: '#F9D2D6',
          marginBottom: 20,
        }}
      >
        Effects of a monophasic combined oral contraceptive containing nomegestrol acetate and 17β-oestradiol compared
        with one containing levonorgestrel and ethinylestradiol on haemostasis, lipids and carbohydrate metabolism
      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
          color: '#fff',
          lineHeight: '22px',
        }}
      >
        <b>Objectives:</b> To compare the effects of a combined oral contraceptive (COC) containing nomegestrol acetate
        and
        17β-oestradiol (NOMAC/E2) on haemostasis, lipids, carbohydrate metabolism, C-reactive protein (CRP) and sex
        hormone-binding globulin (SHBG) with those of a COC containing levonorgestrel and ethinylestradiol (LNG/EE).
      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
          color: '#fff',
          lineHeight: '22px',
        }}
      >
        <b>Methods:</b>  In a randomised, open-label study, 121 healthy women, 18-50 years of age, were randomly assigned to
        receive NOMAC/E2 (2.5 mg/1.5 mg) in a 24/4-day regimen (n=60) or LNG/EE (150 μg/30 μg) in a 21/7-day regimen
        (n=61) for six cycles. The primary outcome was the change from baseline to cycle 6 for all indices.
      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
          color: '#fff',
          lineHeight: '22px',
        }}
      >
        <b>Results:</b>  All parameters were similar at baseline between the two groups. Over six cycles, NOMAC/E2 had less
        effect on most haemostatic indices than LNG/EE. Lipids were essentially unchanged with NOMAC/E2, whereas with
        LNG/EE high-density lipoprotein cholesterol decreased and low-density lipoprotein cholesterol and triglycerides
        slightly increased. NOMAC/E2 induced negligible changes in glucose and insulin parameters, in contrast to
        LNG/EE. A much smaller increase in CRP was observed with NOMAC/E2 than with LNG/EE. NOMAC/E2 was associated with
        a greater increase in SHBG.
      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
          color: '#fff',
          lineHeight: '22px',
        }}
      >
        <b>Conclusions:</b> The monophasic COC NOMAC/E2 had less influence on haemostasis, lipids and carbohydrate metabolism
        than the COC LNG/EE.
      </div>
      <LinkContainer
        text="Ågren UM, Anttila M, Mäenpää-Liukko K, Rantala ML, Rautiainen H, Sommer WF, Mommers E. Effects of a monophasic combined oral contraceptive containing nomegestrol acetate and 17β-oestradiol compared with one containing levonorgestrel and ethinylestradiol on haemostasis, lipids and carbohydrate metabolism. Eur J Contracept Reprod Health Care. 2011 Dec;16(6):444-57. doi: 10.3109/13625187.2011.604450. PMID: 22066891; PMCID: PMC3233272."
        link={'https://pubmed.ncbi.nlm.nih.gov/22066891/'}
      />
    </Page>
  );
};
