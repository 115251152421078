import Page from 'shared/ui/Page';
import {H1} from '../ui';

import {StyledParagraph as P, Link as A} from './ui';
import {PinkLight} from '../../../styles/color';

export const ProginovaLinkSix = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>The effects of E2 supplementation from the early proliferative phase to the late secretory phase of the
        endometrium in hMG-stimulated IVF-ET</H1>
      <P><b>Purpose:</b> Our purpose was to determine if pregnancy rates (PRs) for hMG (human menopausal
        gonadotropin)-stimulated IVF-ET (in vitro fertilization--embryo transfer) can be increased by estradiol (E2)
        supplementation from the early proliferative phase to the late secretory phase of the endometrium.</P>
      <P><b>Method:</b> Eighty-one infertile women with pure tubal factor were randomized into two groups. One group
        received no E2 supplementation (control group) and the other received oral E2 supplementation (2 mg two times
        daily) from the early proliferative phase starting on the third day of the menstrual cycle to the late secretory
        phase of the endometrium, with hMG stimulation for ovulation induction starting on the sixth day of the
        menstrual cycle.</P>
      <P><b>Results:</b> In 85 cycles, at least one embryo was transferred. Compared with the control group (n = 27
        cycles), the E2 supplementation group (n = 58 cycles) had a significantly higher PR (control, 25.9%, versus E2
        supplementation, 48.3%) and IR per ET (control, 10%, versus E2 supplementation, 26%), but FRs per retrieved
        oocytes were not statistically different between the two groups (control, 74%, versus E2 supplementation group,
        73%). Four spontaneous abortions occurred in the E2 supplementation group, and one case in the control group.
        Ectopic pregnancy occurred in one case in the control group.</P>
      <P><b>Conclusions:</b> Clinical PRs and IRs in the E2 supplementation group were significantly higher than in the
        control group, while FRs in the control group did not differ statistically from the E2 supplementation group.
        This suggests that E2 supplementation from the early proliferative phase to the late secretory phase of the
        endometrium in hMG-stimulated IVF-ET increases the receptivity of the endometrium for transferred embryos and
        clinical PRs.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/10754780/">Jung H, Roh HK. The effects of E2 supplementation from the early proliferative phase to the
        late secretory phase of the endometrium in hMG-stimulated IVF-ET. J Assist Reprod Genet. 2000
        Jan;17(1):28-33.</A>]
      </P>
    </Page>
  )
}
