import {useHistory} from 'react-router-dom';

import {WrapperShell} from 'components/wrapper-shell';
import {WrapperCouple} from 'components/wrapper-couple';
import Page from 'shared/ui/Page';
import {Div, List as UL, ListItem as LI, Span, Tables} from 'shared/ui';
import {PinkLight} from 'styles/color';

import {StyledH2 as H2, StyledH1 as H1, StyledParagraph as P, LinkA as A} from './ui';
import pdf from '../../../pdfs/proginova/Прогинова_ИМП.pdf';

export const ProginovaInstruction = () => {
  const history = useHistory();

  const handlerRedirectToDiecyclen = () => {
    history.goBack();
  }

  return (
    <Page onClose={handlerRedirectToDiecyclen} isCross>
      <Div sx={{textAlign: 'center'}}>
        <H2 sx={{fontSize: 20, mb: 3}}>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</H2>
        <H1>ИНСТРУКЦИЯ</H1>
        <H2 sx={{fontSize: 20, textTransform: 'none', mb: 3, mt: 2}}>по применению лекарственного препарата для
          медицинского применения</H2>
        <H1 sx={{marginBottom: 20}}>ПРОГИНОВА®</H1>
      </Div>
      <H2 sx={{fontSize: 20}}>РЕГИСТРАЦИОННЫЙ НОМЕР: <Span sx={{color: '#FFF'}}>П N013529/01</Span></H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ТОРГОВОЕ НАИМЕНОВАНИЕ: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>Прогинова®</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        МЕЖДУНАРОДНОЕ НЕПАТЕНТОВАННОЕ НАИМЕНОВАНИЕ: <Span
        sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>эстрадиола валерат</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ЛЕКАРСТВЕННАЯ ФОРМА: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>драже</Span>
      </H2>

      <H2 style={{marginTop: 50}}>СОДЕРЖАНИЕ</H2>
      <WrapperCouple sx={{display: 'flex', justifyContent: 'space-between'}}>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#compound'}>СОСТАВ</A>
          <A href={'#description'}>ОПИСАНИЕ</A>
          <A href={'#farm-group'}>ФАРМАКОТЕРАПЕВТИЧЕСКАЯ ГРУППА</A>
          <A href={'#farm-property'}>ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА</A>
          <A href={'#ind-for-use'}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ</A>
          <A href={'#dication'}>ПРОТИВОПОКАЗАНИЯ</A>
          <A href={'#warning'}>С ОСТОРОЖНОСТЬЮ</A>
          <A href={'#for-use-feed'}>ПРИМЕНЕНИЕ В ПЕРИОД БЕРЕМЕННОСТИ И ГРУДНОГО<br/> ВСКАРМЛИВАНИЯ</A>
          <A href={'#dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</A>
          <A href={'#effect-action'}>ПОБОЧНОЕ ДЕЙСТВИЕ</A>
          <A href={'#over-dozing'}>ПЕРЕДОЗИРОВКА</A>
        </WrapperShell>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#another-medicines'}>ВЗАИМОДЕЙСТВИЕ С ДРУГИМИ ЛЕКАРСТВЕННЫМИ <br/> СРЕДСТВАМИ</A>
          <A href={'#special-instructions'}>ОСОБЫЕ УКАЗАНИЯ</A>
          <A href={'#managment-transport'}>ВЛИЯНИЕ НА СПОСОБНОСТЬ УПРАВЛЕНИЯ <br/>ТРАНСПОРТНЫМИ СРЕДСТВАМИ,
            МЕХАНИЗМАМИ</A>
          <A href={'#form-production'}>ФОРМА ВЫПУСКА</A>
          <A href={'#keeper'}>УСЛОВИЯ ХРАНЕНИЯ</A>
          <A href={'#deadline'}>СРОК ГОДНОСТИ</A>
          <A href={'#holiday'}>УСЛОВИЯ ОТПУСКА</A>
          <A href={'#owner'}>ВЛАДЕЛЕЦ (ДЕРЖАТЕЛЬ) РЕГИСТРАЦИОННОГО<br/>УДОСТОВЕРЕНИЯ</A>
          <A href={'#producer'}>ПРОИЗВОДИТЕЛЬ</A>
        </WrapperShell>
      </WrapperCouple>

      <H2 id={'compound'}>СОСТАВ</H2>
      <P>1 драже содержит:</P>
      <P><b>действующее вещество:</b> эстрадиола валерат, микро 20 (эстрадиол) - 2,000 мг;</P>
      <P><b>вспомогательные вещества:</b> лактозы моногидрат - 46,250 мг, крахмал кукурузный - 26,200 мг, повидон К25 -
        3,000
        мг, тальк - 2,400 мг, магния стеарат - 0,150 мг; сахарная оболочка: сахароза - 33,980 мг, повидон К90 - 0,296
        мг, макрогол 6000 - 3,767 мг, кальция карбонат - 14,711 мг, тальк - 7,171 мг, воск горный гликолиевый - 0,075
        мг</P>

      <H2 id={'description'}>ОПИСАНИЕ</H2>
      <P>Драже белого цвета.</P>

      <H2 id={'farm-group'}>Фармакотерапевтическая группа: </H2>
      <P>половые гормоны и модуляторы половой системы, природные и полусинтетические эстрогены.</P>
      <P><b>Код ATX</b>: G03CA03</P>

      <H2 id={'farm-property'}>Фармакологические свойства</H2>

      <P><b>Фармакодинамика</b></P>
      <P>Действующим веществом препарата Прогинова® является эстрадиола валерат - эфир валериановой кислоты эндогенного
        женского эстрогена эстрадиола.</P>
      <P>Эстрадиола валерат (синтетический валерат 17β-эстрадиола) расщепляется в организме до 17β-эстрадиола, химически
        и биологически идентичного естественному эстрадиолу человека. Он восполняет дефицит эстрогенов после наступления
        менопаузы и облегчает связанные с ним симптомы менопаузы.</P>
      <P>Эстрогены предотвращают потерю костной ткани после наступления менопаузы или удаления яичников.</P>
      <P>Препарат Прогинова® не подавляет овуляцию, и практически не влияет на выработку гормонов в самом организме.</P>
      <P>Сведения в отношении клинических исследований.</P>
      <P>Облегчение симптомов, вызванных дефицитом эстрогенов и влияние на кровотечения.</P>
      <P>На фоне снижения и прекращения секреции эстрадиола яичниками во время менопаузы возможны нестабильность
        терморегуляции, сопровождающаяся «приливами», нарушение сна, повышенное потоотделение, атрофия слизистой
        оболочки мочевыводящих и половых путей (симптомы: диспареуния, недержание мочи, сухость и раздражение слизистой
        оболочки влагалища), снижение либидо, нервозность, раздражительность, боли в области сердца, тахикардия,
        повышенная утомляемость, снижение памяти и концентрации внимания. Заместительная гормональная терапия (ЗГТ)
        облегчает многие из этих симптомов дефицита эстрадиола у женщины в менопаузе. За первые недели лечения
        достигалось облегчение симптомов менопаузы.</P>
      <P>С целью снижения риска развития гиперплазии и рака эндометрия на фоне применения эстрадиола валерата для женщин
        с интактной (сохраненной) маткой рекомендуется дополнительный прием прогестагена в течение не менее 12 дней в
        каждом цикле ЗГТ. Профилактика остеопороза.</P>
      <P>Дефицит эстрогенов в период менопаузы связан с повышенным обменом костной ткани и снижением костной массы.
        Влияние эстрогенов на минеральную плотность костной ткани (МПКТ) зависит от дозы. ЗГТ в отношении снижения МПКТ
        эффективна только в период терапии, после прекращения ЗГТ потеря костной массы происходит с той же скоростью,
        что и у женщин, не получающих ЗГТ. Результаты исследования «Инициативы по охране здоровья женщин» (US WHI) и
        мета-анализов показывают, что длительная ЗГТ препаратами, содержащими только эстрогены или комбинацию эстрогенов
        с прогестагенами у преимущественно здоровых женщин, позволяет снизить риск переломов бедра, позвоночника или
        других остеопаретических переломов. Имеются ограниченные клинические данные, свидетельствующие, что ЗГТ может
        также предотвращать переломы у женщин со сниженной МПКТ и/или установленным остеопорозом</P>
      <P><b>Фармакокинетика</b></P>
      <P><b><u>Всасывание</u></b></P>
      <P>После приема внутрь эстрадиола валерат быстро и полностью всасывается. В процессе абсорбции эстрадиола валерат
        расщепляется на эстрадиол и валериановую кислоту. Максимальные концентрации эстрадиола в плазме крови
        достигаются через 0,5 - 3 часа. Через 6-8 часов, как правило, наблюдается второй пик концентрации, в связи с
        возможной кишечно-печеночной рециркуляцией эстрадиола. Прием пищи не влияет на биодоступность эстрадиола
        валерата. Абсолютная биодоступность эстрадиола составляет 3 - 5% от пероральной дозы эстрадиола валерата.</P>
      <P><b><u>Распределение</u></b></P>
      <P>В плазме крови эстрадиол находится, в основном, в связанном с белками состоянии: около 37 % - с глобулином,
        связывающим половые гормоны (ГСПГ) и 61 % - с альбумином. Метаболизм</P>
      <P>Эстеразы в плазме и печени быстро расщепляют эстрадиола валерат на эстрадиол и валериановую кислоту. Дальнейшее
        расщепление валериановой кислоты путем бета- окисления приводит к образованию СО2 и воды в качестве конечных
        продуктов. Сам эстрадиол подвергается нескольким стадиям гидроксилирования. Его метаболиты, а также неизмененное
        вещество окончательно конъюгируются. Промежуточные продукты метаболизма представляют собой эстрон и эстриол,
        которые обладают собственной эстрогенной активностью, не столь выраженной, как у эстрадиола. Концентрация
        конъюгированного эстрона в плазме в 25-30 раз выше, чем концентрация неконъюгированного эстрона. В исследовании
        с использованием радиоактивного меченого эстрадиола валерата около 20 % радиоактивных веществ в плазме можно
        было охарактеризовать как неконъюгированные стероиды, 17% как глюкуронизированные стероиды и 33% как стероидные
        сульфаты. Около 30% всех веществ не могли быть извлечены из водной фазы и, следовательно, вероятно, представляют
        собой метаболиты с высокой полярностью.</P>
      <P><b><u>Выведение</u></b></P>
      <P>Эстрадиол и его метаболиты выводятся почками (в основном в виде сульфатов, глюкуронидов) и через кишечник в
        соотношении 9:1. В течение 5 дней около 78-96% принятой дозы выводится с периодом полувыведения около 27
        часов.</P>

      <H2 id={'ind-for-use'}>Показания к применению</H2>
      <P>Заместительная гормональная терапия (ЗГТ) при симптомах дефицита эстрогенов у женщин в постменопаузальном
        периоде (не ранее чем через 6 месяцев после последней менструации в случае естественной менопаузы или сразу же
        после менопаузы в результате хирургического вмешательства, например двухсторонней овариоэктомии).</P>
      <P>Профилактика остеопороза у женщин в постменопаузе с высоким риском переломов при непереносимости или наличии
        противопоказаний к применению других лекарственных средств для профилактики остеопороза.</P>

      <H2 id={'dication'}>Противопоказания</H2>
      <P>ЗГТ противопоказана при наличии любого из перечисленных ниже состояний/заболеваний или факторов риска:</P>
      <UL>
        <LI><P>беременность и период грудного вскармливания,</P></LI>
        <LI><P>диагностированный, в анамнезе или подозреваемый рак молочной железы (РМЖ),</P></LI>
        <LI><P>диагностированные или подозреваемые эстрогензависимые опухоли (например, рак эндометрия),</P></LI>
        <LI><P>нелеченая гиперплазия эндометрия,</P></LI>
        <LI><P>наличие венозного тромбоза или тромбоэмболии (ВТЭ) в настоящее время или в анамнезе (тромбоз глубоких
          вен, тромбоэмболия легочной артерии),</P></LI>
        <LI><P>наличие факторов высокого риска развития венозных и артериальных тромбозов и тромбоэмболий (см. раздел
          «Особые указания»),</P></LI>
        <LI><P>диагностированная врожденная или приобретенная предрасположенность к артериальным или венозным тромбозам
          (например, дефицит протеина С, протеина S, антитромбина III),</P></LI>
        <LI><P>артериальный тромбоз или тромбоэмболия (АТЭ), в том числе инфаркт миокарда, инсульт, цереброваскулярные
          нарушения или продромальные состояния (в том числе, транзиторная ишемическая атака, стенокардия),</P></LI>
        <LI><P>острые заболевания печени или заболевания печени тяжелой степени в анамнезе (если показатели функции
          печени не нормализовались),</P></LI>
        <LI><P>выраженная гипертриглицеридемия,</P></LI>
        <LI><P>гиперчувствительность к эстрадиола валерату и/или к любому вспомогательному веществу в составе
          препарата,</P></LI>
        <LI><P>непереносимость лактозы, фруктозы; дефицит лактазы, сахаразы/изомальтазы; синдром глюкозо-галактозной
          мальабсорбции,</P></LI>
        <LI><P>порфирия,</P></LI>
        <LI><P>возраст до 18 лет.</P></LI>
      </UL>

      <P>При возникновении впервые или выявлении каких-либо из этих состояний/заболеваний или факторов риска на фоне
        ЗГТ, следует немедленно прекратить прием препарата Прогинова® и проконсультироваться с врачом.</P>


      <H2 id={'warning'}>С осторожностью</H2>

      <P>Под тщательным наблюдением врача препарат Прогинова® следует применять, при наличии (в том числе в анамнезе)
        любых из нижеперечисленных заболеваний/состояний или факторов риска:</P>

      <UL>
        <LI><P>лейомиома (фибромиома матки) или эндометриоз;</P></LI>
        <LI><P>факторы риска развития тромбозов и тромбоэмболий;</P></LI>
        <LI><P>факторы риска эстрогензависимых опухолей, в том числе, наличие в семейном анамнезе РМЖ у родственников
          1-й линии (мать, сестры);</P></LI>
        <LI><P>артериальная гипертензия;</P></LI>
        <LI><P>доброкачественные опухоли печени (например, аденома печени);</P></LI>
        <LI><P>сахарный диабет с диабетической ангиопатией или без нее;</P></LI>
        <LI><P>гипертриглицеридемия в анамнезе;</P></LI>
        <LI><P>желчекаменная болезнь;</P></LI>
        <LI><P>холестатическая желтуха или холестатический зуд в анамнезе во время предшествующей беременности;</P></LI>
        <LI><P>врожденные гипербилирубинемии (синдромы Жильбера, Дубина-Джонсона и Ротора);</P></LI>
        <LI><P>наследственный ангионевротический отек;</P></LI>
        <LI><P>хроническая сердечная или почечная недостаточность;</P></LI>
        <LI><P>мигрень или сильная головная боль;</P></LI>
        <LI><P>системная красная волчанка;</P></LI>
        <LI><P>гиперплазия эндометрия в анамнезе;</P></LI>
        <LI><P>эпилепсия;</P></LI>
        <LI><P>бронхиальная астма;</P></LI>
        <LI><P>отосклероз.</P></LI>
      </UL>

      <H2 id={'for-use-feed'}>Применение в период беременности и грудного вскармливания</H2>
      <P><b>Беременность</b></P>
      <P>Применение препарата Прогинова® противопоказано в период беременности.</P>
      <P>Если беременность наступила во время лечения препаратом, прием препарата Прогинова® следует немедленно
        прекратить.</P>
      <P><b>Грудное вскармливание</b></P>
      <P>Применение препарата Прогинова® в период грудного вскармливания противопоказано</P>


      <H2 id={'dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</H2>
      <P>Лекарственный препарат Прогинова® содержит только эстроген.</P>
      <P>Препарат следует принимать внутрь, по 1 драже 1 раз в сутки, ежедневно, примерно в одно и то же время, в
        течение 21 дня. Драже принимают целиком, запивая 1 стаканом воды или молока, независимо от приема пищи. После
        окончания приема драже из упаковки препарата Прогинова®, следует начинать прием препарата из новой упаковки без
        перерыва. Перед началом терапии препаратом следует исключить беременность.</P>
      <P>У женщин, не получающих ЗГТ, или при переводе женщины с непрерывного режима применения комбинированного
        препарата для ЗГТ, терапию препаратом Прогинова® можно начинать в любой день.</P>
      <P>При переходе с циклического режима применения препаратов для ЗГТ, прием препарата Прогинова® следует начинать
        после завершения курса предыдущей терапии без перерыва. Для женщин без гистерэктомии в анамнезе (с сохраненной
        маткой) с целью профилактики гиперплазии и рака эндометрия дополнительно должен быть назначен прогестаген в
        течение не менее 12-14 дней в течение каждого месяца. Следует применять только прогестагены, разрешенные для
        комбинированной терапии с эстрогенами.</P>
      <P>У женщин с гистерэтомией (удаленной маткой), при отсутствии в анамнезе диагностированного эндометриоза,
        дополнительного применения прогестагена не требуется.</P>
      <P><b><u>Пропуск приема препарата</u></b></P>
      <P>Если женщина забыла принять препарат Прогинова® в обычное время, и с момента планируемого приема прошло не
        более 12 часов, следует принять пропущенную дозу препарата сразу же, как только женщина вспомнит об этом.
        Следующе драже принимается в обычное время.</P>
      <P>Если опоздание в приеме препарата составило более 12 часов (интервал с момента приема последнего драже более 36
        часов), пропущенную дозу принимать не нужно. Прием препарата Прогинова® должен быть продолжен на следующий день
        в обычное время.</P>
      <P>Пропуск приема препарата повышает вероятность «прорывного» кровотечения или кровянистых выделений.</P>
      <P>Нельзя принимать двойную дозу для восполнения пропущенной дозы препарата.</P>
      <P><b><u>Применение препарата в особых клинических группах</u></b></P>
      <P><b>Дети и подростки до 18 лет</b></P>
      <P>Препарат Прогинова® не показан к применению у детей и подростков в возрасте до 18 лет.</P>
      <P><b>У пожилых пациенток</b></P>
      <P>Отсутствуют клинические данные в отношении необходимости коррекции дозы у пожилых женщин. Опыт применения ЗГТ у
        женщин в возрасте старше 65 лет ограничен.</P>
      <P><b>У пациенток с печеночной недостаточностью</b></P>
      <P>Специальных клинических исследований у женщин с печеночной недостаточностью не проводилось. Применение
        препарата у женщин с заболеваниями печени тяжелой степени противопоказано.</P>
      <P><b>У пациенток с почечной недостаточностью</b></P>
      <P>Специальных клинических исследований у женщин с почечной недостаточностью не проводилось. Имеющиеся клинические
        данные не указывают на необходимость коррекции дозы у таких пациенток</P>


      <H2 id={'effect-action'}>Побочное действие</H2>
      <P>Возможные нежелательные реакции при применении препарата Прогинова® и других пероральных препаратов для ЗГТ,
        указанные ниже, распределены по системно-органным классам с указанием частоты их возникновения согласно
        рекомендациям ВОЗ: часто (≥ 1/100 и {'<'} 1/10); нечасто (≥ 1/1000 и {'<'}1/100); редко (≥ 1/10000 и {'<'}1/1000),
        частота
        неизвестна (на основании имеющихся данных оценить невозможно).</P>
      <Tables.WrapperTable sx={{overflowX: 'auto'}}>
        <Tables.Table className="align-left" sx={{minWidth: 900, tableLayout: 'fixed'}}>
          <Tables.Thead>
            <Tables.Tr sx={{color: PinkLight}}>
              <Tables.Td><b>Системно-органные классы (MedDRA)</b></Tables.Td>
              <Tables.Td><b>Часто (от ≥ 1/100 до {'<'}1/10) </b></Tables.Td>
              <Tables.Td><b>Нечасто (от ≥ 1/1000 до {'<'}1/100)</b></Tables.Td>
              <Tables.Td><b>Редко (от ≥ 1/10000 до {'<'}1/1000)</b></Tables.Td>
              <Tables.Td><b>Частота неизвестна</b> (на основании имеющихся данных оценить невозможно)</Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td>Доброкачественные, злокачественные и неуточненные новообразования (включая кисты и
                полипы)</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Рак молочной железы*, рак эндометрия*</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны органа зрения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Нарушения зрения</Tables.Td>
              <Tables.Td>Непереносимость контактных линз (неприятные ощущения при их ношении).</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны иммунной системы</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Реакции гиперчувствительности</Tables.Td>
              <Tables.Td>Обострение наследственного ангионевротического отека</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны нервной системы</Tables.Td>
              <Tables.Td>Головная боль</Tables.Td>
              <Tables.Td>Головокружение, инсульт*</Tables.Td>
              <Tables.Td>Мигрень</Tables.Td>
              <Tables.Td>Хорея</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения психики</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Снижение настроения</Tables.Td>
              <Tables.Td>Тревожность/ симптомы депрессии, снижение или увеличение либидо</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны сердца</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Учащенное сердцебиение</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Инфаркт миокарда* (см. раздел «Особые указания»)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны сосудов</Tables.Td>
              <Tables.Td>Повышение артериального давления</Tables.Td>
              <Tables.Td>Тромбофлебит, венозная тромбоэмболия*</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны дыхательной системы, органов грудной клетки и средостения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Носовое кровотечение</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны желудочно-кишечного тракта</Tables.Td>
              <Tables.Td>Тошнота, боль в животе</Tables.Td>
              <Tables.Td>Диспептические расстройства</Tables.Td>
              <Tables.Td>Рвота, вздутие живота, метеоризм</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны печени и желчевыводящих путей</Tables.Td>
              <Tables.Td>Заболевания желчного пузыря, в том числе холестаз</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны кожи и подкожных тканей</Tables.Td>
              <Tables.Td>Кожная сыпь, зуд</Tables.Td>
              <Tables.Td>Узловатая эритема, экзема, крапивница, выпадение волос</Tables.Td>
              <Tables.Td>Гирсутизм, акне, полиморфная эритема</Tables.Td>
              <Tables.Td>Геморрагическая сыпь, хлоазма (см. раздел «Особые указания»)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны скелетно-мышечной и соединительной ткани</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Мышечные судороги, боль в нижних конечностях</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны почек и мочевыводящих путей</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Циститоподобный симптом</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны половых органов и молочной железы</Tables.Td>
              <Tables.Td>Гиперплазия эндометрия, меноррагия («прорывное» кровотечение или «мажущие» кровянистые
                выделения) - аномальные кровотечения/ кровянистые выделения обычно исчезают на фоне непрерывной
                терапии</Tables.Td>
              <Tables.Td>Чувство напряжения в молочных железах, боль в молочных железах, выделения из молочных
                желез</Tables.Td>
              <Tables.Td>Дисменорея, изменение характера выделений из влагалища, жалобы, аналогичные таковым при
                предменструальном синдроме, увеличение молочных желез, увеличение размера миомы матки, кандидозный
                вагинит</Tables.Td>
              <Tables.Td>Эрозия шейки матки</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Общие расстройства и нарушения в месте введения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Периферические отеки</Tables.Td>
              <Tables.Td>Повышенная утомляемость</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P sx={{fontSize: 16, color: PinkLight}}>* Пожалуйста, см. дополнительную информацию ниже.</P>
      <P><b><u>Риск рака молочной железы</u></b></P>
      <P>Имеются сведения о двукратном увеличении риска РМЖ у женщин, получающих комбинированную терапию эстрогеном и
        прогестагеном в течение более 5 лет.</P>
      <P>Повышенный риск у женщин, получающих монотерапию эстрогеном существенно ниже, чем у женщин, принимающих
        комбинацию эстрогена и прогестагена.</P>
      <P>Уровень риска зависит от длительности применения (см. раздел «Особые указания»).</P>
      <P>Результаты самого большого рандомизированного плацебо-контролируемого исследования (WHI) и самого большого
        эпидемиологического исследования (MWS) представлены ниже.</P>
      <P><b><u>Предполагаемый дополнительный риск развития РМЖ после 5 лет применения (по данным «Исследования миллиона
        женщин»).</u></b></P>

      <Tables.WrapperTable sx={{overflowX: 'auto'}}>
        <Tables.Table className="align-left" sx={{minWidth: 900, tableLayout: 'fixed'}}>
          <Tables.Thead>
            <Tables.Tr sx={{color: PinkLight}}>
              <Tables.Td><b>Возрастная группа (годы)</b></Tables.Td>
              <Tables.Td><b>Дополнительные случаи на 1 000 пациенток, ранее не получавших ЗГТ, за период 5
                летa</b></Tables.Td>
              <Tables.Td><b>Отношение риска (ОР*) (95% ДИ* b)</b></Tables.Td>
              <Tables.Td><b>Дополнительные случаи на 1 000 пациенток, ранее получавших ЗГТ, за период свыше 5 лет (95 %
                ДИ*)</b></Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td colSpan={4}>ЗГТ только эстрогеном</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-65</Tables.Td>
              <Tables.Td>9-12</Tables.Td>
              <Tables.Td>1,2</Tables.Td>
              <Tables.Td>1-2 (0-3)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td colSpan={4}>Комбинированная терапия эстрогеном и прогестагеном</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-65</Tables.Td>
              <Tables.Td>9-12</Tables.Td>
              <Tables.Td>1,7</Tables.Td>
              <Tables.Td>6 (5-7)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td colSpan={4}>
                <P>а - на основе исходных данных частоты случаев в развитых странах.</P>
                <P>b - общее отношение риска. Отношение риска не является постоянным, и увеличивается с увеличением
                  длительности применения.</P>
                <P>Примечание: так как фоновая частота случаев РМЖ в разных странах ЕС отличается, количество
                  дополнительных случаев рака также меняется пропорционально.</P>
              </Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P><b><u>Предполагаемый дополнительный риск развития РМЖ после 5 лет применения (по данным «Исследования миллиона
        женщин»).</u></b></P>

      <Tables.WrapperTable sx={{overflowX: 'auto'}}>
        <Tables.Table className="align-left" sx={{minWidth: 900, tableLayout: 'fixed'}}>
          <Tables.Thead>
            <Tables.Tr sx={{color: PinkLight}}>
              <Tables.Td><b>Возрастная группа (годы)</b></Tables.Td>
              <Tables.Td><b>Дополнительные случаи на 1 000 пациенток, ранее не получавших ЗГТ, за период 5
                летa</b></Tables.Td>
              <Tables.Td><b>Отношение риска (ОР*) (95% ДИ* b)</b></Tables.Td>
              <Tables.Td><b>Дополнительные случаи на 1 000 пациенток, ранее получавших ЗГТ, за период свыше 5 лет (95 %
                ДИ*)</b></Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td colSpan={4}>КЭЭ только эстрогеном</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-79</Tables.Td>
              <Tables.Td>21</Tables.Td>
              <Tables.Td>0,8 (0,7-1,0)</Tables.Td>
              <Tables.Td>-4(-6-0)а</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td colSpan={4}>КЭЭ + МПА (Комбинированная терапия) эстрогеном и прогестагеном b</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-79</Tables.Td>
              <Tables.Td>17</Tables.Td>
              <Tables.Td>1,2 (1,0- 1,5)</Tables.Td>
              <Tables.Td>+4 (0-9)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td colSpan={4}>
                <P>а - WHI исследование у женщин с удаленной маткой, у которых не выявлено повышения риска РМЖ.</P>
                <P>b - Когда анализ был ограничен женщинами, которые не применяли ЗГТ до исследования, не было
                  повышенного риска в течение первых 5 лет лечения, после 5 лет риск был выше, чем у женщин, не
                  применявших терапию.</P>
              </Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P><b><u>Риск рака эндометрия</u></b></P>
      <P><b>Женщины в постменопаузе с сохраненной маткой.</b></P>
      <P>Риск рака эндометрия составляет около 5 случаев на 1 000 женщин с сохраненной маткой, не применяющих ЗГТ.</P>
      <P>У женщин с сохраненной маткой применение ЗГТ в виде монотерапии эстрогеном не рекомендуется из-за повышенного
        риска рака эндометрия (см. раздел «Особые указания»). В зависимости от длительного применения только эстрогена и
        его дозы, повышенный риск рака эндометрия в эпидемиологических исследованиях составлял от 5 до 55 дополнительных
        случаев на одну из 1000 женщин в возрасте 50 - 65 лет.</P>
      <P>Добавление прогестагена к терапии только эстрогеном в течение не менее 12 дней на цикл может предотвратить этот
        риск. Данные «Исследования миллиона женщин» показали, что применение в течение 5 лет комбинированной ЗГТ (в
        циклическом или непрерывном режиме) не повышает риск рака эндометрия (ОР* 1,0 (0,8-1,2)).</P>
      <P><b><u>Рак яичников</u></b></P>
      <P>Применение ЗГТ в виде монотерапии эстрогеном или комбинированной терапии эстрогеном и прогестагеном связывают с
        чуть более повышенным риском диагностированного рака яичников (см. раздел «Особые указания»). Мета-анализ 52
        эпидемиологических исследований показал повышенный риск рака яичников у женщин на ЗГТ в сравнении с женщинами,
        которые никогда не применяли ЗГТ (ОР* 1,43, 95% ДИ*: 1,31-1,56). В отношении женщин в возрасте 50 - 54 лет
        применение ЗГТ в течение 5 лет приводило к одному дополнительному случаю на 2000 пациенток. Среди женщин в
        возрасте 50 - 54 лет, не принимавших ЗГТ, рак яичников диагностируется примерно у 2 женщин из 2000 за 5-летний
        период.</P>
      <P><b><u>Риск венозной тромбоэмболии</u></b></P>
      <P>ЗГТ связана с 1,3 - 3-х кратным повышением относительного риска развития венозной тромбоэмболии (ВТЭ), т.е.
        тромбоза глубоких вен или тромбоэмболии легочной артерии. Развитие такого осложнения наиболее вероятно в течение
        1-го года ЗГТ (см. раздел «Особые указания»).</P>
      <P><b><u>Предполагаемый дополнительный риск развития ВТЭ при применении свыше 5 лет (по результатам исследования
        «Инициативы по охране здоровья женщин» - US WHI).</u></b></P>

      <Tables.WrapperTable sx={{overflowX: 'auto'}}>
        <Tables.Table className="align-left" sx={{minWidth: 900, tableLayout: 'fixed'}}>
          <Tables.Thead>
            <Tables.Tr sx={{color: PinkLight}}>
              <Tables.Td><b>Возрастная группа (годы)</b></Tables.Td>
              <Tables.Td><b>Дополнительные случаи на 1 000 пациенток, ранее не получавших ЗГТ, за период 5
                летa</b></Tables.Td>
              <Tables.Td><b>Отношение риска (ОР*) (95% ДИ* b)</b></Tables.Td>
              <Tables.Td><b>Дополнительные случаи на 1 000 пациенток, ранее получавших ЗГТ, за период свыше 5 лет (95 %
                ДИ*)</b></Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td colSpan={4}>Пероральный прием только эстрогенаa</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-59</Tables.Td>
              <Tables.Td>7</Tables.Td>
              <Tables.Td>1,2 (0,6-2,4)</Tables.Td>
              <Tables.Td>1 (-3 - 10)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td colSpan={4}>Пероральный прием комбинации эстрогена и прогестагена</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-59</Tables.Td>
              <Tables.Td>4</Tables.Td>
              <Tables.Td>2,3 (1,2-4,3)</Tables.Td>
              <Tables.Td>5 (1-13)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td colSpan={4}>а - Исследование в отношении женщин с удаленной маткой.
              </Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P><b><u>Риск ишемической болезни сердца</u></b></P>
      <P>Риск ишемической болезни сердца незначительно выше у женщин в возрасте старше 60 лет на фоне комбинированной
        терапии эстрогеном и прогестагеном (см. раздел «Особые указания»).</P>
      <P><b><u>Риск ишемического инсульта</u></b></P>
      <P>ЗГТ в виде монотерапии эстрогеном или комбинированной терапии эстрогена с прогестагеном связано с 1,5-кратным
        повышением относительного риска ишемического инсульта. Риск геморрагического инсульта при ЗГТ не повышается.</P>
      <P>Данный относительный риск не зависит от возраста или продолжительности терапии, но исходный риск сильно зависит
        от возраста, общий риск инсульта у женщин, применяющих ЗГТ, с возрастом увеличивается. (см. раздел «Особые
        указания»).</P>
      <P><b><u>Предполагаемый дополнительный риск развития ишемического инсультаa при применении свыше 5 лет (по
        результатам исследования «Инициативы по охране здоровья женщин» - US WHI).</u></b></P>

      <Tables.WrapperTable sx={{overflowX: 'auto'}}>
        <Tables.Table className="align-left" sx={{minWidth: 900, tableLayout: 'fixed'}}>
          <Tables.Thead>
            <Tables.Tr sx={{color: PinkLight}}>
              <Tables.Td><b>Возрастная группа (годы)</b></Tables.Td>
              <Tables.Td><b>Дополнительные случаи на 1 000 пациенток, ранее не получавших ЗГТ, за период 5
                летa</b></Tables.Td>
              <Tables.Td><b>Отношение риска (ОР*) (95% ДИ* b)</b></Tables.Td>
              <Tables.Td><b>Дополнительные случаи на 1 000 пациенток, ранее получавших ЗГТ, за период свыше 5 лет (95 %
                ДИ*)</b></Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td colSpan={4}>Пероральный прием только эстрогенаa</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>50-59</Tables.Td>
              <Tables.Td>8</Tables.Td>
              <Tables.Td>1,3 (1,1-1,6)</Tables.Td>
              <Tables.Td>3 (1-5)</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>а - Не делали отличий между ишемическим и геморрагическим инсультом.</Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P>В случае начала ЗГТ у женщин в возрасте старше 65 лет повышается риск развития деменции (см. раздел «Особые
        указания»).</P>
      <P>* ОР - отношение риска;</P>
      <P>* ДИ - доверительный интервал.</P>

      <H2 id={'over-dozing'}>Передозировка</H2>
      <P><b>Симптомы:</b> при острой передозировке могут появиться тошнота, рвота, кровотечение из половых путей.</P>
      <P><b>Лечение:</b> специфического антидота нет, следует проводить симптоматическую терапию.</P>

      <H2 id={'another-medicines'}>Взаимодействие с другими лекарственными средствами</H2>
      <P>При необходимости применения других лекарственных препаратов следует ознакомиться с инструкциями по их
        применению для учета возможных потенциальных взаимодействий.</P>
      <P><b><u>Влияние других лекарственных средств на эффективность препарата Прогинова®</u></b></P>
      <P><b>Лекарственные средства, повышающие клиренс половых гормонов (снижение эффективности за счет индукции
        ферментов):</b></P>
      <P>Метаболизм эстрогенов (и прогестагенов) может увеличиваться при одновременном применении лекарственных средств,
        индуцирующих микросомальные ферменты печени (в частности, изоферменты системы цитохрома Р450), такими как,
        противоэпилептические препараты (фенобарбитал, фенитоин, карбамазепин), антибактериальные и противовирусные
        препараты (например, рифампицин, рифабутин, невирапин, эфавиренз), и возможно также фелбамат, гризеофульвин,
        окскарбазепин, топирамат и растительные препараты, содержащие зверобой продырявленный (Hypericum
        perforatum).</P>
      <P>Повышенный метаболизм эстрогенов (и прогестагенов) может привести к снижению их клинического эффекта и
        изменению профиля маточных кровотечений.</P>
      <P>Индукция микросомальных ферментов печени может наблюдаться уже через несколько дней совместного применения,
        максимальная индукция ферментов обычно наблюдается в течение нескольких недель. После прекращения совместного
        применения препарата- индуктора и эстрогена (и прогестагена) индукция микросомальных ферментов печени может
        сохраняться еще в течение 4 недель.</P>
      <P><b>Вещества с различным влиянием на клиренс половых гормонов:</b></P>
      <P><b>При совместном применении с половыми гормонами многие ингибиторы протеазы ВИЧ или вируса гепатита С и
        ненуклеозидные ингибиторы обратной транскриптазы могут как увеличивать, так и уменьшать концентрацию эстрогена
        или прогестагена в плазме крови. В некоторых случаях такое влияние может быть клинически значимо, в связи с чем
        необходимо ознакомиться с инструкцией по применению лекарственных препаратов для лечения ВИЧ/гепатита С, с целью
        выявления потенциальных взаимодействий.</b></P>
      <P><b>Вещества, снижающие клиренс половых гормонов (ингибиторы ферментов):</b></P>
      <P><b>Сильные и умеренные ингибиторы CYP3A4, такие как азольные противогрибковые препараты (например, флуконазол,
        итраконазол, кетоконазол, вориконазол), верапамил, макролиды (например, кларитромицин, эритромицин), дилтиазем и
        грейпфрутовый сок может повысить концентрации в плазме крови прогестагена или эстрогена, или обоих гормонов.</b></P>
      <P><b><u>Другие формы взаимодействия</u></b></P>
      <P><b>Результаты лабораторных исследований.</b></P>
      <P>Применение половых гормонов может повлиять на результаты определенных лабораторных исследований, включая
        биохимические параметры печени, щитовидной железы, показатели функции надпочечников и почек, концентрацию белков
        (носителей) в плазме крови, например, фракции ГСПГ, и фракции липидов/липопротеинов, параметры коагуляции и
        фибринолиза, показатели метаболизма глюкозы. Как правило, изменения находятся в пределах нормы (см. раздел
        «Особые указания»).</P>

      <H2 id={'special-instructions'}>Особые указания</H2>

      <P>ЗГТ с целью лечения симптомов дефицита эстрогенов в постменопаузе необходимо проводить только при наличии
        симптомов, неблагоприятно влияющих на качество жизни женщины. Не менее 1 раза в год следует проводить тщательную
        оценку соотношения «пользы-риска» применения препарата, продолжение терапии обосновано только в случае
        превышения ожидаемой пользы над возможными рисками.</P>
      <P>Имеются ограниченные данные в отношении рисков, связанных с ЗГТ при лечении преждевременной менопаузы. Ввиду
        низкого уровня абсолютного риска у молодых женщин соотношение «польза - риск» у них может быть более
        благоприятным, чем для женщин более старшего возраста.</P>
      <P><b>Медицинское обследование/наблюдение</b></P>
      <P>Перед началом или возобновлением ЗГТ после ее прерывания необходимо собрать подробный индивидуальный и семейный
        анамнез, провести общий и гинекологический осмотр (включая обследование молочных желез и органов малого таза). В
        период терапии рекомендуется проводить периодические медицинские осмотры, частота и характер которых
        определяется индивидуально. Женщина должна быть предупреждена о необходимости информирования лечащего врача в
        случае изменений в молочных железах. Обследования, включая соответствующие методы визуализации, например,
        маммографию, следует проводить в соответствии с принятыми в настоящее время стандартами обследования и в
        зависимости от каждого конкретного случая.</P>
      <P><b>Причины для немедленной отмены терапии</b></P>
      <P>Терапию препаратом следует немедленно прекратить в случае выявления противопоказания или при возникновении
        следующих состояний:</P>

      <UL>
        <LI><P>желтуха или ухудшение функции печени,</P></LI>
        <LI><P>значительное повышение артериального давления,</P></LI>
        <LI><P>возникновение головной боли по типу мигрени,</P></LI>
        <LI><P>беременности.</P></LI>
      </UL>

      <P><b>Гиперплазия и рак эндометрия</b></P>
      <P>У женщин с сохраненной маткой риск возникновения гиперплазии и рака эндометрия повышается при ЗГТ в виде
        монотерапии эстрогеном в течение длительного времени. Увеличение риска рака эндометрия при монотерапии
        эстрогеном составляет от 2 до 12 раз, в сравнении с женщинами, не получающими ЗГТ, в зависимости от
        продолжительности ЗГТ и дозы эстрогена. Поле прекращения ЗГТ риск может оставаться повышенным в течение не менее
        10 лет. Добавление прогестагена циклично в течение не менее 12 дней в каждом цикле или применение
        комбинированной (эстроген + прогестаген) ЗГТ в непрерывном режиме может предотвратить чрезмерный риск, связанный
        с монотерапией эстрогеном.</P>
      <P>При пероральном приеме эстрадиола в дозе более 2 мг, конъюгированных лошадиных эстрогенов в дозе {'>'} 0,625 мг
        и
        трансдермальных пластырей с содержанием эстрадиола {'>'}50 мкг/сутки, безопасность дополнительного применения
        прогестагена для эндометрия не была подтверждена.</P>
      <P>В течение первых месяцев применения препарата Прогинова® возможны кровянистые выделения или «прорывное»
        кровотечение. При появлении кровянистых выделений/кровотечений из влагалища через несколько месяцев после начала
        приема препарата или их сохранении после прекращения ЗГТ необходимо проведение соответствующего обследования для
        исключения злокачественных новообразований эндометрия (включая биопсию эндометрия). Пациентка должна быть
        информирована о необходимости сообщить лечащему врачу в случае появления или продолжающегося кровотечения из
        половых путей.</P>
      <P>Стимуляция эстрогеном может привести к предраковой или злокачественной трансформации в остаточных очагах
        эндометриоза. В связи с чем, при наличии диагностированных остаточных очагов эндометриоза у женщин с
        гистерэктомией по поводу эндометриоза требуется добавление прогестагена при ЗГТ в виде монотерапии
        эстрогеном.</P>
      <P><b>Рак молочной железы</b></P>
      <P>При проведении ЗГТ увеличивается риск РМЖ у женщин, получающих комбинированную терапию эстрогеном и
        прогестагеном, а также, возможно, получающих ЗГТ только эстрогеном. Риск развития РМЖ зависит от длительности
        ЗГТ.</P>
      <P>Комбинированная ЗГТ эстрогеном и прогестагеном</P>
      <P>Результаты эпидемиологических исследований и исследование WHI показали повышенный риск РМЖ на фоне
        комбинированной терапии ЗГТ эстрогеном и прогестагеном, примерно после 3 лет применения.</P>
      <P>ЗГТ только эстрогеном</P>
      <P>Исследование WHI не показало повышения риска развития РМЖ у женщин с гистерэктомией, получающих ЗГТ только
        эстрогеном. Наблюдательные исследования в основном выявили небольшое увеличение частоты РМЖ при монотерапии
        эстрогеном. Повышение риска было существенно ниже, чем при ЗГТ комбинацией эстрогена с прогестагеном.</P>
      <P>Повышенный риск РМЖ сохраняется после нескольких лет ЗГТ, уменьшается после ее прекращения и постепенно
        исчезает в течение нескольких лет (не более 5 лет).</P>
      <P><b>Рак яичников</b></P>
      <P>Рак яичников развивается значительно реже, чем РМЖ. Эпидемиологические данные большого мета-анализа показали,
        что ЗГТ только эстрогеном или комбинацией эстрогена с прогестагеном (не менее 5 лет) была сопряжена с небольшим
        увеличением риска рака яичников. Повышенный риск уменьшался с течением времени после прекращения ЗГТ.</P>
      <P>Результаты некоторых исследований, в том числе исследования WHI, свидетельствуют о том, что длительная ЗГТ
        комбинированными препаратами имеет сходный или незначительно меньший риск.</P>
      <P><b>ВТЭ</b></P>
      <P>ЗГТ связана с увеличением риска развития ВТЭ (тромбоза глубоких вен или тромбоэмболии легочной артерии) в 1,3 -
        3 раза. Вероятность развития ВТЭ выше в течение первого года применения ЗГТ, чем в более поздние сроки. У
        пациенток с подтвержденным тромбофилическим состоянием существует высокий риск ВТЭ, а ЗГТ может дополнительно
        его увеличивать. В связи с чем, ЗГТ у таких женщин противопоказана.</P>
      <P>Общепризнанными факторами риска ВТЭ является прием эстрогенов, пожилой возраст, обширные хирургические
        вмешательства, длительная иммобилизация, ожирение (индекс массы тела [ИМТ] {'>'} 30 кг/м2), беременность и
        послеродовой период, системная красная волчанка и рак. Не существует единого мнения относительно возможной роли
        варикозного расширения вен в развитии ВТЭ. После любого хирургического вмешательства необходимо проводить
        профилактику ВТЭ. В случае длительной иммобилизации, обусловленной плановым оперативным вмешательством, следует
        временно прекратить ЗГТ за 4-6 недель до операции и возобновить только после полного восстановления мобильности
        женщины. При отсутствии ВТЭ в анамнезе женщины, но при наличии тромбоза в возрасте менее 50 лет у ближайших
        родственников, рекомендуется провести скрининговое обследование, предварительно обсудив с женщиной все его
        ограничения (скрининг позволяет выявить только ряд тромбофилических нарушений). При выявлении нарушения, не
        соответствующего заболеванию у родственников, либо при обнаружении «тяжелого» дефекта (например, дефицита
        антитромбина III, протеина S или протеина С, либо их сочетанием) ЗГТ эстрадиолом противопоказана.</P>
      <P>Для женщин, получающих длительное лечение антикоагулянтами, требуется тщательное рассмотрение соотношение
        «пользы-риска» ЗГТ.</P>
      <P>В случае развития ВТЭ прием препарата следует немедленно прекратить. Женщина должна быть проинформирована о
        необходимости немедленного обращения к врачу при появлении возможных признаков тромбоэмболических осложнений
        (например, отека или болезненности по ходу вены нижней конечности, внезапной боли в груди, одышки и т.д.).</P>
      <P><b>Ишемическая болезнь сердца (ИБС)</b></P>
      <P>В рандомизированных контролируемых клинических исследованиях не было получено данных, свидетельствующих, что
        ЗГТ комбинированными препаратами или монотерапия эстрогеном могут предупреждать развитие инфаркта миокарда у
        женщин с ИБС или без нее.</P>
      <P>Монотерапия эстрогенами</P>
      <P>По данным рандомизированных контролируемых клинических исследований у женщин с гистерэктомией в анамнезе риск
        ИБС при ЗГТ только эстрогеном не увеличивается.</P>
      <P>Комбинированная ЗГТ эстрогеном и прогестагеном</P>
      <P>Относительный риск ИБС несколько увеличивается при ЗГТ комбинированными (эстроген + гестаген) препаратами.
        Поскольку базовый абсолютный риск ИБС в значительной мере зависит от возраста, количество дополнительных случаев
        ИБС, обусловленных ЗГТ комбинированными препаратами, у здоровых женщин в близком к менопаузе возрасте очень
        мало, но будет увеличиваться с возрастом.</P>
      <P><b>Ишемический инсульт</b></P>
      <P>ЗГТ комбинированными препаратами (эстроген + гестаген) и ЗГТ только эстрогеном сопряжена с увеличением риска
        ишемического инсульта в 1,5 раза. Относительный риск с возрастом и временем после наступления менопаузы не
        меняется. Однако исходный риск инсульта в большей степени зависти от возраста, соответственно и общий риск
        ишемического инсульта на фоне ЗГТ увеличивается с возрастом. Риск геморрагического инсульта при ЗГТ не
        увеличивается.</P>
      <P><b>Другие состояния</b></P>
      <P>Эстрогены могут вызывать задержку жидкости, в связи с чем, пациентки с хронической сердечной и почечной
        недостаточностью должны находиться под тщательным врачебным наблюдением.
      </P>
      <P>Необходимо тщательно контролировать женщин с ранее существовавшей гипертриглицеридемией, потому что в редких
        случаях ЗГТ эстрогенами вызвала значительное увеличение концентрации триглицеридов в плазме крови, что приводило
        к развитию панкреатита.
      </P>
      <P>Эстрогены вызывают увеличение концентрации тироксинсвязывающего глобулина (ТСГ), что приводит к увеличению
        концентрации общих циркулирующих гормонов щитовидной железы, которую определяют по концентрации связанного с
        белками йода или методом радиоиммунного анализа - трийодтиронина (Т4) или тироксина (ТЗ). Захват Т4 снижается,
        что указывает на повышенную концентрацию ТСГ. Концентрации в плазме крови свободного ТЗ и Т4 не изменяются.
        Концентрация других связывающих белков в плазме крови, кортикостероидсвязывающего глобулина (КСГ) может
        повышаться, что приводит к увеличению концентрации циркулирующих кортикостероидов и половых гормонов
        соответственно. Концентрации свободных или биологически активных гормонов не изменяются.</P>
      <P>Также может увеличиваться концентрация других белков плазмы (ангиотензин-ренин- субстрат, альфа-1-антитрипсин,
        церулоплазмин).</P>
      <P>Когнитивная функция на фоне ЗГТ не улучшается. Имеются данные о повышенном риске развития деменции у женщин при
        начале ЗГТ комбинированными препаратами или монотерапии эстрогеном после 65 лет.</P>
      <P>Следует проводить тщательный врачебный мониторинг (с регулярным определением концентрации пролактина) у
        пациенток с пролактиномой. В некоторых случаях может возникать хлоазма, особенно у женщин с хлоазмой беременных
        в анамнезе. Женщинам с предрасположенностью к хлоазме следует избегать воздействия солнца или ультрафиолетового
        излучения при приеме ЗГТ.</P>

      <H2 id={'managment-transport'}>Влияние на способность управления транспортными средствами, механизмами
      </H2>
      <P>
        Исследований влияния препарата на способность к управлению транспортными средствами и механизмами не
        проводилось. Влияния на способность управлять транспортными средствами и механизмами при применении препарата
        Прогинова® не выявлено.
      </P>

      <H2 id={'form-production'}>Форма выпуска</H2>
      <P>Драже, 2 мг.</P>
      <P>По 21 драже в блистер из ПВХ пленки и алюминиевой фольги. По 1 блистеру вместе с инструкцией по применению
        помещают в картонную пачку.</P>

      <H2 id={'keeper'}>Условия хранения</H2>
      <P>Хранить при температуре не выше 25 C</P>
      <P>Хранить в недоступном для детей месте!</P>

      <H2 id={'deadline'}>Срок годности</H2>
      <P>5 лет.</P>
      <P>Не применять по истечении срока годности, указанного на упаковке.</P>

      <H2 id={'holiday'}>Условия отпуска</H2>
      <P>Отпускают по рецепту.</P>

      <H2 id={'owner'}>Владелец (держатель) Регистрационного удостоверения</H2>
      <P>Алвоген Фарма Трейдинг Юроп ЕООД, Болгария.</P>
      <P>Бульвар Болгария, 86а, 1 этаж, София, 1680, Болгария.</P>

      <H2 id={'producer'}>Производитель</H2>
      <P><b>Все стадии производственного процесса:</b></P>
      <P>
        Дельфарм Лилль С.А.С., Франция<br/>
        Индустриальная зона Рубэ-Восток, рю де Туффле 22, CS 50070, 59452 ЛИС ЛЕ
        ЛАННУА, Седекс, Франция / Delpharm Lille SAS,<br/>
        Parc d’Activitités Roubaix-Est, 22 rue de Toufflers CS 50070, LYS LEZ LANNOY, 59452, France.
      </P>
      <P><b>Организация, принимающая претензии потребителей</b>

      </P>
      <P>ООО «АЛВОГЕН ФАРМА», Россия, 127055, г. Москва, ул. Новослободская, д. 31, строение 4, помещение VI </P>
      <P>тел. +7 (499) 940-01-77, факс +7 (499) 940-01-55.</P>

      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Инструкция PDF
      </a>
    </Page>
  )
}
