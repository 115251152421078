import {Box} from '@mui/material';
import {useHistory} from 'react-router-dom';

import { PinkLight} from '../../../../styles/color';
import Page from 'shared/ui/Page';
import {
  Wrapper,
} from './ui';
import {
  StyledTab,
  StyledCardBorderLine,
  StyledBoxShadow,
  StyledCircle,
  Sup
} from '../ui';
import {ListItem, List, Tables} from 'shared/ui';
import iconTarget from './img/iconTarget.png'
import borders from './img/borders.png'
import img1 from './img/img1.png'
import img2 from './img/img2.png'
import img3 from './img/img3.png'
import img4 from './img/img4.png'
import img5 from './img/img5.png'
import img6 from './img/img6.png'
import img7 from './img/img7.png'
import img8 from './img/img8.png'

export const EffectiveSalesVisitsAprilJulyDydrogesterone = () => {
  const history = useHistory();

  const handleRedirectToBack = () => {
    history.push('/effective-sales/to-doctor');
  }

  return (
    <Page
      head={'ВИЗИТЫ: АПРЕЛЬ-ИЮЛЬ'}
      backText="Назад"
      onClose={handleRedirectToBack}
    >
      <Box sx={{display: 'flex', gap: '20px', mb: '50px'}}>
        <StyledTab to="/effective-sales/visits-april-july-proginova">Прогинова</StyledTab>
        <StyledTab to="/effective-sales/visits-april-july-alvovizan">Алвовизан</StyledTab>
        <StyledTab className="active" to="/effective-sales/visits-april-july-dydrogesterone">Исследование:
          дидрогестерон</StyledTab>
      </Box>
      <Wrapper sx={{display: 'flex', gap: '40px', flexDirection: 'column'}}>
        <Box className="pink" sx={{fontSize: 28, lineHeight: '33px', fontWeight: 600}}>
          Исследование, подтверждающее тератогенный эффект дидрогестерона (входит в состав ЛС Фемостон)
        </Box>
        <StyledBoxShadow sx={{padding: '40px 20px'}}>
          <Box className="header pink">
            Оценка влияния дидрогестерона на частоту врожденных пороков у новорожденных
          </Box>
          <Box sx={{display: 'flex', gap: '20px', mt: 3}}>
            <Box>
              <StyledCardBorderLine sx={{textAlign: 'center'}}>
                <Box className="pink" sx={{fontSize: 28, lineHeight: '33px', fontWeight: 600, mb: 2}}>
                  777 422
                </Box>
                <Box>новорожденных</Box>
              </StyledCardBorderLine>
              <Box sx={{textAlign: 'center'}}>
                <img src={borders}/>
              </Box>
              <Box sx={{display: 'flex', gap: '20px', mt: '-5px'}}>
                <StyledCardBorderLine>
                  <Box className="white" sx={{fontSize: 28, lineHeight: '33px', fontWeight: 600, mb: 2}}>
                    8 508
                  </Box>
                  <Box>
                    детей, подвергшихся внутриутробному воздействию дидрогестерона
                  </Box>
                </StyledCardBorderLine>
                <StyledCardBorderLine className="pink">
                  <Box sx={{fontSize: 28, lineHeight: '33px', fontWeight: 600, mb: 2}}>
                    768 914
                  </Box>
                  <Box>
                    детей, не подвергавшихся воздействию дидрогестерона
                  </Box>
                </StyledCardBorderLine>
              </Box>
            </Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px'}}>
              <StyledCircle>
                <img src={iconTarget}/>
              </StyledCircle>
              <Box>
                <Box sx={{fontWeight: 600}}>В группе дидрогестерона была выше частота инцидентов*:</Box>
                <List>
                  <ListItem sx={{mb: 1}}>
                    гипоспадий (порок развития мочеполовой системы у детей) — <span
                    style={{fontWeight: 600}}>в 1,3 раза</span>
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    общий риск пороков развития сердечно-сосудистой системы — <span
                    style={{fontWeight: 600}}>в 1,2 раза</span>
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    spina bifida (расщепление позвоночника) — <span style={{fontWeight: 600}}>в 2,3 раза</span>
                  </ListItem>
                  <ListItem>
                    гидроцефалии (водянка головного мозга) — <span style={{fontWeight: 600}}>в 2 раза</span>
                  </ListItem>
                </List>
              </Box>
            </StyledCardBorderLine>
          </Box>

          <Box sx={{fontSize: 12, fontWeight: 400, lineHeight: '20px', mt: '20px'}}>
            * с учетом скорректированного воздействия экстракорпорального оплодотворения и других форм вспомогательных
            репродуктивных технологий
            Koren G., D Gilboa. Fetal outcome following dydrogesterone exposure in pregnancy. Archives of Disease in
            Childhood 2019;104:e2.
            Koren, G., Gilboa, D. & Katz, R. RETRACTED ARTICLE: Fetal Safety of Dydrogesterone Exposure in the First
            Trimester of Pregnancy. Clin Drug Investig 40, 679 (2020).
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{padding: '40px 20px'}}>
          <Box className="header pink">
            Сравнение группы новорожденных с врожденными пороками сердца (ВПС) и без ВПС
          </Box>
          <Tables.WrapperTable sx={{mt: 3}}>
            <Tables.Table className="align-left" sx={{fontSize: 18, minWidth: 900, color: PinkLight}}>
              <Tables.Thead>
                <Tables.Tr sx={{fontWeight: 'bold', color: PinkLight, backgroundColor: '#f9d2d633', fontSize: 18}}>
                  <Tables.Td>Факторы риска</Tables.Td>
                  <Tables.Td sx={{width: '240px'}}>Случаи ВПС (n=202)</Tables.Td>
                  <Tables.Td sx={{width: '240px'}}>Контрольная группа без ВПС (n=200)</Tables.Td>
                  <Tables.Td sx={{width: '240px'}}>Достоверность</Tables.Td>
                </Tables.Tr>
              </Tables.Thead>
              <Tables.Body>
                <Tables.Tr>
                  <Tables.Td>Кол-во циклов</Tables.Td>
                  <Tables.Td>26 (13%)</Tables.Td>
                  <Tables.Td>13 (6,5%)</Tables.Td>
                  <Tables.Td>p=0,069</Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>Применение дидрогестерона</Tables.Td>
                  <Tables.Td>75 (37.1 %)</Tables.Td>
                  <Tables.Td>36 (18 %)</Tables.Td>
                  <Tables.Td sx={{fontWeight: 600, color: '#FFF'}}>p=0,001</Tables.Td>
                </Tables.Tr>
              </Tables.Body>
            </Tables.Table>
          </Tables.WrapperTable>

          <Tables.WrapperTable sx={{mt: 3}}>
            <Tables.Table className="align-left" sx={{fontSize: 18, minWidth: 900, color: PinkLight}}>
              <Tables.Thead>
                <Tables.Tr sx={{fontWeight: 'bold', color: PinkLight, backgroundColor: '#f9d2d633', fontSize: 18}}>
                  <Tables.Td>Факторы риска</Tables.Td>
                  <Tables.Td sx={{width: '240px'}}>Достоверность</Tables.Td>
                  <Tables.Td sx={{width: '240px'}}>Скорректированная достоверность</Tables.Td>
                  <Tables.Td sx={{width: '240px'}}>Увеличение относительного риска</Tables.Td>
                </Tables.Tr>
              </Tables.Thead>
              <Tables.Body>
                <Tables.Tr>
                  <Tables.Td>Семейная история ВПС</Tables.Td>
                  <Tables.Td>0.024</Tables.Td>
                  <Tables.Td>0.039</Tables.Td>
                  <Tables.Td sx={{fontWeight: 600, color: '#FFF'}}>2.24 (1.04–5.59)</Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>Применение дидрогестерона</Tables.Td>
                  <Tables.Td>0.001</Tables.Td>
                  <Tables.Td>0.001</Tables.Td>
                  <Tables.Td sx={{fontWeight: 600, color: '#FFF'}}>2.71 (1.64–4.24)</Tables.Td>
                </Tables.Tr>
              </Tables.Body>
            </Tables.Table>
          </Tables.WrapperTable>

          <StyledCardBorderLine sx={{mt: '20px'}}>
            <List className="pink" sx={{mb: 0}}>
              <ListItem className="marker-pink" sx={{mb: 1}}>
                Частота применения дидрогестерона была в 2 раза выше в группе детей с врожденными пороками сердца (ВПС)
              </ListItem>
              <ListItem className="marker-pink">
                Относительный риск развития ВПС был в 2,7 раза выше у новорожденных, применявших дидрогестерон
              </ListItem>
            </List>
          </StyledCardBorderLine>

          <StyledCardBorderLine
            sx={{mt: '20px', mb: '20px', backgroundColor: '#f9d2d633', color: PinkLight, fontWeight: 600}}>
            Частота инцидентов врожденных пороков сердца (ВПС) в мире от 4 до 50 на 1000 новорожденных (в среднем 10
            на 1000)<Sup sx={{color: PinkLight}}>2</Sup>
          </StyledCardBorderLine>

          <ol style={{fontSize: 12, lineHeight: '14px', margin: 0, paddingLeft: '15px'}}>
            <li style={{marginBottom: '4px'}}>
              Zaqout M, Aslem E, Abuqamar M, Abughazza O, Panzer J, De Wolf D. The Impact of Oral Intake of
              Dydrogesterone on Fetal Heart Development During Early Pregnancy. Pediatr Cardiol. 2015 Oct;36(7):1483-8.
            </li>
            <li>
              Hoffman JI, Kaplan S. The incidence of congenital heart disease. J Am Coll Cardiol. 2002 Jun
              19;39(12):1890-900.
            </li>
          </ol>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{padding: '40px 20px'}}>
          <Box>
            <img src={img1} style={{maxWidth: '100%'}}/>
          </Box>

          <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '20px'}}>
            <Box>
              <img src={img2}/>
            </Box>
            <Box>
              <Box sx={{fontWeight: 600}}>
                Риск врожденных дефектов при применении дидрогестерона был выше:
              </Box>
              <List>
                <ListItem sx={{mb: 1}}>
                  по сравнению с любым другим лекарственным средством — <span
                  style={{fontWeight: 600}}>в 5.4 раза</span> (95% ДИ [3,9-7,6])
                </ListItem>
                <ListItem sx={{mb: 1}}>
                  по сравнению с любым другим агентом, используемым для вспомогательных репродуктивных технологий —
                  <span style={{fontWeight: 600}}>в 5,9 раз</span> (95% ДИ [4,2-8,4])
                </ListItem>
                <ListItem>
                  по сравнению с прогестероном — <span style={{fontWeight: 600}}>в 5,4 раза</span> (95% ДИ [3,7-7,9])
                </ListItem>
              </List>
            </Box>
          </StyledCardBorderLine>

          <Box sx={{fontSize: 12, lineHeight: '14px', fontWeight: 400, mt: '20px'}}>
            A. Henry1,2, P. Santulli3, M. Bourdon3, J.M. Treluyer1,2, L. Chouchana1,2Birth defects reporting and the use
            of oral dydrogesterone in assisted reproductive technology: a global pharmacovigilance study. 39th Hybrid
            Annual Meeting of the ESHRE, Copenhagen – Denmark, 25-28 June 2023. Abstract citation ID: dead093.177
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{padding: '40px 20px'}}>
          <Box sx={{mb: '20px'}}>
            <img src={img3} style={{maxWidth: '100%'}}/>
          </Box>
          <Box sx={{mb: '20px'}}>
            <img src={img4} style={{maxWidth: '100%'}}/>
          </Box>
          <Box sx={{mb: '20px'}}>
            <a
              style={{fontSize: 14, lineHeight: '14px', fontWeight: 400, textDecoration: 'underline', color: 'white'}}
              href="https://sds.edqm.eu/pdf/SDS/EDQM_201600331_1.0_SDS_EN.pdf?ref=1597466882">https://sds.edqm.eu/pdf/SDS/EDQM_201600331_1.0_SDS_EN.pdf?ref=1597466882
            </a>
          </Box>
          <Box sx={{mb: '20px', mt: '40px'}}>
            <img src={img5} style={{maxWidth: '100%'}}/>
          </Box>
          <Box sx={{mt: '20px', lineHeight: '15px', fontSize: 12, mb: '20px'}}>
            *Мутации, возникающие в зародышевых клетках, способны передаваться потомству<Sup sx={{color: '#FFF'}}>2</Sup>.
          </Box>
          <ol style={{fontSize: 12, lineHeight: '14px', margin: 0, paddingLeft: '15px'}}>
            <li style={{marginBottom: '10px'}}>
              <a
                style={{fontSize: 14, lineHeight: '14px', fontWeight: 400, textDecoration: 'underline', color: 'white'}}
                href="https://www.trc-canada.com/prod-img/MSDS/D826002MSDS.pdf">https://www.trc-canada.com/prod-img/MSDS/D826002MSDS.pdf
              </a>
            </li>
            <li>
              ГОСТ Р 57454-2017 НАЦИОНАЛЬНЫЙ СТАНДАРТ РОССИЙСКОЙ ФЕДЕРАЦИИ «РУКОВОДСТВО ПО ПРИМЕНЕНИЮ КРИТЕРИЕВ
              КЛАССИФИКАЦИИ ОПАСНОСТИ ХИМИЧЕСКОЙ ПРОДУКЦИИ ПО ВОЗДЕЙСТВИЮ НА ОРГАНИЗМ» от 2018-01-01
            </li>
          </ol>
          <Box sx={{mt: '20px', lineHeight: '15px', fontSize: 12}}>
            РАЗРАБОТАН Техническим комитетом по стандартизации ТК 339 "Химическая безопасность веществ и
            материалов"<br/>
            ВНЕСЕН Федеральным агентством по техническому регулированию и метрологии<br/>
            УТВЕРЖДЕН И ВВЕДЕН В ДЕЙСТВИЕ Приказом Федерального агентства по техническому регулированию и метрологии от
            27 апреля 2017 г. N 333-ст<br/><br/>
          </Box>
          <Box sx={{mb: '20px', mt: '20px'}}>
            <img src={img6} style={{maxWidth: '100%'}}/>
          </Box>
          <a
            style={{fontSize: 14, lineHeight: '14px', fontWeight: 400, textDecoration: 'underline', color: 'white'}}
            href="https://www.trc-canada.com/prod-img/MSDS/D826002MSDS.pdf">https://www.trc-canada.com/prod-img/MSDS/D826002MSDS.pdf
          </a>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{padding: '40px 20px'}}>
          <Box className="header pink">
            Дидрогестерон был отозван с рынков США, Великобритании, Австралии<Sup className="pink">1</Sup>
          </Box>

          <List>
            <ListItem sx={{mb: 1}}>
              США: дидрогестерон в настоящее время не доступен в Соединенных Штатах; он был выведен с рынка по
              коммерческим причинам. Для США дидрогестерон был зарегистрирован в 1961 году и лицензия передана с годами
              нескольким компаниям. В 1997 году нынешний новый владелец заявки на лекарства, Solvay, отозвал продукт,
              потому что зарегистрированные показания больше не были коммерчески жизнеспособными и/или существовал
              потенциально противоречивый интерес в отношении других продуктов, обладателем лицензии которых является
              Solvay.
            </ListItem>
            <ListItem sx={{mb: 1}}>
              Великобритания: продукт был изъят с рынка Соединенного Королевства в 2008 году. Австралия: был изъят в
              2011 году по коммерческим причинам.
            </ListItem>
            <ListItem>
              Для Соединенного Королевства и Австралии низкие продажи сравнительно дешевого препарата и отсутствие новых
              и коммерчески интересных признаков обусловили уход с рынков
            </ListItem>
          </List>
          <Box sx={{fontSize: 14, lineHeight: '14px', fontWeight: 400}}>
            Griesinger G, Blockeel C, Tournaye H. Oral dydrogesterone for luteal phase support in fresh in vitro
            fertilization cycles: a new standard? Fertil Steril. 2018 May; 109(5):756-762.
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{padding: '40px 20px'}}>
          <Box className="header pink">
            Дидрогестерон был отозван с рынков США, Великобритании, Австралии<Sup className="pink">1</Sup>
          </Box>

          <Box sx={{mb: '20px', mt: '40px'}}>
            <img src={img7} style={{maxWidth: '100%'}}/>
          </Box>

          <a
            style={{fontSize: 14, lineHeight: '14px', fontWeight: 400, textDecoration: 'underline', color: 'white'}}
            href="https://www.accessdata.fda.gov/scripts/cder/daf/index.cfm?event=overview.process&ApplNo=017388">
            https://www.accessdata.fda.gov/scripts/cder/daf/index.cfm?event=overview.process&ApplNo=017388
          </a>

        </StyledBoxShadow>

        <StyledBoxShadow sx={{padding: '40px 20px'}}>
          <Box className="header pink">
            Дидрогестерон был отозван в ОАЭ, причины неизвестны
          </Box>

          <Box sx={{ mt: '40px'}}>
            <img src={img8} style={{maxWidth: '100%'}}/>
          </Box>
        </StyledBoxShadow>
      </Wrapper>
    </Page>
  )
}
