import {useHistory} from 'react-router-dom'
import { Box } from '@mui/material';

import Page from 'shared/ui/Page';
import postZoely from './img/super-zoely.jpg';
import postAlvovizan from './img/super-alvovizan.png';
import postProginova from './img/super-proginova.jpg';
import main from './img/siriusz_helper.png';
import arrow from './img/arrow.svg';
import {StyledLink} from './ui';
import {WrapperShell} from '../../components/wrapper-shell';

export const Promotions = () => {
  const history = useHistory();
  const handleRedirectToProducts = () => {
    history.push('/');
  }

  return (
    <>
      <Page head={'SUPER АКЦИИ'} backText="Продукты" onClose={handleRedirectToProducts}>
        <WrapperShell sx={{p:0, maxHeight: 417}}>
          <img src={main} style={{maxWidth: '100%'}}/>
          <StyledLink href="promotions/siriusz-helper" sx={{position: 'absolute', m: '-80px 70px'}}>
            Узнать больше
            <img className="svg" src={arrow} />
          </StyledLink>
        </WrapperShell>
        <Box sx={{display: 'flex', gap: 3, margin: '42px 0 52px'}}>
          <StyledLink href="#proginova">ПРОГИНОВА</StyledLink>
          <StyledLink href="#zoeli">ЗОЭЛИ</StyledLink>
          <StyledLink href="#alvovizan">АЛВОВИЗАН</StyledLink>
        </Box>
        <img id="proginova" alt="Прогинова. Супер цена"  style={{maxWidth: "100%", marginBottom: '20px'}}  src={postProginova}/>
        <StyledLink href="product/proginova/modal" sx={{fontSize: 24, padding: '10px 30px', lineHeight: '28px', height: 60, mb: 7}}>
          Презентация
          <img className="svg" width={24} src={arrow} />
        </StyledLink>
        <img id="zoeli"  alt="Зоэли. Супер цена" style={{maxWidth: "100%",  marginBottom: '20px'}} src={postZoely}/>
        <StyledLink href="product/eoeli" sx={{fontSize: 24, padding: '10px 30px', lineHeight: '28px', height: 60, mb: 7}}>
          Презентация
          <img className="svg" width={24} src={arrow} />
        </StyledLink>
        <img id="alvovizan"  alt="Алвовизан. Супер цена" style={{maxWidth: "100%", marginBottom:  '20px'}} src={postAlvovizan}/>
        <StyledLink href="product/alvovizan/modal" sx={{fontSize: 24, padding: '10px 30px', lineHeight: '28px', height: 60, mb: 20}}>
          Презентация
          <img className="svg" width={24} src={arrow} />
        </StyledLink>
      </Page>
    </>
  )
}
