import {styled} from '@mui/system';
import {Box} from '@mui/material';
import {Black, PinkLight} from '../../../../styles/color';

export const StyledTabs = styled(Box)`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`

export const StyledTab = styled(Box)({
  backgroundColor: PinkLight,
  color: Black,
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '19px',
  borderRadius: 60,
  padding: '10px 30px',
  fontFamily: 'SF Pro Text',
  boxSizing: 'border-box',
  border: '1px solid #F7E5E0',
  boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',

  '&.active': {
    backgroundColor: Black,
    color: PinkLight,
  },

  '&:hover': {
    backgroundColor: Black,
    color: PinkLight,
  },
})
