import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const VelledienLinkEleven = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>The effects of tibolone in older postmenopausal women</H1>
      <P><b>Background:</b> Tibolone has estrogenic, progestogenic, and androgenic effects. Although tibolone prevents
        bone loss, its effects on fractures, breast cancer, and cardiovascular disease are uncertain.</P>
      <P><b>Methods:</b> In this randomized study, we assigned 4538 women, who were between the ages of 60 and 85 years
        and had a bone mineral density T score of -2.5 or less at the hip or spine or a T score of -2.0 or less and
        radiologic evidence of a vertebral fracture, to receive once-daily tibolone (at a dose of 1.25 mg) or placebo.
        Annual spine radiographs were used to assess for vertebral fracture. Rates of cardiovascular events and breast
        cancer were adjudicated by expert panels.</P>
      <P><b>Results:</b> During a median of 34 months of treatment, the tibolone group, as compared with the placebo
        group, had a decreased risk of vertebral fracture, with 70 cases versus 126 cases per 1000 person-years
        (relative hazard, 0.55; 95% confidence interval [CI], 0.41 to 0.74; P{'<'}0.001), and a decreased risk of
        nonvertebral fracture, with 122 cases versus 166 cases per 1000 person-years (relative hazard, 0.74; 95% CI,
        0.58 to 0.93; P=0.01). The tibolone group also had a decreased risk of invasive breast cancer (relative hazard,
        0.32; 95% CI, 0.13 to 0.80; P=0.02) and colon cancer (relative hazard, 0.31; 95% CI, 0.10 to 0.96; P=0.04).
        However, the tibolone group had an increased risk of stroke (relative hazard, 2.19; 95% CI, 1.14 to 4.23;
        P=0.02), for which the study was stopped in February 2006 at the recommendation of the data and safety
        monitoring board. There were no significant differences in the risk of either coronary heart disease or venous
        thromboembolism between the two groups.</P>
      <P><b>Conclusions:</b> Tibolone reduced the risk of fracture and breast cancer and possibly colon cancer but
        increased the risk of stroke in older women with osteoporosis. (ClinicalTrials.gov number, NCT00519857.)</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/18703472/">Cummings SR, et al. The effects of tibolone in
        older postmenopausal women. N Engl J Med. 2008;359:697-708.</A>]
      </P>

      <H1 sx={{color: PinkLight}}>Progestogens and venous thromboembolism among postmenopausal women using hormone
        therapy</H1>
      <P>
        Hormone therapy (HT) is the most effective treatment for correcting menopausal symptoms after menopause. HT
        initially consisted of estrogens alone and progestogens were secondly added to estrogens for preventing the risk
        of endometrial cancer associated to estrogens use. Venous thromboembolism (VTE), including deep vein thrombosis
        and pulmonary embolism, is a major harmful effect of HT. It is now well known that oral and transdermal
        estrogens are differentially associated with VTE risk but progestogens may be another important determinant of
        the thrombotic risk among HT users. Both randomized controlled trials and meta-analysis of observational studies
        suggested that the VTE risk was higher among users of estrogens plus progestogens than among users of estrogens
        alone. With respect to the different pharmacological classes of progestogens, there is evidence for a
        deleterious effect of medroxyprogesterone acetate on VTE risk. In addition, observational studies showed that
        norpregnane derivatives were significantly associated with an increased VTE risk whereas micronized progesterone
        could be safe with respect to thrombotic risk. The effect of tibolone on VTE risk remains uncertain. In
        conclusion, progestogens may have differential effects on VTE risk according to the molecules and therefore
        represent an important potential determinant of the thrombotic risk among postmenopausal women using estrogens.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/22024394/">Canonico M, Plu-Bureau G, Scarabin P-Y.
        Progestogens and venous thromboembolismamong postmenopausal women using hormone therapy. Maturitas.
        2011;70(4):354-60.</A>]
      </P>
    </Page>
  )
}
