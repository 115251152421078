import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanSecondLinkSeven = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>Long-term dienogest administration in patients with symptomatic adenomyosis</H1>
      <P><b>Aim:</b> Adenomyosis is a common gynecological disorder that causes dysmenorrhea, hypermenorrhea and metrorrhagia.
        Previously, we reported that 24 weeks of dienogest treatment is highly effective for pain in symptomatic
        adenomyosis. Up to present, there is no report that describes treatment of adenomyosis with long-term dienogest
        administration for more than 2 years. In this retrospective cohort study, we investigated the course of
        long-term dienogest treatment in patients with symptomatic adenomyosis.
      </P>
      <P><b>Methods:</b> This is a retrospective cohort study. Dienogest was continuously administered at a dose of 2 mg daily
        for patients with symptomatic adenomyosis. The outcome of long-term administration of dienogest was
        investigated, and the characteristics of patients were compared between discontinued cases and long-term
        administration cases.
      </P>
      <P><b>Results:</b> Two patients were excluded from this study because of transfer to another hospital or discontinuation
        due to infertility treatment. Twelve of 18 patients (66.7%) received dienogest until menopause or for a period
        of `{'>'}`80 months. Four cases (22.2%) discontinued dienogest treatment because of severe metrorrhagia. In the
        discontinued cases because of severe metrorrhagia, the pain score for dysmenorrhea and serum CA125 level at
        baseline significantly elevated, and the hemoglobin level at baseline and the frequency of type 2 adenomyosis
        significantly decreased, compared to those with long-term use. Moreover, long-term dienogest use did not
        decrease the serum estradiol level.
      </P>
      <P><b>Conclusion:</b> Our report suggests that dienogest is tolerable for long-term use until menopause and can be an
        alternative treatment option in some patients, especially those with type 2 adenomyosis, to avoid hysterectomy.
      </P>
      <P>
        [ссылка: <A
        href="https://pubmed.ncbi.nlm.nih.gov/29845696/">Neriishi,
        Kazuaki, et al. «Long-term dienogest administration in patients with symptomatic adenomyosis.» Journal of
        Obstetrics and Gynaecology Research (2018).</A>]
      </P>
    </Page>
  )
}
