import {Box} from '@mui/material';

import {StyledAnchorLink, StyledHeader} from '../ui';
import {StyledBoxBorder, StyledBoxInsetShadow, StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {Ticket} from './ticket';
import {HeaderTabs as Tabs} from './header-tabs';

type HeaderTabsProps = {
  tabActive: number
}
const HeaderTabs = ({tabActive}: HeaderTabsProps) => <Tabs tabActive={tabActive} tabLength={3}/>

export const SubpageVelledien = () => {
  return (
    <>
      <StyledHeader>Велледиен</StyledHeader>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <StyledAnchorLink href="#theme1">
          <img src={icons.arrow} alt="*"/>
          Тема #1: Перевод пациенток в постменопаузе, принимающих Ливиал на Велледиен
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme2">
          <img src={icons.arrow} alt="*"/>
          Тема #2: Перевод пациенток в постменопаузе, принимающих циклическую МГТ на Велледиен
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme3">
          <img src={icons.arrow} alt="*"/>
          Тема #3: Перевод пациенток в постменопаузе, принимающих Анжелик на Велледиен
        </StyledAnchorLink>
      </Box>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <HeaderTabs tabActive={0}/>
        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток в постменопаузе, принимающих Ливиал на Велледиен.
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила ВЕЛЛЕДИЕН <b>5 пациенткам</b> в постменопаузе в течение месяца вместо
                Ливиала.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения пациенток <b>в постменопаузе.</b>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Пример продуктивного вопроса для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки для подбора МГТ в постменопаузе?
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки в постменопаузе с жалобами на климактерические симптомы?
                </Box>
                <Box>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких пациенток на приёме около 1–2 в день. А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <ol className="list">
                  <li>
                    Скольким из этих пациенток Вы назначаете Ливиал?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы назначаемый препарат был не только эффективным и безопасным, но и
                    имел социально-ответственную стоимость?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            Назначая Велледиен — тиболон европейского качества по цене на 50% доступнее оригинального препарата, Вы
            обеспечиваете максимальное количество пациенток в постменопаузе эффективной МГТ без риска для молочной
            железы.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              В настоящее время в России наблюдается постоянный рост цен.
            </Box>
            <Box sx={{mb: '12px'}}>
              Многие пациентки не могут позволить себе терапию оригинальным препаратом.
            </Box>
            <Box sx={{mb: '12px'}}>
              Назначая оригинальный препарат доктор в половине случаев не назначает ничего, так как пациентка, услышав в
              аптеке цену, не купит его.
            </Box>
            <Box sx={{mb: '12px'}}>
              Мы подготовили фармакоэкономическое обоснование по препаратам МГТ в постменопаузе с одинаковым составом.
              Вывод очевиден.
            </Box>
            <Box sx={{mb: '12px'}}>
              Экономия на минимальный курс (6 месяцев) по сравнению с Ливиалом составляет 9 000 руб.
            </Box>
            <Box>
              <b>
                Поэтому Велледиен — социально-ответственный препарат, позволяющий максимальному количеству пациенток
                получать эффективную МГТ в постменопаузе без риска для молочной железы.
              </b>
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme2" sx={{mt: 5}}>
        <HeaderTabs tabActive={1}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток в постменопаузе, принимающих циклическую МГТ на Велледиен.
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила ВЕЛЛЕДИЕН <b>5 пациенткам</b> в постменопаузе в течение месяца, которые
                ранее
                принимали циклическую МГТ.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения пациенток
                  <strong> в постменопаузе.</strong>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Пример продуктивного вопроса для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки, которые принимали циклическую МГТ в перименопаузе и которым
                  необходим подбор МГТ в постменопаузе?
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки в постменопаузе с жалобами на болезненность молочных желез на
                  фоне приёма циклической МГТ?
                </Box>
                <Box sx={{mb: '12px'}}>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких пациенток на приёме около 1–2 в день. А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <ol className="list">
                  <li>Скольким из этих пациенток Вы назначаете Фемостон 1/10 и 2/10?</li>
                  <li>
                    Скольким из этих пациенток Вы назначаете Климонорм?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы назначаемый препарат не только купировал климактерические жалобы, но
                    и имел высокий профиль безопасности в отношении риска рака молочной железы?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            Велледиен — оптимальный препарат для перехода с циклической МГТ по социально-ответственной цене.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              Велледиен обладает всеми преимуществами циклической МГТ с левоноргестрелом (Климонорм), обеспечивая
              благоприятный переход на тиболон в постменопаузе.
            </Box>
            <ul className="list">
              <li>
                Улучшение сексуальности при приеме тиболона связано с центральным и с периферическим эффектом: тиболон
                улучшает
                вагинальный кровоток
              </li>
              <li>
                Улучшение настроения на фоне приема тиболона развивается за счет повышения уровня эндорфинов и
                андрогенного
                действия метаболита тиболона
              </li>
              <li>Значительно реже наблюдается болезненность молочных желез и масталгия</li>
              <li>Не наблюдается увеличения плотности ткани на маммограмме</li>
            </ul>
            <Box>
              А цена в 2 раза доступнее оригинального препарата позволит получить качественную европейскую МГТ большему
              количеству женщин в постменопаузе.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme3" sx={{mt: 5}}>
        <HeaderTabs tabActive={2}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток в постменопаузе, принимающих Анжелик на Велледиен
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила ВЕЛЛЕДИЕН <b>5 пациенткам</b> в постменопаузе в течение месяца вместо
                Анжелик.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения пациенток
                  <strong> в постменопаузе.</strong>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Пример продуктивного вопроса для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки для подбора МГТ в постменопаузе?
                </Box>
                <Box>
                  — Как часто к Вам обращаются пациентки в постменопаузе с жалобами на болезненность молочных желез на
                  фоне приёма МГТ?
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <ol className="list">
                  <li>
                    Скольким из этих пациенток Вы назначаете Анжелик?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы назначаемая МГТ не только купировала климактерические жалобы, но и
                    имела высокий профиль безопасности в отношении риска рака молочной железы?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            <Box>
              Назначая Велледиен, Вы обеспечиваете максимальное количество пациенток эффективной МГТ (Менопаузальной
              Гормональной Терапией) в постменопаузе без риска для молочной железы.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              При использовании Тиболона длительно не наблюдается увеличения плотности ткани на маммограмме и не
              повышается риск пролиферации ткани молочной железы, что подтверждено клиническими исследованиями.
            </Box>
            <Box sx={{mb: '12px'}}>
              Всё это позволяет использовать Тиболон длительно.
            </Box>
            <Box>
              Таким образом, назначая Велледиен, Вы обеспечиваете максимальное количество пациенток эффективной МГТ
              (Менопаузальной Гормональной Терапией) в постменопаузе без риска для молочной железы.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>
    </>
  )
}
