import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A, StyledH1 as H1} from './ui';

export const ProginovaSecondLinkTwo = () => {
  return (
    <Page isCross>
      <H1>Effects of conjugated equine estrogens on breast cancer and mammography screening in postmenopausal women with hysterectomy</H1>
      <P><b>Context:</b> The Women's Health Initiative Estrogen-Aone trial comparing conjugated equine estrogens (CEE) with placebo was stopped early because of an increased stroke incidence and no reduction in risk of coronary heart disease. Preliminary results suggesting possible reduction in breast cancers warranted more detailed analysis.</P>
      <P><b>Objective:</b>  To determine the effects of CEE on breast cancers and mammographic findings.</P>
      <P>Design, setting, and participants: Following breast cancer risk assessment, 10,739 postmenopausal women aged 50 to 79 years with prior hysterectomy were randomized to CEE or placebo at 40 US clinical centers from 1993 through 1998. Mammography screenings and clinical breast examinations were performed at baseline and annually. All breast cancers diagnosed through February 29, 2004, are included.</P>
      <P><b>Intervention:</b>  A dose of 0.625 mg/d of CEE or an identical-appearing placebo.</P>
      <P><b>Main outcome measures:</b>  Breast cancer incidence, tumor characteristics, and mammogram findings.</P>
      <P><b>Results:</b>  After a mean (SD) follow-up of 7.1 (1.6) years, the invasive breast cancer hazard ratio (HR) for women assigned to CEE vs placebo was 0.80 (95% confidence interval [CI], 0.62-1.04; P = .09) with annualized rates of 0.28% (104 cases in the CEE group) and 0.34% (133 cases in the placebo group). In exploratory analyses, ductal carcinomas (HR, 0.71; 95% CI, 0.52-0.99) were reduced in the CEE group vs placebo group; however, the test for interaction by tumor type was not significant (P = .054). At 1 year, 9.2% of women in the CEE group had mammograms with abnormalities requiring follow-up vs 5.5% in the placebo group (P{'<'}.001), a pattern that continued through the trial to reach a cumulative percentage of 36.2% vs 28.1%, respectively (P{'<'}.001); however, this difference was primarily in assessments requiring short interval follow-up.</P>
      <P><b>Conclusions:</b> Treatment with CEE alone for 7.1 years does not increase breast cancer incidence in postmenopausal women with prior hysterectomy. However, treatment with CEE increases the frequency of mammography screening requiring short interval follow-up. Initiation of CEE should be based on consideration of the individual woman's potential risks and benefits.</P>
      <P><b>Trial registration:</b> clinicaltrials.gov Identifier: NCT00000611.</P>

      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/16609086/">Stefanick M.L. et al. Effects of conjugated equine estrogens on breast cancer and mammography screening in postmenopausal women with hysterectomy. JAMA. 2006; 295: 1647–1657. Chen Y.C. et al. Unopposed estrogen therapy and the risk of invasive breast cancer. Arch Intern Med. 2006; 166: 1027–1032.</A>]
      </P>
    </Page>
  )
}
