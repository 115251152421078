import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanSecondLinkThree = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>The association of hysterectomy and menopause: a prospective cohort study</H1>
      <P><b>Objective:</b> To determine whether or not hysterectomy leads to an earlier onset of the menopause.</P>
      <P><b>Design:</b> A prospective cohort study.</P>
      <P><b>Setting:</b> Gynaecology service of large urban hospital.</P>
      <P><b>Population:</b> Premenopausal women with and without hysterectomy.</P>
      <P><b>Methods:</b> Multivariate survival analysis techniques were used to adjust for differences in initial
        follicle stimulating hormone (FSH) levels, body mass index, smoking and unilateral oophorectomy between the
        groups.</P>
      <P><b>Main outcome measures:</b> FSH levels were measured for five years following hysterectomy and compared with
        the comparison group. Menopause was defined as a single FSH measurement of at least 40 IU/L.</P>
      <P><b>Results:</b> Two hundred and fifty-seven women undergoing hysterectomy were compared with 259 women who had
        not undergone a hysterectomy. Fifty-three women (20.6%) in the hysterectomy group and 19 women (7.3%) in the
        comparison group reached menopause over the five years of the study. Women in the hysterectomy group with a
        pre-operative FSH `{'<'}`10 IU/L reached menopause 3.7 years (95% CI 1.5-6.0 years) earlier than women in the
        comparison group independent of BMI, smoking and unilateral oophorectomy. Twenty-eight women in the hysterectomy
        group had unilateral oophorectomy and 10 (35.7%) of these women reached menopause over the five years of follow
        up. Women in the hysterectomy group with unilateral oophorectomy reached menopause 4.4 years (95% CI 0.6, 7.9
        years) earlier than women with both ovaries in the hysterectomy group independent of baseline FSH, BMI and
        smoking.</P>
      <P><b>Conclusions:</b> Hysterectomy is associated with an earlier onset of menopause. Hysterectomy with unilateral
        oophorectomy is associated with an even earlier onset of the menopause in this study.</P>
      <P></P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/15957999/">Farquhar CM, et al. The association of hysterectomy
        and menopause: a prospective cohort study. BJOG 2005; 112:956-962</A>]
      </P>

      <H1 sx={{color: PinkLight, mt: 6}}>Treatment of pelvic pain associated with endometriosis: a committee
        opinion</H1>
      <P>Pain associated with endometriosis may involve many mechanisms and requires careful evaluation to confirm the
        diagnosis and exclude other potential causes. Both medical and surgical treatments for pain related to
        endometriosis are effective, and choice of treatment must be individualized. This document replaces the document
        by the same name last published in 2008 (Fertil Steril 2008;90:S260-9).
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/24630080/">American Society for Reproductive Medicine (ASRM),
        Treatment of pelvic pain associated with endometriosis: a committee opinion. Fertil Steril, 2014; 101:927-35</A>]
      </P>

      <H1 sx={{color: PinkLight, mt: 6}}>Endometriosis and Risk of Coronary Heart Disease</H1>
      <P><b>Background:</b> Endometriosis is a prevalent gynecologic disease associated with systemic chronic inflammation,
        heightened oxidative stress, and atherogenic lipid profile that may increase women's risk for coronary heart
        disease (CHD).
      </P>
      <P><b>Methods and results:</b> We examined the prospective association between laparoscopically confirmed endometriosis
        and subsequent CHD among 116 430 women in the Nurses' Health Study II (1989-2009). Participants with a history
        of heart disease and stroke were excluded. When compared with women without endometriosis, women with
        laparoscopically confirmed endometriosis had a higher risk of myocardial infarction (relative risk, 1.52; 95%
        confidence interval, 1.17-1.98), angiographically confirmed angina (1.91; 1.59-2.29), coronary artery bypass
        graft surgery/coronary angioplasty procedure/stent (1.35; 1.08-1.69), or any of these CHD end points combined
        (1.62; 1.39-1.89), independent of potential demographic, anthropometric, family history, reproductive, and
        lifestyle confounders. Relative risk for the combined CHD end point was highest among women aged ≤40 years
        (3.08; 2.02-4.70) and decreased as age increased (40
        `{'<'}` age
        `{'≤'}`50 years, 1.65; 1.35-2.02; 50
        `{'<'}` age
        `{'≤'}`55 years, 1.44; 1.07-1.94; and age `{'>'}`55 years, 0.98; 0.56-1.72; P value, test for heterogeneity=0.001). Having
        had a hysterectomy/oophorectomy was associated with higher risk of combined CHD compared with not having had a
        hysterectomy/oophorectomy (1.51; 1.34-1.71). A percentage of 42 of the association between endometriosis and CHD
        could be explained by greater frequency of hysterectomy/oophorectomy and earlier age at surgery after
        endometriosis diagnosis.
      </P>
      <P><b>Conclusions:</b> In this large, prospective cohort, laparoscopically confirmed endometriosis was associated with
        increased risk of CHD. The association was strongest among young women. Hysterectomy/oophorectomy was associated
        with higher risk of CHD and could partially explain the association between endometriosis and CHD.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/27025928/">MuF Rich-Edwards J, Prime B, et al. Endometriosis
        and risk of coronary heart disease. Circ Cardiovasc Qual Outcomes 2016 May;9(3):257-64</A>]
      </P>
    </Page>
  )
}
