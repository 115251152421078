import {useState} from 'react';
import {useHistory} from 'react-router-dom'

import {Tab, TabsGroup} from 'components/tabs';
import {ProductModal} from 'components/product-modal/product-modal';
import {ProductModalType} from 'components/product-modal/types';
import {WrapperCouple} from 'components/wrapper-couple';
import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import Page from 'shared/ui/Page';
import {Img, Tables, LinkNavigate} from 'shared/ui';
import {Black, Blue, BlueDark, Green, PinkLight} from 'styles/color';

import images from './img'
import {Circle, H1, H2, Paragraph as P, Div, Span, Sup} from './ui';
import {Rectangle} from './rectangle';

export const Microginon = () => {
  const history = useHistory();

  const [modal, setModal] = useState<ProductModalType>(null);
  const handleClosePopup = () => {
    setModal(null);
  }

  const handleOpenModal = () => {
    setModal('Материалы');
  }

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  const handleOpenVideo = () => {
  }

  return (
    <>
      <Page head={'МИКРОГИНОН'} backText="Продукты" onClose={handleRedirectToProducts}>
        <TabsGroup>
          <Tab>
            <LinkNavigate to="/product/microginon/instruction">Инструкция</LinkNavigate>
          </Tab>
          <Tab onClick={handleOpenModal}>Материалы</Tab>
          <Tab onClick={handleOpenVideo}>Видео</Tab>
        </TabsGroup>

        <Div className="text-light" sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <WrapperShell sx={{backgroundImage: `url(${images.main})`, height: 500}}>
            <H1 sx={{color: '#FFF', mt: 32, ml: 6}}>Контрацепция <br/> с
              дополнительными<br/> лечебными<br/> преимуществами</H1>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.sickWoman})`,}}/>
            <WrapperShell>
              <Img sx={{mt: 2}} src={images.who}/>
              <H2 sx={{color: PinkLight}}>По прогнозам ВОЗ, рак остается одной из главных причин смертности
                населения.</H2>
              <P>
                В последнее десятилетие смертность от злокачественных новообразований занимает ВТОРОЕ МЕСТО в структуре
                смертности от основных причин смерти населения.
              </P>
              <P>
                Более того, смертность от онкологических заболеваний остается неизменной, а по ряду локализаций даже
                возросла. Это, прежде всего, касается опухолей органов женской репродуктивной системы.
              </P>
            </WrapperShell>
          </WrapperCouple>
          <WrapperShell>
            <H1 sx={{color: PinkLight}}>
              Регистрация онкологических заболеваний органов репродуктивной системы ежегодно
            </H1>
            <P>
              Ежегодно в мире регистрируется 12,7 млн. больных с впервые выявленными злокачественными новообразованиями
              и 1,2 млн. из них приходится на заболевания органов репродуктивной системы.
            </P>
            <P>
              В России в 2012 г. число вновь выявленных онкологических заболеваний половых органов достигло 47,6 тыс.,
              что составило 17% от числа всех злокачественных опухолей у женщин. И с сожалением надо констатировать, что
              45,1% из них погибает.
            </P>
            <WrapperCouple sx={{justifyContent: 'space-around'}}>
              <Img src={images.statWorld}/>
              <Img src={images.statRu}/>
            </WrapperCouple>

            <P>
              <Rectangle width={60} fill={Blue}/>
              <Span sx={{ml: 2}}>Заболевания органов репродуктивной системы</Span>
            </P>
            <P sx={{mt: -1}}>
              <Rectangle width={60} fill={PinkLight}/>
              <Span sx={{ml: 2}}>Заболевания органов репродуктивной системы</Span>
            </P>
          </WrapperShell>

          <WrapperShellBox>
            <H1 sx={{color: PinkLight}}>Применение КОК снижает риск развития злокачественных опухолей </H1>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell className="small">
              <Div sx={{display: 'flex', justifyContent: 'flex-start', mt: 1}}>
                <Rectangle/>
                <P sx={{mt: 0, ml: 1, mb: 1}}>Без применения КОК</P>
              </Div>
              <Div sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <Rectangle fill={PinkLight}/>
                <P sx={{mt: 0, ml: 1, mb: 1}}>Снижение в целом с применением КОК</P>
              </Div>
              <Div sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <Rectangle fill={Blue}/>
                <P sx={{mt: 0, ml: 1}}>
                  Снижение кумулятивного риска злокачественных опухолей в гинекологии
                </P>
              </Div>
              <Img sx={{mb: 1, mt: 1}} src={images.infographics}/>
            </WrapperShell>
            <WrapperCouple sx={{flexDirection: 'column'}}>
              <WrapperShellBox>
                <H2 sx={{color: PinkLight, mt: 1}}>В <Span sx={{fontSize: 60}}>13</Span>% наблюдений</H2>
                <P>предотвратило развитие РЯ у женщин до 75 лет, что эквивалентно предотвращению более
                  <b>&nbsp;100 000</b> смертей за последние 50 лет.</P>
              </WrapperShellBox>
              <WrapperShellBox>
                <P sx={{mt: 1}}>Долговременные преимущества гормональных контрацептивов включают <b>снижение уровней
                  рака</b></P>
                <Div sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Div>
                    <H2 sx={{color: Blue, mt: 0, mb:0}}>на <Span sx={{fontSize: 60}}>50</Span>%</H2>
                    <P sx={{mb: 0, mt: '12px'}}>рака<br/> эндометрия</P>
                  </Div>
                  <Div sx={{borderRight: '1px solid', borderLeft: '1px solid', borderColor: '#FFF', p: '0 34px'}}>
                    <H2 sx={{color: Blue, mt: 0, mb:0}}>на <Span sx={{fontSize: 60}}>50</Span>%</H2>
                    <P sx={{mb: 0, mt: '12px'}}>рака<br/> яичников</P>
                  </Div>
                  <Div>
                    <H2 sx={{color: Blue, mt: 0, mb:0}}>на <Span sx={{fontSize: 60}}>50</Span>%</H2>
                    <P sx={{mb: 0, mt: '12px'}}>колоректаль-<br/>ного рака</P>
                  </Div>
                </Div>
              </WrapperShellBox>
            </WrapperCouple>
          </WrapperCouple>

          <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight}}>
            <H2 sx={{mt: 1, mb: 0}}>Лечебные преимущества КОК</H2>
            <P>
              Кроме онкологического аспекта, применение КОК обладает еще целым рядом лечебных преимуществ при
              неонкологических патологиях.
            </P>
            <Div sx={{display: 'flex', justifyContent: 'space-between', gap: 3}}>
              <Div sx={{width: '100%'}}>
                <Circle sx={{backgroundImage: `url(${images.circle})`}}>1</Circle>
                <P sx={{fontFamily: 'SF-Pro-Text-Semibold'}}>Профилактика предопухолевой патологии эндометрия</P>
              </Div>
              <Div sx={{width: '100%'}}>
                <Circle sx={{backgroundImage: `url(${images.circle})`}}>2</Circle>
                <P sx={{fontFamily: 'SF-Pro-Text-Semibold'}}>Эффективная контрацепция</P>
              </Div>
              <Div sx={{width: '100%'}}>
                <Circle sx={{backgroundImage: `url(${images.circle})`}}>3</Circle>
                <P sx={{fontFamily: 'SF-Pro-Text-Semibold'}}>
                  Нормализация менструальных циклов у женщин репродуктивного возраста, в том числе не планирующих
                  беременность
                </P>
              </Div>
              <Div sx={{width: '100%'}}>
                <Circle sx={{backgroundImage: `url(${images.circle})`}}>4</Circle>
                <P sx={{fontFamily: 'SF-Pro-Text-Semibold'}}>Уменьшение выраженности, отсутствие предменструального
                  синдрома и др.</P>
              </Div>
            </Div>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell>
              <H1 sx={{color: PinkLight}}>Современная гормональная контрацепция с дополнительными
                лечебными<br/> преимуществами</H1>
              <Img sx={{mt: 2}} src={images.microginon}/>
              <H2 sx={{fontFamily: 'SF-Pro-Text-Bold'}}>МИКРОГИНОН®</H2>
              <H2 sx={{color: PinkLight, mt: 4, mb: 1}}>СОСТАВ:</H2>
              <P sx={{mt: 0}}>21 таблетка: 30 мкг этинилэстрадиола + 150 мкг левоноргестрела</P>
              <LinkNavigate to="/product/microginon/instruction"><H2 sx={{color: PinkLight, mb: 1}}>ПОКАЗАНИЯ К
                ПРИМЕНЕНИЮ<Sup>1</Sup>:</H2></LinkNavigate>
              <P sx={{mt: 0}}>Пероральная контрацепция</P>
            </WrapperShell>
            <WrapperShell
              className="small"
              sx={{boxShadow: 'none', display: 'flex', flexDirection: 'column', gap: '20px', p: 0}}
            >
              <WrapperShell sx={{backgroundImage: `url(${images.pair})`}}/>
              <WrapperShell sx={{minHeight: 342}}>
                <Img sx={{mt: 2, mb: 1}} src={images.warn}/>
                <P sx={{color: PinkLight}}>
                  <b>Для всех женщин<br/> репродуктивного возраста</b>, для которых важны профиль
                  безопасности контрацептива и экономическая выгода наряду с высоким качеством оригинального
                  препарата
                </P>
              </WrapperShell>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShell>
            <LinkNavigate to="/product/microginon/instruction"><H1
              sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Semibold'}}>
              Оригинальным контрацептивом с Левоноргестрелом является МИКРОГИНОН, содержащий 30 мг ЭЭ и 150 мкг
              ЛНГ<Sup>1</Sup>
            </H1></LinkNavigate>
            <WrapperShellBox sx={{boxShadow: 'none', backgroundColor: PinkLight}}>
              <H2 sx={{color: Black, m: 0}}>Выбор препарата основывается на безопасности гестагена и дозе
                эстрогена.</H2>
            </WrapperShellBox>
            <WrapperCouple sx={{mb: 2, mt: '20px'}}>
              <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                <Img src={images.plus}/>
                <P>
                  Левоноргестрел — широко распространённый прогестин, который входит в состав многих комбинированных
                  оральных контрацептивов (КОК) и сам по себе является высокоэффективным средством, не только входя в
                  состав противозачаточных таблеток, но и в виде долгодействующих подкожных имплантов, внутриматочных
                  устройств и в виде неотложной контрацепции.
                </P>
              </WrapperShell>
              <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                <Img src={images.plus}/>
                <LinkNavigate to="/product/microginon/link1"><P>
                  Безопасность Левоноргестрела сегодня определяется целым рядом его преимуществ и популярность его в
                  комбинации с низкодозированным уровнем Э-Э растет, особенно в сравнении с третьей генерацией
                  прогестинов, таких как дезогестрел и гестоден<Sup>2</Sup>
                </P>
                </LinkNavigate>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShellBox sx={{backgroundColor: PinkLight}}>
            <LinkNavigate to="/product/microginon/instruction"><H1 sx={{color: Black}}>Для кого Микрогинон?<Sup>1</Sup></H1>
            </LinkNavigate>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.girlFirst})`}}/>
            <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight}}>
              <Div sx={{display: 'flex', alignItems: 'top'}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.plusMini}/>
                </Div>
                <P sx={{m: 0, ml: 1}}>Контрацепция первого выбора у здоровых женщин</P>
              </Div>
              <Div sx={{display: 'flex', alignItems: 'top', mt: 1}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.plusMini}/>
                </Div>
                <P sx={{m: 0, ml: 1}}>Выбор контрацептива у женщин с неизвестным тромбофилическим риском</P>
              </Div>
              <Div sx={{display: 'flex', alignItems: 'top', mt: 4}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.approveMini}/>
                </Div>
                <P sx={{mt: 0, ml: 1}}>
                  <b>Микрогинон — самый изученный и самый назначаемый в мире КОК. Комбинация ЭЭ+ЛНГ является стандартом
                    сравнения всех КОК</b> в клинических исследованиях. Благодаря остаточному андрогенному и слабому
                  антиминералокортикоидному эффектам ЛНГ хорошо переносится. ЛНГ-КОК имеют наименьший риск
                  тромботических осложнений по сравнению с другими препаратами этой группы.
                </P>
              </Div>
            </WrapperShell>
          </WrapperCouple>

          <WrapperCouple>
            <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight}}>
              <Div sx={{display: 'flex', alignItems: 'top'}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.plusMini}/>
                </Div>
                <P sx={{m: 0, ml: 1}}>
                  Контрацепция у женщин со склонностью к обильным менструациям и аномальным маточным кровотечениям (АМК)
                </P>
              </Div>
              <Div sx={{display: 'flex', alignItems: 'top', mt: 1}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.plusMini}/>
                </Div>
                <P sx={{m: 0, ml: 1}}>
                  Регуляция менструального цикла при АМК у сексуально активных женщин, не планирующих беременность
                </P>
              </Div>
              <Div sx={{display: 'flex', alignItems: 'top', mt: 4}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.approveMini}/>
                </Div>
                <P sx={{mt: 0, ml: 1}}>
                  <b>ЛНГ обладает сильным прогестагенным эффектом,</b> что позволяет ЛНГ-КОК хорошо контролировать
                  менструальный ритм и достоверно редуцировать менструальную кровопотерю. Применение ЛНГ-КОК позволяет
                  осуществить профилактику железодефицитной анемии и оптимизировать ее лечение.
                </P>
              </Div>
            </WrapperShell>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.girlSecond})`}}/>
          </WrapperCouple>

          <WrapperCouple>
            <WrapperShell sx={{backgroundImage: `url(${images.embrace})`}}/>
            <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight}}>
              <Div sx={{display: 'flex', alignItems: 'top'}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.plusMini}/>
                </Div>
                <P sx={{m: 0, ml: 1}}>
                  Профилактика гиперпластических и неопластических заболеваний эндометрия у женщин, перенесших
                  гиперплазию эндометрия
                </P>
              </Div>
              <Div sx={{display: 'flex', alignItems: 'top', mt: 1}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.plusMini}/>
                </Div>
                <P sx={{m: 0, ml: 1}}>
                  Регуляция менструального цикла при АМК у сексуально активных женщин, не планирующих беременность
                </P>
              </Div>
              <Div sx={{display: 'flex', alignItems: 'top', mt: 4}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.approveMini}/>
                </Div>
                <P sx={{mt: 0, ml: 1}}>
                  Применение всех КОК связано с редукцией риска рака эндометрия, но <b>КОК, содержащие сильный
                  прогестаген,
                  снижают риск рака в 2 раза больше.</b> ЛНГ-КОК могут применяться после кюретажа у пациенток с
                  гиперплазией
                  эндометрия без атипии и на II этапе после терапии атипической гиперплазии эндометрия.
                </P>
              </Div>
            </WrapperShell>
          </WrapperCouple>

          <WrapperCouple>
            <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight, minHeight: 312}}>
              <Div sx={{display: 'flex', alignItems: 'top'}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.plusMini}/>
                </Div>
                <P sx={{m: 0, ml: 1}}>
                  Остановка острого АМК (гормональный гемостаз) у женщин с кровотечением, не угрожающим жизни и не
                  связанным со структурными изменениями матки
                </P>
              </Div>
              <Div sx={{display: 'flex', alignItems: 'top', mt: 4}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.approveMini}/>
                </Div>
                <P sx={{mt: 0, ml: 1}}>
                  <b>Накоплен большой клинический опыт применения КОК, содержащих 30 мкг ЭЭ и сильный прогестаген</b> с
                  целью
                  гемостаза при острых АМК дисфункционального происхождения. Наиболее часто в рутинной практике
                  используются КОК с левоноргестрелом.
                </P>
              </Div>
            </WrapperShell>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.doctor})`, minHeight: 312}}/>
          </WrapperCouple>

          <WrapperCouple>
            <WrapperShell sx={{backgroundImage: `url(${images.girlBed})`, minHeight: 312}}/>
            <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight, minHeight: 312}}>
              <Div sx={{display: 'flex', alignItems: 'top', mt: 1}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.plusMini}/>
                </Div>
                <P sx={{m: 0, ml: 1}}>
                  Побочные эффекты КОК, связанные с антиандрогенными свойствами прогестина: снижение либидо, вялость,
                  депрессия, снижение работоспособности
                </P>
              </Div>
              <Div sx={{display: 'flex', alignItems: 'top', mt: 4}}>
                <Div sx={{minWidth: 31}}>
                  <Img src={images.approveMini}/>
                </Div>
                <P sx={{mt: 0, ml: 1}}>
                  Остаточная андрогенная активность ЛНГ позволяет нивелировать его антиандрогенный прогестагенный эффект
                  в
                  отличие от других прогестинов, применяемых в составе КОК
                </P>
              </Div>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShell>
            <H1 sx={{color: PinkLight}}>Активность прогестинов — производных разных групп, реализуемая через рецепторы
              стероидов</H1>
            <Tables.WrapperTable>
              <Tables.Table sx={{minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr>
                    <Tables.Td sx={{backgroundColor: Green}}>Активность</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>НМГЛ</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>ДРСП</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>ЛНГ</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>ДНГ</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>Прогестерон</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td>Прогестагенная</Tables.Td>
                    <Tables.Td>0,12</Tables.Td>
                    <Tables.Td sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>1,7</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Green}}>0,09</Tables.Td>
                    <Tables.Td>2,3</Tables.Td>
                    <Tables.Td>0,7</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>
                    <Tables.Td>Андрогенная</Tables.Td>
                    <Tables.Td>{'>'}100</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>{'>'}100</Tables.Td>
                    <Tables.Td>1,57</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>{'>'}100</Tables.Td>
                    <Tables.Td>{'>'}100</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Антиандрогенная</Tables.Td>
                    <Tables.Td>92</Tables.Td>
                    <Tables.Td>279</Tables.Td>
                    <Tables.Td>4</Tables.Td>
                    <Tables.Td>210</Tables.Td>
                    <Tables.Td>141</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>
                    <Tables.Td>Эстрогенная</Tables.Td>
                    <Tables.Td>{'>'}100</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>{'>'}100</Tables.Td>
                    <Tables.Td>{'>'}100</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>{'>'}100</Tables.Td>
                    <Tables.Td>{'>'}100</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Антиэстрогенная</Tables.Td>
                    <Tables.Td>{'>'}1000</Tables.Td>
                    <Tables.Td>{'>'}1000</Tables.Td>
                    <Tables.Td>{'>'}1000</Tables.Td>
                    <Tables.Td>{'>'}1000</Tables.Td>
                    <Tables.Td>{'>'}1000</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>
                    <Tables.Td>Антиминералокортикоидная</Tables.Td>
                    <Tables.Td>{'>'}10000</Tables.Td>
                    <Tables.Td>5,1</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>172</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>{'>'}10000</Tables.Td>
                    <Tables.Td>10,1</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Глюкокортикоидная</Tables.Td>
                    <Tables.Td>{'>'}10000</Tables.Td>
                    <Tables.Td sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>{'>'}10000</Tables.Td>
                    <Tables.Td>{'>'}10000</Tables.Td>
                    <Tables.Td sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>{'>'}10000</Tables.Td>
                    <Tables.Td>{'>'}10000</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
            <P sx={{mb: 0, fontSize: 16}}>НМГЛ — номегестрола ацетат (производное прогестерона)</P>
            <P sx={{m: 0, fontSize: 16}}>ДРСП — дроспиренон (производное спиронолактона)</P>
            <P sx={{m: 0, fontSize: 16}}>ЛНГ — левоноргестрел (производное нортестостерона)</P>
            <P sx={{m: 0, fontSize: 16}}>
              ДНГ — диеногест (производное нортестостерона, лишенное этинильного радикала — гибридный прогестин)
            </P>

            <WrapperCouple>
              <WrapperShellBox sx={{boxShadow: 'none', display: 'flex', p: 0, mt: 6}}>
                <Div sx={{minWidth: 34, mr: 1}}>
                  <Img src={images.warn}/>
                </Div>
                <P sx={{m: 0}}>
                  Результаты проведенного исследования отражают самую высокую прогестагенную активность ЛНГ по сравнению
                  с любым другим прогестином. Это и определяет ряд дополнительных позитивных свойств, которые широко
                  используются в клинической практике.
                </P>
              </WrapperShellBox>
              <WrapperShellBox sx={{boxShadow: 'none'}}>
                <Img sx={{mt: 3}} src={images.diagram}/>
              </WrapperShellBox>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell>
            <LinkNavigate to="/product/microginon/link2">
              <H1 sx={{color: PinkLight}}>
                Риски венозных тромбозов у пользователей КОК в зависимости от типа
                гестагена<Sup>3</Sup>
              </H1>
            </LinkNavigate>

            <Tables.WrapperTable sx={{mb: 2}}>
              <Tables.Table sx={{minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr>
                    <Tables.Td sx={{backgroundColor: BlueDark}}>VTE confirmed</Tables.Td>
                    <Tables.Td sx={{backgroundColor: BlueDark}}>Vinogradova</Tables.Td>
                    <Tables.Td sx={{backgroundColor: BlueDark}}>Lidegaard</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td sx={{backgroundColor: Green}}>Non use</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>Ireference</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>Ireference</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>COC levonorgestrel</Tables.Td>
                    <Tables.Td sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>3,0 (2,6–3,3)</Tables.Td>
                    <Tables.Td>3,0 (2,2–4,0)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>
                    <Tables.Td>COC norgestimate</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>3,5 (2,9–4,4)</Tables.Td>
                    <Tables.Td>3,5 (2,9–4,3)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>COC desogestrel</Tables.Td>
                    <Tables.Td sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>6,2 (5,0–7,7)</Tables.Td>
                    <Tables.Td>6,6 (5,6–7,6)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>
                    <Tables.Td>COC gestodene</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>6,5 (5,0–8,4)</Tables.Td>
                    <Tables.Td>6,2 (5,6–7,0)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>COC drospirenone</Tables.Td>
                    <Tables.Td sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>6,1 (4,7–7,8)</Tables.Td>
                    <Tables.Td>6,4 (5,4–7,5)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: `rgba(247, 229, 224, .25)`}}>
                    <Tables.Td>COC cyproterone</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>6,0 (4,7–7,7) </Tables.Td>
                    <Tables.Td>6,4 (5,1–7,9)</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
          </WrapperShell>

          <WrapperShellBox sx={{backgroundColor: PinkLight}}>
            <H1 sx={{color: Black}}>Затраты пациенток с АМК</H1>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell sx={{display: 'flex', flexDirection: 'column', gap: '10px', boxShadow: 'none', p: 0}}>
              <WrapperShellBox
                sx={{position: 'relative', p: '50px 20px', textAlign: 'center', backgroundColor: PinkLight}}>
                <Img src={images.circlePrice} sx={{position: 'absolute', right: 30, top: 18}}/>
                <Img src={images.microginonLarge}/>
              </WrapperShellBox>
              <WrapperShellBox sx={{backgroundColor: PinkLight}}>
                <P sx={{fontSize: 24, m: 0, color: Black, textAlign: 'center'}}>500 ₽ х 12 мес. = <b>6000 ₽ </b>в
                  год</P>
              </WrapperShellBox>
            </WrapperShell>

            <WrapperShell sx={{display: 'flex', flexDirection: 'column', gap: '10px', boxShadow: 'none', p: 0}}>
              <WrapperShellBox
                sx={{position: 'relative', p: '29px 20px', textAlign: 'center', backgroundColor: PinkLight}}>
                <Img src={images.price300} sx={{position: 'absolute', right: 30, top: 18}}/>
                <Img src={images.naturella}/>
              </WrapperShellBox>
              <WrapperShellBox sx={{backgroundColor: PinkLight}}>
                <P sx={{fontSize: 24, m: '0 -6px', color: Black, textAlign: 'center'}}>
                  300 ₽ х 2,5 уп.* х 12 мес. = <b>9000 ₽ </b>в год
                </P>
              </WrapperShellBox>
              <P sx={{fontSize: 16, mt: 0}}>*Женщинам с обильными кровотечениями требуется до 10 прокладок в сутки</P>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShellBox sx={{mb: 10}}>
            <Div sx={{display: 'flex', mt: 2}}>
              <Div sx={{minWidth: 60}}><Img src={images.approveLarge}/></Div>
              <P sx={{mt: 0, ml: 2}}>
                В настоящее<br/> <b>время приём МИКРОГИНОНА в 1,5 раза выгоднее,</b><br/> чем затраты на средства личной
                гигиены для
                женщин с обильными кровотечениями. Кроме того, назначая Микрогинон, врач контролирует менструальный цикл
                и снижает менструальную кровопотерю у пациенток с АМК, тем самым улучшая качество жизни.
              </P>
            </Div>
          </WrapperShellBox>
        </Div>
      </Page>
      <ProductModal product="microginon" modal={modal} onClose={handleClosePopup}/>
    </>
  )
}
