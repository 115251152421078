import {useState} from 'react';
import {useHistory} from 'react-router-dom'

import {Tab, TabsGroup} from 'components/tabs';
import {ProductModal} from 'components/product-modal/product-modal';
import {ProductModalType} from 'components/product-modal/types';
import {WrapperCouple} from 'components/wrapper-couple';
import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import Page from 'shared/ui/Page';
import {Img, List as UL, ListItem as LI, LinkNavigate} from 'shared/ui';
import {Black, PinkLight} from 'styles/color';

import {H1, H2, Paragraph as P, Div, Sup} from './ui';
import images from './img'

export const ProginovaSecond = () => {
  const history = useHistory();

  const [modal, setModal] = useState<ProductModalType>(null);
  const handleClosePopup = () => {
    setModal(null);
  }

  const handleOpenModal = () => {
    setModal('Материалы');
  }

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  const handleOpenVideo = () => {
    setModal('Видео');
  }

  return (
    <>
      <Page head={'ПРОГИНОВА'} backText="Продукты" onClose={handleRedirectToProducts}>
        <TabsGroup>
          <Tab>
            <LinkNavigate to="/product/proginova/instruction">Инструкция</LinkNavigate>
          </Tab>
          <Tab onClick={handleOpenModal}>Материалы</Tab>
          <Tab onClick={handleOpenVideo}>Видео</Tab>
        </TabsGroup>

        <Div className="text-light" sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <WrapperShell sx={{backgroundImage: `url(${images.main})`, height: 500}}>
            <H1 sx={{color: '#FFF', mt: 5, ml: 6}}>
              Прогинова® — <br/>помощь при эстрогенной <br/> недостаточности
            </H1>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight}}>
              <H2>ПРОГИНОВА® является оптимальным средством коррекции уровня эстрогенов с доказанной эффективностью и
                безопасностью.</H2>
              <P>При длительном использовании снижает риски рака молочной железы и первичного рака печени, обладает
                выраженным нейропротективным эффектом.
              </P>
              <H2>СОСТАВ:</H2>
              <P>21 драже белого цвета: 2 мг эстрадиола валерата</P>
              <H2>
                <LinkNavigate to="/product/proginova/instruction">
                  ПОКАЗАНИЯ К ПРИМЕНЕНИЮ<Sup>1</Sup>:
                </LinkNavigate>
              </H2>
              <P>
                Заместительная гормональная терапия (ЗГТ) при климактерических расстройствах, инволютивных изменениях
                кожи и мочеполового тракта, депрессивных состояниях в климактерическом периоде, а также симптомов
                дефицита эстрогенов вследствие естественной менопаузы или стерилизации.
              </P>
              <P>Профилактика постменопаузального остеопороза.</P>
            </WrapperShell>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.blonde})`}}/>
          </WrapperCouple>

          <WrapperShell sx={{display: 'flex', flexDirection: 'column', gap: '20px', pb: 5}}>
            <H1 sx={{color: PinkLight, mb: 1}}>
              <LinkNavigate to="/product/proginova-second/link1">
                Терапевтические ниши – Прогинова<Sup>2</Sup>
              </LinkNavigate>
            </H1>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <Img src={images.plus}/>
              <H2 sx={{color: PinkLight, mt: 1}}>Врожденный гипогонадотропный гипогонадизм</H2>
              <P sx={{fontWeight: 'bold', lineHeight: '26px'}}>
                <LinkNavigate to="/product/proginova/instruction">
                  ПОКАЗАНИЯ ДЛЯ НАЗНАЧЕНИЯ ПРОГИНОВЫ<Sup sx={{fontSize: 20, mr: '4px'}}>1</Sup>:
                </LinkNavigate>
              </P>
              <P>гипоплазия молочных желез, скудное оволосение на лобке, гипоплазия матки, узкий таз, задержка роста и
                развития костей, остеопороз. Терапия направлена на восстановление нормального уровня половых стероидов.
                Проводится в сочетании с гестагенами</P>
            </WrapperShellBox>
            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                <Img src={images.plus}/>
                <H2 sx={{color: PinkLight, mt: 1, fontSize: 24}}>
                  Приобретенный гипогонадизм, функциональная гипогонадотропная недостаточность («функциональная
                  гипоталамическая аменорея»)
                </H2>
                <P sx={{fontWeight: 'bold', lineHeight: '26px'}}>
                  <LinkNavigate to="/product/proginova/instruction">
                    ПОКАЗАНИЯ ДЛЯ НАЗНАЧЕНИЯ ПРОГИНОВЫ<Sup sx={{fontSize: 20, mr: '4px'}}>1</Sup>:
                  </LinkNavigate>
                </P>
                <P>восстановление нормального уровня половых стероидов с целью профилактики снижения минеральной
                  плотности костной ткани, регрессии симптомов гипоэстрогении, нормализация метаболических нарушений,
                  положительное влияние на состояние урогенитального тракта, сохранение здоровья женщины.
                  Продолжительность лечения в сочетании с гестагенами до периода менопаузы</P>
              </WrapperShell>
              <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                <Img src={images.plus}/>
                <H2 sx={{color: PinkLight, mt: 1, fontSize: 24}}>У пациенток с интактной маткой</H2>
                <P sx={{fontWeight: 'bold', lineHeight: '26px'}}>
                  <LinkNavigate to="/product/proginova/instruction">
                    ПОКАЗАНИЯ ДЛЯ НАЗНАЧЕНИЯ ПРОГИНОВЫ<Sup sx={{fontSize: 20, mr: '4px'}}>1</Sup>:
                  </LinkNavigate>
                </P>
                <UL>
                  <LI>
                    <P sx={{m: 0}}>у пациенток с бедным ответом назначение Прогиновы 2 – 3 цикла накануне стимуляции
                      овуляции
                      улучшает качество ооцитов. Поддержка лютеиновой фазы стимулированного цикла в сочетании с
                      гестагенами
                    </P>
                  </LI>
                  <LI>
                    <P sx={{m: 0}}>использование средних доз эстрогенов у пациенток с тонким эндометрием в сочетании с
                      гестагенами</P>
                  </LI>
                  <LI>
                    <P sx={{m: 0}}>донорство ооцитов и эмбрионов и с целью коррекции гормонального дефицита у этой
                      группы
                      пациенток в сочетании с гестагенами</P>
                  </LI>
                  <LI>
                    <P sx={{m: 0}}>постепенная отмена эстрогенов с 8-й по 12-ю неделю беременности назначенных в рамках
                      гормональной поддержки в протоколе стимуляции овуляции в сочетании с гестагенами
                    </P>
                  </LI>
                </UL>
              </WrapperShell>
            </WrapperCouple>
            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none', p: 0, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                  <Img src={images.plus}/>
                  <H2 sx={{color: PinkLight, mt: 1, fontSize: 24}}>В протоколах контролируемой стимуляции овуляции</H2>
                  <P sx={{fontWeight: 'bold', lineHeight: '26px'}}>
                    <LinkNavigate to="/product/proginova/instruction">
                      ПОКАЗАНИЯ ДЛЯ НАЗНАЧЕНИЯ ПРОГИНОВЫ<Sup sx={{fontSize: 20, mr: '4px'}}>1</Sup>:
                    </LinkNavigate>
                  </P>
                  <P>отсутствие адекватной трансформации эндометрия вследствие периферического антиэстрогенного действия
                    кломифена</P>
                </WrapperShellBox>
                <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                  <Img src={images.plus}/>
                  <H2 sx={{color: PinkLight, mt: 1, fontSize: 24}}>У пациенток с интактной маткой</H2>
                  <P sx={{fontWeight: 'bold', lineHeight: '26px'}}>
                    <LinkNavigate to="/product/proginova/instruction">
                      ПОКАЗАНИЯ ДЛЯ НАЗНАЧЕНИЯ ПРОГИНОВЫ<Sup sx={{fontSize: 20, mr: '4px'}}>1</Sup>:
                    </LinkNavigate>
                  </P>
                  <P>показания для назначения Прогиновы в комплексной терапии климактерических симптомов у пациенток,
                    использующих внутриматочную левоноргестрелсодержащую рилизинг-систему в период перименопаузы</P>
                </WrapperShellBox>
              </WrapperShell>
              <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, maxWidth: '490px'}}>
                <Img src={images.plus}/>
                <H2 sx={{color: PinkLight, mt: 1, fontSize: 24}}>У пациенток, подвергшихся тотальной или субтотальной
                  гистерэктомии</H2>
                <P sx={{fontWeight: 'bold', lineHeight: '26px'}}>
                  <LinkNavigate to="/product/proginova/instruction">
                    ПОКАЗАНИЯ ДЛЯ НАЗНАЧЕНИЯ ПРОГИНОВЫ<Sup sx={{fontSize: 20, mr: '4px'}}>1</Sup>:
                  </LinkNavigate>
                </P>
                <UL>
                  <LI>
                    <P sx={{m: 0}}>Прогинова как терапия выбора с целью купирования климактерических симптомов у
                      пациенток: </P>
                  </LI>
                  <LI>
                    <P sx={{m: 0}}>с наследственным анамнезом рака молочной железы (монотерапия эстрогенами снижает
                      риски рака молочной железы)
                    </P>
                  </LI>
                  <LI>
                    <P sx={{m: 0}}>с сахарным диабетом 2-го типа (монотерапия эстрогенами снижает риски первичного рака
                      печени)
                    </P>
                  </LI>
                  <LI>
                    <P sx={{m: 0}}>с болезнью Альцгеймера в семейном анамнезе (нейропротективный эффект эстрогенов в
                      возрастном периоде, соответствующем менопаузальному переходу и в возрасте ранней постменопаузы)
                    </P>
                  </LI>
                </UL>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight, pb: 5}}>
            <H1>Наследственный рак молочной железы и яичника: варианты МГТ и риск развития злокачественных
              новообразований</H1>
            <WrapperCouple sx={{mt: 5}}>
              <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${Black}`, backgroundColor: PinkLight}}>
                <Div sx={{display: 'flex'}}>
                  <Div sx={{minWidth: 50, m: '-6px 0 0 -6px'}}><Img src={images.approve}/></Div>
                  <P sx={{mt: 0, ml: 2}}>
                    <LinkNavigate to="/product/proginova-second/link2">
                      <b>Данные WHI</b> (n=10 739 женщин после выполнения гистерэктомии с медианой наблюдения 6,8
                      года) <b>и NHS</b>
                      (n=11 508 женщин в постменопаузе после гистерэктомии, наблюдение свыше 15 лет) в целом
                      свидетельствуют о том, что <b>монотерапия эстрогенами в течение 7–10 лет</b> не повышает риска, а
                      наоборот, <b>способствует снижению риска РМЖ</b> ниже популяционного<Sup>3</Sup>.
                    </LinkNavigate>
                  </P>
                </Div>
              </WrapperShellBox>
              <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${Black}`, backgroundColor: PinkLight}}>
                <Div sx={{display: 'flex'}}>
                  <Div sx={{minWidth: 50, m: '-6px 0 0 -6px'}}><Img src={images.approve}/></Div>
                  <P sx={{m: 0, ml: 2}}>
                    <LinkNavigate to="/product/proginova-second/link3">
                      <b>В эпидемиологическом сравнительном исследовании</b> по типу «случай-контроль» среди носительниц
                      мутаций, ассоциированных с наследственным РМЖ, и сохраненной маткой (n=1110) отобраны 83 больные
                      РЭ. <b>МГТ только эстрогенами ассоциирована со снижением рисков развития РЭ</b> (ОР=0,23; 95% ДИ
                      0,03–1,78)<Sup>4</Sup>.
                    </LinkNavigate>
                  </P>
                </Div>
              </WrapperShellBox>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell>
            <H1 sx={{color: PinkLight}}>Моноэстрогены в качестве МГТ: риск развития гепатоцеллюлярного рака</H1>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, display: 'flex'}}>
              <Div sx={{minWidth: 60}}><Img src={images.warn}/></Div>
              <P sx={{m: 0, fontSize: 28}}>
                <LinkNavigate to="/product/proginova-second/link4">По данным анализа канцерорегистра Великобритании с
                  1988 по 2011 г.
                  установлено, что использование
                  <b>&nbsp;МГТ ассоциировано со значительно меньшим риском развития рака печени</b> (ОР=0,58, 95% ДИ
                  0,37–0,90),
                  особенно среди пользователей МГТ моноэстрогенами (ОР=0,44, 95% ДИ 0,22-0,88)<Sup>5</Sup>.
                </LinkNavigate>
              </P>
            </WrapperShellBox>
            <H2 sx={{color: PinkLight, mb: 0, mt: 5}}>
              <LinkNavigate to="/product/proginova-second/link5">
                Лечение — хирургическое<Sup>6</Sup>:
              </LinkNavigate>
            </H2>
            <WrapperCouple sx={{mt: 3, mb: 2}}>
              <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                <Div sx={{display: 'flex'}}>
                  <Div sx={{minWidth: 50, m: '-6px 0 0 -6px'}}><Img src={images.numOne}/></Div>
                  <P sx={{m: 0, ml: 2}}>
                    Назначение больших доз эстрогенов при повреждении базального слоя эндометрия усиливает продукцию
                    медиаторов воспаления, что индуцирует фиброз тканей эндометрия
                  </P>
                </Div>
              </WrapperShellBox>
              <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                <Div sx={{display: 'flex'}}>
                  <Div sx={{minWidth: 50, m: '-6px 0 0 -6px'}}><Img src={images.numTwo}/></Div>
                  <P sx={{m: 0, ml: 2}}>
                    Назначение малых доз эстрогенов в послеоперационном периоде улучшает процессы репарации базального и
                    функционального слоя эндометрия
                  </P>
                </Div>
              </WrapperShellBox>
            </WrapperCouple>
          </WrapperShell>
        </Div>
      </Page>
      <ProductModal product="proginova/modal" modal={modal} onClose={handleClosePopup}/>
    </>
  )
}
