import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';
import pdf1 from '../../../pdfs/klimonorm/менопаузальная_гормонотерапия_и_сохранение_здоровья_женщин_в_зрелом_возрасте.pdf';
import pdf2 from '../../../pdfs/klimonorm/Консенсусное заявление IMS Climacteric 2009 12 368–377.pdf';

export const KlomonormLinkOne = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Менопаузальная гормонотерапия и сохранение здоровья женщин в зреломвозрасте</H1>
      <P sx={{color: PinkLight, fontSize: 20}}>[письмо Минздрава РФ от 2 октября 2015 г. № 15-4/10/2-5804]</P>
      <P>Клинические рекомендации (протокол лечения)
        «Менопаузальная гормонотерапия и сохранение
        здоровья женщин в зрелом возрасте»
        (утв. президентом Российского общества
        акушеров-гинекологов)
      </P>
      <a
        href={pdf1}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '35px 0 40px',
        }}
      >
        Статья PDF
      </a>
      <H1 sx={{color: PinkLight}}>
        Updated practical recommendations for hormone replacement therapy in the peri- and postmenopause
      </H1>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/18365854/">
        Updated practical recommendations for hormone replacement therapy in the peri- and postmenopause.
        Climacteric. 2008; 11: 116–119.
      </A>]
      </P>

      <H1 sx={{color: PinkLight, mb:0}}>КОНСЕНСУСНОЕ ЗАЯВЛЕНИЕ МЕЖДУНАРОДНОГО ОБЩЕСТВА ПО МЕНОПАУЗЕ</H1>
      <P sx={{color: PinkLight, fontSize: 20,}}>Climacteric 2009; 12:368-377</P>
      <a
        href={pdf2}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '35px 0 150px',
        }}
      >
        Статья PDF
      </a>
    </Page>
  )
}
