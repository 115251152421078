import {Box} from '@mui/material';

import {StyledBoxBorder, StyledBoxShadow, StyledCircle, StyledHeader} from '../../ui';
import {StyledWrapperBox} from './ui';
import images, {icons} from '../../img';

export const EffectiveSalesMedicaments = () => {
  return (
    <>
      <StyledHeader sx={{mb: 5, mt: 7}}>Препараты и показания к применению</StyledHeader>
      <StyledWrapperBox>
        <StyledBoxShadow sx={{display: 'flex', gap: 6}}>
          <Box>
            <StyledBoxBorder className="product-box">
              <Box className="title">
                КЛИМОНОРМ®
              </Box>
              <img src={images.klimonorm} alt="Климонорм"/>
            </StyledBoxBorder>
          </Box>
          <Box>
            <Box sx={{display: 'flex', gap: 2, mt: 2}}>
              <StyledCircle sx={{padding: '5px'}}>
                <img src={icons.drug} alt="Drug"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2}}>
                  <strong>9 таблеток жёлтого цвета: </strong>2 мг эстрадиола валерата
                </Box>
                <Box>
                  <strong>12 таблеток коричневого цвета: </strong>
                  2 мг эстрадиола валерата + <br/> 150 мкг левоноргестрела
                </Box>
              </Box>
            </Box>

            <Box sx={{display: 'flex', gap: 2, mt: 5}}>
              <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                <img src={icons.file} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                  <strong>Показания к применению</strong>
                </Box>
                <Box>
                  <ul className="list">
                    <li>
                      Заместительная гормональная терапия (ЗГТ)
                      при климактерических расстройствах, инволюционных
                      изменениях кожи и мочеполового тракта,
                      депрессивных состояниях в климактерическом
                      периоде, а также симптомах дефицита
                      эстрогенов вследствие естественной менопаузы
                      или гипогонадизма, стерилизации или первичной
                      дисфункции яичников у женщин с неудаленной маткой
                    </li>
                    <li>Профилактика постменопаузального остеопороза</li>
                    <li>Нормализация нерегулярных менструальных циклов</li>
                    <li>Лечение первичной или вторичной аменореи</li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{display: 'flex', gap: 6}}>
          <Box>
            <StyledBoxBorder className="product-box">
              <Box className="title">
                АЛВОВИЗАН
              </Box>
              <img src={images.alvovizan} alt="Алвовизан"/>
            </StyledBoxBorder>
          </Box>
          <Box>
            <Box sx={{display: 'flex', gap: 2, mt: 2}}>
              <StyledCircle sx={{padding: '5px'}}>
                <img src={icons.drug} alt="Drug"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2}}>
                  <strong>28 таблеток:</strong> <br/>
                  2 мг диеногест микронизированный
                </Box>
              </Box>
            </Box>

            <Box sx={{display: 'flex', gap: 2, mt: 3}}>
              <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                <img src={icons.file} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1, textTransform: 'uppercase'}}>
                  <strong>Показания к применению</strong>
                </Box>
                <Box>Лечение эндометриоза</Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{display: 'flex', gap: 6}}>
          <Box>
            <StyledBoxBorder className="product-box">
              <Box className="title">
                ЗОЭЛИ®
              </Box>
              <img src={images.zoely} alt="Зоэли"/>
            </StyledBoxBorder>
          </Box>
          <Box>
            <Box sx={{display: 'flex', gap: 2, mt: 2}}>
              <StyledCircle sx={{padding: '5px'}}>
                <img src={icons.drug} alt="Drug"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1}}>
                  <strong>24 белые таблетки:</strong> 2,5 мг номегэстрол + 1,5 мг эстрадиол <br/>
                </Box>
                <Box sx={{mb: 1}}>
                  <strong>4 жёлтые таблетки:</strong> плацебо
                </Box>
              </Box>
            </Box>

            <Box sx={{display: 'flex', gap: 2, mt: 3}}>
              <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                <img src={icons.file} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1, textTransform: 'uppercase'}}>
                  <strong>Показания к применению</strong>
                </Box>
                <Box>Контрацепция</Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{display: 'flex', gap: 6}}>
          <Box>
            <StyledBoxBorder className="product-box">
              <Box className="title">
                ВИДОРА
              </Box>
              <img src={images.vidora} alt="Видора"/>
            </StyledBoxBorder>
          </Box>
          <Box>
            <Box sx={{display: 'flex', gap: 2, mt: 2}}>
              <StyledCircle sx={{padding: '5px'}}>
                <img src={icons.drug} alt="Drug"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1}}>
                  <strong>21 активная таблетка жёлтого цвета.</strong> 1 таблетка содержит: <br/>
                  дроспиренон — 3,00 мг, этинилэстрадиол — 0,03 мг.
                </Box>
                <Box sx={{mb: 1}}>
                  <strong>7 таблеток плацебо белого цвета</strong>
                </Box>
              </Box>
            </Box>

            <Box sx={{display: 'flex', gap: 2, mt: 3}}>
              <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                <img src={icons.file} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1, textTransform: 'uppercase'}}>
                  <strong>Показания к применению</strong>
                </Box>
                <Box>Контрацепция</Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{display: 'flex', gap: 6}}>
          <Box>
            <StyledBoxBorder className="product-box">
              <Box className="title">
                ВИДОРА Микро 24+4
              </Box>
              <img src={images.vidora_24_4} alt="ВИДОРА Микро 24+4"/>
            </StyledBoxBorder>
          </Box>
          <Box>
            <Box sx={{display: 'flex', gap: 2, mt: 2}}>
              <StyledCircle sx={{padding: '5px'}}>
                <img src={icons.drug} alt="Drug"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1}}>
                  <strong>
                    24 активные таблетки от свело-розового
                    до розового цвета.
                  </strong>
                  1 таблетка содержит:
                  дроспиренон — 3,00 мг,
                  этинилэстрадиол — 0,02 мг
                </Box>
                <Box sx={{mb: 1}}>
                  <strong>4 таблетки плацебо белого цвета</strong>
                </Box>
              </Box>
            </Box>

            <Box sx={{display: 'flex', gap: 2, mt: 3}}>
              <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                <img src={icons.file} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1, textTransform: 'uppercase'}}>
                  <strong>Показания к применению</strong>
                </Box>
                <Box>
                  <ul className="list">
                    <li>Контрацепция</li>
                    <li>
                      Контрацепция и лечение угревой сыпи
                      средней степени тяжести (acne vulgaris)
                    </li>
                    <li>
                      Контрацепция и лечение тяжёлой
                      формы предменструального синдрома
                      (ПМС)
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{display: 'flex', gap: 6}}>
          <Box>
            <StyledBoxBorder className="product-box">
              <Box className="title">
                ВИДОРА Микро 21+7
              </Box>
              <img src={images.vidora_21_7} alt="ВИДОРА Микро 21+7"/>
            </StyledBoxBorder>
          </Box>
          <Box>
            <Box sx={{display: 'flex', gap: 2, mt: 2}}>
              <StyledCircle sx={{padding: '5px'}}>
                <img src={icons.drug} alt="Drug"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1}}>
                  <strong>
                    21 активная таблетка от свело-розового
                    до розового цвета.
                  </strong>
                  1 таблетка содержит:
                  дроспиренон — 3,00 мг,
                  этинилэстрадиол — 0,02 мг
                </Box>
                <Box sx={{mb: 1}}>
                  <strong>7 таблеток плацебо белого цвета</strong>
                </Box>
              </Box>
            </Box>

            <Box sx={{display: 'flex', gap: 2, mt: 3}}>
              <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                <img src={icons.file} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1, textTransform: 'uppercase'}}>
                  <strong>Показания к применению</strong>
                </Box>
                <Box>
                  <ul className="list">
                    <li>Контрацепция</li>
                    <li>
                      Контрацепция и лечение угревой сыпи
                      средней степени тяжести (acne vulgaris)
                    </li>
                    <li>
                      Контрацепция и лечение тяжёлой
                      формы предменструального синдрома
                      (ПМС)
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{display: 'flex', gap: 6}}>
          <Box>
            <StyledBoxBorder className="product-box">
              <Box className="title">
                ДИЕЦИКЛЕН
              </Box>
              <img src={images.diecyclen} alt="ДИЕЦИКЛЕН"/>
            </StyledBoxBorder>
          </Box>
          <Box>
            <Box sx={{display: 'flex', gap: 2, mt: 2}}>
              <StyledCircle sx={{padding: '5px'}}>
                <img src={icons.drug} alt="Drug"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1}}>
                  <strong>
                    21 таблетка белого цвета.
                  </strong>
                </Box>
                <Box sx={{mb: 1}}>
                  1 таблетка содержит: диеногест — 2,00 мг, этинилэстрадиол — 0,03 мг
                </Box>
              </Box>
            </Box>

            <Box sx={{display: 'flex', gap: 2, mt: 5}}>
              <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                <img src={icons.file} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1, textTransform: 'uppercase'}}>
                  <strong>Показания к применению</strong>
                </Box>
                <Box>
                  <ul className="list">
                    <li>Пероральная контрацепция</li>
                    <li>
                      Лечение акне легкой и средней степени тяжести
                      женщин, нуждающихся в контрацепции, при неэффективности
                      местного лечения
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{display: 'flex', gap: 6}}>
          <Box>
            <StyledBoxBorder className="product-box">
              <Box className="title">
                ВЕЛЛЕДИЕН
              </Box>
              <img src={images.velledien} alt="ВЕЛЛЕДИЕН"/>
            </StyledBoxBorder>
          </Box>
          <Box>
            <Box sx={{display: 'flex', gap: 2, mt: 2}}>
              <StyledCircle sx={{padding: '5px'}}>
                <img src={icons.drug} alt="Drug"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2}}>
                  <strong>
                    28 таблеток: <br/>
                  </strong>
                  2,5 мг тиболон
                </Box>
              </Box>
            </Box>

            <Box sx={{display: 'flex', gap: 2, mt: 5}}>
              <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                <img src={icons.file} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1, textTransform: 'uppercase'}}>
                  <strong>Показания к применению</strong>
                </Box>
                <Box>
                  <ul className="list">
                    <li>
                      Лечение симптомов эстрогенной
                      недостаточности у женщин
                      в постменопаузальном периоде (не
                      ранее чем через 1 год после последней
                      менструации при наступлении
                      естественной менопаузы или сразу после хирургической менопаузы)
                    </li>
                    <li>
                      Профилактика постменопаузального
                      остеопороза у женщин с высоким риском
                      переломов при непереносимости
                      или противопоказаниях к применению
                      других лекарственных препаратов,
                      предназначенных для лечения остеопороза
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow sx={{display: 'flex', gap: 6}}>
          <Box>
            <StyledBoxBorder className="product-box">
              <Box className="title">
                ПРОГИНОВА®
              </Box>
              <img src={images.proginova} alt="ПРОГИНОВА®"/>
            </StyledBoxBorder>
          </Box>
          <Box>
            <Box sx={{display: 'flex', gap: 2, mt: 2}}>
              <StyledCircle sx={{padding: '5px'}}>
                <img src={icons.drug} alt="Drug"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2}}>
                  <strong>
                    21 драже белого цвета:<br/>
                  </strong>
                  2 мг эстрадиола валерата
                </Box>
              </Box>
            </Box>

            <Box sx={{display: 'flex', gap: 2, mt: 5}}>
              <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                <img src={icons.file} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 1, textTransform: 'uppercase'}}>
                  <strong>Показания к применению</strong>
                </Box>
                <Box>
                  <ul className="list">
                    <li>
                      Заместительная гормональная терапия
                      (ЗГТ) при симптомах дефицита эстрогенов
                      у женщин в постменопаузальном
                      периоде (не раннее чем через 6 месяцев
                      после последней менструации в случае
                      естественной менопаузы или сразу же после
                      менопаузы в результате хирургического
                      вмешательства, например, после
                      двухсторонней овариэктомии)
                    </li>
                    <li>
                      Профилактика остеопороза
                      в постменопаузе у женщин с высоким
                      риском переломов при непереносимости
                      или наличии противопоказаний
                      к применению других лекарственных
                      средств для профилактики остеопороза.
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

      </StyledWrapperBox>
    </>
  )
}
