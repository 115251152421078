import {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {ProductModalType} from 'components/product-modal/types';
import {Tab, TabsGroup} from 'components/tabs';
import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import {Img, LinkNavigate, List as UL, ListItem as LI, Tables} from 'shared/ui';
import Page from 'shared/ui/Page';
import {WrapperCouple} from 'components/wrapper-couple';
import {ProductModal} from 'components/product-modal';
import {Black, PinkLight, Violet} from 'styles/color';

import {H2, Sup, Paragraph as P, H1, Div} from './ui';

import images from './img';
import {SectionBlock, SectionCompany} from './section';
import Popup from 'shared/ui/Popup';
import PrezentationItem from './PrezentationItem';

export const Vidora = () => {
  const history = useHistory();

  const [modal, setModal] = useState<ProductModalType>(null);
  const [modalSecond, setModalSecond] = useState<ProductModalType>(null);
  const handleClosePopup = () => {
    setModal(null);
    setModalSecond(null);
  }

  const handleOpenModalSecond = () => {
    setModalSecond('Материалы');
  }

  const handleOpenModalInstruction = () => {
    setModal('Иструкции');
  }

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  const handleOpenVideo = () => {
  }

  return (
    <>
      <Page head={'ВИДОРА'} backText="Продукты" onClose={handleRedirectToProducts}>
        <TabsGroup>
          <Tab onClick={handleOpenModalInstruction}>Инструкция</Tab>
          <Tab onClick={handleOpenModalSecond}>Материалы</Tab>
          <Tab onClick={handleOpenVideo}>Видео</Tab>
        </TabsGroup>

        <Div sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <WrapperShell sx={{backgroundImage: `url(${images.doctorMain})`, height: 500}}>
            <H2 sx={{color: Black, fontSize: 36, maxWidth: 550, fontFamily: 'SF-Pro-Text-Regular', pl: 5}}>
              Социально-ответственные врачи сейчас назначают качественные дженерики
            </H2>
          </WrapperShell>

          <SectionBlock/>

          <WrapperShellBox sx={{pt: 5, pb: 5}}>
            <WrapperShellBox sx={{boxShadow: 'none', display: 'flex', p: 0}}>
              <Img sx={{height: 40, mr: 2}} src={images.mark}/>
              <H1 sx={{color: PinkLight}}>ЯРИНА® — ОРИГИНАЛЬНЫЙ ПРЕПАРАТ</H1>
            </WrapperShellBox>
            <WrapperCouple className="text-light">
              <WrapperShellBox sx={{boxShadow: 'none', p: 0}}>
                <H2 sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Regular'}}>СОСТАВ:</H2>
                <P>
                  1 таблетка содержит: <br/>
                  дроспиренон – 3,00 мг,<br/>
                  этинилэстрадиол – 0,03 мг.<br/>
                </P>
              </WrapperShellBox>
              <WrapperShellBox sx={{boxShadow: 'none', p: 0}}>
                <H2 sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Regular'}}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ*:</H2>
                <P>Контрацепция</P>
                <P sx={{fontSize: 16}}>*Инструкция по медицинскому применению препарата Ярина</P>
              </WrapperShellBox>
            </WrapperCouple>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell
              className="small"
              sx={{maxHeight: 628, backgroundImage: `url(${images.redHead})`, height: 628}}
            />
            <WrapperShell sx={{pt: 3}}>
              <H1 sx={{color: '#DE8642', fontFamily: 'SF-Pro-Text-Regular', mt: 2, mb: 1}}>ВИДОРА Микро 24+4:</H1>
              <H2 sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Regular', mt: 1}}>
                ИМЕЕТ ТОТ ЖЕ СОСТАВ И ТЕ ЖЕ ПОКАЗАНИЯ, ЧТО И ОРИГИНАЛЬНЫЙ ПРЕПАРАТ
              </H2>
              <H2 sx={{color: '#DE8642', fontFamily: 'SF-Pro-Text-Regular', mb: 1}}>
                СОСТАВ:
              </H2>
              <P sx={{color: PinkLight, mt: 2}}>
                24 активных таблетки от светло-розового до розового <br/> цвета. <br/>
                1 таблетка содержит: дроспиренон – 3,00 мг, <br/> этинилэстрадиол – 0,02 мг. <br/>
                4 таблетки плацебо белого цвета.
              </P>
              <H2 sx={{color: '#DE8642', fontFamily: 'SF-Pro-Text-Regular'}}>
                <LinkNavigate to="/product/vidora-micro/instruction">ПОКАЗАНИЯ К ПРИМЕНЕНИЮ<Sup>3</Sup>:</LinkNavigate>
              </H2>
              <UL>
                <LI><P sx={{color: PinkLight, m: '6px 0'}}>Контрацепция</P></LI>
                <LI><P sx={{color: PinkLight, m: '6px 0'}}>Контрацепция и лечение угревой сыпи средней тяжести (acne
                  vulgaris)</P></LI>
                <LI><P sx={{color: PinkLight, m: '6px 0'}}>Контрацепция и лечение тяжелой формы предменструального
                  периода
                  (ПМС)</P></LI>
              </UL>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShellBox sx={{pt: 5, pb: 5}}>
            <WrapperShellBox sx={{boxShadow: 'none', display: 'flex', p: 0}}>
              <Img sx={{height: 40, mr: 2}} src={images.markYellow}/>
              <H1 sx={{color: PinkLight}}>ДЖЕС® — ОРИГИНАЛЬНЫЙ ПРЕПАРАТ</H1>
            </WrapperShellBox>
            <WrapperCouple className="text-light">
              <WrapperShellBox sx={{boxShadow: 'none', p: 0}}>
                <H2 sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Regular'}}>СОСТАВ:</H2>
                <P>
                  1 таблетка содержит: дроспиренон – 3,00 мг,<br/>
                  этинилэстрадиол – 0,02 мг.
                </P>
              </WrapperShellBox>
              <WrapperShellBox sx={{boxShadow: 'none', p: 0}}>
                <H2 sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Regular'}}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ*:</H2>
                <P>
                  Контрацепция; Контрацепция и лечение угревой сыпи средней тяжести (acne vulgaris); Контрацепция и
                  лечение тяжелой формы предменструального периода (ПМС)
                </P>
                <P sx={{fontSize: 16}}>*Инструкция по медицинскому применению препарата Джес</P>
              </WrapperShellBox>
            </WrapperCouple>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell sx={{pt: 3, backgroundColor: PinkLight}}>
              <H2 sx={{color: '#0076BD', fontFamily: 'SF-Pro-Text-Regular', fontSize: 36, mt: 2}}>ВИДОРА Микро
                21+7:</H2>
              <H2 sx={{color: '#0076BD', fontFamily: 'SF-Pro-Text-Regular', mt: 3}}>
                СОСТАВ:
              </H2>
              <P sx={{color: Black}}>
                24 активных таблетки от светло-розового до розового <br/> цвета. <br/>
                1 таблетка содержит: дроспиренон – 3,00 мг, <br/> этинилэстрадиол – 0,02 мг. <br/>
                7 таблеток плацебо белого цвета.
              </P>
              <H2 onClick={handleOpenModalInstruction} sx={{color: '#0076BD', fontFamily: 'SF-Pro-Text-Regular'}}>
                ПОКАЗАНИЯ К ПРИМЕНЕНИЮ<Sup>3</Sup>:
              </H2>
              <UL>
                <LI className="marker-black"><P sx={{color: Black, m: '6px 0'}}>Контрацепция</P></LI>
                <LI className="marker-black"><P sx={{color: Black, m: '6px 0'}}>Контрацепция и лечение угревой сыпи
                  средней тяжести
                  (acne
                  vulgaris)</P></LI>
                <LI className="marker-black"><P sx={{color: Black, m: '6px 0'}}>Контрацепция и лечение тяжелой формы
                  предменструального периода
                  (ПМС)</P></LI>
              </UL>
            </WrapperShell>
            <WrapperShell
              className="small"
              sx={{maxHeight: 628, backgroundImage: `url(${images.selfie})`, height: 528}}
            />
          </WrapperCouple>

          <SectionCompany/>

          <WrapperShell>
            <h2
              style={{
                fontSize: 36,
                lineHeight: '43px',
                fontFamily: 'SF Pro Display Semibold',
                display: 'flex',
                alignItems: 'center',
                marginTop: 10,
                color: '#F9D2D6'
              }}
            >
              Сравнительный анализ стоимости
            </h2>
            <Tables.WrapperTable>
              <Tables.Table className="align-default" sx={{fontSize: 18, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr>
                    <Tables.Td sx={{width: 250}}>Состав</Tables.Td>
                    <Tables.Td colSpan={2}>Дроспиренон – 3 мг, ЭЭ – 0,03 мг</Tables.Td>
                    <Tables.Td colSpan={2}>Дроспиренон – 3 мг, ЭЭ – 0,02 мг</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: PinkLight}}>
                    <Tables.Td sx={{color: Black, width: 250}}>Препарат</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Violet, width: 125}}>Видора <br/> №21+7 </Tables.Td>
                    <Tables.Td sx={{color: Black, width: 125}}>Ярина <br/> №21+7</Tables.Td>
                    <Tables.Td
                      sx={{backgroundColor: '#DE8642', color: '#FFF', width: 125}}>Видора <br/> №24+4</Tables.Td>
                    <Tables.Td sx={{color: Black, width: 125}}>Джес <br/> №24+4</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td>Средняя стоимость в аптеке, ₽</Tables.Td>
                    <Tables.Td>660</Tables.Td>
                    <Tables.Td>1 240</Tables.Td>
                    <Tables.Td>660</Tables.Td>
                    <Tables.Td>1 210</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / месяц, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>580</Tables.Td>
                    <Tables.Td colSpan={2}>550</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / год — 12 циклов, ₽</Tables.Td>
                    <Tables.Td colSpan={2}><b>6 960</b></Tables.Td>
                    <Tables.Td colSpan={2}><b>6 600</b></Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell className="small" sx={{backgroundColor: PinkLight}}>
              <Img src={images.approve}/>
              <H2 sx={{color: Violet, fontFamily: 'SF-Pro-Text-Semibold'}}>
                Назначая Видоры вместо Джеса и Ярины в тех же терапевтических нишах, где и оригинальный препарат, вы
                обеспечиваете качественной помощью бо́льшее количество пациенток.
              </H2>
            </WrapperShell>
            <WrapperShell sx={{backgroundColor: PinkLight}}>
              <Img sx={{mb: 3}} src={images.plus}/>
              <P sx={{color: Black}}>
                С учётом того, что Видоры обладают косметическим эффектом, это поможет молодым пациенткам улучшить
                состояние кожи, волос и ногтей. А ведь, как правило, на аборт приходят именно молодые девушки. Поэтому
                Вы
                обеспечите надежную контрацепцию от нежелательной беременности в дальнейшем и дополнительный
                косметический
                эффект, повышая приверженность у пациентки к КОКам. Чтобы она вновь не стала использовать прерванный
                половой акт и не вернулась к вам на очередной аборт.
              </P>
              <P sx={{mt: 3, color: Black, fontFamily: 'SF-Pro-Text-Bold'}}>
                <b>А мы позаботимся о том, чтобы Видора всегда была доступна всем.</b>
              </P>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShellBox sx={{pt: 5}}>
            <H1 sx={{color: PinkLight, mt: 1}}>Механизмы гиперандрогении</H1>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell sx={{backgroundColor: PinkLight}}>
              <H2 sx={{color: Black}}>Образование андрогенов у женщины</H2>
              <Img src={images.schema}/>
            </WrapperShell>
            <WrapperShell>
              <H2 sx={{color: PinkLight}}>Причины гиперандрогении</H2>
              <UL>
                <LI className="marker-pink"><P sx={{color: PinkLight}}>Много вырабатывается — <b>яичники,
                  надпочечники</b></P></LI>
                <LI className="marker-pink"><P sx={{color: PinkLight}}>Мало связывается в неактивные формы — <b>мало
                  ГСПГ</b></P></LI>
                <LI className="marker-pink"><P sx={{color: PinkLight}}>Много метаболизируется в активные формы — <b>5
                  альфа редуктаза</b></P></LI>
                <LI className="marker-pink"><P sx={{color: PinkLight}}>Повышенная чувствительность — <b>рецепторный
                  аппарат</b></P></LI>
              </UL>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShellBox sx={{pt: 5}}>
            <H1 sx={{color: PinkLight, mt: 1}}>Антиандрогенный эффект КОК обусловлен несколькими взаимодополняющими
              механизмами</H1>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell>
              <Img src={images.one}/>
              <Div>
                <P sx={{height: 120, color: PinkLight}}>Подавление уровня ЛГ, синтеза тестостерона.</P>
                <hr/>
                <P sx={{color: PinkLight}}>Есть у всех КОК</P>
              </Div>
            </WrapperShell>
            <WrapperShell>
              <Img src={images.two}/>
              <Div>
                <P sx={{height: 120, color: PinkLight}}>
                  Эстрогенный компонент увеличивает продукцию ГСПГ в печени.
                </P>
                <hr/>
                <P sx={{color: PinkLight}}>Более выражен у КОК, содержащих этинилэстрадиол</P>
              </Div>
            </WrapperShell>
            <WrapperShell sx={{flexBasis: 'max-content'}}>
              <Img src={images.tree}/>
              <Div sx={{width: '100%', minWidth: 450}}>
                <P sx={{height: 120, color: PinkLight}}>
                  Блокада рецепторов к тестостерону в органах-мишенях и влияние на фермент 5-альфа-редуктазу,
                  участвующую
                  в синтезе дигидротестостерона (ДГТ) из тестостерона (Т).
                </P>
                <hr/>
                <P sx={{color: PinkLight}}>Есть у КОК с антиандрогенным Гестагеном</P>
              </Div>
            </WrapperShell>
          </WrapperCouple>

          <Div sx={{display: 'flex', gap: '20px', justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <WrapperShellBox sx={{p: 0, height: 270, width: 653}}>
              <Img src={images.group}/>
            </WrapperShellBox>
            <WrapperShellBox sx={{height: 270, width: 326, flex: 'auto', backgroundColor: PinkLight}}>
              <Img src={images.vector}/>
              <H2 sx={{mt: 1, color: Violet}}>Снижает<br/> количество<br/> андрогенов и их<br/> влияние на кожу
                и<br/> волосы</H2>
            </WrapperShellBox>
          </Div>

          <WrapperCouple sx={{mb: 6}}>
            <WrapperShell>
              <H1 sx={{color: PinkLight}}>Лечение акне в зависимости от степени тяжести</H1>
              <Img src={images.add}/>
              <H2 sx={{mt: 1, color: PinkLight}}>ЛЕГКАЯ</H2>
              <UL sx={{mt: 0}}>
                <LI className="marker-pink"><P sx={{color: PinkLight, m: 0}}><b>Антиандрогенные КОК</b></P></LI>
                <LI className="marker-pink"><P sx={{color: PinkLight, m: 0}}>Лечебная косметика и процедуры</P></LI>
              </UL>

              <Div sx={{display: 'flex', justifyContent: 'flex-start', mt: 4}}>
                <Img src={images.add}/>
                <Img src={images.add}/>
              </Div>
              <H2 sx={{mt: 1, color: PinkLight}}>СРЕДНЯЯ</H2>
              <UL sx={{mt: 0}}>
                <LI className="marker-pink"><P sx={{color: PinkLight, m: 0}}><b>Антиандрогенные КОК</b></P></LI>
                <LI className="marker-pink"><P sx={{color: PinkLight, m: 0}}>Лечебная косметика и процедуры</P></LI>
              </UL>

              <Div sx={{display: 'flex', justifyContent: 'flex-start', mt: 4}}>
                <Img src={images.add}/>
                <Img src={images.add}/>
              </Div>
              <H2 sx={{mt: 1, color: PinkLight}}>ТЯЖЕЛАЯ</H2>
              <UL sx={{mt: 0}}>
                <LI className="marker-pink"><P sx={{color: PinkLight, m: 0}}><b>Антиандрогенные КОК</b></P></LI>
                <LI className="marker-pink"><P sx={{color: PinkLight, m: 0}}>Лечебная косметика и процедуры</P></LI>
                <LI className="marker-pink"><P sx={{color: PinkLight, m: 0}}>Специальные лечебные препараты</P></LI>
              </UL>
            </WrapperShell>
            <WrapperShell className="small"
                          sx={{boxShadow: 'none', display: 'flex', flexDirection: 'column', gap: '20px', p: 0}}>
              <WrapperShell sx={{backgroundImage: `url(${images.blonde})`, minHeight: '360px!important'}}/>
              <WrapperShell>
                <Img sx={{mt: 2}} src={images.warnMark}/>
                <H2 sx={{color: PinkLight, fontSize: 36}}>У мужчин такой возможности нет!</H2>
                <P sx={{color: PinkLight}}>Только специальные лечебные препараты, лечебная косметика и процедуры.</P>
              </WrapperShell>
            </WrapperShell>
          </WrapperCouple>
        </Div>
      </Page>

      <Popup opened={Boolean(modal)} togglePopup={handleClosePopup} title={modal || ''}>
        <PrezentationItem name="Видора инструкция" link="/product/vidora/instruction"/>
        <PrezentationItem name="Видора-Микро инструкция" link="/product/vidora-micro/instruction"/>
      </Popup>

      <ProductModal product="vidora" modal={modalSecond} onClose={handleClosePopup}/>
    </>
  )
}
