import {styled} from '@mui/system';
import images from './img'

export const H2 = styled('h2')({
  fontSize: 28,
  fontFamily: 'SF-Pro-Text-Semibold',
  marginBottom: 10,

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const H1 = styled('h1')({
  fontSize: 36,
  fontFamily: 'SF-Pro-Text-Bold',
  lineHeight: '43px',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const Paragraph = styled('p')({
  fontSize: 20,
  fontFamily: 'SF-Pro-Text-Regular',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {}
})
export const Div = styled('div')({
  '.link-black': {
    position: 'relative',
    ':after': {
      position: 'absolute',
      left: 0,
      top: 46,
      content: `url(${images.linkBlack})`,
      '@media only screen and (max-width: 1080px)': {
        display: 'none'
      }
    }
  },

  '.link-blue': {
    position: 'relative',
    ':after': {
      position: 'absolute',
      left: -192,
      top: 46,
      content: `url(${images.linkBlue})`,

      '@media only screen and (max-width: 1080px)': {
        display: 'none'
      }
    }
  },

  '.clamp': {
    position: 'relative',
    ':after': {
      position: 'absolute',
      left: 34,
      top: -27,
      content: `url(${images.clamp})`,
    }
  },

  '.wrap-768': {
    '@media only screen and (max-width: 768px)': {
      flexWrap: 'wrap',

      '*>img': {
        marginTop: 0,
        width: 260
      }
    }
  }
})

export const Span = styled('span')({
  wordBreak: 'normal'
})

export const Sup = styled('sup')({
  color: '#0076BD',
  fontWeight: 'bold',
  fontSize: '0.688em',
  left: '2px',
  verticalAlign: 'top',
  position: 'relative',
  top: '-0.3em',
})