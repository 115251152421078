import main from './main.png'
import warn from './warn.png'
import chartOne from './chart.png'
import chartTwo from './chart2.png'
import chartThree from './chart3.png'
import chartFour from './chart4.png'
import plus from './plus.png'
import balance from './balance.png'
import approveRed from './approve-red.png'
import face from './face.png'
import mainBottom from './main-bottom.png'
import quotation from './quotation.png'
import warnRed from './warn-red.png'
import approve from './approve.png'

export default {
  main,
  warn,
  chartOne,
  chartTwo,
  chartThree,
  chartFour,
  plus,
  balance,
  approveRed,
  face,
  mainBottom,
  quotation,
  warnRed,
  approve,
}