import {styled} from '@mui/system';

import {H1, Paragraph} from 'shared/ui';
import {PinkLight} from 'styles/color';

import { H2 } from '../ui';

export const StyledParagraph = styled(Paragraph)({
  fontSize: 16,
  lineHeight: '20px',
  margin: '14px 0',
})

export const Link = styled('a')({
  color: '#0076BD',
})

export const StyledH2 = styled(H2)({
  color: PinkLight,
  fontFamily: 'SF-Pro-Text-Semibold',
  textTransform: 'uppercase',
  marginTop: 40,
})

export const StyledH1 = styled(H1)({
  color: PinkLight,
  fontFamily: 'SF-Pro-Text-Semibold',
  fontSize: 38,
})

export const LinkA = styled('a')({
  display: 'block',
  color: '#0076BD',
  margin: '10px auto',
  letterSpacing: '0.1px',
  fontSize: 17,
  fontFamily: 'SF-Pro-Text-Semibold',
})
