import {Link} from 'react-router-dom';
import file from '../../../assets/img/file.svg';
import video from './video.svg';
import plus from 'assets/img/plus.svg';
import linkICON from 'assets/img/link.png';

import styles from './styles.module.scss';

type PrezentationItemType = {
  name: string;
  link: string;
  icon?: 'video' | 'plus' | 'link';
};

function getIcon(iconLabel?: string) {
  switch (iconLabel) {
    case 'video': return video;
    case 'plus': return plus;
    case 'link': return linkICON;
    default: return file
  }
}


export default ({name, link, icon,}: PrezentationItemType) => {
  return (
    <Link
      to={link}
      className={styles.wrap}
    >
      <img src={getIcon(icon)} alt=""/>
      <span className={styles.text}>{name}</span>
    </Link>
  );
};
