import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const KlomonormLinkEight = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Black cohosh (Cimicifuga racemosa): a systematic review of adverse events</H1>
      <P>Black cohosh (Cimicifuga racemosa) is used most often to treat symptoms that can occur during menopause. However, in the last years, several concerns regarding its safety have been voiced. The aim of this systematic review was to evaluate the clinical evidence for or against the safety of black cohosh. Systematic literature searches were conducted in 5 computerized databases (Medline, Embase, Amed, Phytobase, and Cochrane Library). The references of all located articles were scanned for further relevant publications. Any type of clinical data that included case reports and observational studies was considered. No language restrictions were imposed. Thirteen clinical trials (all of which indicated relative safety), 3 postmarketing surveillance studies, 4 case series, and 8 single case reports were identified. Clinical studies suggest black cohosh to be safe. In most case reports, causal attribution is problematic. In conclusion, black cohosh has been associated with serious safety concerns that urgently require further investigation.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/18984078/">Borrelli F., Ernst E. Black cohosh (Cimicifuga racemosa): a systematic review of adverse events. Am J Obstet Gynecol. 2008; 199(5): 455–66.</A>]
      </P>
    </Page>
  )
}
