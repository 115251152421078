import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanSecondLinkEight = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>Journal of Endometriosis and Pelvic Pain Disorders</H1>
      <P>The Journal of Endometriosis and Pelvic Pain Disorders (JEPPD) publishes basic and clinical original research
        articles and critical reviews focusing specifically on diagnosis, medical and surgical treatment of
        endometriosis in all its multidimensional aspects. In particular, contributions on the epidemiology of the
        disease, of its diagnosis and classification, and of its medical, social, psychological and health outcome
        consequences are welcome. Manuscripts related to uterine disorders and other gynecological and non-gynecological
        diseases leading to pelvic pain are also a specific focus of the journal.
      </P>
      <P>
        [ссылка: <A
        href="https://us.sagepub.com/en-us/nam/journal-of-endometriosis-and-pelvic-pain-disorders/journal203464#description">Ota
        Y et al. Journal of Endometriosis and Pelvic Pain Disorders
        2015; 7(2): 63-67</A>]
      </P>
    </Page>
  )
}
