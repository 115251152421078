import style from 'components/Product/styles.module.scss';
import {Link, useHistory, useLocation} from 'react-router-dom';
import clsx from 'clsx';

import Page from 'shared/ui/Page';
import {products} from 'shared/contants';
import Popup from 'shared/ui/Popup';
//TODO move to ./components
import PrezentationItem from '../vidora/PrezentationItem';
import styles from './styles.module.scss';

import klimen from '../../assets/img/bayer/klimen.png';
import sales from './img/sales.png';
import doctors from './img/doctors.png';
import stock from './img/stock.png';

export default () => {
  const {pathname} = useLocation();
  const history = useHistory();

  const handleClosePopup = () => {
    history.push('/');
  }

  return (
    <>
      <Page backText="Главная">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: 36,
              color: '#F9D2D6',
              fontWeight: 'bold',
            }}
          >
            ZENTIVA WOMAN HEALTH
          </div>
        </div>
        <div className={styles.table}>
          {products.map((item, index) => (
            <>
              {index === 8 && (
                <Link
                  key={`${item.id}-${index}-klimen`}
                  className={styles.item}
                  to={'/bayer'}
                >
                  <div className={styles.productName}>Климен</div>
                  <img className={style.productPic} src={klimen} alt=""/>
                </Link>
              )}
              <Link
                key={`${item.id}-${index}`}
                className={styles.item}
                to={`/product/${item.id}`}
              >
                <div className={styles.productName}>{item.name}</div>
                <img className={style.productPic} src={item.img} alt=""/>
              </Link>
            </>
          ))}
        </div>
        <div className={clsx(styles.table, styles.tableunder)}>
          <Link
            className={clsx(styles.item, styles.itemunder)}
            to={`/promotions`}
          >
            <img src={stock}/>
            <div className={clsx(styles.productName, styles.productNameunder)}>
              SUPER АКЦИИ
            </div>
          </Link>
          <Link
            className={clsx(styles.item, styles.itemunder)}
            to={`/conference`}
          >
            <img src={doctors}/>
            <div className={clsx(styles.productName, styles.productNameunder)}>
              КОНФЕРЕНЦИИ<br/> И КОНГРЕССЫ
            </div>
          </Link>
          <Link
            className={clsx(styles.item, styles.itemunder)}
            to={'/product/sales/modal'}
          >
            <img src={sales}/>
            <div className={clsx(styles.productName, styles.productNameunder)}>
              ЭФФЕКТИВНЫЕ ПРОДАЖИ
            </div>
          </Link>
        </div>
      </Page>

      {/* TODO заменить components/proginova */}
      <Popup opened={pathname === '/product/proginova/modal'} togglePopup={handleClosePopup} title={'Прогинова'}>
        <PrezentationItem icon="plus" name="Помощь новой жизни" link="/product/proginova"/>
        <PrezentationItem icon="plus" name="Помощь при эстрогенной недостаточности" link="/product/proginova-second"/>
      </Popup>

      <Popup opened={pathname === '/product/alvovizan/modal'} togglePopup={handleClosePopup} title={'Алвовизан'}>
        <PrezentationItem icon="plus" name="Расширение терапевтического взгляда на эндометриоз" link="/product/alvovizan"/>
        <PrezentationItem icon="plus" name="Привычка всех лечить оперативно" link="/product/alvovizan-second"/>
        <PrezentationItem icon="plus" name="Социально-ответственная терапия эндометриоза" link="/product/alvovizan-thirteen"/>
      </Popup>

      <Popup opened={pathname === '/product/sales/modal'} togglePopup={handleClosePopup} title={'Эффективные продажи'}>
        <PrezentationItem icon="link" name="Цели работы" link="/effective-sales/target"/>
        <PrezentationItem icon="link" name="Препараты и показания к применению" link="/effective-sales/medicaments"/>
        <PrezentationItem icon="link" name="Позиционирование" link="/effective-sales/positioning"/>
        <PrezentationItem icon="link" name="Конкуренты" link="/effective-sales/competitors"/>
        <PrezentationItem icon="link" name="Рынок половых стероидов в России" link="/effective-sales/market"/>
        <PrezentationItem icon="link" name="Визит к врачу" link="/effective-sales/to-doctor"/>
        <PrezentationItem icon="link" name="Сценарии визитов" link="/effective-sales-scenarios/klimonorm"/>
        <PrezentationItem icon="link" name="Визиты: Апрель-Июль" link="/effective-sales/visits-april-july-proginova"/>
      </Popup>
    </>
  );
};
