import { Box } from '@mui/material';
import req from './icons/req.png';
import res from './icons/res.png';
import {StyledBox} from './ui';

type Props = {
  children: React.ReactNode;
  isQuestion?: boolean;
}
export const Message = ({children, isQuestion = true}: Props) => {
  const clsFlex = isQuestion ? 'flex-start' : 'flex-end';
  return (
    <Box sx={{display: 'flex', gap: '12px', alignItems: 'flex-start', justifyContent: clsFlex}}>
      {isQuestion && (
        // <StyledCircle sx={{padding: '5px'}}>
        //   <img src={req} alt="*"/>
        // </StyledCircle>
        <img style={{objectFit: 'contain', marginTop: '-4px', margin: '-5px 0'}} src={req} alt="*"/>
      )}
      <StyledBox className={isQuestion ? '' : 'fill'}>{children}</StyledBox>
      {!isQuestion && (
        <img style={{objectFit: 'contain', marginTop: '-4px', margin: '-5px 0'}} src={res} alt="*"/>
      )}
    </Box>
  )
}