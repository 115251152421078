type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

export const Rectangle = ({width = 20, height = 20, fill = 'gray'}: Props) => {
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg style={{minWidth: 20}} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="20" fill={fill}/>
    </svg>
  )
}
