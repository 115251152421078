import {styled} from '@mui/system';
import {Box} from '@mui/material';

import {Black, PinkLight} from '../../../../styles/color';

export const StyledWrapperBox = styled(Box)`
  font-family: "SF Pro Text", sans-serif;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  & .wrapper-container {
    margin-top: 40px;
    display: flex;;
    gap: 20px;
    width: 100%;
    padding-bottom: 15px;
  }
  
  & .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  & .list {
    padding-left: 30px;

    li {
      margin-bottom: 10px;
    }
  }
  
  & .border-box {
    text-align: center;
    
    &__title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
`

export const StyledBoxAnchor = styled('a')`
  padding: 10px 30px;
  background-color: ${Black};
  border: 1px solid #FFFFFF;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  text-transform: uppercase;
  line-height: 18px;
  font-size: 16px;
  font-family: "SF Pro Text", sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.1px;
  
  &:hover {
    background-color: ${PinkLight};
    color: ${Black};
  }
`