import {Box, styled} from '@mui/material';
import {Black, PinkLight} from '../../../../../styles/color';

export const StyledCircle = styled('div')`
  width: 40px;
  min-width: 40px;
  height: 40px;
  // box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(249, 210, 214, 0.2);
  // background-color: ${Black};
  border-radius: 50%;
  box-sizing: border-box;

  font-size: 28px;
  line-height: 34px;
  color: ${PinkLight};
  text-align: center;
  margin-top: -8px;


  
  //&.border {
    //border: 2px solid ${PinkLight};

    //&--white {
    //  border-color: #FFFFFF;
    //}
  //}
`;

export const StyledBox = styled(Box)`
  border-radius: 12px;
  border: 1px solid #F9D2D6;
  width: 100%;
  max-width: 800px;
  padding: 10px 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
  box-sizing: border-box;
  
  &.fill {
    color: #2F2F34;
    background: #F9D2D6;
  }
`
