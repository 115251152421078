import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A,H1} from './ui';
import {PinkLight} from 'styles/color';

export const KlomonormLinkFour = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>Results of a long-term study in hormone replacement therapy with Klimonorm®</H1>
      <P>The effect of the sequential combined estrogen-progestogen drug Klimonorm® on climacteric complaints and lipid metabolism was studied in 123 menopausal women (mean age 51 years). The duration of therapy was 36 months. The following parameters were investigated: Kuppermann index, endometrial thickness, body mass index (BMI), arterial blood pressure, serum total cholesterol, low density lipoprotein (LDL)-cholesterol, high density lipoprotein (HDL)-cholesterol, triglycerides, glucose and liver enzymes. The results showed a significant reduction of the climacteric complaints of the women after treatment. After 9 months of therapy, the menopausal symptoms almost disappeared. Endometrial thickness increased only slightly, with significance only after 36 months. Hormone replacement therapy (HRT) with Klimonorm® led to a slight but significant decrease in systolic blood pressure, while diastolic blood pressure was unchanged. The body mass index showed a significant decrease during Klimonorm® administration, most pronounced at the end of the treatment. Klimonorm® favorably affected the lipia profile. Total cholesterol and LDL-cholesterol decreased significantly during the therapy, and HDL-cholesterol levels increased significantly. The changes were most pronounced after 3 years of treatment. Triglycerides steadily decreased during the therapy, as did serum glucose in the last stage of the study. The serum concentrations of γ-glutamylaminotransferase (GMT) significantly decreased after 18 months of treatment, while alanine aminotransferase (ALT) and aspartate aminotransferase (AST) were not influenced by the HRT.</P>
      <P>
        [ссылка: <A href="https://www.researchgate.net/publication/286332146_Results_of_a_long-term_study_in_hormone_replacement_therapy_with_KlimonormR">M.Burdova et al. Results of a long-term study in hormone replacement therapy with Klimonorm. Drugs of Today 2001, 37 (Suppl.C): 23-29</A>]
      </P>

      <H1 sx={{color: PinkLight, mt: 6, mb: 0}}>Georgiev D et al. Methods Find Exp Clin Pharmacol. 2001 May;23(4):197-202.</H1>
      <P>
        [ссылка: Georgiev D et al. Methods Find Exp Clin Pharmacol. 2001 May;23(4):197-202.]
      </P>

      <H1 sx={{color: PinkLight, mt: 6, mb: 0}}>Postmarketing surveillance studies of Klimonorm® in Central and Eastern Europe</H1>
      <P>The oral hormone replacement preparation Klimonorm® contains 2 mg estradiol valerate and 0.150 mg levonorgestrel. The estrogen component is given daily for 21 days and levonorgestrel is administered during the last 12 days of the treatment cycle. Postmarketing surveillance studies on Klimonorm® were performed in Germany, Poland, Bulgaria, the Czech Republic and Slovakia. A multinational trial was also carried out immediately after its launch. In these studies 10,459 women with a total of 59,275 dosing cycles were documented. The positive experience of the patients in regard to efficacy and tolerability seems to be the main reason for the very high compliance observed in the individual studies. No clinically significant differences between study participants from individual Central and East European countries were found.</P>
      <P>
        [ссылка: <A href="https://www.researchgate.net/publication/287165620_Postmarketing_surveillance_studies_of_KlimonormR_in_Central_and_Eastern_Europe">Т. Zimmermann, et al. Postmarketing surveillance studies of Klimonorm. Drugs of Today 2001, 37 (Suppl.C): 19-22.</A>]
      </P>

      <H1 sx={{color: PinkLight, mt: 6, mb: 0}}>Rudolph I, et al. Methods Find Exp Clin Pharmacol 2000, 22(1): 51-56. </H1>
      <P>[ссылка: Rudolph I, et al. Methods Find Exp Clin Pharmacol 2000, 22(1): 51-56.]</P>

      <H1 sx={{color: PinkLight, mt: 6}}>Zivny J, et al. Drugs of Today 2001, 37 (Suppl.C): 37-44. </H1>
      <P>[ссылка: Zivny J, et al. Drugs of Today 2001, 37 (Suppl.C): 37-44. ]</P>

      <P sx={{mt: 6}}>
        <A href="/product/klomonorm/instruction" sx={{fontSize: 36}}>Инструкция по медицинскому применению препарата Климонорм.</A>
      </P>
    </Page>
  )
}
