import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const VelledienLinkSeven = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Effect of hormone replacement therapy on cardiovascular events in recently postmenopausal women: randomised trial</H1>
      <P><b>Objective:</b> To investigate the long term effect of hormone replacement therapy on cardiovascular outcomes in recently postmenopausal women.
      </P>
      <P><b>Design:</b> Open label, randomised controlled trial.</P>
      <P><b>Setting: Denmark, 1990-93.</b></P>
      <P><b>Participants:</b> 1006 healthy women aged 45-58 who were recently postmenopausal or had perimenopausal symptoms in combination with recorded postmenopausal serum follicle stimulating hormone values. 502 women were randomly allocated to receive hormone replacement therapy and 504 to receive no treatment (control). Women who had undergone hysterectomy were included if they were aged 45-52 and had recorded values for postmenopausal serum follicle stimulating hormone.</P>
      <P><b>Interventions:</b> In the treatment group, women with an intact uterus were treated with triphasic estradiol and norethisterone acetate and women who had undergone hysterectomy received 2 mg estradiol a day. Intervention was stopped after about 11 years owing to adverse reports from other trials, but participants were followed for death, cardiovascular disease, and cancer for up to 16 years. Sensitivity analyses were carried out on women who took more than 80% of the prescribed treatment for five years.</P>
      <P><b>Main outcome measure:</b> The primary endpoint was a composite of death, admission to hospital for heart failure, and myocardial infarction.</P>
      <P><b>Results:</b> At inclusion the women on average were aged 50 and had been postmenopausal for seven months. After 10 years of intervention, 16 women in the treatment group experienced the primary composite endpoint compared with 33 in the control group (hazard ratio 0.48, 95% confidence interval 0.26 to 0.87; P=0.015) and 15 died compared with 26 (0.57, 0.30 to 1.08; P=0.084). The reduction in cardiovascular events was not associated with an increase in any cancer (36 in treated group v 39 in control group, 0.92, 0.58 to 1.45; P=0.71) or in breast cancer (10 in treated group v 17 in control group, 0.58, 0.27 to 1.27; P=0.17). The hazard ratio for deep vein thrombosis (2 in treated group v 1 in control group) was 2.01 (0.18 to 22.16) and for stroke (11 in treated group v 14 in control group) was 0.77 (0.35 to 1.70). After 16 years the reduction in the primary composite outcome was still present and not associated with an increase in any cancer.
      </P>
      <P><b>Conclusions:</b> After 10 years of randomised treatment, women receiving hormone replacement therapy early after menopause had a significantly reduced risk of mortality, heart failure, or myocardial infarction, without any apparent increase in risk of cancer, venous thromboembolism, or stroke.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/23048011/">Schierbeck LL, Rejnmark L, Tofteng CL, Stilgren L, Eiken P, Mosekilde L, Kober L, Jensen JE (2012) Effect of hormone replacement therapy on cardiovascular events in recently postmenopausal women: randomised trial. BMJ 345:e6409</A>]
      </P>
    </Page>
  )
}
