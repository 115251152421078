import {styled} from '@mui/system';

export const Sup = styled('sup')({
  color: '#0076BD',
  fontWeight: 'bold',
  fontSize: '0.688em',
  left: '2px',
  verticalAlign: 'top',
  position: 'relative',
  top: '-0.4em',
})