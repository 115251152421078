import {styled} from '@mui/system';
import {Box} from '@mui/material';
import {Black, PinkLight} from '../../styles/color';
import approve from '../velledien/img/approve.png';

export const StyledBoxShadow = styled(Box)({
  width: '100%',
  fontFamily:  'SF Pro Text',
  color: '#FFFFFF',
  backgroundColor: Black,
  boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(249, 210, 214, 0.2)',
  borderRadius: 12,
  padding: 20,
  boxSizing: 'border-box',
})

export const StyledBoxBorder = styled(Box)`
  border: 1px solid ${PinkLight};
  box-sizing: border-box;
  padding: 20px;
  border-radius: 12px;
  
  & .rubric {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }
`;

export const H2 = styled('h2')({
  fontSize: 24,
  fontFamily: 'SF-Pro-Text-Bold',
  color: '#F9D2D6',
  marginBottom: 10,
  fontWeight: 400,

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const H1 = styled('h1')({
  fontSize: 30,
  fontFamily: 'SF-Pro-Text-Bold',
  lineHeight: '43px',
  letterSpacing: '0.1px',
  color: '#F9D2D6',
  fontWeight: 400,

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const Paragraph = styled('p')({
  fontSize: 18,
  fontFamily: 'SF-Pro-Text-Regular',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {}
})
export const Div = styled('div')({})

export const Span = styled('span')({
  wordBreak: 'normal'
})

export const Sup = styled('sup')({
  // color: '#0076BD',
  // fontWeight: 'bold',
  fontSize: '0.688em',
  left: '2px',
  verticalAlign: 'top',
  position: 'relative',
  top: '-0.2em',
})

export const Block = styled('div')`
  padding: 12px 24px;
  border: 1px solid #FFFFFF;
  border-radius: 12px;
`;

export const UL = styled('ul')({
  paddingLeft: 28,

  '.prefix': {
    display: 'flex',

    '&::before': {
      content: `url(${approve})`,
      marginLeft: -30,
      marginRight: 20,
      marginTop: 6,
    }
  },

  '.marker-black': {
    '::marker': {
      color: Black,
    },
  },

  '.marker-pink': {
    '::marker': {
      color: PinkLight,
    },
  }
})

/*
export const LI = styled('li')<{ color?: string }>`
  &::marker: {
    color: ${({color}) => color || '#FFF'};
  }
`
* */

export const LI = styled('li')({
  '::marker': {
    color: '#F9D2D6',
  },
})

export const LIB = styled('li')({
  '::marker': {
    color: '#2F2F34',
  },
})

export const LIW = styled('li')({
  '::marker': {
    color: '#FFFFFF',
  },
})

export const PopupItemWrap = styled('span')`
 font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  background: #F9D2D6;
  border-radius: 12px;
  padding: 10px 15px;
  margin-bottom: 10px;
  color: #2F2F34;
  cursor: pointer;
`

export const StyledCircle = styled('div')`
  width: 40px;
  min-width: 40px;
  height: 40px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(249, 210, 214, 0.2);
  background-color: ${Black};
  border-radius: 50%;
  box-sizing: border-box;
  
  font-size: 28px;
  line-height: 34px;
  color: ${PinkLight};
  text-align: center;
  border: 3px solid ${PinkLight};
`;

export const Content = styled(Box)`
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & * {
    letter-spacing: 0.1px;
  }
  
  & .head {
    font-size: 30px;
    line-height: 35px;
    font-weight: 600;
    color: #F9D2D6;
    letter-spacing: 0.1px;
  }

  & .sub-head {
    font-size: 20px;
    line-height: 24px;
    color: #F9D2D6;
  }
  
  & .pink {
    color: #F9D2D6;
  }

  & .badge {
    color: #F9D2D6;
    font-size: 16px;
    line-height: 19px;
    padding: 5px 15px;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    width: fit-content;
  }

  & .border-top {
    border-top: 1px solid #F9D2D6;
  }

  & .border-bottom {
    border-bottom: 1px solid #F9D2D6;
  }
  
  & .border {
    border: 1px solid #F9D2D6;
    border-radius: 12px;
    padding: 20px;
  }
  
  & .bg-pink20 {
    background-color: rgba(249, 210, 214, .2)
  }
  
  img {
    max-width: 100%;
  }
`
