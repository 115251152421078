import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanThirteenLinkEight = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>Причины бесплодия при эндометриозе: версии и контраверсии XXI в.</H1>
      <P>В данной статье обсуждаются причины и механизмы возникновения бесплодия при эндометриозе в рамках версий и
        контраверсий. Выделены 4 основные фактора эндометриоз-ассоциированного бес плодия: трубно-перитонеальный,
        овариальный, эмбриональный и эндометриальный. Учитывая противо речивость научных данных, отмечена необходимость
        дальнейших исследований.
      </P>
      <P>
        [ссылка: <A
        href="https://cyberleninka.ru/article/n/prichiny-besplodiya-pri-endometrioze-versii-i-kontraversii-xxi-v">Бесплодие
        и эндометриоз. Версии и контраверсии / Под ред. В. Е. Радзинского, М. Р. Оразова. — М.: Редакция журнала
        StatusPraesens, 2019.</A>]
      </P>
    </Page>
  )
}
