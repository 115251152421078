import {styled} from '@mui/system';
import {WrapperShellBox} from 'components/wrapper-shell';
import mark from './img/compare-mark.png';
import {PinkLight} from 'styles/color';
import { Div } from 'shared/ui';

export const LinkA = styled('a')({
  display: 'flex',
  color: '#0076BD',
  marginTop: '10px',
  letterSpacing: '0.1px',
  fontSize: 12,
  fontFamily: 'SF-Pro-Text-Semibold',
  textAlign: 'left'
})

export const Notice = styled(WrapperShellBox)({
  display: 'flex',
  justifyContent: 'flex-start',
  boxShadow: 'none',
  border: `1px solid ${PinkLight}`,

  '&::before': {
    content: `url(${mark})`,
    marginLeft: -5,
    marginRight: 12,
    marginTop: 0,
  }
})

export const MiniBox = styled(Div)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 64,
  height: 64,
  boxSizing: 'border-box',
  boxShadow: 'none',
  border: `1px solid ${PinkLight}`,
  borderRadius: '10px',
})

export const AnchorLink = styled('a')({
  textDecoration: 'none',
  color: 'inherit',
  cursor: 'pointer',
})