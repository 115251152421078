import {TabsItem} from './tabs-item';

import {StyledTabs} from './ui';
import {TABS} from '../constants';

export const Tabs = ({...props}: any) => {
  return (
    <StyledTabs {...props}>
      <TabsItem link={TABS.TARGET}>Цели работы</TabsItem>
      <TabsItem link={TABS.MEDICAMENTS}>Препараты и показания к применению</TabsItem>
      <TabsItem link={TABS.POSITIONING}>Позиционирование</TabsItem>
      <TabsItem link={TABS.COMPETITORS}>Конкуренты</TabsItem>
      <TabsItem link={TABS.MARKET}>Рынок половых стероидов в России</TabsItem>
      <TabsItem link={TABS.TO_DOCTOR}>Визит к врачу</TabsItem>
      <TabsItem link="/effective-sales-scenarios/klimonorm" fullLink={true}>Сценарии визитов</TabsItem>
      <TabsItem link="/effective-sales/visits-april-july-proginova" fullLink={true}>Визиты: Апрель-Июль</TabsItem>
    </StyledTabs>
  )
}
