import {Box} from '@mui/material';

import {StyledBoxBorder, StyledBoxShadow, StyledCircle, StyledHeader} from '../../ui';
import {StyledWrapperBox} from './ui';
import images from '../../img';

export const EffectiveSalesTarget = () => {
  return (
    <StyledBoxShadow>
      <StyledHeader>Цели работы</StyledHeader>
      <StyledWrapperBox>
        <Box sx={{display: 'flex', gap: '20px'}}>
          <Box className="container">
            <StyledBoxBorder sx={{textAlign: 'center', height: 140}}>
              <img src={images.klimonorm} alt="Климонорм"/>
            </StyledBoxBorder>
            <StyledBoxBorder sx={{flex: 'auto'}}>
              <StyledCircle className="border" sx={{mb: 2}}>1</StyledCircle>
              <Box className="rubric">
                Добиться назначения каждым гинекологом базы
                <strong> 40 упаковок КЛИМОНОРМА</strong> в месяц пациенткам
                в пременопаузе, ранее получавшим Фемостон.
              </Box>
            </StyledBoxBorder>
          </Box>

          <Box className="container">
            <StyledBoxBorder sx={{textAlign: 'center', height: 140}}>
              <img src={images.proginova} alt="Прогинова"/>
            </StyledBoxBorder>
            <StyledBoxBorder sx={{flex: 'auto'}}>
              <StyledCircle className="border" sx={{mb: 2}}>2</StyledCircle>
              <Box className="rubric">
                Добиться назначений только <strong>ПРОГИНОВЫ </strong>
                в программах ВРТ и в смежных нишах (см. терапевтические ниши для ПРОГИНОВЫ).
              </Box>
            </StyledBoxBorder>
          </Box>

          <Box className="container">
            <StyledBoxBorder sx={{textAlign: 'center', height: 140}}>
              <img src={images.microgynom} alt="Микрогином"/>
            </StyledBoxBorder>
            <StyledBoxBorder sx={{flex: 'auto'}}>
              <StyledCircle className="border" sx={{mb: 2}}>3</StyledCircle>
              <Box className="rubric">
                Добиться назначений <strong>20 упаковок</strong> в месяц
                <strong> МИКРОГИНОНА</strong> каждым гинекологом определенной
                группе пациенток (молодые пациентки с обильными
                менструациями, которым нужна контрацепция; пациентки
                с дисфункцией яичников).
              </Box>
            </StyledBoxBorder>
          </Box>

          <Box className="container">
            <StyledBoxBorder sx={{textAlign: 'center', height: 140}}>
              <img src={images.alvovizan} alt="Алвовизан"/>
            </StyledBoxBorder>
            <StyledBoxBorder sx={{flex: 'auto'}}>
              <StyledCircle className="border" sx={{mb: 2}}>4</StyledCircle>
              <Box className="rubric">
                Добиться назначений <strong>10 упаковок</strong> в месяц
                <strong> АЛВОВИЗАНА</strong> каждым гинекологом, который ведет
                пациенток с эндометриозом.
              </Box>
            </StyledBoxBorder>
          </Box>
        </Box>

        <Box sx={{display: 'flex', gap: '20px', mt: 5}}>
          <Box className="container">
            <StyledBoxBorder sx={{textAlign: 'center', height: 140}}>
              <img src={images.zoely} alt="Зоэли"/>
            </StyledBoxBorder>
            <StyledBoxBorder sx={{flex: 'auto'}}>
              <StyledCircle className="border" sx={{mb: 2}}>5</StyledCircle>
              <Box className="rubric">
                Добиться назначений <strong>10 упаковок</strong> в месяц <strong>ЗОЭЛИ </strong>
                каждым гинекологом всем женщинам, которым требуется
                максимально физиологичная надежная и максимально
                качественная контрацепция.
              </Box>
            </StyledBoxBorder>
          </Box>

          <Box className="container">
            <StyledBoxBorder sx={{textAlign: 'center', height: 140}}>
              <img src={images.vidora} alt="Видора"/>
            </StyledBoxBorder>
            <StyledBoxBorder sx={{flex: 'auto'}}>
              <StyledCircle className="border" sx={{mb: 2}}>6</StyledCircle>
              <Box className="rubric">
                Добиться назначений <strong>10 упаковок</strong> в месяц <strong>ВИДОРЫ </strong>
                и ВИДОРЫ Микро каждым гинекологом пациенткам,
                которые используют в качестве контрацепции препараты Байер (Ярина, Джес), но хотят получать те же
                эффекты
                по более доступной цене.
              </Box>
            </StyledBoxBorder>
          </Box>

          <Box className="container">
            <StyledBoxBorder sx={{textAlign: 'center', height: 140}}>
              <img src={images.diecyclen} alt="Диециклин"/>
            </StyledBoxBorder>
            <StyledBoxBorder sx={{flex: 'auto'}}>
              <StyledCircle className="border" sx={{mb: 2}}>7</StyledCircle>
              <Box className="rubric">
                Добиться назначений <strong>10 упаковок</strong> в месяц
                ДИЕЦИКЛЕНА каждым гинекологом пациенткам,
                которые используют в качестве контрацепции препарат
                Байер (Жанин), но хотят получать те же эффекты по более
                доступной цене.
              </Box>
            </StyledBoxBorder>
          </Box>

          <Box className="container">
            <StyledBoxBorder sx={{textAlign: 'center', height: 140}}>
              <img src={images.velledien} alt="Велледиен"/>
            </StyledBoxBorder>
            <StyledBoxBorder sx={{flex: 'auto'}}>
              <StyledCircle className="border" sx={{mb: 2}}>8</StyledCircle>
              <Box className="rubric">
                Добиться назначений <strong>5 упаковок</strong> в месяц <strong>ВЕЛЛЕДИЕНА </strong>
                каждым гинекологом всем пациенткам, которые хотят
                получать эффективную МГТ в постменопаузе без риска
                для молочной железы.
              </Box>
            </StyledBoxBorder>
          </Box>
        </Box>

        <StyledBoxBorder sx={{display: 'flex', gap: '20px', mt: '40px', mb: '20px'}}>
          <StyledCircle className="border">9</StyledCircle>
          <Box className="rubric" sx={{maxWidth: 900}}>
            Обеспечить неснижаемый остаток КЛИМОНОРМ®,
            ПРОГИНОВА®, МИКРОГИНОН®, АЛВОВИЗАН,
            ЗОЭЛИ, ВИДОРА и ВИДОРА Микро, ДИЕЦИКЛЕН,
            ВЕЛЛЕДИЕН <strong>5 упаковок в 20 аптеках города.</strong>
          </Box>
        </StyledBoxBorder>
      </StyledWrapperBox>
    </StyledBoxShadow>
  )
}
