import {Link, useLocation} from 'react-router-dom';
import {StyledTab} from './ui';

type Props = {
  children: React.ReactNode;
  link: string;
}

export const TabsItem = ({children, link}: Props) => {
  const {pathname} = useLocation();
  const cls = `${pathname.split('/')[2]}` === link ? 'active' : '';

  return (
    <Link to={`/effective-sales-doctor/${link}`}>
      <StyledTab className={cls}>
        {children}
      </StyledTab>
    </Link>
  )
}
