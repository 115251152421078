import {styled} from '@mui/system';

import {Black, PinkLight} from '../../../../../styles/color';

export const StyledLink = styled('a')`
  padding: 10px 30px;
  border: 1px solid #FFFFFF;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  line-height: 18px;
  font-size: 16px;
  font-family: "SF Pro Text", sans-serif;
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.1px;
  background-color: ${PinkLight};
  color: ${Black};
  display: block;
  text-align: center;

  &:hover {
    background-color: ${Black};
    color: #FFFFFF;
  }
`