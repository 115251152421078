import Page from 'shared/ui/Page';
import {H1} from '../ui';

import {StyledParagraph as P} from './ui';
import pdf from '../../../pdfs/proginova/ProblemyReproduktsii_2015_04_048.pdf';
import {PinkLight} from '../../../styles/color';

export const ProginovaLinkNine = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>А.А. Смирнова. Применение эстрогенов в программах ВРТ (обзор литературы). Проблемы репродукции, 4, 2015. </H1>
      <P><b>Центр репродукции и генетики «ФертиМед» 8/9, Москва, Россия, 105043; Кафедра эндокринологии факультета
        повышения квалификации медицинских работников РУДН, Москва, Россия, 117198</b></P>
      <P>Представлен обзор мировой литературы, касающейся применения препаратов эстрадиола в циклах ВРТ для подготовки и
        во время беременности. Обсуждается безопасность высоких концентраций эстрадиола в циклах стимуляции для ЭКО,
        необходимость дополнительного назначения эстрогенов в лютеиновую фазу цикла, эффективность подготовки эндометрия
        переносу размороженных эмбрионов, целесообразность экзогенного введения эстрогенов при тонком эндометрии,
        эстрогенный прайминг у пациенток с недостаточным ответом яичников на стимуляцию.</P>
      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Статья PDF
      </a>
    </Page>
  )
}
