import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A, StyledH1 as H1} from './ui';

export const ProginovaSecondLinkFour = () => {
  return (
    <Page isCross>
      <H1>Hormone replacement therapy and cancer risk: a systematic analysis from a network of case-control studies</H1>
      <P>
        To provide comprehensive and quantitative information on the benefits and risks of hormone replacement therapy (HRT) on several cancer sites, we systematically examined the relation between HRT use and the risk of various cancers in women aged 45-79 by using data from a framework of case-control studies conducted in Italy between 1983 and 1999. The overall data set included the following incident, histologically confirmed neoplasms: oral cavity, pharynx, larynx and esophagus (n = 253), stomach (n = 258), colon (n = 886), rectum (n = 488), liver (n = 105), gallbladder (n = 31), pancreas (n = 122), breast (n = 4,713), endometrium (n = 704), ovary (n = 1,614), urinary bladder (n = 106), kidney (n = 102), thyroid (n = 65), Hodgkin's disease (n = 26), non-Hodgkin's lymphomas (n = 145), multiple myeloma (n = 65) and sarcomas (n = 78). The control group comprised 6,976 women aged 45-79 years, admitted for a wide spectrum of acute, nonneoplastic conditions. Odds ratios (OR) and the corresponding 95% confidence intervals (CI) for use of HRT were derived from multiple logistic regression equations. There was an inverse association between ever use of HRT and colon (OR = 0.7), rectum (OR = 0.5) and liver cancer (OR = 0.2), with a consistent pattern of protection for duration of use. An excess risk was found for gallbladder (OR = 3.2), breast (OR = 1.1), endometrial (OR = 3.0) and urinary bladder cancer (OR = 2.0). These data from a southern European population add some useful information on the risk-benefit assessment of HRT among postmenopausal women.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/12704678/">Int J Cancer. 2016; 138(9): 2146–53.</A>]
      </P>
    </Page>
  )
}
