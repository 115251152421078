import Page from 'shared/ui/Page';

import {Paragraph as P, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanSecondLinkEleven = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Dept of Ob-Gyn, University Hospital, Angers, France Ph. Descamps
      </H1>
      <P>
        [ссылка: Dept of Ob-Gyn, University Hospital, Angers, France Ph. Descamps]
      </P>
    </Page>
  )
}
