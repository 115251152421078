import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const Duo = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>
        Tibolone versus conjugated estrogens and sequential progestogen in the treatment of climacteric complaints
      </H1>
      <P>
        Objective: Tibolone has been shown to alleviate climacteric symptoms. This study was designed to compare the
        effect of tibolone (Livial, 2.5 mg daily) on different climacteric complaints and its impact on the endometrium,
        determined by vaginal ultrasound, with that of conjugated estrogens (Premarin, 0.625 mg daily) continuously for
        6 months in combination with the progestogen medrogestone (Colpron, 2 x 5 mg daily for 12 days each month).
      </P>
      <P>
        Methods: One hundred and twenty-nine postmenopausal women were recruited and the severity of climacteric
        symptoms as well as endometrial thickness were recorded at the pre-trial examination and after 1, 3, and 6
        months.
      </P>
      <P>
        Results: With the exception of vertigo, mood depression, mood disorder, loss of libido, and dryness of skin,
        where tibolone was found to be more effective than conjugated estrogens/medrogestone, climacteric symptoms
        improved significantly in both groups over the 6-month study period. Endometrial thickness did not increase
        significantly in the tibolone group, whereas in the conjugated estrogens/medrogestone group there was a highly
        significant increase after 1 month and still a trend towards significance after 6 months. Recurrence of vaginal
        bleeding occurred significantly less frequently in the tibolone group than in the comparison group.
      </P>
      <P>
        Conclusion: Tibolone seems to offer a complete treatment of the climacteric complaints whilst avoiding some of
        the problems associated with classical hormone replacement therapy.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/8861087/">https://pubmed.ncbi.nlm.nih.gov/8861087/</A>]
      </P>
    </Page>
  )
}
