import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanLinkTwo = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Endometriosis: diagnosis and management</H1>
      <P>
        This guideline makes recommendations for the diagnosis and management of endometriosis in community services,
        gynaecology services and specialist endometriosis services (endometriosis centres).
      </P>

      <P>The guideline also covers the care of women with confirmed or suspected endometriosis, including recurrent
        endometriosis. It includes women who do not have symptoms but have endometriosis discovered incidentally.
        Special consideration was given to young women (aged 17 and under). The guideline does not cover the
        investigation of fertility problems related to endometriosis, care of women with endometriosis occurring outside
        the pelvis, nor postmenopausal women.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/29787038/">Endometriosis: diagnosis and Management NICE
        guideline Published: 6 September 2017.</A>]
      </P>
    </Page>
  )
}
