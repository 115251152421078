import {Box} from '@mui/material';

import {StyledHeader} from '../ui';
import {StyledBoxShadow} from '../../ui';
import {Message} from './message';

export const SubpageMicroginon = () => {
  return (
    <>
      <StyledHeader>Микрогинон®</StyledHeader>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день! Медицинский представитель компании Зентива (имя, фамилия). Уделите мне,
            пожалуйста, несколько минут.
          </Message>
          <Message isQuestion={false}>
            Здравствуйте! Проходите, но у меня мало времени.
          </Message>

          <Message>
            Спасибо. Ольга Ивановна, как часто к Вам обращаются здоровые женщины за назначением гормональной
            контрацепции?
          </Message>
          <Message isQuestion={false}>
            2-3 раза в неделю вижу таких пациенток.
          </Message>

          <Message>
            Ольга Ивановна, насколько для Вас важно, чтобы препарат не только защищал от нежелательной беременности, но
            и обладал дополнительными лечебными эффектами, а также улучшал качество жизни пациентки (улучшал настроение,
            повышал либидо)?
          </Message>
          <Message isQuestion={false}>
            Конечно важно, мы же всегда ориентируемся на интересы наших пациенток.
          </Message>

          <Message>
            Какие препараты Вы сейчас назначаете? Есть ли в назначениях препарат нашей компании Микрогинон?
          </Message>
          <Message isQuestion={false}>
            Да, Микрогинон раньше назначала, сейчас подзабыла. Много новых КОКов появилось, с дроспиреноном препараты
            назначаю - постоянно что то новое приносят ваши коллеги.
          </Message>

          <Message>
            Ольга Ивановна, вы молодец, что отдаете предпочтение гормональной контрацепции, но все же хочу сказать, что
            Микрогинон забыт Вами незаслуженно — ведь это самый изученный и назначаемый КОК в мире, а комбинация
            этинилэстрадиола и левоноргестрела является «золотым стандартом» сравнения для всех КОК.
          </Message>
          <Message isQuestion={false}>
            Да, раньше любила его. Напомните, пожалуйста, кому его лучше назначить.
          </Message>

          <Message>
            Ольга Ивановна, во первых это препарат первого выбора для здоровых женщин, а так же для женщин с
            неизвестными тромботическими рисками, тк ЛНГ обладает самым низким риском венозных тромбозов по сравнению с
            другими гестагенами.
            <Box sx={{mt: '12px', mb: '12px'}}>
              Во вторых, это препарат для женщин с обильными менструациями, с АМК, гиперплазией эндометрия — это опять
              же
              обусловлено сильными гестагенными свойствами ЛНГ, и способствует снижению риска рака эндометрия в 2 раза!
            </Box>
            Ну и Вы наверное знаете, что ЛНГ обладает еще и остаточным андрогенным эффектом?
          </Message>
          <Message isQuestion={false}>
            Конечно.
          </Message>

          <Message>
            Так вот благодаря этому эффекту Вы можете назначать его женщинам, у которых есть такие побочные эффекты, как
            снижение либидо, депрессия, вялость. Так же астеничным пациенткам, ведь ЛНГ сохраняет мышечную ткань.
            Доктор, что Вы на это скажете? Вернете Микрогинон в свою практику?
          </Message>
          <Message isQuestion={false}>
            Действительно, все эти эффекты очень важны для женщины, и многие мои пациентки на других гестагенах жалуются
            на снижение либидо, АМК- поэтому обязательно возобновлю назначения Микрогинона.
          </Message>

          <Message>
            Я рада, что смогла убедить Вас. Скольким пациенткам получится назначить Микрогинон на этой неделе?
          </Message>
          <Message isQuestion={false}>
            Я думаю, что 2-3 пациентки будет.
          </Message>
          <Message>
            Отлично! Я приду к Вам через месяц, обсудим назначения. До свидания!
          </Message>
        </Box>
      </StyledBoxShadow>
    </>
  )
}
