import alvovizan from './alvovizan.png';
import diecyclen from './diecyclen.png';
import klimonorm from './klimonorm.png';
import microgynom from './microgynom.png';
import proginova from './proginova.png';
import velledien from './velledien.png';
import vidora from './vidora.png';
import zoely from './zoely.png';
import vidora_24_4 from './vidora_24_4.png';
import vidora_21_7 from './vidora_21_7.png';

import drug from './icons/drug.png';
import file from './icons/file.png';
import star from './icons/star.png';
import person from './icons/person.png';
import plus from './icons/plus.png';
import doctor from './icons/doctor.png';
import arrow from './icons/arrow.png';
import chat from './icons/chat.png';
import edit from './icons/edit.png';
import people from './icons/people.png';
import search from './icons/search.png';
import target from './icons/target.png';
import voice from './icons/voice.png';
import file_ok from './icons/file-ok.png'
import file_search from './icons/file-search.png'
import hand from './icons/hand.png'
import hands from './icons/hands.png'

import p_diecyclen from './portraits/p-diecyclen.png'
import p_alvovizan from './portraits/p-alvovizan.png'
import p_klimonorm from './portraits/p-klimonorm.png'
import p_micogynom from './portraits/p-micogynom.png'
import p_velledien from './portraits/p-velledien.png'
import p_vidora from './portraits/p-vidora.png'
import p_zoely from './portraits/p-zoely.png'
import p_proginova from './portraits/p-proginova.png'
import arina from './arina.png'
import chanin from './chanin.png'
import dches from './dches.png'
import dlachens_klimo from './dlachens-klimo.png'
import dlachens_metri from './dlachens-metri.png'
import esteretta from './esteretta.png'
import femoston_1 from './femoston-1.png'
import femoston_2 from './femoston-2.png'
import klaira from './klaira.png'
import livial from './livial.png'
import planichens from './planichens.png'
import planichens_drospy_30 from './planichens-drospy-30.png'
import planiches_die from './planiches-die.png'
import planiches_dropsy_20 from './planiches-dropsy-20.png'
import rigevidon from './rigevidon.png'
import vizanna from './vizanna.png'
import zafrilla from './zafrilla.png'

export default {
  alvovizan,
  diecyclen,
  klimonorm,
  microgynom,
  proginova,
  velledien,
  vidora,
  zoely,
  vidora_24_4,
  vidora_21_7,
  arina,
  chanin,
  dches,
  dlachens_klimo,
  dlachens_metri,
  esteretta,
  femoston_1,
  femoston_2,
  klaira,
  livial,
  planichens,
  planichens_drospy_30,
  planiches_die,
  planiches_dropsy_20,
  rigevidon,
  vizanna,
  zafrilla,
}

export const icons = {
  drug,
  file,
  star,
  person,
  plus,
  doctor,
  arrow,
  chat,
  edit,
  people,
  search,
  target,
  voice,file_ok,
  file_search,
  hand,
  hands,
}

export const portraits = {
  p_diecyclen,
  p_alvovizan,
  p_klimonorm,
  p_micogynom,
  p_velledien,
  p_vidora,
  p_zoely,
  p_proginova,
}