import {styled} from '@mui/system';
import {Box} from '@mui/material';
import {Black, PinkLight} from '../../../styles/color';
import approve from '../../velledien/img/approve.png';

export const StyledBoxShadow = styled(Box)`
  width: 100%;
  font-family:  SF Pro Text;
  color: #FFFFFF;
  background-color: #030360;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(0, 176, 240, 0.2);
  border-radius: 12px;
  padding: 40px 20px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 21px;

  & .title {
    font-size: 30px;
    line-height: 35px;
    font-weight: 600;
    letter-spacing: 0.1px;
  }
  
   & .border {
    border: 1px solid #00B0F0;
    border-radius: 12px;
    padding: 20px;
  }
  
    & .blue {
    color: #00B0F0;
  }
`

export const StyledCircle = styled('div')`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  
  font-size: 28px;
  line-height: 34px;
  color: ${PinkLight};
  text-align: center;
  margin-top: -6px; 
`;

export const UL = styled('ul')({
  paddingLeft: 28,

  '.prefix': {
    display: 'flex',

    '&::before': {
      content: `url(${approve})`,
      marginLeft: -30,
      marginRight: 20,
      marginTop: 6,
    }
  },

  '.marker-black': {
    '::marker': {
      color: Black,
    },
  },

  '.marker-pink': {
    '::marker': {
      color: PinkLight,
    },
  }
})

export const LI = styled('li')({
  '::marker': {
    color: '#FFF',
  },
})

export const StyledCircleBorder = styled('div')`
  width: 40px;
  min-width: 40px;
  height: 40px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(249, 210, 214, 0.2);
  background-color: #030360;
  border-radius: 50%;
  box-sizing: border-box;
  
  padding-top: 3px;
  font-size: 28px;
  line-height: 34px;
  color: #FFF;
  text-align: center;
  border-color: #00B0F0;
`;
