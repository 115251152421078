import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanLinkOne = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Why are there delays in the diagnosis of endometriosis?</H1>
      <P sx={{fontSize: 28}}>As leaders in women’s health care, we can do much more to improve</P>
      <P>Endometriosis is a common gynecologic problem in adolescents and women. It often presents with pelvic pain, an ovarian endometrioma, and/or subfertility. In a prospective study of 116,678 nurses, the incidence of a new surgical diagnosis of endometriosis was greatest among women aged 25 to 29 years and lowest among women older than age 44.1 Using the incidence data from this study, the calculated prevalence of endometriosis in this large cohort of women of reproductive age was approximately 8%...</P>
      <P>
        [ссылка: <A href="https://www.mdedge.com/obgyn/article/132337/gynecology/why-are-there-delays-diagnosis-endometriosis">
        Why are there delays in the diagnosis of endometriosis? OBG Manag. 2017 March;29(3):8, 10-1
      </A>]
      </P>
    </Page>
  )
}
