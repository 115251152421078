import {Box} from '@mui/material';
import { ReactNode } from 'react';
import {StyledCircle} from '../../../effective-sales/ui';

type Props = {
  title?: string;
  children: ReactNode;
  textTransform?: string
  img?: string;
}
export const Ticket = ({title, img, children}: Props) => {
  return (
    <Box sx={{display: 'flex', gap: '20px'}}>
      {
        img && (
          <StyledCircle sx={{padding: '5px'}}>
            <img src={img} alt="*"/>
          </StyledCircle>
        )
      }
      <Box>
        {title &&  <Box sx={{mb: 1, color: '#F9D2D6', fontSize: 24}}>{title}</Box>}
        <Box sx={{fontSize: 18}}>{children}</Box>
      </Box>
    </Box>
  )
}
