import {Box} from '@mui/material';

import {StyledAnchorLink, StyledHeader} from '../ui';
import {StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {HeaderTabs as Tabs} from './header-tabs';
import {Message} from './message';

type HeaderTabsProps = {
  tabActive: number
}
const HeaderTabs = ({tabActive}: HeaderTabsProps) => <Tabs tabActive={tabActive} tabLength={5}/>
export const SubpageKlimonorm = () => {
  return (
    <>
      <StyledHeader>Климонорм®</StyledHeader>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <StyledAnchorLink href="#theme1">
          <img src={icons.arrow} alt="*"/>
          Сценарий #1: Климонорм (при ПНЯ) vs Фемостон 2.10
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme2">
          <img src={icons.arrow} alt="*"/>
          Сценарий #2: Климонорм vs Фемостон 1.10 - v1
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme3">
          <img src={icons.arrow} alt="*"/>
          Сценарий #3: Климонорм vs Фемостон 1.10 - v2
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme4">
          <img src={icons.arrow} alt="*"/>
          Сценарий #4: Климонорм vs Фемостон 1.10 - v3
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme5">
          <img src={icons.arrow} alt="*"/>
          Сценарий #5: Климонорм vs Фемостон 2.10
        </StyledAnchorLink>
      </Box>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <HeaderTabs tabActive={0}/>
        <Box className="title" sx={{mb: 3}}>
          Климонорм (при ПНЯ) vs Фемостон 2.10
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день! Медицинский представитель компании Зентива (имя, фамилия). Уделите мне немного
            времени для обсуждения пациенток с ПНЯ.
          </Message>
          <Message isQuestion={false}>
            Да, проходите, только недолго
          </Message>

          <Message>
            Ольга Ивановна, как много у Вас пациенток с ПНЯ? Ваши коллеги жалуются, что выросло количество молодых
            пациенток с ПНЯ, а как у Вас?
          </Message>
          <Message isQuestion={false}>
            Есть такое и в моей практике, причем есть пациентки, которым ещё нет и 40 лет, но в среднем это 40-45 лет.
          </Message>

          <Message>
            Назначаете ли Вы им заместительную гормональную терапию? Что именно?
          </Message>
          <Message isQuestion={false}>
            Я рекомендую в таком случае Фемостон 2/10 и отзывы слышу, что действует хорошо. Ничего плохого в Фемостоне
            не вижу.
          </Message>

          <Message>
            Ольга Ивановна, насколько для Вас важно, чтобы пациентка с ПНЯ восполняла не только дефицит эстрогенов, но и
            дефицит андрогенов, которые сопровождают данную патологию, для того чтобы снизить риск сердечно-сосудистых
            заболеваний, остеопороза, снижение когнитивных функций, деменции и Альцгеймера?
          </Message>
          <Message isQuestion={false}>
            Да, это тоже важно, но это нагрузка для организма от такого количества гормонов.
          </Message>

          <Message>
            Понимаю, что такие опасения имеют место быть, но на основании рекомендаций IMS (Международного общества по
            менопаузе) 2016 года именно применение эстроген-прогестагенных препаратов, которое должно продолжаться до
            середины среднего возраста естественной менопаузы, является основой лечения ПНЯ. Если не лечить ПНЯ
            повышается риск ССЗ, остеопороза, снижения когнитивных функций, деменции и Альцгеймера, таким образом
            препарат выбора является Климонорм за счет остаточной андрогенности ЛНГ и его нельзя заменить в данной
            клинической ситуации. Только у Климонорма есть в инструкции ПНЯ. Климонорм в данной ситуации — препарат
            выбора.
          </Message>
          <Message isQuestion={false}>
            Да, сейчас пациенты грамотные, прежде чем пить назначенные им лекарства читают инструкцию и могут задавать
            вопросы, раз все прописано, это аргумент.
          </Message>

          <Message>
            В месяц 10 пациенток с ПНЯ у Вас наверняка будет? Назначьте им Климонорм, хорошо?
          </Message>
          <Message isQuestion={false}>
            Хорошо.
          </Message>
          <Message>
            Тогда через месяц обсудим опыт применения Климонорма у Ваших пациенток с ПНЯ.
            До встречи!
          </Message>
        </Box>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme2" sx={{mt: 5}}>
        <HeaderTabs tabActive={1}/>
        <Box className="title" sx={{mb: 3}}>
          Климонорм vs Фемостон 1.10 - v1
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день! Медицинский представитель компании Зентива (имя, фамилия). Подскажите,
            пожалуйста, как много пациенток 45+ сейчас у Вас и с какой патологией они чаще обращаются?
          </Message>
          <Message isQuestion={false}>
            По-разному, 2-3 в день бывает, чаще с АМК, приливами, депрессией.
          </Message>

          <Message>
            МГТ терапию назначаете? Какую?
          </Message>
          <Message isQuestion={false}>
            Предпочитаю Фемостон 1/10
          </Message>

          <Message>
            Здорово, что применяете МГТ. Какой эффект от терапии для Вас предпочтительней?
          </Message>
          <Message isQuestion={false}>
            Регуляция цикла, устранение жалоб на приливы, депрессии и сниженное либидо…
          </Message>

          <Message>
            Правильно я поняла, Вы хотите вернуть таким пациенткам качество жизни?
          </Message>
          <Message isQuestion={false}>
            Да, конечно. Это важно.
          </Message>

          <Message>
            Ольга Ивановна, по многочисленным исследованиям - МГТ необходимо выбирать с учетом прогестагенной активности
            в отношении эндометрия.

            <Box sx={{mt: '12px'}}>
              Климонорм, в состав которого входит сильный прогестаген левоноргестрел, позволяет эффективно купировать
              симптомы климактерического синдрома, осуществлять профилактику аномальных маточных кровотечений, не
              оказывая
              негативного воздействия на состояние эндометрия. (слайды - ведущие научные лидеры, фармакологическая
              активность гестагенов)
            </Box>
          </Message>
          <Message isQuestion={false}>
            У Климонорма нет дозировки 1 мг эстрадиола, только 2 мг!
          </Message>

          <Message>
            В состав Климонорма, действительно, входит 2 мг эстрадиола валерата, а по рекомендациям международных
            обществ по менопаузе именно 2 мг является минимальной эффективной дозировкой в периоде менопаузального
            перехода (45-лет-52 года).

            <Box sx={{mt: '12px'}}>
              Пожалуйста, назначьте уже сегодня 2-3 пациенткам с АМК, приливами, низким либидо Климонорм, пациентки
              вернутся с благодарностью.
            </Box>
          </Message>
          <Message isQuestion={false}>
            Хорошо
          </Message>

          <Message>
            Климонорм является оптимальным средством сохранения здоровья женщины в зрелом возрасте за счет преимуществ
            ЛНГ для профилактики кровотечений, остеопороза, сердечно-сосудистых заболеваний и сохранения когнитивных
            функций. Именно поэтому Климонорм рекомендован как препарат №1 для использования у пациенток в периоде
            менопаузального перехода в Протоколах Минздрава назначения МГТ 2016 года.
            <Box sx={{mt: '12px'}}>
              Ольга Ивановна, договорились с Вами о назначении Климонорма 10-15 пациенткам с АМК, приливами, низким
              либидо
              вместо Фемостона 1/10?
            </Box>
          </Message>
          <Message isQuestion={false}>
            Да… Ваши аргументы убедительны, попробую...
          </Message>

          <Message>
            До встречи через месяц, открыта для вопросов.
          </Message>
        </Box>
      </StyledBoxShadow>
      <StyledBoxShadow id="theme3" sx={{mt: 5}}>
        <HeaderTabs tabActive={2}/>
        <Box className="title" sx={{mb: 3}}>
          Климонорм vs Фемостон 1.10 - v2
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день. Медицинский представитель компании Зентива (имя, фамилия). Попрошу у Вас 5-7
            минут для обсуждения комплексной защиты пациенток в период менопаузального перехода.
          </Message>
          <Message isQuestion={false}>
            Да.
          </Message>

          <Message>
            Возможно 3-4 раза в неделю к Вам обращаются пациентки с жалобами на приливы и нарушение цикла, депрессивное
            состояние, снижение либидо?
          </Message>
          <Message isQuestion={false}>
            Да 3-4 пациентки в неделю регулярно таких бывает.
          </Message>

          <Message>
            Что Вы назначаете таким пациенткам чаще?
          </Message>
          <Message isQuestion={false}>
            Чаще всего Фемостон 1/10. А вообще у них разные дозировки, есть из чего выбрать.
          </Message>

          <Message>
            А есть ли у Вас пациентки на Фемостоне которые «кровят», жалуются на усталость, просят отменить препарат или
            спрашивают когда уже можно перестать его принимать?
          </Message>
          <Message isQuestion={false}>
            Не часто, но вижу… А что делать? Им же надо лечится!
          </Message>

          <Message>
            А всегда ли одного миллиграмма эстрогена в составе МГТ достаточно для купирования климактерических
            симптомов?
            И наверняка каждая вторая сталкивается с симптомами гиперплазии эндометрия, АМК, снижением либидо,
            астено-депрессивной симптоматикой?
          </Message>
          <Message isQuestion={false}>
            Да, таких много.
          </Message>

          <Message>
            Согласно Клиническим рекомендациям для купирования симптомов и сохранения здоровья женщины в период
            менопаузального перехода рекомендовано 2 мг эстрогенного компонента.
          </Message>
          <Message isQuestion={false}>
            Во многом согласна, но Фемостон, безопасный, мы как-то привыкли…
          </Message>

          <Message>
            Доктор, Дидрогестерон входящий в состав Фемостона может провоцировать заболевания эндометрия. Он не
            безопасный, а бесполезный! Он в 10 раз слабее собственного прогестерона. Дидрогестерона нет ни в одном
            контрацептиве! И конечно становится понятно, почему так нужен ЛНГ женщине в переходном периоде! ЛНГ самый
            изученный гестаген, с низким риском ВТЭ, синергист, который усиливает экспрессию эстрогеновых рецепторов,
            тем самым снижает интенсивность апоптоза, увеличивая выживаемость нейронов. Что при длительном применении
            снижает риск развития болезни Альцгеймера. Таким образом назначая Климонорм вместо Фемостона Вы
            способствуете сохранению когнитивных функций и профилактике болезни Альцгеймера.
          </Message>
          <Message isQuestion={false}>
            Да… Ваши аргументы убедительны.
          </Message>

          <Message>
            Доктор тогда порекомендуйте 2-3 пациенткам Климонорм вместо Фемостона 1/10?
          </Message>
          <Message isQuestion={false}>
            Договорились. Я пересмотрю свои рекомендации.
          </Message>
        </Box>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme4" sx={{mt: 5}}>
        <HeaderTabs tabActive={3}/>
        <Box className="title" sx={{mb: 3}}>
          Климонорм vs Фемостон 1.10 - v3
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день. Медицинский представитель компании Зентива (имя, фамилия). Уделите мне
            несколько минут для обсуждения пациенток в периоде менопаузального перехода?
          </Message>
          <Message isQuestion={false}>
            Да, но у меня мало времени.
          </Message>

          <Message>
            Как часто пациентки 45-52 лет приходят к Вам на прием?
          </Message>
          <Message isQuestion={false}>
            Я не считала, 3-4 в неделю бывает.
          </Message>

          <Message>
            А вы только в этом центре принимаете или где-то ещё?
          </Message>
          <Message isQuestion={false}>
            Да я ещё принимаю в Люксе по выходным.
          </Message>

          <Message>
            А там бывает 1-2 пациентки 45-52 лет на приеме?
          </Message>
          <Message isQuestion={false}>
            В день одна точно бывает.
          </Message>

          <Message>
            А что вы назначаете этим пациентам в возрасте 45+, которые жалуются на симптомы менопаузы?
          </Message>
          <Message isQuestion={false}>
            Чаще всего Фемостон, так как у него большая линейка, любой пациентке можно подобрать.
          </Message>

          <Message>
            А конкретно женщинам, у которых есть месячные, но только начинается сбой цикла?
          </Message>
          <Message isQuestion={false}>
            Я Вам говорю, Фемостон 1/10
          </Message>

          <Message>
            Ольга Ивановна, в инструкции по Фемостон 1/10 написано, его можно применять только через 6 месяцев после
            последней менструации. Но ведь не все такие. Бывают женщины у которых менструации нет всего 3-4 месяца. Ведь
            так?
          </Message>
          <Message isQuestion={false}>
            Да, конечно, есть такие. Но я считала, что Фемостон для всех.
          </Message>

          <Message>
            Давайте посмотрим с вами инструкцию.
            <Box sx={{mt: '12px'}}>
              Ольга Ивановна, сегодня хотела бы вам напомнить про препарат номер 1 для использования у пациенток в
              период
              менопаузального перехода, в протоколе Минздрава МГТ и сохранение здоровья женщин - это Климонорм
              (показываю
              скрин протокола).
            </Box>
          </Message>
          <Message isQuestion={false}>
            Что о нём говорить, его уже давно нет и, наверное, уже не будет. Я раньше его часто назначала.
          </Message>

          <Message>
            Ольга Ивановна, радуйтесь, он вернулся. Климонорм, есть во всех аптеках города. Я хочу вам напомнить про
            Климонорм, в состав которого входит 2 мг эстрадиола валерата , а по рекомендациям протокола Минздрава, 2 мг
            является минимально эффективной дозой в периоде менопаузального перехода 45-52 года. А в сочетании с
            левоноргестрелом дает женщине целый ряд преимуществ по профилактике остеопороза , сердечно-сосудистых
            заболеваний и снижению когнитивных функций, а также при снижении либидо. Все эти преимущества Климонорма
            зафиксированы в инструкции, что дает вам все основания назначить Климонорм большинству пациенток возрасте
            45-52 г.
            <Box sx={{mt: '12px;'}}>
              Вы со мной согласны?
            </Box>
          </Message>
          <Message isQuestion={false}>
            Да конечно. Рада, что Климонорм вернулся.
          </Message>

          <Message>
            Ольга Ивановна, как думаете, получится порекомендовать 5-6 пациенткам за месяц Климонорм вместо Фемостона
            1/10?
          </Message>
          <Message isQuestion={false}>
            5-6-ти — не уверена, но 2-3 м думаю получится.
          </Message>

          <Message>
            Отлично. Я приду к Вам через месяц и Вы мне расскажите о том как женщины расцвели на фоне приема Климонорм.
            Хорошо?
          </Message>
          <Message isQuestion={false}>
            Надеюсь так и будет.
          </Message>
          <Message>
            До встречи! Хорошего дня!
          </Message>
        </Box>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme5" sx={{mt: 5}}>
        <HeaderTabs tabActive={4}/>
        <Box className="title" sx={{mb: 3}}>
          Климонорм vs Фемостон 2.10
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день. Медицинский представитель компании Зентива (имя, фамилия). Я хочу с Вами
            поговорить о пациентах 45+,которые приходят с депрессией, приливами, ССЗ, когнитивными нарушениями.
            <Box sx={{mt: '12px'}}>
              Есть ли такие пациенты и если «Да», сколько в течении дня?
            </Box>
          </Message>
          <Message isQuestion={false}>
            Да, такие конечно есть 1-2 в день приходят.
          </Message>

          <Message>
            Как Вы работаете с ними?
          </Message>
          <Message isQuestion={false}>
            Сначала провожу полное обследование и если все нормально, нет противопоказаний, пациент согласен на МГТ, то
            назначаю Фемостон 2/10.
          </Message>

          <Message>
            Есть ли в Вашей практике назначения Климонорм?
          </Message>
          <Message isQuestion={false}>
            Да, раньше назначала до того, как он пропал и я всех своих пациенток перевела на Фемостон 2/10.
          </Message>

          <Message>
            А кто-нибудь из них после этого возвращался с жалобами на ухудшение либидо, когнитивной функции, набор веса?
          </Message>
          <Message isQuestion={false}>
            Да, бывало, а что делать?
          </Message>

          <Message>
            Здесь, на мой взгляд, ответ очевиден. Верните этих пациенток на Климонорм! Ведь КЛИМОНОРМ® в отличие от
            Фемостона позволяет не только купировать климактерические жалобы, но и дополнительно повысить качество
            жизни, улучшить когнитивные функции, работоспособность и сексуальное желание за счет остаточная андрогенная
            активность ЛНГ в составе.
            <Box sx={{mt: '12px', mb: '12px'}}>
              Кроме того, именно сочетание 2 мг эстрадиола валерата c ЛНГ даёт целый набор дополнительных преимуществ по
              профилактике остеопороза, сердечно-сосудистых заболеваний, последствий преждевременной недостаточности
              яичников, снижения когнитивных функций.
            </Box>
            Именно поэтому данная комбинация 2 мг эстрадиола валерата и 0,15 мг ЛНГ стоит на 1-м месте в протоколах МГТ
            для циклического приёма. Что дает Вам, доктор, все основания назначать КЛИМОНОРМ® большинству пациенток в
            возрасте 45 лет — 52 года.
          </Message>
          <Message isQuestion={false}>
            Да, думаю так я и сделаю. Переведу тех, кто пришёл с подобными жалобами после перевода на Фемостон 2/10.
          </Message>

          <Message>
            Будет ли 2 таких пациентов в неделю?
          </Message>
          <Message isQuestion={false}>
            Прогнозировать конечно сложно, но думаю да.
          </Message>

          <Message>
            Я к Вам приду через месяц и буду очень благодарна, если поделитесь кого удалось перевести с Фемостон 2/10 на Климонорм.
          </Message>
          <Message isQuestion={false}>
            Климонорм-это единственный препарат МГТ с лечебным эффектом.
            <Box sx={{mt: '12px', mb: '12px'}}>
              ЛНГ-синергист.
            </Box>
            Поэтому максимально эффективен: профилактика Альцгеймера за счёт усиления нейростероидогенеза, профилактика остеопороза и ССЗ.
          </Message>

          <Message>
            Есть ли у Вас какие-нибудь вопросы?
          </Message>
          <Message isQuestion={false}>
            Да, какая цена сейчас в аптеках?
          </Message>

          <Message>
            В ближайших аптеках 1 350 ₽
          </Message>
          <Message isQuestion={false}>
            Цена хорошая, тем более для такого препарата!
          </Message>
          <Message>
            Спасибо за уделённое Вами время! В следующий раз поделитесь пожалуйста опытом назначения Климонорм своим пациентам.
          </Message>
        </Box>
      </StyledBoxShadow>
    </>
  )
}
