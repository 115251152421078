import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanLinkSix = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>
        Nerve-sparing laparoscopic eradication of deep endometriosis with segmental rectal and parametrial resection:
        the Negrar method. A single-center, prospective, clinical trial
      </H1>
      <P>
        Background: The weight of surgical radicality, together with a lack of anatomical theoretical basis for surgery
        and inappropriate practical skills, can lead to serious impairments to bladder, rectal, and sexual functions
        after laparoscopic excision of deep infiltrating endometriosis. Although the "classical" laparoscopic technique
        for endometriosis excision involving segmental bowel resection has proven to relieve symptoms successfully, it
        is hampered by several postoperative long-term and/or definitive pelvic dysfunctions.

      </P>
      <P>
        Methods: In this prospective cohort study, we compare the laparoscopic nerve-sparing approach to the classical
        laparoscopic procedure in a series of 126 cases. Satisfactory data for bowel, bladder, and sexual function were
        considered as primary endpoints.

      </P>
      <P>
        Results: A total of 126 patients were considered for analysis: 61 treated with nerve-sparing radical excision of
        pelvic endometriosis with segmental bowel resection (group B), and 65 treated with the classical technique
        (group A). Intraoperative, perioperative, and postoperative complications were similar between the two groups.
        Mean days of self-catheterization were significantly lower in the nerve-sparing group (39.8 days) compared with
        the non-nerve-sparing group (121.1 days; p  `{'<'}` 0.001). The relapse rate within 12 months after surgery was
        comparable between the two groups. Patients of group A suffered from urinary retention more frequently between 1
        and 6 months (p = 0.035) compared with group B and did not experience any improvement between 6 months and 1
        year (p = 0.018). Overall detection of severe bladder/rectal/sexual dysfunctions was significantly different
        between the two groups, and 56 patients of group A (86.2%) reported a significantly higher rate of severe
        neurologic pelvic dysfunctions vs. 1 patient (1.6%) of group B (p 0.001).

      </P>
      <P>
        Conclusions: Our technique appears to be feasible and offers good results in terms of reduced bladder morbidity
        and apparently higher satisfaction than the classical technique. Considering that this kind of surgery requires
        uncommon surgical skills and anatomical knowledge, we believe that it should be performed only in selected
        reference centers.

      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/22278102/">Quevedo, A., Pasic, R.P., Connor, A., &
        Chamseddine, P. (2021). Fertility sparing endometriosis surgery: A review.</A>]
      </P>
    </Page>
  )
}
