import {styled} from '@mui/system';
import approve from 'pages/velledien/img/approve.png'
import {Black, PinkLight} from 'styles/color';

export const List = styled('ul')({
  paddingLeft: 28,

  '.prefix': {
    display: 'flex',

    '&::before': {
      content: `url(${approve})`,
      marginLeft: -30,
      marginRight: 20,
      marginTop: 6,
    }
  },

  '.marker-black': {
    '::marker': {
      color: Black,
    },
  },

  '.marker-pink': {
    '::marker': {
      color: PinkLight,
    },
  }
})

export const ListItem = styled('li')({
  '::marker': {
    color: '#fff',
  },
})