import eye from './eye.png';
import eyeClose from './close-eye.png';
import logo from './logo.png';

const images = {
  eye,
  eyeClose,
  logo,
}

export default images;