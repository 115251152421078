import {useState} from 'react';
import {useHistory} from 'react-router-dom'

import {Tab, TabsGroup} from 'components/tabs';
import {ProductModal} from 'components/product-modal/product-modal';
import {ProductModalType} from 'components/product-modal/types';
import {WrapperCouple} from 'components/wrapper-couple';
import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import Page from 'shared/ui/Page';
import {Img, List as UL, ListItem as LI, Tables, LinkNavigate} from 'shared/ui';
import {Black, GrayBlack, PinkLight, Red, VioletLight} from 'styles/color';

import {H2, Paragraph as P, H1, Span, Div, Sup} from './ui';
import images from './img'

export const AlvovizanSecond = () => {
  const history = useHistory();

  const [modal, setModal] = useState<ProductModalType>(null);
  const handleClosePopup = () => {
    setModal(null);
  }

  const handleOpenModal = () => {
    setModal('Материалы');
  }

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  const handleOpenVideo = () => {
    history.push('/video/Видео/QjffUXwlqsQ');
  }

  return (
    <>
      <Page head={'Алвовизан'} backText="Продукты" onClose={handleRedirectToProducts}>
        <TabsGroup>
          <Tab>
            <LinkNavigate to="/product/alvovizan/instruction">Инструкция</LinkNavigate>
          </Tab>
          <Tab onClick={handleOpenModal}>Материалы</Tab>
          <Tab onClick={handleOpenVideo}>Видео</Tab>
        </TabsGroup>

        <Div className="text-light" sx={{display: 'flex', flexDirection: 'column', gap: '20px', mb: '150px'}}>
          <WrapperShell sx={{backgroundImage: `url(${images.main})`, height: 500}}>
            <H1 sx={{color: '#FFF', fontSize: 48, mt: 34, ml: 6, lineHeight: '60px'}}>
              Привычка всех <br/> лечить <Span sx={{color: Red}}>оперативно</Span>
            </H1>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell>
              <LinkNavigate to="/product/alvovizan-second/link1">
                <H1 sx={{color: Red}}>Эндометриоз — это хроническое заболевание, которое невозможно
                  вылечить<Sup>1</Sup></H1>
              </LinkNavigate>

              <P sx={{position: 'relative', paddingLeft: '60px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.warn}/>
                Эндометриоз — заболевание с тенденцией <Span sx={{color: Red, fontWeight: 'bold'}}>к прогрессированию и
                рецидивированию.</Span>
              </P>
              <P sx={{position: 'relative', paddingLeft: '60px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.warn}/>
                <Span sx={{color: Red, fontWeight: 'bold'}}>Эндометриоз требует пожизненного плана ведения,&nbsp;
                </Span>
                цель которого — максимально использовать возможности медикаментозной терапии и не допустить повторных
                оперативных вмешательств.
              </P>
              <P sx={{position: 'relative', paddingLeft: '60px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.warn}/>
                <Span sx={{color: Red, fontWeight: 'bold'}}>
                  Нужен индивидуальный подход
                </Span>
                &nbsp;к ведению пациенток с эндометриозом, который регламентируется Клиническими рекомендациями МЗ РФ.
              </P>
            </WrapperShell>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.pair})`}}/>
          </WrapperCouple>

          <WrapperShell sx={{backgroundImage: `url(${images.girl1})`, height: 500}}>
            <WrapperShellBox
              sx={{
                boxShadow: 'none',
                border: `1px solid ${PinkLight}`,
                pl: '80px',
                position: 'relative',
                mt: 26,
                backgroundColor: GrayBlack
              }}>
              <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.warn}/>
              <P sx={{mb: 0, mt: 0, fontSize: 28}}>По протоколам и стандартам терапии эндометриоза операция должна
                проводиться только
                при неэффективности медикаментозной терапии.</P>
              <LinkNavigate to="/product/alvovizan-second/link2">
                <P sx={{fontSize: 28, fontWeight: 'bold', mt: 3, color: Red, mb: 0}}>
                  Операция по поводу эндометриоза в жизни женщины должна быть одна.<Sup>2</Sup>
                </P>
              </LinkNavigate>
            </WrapperShellBox>
          </WrapperShell>

          <WrapperShell>
            <P sx={{fontSize: 24, fontWeight: 'bold', position: 'relative', paddingLeft: '120px'}}>
              <Img sx={{position: 'absolute', left: 0, top: -14}} src={images.seud}/>
              3<Sup sx={{color: '#FFF', fontSize: 14, top: 2}}>RD</Sup>
              CONGRESS OF THE SOCIETY 3 OF<br/>
              ENDOMETRIOSIS AND UTERINE DISORDERS<br/>
              <Span sx={{color: VioletLight, fontSize: 20}}>APRIL 6, 7, 8 SINGAPORE</Span>
            </P>

            <WrapperShellBox
              sx={{
                boxShadow: 'none',
                border: `1px solid ${PinkLight}`,
                pl: '80px',
                position: 'relative',
                mt: 7,
                backgroundColor: GrayBlack
              }}>
              <Div
                sx={{
                  position: 'absolute',
                  left: 16,
                  top: 16,
                  backgroundImage: `url(${images.ellips})`,
                  width: 50,
                  backgroundRepeat: 'no-repeat'
                }}>
                <H2 sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>1</H2>
              </Div>
              <LinkNavigate to="/product/alvovizan-second/link2">
                <P sx={{mt: 0, fontSize: 28, mb: 0}}>
                  Ни в диагностике, ни в лечении эндометриоза хирургическая тактика не должна быть первой линией
                  выбора.<Sup>2</Sup>
                </P>
              </LinkNavigate>
            </WrapperShellBox>
            <WrapperShellBox
              sx={{
                boxShadow: 'none',
                border: `1px solid ${PinkLight}`,
                pl: '80px',
                position: 'relative',
                mt: '20px',
                backgroundColor: GrayBlack
              }}>
              <Div
                sx={{
                  position: 'absolute',
                  left: 16,
                  top: 16,
                  backgroundImage: `url(${images.ellips})`,
                  width: 50,
                  backgroundRepeat: 'no-repeat'
                }}>
                <H2 sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>2</H2>
              </Div>
              <LinkNavigate to="/product/alvovizan-second/link2">
                <P sx={{mt: 0, fontSize: 28, mb: 0}}>
                  Начало медикаментозной терапии возможно без предварительной гистологической верификации.<Sup>2</Sup>
                </P>
              </LinkNavigate>
            </WrapperShellBox>
            <WrapperShellBox
              sx={{
                boxShadow: 'none',
                border: `1px solid ${PinkLight}`,
                pl: '80px',
                position: 'relative',
                mt: '20px',
                backgroundColor: GrayBlack
              }}>
              <Div
                sx={{
                  position: 'absolute',
                  left: 16,
                  top: 16,
                  backgroundImage: `url(${images.ellips})`,
                  width: 50,
                  backgroundRepeat: 'no-repeat'
                }}>
                <H2 sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>3</H2>
              </Div>
              <LinkNavigate to="/product/alvovizan-second/link2">
                <P sx={{mt: 0, fontSize: 28, mb: 0}}>
                  Новая стратегия лечения эндометриоза — уменьшить количество ненужных операций.
                  <Sup>2</Sup>
                </P>
              </LinkNavigate>
            </WrapperShellBox>

            <Div sx={{display: 'flex', mt: 3}}>
              <Div>
                <Img src={images.professor}/>
              </Div>
              <Div sx={{pl: 2, mt: 1}}>
                <H2 sx={{mb: 0}}>Charles Chapron</H2>
                <P sx={{mt: 1}}>
                  Профессор акушерства и гинекологии<br/>
                  медицинского факультета Парижского<br/>
                  Университета Декарта с 1999 года.
                </P>
              </Div>
            </Div>
          </WrapperShell>

          <WrapperShellBox>
            <H1 sx={{color: Red}}>Актуализация проблемы</H1>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell>
              <H1 sx={{color: PinkLight, mb: 5}}>Гистерэктомия — не лучшее решение при аденомиозе</H1>

              <P sx={{position: 'relative', paddingLeft: '60px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plus}/>
                Операция не всегда возможна, а также может иметь негативные последствия.
              </P>
              <P sx={{position: 'relative', paddingLeft: '60px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plus}/>
                Не всегда возможно полное удаление очагов эндометриоза.
              </P>
              <P sx={{position: 'relative', paddingLeft: '60px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plus}/>
                Существуют формы экстрагенитального эндометриоза, когда оперативное лечение сопряжено с осложнениями или
                не возможно.
              </P>
              <P sx={{position: 'relative', paddingLeft: '60px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plus}/>
                Повторные операции могут усилить центральную сенситизацию.
              </P>
              <P sx={{position: 'relative', paddingLeft: '60px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plus}/>
                Пациентка может отказаться от операции по разным причинам.
              </P>
            </WrapperShell>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.girl2})`}}/>
          </WrapperCouple>

          <WrapperShellBox>
            <WrapperShellBox
              sx={{
                boxShadow: 'none',
                border: `1px solid ${PinkLight}`,
                pl: '80px',
                position: 'relative',
                backgroundColor: GrayBlack
              }}>
              <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.warnRed}/>
              <P sx={{mb: 0, mt: 0}}>
                <b>Симптомы аденомиоза</b> — боли и кровотечения — могут быть достаточно выражены, особенно в старшем
                репродуктивном возрасте.
              </P>
              <LinkNavigate to="/product/alvovizan-second/link3">
                <P sx={{mt: 3, mb: 0}}>
                  <b>Гистерэктомии,</b> даже с сохраненными яичниками, произведенные до срока естественной менопаузы,
                  ведут к
                  повышению риска ССЗ* и метаболических расстройств за счет гипоэстрогенного состояния.<Sup>3</Sup>
                </P>
              </LinkNavigate>
            </WrapperShellBox>
            <P sx={{fontSize: 16}}>* ССЗ — сердечно-сосудистые заболевания</P>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.girl3})`}}/>
            <WrapperShell>
              <H1 sx={{color: PinkLight, mb: 5}}>
                Роль акушера-гинеколога в выборе тактики ведения больных эндометриозом в концепции сохранения
                репродуктивной функции
              </H1>

              <P sx={{position: 'relative', paddingLeft: '50px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plusSmall}/>
                Ранняя эмпирическая диагностика
              </P>
              <P sx={{position: 'relative', paddingLeft: '50px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plusSmall}/>
                Патогенетическое лечение

              </P>
              <P sx={{position: 'relative', paddingLeft: '50px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plusSmall}/>
                Реализация репродуктивной функции приведет к повышению качества жизни и снижению рисков развития
                эндометриоз-ассоциированных злокачественных опухолей
              </P>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShell>
            <H1 sx={{color: Red}}>Медикаментозная терапия эндометриоза 2017</H1>
            <Div sx={{display: 'flex', gap: '20px'}}>
              <Div sx={{
                border: `1px solid ${PinkLight}`,
                borderRadius: '12px',
                p: '20px',
                minWidth: 310,
                boxSizing: 'border-box'
              }}>
                <Img src={images.approve}/>
                <H2 sx={{color: PinkLight, mt: 1, fontSize: 24}}>СПЕЦИФИЧЕСКАЯ ТЕРАПИЯ</H2>
                <P>Прогестагены: Диеногест, ЛНГ-ВМС, аГнРГ</P>
                <H2 sx={{color: PinkLight}}>ОДОБРЕНА!</H2>
              </Div>
              <Div sx={{
                border: `1px solid ${PinkLight}`,
                borderRadius: '12px',
                p: '20px',
                minWidth: 312,
                boxSizing: 'border-box'
              }}>
                <Img src={images.cross}/>
                <H2 sx={{color: Red, mt: 1, fontSize: 24}}>НЕ СПЕЦИФИЧЕСКАЯ ТЕРАПИЯ</H2>
                <P>КОК и НПВС</P>
                <H2 sx={{color: Red}}>НЕ ОДОБРЕНА!</H2>
              </Div>
              <Div sx={{
                border: `1px solid ${PinkLight}`,
                borderRadius: '12px',
                p: '20px',
                minWidth: 312,
                boxSizing: 'border-box'
              }}>
                <Img src={images.minus}/>
                <H2 sx={{color: Red, mt: 1, wordBreak: 'break-word', fontSize: 24}}>ЭКСПЕРИМЕНТАЛЬНАЯ ТЕРАПИЯ</H2>
                <P>Ингибиторы ароматазы, антиангиогенная терапия, статины, антиоксиданты, тиазолидиндионы</P>
                <H2 sx={{color: Red}}>СТАДИЯ ИЗУЧЕНИЯ</H2>
              </Div>
            </Div>
            <Div sx={{display: 'flex', mt: 4}}>
              <Div>
                <Img src={images.quotationLarge}/>
              </Div>
              <Div sx={{pl: 2}}>
                <H2 sx={{m: 0}}>Charles Chapron</H2>
                <P sx={{mt: 1}}>
                  3RD Сongress Of The Society Of Endometriosis And Uterine<br/> Disorders. April 6-8, Singapore, 2017
                </P>
              </Div>
            </Div>
          </WrapperShell>

          <WrapperShellBox>
            <LinkNavigate to="/product/alvovizan-second/link4">
              <H1 sx={{color: Red}}>Согласно Итальянским Клиническим Рекомендациям по ведению пациенток с эндометриозом
                2018
                года:<Sup>4</Sup></H1>
            </LinkNavigate>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell>
              <Div
                sx={{
                  pl: '55px',
                  position: 'relative',
                }}>
                <Img sx={{position: 'absolute', left: 0, top: 0}} src={images.plusSmall}/>
                <H2 sx={{m: 0, fontSize: 24, color: PinkLight}}>ВЫБОР МЕТОДА ЛЕЧЕНИЯ ЭНДОМЕТРИОМ</H2>
                <P sx={{mb: 0, mt: 0}}>
                  зависит от симптомов и репродуктивных планов.
                </P>
              </Div>

              <Div
                sx={{
                  pl: '55px',
                  position: 'relative',
                }}>
                <Img sx={{position: 'absolute', left: 0, top: 0}} src={images.plusSmall}/>
                <H2 sx={{mb: 2, mt: 4, fontSize: 24, color: PinkLight}}>КЛЮЧЕВЫЕ ПРИНЦИПЫ ТЕРАПИИ ЭНДОМЕТРИОМ:</H2>
                <ol style={{paddingLeft: 28, fontSize: 20}}>
                  <li>
                    <P><b>Медикаментозная терапия показана ДО ИЛИ ВМЕСТО ОПЕРАЦИИ:</b> Хирургическое лечение показано,
                      если
                      симптомы не купируются медикаментозно, или эндометриома увеличивается в объеме, и ее размер
                      превышает 3 см у пациенток с бесплодием.
                    </P>
                  </li>
                  <li><P>
                    <b>Минимизация риска рецидивирования:</b>
                  </P></li>
                </ol>
                <UL>
                  <LI><P>Применение противорецидивной терапии после хирургического лечения эндометриом является
                    обязательным.</P></LI>
                  <LI><P>Рекомендуются чистые прогестины в качестве первой линии противорецидивной терапии.</P></LI>
                </UL>
              </Div>
            </WrapperShell>
            <WrapperShell>
              <Div sx={{position: 'relative', pl: 6}}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    backgroundImage: `url(${images.ellipsSmall})`,
                    width: 41,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>1</P>
                </Div>
                <LinkNavigate to="/product/alvovizan-second/link13">
                  <P>
                    Европейское общество гинекологической эндоскопии (ESGE) и Европейское общество репродукции и
                    эмбриологии человека (ESHRE) считают, что хирургическое лечение не обязательно для небольших
                    бессимптомных эндометриом диаметром {'<'}3 см [2].<Sup>13</Sup>
                  </P>
                </LinkNavigate>
              </Div>
              <Div sx={{position: 'relative', pl: 6}}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    backgroundImage: `url(${images.ellipsSmall})`,
                    width: 41,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>2</P>
                </Div>
                <P>Хирургическое лечение снижает овариальный резерв*, вне зависимости от типа операции.</P>
              </Div>
              <Div sx={{position: 'relative', pl: 6}}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    backgroundImage: `url(${images.ellipsSmall})`,
                    width: 41,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>3</P>
                </Div>
                <P>Наличие эндометриом не является противопоказанием для ЭКО/ИКСИ.</P>
              </Div>
              <Div sx={{position: 'relative', pl: 6}}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    backgroundImage: `url(${images.ellipsSmall})`,
                    width: 41,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>4</P>
                </Div>
                <P>
                  Хирургическое лечение эндометрия {'>'}3 см в диаметре улучшает фертильность лучше, чем просто дренаж
                  или
                  только коагуляция кисты.
                </P>
              </Div>
              <Div sx={{position: 'relative', pl: 6}}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    backgroundImage: `url(${images.ellipsSmall})`,
                    width: 41,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>5</P>
                </Div>
                <P>
                  Частота спонтанной овуляции, равно как и ответ на гиперстимуляцию гонадотропинами снижаются после
                  операции.
                </P>
              </Div>
              <P sx={{fontSize: 16, color: PinkLight, mt: 5}}>* на основании уровня АМГ после операции.</P>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShellBox>
            <H1 sx={{color: Red}}>Эндометриоз и бесплодие</H1>
          </WrapperShellBox>

          <WrapperCouple>
            <WrapperShell>
              <LinkNavigate to="/product/alvovizan-second/link5">
                <H2>Показания для хирургического вмешательства<Sup>5</Sup></H2>
              </LinkNavigate>
              <Div sx={{
                position: 'relative',
                border: `1px solid ${PinkLight}`,
                p: '20px',
                pl: 9,
                borderRadius: '12px',
                mt: '40px'
              }}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: 18,
                    top: 18,
                    backgroundImage: `url(${images.ellipsSmall})`,
                    width: 41,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>1</P>
                </Div>
                <P sx={{m: 0}}>Невозможность исключить злокачественный процесс.</P>
              </Div>
              <Div sx={{
                position: 'relative',
                border: `1px solid ${PinkLight}`,
                p: '20px',
                pl: 9,
                borderRadius: '12px',
                mt: '20px'
              }}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: 18,
                    top: 18,
                    backgroundImage: `url(${images.ellipsSmall})`,
                    width: 41,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>2</P>
                </Div>
                <P sx={{m: 0}}>Эндометриомы более 4-х см.</P>
              </Div>
              <Div sx={{
                position: 'relative',
                border: `1px solid ${PinkLight}`,
                p: '20px',
                pl: 9,
                borderRadius: '12px',
                mt: '20px'
              }}>
                <Div
                  sx={{
                    position: 'absolute',
                    left: 18,
                    top: 18,
                    backgroundImage: `url(${images.ellipsSmall})`,
                    width: 41,
                    backgroundRepeat: 'no-repeat'
                  }}>
                  <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>3</P>
                </Div>
                <P sx={{m: 0}}>Резервный метод лечения эндометриоза, ассоциированного c болевым синдромом при неудаче
                  медикаментозной терапии.</P>
              </Div>
            </WrapperShell>
            <WrapperShell>
              <Div sx={{display: 'flex', mt: 0}}>
                <Div>
                  <Img src={images.professorSecond}/>
                </Div>
                <Div sx={{pl: 2, mt: 0}}>
                  <H2 sx={{mb: 0}}>Профессор <br/>Charles Chapron:</H2>
                </Div>
              </Div>
              <P>В последнее время пересматриваются стратегии хирургического подхода в сторону более консервативного
                ведения.</P>
              <P>Медикаментозное лечение позволяет нам управлять течением заболевания.</P>
              <P>Необходимо разрабатывать и обсуждать индивидуальный план долгосрочного ведения, своевременно
                планировать оптимальное время оперативного вмешательства и время рождения желанного ребенка.</P>
              <LinkNavigate to="/product/alvovizan-second/link6">
                <P>Длительная медикаментозная терапия призвана уменьшать симптоматику и способствовать профилактике
                  распространения эндометриоза.<Sup>6</Sup></P>
              </LinkNavigate>
            </WrapperShell>
          </WrapperCouple>


          <WrapperShell>
            <LinkNavigate to="/product/alvovizan-second/link7">
              <H2 sx={{color: Red}}>Длительная терапия диеногестом может быть приемлемой альтернативой
                гистерэктомии<Sup>7</Sup></H2>
            </LinkNavigate>
            <H2 sx={{color: PinkLight}}>Ретроспективное когортное исследование пациенток с аденомиозом, получавших
              диеногест 2 мг:</H2>

            <WrapperCouple>
              <WrapperShellBox
                sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative', mb: 2}}>
                <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.approveMin}/>
                <P sx={{mt: 1, mb: 2}}>При долгосрочном приеме ДНГ в течение 5 лет было продемонстрировано
                  снижение:</P>
                <UL>
                  <LI><P>Размера матки на 30,5%: от 38,7 ± 18,7 до 26,9 ± 10,9 см2</P></LI>
                  <LI><P>CA-125: от 124,1 МЕ/мл до 19,6 МЕ/мл (84,2%)</P></LI>
                </UL>
              </WrapperShellBox>
              <WrapperShellBox
                sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative', mb: 2}}>
                <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.approveMin}/>
                <P sx={{mt: 1, mb: 2}}>
                  Даже при долгосрочном приеме (более 5 лет) показатели сывороточного эстрадиола сохранялись в рамках
                  референсных для данного возраста норм.
                </P>
              </WrapperShellBox>
            </WrapperCouple>

            <WrapperCouple>
              <WrapperShellBox
                sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative', mb: 2}}>
                <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.approveMin}/>
                <P sx={{mt: 1, mb: 2}}>
                  Практически 70% участниц продолжили принимать ДНГ в течение более 80 месяцев или вплоть до наступления
                  менопаузы.
                </P>
              </WrapperShellBox>
              <WrapperShellBox
                sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative', mb: 2}}>
                <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.approveMin}/>
                <P sx={{mt: 1, mb: 2}}>
                  Диеногест 2 мг рассматривается как хорошо переносимый препарат с благоприятным профилем эффективности
                  для долгосрочного приема и приемлемая альтернатива гистерэктомии у некоторых пациенток, в особенности
                  с аденомиозом 2 типа.
                </P>
              </WrapperShellBox>
            </WrapperCouple>
            <P sx={{color: PinkLight, fontSize: 16}}>ДНГ — диеногест, Е2 — эстрадиол, N.S. — статистически не
              значимо</P>
          </WrapperShell>

          <WrapperShell>
            <H1 sx={{color: Red}}>Алгоритм ведения больных при аденомиозе</H1>
            <Img sx={{mt: 1, mb: 2}} src={images.chartOne}/>
          </WrapperShell>

          <WrapperShell>
            <H1 sx={{color: Red}}>Необходимость противорецидивной медикаментозной терапии после оперативного
              лечения</H1>
            <H2 sx={{color: PinkLight}}>Рецидивы эндометриом на фоне терапии диеногестом 2 мг через 5 лет составили
              всего 4%, в то время, как
              без лечения рецидивы были у 69%.</H2>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell>
              <LinkNavigate to="/product/alvovizan-second/link8">
                <H2 sx={{color: Red, mb: 3}}>Длительное (более 5 лет) применение Диеногеста после хирургического
                  удаления эндометриомы<Sup>8</Sup></H2>
              </LinkNavigate>
              <Img src={images.chartTwo}/>
            </WrapperShell>
            <WrapperShell className="small">
              <H1 sx={{color: Red, fontSize: 48, mt: 1, mb: 1}}>0,9%</H1>
              <LinkNavigate to="/product/alvovizan-second/link9">
                <P sx={{mb: 5}}>частота рецидива эндометриом на фоне лечения Диеногестом более 12 месяцев
                  — (1 из 114 женщин) <Sup>9</Sup>
                </P>
              </LinkNavigate>
              <Img src={images.operation}/>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShell>
            <LinkNavigate to="/product/alvovizan-second/link10">
              <H1 sx={{color: Red}}>Длительное (более 5 лет) применение Диеногеста после хирургического
                удаления эндометриомы<Sup>10</Sup>
              </H1>
            </LinkNavigate>

            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none', p: 0, flexDirection: 'column', display: 'flex', gap: '20px'}}>
                <WrapperShellBox sx={{border: `1px solid ${PinkLight}`, boxShadow: 'none'}}>
                  <Img src={images.plus}/>
                  <P sx={{mt: 1, mb: 0}}>
                    <Span sx={{color: Red, fontWeight: 'bold'}}>ЭНДОМЕТРИОЗ СЛЕДУЕТ НАЧИНАТЬ ЛЕЧИТЬ
                      МЕДИКАМЕНТОЗНО</Span> и лишь при отсутствии положительного эффекта на
                    протяжении 6 месяцев решать вопрос о целесообразности оперативного вмешательства.
                  </P>
                </WrapperShellBox>

                <WrapperShellBox sx={{border: `1px solid ${PinkLight}`, boxShadow: 'none'}}>
                  <Img src={images.quotation}/>
                  <P sx={{mt: 2, mb: 0}}>
                    Даже если спустя полгода необходимость в хирургическом удалении очагов эндометриоза сохраняется,
                    прекращать фармакотерапию не стоит:
                  </P>
                  <P sx={{mt: 2, mb: 0}}>...в рутинной практике медикаментозное лечение по отношению к хирургическому
                    следует использовать в качестве вспомогательного, причём как до операции, так и после — для
                    профилактики рецидивов эндометриоза...
                  </P>
                </WrapperShellBox>

              </WrapperShell>
              <WrapperShell sx={{border: `1px solid ${PinkLight}`, boxShadow: 'none'}}>
                <Img src={images.plus}/>
                <H2 sx={{color: PinkLight, mb: 4, mt: 1}}>ЗАКЛЮЧЕНИЕ:</H2>
                <Div sx={{position: 'relative', pl: 6}}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      backgroundImage: `url(${images.ellipsSmall})`,
                      width: 41,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>1</P>
                  </Div>
                  <P>Снижение показаний к хирургическому лечению эндометриом вследствие потенциального риска снижения
                    овариального резерва.</P>
                </Div>
                <Div sx={{position: 'relative', pl: 6, mt: 5}}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      backgroundImage: `url(${images.ellipsSmall})`,
                      width: 41,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>2</P>
                  </Div>
                  <P>Хирургическое лечение в случае:</P>
                  <UL>
                    <LI><P sx={{m: 0}}>выраженного болевого синдрома,</P></LI>
                    <LI><P sx={{m: 0}}>интактного овариального резерва,</P></LI>
                    <LI><P sx={{m: 0}}>отсутствия хирургического лечения в анамнезе,</P></LI>
                    <LI><P sx={{m: 0}}>односторонней эндометриомы,</P></LI>
                    <LI><P sx={{m: 0}}>быстрого роста эндометриом.</P></LI>
                  </UL>
                </Div>
                <Div sx={{position: 'relative', pl: 6, mt: 5}}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      backgroundImage: `url(${images.ellipsSmall})`,
                      width: 41,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <P sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>3</P>
                  </Div>
                  <P>Альтернативные техники (плазма, этанол) требуют дополнительного изучения.</P>
                </Div>
              </WrapperShell>
            </WrapperCouple>
            <WrapperShellBox
              sx={{
                boxShadow: 'none',
                border: `1px solid ${PinkLight}`,
                pl: '80px',
                position: 'relative',
                mt: '20px',
                mb: 2,
                backgroundColor: GrayBlack
              }}>
              <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.warn}/>
              <P sx={{mb: 0, mt: 0, color: Red, fontSize: 26}}>
                <Span sx={{fontWeight: 'bold', fontSize: 28}}>Операция</Span> по поводу эндометриоза
                в жизни женщины <Span sx={{fontWeight: 'bold', fontSize: 28}}>должна быть только одна</Span>
              </P>
            </WrapperShellBox>
          </WrapperShell>

          <WrapperShell>
            <H1 sx={{color: Red}}>Алвовизан — немецкий препарат, на 30% дешевле оригинального</H1>
            <H2 sx={{color: PinkLight, mb: 3}}>
              Назначая Алвовизан, доктор гарантирует своим пациенткам эффективное и доступное по цене лечение.
            </H2>

            <Tables.WrapperTable sx={{overflowX: 'auto'}}>
              <Tables.Table className="align-default" sx={{fontSize: 18, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr>
                    <Tables.Td sx={{width: 250}}>Действующее вещество</Tables.Td>
                    <Tables.Td colSpan={2}>Диеногест 2 мг</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: PinkLight}}>
                    <Tables.Td sx={{color: Black}}>Препарат</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Red, width: 230}}>Алвовизан №28</Tables.Td>
                    <Tables.Td sx={{color: Black, width: 230}}>Визанна №28</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td>Средняя стоимость<br/> в аптеке, ₽</Tables.Td>
                    <Tables.Td>2 500</Tables.Td>
                    <Tables.Td>3 500</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / месяц, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>1 080</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / год — 12 циклов, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>12 000</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
          </WrapperShell>

          <WrapperShell>
            <H2 sx={{color: PinkLight}}>Исследование сравнительной фармакокинетики и биоэквивалентности препаратов
              Алвовизан и Визанна показало,
              что препараты
            </H2>
            <LinkNavigate to="/product/alvovizan-second/link12">
              <H1 sx={{color: Red, fontSize: 35}}>Алвовизан и Визанна являются биоэквивалентными<Sup>12</Sup></H1>
            </LinkNavigate>
            <Img sx={{mb: 5}} src={images.chartFour}/>

            <WrapperShellBox
              sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative', mb: 2}}>
              <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.approve}/>
              <P sx={{fontSize: 24, fontWeight: 'bold', mt: 1, color: Red, mb: 2}}>ВЫВОДЫ:</P>
              <UL>
                <LI><P>Исследуемые препараты характеризуются высокой степенью сходства показателей фармакокинетики.</P></LI>
                <LI><P>Профили фармакокинетических кривых препаратов Алвовизин и Визанна имеют совпадающие
                  формы.</P></LI>
                <LI><P>Препараты характеризуются близкими значениями относительной биодоступности, максимальной
                  концентрации и относительной скорости всасывания.</P></LI>
                <LI><P>Доверительные интервалы для AUC, Cmax и Cmax/AUC соответствуют допустимым пределам
                  80–125%.</P></LI>
                <LI><P>Препараты имеют сопоставимый профиль безопасности.</P></LI>
              </UL>
            </WrapperShellBox>
          </WrapperShell>

          <WrapperShell>
            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none  '}}>
                <H1 sx={{color: Red, mt: 0}}>АЛВОВИЗАН</H1>
                <P>Диеногест микронизированный 2 мг</P>
                <UL>
                  <LI><P sx={{mt: 1, mb: 1}}>Лекарственная форма: таблетки 2 мг</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Фармакотерапевтическая группа: гестаген</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Форма выпуска: таблетки № 28, № 84 и № 168</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Срок годности: 2 года</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Условия отпуска: по рецепту</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Производитель: Хаупт Фарма Мюнстер ГмбХ (Германия).</P></LI>
                </UL>
                <LinkNavigate to="/product/alvovizan/instruction">
                  <H2 sx={{color: Red}}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ:<Sup>13</Sup></H2>
                </LinkNavigate>
                <P sx={{mt: 1}}>Лечение эндометриоза</P>
              </WrapperShell>
              <WrapperShell className="small" sx={{
                boxShadow: 'none',
                border: `1px solid ${PinkLight}`,
                pl: '80px',
                position: 'relative',
                mb: 2
              }}>
                <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.approveRed}/>
                <P sx={{mt: 0}}>Применение препарата в особых клинических группах пациенток.</P>
                <P>Пациентки детского возраста.</P>
                <P>Препарат не показан к применению у детей до наступления менархе.</P>
                <P sx={{mb: 0}}>Эффективность препарата была продемонстрирована при лечении эндометриозассоциированной
                  тазовой боли у
                  подростков (12–18 лет) с общей благоприятной безопасностью и переносимостью.</P>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell sx={{backgroundImage: `url(${images.mainBottom})`, height: 500}}/>
        </Div>
      </Page>
      <ProductModal product="alvovizan/modal" modal={modal} onClose={handleClosePopup}/>
    </>
  )
}
