import {Box} from '@mui/material';

import {StyledBoxAnchor, StyledWrapperBox} from './ui';
import {StyledBoxBorder, StyledBoxInsetShadow, StyledBoxShadow, StyledCircle, StyledHeader} from '../../ui';
import images, {icons} from '../../img'
import {PinkLight} from '../../../../styles/color';

export const EffectiveSalesCompetitors = () => {
  return (
    <>
      <StyledHeader sx={{mb: 3, mt: 7}}>Кокуренты</StyledHeader>
      <Box sx={{display: 'flex', gap: '20px', flexWrap: 'wrap', mb: 5}}>
        <StyledBoxAnchor href="#zoely">Зоэли</StyledBoxAnchor>
        <StyledBoxAnchor href="#microgynon">МИКРОГИНОН</StyledBoxAnchor>
        <StyledBoxAnchor href="#vidora">ВИДОРА</StyledBoxAnchor>
        <StyledBoxAnchor href="#vidora-micro">ВИДОРА Микро</StyledBoxAnchor>
        <StyledBoxAnchor href="#diecyclen">ДИЕЦИКЛЕН</StyledBoxAnchor>
        <StyledBoxAnchor href="#alvovizan">АЛВОВИЗАН</StyledBoxAnchor>
        <StyledBoxAnchor href="#klimonorm">КЛИМОНОРМ</StyledBoxAnchor>
        <StyledBoxAnchor href="#velledien">ВЕЛЛЕДИЕН</StyledBoxAnchor>
      </Box>
      <StyledWrapperBox>
        <StyledBoxShadow id="zoely">
          <StyledBoxInsetShadow
            sx={
              {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 40px',
              }
            }
          >
            <Box sx={{fontSize: 24, fontWeight: 700}}>KOK</Box>
            <Box sx={{color: PinkLight, fontSize: 30, fontWeight: 700}}>ЗОЭЛИ®</Box>
            <img src={images.zoely} alt="Зоэил"/>
          </StyledBoxInsetShadow>

          <Box className="wrapper-container">
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">КЛАЙРА®</Box>
                <img src={images.klaira} alt="Клайра"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>2 тёмно-жёлтые таблетки:</strong> <br/>
                    эстрадиола валерат микро 20 диеногест микро — 3,00 мг
                  </Box>
                  <Box sx={{mb: 2}}>
                    <strong>5 розовых таблеток:</strong> <br/>
                    эстрадиола валерат, микро 20 — 2,00 мг, диеногест микро — 3,00 мг
                  </Box>
                  <Box sx={{mb: 2}}>
                    <strong>17 бледно-жёлтых таблеток:</strong> <br/>
                    эстрадиола валерат, микро 20 — 2,00 мг, диеногест микро — 3,00 мг
                  </Box>
                  <Box sx={{mb: 2}}>
                    <strong>2 красные таблетки:</strong> <br/>
                    эстрадиола валерат микро 20 — 1,00 мг
                  </Box>
                  <Box sx={{mb: 2}}>
                    <strong>
                      2 белые таблетки, покрытые плёночной оболочкой, — плацебо
                    </strong>
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box>
                    <ul className="list">
                      <li>
                        Клайра (Qlaira) используется для предотвращения беременности
                      </li>
                      <li>
                        Также препарат назначается для лечения тяжёлых и длительных менструальных
                        кровотечений (не связанных с протеканием основного заболевания)
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ЭСТЕРЕТТА®</Box>
                <img src={images.esteretta} alt="Эстератта"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>24 активные таблетки светло-розового цвета:</strong> <br/>
                    эстетрола моногидрат — 15,00 мг,
                    дроспиренона — 3,00 мг
                  </Box>
                  <Box sx={{mb: 2}}>
                    <strong>4 белые таблетки плацебо</strong>
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box>
                    Пероральная контрацепция у взрослых
                    женщин (в возрасте старше 18 лет)
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow id="microgynon">
          <StyledBoxInsetShadow
            sx={
              {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 40px',
              }
            }
          >
            <Box sx={{fontSize: 24, fontWeight: 700}}>KOK</Box>
            <Box sx={{color: PinkLight, fontSize: 30, fontWeight: 700}}>МИКРОГИНОН®</Box>
            <img src={images.microgynom} alt="МИКРОГИНОН"/>
          </StyledBoxInsetShadow>

          <Box className="wrapper-container">
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">РИГЕВИДОН®</Box>
                <img src={images.rigevidon} alt="РИГЕВИДОН"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>Активные вещества:</strong> <br/>
                    этинилэстрадиол — 0,03 мг,
                    левоноргестрел — 0,15 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box>
                    <ul className="list">
                      <li>
                        Пероральная контрацепция
                      </li>
                      <li>
                        Функциональные нарушения
                        менструального цикла
                        (в т. ч. дисменорея
                        без органической причины,
                        дисфункциональная метроррагия,
                        предменструальный синдром)
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ПланиЖенс® лево</Box>
                <img src={images.planichens} alt="ПланиЖенс® лево"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>Активные вещества:</strong> <br/>
                    этинилэстрадиол — 0,03 мг,
                    левоноргестрел — 0,15 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box>Пероральная контрацепция</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow id="vidora">
          <StyledBoxInsetShadow
            sx={
              {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 40px',
              }
            }
          >
            <Box sx={{fontSize: 24, fontWeight: 700}}>KOK</Box>
            <Box sx={{color: PinkLight, fontSize: 30, fontWeight: 700}}>ВИДОРА®</Box>
            <img src={images.vidora} alt="ВИДОРА"/>
          </StyledBoxInsetShadow>

          <Box className="wrapper-container">
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ЯРИНА®</Box>
                <img src={images.arina} alt="ЯРИНА®"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>21 активная таблетка жёлтого цвета:</strong> <br/>
                    дроспиренон — 3,00 мг,
                    этинилэстрадиол 0,03 мг
                  </Box>
                  <Box sx={{mb: 2}}>
                    <strong>7 таблеток плацебо белого цвета.</strong>
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box>Контрацепция</Box>
                </Box>
              </Box>
            </Box>
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ПланиЖенс® дроспи 30</Box>
                <img src={images.planichens_drospy_30} alt="ПланиЖенс® дроспи 30"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>21 активная таблетка жёлтого цвета:</strong> <br/>
                    дроспиренон — 3,00 мг,
                    этинилэстрадиол 0,03 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box>
                    Пероральная
                    контрацепция у девочек-подростков и взрослых
                    женщин
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow id="vidora-micro">
          <StyledBoxInsetShadow
            sx={
              {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 40px',
              }
            }
          >
            <Box sx={{fontSize: 24, fontWeight: 700}}>KOK</Box>
            <Box sx={{color: PinkLight, fontSize: 30, fontWeight: 700}}>ВИДОРА® Микро</Box>
            <img src={images.vidora_24_4} alt="ВИДОРА® Микро"/>
          </StyledBoxInsetShadow>

          <Box className="wrapper-container">
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ДЖЕС®</Box>
                <img src={images.dches} alt="ДЖЕС®"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>24 активные таблетки свело-розового цвета:</strong> <br/>
                    дроспиренон — 3,00 мг,
                    этинилэстрадиол 0,02 мг
                  </Box>
                  <Box sx={{mb: 2}}>
                    <strong>4 таблетки плацебо белого цвета</strong>
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box>
                    <ul className="list">
                      <li>Контрацепция</li>
                      <li>
                        Контрацепция и лечение угревой
                        сыпи (acne vulgaris) средней
                        степени тяжести
                      </li>
                      <li>
                        Контрацепция и лечение тяжелой
                        формы предменструального
                        синдрома
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ПланиЖенс® дроспи 20</Box>
                <img src={images.planiches_dropsy_20} alt="ПланиЖенс® дроспи 20"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>21 активная таблетка жёлтого цвета:</strong> <br/>
                    дроспиренон — 3,00 мг,
                    этинилэстрадиол 0,03 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box>
                    <ul className="list">
                      <li>Контрацепция</li>
                      <li>
                        Контрацепция и лечение угревой
                        сыпи (acne vulgaris) средней
                        степени тяжести
                      </li>
                      <li>
                        Контрацепция и лечение тяжелой
                        формы предменструального
                        синдрома
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow id="diecyclen">
          <StyledBoxInsetShadow
            sx={
              {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 40px',
              }
            }
          >
            <Box sx={{fontSize: 24, fontWeight: 700}}>KOK</Box>
            <Box sx={{color: PinkLight, fontSize: 30, fontWeight: 700}}>ДИЕЦИКЛЕН®</Box>
            <img src={images.diecyclen} alt="ДИЕЦИКЛЕН®"/>
          </StyledBoxInsetShadow>

          <Box className="wrapper-container">
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ЖАНИН®</Box>
                <img src={images.chanin} alt="ЖАНИН®"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>21 таблетка белого цвета:</strong> <br/>
                    диеногест — 2,00 мг,
                    этинилэстрадиол — 0,03 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box>Пероральная контрацепция</Box>
                </Box>
              </Box>
            </Box>
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ПланиЖенс® дие</Box>
                <img src={images.planiches_die} alt="ПланиЖенс® дие"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>21 таблетка белого цвета:</strong> <br/>
                    диеногест — 2,00 мг,
                    этинилэстрадиол — 0,03 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box sx={{mb: 2}}>Пероральная контрацепция.</Box>
                  <Box sx={{mb: 2}}>
                    При принятии решения о назначении
                    препарата ПланиЖенс® дие следует
                    учитывать наличие индивидуальных факторов
                    риска, особенно тех, которые относятся
                    к факторам риска развития венозной
                    тромбоэмболии (ВТЭ), и то, как риск
                    развития ВТЭ при применении препарата
                    ПланиЖенс® дие сопоставим с риском ВТЭ
                    при применении других комбинированных
                    гормональных контрацептивов.
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow id="alvovizan">
          <StyledBoxInsetShadow
            sx={
              {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 40px',
              }
            }
          >
            <Box sx={{fontSize: 24, fontWeight: 700}}>ЛЕЧЕНИЕ<br/>ЭНДОМЕТРИОЗА</Box>
            <Box sx={{color: PinkLight, fontSize: 30, fontWeight: 700}}>АЛВОВИЗАН®</Box>
            <img src={images.alvovizan} alt="АЛВОВИЗАН®"/>
          </StyledBoxInsetShadow>

          <Box className="wrapper-container">
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ЗАФРИЛЛА®</Box>
                <img src={images.zafrilla} alt="ЗАФРИЛЛА®"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>28 таблеток белого цвета:</strong> <br/>
                    диеногест<br/> микронизированный — 2 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания<br/> к применению</strong>
                  </Box>
                  <Box>Лечение эндометриоза</Box>
                </Box>
              </Box>
            </Box>
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ВИЗАННА®</Box>
                <img src={images.vizanna} alt="ВИЗАННА®"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>28 таблеток белого цвета:</strong> <br/>
                    диеногест<br/> микронизированный — 2 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания<br/> к применению</strong>
                  </Box>
                  <Box sx={{mb: 2}}>Лечение эндометриоза</Box>
                </Box>
              </Box>
            </Box>
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ДЛЯЖЕНС® метри</Box>
                <img src={images.dlachens_klimo} alt="ДЛЯЖЕНС® метри"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>28 таблеток белого цвета:</strong> <br/>
                    диеногест<br/> микронизированный — 2 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания<br/> к применению</strong>
                  </Box>
                  <Box>Лечение эндометриоза</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow id="klimonorm">
          <StyledBoxInsetShadow
            sx={
              {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 40px',
              }
            }
          >
            <Box sx={{fontSize: 24, fontWeight: 700}}>МГТ</Box>
            <Box sx={{color: PinkLight, fontSize: 30, fontWeight: 700}}>КЛИМОНОРМ®</Box>
            <img src={images.klimonorm} alt="КЛИМОНОРМ®"/>
          </StyledBoxInsetShadow>

          <Box className="wrapper-container">
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ФЕМОСТОН® 1</Box>
                <img src={images.femoston_1} alt="ФЕМОСТОН® 1"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>14 таблеток серого цвета:</strong> <br/>
                    эстрадиола гемигидрат — 1,03 мг,
                    дидрогестерон — 10 мг
                  </Box>
                  <Box sx={{mb: 2}}>
                    <strong>14 таблеток белого цвета:</strong> <br/>
                    эстрадиола гемигидрат — 1,03 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания<br/> к применению</strong>
                  </Box>
                  <Box>
                    <ul className="list">
                      <li>
                        Заместительная гормональная терапия
                        расстройств, обусловленных дефицитом
                        эстрогенов у женщин в перименопаузе (не
                        ранее чем через 6 месяцев после последней
                        менструации) или у женщин в постменопаузе
                      </li>
                      <li>
                        Профилактика постменопаузального остеопороза
                        у женщин с высоким риском переломов
                        при непереносимости или противопоказаниях
                        к применению других лекарственных препаратов
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ФЕМОСТОН® 2</Box>
                <img src={images.femoston_2} alt="ФЕМОСТОН® 2"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>14 таблеток светло-жёлтого цвета:</strong> <br/>
                    эстрадиола гемигидрат — 2,06 мг,
                    дидрогестерон — 10 мг
                  </Box>
                  <Box sx={{mb: 2}}>
                    <strong>14 таблеток розового цвета:</strong> <br/>
                    эстрадиола гемигидрат — 2,06 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания<br/> к применению</strong>
                  </Box>
                  <Box>
                    <ul className="list">
                      <li>
                        Заместительная гормональная терапия
                        расстройств, обусловленных дефицитом
                        эстрогенов у женщин в перименопаузе (не
                        ранее чем через 6 месяцев после последней
                        менструации) или у женщин в постменопаузе
                      </li>
                      <li>
                        Профилактика постменопаузального остеопороза
                        у женщин с высоким риском переломов
                        при непереносимости или противопоказаниях
                        к применению других лекарственных препаратов
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ДЛЯЖЕНС® климо</Box>
                <img src={images.dlachens_klimo} alt="ДЛЯЖЕНС® климо"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>9 таблеток белого цвета:</strong> <br/>
                    эстрадиола гемигидрат — 2,07 мг в пересчете на эстрадиол — 2,0 мг
                  </Box>
                  <Box sx={{mb: 2}}>
                    <strong>12 таблеток жёлтого цвета:</strong> <br/>
                    левоноргестрел — 0,15 мг, эстрадиола гемигидрат — 2,07 мг в пересчете на эстрадиол — 2,0 мг
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания<br/> к применению</strong>
                  </Box>
                  <Box>
                    <ul className="list">
                      <li>
                        Заместительная гормональная терапия (ЗГТ) при симптомах дефицита эстрогенов
                        у женщин с интактной (сохраненной) маткой в постменопаузе (не раньше, чем
                        через 6 месяцев после последней менструации, в случае естественной менопаузы,
                        или сразу же после менопаузы в результате хирургического вмешательства,
                        например, после двухсторонней овариэктомии или лучевой терапии).
                      </li>
                      <li>
                        Профилактика остеопороза в постменопаузе у женщин с высоким риском
                        переломов при непереносимости или наличии противопоказаний к применению
                        других лекарственных средств для профилактики остеопороза. Опыт применения
                        комбинации левоноргестрел+эстрадиол у женщин в возрасте старше 65 лет
                        ограничен
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow id="velledien">
          <StyledBoxInsetShadow
            sx={
              {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 40px',
              }
            }
          >
            <Box sx={{fontSize: 24, fontWeight: 700}}>МГТ</Box>
            <Box sx={{color: PinkLight, fontSize: 30, fontWeight: 700}}>ВЕЛЛЕДИЕН®</Box>
            <img src={images.velledien} alt="ВЕЛЛЕДИЕН®"/>
          </StyledBoxInsetShadow>

          <Box className="wrapper-container">
            <Box className="container">
              <StyledBoxBorder className="border-box">
                <Box className="border-box__title">ЛИВИАЛ®</Box>
                <img src={images.livial} alt="ЛИВИАЛ®"/>
              </StyledBoxBorder>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px'}}>
                  <img src={icons.drug} alt="Drug"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2}}>СОСТАВ</Box>
                  <Box sx={{mb: 2}}>
                    <strong>28 таблеток:</strong> 2,5 мг тиболон
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                <StyledCircle sx={{padding: '5px 4px 5px 7px'}}>
                  <img src={icons.file} alt="File"/>
                </StyledCircle>
                <Box>
                  <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                    <strong>Показания к применению</strong>
                  </Box>
                  <Box>
                    <ul className="list">
                      <li>
                        Лечение симптомов эстрогенной недостаточности
                        у женщин в постменопаузальном периоде (не ранее
                        чем через 1 год после последней менструации
                        при наступлении естественной менопаузы или сразу
                        после хирургической менопаузы)
                      </li>
                      <li>
                        Профилактика постменопаузального остеопороза
                        у женщин с высоким риском переломов
                        при непереносимости или противопоказаниях
                        к применению других лекарственных препаратов,
                        предназначенных для лечения остеопороза
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>
      </StyledWrapperBox>
    </>
  )
}
