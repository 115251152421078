import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const VelledienLinkNine = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Endometrial effects of tibolone</H1>
      <P><b>Background and objectives:</b> The Tibolone Histology of the Endometrium and Breast Endpoints Study is a multicenter, randomized, double-blind study designed to address the conflicting reports in the literature about the endometrial safety of tibolone (1.25 or 2.5 mg/d). Tibolone was compared with continuous combined conjugated equine estrogen (CEE) plus medroxyprogesterone acetate (MPA) (0.625 + 2.5 mg/d).
      </P>
      <P><b>Methods:</b> Subjects were randomized in a 1:1:2 ratio to tibolone 1.25 mg/d, 2.5 mg/d, and CEE/MPA, respectively. The one-sided 95% confidence interval (CI) has been evaluated for the incidence of abnormal endometrial histology (hyperplasia or carcinoma) and hyperplasia and carcinoma separately for each of the two treatment groups and the treatment groups combined after 1 and 2 yr of treatment with tibolone, compared with CEE/MPA.
      </P>
      <P><b>Results:</b> A total of 3240 women were randomized, with 3224 receiving at least one dose of study medication. The incidence and upper one-sided 95% CI for the incidence of abnormal endometrium (hyperplasia or carcinoma), and hyperplasia and carcinoma separately, were calculated at end point, yr 1, and yr 2. The incidence (upper one-sided 95% CI) of abnormal endometrium at end point was 0.0 (0.5), 0.0 (0.4), and 0.2 (0.5) in the tibolone 1.25 mg, 2.5 mg, and CEE/MPA groups, respectively. During the entire treatment period, amenorrhea was reported more frequently with tibolone 1.25 mg (78.7%) and 2.5 mg (71.4%) than CEE/MPA (44.9%).
      </P>
      <P><b>Conclusion:</b> The Tibolone Histology of the Endometrium and Breast Endpoints Study results confirm previous findings that tibolone does not induce endometrial hyperplasia or carcinoma in postmenopausal women, and it is associated with a better vaginal bleeding profile than CEE/MPA.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/17192288/">Archer DF, et al. Endometrial effects of tibolone. J Clin Endocrinol Metab. 2007 Mar;92(3):911-8.</A>]
      </P>
    </Page>
  )
}
