import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import Page from 'shared/ui/Page';
import {Tabs} from './tabs';
import {TABS} from './constants';
import {SubpageKlimonorm} from './subpage/subpage-klimonorm';
import {SubpageZoely} from './subpage/subpage-zoely';
import {SubpageMicroginon} from './subpage/subpage-microginon';
import {SubpageAlvovizan} from './subpage/subpage-alvovizan';
import {SubpageVidora} from './subpage/subpage-vidora';
import {SubpageDiecyclen} from './subpage/subpage-diecyclen';
import {SubpageVelledien} from './subpage/subpage-velledien';
import {SubpageProginova} from './subpage/subpage-proginova';
import {StyledBtn, WrapperBox} from './ui';
import Popup from '../../../shared/ui/Popup';
import PrezentationItem from '../../vidora/PrezentationItem';

export const EffectiveSalesDoctor = () => {
  const history = useHistory();
  const {pathname} = useLocation();
  const currentSubPage = pathname.split('/')[2];

  const [product, setProduct] = useState<string | null>(null);

  useEffect(() => {
    const path = getPathForProduct(currentSubPage as TABS);
    setProduct(path);
  }, [currentSubPage]);

  const handleRedirectToBack = () => {
    history.push('/effective-sales/to-doctor');
  }

  const handleClosePopup = () => {
    history.push(`/effective-sales-doctor/${product}`);
  }

  const handleToPromo = () => {
    if(product === 'alvovizan' || product ===  'proginova') {
      history.push(`/effective-sales-doctor/${product}/modal`)
    } else {
      history.push(`/product/${product}`)
    }
  }

  return (
    <>
      <Page
        head={'ВИЗИТ К ВРАЧУ'}
        backText="Назад"
        onClose={handleRedirectToBack}
      >
        <WrapperBox sx={{mb: 21}}>
          <Tabs sx={{mt: 7, mb: 5}}/>
          {currentSubPage === TABS.KLIMONORM && <SubpageKlimonorm/>}
          {currentSubPage === TABS.ZOELY && <SubpageZoely/>}
          {currentSubPage === TABS.MICROGINON && <SubpageMicroginon/>}
          {currentSubPage === TABS.ALVOVIZAN && <SubpageAlvovizan/>}
          {currentSubPage === TABS.VIDORA && <SubpageVidora/>}
          {currentSubPage === TABS.DIECYCLEN && <SubpageDiecyclen/>}
          {currentSubPage === TABS.VELLEDIEN && <SubpageVelledien/>}
          {currentSubPage === TABS.PROGINOVA && <SubpageProginova/>}
          <StyledBtn onClick={handleToPromo}>ПРОМОМАТЕРИАЛЫ</StyledBtn>
        </WrapperBox>
      </Page>

      <Popup opened={pathname === '/effective-sales-doctor/proginova/modal'} togglePopup={handleClosePopup} title={'Прогинова'}>
        <PrezentationItem icon="plus" name="Помощь новой жизни" link="/product/proginova"/>
        <PrezentationItem icon="plus" name="Помощь при эстрогенной недостаточности" link="/product/proginova-second"/>
      </Popup>

      <Popup opened={pathname === '/effective-sales-doctor/alvovizan/modal'} togglePopup={handleClosePopup} title={'Алвовизан'}>
        <PrezentationItem icon="plus" name="Расширение терапевтического взгляда на эндометриоз" link="/product/alvovizan"/>
        <PrezentationItem icon="plus" name="Привычка всех лечить оперативно" link="/product/alvovizan-second"/>
        <PrezentationItem icon="plus" name="Социально-ответственная терапия эндометриоза" link="/product/alvovizan-thirteen"/>
      </Popup>
    </>
  )
}

const getPathForProduct = (currentSubPage: TABS) => {
  switch (currentSubPage) {
    case TABS.KLIMONORM:
      return 'klomonorm';
    case TABS.ZOELY:
      return 'eoeli';
    case TABS.MICROGINON:
      return 'microginon';
    case TABS.ALVOVIZAN:
      return 'alvovizan';
    case TABS.VIDORA:
      return 'vidora';
    case TABS.DIECYCLEN:
      return 'diecyclen';
    case TABS.VELLEDIEN:
      return 'velledien';
    case TABS.PROGINOVA:
      return 'proginova';
  }
}
