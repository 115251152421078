// import styled from 'styled-components';
import {styled} from '@mui/system';

export const H1 = styled('h1')`
  color: #F9D2D6;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  word-break: break-word;
  margin: 30px auto 14px;
  font-family: 'SF-Pro-Text-Semibold';
  
  @media only screen and (max-width: 600px){
    font-size: 26px;
  }
`;

export const H2 = styled('h2')<{ textAlign?: 'left' | 'right' | 'center'}>`
  font-size: 22px;
  color: #F9D2D6;
  margin: 8px auto;
  letter-spacing: 1px;
  text-align: ${({ textAlign }) => textAlign};
  font-family: 'SF-Pro-Text-Semibold';
`

export const A = styled('a')<{
  textAlign?: string
}>`
  display: block;
  color: #0076BD;
  margin: 10px auto;
  letter-spacing: 0.1px;
  font-size: 17px;
  text-align: ${({ textAlign }) => textAlign};
  font-family: 'SF-Pro-Text-Semibold';

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Wrapper = styled('div')<{
  textAlign?: string,
  display?: string,
  justifyContent?: string;
  flexDirection?: string;
  wrap?: string;
}>`
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ wrap }) => wrap};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

export const Span = styled('span')<{ color?: string}>`
  color: ${({ color }) => color || '#fff'};
  font-family: 'SF Pro Display Light';
  font-weight: 400;
`

export const P = styled('p')`
  font-size: 19px;
  letter-spacing: 1px;
  color: #fff;
  margin: 8px auto;
  font-family: 'SF Pro Display Light';
`

export const Strong = styled('strong')<{ textDecoration?: string}>`
  text-decoration: ${({ textDecoration }) => textDecoration};
`

export const UL = styled('ul')({
  paddingLeft: 4
})

export const LI = styled('li')`
  margin-left: 25px;
  ::marker {
    color: #fff;
  }
`