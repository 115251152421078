import {useHistory} from 'react-router-dom';

import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import {WrapperCouple} from 'components/wrapper-couple';
import Page from 'shared/ui/Page';
import {Div, H1, H2, Img, List as UL, ListItem as LI, Paragraph as P, Span, Sup, Tables} from 'shared/ui';
import {BlueDark, GrayLight, GreenLight, PinkLight, RedLight, Violet2, YellowDark} from 'styles/color';
import {AnchorLink, LinkA as A, Notice} from './ui';

import * as images from './img';

export const ZoelyCompare = () => {
  const history = useHistory();

  const handleToBack = () => {
    history.push('/product/eoeli#survey');
  }

  return (
    <Page onClose={handleToBack} isCross>
      <Div sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
        <H2 sx={{
          fontSize: 36,
          color: '#FFFFFF',
          fontFamily: 'SF-Pro-Text-Regular',
          textAlign: 'center',
          mt: 0,
          textTransform: 'uppercase',
          mb: 2
        }}>СРАВНЕНИЕ ВЛИЯНИЯ НА ГЕМОСТАЗ СРЕДИ КОК</H2>
        <WrapperShell sx={{pt: 4, pb: 4}}>
          <H1>Количество инцидентов ВТЭ на 10 000 женщин в год</H1>
          <WrapperShell
            sx={{
              boxShadow: 'none',
              border: `1px solid ${PinkLight}`,
              backgroundImage: `url(${images.compareMain})`,
              height: 400,
              backgroundOrigin: 'content-box'
            }}>
            <P sx={{mt: '35px', ml: '4px', display: 'flex'}}>
              <AnchorLink href="#group-one-1">E2/NOMAC</AnchorLink><Sup>1</Sup>
              <Span sx={{ml: 11, fontSize: 30}}>2</Span>
            </P>
            <P sx={{mt: '42px', ml: '74px', display: 'flex'}}>
              НЕ ПРИМЕНЯЮЩИЕ <br/>
              <Span sx={{ml: 11, fontSize: 30}}>2-3</Span>
            </P>
            <P sx={{mt: -1, ml: '210px', display: 'flex'}}>
              <AnchorLink sx={{display: 'block'}} href="#group-one-2">KOK</AnchorLink><Sup>2-4</Sup>
            </P>
            <P sx={{mt: '21px', ml: '364px', display: 'flex'}}>
              <AnchorLink href="#group-one-2">EE/LNG</AnchorLink><Sup>2</Sup>
              <Span sx={{ml: 11, fontSize: 30}}>5-7</Span>
            </P>
            <P sx={{mt: '43px', ml: '512px', display: 'flex'}}>
              <AnchorLink href="#group-one-2">EE/DRSP</AnchorLink><Sup>2</Sup>
              <Span sx={{ml: 12, fontSize: 30}}>9-12</Span>
            </P>
            <P sx={{mt: '43px', ml: '662px', display: 'flex'}}>
              <AnchorLink href="#group-one-5">E4/DRSP</AnchorLink><Sup>5</Sup>
              <Span sx={{ml: 16, fontSize: 48}}>?</Span>
            </P>
          </WrapperShell>

          <P sx={{mt: 5}}>
            Риск ВТЭ у E2/NOMAC и женщин, не использующих КОК, был&nbsp;
            <AnchorLink href="#group-one-5">сопоставим</AnchorLink><Sup>5</Sup>&nbsp;
            – на основании исследования PRO-E2 с участием
            <AnchorLink href="#group-one-1">101498 женщин</AnchorLink><Sup>1</Sup>
          </P>

          <P sx={{mt: 2, mb: 5}}>
            Вероятно, потребуется еще десятилетие, чтобы получить результаты постмаркетинговых исследований риска ВТЭ
            при
            приеме&nbsp;
            <AnchorLink href="#group-one-5">эстетрола (E4)</AnchorLink><Sup>5</Sup>.
          </P>

          <A id="group-one-1" href="https://pubmed.ncbi.nlm.nih.gov/34644228/">
            <Span>1. </Span>
            <Span sx={{textDecoration: 'underline'}}>
              Reed S, Eur J Contracept Reprod Health Care. 2021;26(6):439–446.
            </Span>
          </A>
          <A id="group-one-2"
             href="https://www.ema.europa.eu/en/news/prac-confirms-benefits-all-combined-hormonal-contraceptives-chcs-continue-outweigh-risks">
            <Span>2. </Span>
            <Span sx={{textDecoration: 'underline'}}>
              PRAC confirms that benefits of all combined hormonal contraceptives (CHCs) continue to outweigh
              risks Share/ Press release 11/10/2013/
            </Span>
          </A>
          <A id="group-one-3">
            <Span>3. </Span>
            <Span sx={{color: '#FFFFFF', fontFamily: 'SF-Pro-Text-Regular'}}>Lidegaard O, et al. BMJ.
              2012;344:e2990.</Span>
          </A>
          <A id="group-one-4">
            <Span>4. </Span>
            <Span sx={{color: '#FFFFFF', fontFamily: 'SF-Pro-Text-Regular'}}>Dinger J, et al. Front Women’s Health.
              2020;5:1–8</Span>
          </A>
          <A id="group-one-5">
            <Span>5. </Span>
            <Span sx={{color: '#FFFFFF', fontFamily: 'SF-Pro-Text-Regular'}}>
              Grandi G, et al. Eur J Contracept Reprod Health Care. 2022 Apr;27(2):83-84.
            </Span>
          </A>
        </WrapperShell>

        <WrapperShell sx={{pt: 4, pb: 4}}>
          <H1>
            Сравнение КОК с <Span sx={{color: Violet2}}>EE/LNG</Span> по влиянию<br/>на протромбиновый фрагмент 1+2
            (ПФ 1+2)
          </H1>
          <WrapperCouple>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pb: 2}}>
              <P sx={{color: PinkLight, mb: 2, mt: 0}}>Сравнение уровня ПФ 1+2 <br/>на фоне приёма&nbsp;
                <Span sx={{color: BlueDark, fontWeight: 'bold'}}>E4/DRSP</Span> и&nbsp;
                <AnchorLink href="#group-two-1">
                  <Span sx={{color: Violet2, fontWeight: 'bold'}}>EE/LNG</Span><Sup>1</Sup>
                </AnchorLink>
              </P>
              <Img src={images.compareChartOne}/>
            </WrapperShellBox>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <P sx={{color: PinkLight, mb: 2, mt: 0}}>Сравнение уровня ПФ 1+2 <br/>на фоне приёма&nbsp;
                <Span sx={{color: YellowDark, fontWeight: 'bold'}}>E2/NOMAC</Span> и&nbsp;
                <AnchorLink href="#group-two-2">
                  <Span sx={{color: Violet2, fontWeight: 'bold'}}>EE/LNG</Span><Sup>2</Sup>
                </AnchorLink>
              </P>
              <Img src={images.compareChartTwo}/>
            </WrapperShellBox>
          </WrapperCouple>

          <P sx={{mt: 5, mb: 2}}>
            <Span sx={{color: BlueDark}}>Е4/DRSP</Span> менее выраженно повышал риск тромбоза по сравнению с&nbsp;
            <AnchorLink href="#group-two-2"><Span sx={{color: Violet2}}>ЕЕ/LNG</Span><Sup>1</Sup></AnchorLink>
            <br/>
            <Span sx={{color: YellowDark, fontWeight: 'bold'}}>Е2/NOMAC</Span><AnchorLink href="#group-two-2"> СНИЖАЛ
            РИСК ТРОМБОЗА<Sup>2</Sup></AnchorLink>
          </P>
          <P>
            <AnchorLink href="#group-two-1"><Sup>1</Sup> открытое, рандомизированное исследование <br/></AnchorLink>
            <Sup>2</Sup> двойное слепое, рандомизированное исследование с параллельными группами
          </P>
          <P sx={{mt: 2, mb: 5}}>*КОК, имеющий низкий риск венозного тромбоза</P>

          <A id="group-two-1" href="https://pubmed.ncbi.nlm.nih.gov/32956694/">
            <Span>1. </Span>
            <Span sx={{textDecoration: 'underline'}}>
              Douxfils J, et al. Contraception. 2020 Dec;102(6):396-402.
            </Span>
          </A>
          <A id="group-two-2" href="https://pubmed.ncbi.nlm.nih.gov/21225090/">
            <Span>2. </Span>
            <Span sx={{textDecoration: 'underline'}}>
              Gaussem P, et al. Thromb Haemost. 2011 Mar;105(3):560-7.
            </Span>
          </A>
        </WrapperShell>

        <WrapperShell sx={{pt: 4, pb: 4}}>
          <H1>
            Сравнение КОК с <Span sx={{color: Violet2}}>EE/LNG</Span> по влиянию <br/>
            на резистентность к активированному протеину <br/>
            C (APCr)
          </H1>
          <WrapperCouple>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pb: 2}}>
              <P sx={{color: PinkLight, mb: 2, mt: 0}}>Сравнение увеличения уровня APCr (+%) <br/>на фоне приёма&nbsp;
                <Span sx={{color: BlueDark, fontWeight: 'bold'}}>E4/DRSP</Span> и&nbsp;
                <AnchorLink href="#group-three-1">
                  <Span sx={{color: Violet2, fontWeight: 'bold'}}>EE/LNG</Span><Sup>1</Sup>
                </AnchorLink>
              </P>
              <Img src={images.compareChartThree}/>
            </WrapperShellBox>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <P sx={{color: PinkLight, mb: 2, mt: 0}}>Сравнение увеличения уровня APCr (+%) <br/>на фоне приёма&nbsp;
                <Span sx={{color: YellowDark, fontWeight: 'bold'}}>E2/NOMAC</Span> и&nbsp;
                <AnchorLink href="#group-three-2">
                  <Span sx={{color: Violet2, fontWeight: 'bold'}}>EE/LNG</Span><Sup>2</Sup>
                </AnchorLink>
              </P>
              <Img src={images.compareChartFour}/>
            </WrapperShellBox>
          </WrapperCouple>

          <P sx={{mt: 5, mb: 2}}>
            На фоне приема
            <Span sx={{color: BlueDark}}>Е4/DRSP</Span> и&nbsp;
            <Span sx={{color: YellowDark}}>Е2/NOMAC</Span>&nbsp;
            уровень APCr был ниже, чем на фоне приёма&nbsp;
            <Span sx={{color: Violet2}}>ЕЕ/LNG</Span>
          </P>
          <P>
            <AnchorLink href="#group-three-1"><Sup>1</Sup> открытое, рандомизированное исследование <br/></AnchorLink>
            <AnchorLink href="#group-three-2"><Sup>2</Sup> двойное слепое, рандомизированное исследование с
              параллельными группами</AnchorLink>
          </P>
          <P sx={{mt: 2, mb: 5}}>*КОК, имеющий низкий риск венозного тромбоза</P>

          <A id="group-three-1" href="https://pubmed.ncbi.nlm.nih.gov/32956694/">
            <Span>1. </Span>
            <Span sx={{textDecoration: 'underline'}}>
              Douxfils J, et al. Contraception. 2020 Dec;102(6):396-402.
            </Span>
          </A>
          <A id="group-three-2" href="https://pubmed.ncbi.nlm.nih.gov/21225090/">
            <Span>2. </Span>
            <Span sx={{textDecoration: 'underline'}}>
              Gaussem P, et al. Thromb Haemost. 2011 Mar;105(3):560-7.
            </Span>
          </A>
        </WrapperShell>

        <WrapperShell sx={{pt: 4, pb: 4}}>
          <H1>
            Сравнение КОК с <Span sx={{color: Violet2}}>EE/LNG</Span> по влиянию на уровень <br/>
            D-димера
          </H1>

          <Notice sx={{mt: 5, mb: 5}}>
            <P sx={{mt: 1, color: '#FFFFFF', fontSize: 28, lineHeight: '33px'}}>
              Анализ данных 31199 человек показал, что&nbsp;
              <Span sx={{color: Violet2}}>повышенный уровень D-димера в 3,28 раз увеличивает риск ВТЭ</Span>&nbsp;
              в течение&nbsp;
              <AnchorLink href="#group-five-3">
                90 дней (p {`<`} 0,0001)<Sup>3</Sup>
              </AnchorLink>
            </P>
          </Notice>

          <WrapperCouple>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pb: 2}}>
              <P sx={{color: PinkLight, mb: 2, mt: 0}}>Сравнение уровня D-димера <br/>на фоне приёма&nbsp;
                <Span sx={{color: BlueDark, fontWeight: 'bold'}}>E4/DRSP</Span> и&nbsp;
                <AnchorLink href="#group-four-1">
                  <Span sx={{color: Violet2, fontWeight: 'bold'}}>EE/LNG</Span>
                  <Sup>1<Span sx={{fontSize: 12, color: PinkLight}}>*</Span></Sup>
                </AnchorLink>
              </P>
              <Img src={images.compareChartFive}/>
            </WrapperShellBox>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <P sx={{color: PinkLight, mb: 2, mt: 0}}>Сравнение уровня ПФ 1+2 <br/>на фоне приёма&nbsp;
                <Span sx={{color: YellowDark, fontWeight: 'bold'}}>E2/NOMAC</Span> и&nbsp;
                <AnchorLink href="#group-four-2">
                  <Span sx={{color: Violet2, fontWeight: 'bold'}}>EE/LNG</Span>
                  <Sup>2<Span sx={{fontSize: 12, color: PinkLight}}>*</Span></Sup>
                </AnchorLink>
              </P>
              <Img src={images.compareChartSix}/>
            </WrapperShellBox>
          </WrapperCouple>

          <P sx={{mt: 5, mb: 2}}>
            <Span sx={{color: BlueDark}}>Е4/DRSP</Span> менее выраженно повышал риск тромбоза по сравнению с&nbsp;
            <AnchorLink href="#group-four-1"><Span sx={{color: Violet2}}>ЕЕ/LNG</Span><Sup>1</Sup></AnchorLink>
            <br/>
            <Span sx={{color: YellowDark, fontWeight: 'bold'}}>Е2/NOMAC</Span> СНИЖАЛ РИСК <AnchorLink
            href="#group-four-2">ТРОМБОЗА<Sup>2</Sup></AnchorLink>
          </P>
          <P>
            <AnchorLink href="#group-four-1"><Sup>1</Sup> открытое, рандомизированное исследование <br/></AnchorLink>
            <AnchorLink href="#group-four-2"><Sup>2</Sup> двойное слепое, рандомизированное исследование с параллельными
              группами</AnchorLink>
          </P>
          <P sx={{mt: 2, mb: 5}}>*КОК, имеющий низкий риск венозного тромбоза</P>

          <A id="group-four-1" href="https://pubmed.ncbi.nlm.nih.gov/32956694/">
            <Span>1. </Span>
            <Span sx={{textDecoration: 'underline'}}>
              Douxfils J, et al. Contraception. 2020 Dec;102(6):396-402.
            </Span>
          </A>
          <A id="group-four-2" href="https://pubmed.ncbi.nlm.nih.gov/21225090/">
            <Span>2. </Span>
            <Span sx={{textDecoration: 'underline'}}>
              Gaussem P, et al. Thromb Haemost. 2011 Mar;105(3):560-7.
            </Span>
          </A>
        </WrapperShell>

        <WrapperShell sx={{pt: 4, pb: 4}}>
          <H1>
            Сопоставление <Span sx={{color: YellowDark, fontWeight: 'bold'}}>Е2/NOMAC</Span> и&nbsp;
            <Span sx={{color: BlueDark}}>Е4/DRSP</Span> с ЕЕ/LNG,&nbsp;
            который является эталонным КОК для оценки параметров гемостаза у новых&nbsp;
            <AnchorLink href="#group-five-1">КОК<Sup>1</Sup></AnchorLink>
          </H1>

          <Tables.WrapperTable sx={{mt: 7, mb: 3}}>
            <Tables.Table className="align-left small"
                          sx={{
                            fontSize: 14,
                            minWidth: 900,
                            lineHeight: '22px',
                            tableLayout: 'fixed'
                          }}>
              <Tables.Thead>
                <Tables.Tr sx={{color: PinkLight}}>
                  <Tables.Td rowSpan={2}>
                    <AnchorLink href="#group-five-2">Параметры гемостаза<Sup>2,3</Sup></AnchorLink>
                  </Tables.Td>
                  <Tables.Td rowSpan={2}>Нарушения гемостаза, патогномонич&shy;ные для приема КОК</Tables.Td>
                  <Tables.Td colSpan={3}
                             sx={{fontSize: 20, textAlign: 'center!important', verticalAlign: 'middle!important'}}>
                    <AnchorLink href="#group-five-4">E2/NOMAC vs EE/LNG<Sup>4,5</Sup></AnchorLink>
                  </Tables.Td>
                  <Tables.Td colSpan={3}
                             sx={{fontSize: 20, textAlign: 'center!important', verticalAlign: 'middle!important'}}>
                    <AnchorLink href="#group-five-6">E4/DRSP vs EE/LNG6<Sup>6</Sup></AnchorLink>
                  </Tables.Td>
                </Tables.Tr>
                <Tables.Tr sx={{color: PinkLight}}>
                  <Tables.Td>E2/NOMAC</Tables.Td>
                  <Tables.Td>EE/LNG</Tables.Td>
                  <Tables.Td>Достоверность</Tables.Td>
                  <Tables.Td>E4/DRSP</Tables.Td>
                  <Tables.Td>EE/LNG</Tables.Td>
                  <Tables.Td sx={{height: 42}}>Достоверность</Tables.Td>
                </Tables.Tr>
              </Tables.Thead>
              <Tables.Body>
                <Tables.Tr sx={{backgroundColor: GrayLight}}>
                  <Tables.Td rowSpan={5}>Прокоагулянт&shy;ные зимогены</Tables.Td>
                </Tables.Tr>
                <Tables.Tr sx={{backgroundColor: GrayLight}}>
                  <Tables.Td>↑ Фибриноген</Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p=0,112</Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p{`>`}0,05</Tables.Td>
                </Tables.Tr>
                <Tables.Tr sx={{backgroundColor: GrayLight}}>
                  <Tables.Td>↑ Протромбин (II)</Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p=0,5</Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p{`>`}0,05</Tables.Td>
                </Tables.Tr>
                <Tables.Tr sx={{backgroundColor: GrayLight}}>
                  <Tables.Td>↑ Фактор VII</Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p=0,42</Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p{`>`}0,05</Tables.Td>
                </Tables.Tr>
                <Tables.Tr sx={{backgroundColor: GrayLight}}>
                  <Tables.Td>↑ Фактор VIII</Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p{`>`}0,05</Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p{`>`}0,05</Tables.Td>
                </Tables.Tr>

                <Tables.Tr>
                  <Tables.Td rowSpan={4}>Ингибиторы коагуляции</Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>↓ антитромбин (III)</Tables.Td>
                  <Tables.Td sx={{color: GreenLight}}>+0,2%</Tables.Td>
                  <Tables.Td>-4,2%</Tables.Td>
                  <Tables.Td>p{`<`}0,001 </Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p{`>`}0,05</Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>↓ ингибитор пути тканевого фактора (TFPI)</Tables.Td>
                  <Tables.Td>-19%</Tables.Td>
                  <Tables.Td sx={{color: RedLight}}>-29%</Tables.Td>
                  <Tables.Td>p=0,003</Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p{`>`}0,05</Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>↑ резистентность к активированн&shy;ому протеину C</Tables.Td>
                  <Tables.Td>+19,8%</Tables.Td>
                  <Tables.Td sx={{color: RedLight}}>+42%</Tables.Td>
                  <Tables.Td>p{`<`}0,01 </Tables.Td>
                  <Tables.Td>+30%</Tables.Td>
                  <Tables.Td sx={{color: RedLight}}>+165%</Tables.Td>
                  <Tables.Td>p{`>`}0,05</Tables.Td>
                </Tables.Tr>

                <Tables.Tr sx={{backgroundColor: GrayLight}}>
                  <Tables.Td rowSpan={3}>Маркеры формирования тромбина</Tables.Td>
                </Tables.Tr>
                <Tables.Tr sx={{backgroundColor: GrayLight}}>
                  <Tables.Td>↑ протромбино&shy;вый фрагмент F1+2</Tables.Td>
                  <Tables.Td sx={{color: GreenLight}}>-3,7%</Tables.Td>
                  <Tables.Td>+16%</Tables.Td>
                  <Tables.Td>p{`<`}0,01 </Tables.Td>
                  <Tables.Td>+23%</Tables.Td>
                  <Tables.Td sx={{color: RedLight}}>+71%</Tables.Td>
                  <Tables.Td>p{`<`}0,05</Tables.Td>
                </Tables.Tr>
                <Tables.Tr sx={{backgroundColor: GrayLight}}>
                  <Tables.Td>↑ D-димер</Tables.Td>
                  <Tables.Td sx={{color: GreenLight}}>-21%</Tables.Td>
                  <Tables.Td sx={{color: RedLight}}>+19%</Tables.Td>
                  <Tables.Td>p{`<`}0,001</Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>?</Tables.Td>
                </Tables.Tr>

                <Tables.Tr>
                  <Tables.Td rowSpan={3}>Нарушения фибринолиза</Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>↓ активность PAI-1</Tables.Td>
                  <Tables.Td>-21%</Tables.Td>
                  <Tables.Td sx={{color: RedLight}}>-58%</Tables.Td>
                  <Tables.Td>p{`<`}0,001 </Tables.Td>
                  <Tables.Td colSpan={2}>сопоставимо</Tables.Td>
                  <Tables.Td>p{`<`}0,05</Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td> ↑ плазминоген</Tables.Td>
                  <Tables.Td>+6,3%</Tables.Td>
                  <Tables.Td sx={{color: RedLight}}>+32%</Tables.Td>
                  <Tables.Td>p{`<`}0,0001</Tables.Td>
                  <Tables.Td>+12%</Tables.Td>
                  <Tables.Td sx={{color: RedLight}}>+40%</Tables.Td>
                  <Tables.Td>?</Tables.Td>
                </Tables.Tr>
              </Tables.Body>
            </Tables.Table>
          </Tables.WrapperTable>

          <Tables.WrapperTable sx={{mt: 7, mb: 7}}>
            <Tables.Table className="align-left"
                          sx={{
                            fontSize: 14,
                            minWidth: 900,
                            lineHeight: '22px',
                            tableLayout: 'fixed'
                          }}>
              <Tables.Thead>
                <Tables.Tr sx={{fontSize: 20}}>
                  <Tables.Td sx={{color: PinkLight}}>Критерии сравнения</Tables.Td>
                  <Tables.Td sx={{color: YellowDark, fontWeight: 'bold'}}>E2/NOMAC</Tables.Td>
                  <Tables.Td sx={{color: BlueDark, fontWeight: 'bold'}}>E4/DRSP</Tables.Td>
                </Tables.Tr>
              </Tables.Thead>
              <Tables.Body>
                <Tables.Tr>
                  <Tables.Td>Качество данных</Tables.Td>
                  <Tables.Td>
                    <UL sx={{m: 0}}>
                      <LI>На основании 2-х сравнительных исследований с участием 211 <AnchorLink
                        href="#group-five-4">женщин<Sup>4,5</Sup></AnchorLink></LI>
                      <LI>Оценивалась достоверность всех параметров</LI>
                    </UL>
                  </Tables.Td>
                  <Tables.Td>
                    <UL sx={{m: 0}}>
                      <LI>На основании 1 исследования с участием 91 <AnchorLink
                        href="#group-five-6">женщины<Sup>6</Sup></AnchorLink></LI>
                      <LI>В публикации, имеющейся в общем доступе, указаны не все данные, неизвестна достоверность всех
                        данных</LI>
                    </UL>
                  </Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>Более благоприятное действие на параметры гемостаза по сравнению с EE/LNG</Tables.Td>
                  <Tables.Td>
                    <UL sx={{m: 0}}>
                      <LI>ингибитор пути тканевого фактора (TFPI)</LI>
                      <LI>резистентность к активированному протеину C</LI>
                      <LI>активность PAI-1</LI>
                      <LI>уровень плазминогена</LI>
                      <LI>протромбиновый фрагмент F1+2</LI>
                      <LI>D-димер</LI>
                    </UL>
                  </Tables.Td>
                  <Tables.Td>
                    <UL sx={{m: 0}}>
                      <LI>резистентность к активированному протеину C</LI>
                      <LI>протромбиновый фрагмент F1+2</LI>
                      <LI>уровень плазминогена</LI>
                    </UL>
                  </Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>Более благоприятное действие на параметры гемостаза по сравнению с EE/LNG</Tables.Td>
                  <Tables.Td>
                    <UL sx={{m: 0}}>
                      <LI>Сопоставимое действие по сравнению с EE/LNG</LI>
                    </UL>
                  </Tables.Td>
                  <Tables.Td>
                    <UL sx={{m: 0}}>
                      <LI>прокоагулянтные зимогены</LI>
                      <LI>антитромбин (III)</LI>
                      <LI>ингибитор пути тканевого фактора (TFPI)</LI>
                      <LI>D-димер</LI>
                      <LI>активность PAI-1</LI>
                    </UL>
                  </Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>
                    Реальный риск ВТЭ (количество инцидентов ВТЭ на 10000 <AnchorLink
                    href="#group-five-1">женщин в год)<Sup>1</Sup></AnchorLink>
                  </Tables.Td>
                  <Tables.Td>
                    <P sx={{fontSize: 14, mt: 0}}>
                      Риск ВТЭ у не использующих <AnchorLink href="#group-five-7">КОК<Sup>7-9</Sup></AnchorLink> и&nbsp;
                      принимающих E2/NOMAC был&nbsp;
                      <AnchorLink href="#group-five-1">сопоставим<Sup>1</Sup></AnchorLink> : 2 инцидента&nbsp;
                      ВТЭ на 10000 женщин в год.
                    </P>
                    <P sx={{fontSize: 14, mt: 1}}>
                      По мнению исследователей, возможно значительное снижение тромботического риска с
                      E2-содержащими&nbsp;
                      <AnchorLink href="#group-five-1">КОК<Sup>1</Sup></AnchorLink>.
                    </P>
                  </Tables.Td>
                  <Tables.Td>
                    Вероятно, потребуется еще десятилетие, чтобы получить результаты постмаркетинговых исследований
                    риска ВТЭ при приеме&nbsp;
                    <AnchorLink href="#group-five-1">эстетрола (E4)<Sup>1</Sup></AnchorLink>.
                  </Tables.Td>
                </Tables.Tr>
              </Tables.Body>
            </Tables.Table>
          </Tables.WrapperTable>

          <H1>ВЫВОДЫ</H1>

          <Notice>
            <div>
              <P sx={{fontSize: 28, lineHeight: '33px'}}>
                <Span sx={{color: YellowDark, fontWeight: 'bold'}}>Е2/NOMAC</Span> в 3-х группах 7-ми параметров
                гемостаза <br/>
                <Span sx={{color: YellowDark, fontWeight: 'bold'}}>превосходил EE/LNG,</Span> за исключением
                группы <br/>
                прокоагулянтных зимогенов, действие E2/NOMAC и EE/LNG на которые было сопоставимым.
              </P>
              <P sx={{fontSize: 28, lineHeight: '34px', m: '12px 0'}}>
                <Span sx={{color: YellowDark, fontWeight: 'bold'}}>Риск ВТЭ для E2/NOMAC: 2 инцидента</Span> ВТЭ на 10
                000 женщин в год.
              </P>
              <P sx={{fontSize: 28, lineHeight: '34px'}}>
                <Span sx={{color: Violet2, fontWeight: 'bold'}}>Риск ВТЭ для EE/LNG: 5-7 инцидентов</Span> на 10 000
                здоровых женщин в год.
              </P>
            </div>
          </Notice>
          <Notice sx={{mt: '20px', mb: 5}}>
            <P sx={{fontSize: 28, lineHeight: '33px'}}>
              <Span sx={{color: BlueDark, fontWeight: 'bold'}}>E4/DRSP превосходил EE/LNG</Span> только в 3-х параметрах
              гемостаза: резистентность к активированному протеину C, протромбиновый фрагмент F1+2, плазминоген, в
              остальных 8-ми параметрах гемостаза действие Е4/DRSP и ЕЕ/LNG было сопоставимо.
            </P>
          </Notice>

          <A id="group-five-1" href="https://pubmed.ncbi.nlm.nih.gov/35133236/" sx={{mb: '12px'}}>
            <Span>1. </Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Grandi G, Facchinetti F, Bitzer J. Confirmation of the safety of combined oral contraceptives containing
              oestradiol on the risk of venous thromboembolism. Eur J Contracept Reprod Health Care. 2022
              Apr;27(2):83-84.
            </Span>
          </A>
          <A id="group-five-2" href="https://pubmed.ncbi.nlm.nih.gov/25635172/" sx={{mb: '12px'}}>
            <Span>2. </Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Lipets, E.N., Ataullakhanov, F.I. Global assays of hemostasis in the diagnostics of hypercoagulation and
              evaluation of thrombosis risk. Thrombosis J 13, 4 (2015).
            </Span>
          </A>
          <A id="group-five-3" href="https://pubmed.ncbi.nlm.nih.gov/26007631/" sx={{mb: '12px'}}>
            <Span>3. </Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Lete I, et al. Haemostatic and metabolic impact of estradiol pills and drospirenone-containing
              ethinylestradiol pills vs. levonorgestrel-containing ethinylestradiol pills: A literature review. Eur J
              Contracept Reprod Health Care. 2015;20(5):329-43.
            </Span>
          </A>
          <A id="group-five-4" href="https://pubmed.ncbi.nlm.nih.gov/21225090/" sx={{mb: '12px'}}>
            <Span>4. </Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Gaussem P, et al. Thromb Haemost. 2011 Mar;105(3):560-7.
            </Span>
          </A>
          <A id="group-five-5" href="https://pubmed.ncbi.nlm.nih.gov/22066891/" sx={{mb: '12px'}}>
            <Span>5. </Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Ågren UM, et al. Eur J Contracept Reprod Health Care. 2011 Dec;16(6):444-57.
            </Span>
          </A>
          <A id="group-five-6" href="https://pubmed.ncbi.nlm.nih.gov/32956694/" sx={{mb: '12px'}}>
            <Span>6. </Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Douxfils J, et al. Contraception. 2020 Dec;102(6):396-402.
            </Span>
          </A>
          <A id="group-five-7" sx={{mb: '12px'}}>
            <Span>7. </Span>
            <Span sx={{ml: '4px', color: '#FFFFFF', fontFamily: 'SF-Pro-Text-Regular'}}>
              Lidegaard O, et al. BMJ. 2012;344:e2990.
            </Span>
          </A>
          <A id="group-five-8" sx={{mb: '12px'}}>
            <Span>8. </Span>
            <Span sx={{ml: '4px', color: '#FFFFFF', fontFamily: 'SF-Pro-Text-Regular'}}>
              Dinger J, et al. Front Women’s Health. 2020;5:1–8.
            </Span>
          </A>
          <A
            id="group-five-9"
            href="https://www.ema.europa.eu/en/news/prac-confirms-benefits-all-combined-hormonal-contraceptives-chcs-continue-outweigh-risks"
            sx={{mb: '12px'}}>
            <Span>9. </Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              PRAC confirms that benefits of all combined hormonal contraceptives (CHCs) continue to outweigh risks
              Share/ Press release 11/10/2013/
            </Span>
          </A>
        </WrapperShell>
      </Div>
    </Page>
  )
}
