import { useHistory } from 'react-router-dom';
import { StyledBoxBorder, StyledBoxShadow, H1, H2, Div, Paragraph, StyledLink, StyledLinkNavigate } from './ui';

import { Box } from '@mui/material';
import Page from 'shared/ui/Page';

import img2 from './img/img2.png';
import img1 from './img/img1.png';
import videoVector from './img/video-icon.svg';
import photoVector from './img/photo-icon.svg';

export const Conference = () => {
  const history = useHistory();

  const handleRedirectToProducts = () => {
    history.push('/');
  };

  return (
    <Page head={'КОНФЕРЕНЦИИ И КОНГРЕССЫ'} backText="Продукты" onClose={handleRedirectToProducts}>

      <StyledBoxShadow sx={{mt: 11, paddingBottom: '10px' }}>
        <H1 sx={{ margin: '20px 0 10px' }}>
          XVIII Общероссийский научно-практический семинар «Репродуктивный потенциал России: версии и контраверсии»
        </H1>
        <Div sx={{ fontSize: 24, fontWeight: 'normal', textTransform: 'uppercase', margin: '10px 0 20px' }}>06—09
          сентября 2024, сочи</Div>
        <StyledLinkNavigate target="_blank" rel='noopener noreferrer' to={{ pathname: "https://praesens.ru/events/all-R/aig/sochi/" }} >
          Сайт конференции
        </StyledLinkNavigate>

        <Box sx={{ margin: '40px 0' }}>
          <img src={img2} alt="conference" width='100%' />
        </Box>

        <H2 sx={{ margin: '20px 0' }}>
          О конференции
        </H2>

        <Paragraph>
          C 6 по 9 сентября 2024 года в Сочи прошёл XVIII Общероссийский семинар «Репродуктивный потенциал России:
          версии и контраверсии», Всероссийская научно-практическая конференция и выставка «Главврач XXI века».
        </Paragraph>
        <Paragraph>
          Выставка «Главврач XXI века» — масштабное и долгожданное мероприятие для специалистов репродуктивного
          здоровья, на котором ведущие отечественные и зарубежные компании каждый год представляют новую и уже
          зарекомендовавшую себя на рынке продукцию для женского и мужского здоровья. Посетив выставку, вы окунётесь в
          мир новинок репродуктивного здоровья и реальных инструментов работы практикующего врача, познакомитесь с
          актуальными клиническими исследованиями и рекомендациями, обновлёнными инструкциями и правилами работы.
        </Paragraph>

        <StyledBoxBorder sx={{ mt: '34px' }}>
          <H2 sx={{ margin: '0 0' }}>
            Презентация видео- и фото-отчета
          </H2>
          <Paragraph>
            Уважаемый доктор!
          </Paragraph>
          <Paragraph>
            Хотим поделиться с Вами этим видео с конгресса Радзинского в Сочи! Мы создали арт-галерею своих оригинальных
            препаратов Женского здоровья по всему пространству конгресса В ФОРМАТЕ ВЫСТАВКИ
          </Paragraph>

          <Paragraph>
            Именно так мы решили напомнить всем врачам, что у наших самых лучших в мире женщин должны быть самые лучшие
            препараты и самые лучшие врачи рядом! Давайте объединяться вокруг нашего портфеля продуктов во имя здоровья
            русских женщин!
          </Paragraph>

          <Paragraph sx={{ mb: 0, fontWeight: 'bold' }}>
            Вы с нами? Запустить видео!
          </Paragraph>
        </StyledBoxBorder>

        <Box sx={{ display: 'flex', gap: 3, margin: '42px 0 52px' }}>
          <StyledLink href="/video/Видео/bmrZHpF2Jsg">
            <img className="svg" width={24} src={videoVector} />
            Видео #1
          </StyledLink>
          <StyledLink href="/video/Видео/nvJewADH3o8">
            <img className="svg" width={24} src={videoVector} />
            Видео #2
          </StyledLink>
          <StyledLink href="/video/Видео/afqNcqzqjJA">
            <img className="svg" width={24} src={videoVector} />
            Видео #3
          </StyledLink>
          <StyledLink href="https://disk.yandex.ru/d/E1WZ9reFEwrCag">
            <img className="svg" width={24} src={photoVector} />
            Фото-отчет
          </StyledLink>
        </Box>
      </StyledBoxShadow>

      <StyledBoxShadow sx={{ mt: '20px', paddingBottom: '10px', mb: '150px' }}>
        <H1 sx={{ margin: '20px 0 10px' }}>
          XXXIV Ежегодная международная конференция РАРЧ «Репродуктивные технологии сегодня и завтра»
        </H1>
        <Div sx={{ fontSize: 24, fontWeight: 'normal', textTransform: 'uppercase', margin: '10px 0 20px' }}>
          4—7 сентября 2024, Екатеринбург
        </Div>
        <StyledLinkNavigate target="_blank" rel='noopener noreferrer' to={{ pathname: "https://praesens.ru/events/all-R/aig/sochi/" }} >
          Сайт конференции
        </StyledLinkNavigate>

        <Box sx={{ margin: '40px 0' }}>
          <img src={img1} alt="conference" width='100%' />
        </Box>

        <H2 sx={{ margin: '20px 0' }}>
          О мероприятии
        </H2>

        <Paragraph>
          С 4 по 7 сентября в Екатеринбурге прошла ежегодная международная конференция Российской Ассоциации Репродукции
          Человека (РАРЧ), ставшая ключевым событием в сфере репродуктивной медицины. Мероприятие собрало ведущих
          специалистов со всего мира, объединив ученых, эмбриологов, репродуктологов, профессионалов, занимающихся
          вопросами репродуктивного здоровья.
        </Paragraph>

        <StyledBoxBorder sx={{ mt: '34px' }}>
          <H2 sx={{ margin: '0 0' }}>
            Презентация видео-отчета
          </H2>
          <Paragraph>
            Уважаемый доктор!
          </Paragraph>
          <Paragraph>
            ПРОГИНОВА, как главный продукт в поддержке и развитии нацпроекта «ДЕМОГРАФИЯ» не могла остаться в стороне и
            приняла особое участие в конференции репродуктологов в Екатеринбурге.
          </Paragraph>

          <Paragraph>
            ПРОГИНОВА не только помогает в безнадёжных клинических ситуациях (продукт, без которого не обойтись в ВРТ и
            гинекологической эндокринологии), но и способна вызывать такие сильные эмоции у врачей как самый главный
            продукт, без которого многие женщины не услышали бы главное слово "МАМА!".
          </Paragraph>

          <Paragraph>
            На конференции мы установили ролл-апы с национальной программой «Нам есть, куда расти!», ролл-апы ПРОГИНОВА
            в зонах кофе-брейков, пробрендировали автобусы, которые привозили делегатов на конференцию, провели лотерею,
            все материалы и призы которой были от имени ПРОГИНОВЫ.
          </Paragraph>

          <Paragraph>
            Ну и конечно, стали генеральными спонсорами Гала-ужина.
          </Paragraph>

          <Paragraph>
            За главным столом #1 на Гала ужине Академик Адамян, Академик Корсак, вице-президент РАРЧ Смирнова А.А.,
            которая первой разрезала наш торт ПРОГИНОВА!
          </Paragraph>

          <Paragraph>
            Видео с нашей ПРОГИНОВОЙ сразу появились в группах врачей, о чём нам начали сообщать гинекологи из других
            городов!
          </Paragraph>

          <Paragraph sx={{ mb: 0, fontWeight: 'bold' }}>
            Нам есть, чем гордиться!
          </Paragraph>
        </StyledBoxBorder>

        <Box sx={{ display: 'flex', gap: 3, margin: '42px 0 52px' }}>
          <StyledLink href="/video/Видео/S-lj_3F6WKc">
            <img className="svg" width={24} src={videoVector} />
            Видео
          </StyledLink>
        </Box>
      </StyledBoxShadow>

    </Page>
  );
};
