import {useHistory} from 'react-router-dom';
import {styled} from '@mui/system';

import {WrapperShell} from 'components/wrapper-shell';
import {WrapperCouple} from 'components/wrapper-couple';
import Page from 'shared/ui/Page';
import {List as UL, ListItem as LI, Tables} from 'shared/ui';
import {PinkLight} from 'styles/color';

import {H2 as Header2, H1 as Header1, Paragraph as P, LinkA} from './ui';
import {Span, Div} from '../ui';
import pdf from 'pdfs/alvovizan/Эндометриоз диагностика  лечение и реабилитация  Клинические рекомендации Москва  2013  86 с.pdf';

const H2 = styled(Header2)({
  color: PinkLight,
  textTransform: 'uppercase'
})

const H1 = styled(Header1)({
  color: PinkLight,
})

const A = styled(LinkA)({
  display: 'block',
  margin: '10px auto',
  letterSpacing: '0.1px',
  fontSize: 17,
  fontFamily: 'SF-Pro-Text-Semibold',
  textDecoration: 'none',
})

export const AlvovizanInstruction = () => {
  const history = useHistory();

  const handlerRedirectToDiecyclen = () => {
    history.push('/product/klomonorm');
  }

  return (
    <Page onClose={handlerRedirectToDiecyclen} isCross>
      <Div sx={{textAlign: 'center'}}>
        <H2 sx={{fontSize: 20, mb: 3}}>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</H2>
        <H1>ИНСТРУКЦИЯ</H1>
        <H2 sx={{fontSize: 20, textTransform: 'none', mb: 3, mt: 2}}>по применению лекарственного препарата для
          медицинского применения</H2>
        <H1 sx={{mb: 3}}>АЛВОВИЗАН</H1>
      </Div>
      <H2 sx={{fontSize: 20}}>РЕГИСТРАЦИОННЫЙ НОМЕР:</H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ТОРГОВОЕ НАИМЕНОВАНИЕ: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>Алвовизан</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        МЕЖДУНАРОДНОЕ НЕПАТЕНТОВАННОЕ НАИМЕНОВАНИЕ: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>
        диеногест микронизированный</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ЛЕКАРСТВЕННАЯ ФОРМА: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>таблетки, покрытые
        пленочной оболочкой</Span>
      </H2>

      <H2 style={{marginTop: 50}}>СОДЕРЖАНИЕ</H2>
      <WrapperCouple sx={{display: 'flex', justifyContent: 'space-between'}}>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#compound'}>СОСТАВ</A>
          <A href={'#description'}>ОПИСАНИЕ</A>
          <A href={'#farm-group'}>ФАРМАКОТЕРАПЕВТИЧЕСКАЯ ГРУППА</A>
          <A href={'#farm-property'}>ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА</A>
          <A href={'#ind-for-use'}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ</A>
          <A href={'#dication'}>ПРОТИВОПОКАЗАНИЯ</A>
          <A href={'#warning'}>С ОСТОРОЖНОСТЬЮ</A>
          <A href={'#for-use-feed'}>ПРИМЕНЕНИЕ В ПЕРИОД БЕРЕМЕННОСТИ И ГРУДНОГО<br/> ВСКАРМЛИВАНИЯ</A>
          <A href={'#dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</A>
          <A href={'#effect-action'}>ПОБОЧНОЕ ДЕЙСТВИЕ</A>
          <A href={'#over-dozing'}>ПЕРЕДОЗИРОВКА</A>
        </WrapperShell>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#another-medicines'}>ВЗАИМОДЕЙСТВИЕ С ДРУГИМИ ЛЕКАРСТВЕННЫМИ <br/> СРЕДСТВАМИ</A>
          <A href={'#special-instructions'}>ОСОБЫЕ УКАЗАНИЯ</A>
          <A href={'#managment-transport'}>ВЛИЯНИЕ НА СПОСОБНОСТЬ УПРАВЛЕНИЯ <br/>ТРАНСПОРТНЫМИ СРЕДСТВАМИ,
            МЕХАНИЗМАМИ</A>
          <A href={'#form-production'}>ФОРМА ВЫПУСКА</A>
          <A href={'#keeper'}>УСЛОВИЯ ХРАНЕНИЯ</A>
          <A href={'#deadline'}>СРОК ГОДНОСТИ</A>
          <A href={'#holiday'}>УСЛОВИЯ ОТПУСКА</A>
          <A href={'#owner'}>ВЛАДЕЛЕЦ (ДЕРЖАТЕЛЬ) РЕГИСТРАЦИОННОГО УДОСТОВЕРЕНИЯ</A>
          <A href={'#producer'}>ПРОИЗВОДИТЕЛЬ</A>
        </WrapperShell>
      </WrapperCouple>

      <H2 id={'compound'}>СОСТАВ</H2>
      <P>1 таблетка, покрытая пленочной оболочкой содержит:</P>
      <P><b>Действующее вещество:</b> диеногест микронизированный — 2,0 мг;</P>
      <P><b>Вспомогательные вещества:</b> лактозы моногидрат — 57,2 мг, крахмал кукурузный — 12,0 мг, повидон К30 — 3,0
        мг, карбоксиметилкрахмал натрия (натрия крахмал гликолят), тип А — 5,0 мг, магния стеарат — 0,8 мг.</P>
      <P>Пленочная оболочка:</P>
      <P>АкваПолиш белый 014.17MS — 3,0 мг (гипромеллоза (Е464) — 1,44 мг, гипролоза (гидроксипропилцеллюлоза) (Е463) —
        0,36 мг, тальк (Е 553b) — 0,60 мг, хлопковое масло гидрогенизированное — 0,15 мг, титана диоксид (Е 171) — 0,45
        мг).</P>

      <H2 id={'description'}>ОПИСАНИЕ</H2>
      <P>Круглые двояковыпуклые таблетки, покрытые пленочной оболочкой белого цвета, с гравировкой «2» на одной стороне.
        На поперечном разрезе - ядро и пленочная оболочка белого цвета.</P>

      <H2 id={'farm-group'}>Фармакотерапевтическая группа:</H2>
      <P>Гестаген</P>
      <P><b>Код ATX:</b> G03DB08</P>

      <H2 id={'farm-property'}>Фармакологические свойства</H2>
      <P><b>Фармакодинамика</b></P>
      <P>Диеногест - является производным нортестостерона, характеризуется антиандрогенной активностью, составляющей
        примерно одну треть активности ципротерона ацетата. Диеногест связывается с рецепторами прогестерона в матке
        женщины, обладая лишь 10% относительного сродства к рецепторам прогестерона. Несмотря на низкое сродство к
        рецепторам прогестерона, диеногест обладает мощным прогестагенным эффектом in vivo. Диеногест не обладает
        значимой андрогенной, минералокортикоидной или глюкокортикоидной активностью in vivo.</P>
      <P>Диеногест воздействует на эндометриоз, за счет подавления трофических эффектов эстрадиола в отношении
        эутопического и эктопического эндометрия, вследствие снижения продукции эстрогенов в яичниках и уменьшения их
        концентрации в плазме.</P>
      <P>При продолжительном применении вызывает начальную децидуализацию ткани эндометрия с последующей атрофией
        эндометриоидных очагов. Другие фармакологические свойства диеногеста, такие как иммуномодулирующее и
        антиангиогенное, вероятно способствуют его подавляющему воздействию на пролиферацию клеток.</P>
      <P>Преимущество диеногеста по сравнению с плацебо в отношении тазовой боли, ассоциированной с эндометриозом, было
        продемонстрировано у 198 пациенток в клиническом исследовании продолжительностью 3 месяца. Тазовую боль,
        ассоциированную с эндометриозом, оценивали по визуальной аналоговой шкале (ВАШ, 0-100 мм). После 3 месяцев
        лечения диеногестом было показано статистически значимое различие с плацебо (А = 12,3 мм; 95% ДИ: 6,4- 18,1;
        р`{'<'}`0,0001), а также клинически значимое уменьшение боли по сравнению с исходными показателями (среднее
        уменьшение = 27,4 мм ± 22,9).</P>
      <P>Через 3 месяца лечения у 37,3% пациенток отмечено уменьшение интенсивности тазовой боли, ассоциированной с
        эндометриозом, на 50% или более без соответствующего увеличения дозы дополнительного обезболивающего средства,
        которое они принимали (в группе плацебо: у 19,8%); у 18,6% пациенток отмечено уменьшение интенсивности тазовой
        боли, ассоциированной с эндометриозом, на 75% или более без повышения дозы дополнительного обезболивающего
        средства, которое они принимали (плацебо: 7,3%).</P>
      <P>В продленной открытой фазе данного плацебо-контролируемого исследования наблюдалось устойчивое уменьшение
        тазовой боли, ассоциированной с эндометриозом, при продолжительности лечения до 15 месяцев.</P>
      <P>Результаты плацебо-контролируемой части исследования подтверждались результатами, полученными в исследовании с
        группой активного контроля (прием агониста гонадотропин- рилизинг гормона (ГнРГ)) продолжительностью 6 месяцев у
        252 пациенток с эндометриозом.</P>
      <P>В трех исследованиях, в которых в общей сложности приняло участие 252 пациентки, получавших суточную дозу
        диеногеста 2 мг, было продемонстрировано существенное уменьшение эндометриоидных очагов через 6 месяцев
        лечения.</P>
      <P>В небольшом исследовании (n=8 в каждой дозовой группе) показано, что диеногест в суточной дозе 1 мг через 1
        месяц вызывал развитие ановуляторного статуса. Изучение контрацептивной эффективности диеногеста в более крупных
        исследованиях не проводилось.</P>
      <P>В период терапии диеногестом отмечается умеренное снижение концентрации эндогенных эстрогенов. В настоящее
        время отсутствуют данные долгосрочного исследования минеральной плотности костей (МПК) и риска переломов при
        приеме диеногеста.</P>
      <P>МПК оценивалась у 21 взрослой пациентки до начала лечения и через 6 месяцев применения препарата, снижения
        среднего показателя МПК отмечено не было. После такого же периода лечения лейпрорелина ацетатом (ЛА) у 29
        пациенток отмечено снижение МПК на 4,04% ± 4,84 (А между группами = 4,29%; 95% ДИ: 1,93 - 6,66; р0,0003).</P>
      <P>Во время применения препарата Алвовизан продолжительностью до 15 месяцев значительного влияния препарата на
        стандартные лабораторные параметры, включая гематологию, химический состав крови, показатели ферментов печени,
        липидов и гликированного гемоглобина, не наблюдалось.</P>
      <P>В исследовании продолжительностью 12 месяцев, в котором приняли участие 111 пациенток подросткового возраста, у
        103 пациенток среднее относительное изменение показателя МПК поясничного отдела позвоночника (позвонки L2 — L4)
        по сравнению с исходным показателем составило - 1,2%. Через 6 месяцев после окончания лечения в рамках периода
        продолженного наблюдения у группы пациенток, у которых наблюдалось снижение МПК, данный параметр снова был
        измерен, и анализ показал повышение уровня МПК в сторону исходного показателя до уровня — 0,6%.</P>
      <P>Доклинические данные, полученные в ходе стандартных исследований фармакологической безопасности, токсичности
        при многократном введении доз, генотоксичности, канцерогенного потенциала и токсичности для репродуктивной
        системы, не указывают на существование специфического риска для человека. Однако следует учитывать, что половые
        гормоны способны стимулировать рост ряда гормонозависимых тканей и опухолей.
      </P><P><b>Фармакокинетика</b></P>
      <P><b><u>Абсорбция</u></b></P>
      <P>После перорального применения диеногест быстро и практически полностью всасывается.</P>
      <P>Максимальная концентрация в плазме крови, составляющая 47 нг/мл, достигается примерно через 1,5 ч после
        разового перорального приема. Биодоступность составляет около 91%. Фармакокинетика диеногеста в дозовом
        диапазоне от 1 до 8 мг характеризуется дозозависимостью.</P>
      <P><b><u>Распределение</u></b></P>
      <P>Диеногест связывается с альбумином в плазме и не связывается с глобулином, связывающим половые гормоны (ГСПГ),
        а также с кортикоид-связывающим глобулином (КСГ). 10% от общей концентрации вещества в плазме крови находится в
        виде свободного стероида, тогда как около 90% неспецифически связано с альбумином.</P>
      <P>Кажущийся объем распределения (Vd/F) диеногеста составляет 40 л. Метаболизм</P>
      <P>Диеногест почти полностью метаболизируется преимущественно путем гидроксилирования с образованием нескольких
        практически неактивных метаболитов. Исходя из результатов исследований in vitro и in vivo, основным ферментом,
        участвующим в метаболизме диеногеста, является CYP3A4. Метаболиты выводятся очень быстро, так что преобладающей
        фракцией в плазме крови является неизмененный диеногест.</P>
      <P>Скорость метаболического клиренса (C1/F) из плазмы крови составляет 64 мл/мин. Элиминация</P>
      <P>Концентрация диеногеста в плазме крови снижается двухфазно. Период полувыведения в терминальной фазе составляет
        приблизительно 9-10 часов. После приема внутрь в дозе 0,1 мг/кг диеногест выводится в виде метаболитов почками и
        через кишечник в соотношении примерно 3:1. Период полувыведения метаболитов почками составляет 14 ч.</P>
      <P>После перорального применения приблизительно 86% полученной дозы выводится в течение 6 дней; причем основная
        часть этого количества выводится за первые 24 часа, преимущественно почками.</P>
      <P><b><u>Равновесная концентрация</u></b></P>
      <P>Фармакокинетика диеногеста не зависит от уровня ГСПГ. Концентрация диеногеста в плазме крови после ежедневного
        применения возрастает примерно в 1,24 раза, достигая равновесной концентрации через 4 дня приема.
        Фармакокинетика диеногеста после многократного применения препарата Алвовизан может быть спрогнозирована на
        основе фармакокинетики после разового применения.</P>
      <P><b><u>Фармакокоинетика в особых клинических группах пациенток</u></b></P>
      <P>Исследований фармакокинетики диеногеста у пациенток с нарушением функции почек не проводилось.</P>
      <P>Целенаправленное исследование диеногеста при почечной недостаточности не проводилось. Фармакокинетика
        диеногеста у пациенток с печеночной недостаточностью не изучалась.</P>
      <P></P>

      <H2 id={'ind-for-use'}>Показания к применению</H2>
      <P>Лечение эндометриоза.</P>

      <H2 id={'dication'}>противоПоказания</H2>
      <P>Применение препарата Алвовизан противопоказано при наличии любого из перечисленных ниже
        состояний/заболеваний/факторов риска, часть которых является общей для всех препаратов, содержащих только
        гестагенный компонент.</P>

      <UL>
        <LI><P>Острый венозный тромбофлебит, венозные тромбоэмболии (ВТЭ).</P></LI>
        <LI><P>Заболевания сердца и артерий, в основе которых лежат атеросклеротические поражения сосудов в настоящее
          время или в анамнезе (в том числе инфаркт миокарда, нарушение мозгового кровообращения, ишемическая болезнь
          сердца).</P></LI>
        <LI><P>Сахарный диабет с ангиопатией.</P></LI>
        <LI><P>Тяжелые заболевания печени в настоящее время или в анамнезе до нормализации показателей функции
          печени.</P></LI>
        <LI><P>Опухоли печени в настоящее время или в анамнезе (доброкачественные или злокачественные).</P></LI>
        <LI><P>Диагностированные гормонозависимые злокачественные заболевания половых органов или молочной железы, или
          подозрение на них.</P></LI>
        <LI><P>Кровотечения из влагалища неясного генеза.</P></LI>
        <LI><P>Гиперчувствительность к диеногесту или любому из вспомогательных веществ.</P></LI>
        <LI><P>Беременность и период грудного вскармливания.</P></LI>
        <LI><P>Наследственная непереносимость лактозы, дефицит лактазы, глюкозо-галактозная мальабсорбция.</P></LI>
        <LI><P>Наследственная непереносимость лактозы, дефицит лактазы, глюкозо-галактозная мальабсорбция.</P></LI>
        <LI><P>Возраст до 12 лет (до наступления менархе).</P></LI>
      </UL>

      <P>Если какое-либо из данных состояний/заболеваний/факторов риска развивается на фоне применения препарата, прием
        препарата следует немедленно прекратить.</P>

      <H2 id={'warning'}>С осторожностью</H2>
      <P>Депрессия в анамнезе, внематочная беременность в анамнезе, артериальная гипертензия, хроническая сердечная
        недостаточность, мигрень с аурой, сахарный диабет без сосудистых осложнений, гиперлипидемия, тромбофлебит
        глубоких вен в анамнезе, наличие ВТЭ в личном или семейном анамнезе (см. раздел «Особые указания»).</P>

      <H2 id={'for-use-feed'}>Применение в период беременности и грудного вскармливания</H2>
      <P><b>Беременность</b></P>
      <P>Данные о применении диеногеста у беременных женщин очень ограничены. В исследованиях на животных репродуктивной
        токсичности, генотоксичности и канцерогенности при применениии препарата не выявлено.</P>
      <P>Применение препарата в период беременности противопоказано, так как терапия эндометриоза в период беременности
        не требуется.
      </P>
      <P><b>Период грудного вскармливания</b></P>
      <P>Прием препарата Алвовизан в период грудного вскармливания противопоказан. Исследования на животных показали,
        что диеногест проникает в грудное молоко животных. Не известно, проникает ли диеногест в грудное молоко
        человека. Следует решить вопрос о прекращении грудного вскармливания или терапии препаратом Алвовизан.</P>
      <P>Решение о прекращении грудного вскармливания или об отказе от приема препарата Алвовизан принимается исходя из
        оценки соотношения пользы грудного вскармливания для ребенка и преимущества терапии диеногестом для женщины.</P>

      <H2 id={'dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</H2>
      <P>Для приема внутрь.</P>
      <P>До начала приема препарата Алвовизан необходимо прекратить применение любой гормональной контрацепции. В случае
        необходимости контрацепции используют негормональные методы (например, барьерный).</P>
      <P>Начало приема препарата Алвовизан возможно в любой день менструального цикла. Препарат принимают по 1 таблетке
        в сутки непрерывно, предпочтительно в одно и то же время, при необходимости запивая небольшим количеством
        жидкости. Можно принимать таблетку натощак или во время еды. Следует принимать таблетки регулярно, вне
        зависимости от вагинального кровотечения. После завершения приема таблеток из одной упаковки начинают прием
        препарата Алвовизан из следующей упаковки, не делая перерыва в приеме препарата.</P>
      <P>В случае пропуска приема таблеток, рвоты и/или диареи (если это происходит в течение 3-4 часов после приема
        таблетки) эффективность препарата Алвовизан может снижаться. В случае пропуска приема одной или нескольких
        таблеток женщина должна принять только одну таблетку сразу, как только она об этом вспомнит, затем продолжать
        прием таблеток на следующий день в обычное время. Если всасывание препарата нарушено из-за рвоты или диареи,
        также следует принять одну таблетку.</P>
      <P>Длительность приема препарата — 6 месяцев. Решение о дальнейшей терапии диеногестом принимается врачом в
        зависимости от клинической картины.</P>
      <P><b><u>Применение препарата в особых клинических группах пациенток</u></b></P>
      <P><b>Данные по безопасности у подростков</b></P>
      <P>Безопасность диеногеста в отношении МПК была исследована в неконтролируемом клиническом исследовании в течение
        12 месяцев у 111 пациенток (12 - 18 лет, после менархе) с клинически подозреваемым или подтвержденным
        эндометриозом. Среднее относительное изменение показателя МПК поясничного отдела позвоночника (позвонки L2 —L4)
        у 103 пациенток по сравнению с исходным показателем составило -1,2%. У группы пациенток, у которых наблюдалось
        снижение МПК. через 6 месяцев после окончания лечения в рамках периода продолженного наблюдения данный параметр
        снова был измерен, и анализ показал повышение уровня МПК до -0,6%.</P>
      <P>Во время применения диеногеста продолжительностью до 15 месяцев значительного влияния препарата на стандартные
        лабораторные параметры, включая гематологию, химический состав крови, показатели ферментов печени, липидов и
        гликолизированного гемоглобина, не наблюдалось.</P>
      <P><b>Пациентки пожилого возраста</b></P>
      <P>Обоснованные показания к применению препарата Алвовизан у пожилых пациенток отсутствуют.</P>
      <P><b>Пациентки с нарушением функции печени</b></P>
      <P>Препарат Алвовизан противопоказан пациенткам с тяжелым заболеванием печени — текущим или в анамнезе (см. раздел
        «Противопоказания»).</P>
      <P><b>Пациентки с нарушением функции почек</b></P>
      <P>Отсутствуют данные, свидетельствующие о необходимости коррекции доз у пациенток с нарушением функции почек.</P>

      <H2 id={'effect-action'}>Побочное действие</H2>
      <P>Нежелательные реакции (HP) чаще возникают в первые месяцы после начала терапии препаратом Алвовизан и
        уменьшаются при продолжении лечения. Возможны изменения характера кровотечений, например, «мажущие» кровянистые
        выделения, нерегулярные кровотечения или аменорея. Самыми частыми HP, отмечаемыми при лечении диеногестом,
        являются головная боль, дискомфорт в молочных железах, депрессивное настроение и акне.</P>
      <P>Кроме того, у большинства пациенток, получающих диеногест, изменяется характер менструальных кровотечений. В
        течение первых 90 дней терапии диеногестом наблюдались следующие виды нарушений менструального цикла: аменорея,
        нечастные кровотечения, частые кровотечения, нерегулярные кровотечения, длительные кровотечения.</P>
      <P>Ниже приведены HP, отмеченные при применении диеногеста.</P>
      <P>Данные о частоте нежелательных реакций на лекарственный препарат приведены в соответствии с классами систем
        органов MedDRA. В пределах каждого уровня частоты HP представлены в порядке убывания частоты. Частота
        определяется как «часто» (1/100 до `{'<'}`1/10) и «нечасто» (`{'>'}`1/1000 до `{'<'}`1/100).</P>
      <P>Частота HP рассчитана по обобщенным данным четырех клинических исследований, включавших 332 пациентки
        (100%).</P>

      <Tables.WrapperTable sx={{overflowX: 'auto'}}>
        <Tables.Table sx={{minWidth: 900, tableLayout: 'fixed'}}>
          <Tables.Thead>
            <Tables.Tr sx={{fontWeight: 'bold', color: PinkLight}}>
              <Tables.Td>Системно-органные классы (MedDRA)</Tables.Td>
              <Tables.Td>Часто </Tables.Td>
              <Tables.Td>Нечасто</Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны крови и лимфатической системы</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>анемия</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны обмена веществ и питания</Tables.Td>
              <Tables.Td>увеличение массы тела</Tables.Td>
              <Tables.Td>увеличение массы тела снижение массы тела, повышение аппетита</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения психики</Tables.Td>
              <Tables.Td>депрессивное настроение, нарушение сна, нервозность, потеря либидо, изменение
                настроения</Tables.Td>
              <Tables.Td>тревожность, депрессия, резкие перемены настроения</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны нервной системы</Tables.Td>
              <Tables.Td>головная боль, мигрень</Tables.Td>
              <Tables.Td>дисбаланс вегетативной нервной системы, нарушение концентрации внимания</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны органа зрения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>сухость глаз</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны органа слуха и лабиринтные нарушения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>звон в ушах</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны сердца и сосудов</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>неуточненное нарушение со стороны системы кровообращения, ощущение сердцебиения, артериальная
                гипотензия</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны дыхательной системы, органов грудной клетки и средостения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>одышка</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны желудочно-кишечного тракта</Tables.Td>
              <Tables.Td>тошнота, боль в животе, метеоризм, ощущение распирания живота, рвота</Tables.Td>
              <Tables.Td>диарея, запор, дискомфорт в животе, воспалительные заболевания желудочно- кишечного тракта,
                гингивит</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны кожи и подкожных тканей</Tables.Td>
              <Tables.Td>акне, алопеция</Tables.Td>
              <Tables.Td>сухость кожи, гипергидроз, зуд, гирсутизм, онихоклазия, перхоть, дерматит, аномальный рост
                волос, реакции фоточувствительности, нарушения пигментации</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны скелетно-мышечной и соединительной ткани</Tables.Td>
              <Tables.Td>боль в спине</Tables.Td>
              <Tables.Td>боль в костях, спазмы в мышцах, боль в конечности, тяжесть в конечностях</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны почек и мочевыводящих путей</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>инфекции мочевыводящих путей</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны половых органов и молочной железы</Tables.Td>
              <Tables.Td>дискомфорт в молочных железах, киста яичника, «приливы», кровотечения из половых путей, в том
                числе «мажущие» кровянистые выделения</Tables.Td>
              <Tables.Td>кандидоз влагалища, сухость вульвы и влагалища, выделения из половых органов,
                боль в тазовой области, атрофический вульвовагинит, уплотнение ткани молочной железы, фиброзно-кистозная
                мастопатия
              </Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Общие расстройства и нарушения в месте введения</Tables.Td>
              <Tables.Td>астеническое состояние, раздражительность</Tables.Td>
              <Tables.Td>отек</Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <H2 id={'over-dozing'}>Передозировка</H2>
      <P>При проведении исследований острой токсичности диеногеста не выявлено риска острых нежелательных реакций
        вследствие непреднамеренного приема препарата в дозе, многократно превышающей суточную дозу. Нет сообщений о
        серьезных нарушениях при передозировке препарата. Специфического антидота нет, следует проводить
        симптоматическое лечение. Ежедневный прием 20-30 мг диеногеста в течение 24 недель очень хорошо переносился.</P>

      <H2 id={'another-medicines'}>Взаимодействие с другими лекарственными средствами</H2>
      <P><b>Влияние других лекарственных препаратов на диеногест</b></P>
      <P>Прогестагены, в том числе диеногест, метаболизируются преимущественно при участии изоферментов системы цитохрома Р450 (CYP3A4) в слизистой оболочке кишечника и печени. Поэтому индукторы или ингибиторы CYP3A4 могут влиять на метаболизм препаратов прогестагенов.</P>
      <P>Повышенный клиренс половых гормонов, обусловленный индукцией ферментов, может снижать терапевтическое действие диеногеста и вызывать HP, например, изменения характера маточных кровотечений.</P>
      <P>Снижение клиренса половых гормонов вследствие ингибирования ферментов может увеличивать экспозицию диеногеста и приводить к развитию HP.</P>
      <P>К веществам, повышающим клиренс половых гормонов (снижение эффективности путем индукции ферментов), в частности, относятся: фенитоин, барбитураты, примидон, карбамазепин, рифампицин и, возможно, также окскарбазепин, топирамат, фелбамат, гризеофульвин и препараты, содержащие зверобой продырявленный (Hypericum perforatum).</P>
      <P>Индукция ферментов отмечается уже через несколько дней после начала лечения. Максимальная индукция ферментов обычно достигается в течение нескольких недель. После прекращения терапии индукция ферментов может сохраняться в течение 4 недель.</P>
      <P>Эффект индуктора CYP3A4 рифампицина изучался у здоровых женщин в постменопаузе. Одновременное применение рифампицина с таблетками, содержащими эстрадиола валерат/диеногест приводило к значительному снижению равновесных концентраций и системной экспозиции диеногеста и эстрадиола. Системные экспозиции диеногеста и эстрадиола в равновесном состоянии, измеренные по величине AUC (0-24ч), соответственно снижались на 83% и 44%.</P>
      <P><b>Вещества с вариабельным влиянием на клиренс половых гормонов:</b></P>
      <P>При одновременном применении с половыми гормонами многие комбинации ингибиторов протеаз и ненуклеозидных ингибиторов обратной транскриптазы для лечения ВИЧ-инфекции и вирусного гепатита С могут увеличивать или уменьшать концентрации прогестагена в плазме крови. Суммарные эффекты этих изменений в некоторых случаях могут быть клинически значимыми.</P>
      <P>Вещества, снижающие клиренс половых гормонов (ингибиторы ферментов)</P>
      <P>Диеногест является субстратом цитохрома Р450 (CYP3A4).</P>
      <P>Одновременное применение высокоактивных ингибиторов CYP3A4 может повышать концентрации диеногеста в плазме крови.</P>
      <P>Одновременное применение с сильным ингибитором ферментов CYP3A4 кетоконазолом приводило к увеличению величины АUС(0-24ч) диеногеста в равновесной концентрации в 2,9 раза. Одновременное применение умеренного ингибитора эритромицина повышало АUС(0-24ч) диеногеста в равновесной концентрации в 1,6 раза.</P>
      <P><b>Влияние диеногеста на другие лекарственные препараты</b></P>
      <P>По данным исследований ингибирования in vitro, показано что клинически значимое взаимодействие диеногеста с ферментами системы цитохрома Р450, с существенным влиянием на метаболизм других препаратов, маловероятно.</P>
      <P><b>Взаимодействие с пищевыми продуктами</b></P>
      <P>Прием пищи с высоким содержанием жиров не влиял на биодоступность диеногеста.</P>
      <P><b>Другие виды взаимодействия</b></P>
      <P>Применение прогестагенов может повлиять на результаты определенных лабораторных исследований, в том числе на биохимические показатели функции печени, щитовидной железы, надпочечников, почек, плазменные концентрации белков (носителей) (например, кортикостероид-связывающего глобулина и фракций липидов/липопротеинов), параметры углеводного обмена, показатели свертывания и фибринолиза.</P>
      <P>Обычно эти изменения не выходят за рамки нормальных лабораторных показателей.</P>

      <H2 id={'special-instructions'}>Особые указания</H2>
      <P>Перед началом применения препарата необходимо исключить беременность. Во время применения препарата при необходимости контрацепции пациенткам рекомендуется применять негормональные контрацептивные методы (например, барьерный).</P>
      <P><b>Фертильность</b></P>
      <P>На основании имеющихся данных, во время применения препарата у большинства пациенток происходит подавление овуляции. Однако диеногест в дозировке 2 мг не является контрацептивным препаратом.</P>
      <P>По имеющимся данным, физиологический менструальный цикл возвращается к норме в течение 2 месяцев после прекращения лечения диеногестом.</P>
      <P>Вероятность наступления эктопической беременности выше у пациенток, принимающих с целью контрацепции препараты, содержащие только гестагенный компонент, по сравнению с пациентками, принимающими комбинированные пероральные контрацептивы. Таким образом, для женщин с внематочной беременностью в анамнезе или при непроходимости маточных труб следует оценивать соотношение пользы и риска перед применением диеногеста.</P>
      <P><b>Изменение характера кровотечений</b></P>
      <P>У большинства женщин применение диеногеста влияет на характер менструальных кровотечений.</P>
      <P>При применении диеногеста возможно усиление маточного кровотечения, например, у женщин с аденомиозом матки или лейомиомами матки. При тяжелом и продолжительном кровотечении возможно развитие анемии (иногда тяжелой). В случае анемии следует рассмотреть вопрос об отмене препарата.</P>
      <P><b>Нарушения кровообращения</b></P>
      <P>В процессе эпидемиологических исследований, показана недостаточно убедительная взаимосвязь между применением монопрепаратов прогестагена и повышением риска инфаркта миокарда или тромбоэмболии сосудов головного мозга. В большей степени риск сердечно-сосудистых и церебральных заболеваний связан с возрастом, наличием артериальной гипертензии или курением. У женщин с гипертензией риск развития инсульта при приеме монопрепаратов прогестагена может незначительно увеличиваться.</P>
      <P>В отдельных исследованиях показано небольшое и статистически незначимое увеличение риска венозной тромбоэмболии (тромбоза глубоких вен, тромбоэмболии легочной артерии) при использовании монопрепаратов прогестагена. Общепризнанными факторами риска ВТЭ являются наличие ВТЭ в анамнезе у пациента или в семейном анамнезе (ВТЭ у брата/сестры или родителя в возрасте менее 50 лет), возраст, ожирение, длительная иммобилизация, серьезное оперативное вмешательство или обширная травма. В таких случаях следует прекратить прием диеногеста (не менее чем за четыре недели до плановой операции) и возобновлять прием не ранее чем через 2 недели после полного восстановления двигательной активности.</P>
      <P>Следует учитывать повышенный риск развития тромбоэмболии в послеродовом периоде.</P>
      <P>При развитии или подозрении на развитие артериального или венозного тромбоза применение препарата следует немедленно прекратить.</P>
      <P><b>Опухоли</b></P>
      <P>Мета-анализ 54 эпидемиологических исследований выявил незначительное увеличение относительного риска (ОР=1,24) развития рака молочной железы у женщин, принимающих на момент исследования пероральные контрацептивы, преимущественно комбинированные (эстроген+гестаген). Этот повышенный риск постепенно снижается в течение 10 лет после прекращения приема комбинированных пероральных контрацептивов (КОК).</P>
      <P>Поскольку рак молочной железы (РМЖ) редко встречается у женщин моложе 40 лет, некоторое увеличение количества подобных диагнозов у женщин, принимающих или недавно принимавших КОК, невелико по сравнению с общим риском рака молочной железы. Риск диагностирования РМЖ у женщин, применяющих монопрепараты прогестагена, примерно соответствует таковому при приеме КОК. Однако данные по монопрепаратам прогестагена получены в значительно меньших популяциях пациенток и являются менее убедительными, чем данные по КОК. Установить причинно-следственную связь на основе этих исследований не представляется возможным.</P>
      <P>На фоне приема диеногеста было отмечено снижение МПК, поэтому необходимо оценивать ожидаемую пользу его применения по отношению к возможному риску для каждой пациентки, принимая во внимание возможность возникновения факторов риска развития остеопороза, особенно у пациенток с повышенным риском остеопороза, так как во время лечения диеногестом происходит умеренное снижение концентрации эндогенных эстрогенов.</P>
      <P>Женщинам любого возраста важно принимать препарат кальция и витамин D, вне зависимости от соблюдения определенной диеты или применения витаминных добавок.</P>
      <P><b>Другие состояния</b></P>
      <P>Необходимо тщательное наблюдение за пациентками с наличием в анамнезе депрессии. Если депрессия рецидивирует в серьезной форме, препарат следует отменить.</P>
      <P>В целом показано, что препарат не влияет на артериальное давление (АД) у женщин с нормальным АД. Тем не менее, если на фоне применения диеногеста возникает хроническая, клинически значимая артериальная гипертензия, рекомендуется отменить препарат и начать гипотензивное лечение.</P>
      <P>При рецидиве холестатической желтухи и/или холестатического зуда, которые впервые появились во время беременности или предшествующего приема половых гормонов, препарат необходимо отменить.</P>
      <P>Диеногест может оказывать незначительное влияние на периферическую инсулинорезистентность и толерантность к глюкозе. Пациентки с сахарным диабетом, особенно при наличии гестационного сахарного диабета в анамнезе, в период терапии диеногестом нуждаются в тщательном наблюдении.</P>
      <P>В некоторых случаях возможно развитие хлоазмы, особенно у женщин с хлоазмой беременных в анамнезе. Женщинам со склонностью к хлоазме, необходимо избегать воздействия солнечных лучей или ультрафиолетового облучения в период применения препарата.</P>
      <P>В период терапии препаратом могут возникать персистирующие фолликулы в яичниках (часто называемые функциональными кистами яичников). Большинство таких фолликулов не имеют клинических проявлений, хотя некоторые могут сопровождаться болью в области таза.</P>
      <P><b>Лактоза</b></P>
      <P>В одной таблетке препарата Алвовизан содержится 57,2 мг лактозы моногидрата. Находящимся на безлактозной диете пациенткам с редкими наследственными нарушениями, такими как непереносимость галактозы, дефицит лактазы Лаппа или глюкозо-галактозная мальабсорбция, применение препарата противопоказано.</P>

      <H2 id={'managment-transport'}>
        Влияние на способность управления транспортными средствами, механизмами
      </H2>
      <P>Отрицательного влияния на способность управлять транспортными средствами и механизмами на фоне терапии диеногестом не наблюдалось, однако пациентки, у которых отмечаются нарушения концентрации внимания, должны соблюдать осторожность.</P>

      <H2 id={'form-production'}>Форма выпуска</H2>
      <P>Таблетки, покрытые пленочной оболочкой, 2 мг.
        По 14 таблеток в блистер из ПВХ/ПВДХ и алюминиевой фольги.
        По 2, 6 или 12 блистеров в картонную пачку вместе с инструкцией по применению.
      </P>

      <H2 id={'keeper'}>Условия хранения</H2>
      <P>При температуре не выше 25 °C.</P>
      <P>Хранить в недоступном для детей месте!</P>


      <H2 id={'deadline'}>Срок годности</H2>
      <P>3 года.</P>
      <P>Препарат не следует применять по истечении срока годности, указанного на упаковке.</P>


      <H2 id={'holiday'}>Условия отпуска</H2>
      <P>По рецепту.</P>

      <H2 id={'owner'}>Владелец Регистрационного удостоверения</H2>
      <P>Альвоген Мальта Оперейшнз (РОУ) Лтд., Мальта</P>

      <H2 id={'producer'}>Производитель</H2>
      <P>Хаупт Фарма Мюнстер ГмбХ,</P>
      <P>Шлеебрюггенкамп 15, 48159 Мюнстер, Германия</P>
      <P><b>Организация, принимающая претензии потребителей</b></P>
      <P>ООО «АЛВОГЕН ФАРМА», Россия</P>
      <P>127055, г. Москва, ул. Новослободская, д. 31, стр. 4, помещение VI</P>
      <P>Тел: +7 (499) 940-01-77, факс: +7 (499) 940-01-55</P>
      <P>www.alvogen.ru</P>

      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Инструкция PDF
      </a>
    </Page>
  )
}
