import {styled} from '@mui/system';

export const Div = styled('div')({
  display: 'flex',
  gap: 20,

  '.small': {
    maxWidth: 412,
  },

  '.extra-small': {
    maxWidth: 326,
  },

  '@media only screen and (max-width: 900px)': {
    flexWrap: 'wrap',

    '.small': {
      maxWidth: '100%',
    },
  }
})