import {useHistory, useLocation} from 'react-router-dom';
import {Box} from '@mui/material';

import Page from 'shared/ui/Page';
import {Tabs} from './tabs';
import {EffectiveSalesTarget} from './subpages/target';
import {EffectiveSalesMedicaments} from './subpages/medicaments';
import {EffectiveSalesPositioning} from './subpages/positioning';
import {EffectiveSalesCompetitors} from './subpages/competitors';
import {EffectiveSalesToDoctor} from './subpages/to-doctor';
import {EffectiveSalesMarket} from './subpages/market';
import {TABS} from './constants';

export const EffectiveSales = () => {
  const history = useHistory();
  const {pathname} = useLocation();
  const currentSubPage = pathname.split('/')[2];

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  return (
    <>
      <Page
        head={'Эффективные продажи'}
        backText="Главная"
        onClose={handleRedirectToProducts}
      >
        <Box sx={{mb: 21}}>
          <Tabs sx={{mt: 7, mb: 5}}/>
          {currentSubPage === TABS.TARGET && <EffectiveSalesTarget/>}
          {currentSubPage === TABS.MEDICAMENTS && <EffectiveSalesMedicaments/>}
          {currentSubPage === TABS.POSITIONING && <EffectiveSalesPositioning/>}
          {currentSubPage === TABS.COMPETITORS && <EffectiveSalesCompetitors/>}
          {currentSubPage === TABS.MARKET && <EffectiveSalesMarket/>}
          {currentSubPage === TABS.TO_DOCTOR && <EffectiveSalesToDoctor/>}
        </Box>
      </Page>
    </>
  )
}
