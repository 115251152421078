import {useHistory} from 'react-router-dom';

import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import Page from 'shared/ui/Page';
import {Div, H1, H2, List as UL, ListItem as LI, Sup, Paragraph as P, Span, Tables} from 'shared/ui';
import {BlueDark, PinkLight, YellowDark} from 'styles/color';
import {AnchorLink, LinkA as A} from './ui';

export const ZoelyEffects = () => {
  const history = useHistory();

  const handleToBack = () => {
    history.push('/product/eoeli#survey');
  }

  return (
    <Page onClose={handleToBack} isCross>
      <Div sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
        <H2 sx={{
          fontSize: 36,
          color: '#FFFFFF',
          fontFamily: 'SF-Pro-Text-Regular',
          textAlign: 'center',
          mt: 0,
          textTransform: 'uppercase',
          mb: 2
        }}>Эффекты NOMAC/Е2 в отношении молочной железы и матки</H2>
        <WrapperShell sx={{pt: 4, pb: 4}}>
          <H1>Верно ли утверждение, что Е4, в отличие от других эстрогенов, снижает риск рака молочной железы?</H1>

          <Div sx={{display: 'flex', gap: '20px', mb: '20px'}}>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <H2 sx={{color: '#FFFFFF', mt: 0, fontSize: 36, fontFamily: 'SF-Pro-Text-Regular'}}>E2</H2>
              <UL>
                <LI><P>
                  в ER-позитивной линии рака молочной железы более высокие концентрации Е2 ингибируют
                  рост <AnchorLink href="#group-one-1">клеток<Sup>1</Sup></AnchorLink>
                </P></LI>
                <LI><P>
                  Е2 может оказывать антиканцерогенное действие против различных форм рака
                </P></LI>
                <LI><P>
                  Е2 в раковых клеточных линиях способствует нарушению внутриклеточного метаболизма железа таким
                  образом, что задерживает циклирование клеток в клетках рака молочной железы*
                  и <AnchorLink href="#group-one-1">яичников<Sup>2</Sup>.</AnchorLink>
                </P></LI>
              </UL>
              <P sx={{fontSize: 12, mb: 0, lineHeight: '14px'}}>
                *метастатические (MDA-MB-231) и неметастатические (MCF-7) клеточные линии рака молочной железы,
                обработанные 20 нМ E2
              </P>
            </WrapperShellBox>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <H2 sx={{color: '#FFFFFF', mt: 0, fontSize: 36, fontFamily: 'SF-Pro-Text-Regular'}}>E2</H2>
              <P>
                E3* может использоваться в качестве терапевтического агента не только для пациентов в постменопаузе, у
                которых рецидивирующий рак после первичной эндокринной терапии, но и в качестве выбора лечения
                гормонально чувствительного рака молочной <AnchorLink href="#group-one-1">железы<Sup>1</Sup></AnchorLink>
              </P>
              <P sx={{fontSize: 12, mb: 0}}>*Е3 является активным метаболитом Е2</P>
            </WrapperShellBox>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <H2 sx={{color: '#FFFFFF', mt: 0, fontSize: 36, fontFamily: 'SF-Pro-Text-Regular'}}>E4</H2>
              <UL>
                <LI><P>
                  отсутствие ингибирующих эффектов более высокой концентрации Е4 в MCF-7&nbsp;
                  <AnchorLink href ="#group-one-1">клетках<Sup>1</Sup></AnchorLink>
                </P></LI>
                <LI><P>
                  введение in vivo высоких дозы E4 стимулировало экспрессию гена матки, пролиферацию
                  <AnchorLink href="#group-one-1">эпителия<Sup>1</Sup></AnchorLink>
                </P></LI>
                <LI><P>
                  в доклинических моделях рака молочной железы у мышей высокие дозы E4 индуцируют проканцерогенные
                  и <AnchorLink href="#group-one-3">эффекты<Sup>3</Sup>.</AnchorLink>
                </P></LI>
              </UL>
            </WrapperShellBox>
          </Div>

          <A
            id="group-one-1"
            href="https://pubmed.ncbi.nlm.nih.gov/20086172/">
            <Span>1.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Ariazi EA, et al. The G protein-coupled receptor GPR30 inhibits proliferation of estrogen
              receptor-positive breast cancer cells. Cancer Res. 2010;70:1184–1194.
            </Span>
          </A>

          <A id="group-one-2">
            <Span>2.</Span>
            <Span sx={{ml: '4px', color: '#FFFFFF', fontFamily: 'SF-Pro-Text-Regular'}}>
              Bajbouj K., Shafarin J., Hamad M. 17-β estradiol promotes autophagy and induces cellular senescence in
              breast cancer cells. Journal of Clinical Oncology 37, no. 15. Published online May 26, 2019.
            </Span>
          </A>

          <A
            id="group-one-3"
            href="https://pubmed.ncbi.nlm.nih.gov/26056044/">
            <Span>3.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Gérard C., et al. Combined estrogenic and anti-estrogenic properties of estetrol on breast cancer may
              provide a safe therapeutic window for the treatment of menopausal symptoms. Oncotarget.
              2015;6:17621–17636.
            </Span>
          </A>

          <Tables.WrapperTable sx={{mt: 7, mb: 3}}>
            <Tables.Table className="align-left"
                          sx={{
                            fontSize: 14,
                            minWidth: 900,
                            lineHeight: '22px',
                            tableLayout: 'fixed'
                          }}>
              <Tables.Thead>
                <Tables.Tr sx={{fontSize: 20}}>
                  <Tables.Td sx={{color: PinkLight}}>Факторы, снижающие риск рака молочной железы</Tables.Td>
                  <Tables.Td sx={{color: YellowDark, fontWeight: 'bold'}}>E2/NOMAC</Tables.Td>
                  <Tables.Td sx={{color: BlueDark, fontWeight: 'bold'}}>E4/DRSP</Tables.Td>
                </Tables.Tr>
              </Tables.Thead>
              <Tables.Body>
                <Tables.Tr>
                  <Tables.Td>Влияние прогестогена на риск рака молочной железы</Tables.Td>
                  <Tables.Td>NOMAC уменьшает эстрогенные эффекты Е2 на молочную железу, снижая пролиферацию клеток
                    молочной железы и оказывая антипролиферативное действие на клетки рака молочной железы&nbsp;
                    <AnchorLink href="#group-two-1">человека<Sup>1</Sup>.</AnchorLink>
                  </Tables.Td>
                  <Tables.Td>
                    Согласно анализу, проведенному Mørch LS с соавт. <AnchorLink
                    href="#group-two-12">(2017)<Sup>12</Sup></AnchorLink>, относительный риск (RR) рака молочной железы (РМЖ) у
                    потребителей дроспиренона и не применявших КОК был практически сопоставим:
                    <UL>
                      <LI>при приеме дроспиренона - 1,05</LI>
                      <LI>у не применявших КОК – 1,0</LI>
                    </UL>
                  </Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>
                    ERβ противодействует эстроген-индуцированной клеточной гиперпролиферации в тканях молочной&nbsp;
                    <AnchorLink href="#group-two-3">железы<Sup>3,5</Sup></AnchorLink>* и&nbsp;
                    <AnchorLink href="#group-two-3">матки<Sup>3</Sup></AnchorLink>.&nbsp;
                    <br/>
                    <br/>
                    <Span sx={{fontSize: 12}}>*клеточная линия рака молочной железы T47D</Span>
                  </Tables.Td>
                  <Tables.Td>
                    E2 активирует ERα и ERβ с одинаковым&nbsp;
                    <AnchorLink href="#group-two-2">сродством<Sup>2</Sup></AnchorLink>.
                  </Tables.Td>
                  <Tables.Td>
                    Сродство E4 к ERβ в 4 раза меньше, чем&nbsp;
                    <AnchorLink href="#group-two-4">сродство Е2<Sup>4</Sup></AnchorLink>.
                  </Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>
                    ГСПГ <br/>
                    <UL>
                      <LI>контролирует количество половых гормонов, доступных организму</LI>
                      <LI sx={{mt: 1, mb: 0}}>помогает поддерживать в равновесии процессы, связанные с половыми
                        гормонами</LI>
                    </UL>
                  </Tables.Td>
                  <Tables.Td>
                    ГСПГ связывает половые стероидные гормоны, в т.ч. Е2
                  </Tables.Td>
                  <Tables.Td>
                    ГСПГ не связывает и не контролирует количество Е4 в&nbsp;
                    <AnchorLink href="#group-two-6">организме<Sup>6</Sup></AnchorLink>.
                  </Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>
                    <UL sx={{display: 'flex', flexDirection: 'column', gap: '12px', m: 0}}>
                      <LI>
                        ГСПГ способствует ингибированию роста и пролиферации клеток рака молочной железы,
                        индуцированного&nbsp;
                        <AnchorLink href="#group-two-8">эстрогенами<Sup>8</Sup></AnchorLink>.
                      </LI>
                      <LI>
                        ГСПГ связан со сниженным риском развития новообразования после воздействия&nbsp;
                        <AnchorLink href="#group-two-8">эстрогена<Sup>8</Sup></AnchorLink>.
                      </LI>
                      <LI>
                        Опухоли пациентов с высоким титром ГСПГ показали уровень ответа на терапию 91,8%, опухоли
                        пациентов с низким титром SHBG показали только 8,2% уровень ответа на&nbsp;
                        <AnchorLink href="#group-two-7">терапию<Sup>7</Sup></AnchorLink>.
                      </LI>
                      <LI>
                        Показана обратная связь между концентрациями ГСПГ и риском развития рака молочной&nbsp;
                        <AnchorLink href="#group-two-9">железы<Sup>9</Sup></AnchorLink>.
                      </LI>
                      <LI>
                        Связывание эстрадиола с ГСПГ приводит к апоптозу клеток рака молочной железы и подавлению
                        его&nbsp;
                        <AnchorLink href="#group-two-9">роста<Sup>9</Sup></AnchorLink>.
                      </LI>
                      <LI>
                        ГСПГ играет защитную роль в воздействии эстрогена на клетки молочной&nbsp;
                        <AnchorLink href="#group-two-9">железы<Sup>9,10</Sup></AnchorLink>.
                      </LI>
                    </UL>
                  </Tables.Td>
                  <Tables.Td>
                    Низкие дозы Е2 повышают уровни&nbsp;
                    <AnchorLink href="#group-two-11">ГСПГ<Sup>11</Sup></AnchorLink>.
                  </Tables.Td>
                  <Tables.Td>
                    Е4 не увеличивает выработку ГСПГ клетками печени&nbsp;
                    <AnchorLink href="#group-two-6">человека<Sup>6</Sup></AnchorLink>.
                  </Tables.Td>
                </Tables.Tr>
              </Tables.Body>
            </Tables.Table>
          </Tables.WrapperTable>

          <A
            id="group-two-1"
            href="https://pubmed.ncbi.nlm.nih.gov/29386925/">
            <Span>1.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Akintomide H, Panicker S. Nomegestrol acetate/17-beta estradiol: a review of efficacy, safety, and
              patient acceptability. Open Access J Contracept. 2015 May 26;6:77-86.
            </Span>
          </A>
          <A
            id="group-two-2"
            href="https://pubmed.ncbi.nlm.nih.gov/9658195/">
            <Span>2.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              T. Barkhem, B. Carlsson, Y. Nilsson, E. Enmark, J. Gustafsson, S. Nilsson. Differential response of
              estrogen receptor alpha and estrogen receptor beta to partial estrogen agonists/antagonists. Mol.
              Pharmacol., 54 (1998), pp. 105-112.
            </Span>
          </A>
          <A
            id="group-two-3"
            href="https://pubmed.ncbi.nlm.nih.gov/24971815/">
            <Span>3.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Paterni I, Granchi C, Katzenellenbogen JA, Minutolo F. Estrogen receptors alpha (ERα) and beta (ERβ):
              subtype-selective ligands and clinical potential. Steroids. 2014 Nov;90:13-29.
            </Span>
          </A>
          <A
            id="group-two-4"
            href="https://pubmed.ncbi.nlm.nih.gov/24904530/">
            <Span>4.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Giretti MS, Montt Guevara MM, Cecchi E, Mannella P, Palla G, Spina S, Bernacchi G, Di Bello S, Genazzani
              AR, Genazzani AD, Simoncini T. Effects of Estetrol on Migration and Invasion in T47-D Breast Cancer
              Cells through the Actin Cytoskeleton. Front Endocrinol (Lausanne). 2014 May 26;5:80.
            </Span>
          </A>
          <A
            id="group-two-5"
            href="https://pubmed.ncbi.nlm.nih.gov/14745018/">
            <Span>5.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Ström A, et al. Estrogen receptor beta inhibits 17beta-estradiol-stimulated proliferation of the breast
              cancer cell line T47D. Proc Natl Acad Sci U S A. 2004 Feb 10;101(6):1566-71.
            </Span>
          </A>
          <A
            id="group-two-6"
            href="https://pubmed.ncbi.nlm.nih.gov/18464022/">
            <Span>6.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Hammond GL, et al. Estetrol does not bind sex hormone binding globulin or increase its production by
              human HepG2 cells. Climacteric. 2008;11 Suppl 1:41-6.
            </Span>
          </A>
          <A
            id="group-two-7"
            href="https://link.springer.com/article/10.1007/BF02966450">
            <Span>7.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Murayama Y, Hammond GL, Sugihara K. The shbg Gene and Hormone Dependence of Breast Cancer: A Novel
              Mechanism of Hormone Dependence of MCF-7 Human Breast Cancer Cells Based upon SHBG. Breast Cancer
              (Tokyo, Japan). 1999 Oct;6(4):338-343.
            </Span>
          </A>
          <A
            id="group-two-8"
            href="https://pubmed.ncbi.nlm.nih.gov/19770023/">
            <Span>8.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Fortunati N, Catalano MG, Boccuzzi G, Frairia R. Sex Hormone-Binding Globulin (SHBG), estradiol and
              breast cancer. Mol Cell Endocrinol. 2010 Mar 5;316(1):86-92.
            </Span>
          </A>
          <A
            id="group-two-9"
            href="https://pubmed.ncbi.nlm.nih.gov/32565962/">
            <Span>9.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Park SJ, Kim TS, Park KH, Kwon WS and Kim JJ: Serum concentration of sex hormone‑binding globulin in
              healthy volunteers and patients with breast cancer stratified by sex and age. Oncol Lett 20: 364-372,
              2020.
            </Span>
          </A>
          <A
            id="group-two-10"
            href="https://pubmed.ncbi.nlm.nih.gov/15664449/">
            <Span>10.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Catalano MG, Frairia R, Boccuzzi G, Fortunati N. Sex hormone-binding globulin antagonizes the
              anti-apoptotic effect of estradiol in breast cancer cells. Mol Cell Endocrinol. 2005;230:31–37.
            </Span>
          </A>
          <A
            id="group-two-11"
            href="https://pubmed.ncbi.nlm.nih.gov/17484513/">
            <Span>11.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Mozzanega B, Babbo GL, Salmaso L, De Toni R, Schiavo A, Mioni R, de Kreutzenberg SV. Oral
              17beta-estradiol and sequential progesterone in menopause: effects on insulin-like growth factors and
              their binding proteins. Gynecol Endocrinol. 2007 Jan;23(1):50-7.
            </Span>
          </A>
          <A
            id="group-two-12"
            href="https://pubmed.ncbi.nlm.nih.gov/29211679/">
            <Span>12.</Span>
            <Span sx={{ml: '4px', textDecoration: 'underline'}}>
              Mørch LS, Skovlund CW, Hannaford PC, Iversen L, Fielding S, Lidegaard Ø. Contemporary Hormonal
              Contraception and the Risk of Breast Cancer. N Engl J Med. 2017 Dec 7;377(23):2228-2239.
            </Span>
          </A>
        </WrapperShell>
      </Div>
    </Page>
  )
}
