import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const Tres = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>
        Objective: Our purpose was to compare the effects of tibolone, continuous combined hormone replacement therapy,
        and placebo on mammographic breast density.
      </H1>
      <P>
        Study design: A prospective, randomized, double-blind placebo-controlled study was performed. A total of 166
        postmenopausal women were equally randomized to receive tibolone 2.5 mg, estradiol 2 mg/norethisterone acetate 1
        mg (E(2)/NETA), or placebo. Mammograms were performed at baseline and after 6 months of treatment. Mammographic
        density was quantified according to the Wolfe classification and by the percentage area of the breast that had a
        dense pattern.
      </P>
      <P>
        Results: An increase in mammographic density was much more common among women receiving continuous combined
        hormone replacement therapy (46%-50%) than among those receiving tibolone (2%-6%) and placebo (0%) treatment.
        The difference between E(2)/NETA and placebo was highly significant (P {'<'}.001). Treatment with tibolone did not
        differ from that with placebo. The relative risk of an increase in breast density for E(2)/NETA versus tibolone
        was found to be 8.3 (95% CI 2.7-25.0).
      </P>
      <P>
        Conclusion: An increase in mammographic density should be regarded as an unwanted side effect of hormone
        replacement therapy. In contrast to estrogen/progestogen treatment, tibolone seems to exert little stimulation
        of breast tissue.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/11967497/">https://pubmed.ncbi.nlm.nih.gov/11967497/</A>]
      </P>
    </Page>
  )
}
