import doctorBlonde from './doctor-blonde.png';
import smilingWoman from './smiling-woman.png';
import family from './family.png';
import add from './add.png';
import market from './market.png';
import people from './people.png';
import approve from './approve.png';
import product from './product.png';
import productLarge from './product-large.png';
import livial from './product-livial.png';
import doc1 from './doc_1.png';
import doc2 from './doc_2.png';
import doc3 from './doc_3.png';
import doc4 from './doc_4.png';
import doc5 from './doc_5.png';

export default {
  doctorBlonde,
  smilingWoman,
  family,
  add,
  market,
  people,
  approve,
  livial,
  product,
  productLarge,
  doc1,
  doc2,
  doc3,
  doc4,
  doc5,
}