import Page from 'shared/ui/Page';
import {H1} from '../ui';

import {StyledParagraph as P, Link as A} from './ui';
import {PinkLight} from '../../../styles/color';

export const ProginovaLinkSeven = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Effect of estrogen priming through luteal phase and stimulation phase in poor responders in in-vitro
        fertilization</H1>
      <P><b>Purpose:</b> To verify whether a novel protocol administering E(2) during the luteal phase of the preceding
        cycle and during ovarian stimulation in GnRH antagonist cycle could enhance follicular response and hence
        improve outcomes in poor responders.</P>
      <P><b>Methods:</b> In this retrospective analysis, a total of 155 poor responder patients subjected to IVF/ICSI
        were analyzed. All the patients had history of more than one prior IVF cycle failure with poor response (less
        than 5 oocytes retrieved and/or maximal E₂ level less than 500 pg/mL) by using conventional long agonist or
        antagonist protocol. In luteal E2 treatment protocol (n = 86), oral estradiol valerate 4 mg/day was initiated on
        luteal day 21 and either stopped at menstrual cycle day 3 (Protocol A, n = 28) or continued during the period of
        ovarian stimulation until the day of hCG injection (Protocol B, n = 58). IVF parameters and pregnancy outcome of
        luteal E2 treatments group were compared with a standard GnRH antagonist protocol (n = 69) which the patients
        received no hormonal pretreatment.</P>
      <P><b>Results:</b> Compared to standard GnRH antagonist protocol, cancellation rate was lower with luteal E2 group
        (15.1% vs 37.7%, p {'<'} 0.01). Moreover, patients treated with luteal estrogen resulted in an increased number of
        oocytes retrieved (4.5 ± 2.9 vs 3.2 ± 1.9; p {'<'} 0.01). A trend toward increase in number of normally fertilized
        embryos (2.9 ± 2.1vs 2.3 ± 1.9; p = 0.043), and increased prevalence of good quality embryos (51.2% vs 25%; p =
        0.047) were noted. Comparing protocol A and B, there were no significant difference between embryologic data,
        however there were slight increase in ongoing pregnancy rate in protocol B compared to A (27.1% vs 20%, p =
        0.357), although statistical significance was not achieved.</P>
      <P><b>Conclusion:</b> Estrogen priming through luteal phase and stimulation phase improved ovarian responsiveness
        and this may lead to an increase in pregnancy rate in poor responders with failed cycle.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/22160464/">Chang E, Han JE, Won HJ, Kim YS, Yoon TK, Lee WS. Effect of estrogen priming through luteal
        phase and stimulation phase in poor responders in in-vitro fertilization. J Assist Reprod Genet. 2012
        Mar;29(3):225-230.</A>]
      </P>
    </Page>
  )
}
