import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';
import pdf from '../../../pdfs/alvovizan/Guidelines for diagnosis and treatment of endometriosis Busacca2018.pdf';

export const AlvovizanSecondLinkOne = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Treatment of pelvic pain associated with endometriosis: a committee opinion</H1>
      <P sx={{fontSize: 28}}>As leaders in women’s health care, we can do much more to improve</P>
      <P>Endometriosis is a common gynecologic problem in adolescents and women. It often presents with pelvic pain, an ovarian endometrioma, and/or subfertility. In a prospective study of 116,678 nurses, the incidence of a new surgical diagnosis of endometriosis was greatest among women aged 25 to 29 years and lowest among women older than age 44.1 Using the incidence data from this study, the calculated prevalence of endometriosis in this large cohort of women of reproductive age was approximately 8%...</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/24630080/">
        American Society for Reproductive Medicine (ASRM), Treatment of pelvic pain associated with endometriosis: a committee opinion. Fertil Steril, 2014; 101:927-35.
      </A>]
      </P>

      <H1 sx={{color: PinkLight, mt: 5}}>Эндометриоз: диагностика, лечение и реабилитация. Клинические рекомендации.</H1>
      <P sx={{color: PinkLight}}>Москва, 2013, 86 с.</P>

      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Статья PDF
      </a>
    </Page>
  )
}
