import main from './main.png';
import sickWoman from './sick-woman.png';
import statRu from './stat-ru.png';
import statWorld from './stat-world.png';
import who from './who.png';
import rectangle from './rectangle.svg';
import infographics from './infographics.png';
import circle from './circle.png';

import approveMini from './approve-mini.png';
import doctor from './doctor.png';
import embrace from './embrace.png';
import girlFirst from './girl1.png';
import girlSecond from './girl2.png';
import girlBed from './girlbed.png';
import warn from './warn.png';
import pair from './pair.png';
import plus from './plus.png';
import plusMini from './plus-mini.png';
import microginon from './microginon.png';
import diagram from './diagram.png';
import microginonLarge from './micoginon-large.png';
import circlePrice from './circle-price.png';
import naturella from './naturella.png';
import approveLarge from './approve-large.png';
import schema from './schema.png';
import price300 from './circle-price-300.png';

export default {
  main,
  sickWoman,
  statRu,
  statWorld,
  who,
  rectangle,
  infographics,
  circle,
  approveMini,
  doctor,
  embrace,
  girlSecond,
  girlFirst,
  girlBed,
  warn,
  pair,
  plus,
  plusMini,
  microginon,
  diagram,
  microginonLarge,
  circlePrice,
  naturella,
  approveLarge,
  schema,
  price300,
}