import { useEffect } from 'react';
import Popup from '../../shared/ui/Popup';
import PrezentationItem from '../../pages/vidora/PrezentationItem';

type AlvovizanListModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}

const ESCAPE_KEY = 'Escape';

export const AlvovizanListModal = ({isOpen, onClose}: AlvovizanListModalProps) => {
  useEffect(() => {
    const handleKeyDown = ({key}: KeyboardEvent) => {
      if (key === ESCAPE_KEY) {
        handleClosePopup();
      }
    };

    window.document.addEventListener('keyup', handleKeyDown);
    return () => {
      window.document.removeEventListener('keyup', handleKeyDown);
    };
  });

  const handleClosePopup = () => {
    if(onClose) {
      onClose();
    }
  }

  return (
    <Popup opened={isOpen} togglePopup={handleClosePopup} title={'Алвовизан'}>
      <PrezentationItem icon="plus" name="Расширение терапевтического взгляда на эндометриоз" link="/product/alvovizan"/>
      <PrezentationItem icon="plus" name="Привычка всех лечить оперативно" link="/product/alvovizan-second"/>
      <PrezentationItem icon="plus" name="Социально-ответственная терапия эндометриоза" link="/product/alvovizan-thirteen"/>
    </Popup>
  )
}
