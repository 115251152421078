import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const KlomonormLinkNine = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>ACOG Committee Opinion #322: Compounded bioidentical hormones</H1>
      <P></P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/16260546/">ACOG Committee of Gynecologic Practice. Committee opinion #322: compounded bioidentical hormones. Obstet Gynecol 2005;106(5 Pt 1):1139–40</A>]
      </P>

      <H1 sx={{color: PinkLight}}>Updated 2013 International Menopause Society recommendations on menopausal hormone therapy and preventive strategies for midlife health</H1>
      <P></P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/23672656/">de Villiers T.J., Pines A., Panay N. et al. on behalf of the International Menopause Society. Updated 2013 International Menopause Society recommendations on menopausal hormone therapy and preventive strategies for midlife health. Climacteric. 2013; 16: 316–337</A>]
      </P>
    </Page>
  )
}
