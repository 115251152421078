import {useHistory} from 'react-router-dom';

import Page from 'shared/ui/Page';
import {H1, H2, A, P, Span, Strong, UL, Wrapper, LI} from './ui';
import pdf from '../../../pdfs/diecyclen/diecyclen.pdf';

export const DiecyclenInstruction = () => {
  const history = useHistory();

  const handlerRedirectToDiecyclen = () => {
    history.push('/product/diecyclen');
  }

  return (
    <Page onClose={handlerRedirectToDiecyclen} isCross>
      <Wrapper textAlign={'center'}>
        <H2>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</H2>
        <H1 style={{fontSize: 38}}>ИНСТРУКЦИЯ</H1>
        <H2>по применению лекарственного препарата для медицинского применения</H2>
        <H1 style={{fontSize: 38, marginBottom: 20}}>ДИЕЦИКЛЕН®</H1>
      </Wrapper>
      <H2>ТОРГОВОЕ НАИМЕНОВАНИЕ: <Span>Диециклен®</Span></H2>
      <H2>МЕЖДУНАРОДНОЕ НЕПАТЕНТОВАННОЕ ИЛИ ГРУППИРОВОЧНОЕ НАИМЕНОВАНИЕ: <Span>диеногест + этинилэстрадиол</Span></H2>
      <H2>ЛЕКАРСТВЕННАЯ ФОРМА: <Span>таблетки, покрытые пленочной оболочкой</Span></H2>

      <H1 style={{marginTop: 60}}>СОДЕРЖАНИЕ</H1>
      <Wrapper display={'flex'} justifyContent={'space-between'}>
        <Wrapper flexDirection={'column'}>
          <A href={'#compound'}>СОСТАВ</A>
          <A href={'#description'}>ОПИСАНИЕ</A>
          <A href={'#farm-group'}>ФАРМАКОТЕРАПЕВТИЧЕСКАЯ ГРУППА</A>
          <A href={'#farm-description'}>ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА</A>
          <A href={'#ind-for-use'}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ</A>
          <A href={'#dication'}>ПРОТИВОПОКАЗАНИЯ</A>
          <A href={'#warning'}>С ОСТОРОЖНОСТЬЮ</A>
          <A href={'#for-use-feed'}>ПРИМЕНЕНИЕ В ПЕРИОД БЕРЕМЕННОСТИ И ГРУДНОГО<br/> ВСКАРМЛИВАНИЯ</A>
          <A href={'#dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</A>
          <A href={'#effect-action'}>ПОБОЧНОЕ ДЕЙСТВИЕ</A>
          <A href={'#over-dozing'}>ПЕРЕДОЗИРОВКА</A>
        </Wrapper>
        <Wrapper flexDirection={'column'}>
          <A href={'#another-medicines'}>ВЗАИМОДЕЙСТВИЕ С ДРУГИМИ ЛЕКАРСТВЕННЫМИ <br/> СРЕДСТВАМИ</A>
          <A href={'#special-instructions'}>ОСОБЫЕ УКАЗАНИЯ</A>
          <A href={'#managment-transport'}>ВЛИЯНИЕ НА СПОСОБНОСТЬ УПРАВЛЕНИЯ <br/>ТРАНСПОРТНЫМИ СРЕДСТВАМИ, МЕХАНИЗМАМИ</A>
          <A href={'#form-production'}>ФОРМА ВЫПУСКА</A>
          <A href={'#keeper'}>УСЛОВИЯ ХРАНЕНИЯ</A>
          <A href={'#deadline'}>СРОК ГОДНОСТИ</A>
          <A href={'#holiday'}>УСЛОВИЯ ОТПУСКА</A>
          <A href={'#owner'}>ВЛАДЕЛЕЦ (ДЕРЖАТЕЛЬ) РЕГИСТРАЦИОННОГО<br/>УДОСТОВЕРЕНИЯ</A>
          <A href={'#producer'}>ПРОИЗВОДИТЕЛЬ</A>
        </Wrapper>
      </Wrapper>

      <H1 id={'compound'}>СОСТАВ</H1>
      <P>1 таблетка, покрытая пленочной оболочкой, содержит:</P>
      <P><Strong>Состав ядра таблетки:</Strong></P>
      <P><Strong>Активные вещества:</Strong> диеногест 2,00 мг, этинилэстрадиол 0,03 мг</P>
      <P>
        <Strong>Вспомогательные вещества:</Strong> лактозы моногидрат 54,60 мг; крахмал кукурузный 9,89 мг; повидон-КЗО
        1,73 мг; тальк
        1,40 мг; магния стеарат 0,33 мг
      </P>
      <P>
        <Strong>Состав оболочки таблетки:</Strong> опаглос 2 прозрачный 0,50 мг (кармеллоза натрия-270,80 мкг;
        мальтодекстрин 104,00
        мкг; декстрозы моногидрат 84,70 мкг; лецитин соевый 30,50 мкг; натрия цитрата дигидрат 10,00 мкг).
      </P>

      <H1 id={'description'}>ОПИСАНИЕ</H1>
      <P>Круглые, двояковыпуклые таблетки белого цвета</P>
      <P>Вид на разрезе: таблетки белого или почти белого цвета.</P>

      <H1 id={'farm-group'}>Фармакотерапевтическая группа:</H1>
      <P>контрацептивное средство комбинированное (эстроген+гестаген).</P>
      <P><Strong>Код ATX:</Strong> G03FA15</P>

      <H1 id={'farm-description'}>Фармакологические свойства</H1>
      <P><Strong>Фармакодинамика</Strong></P>
      <P>
        Препарат Диециклен® является низкодозированным монофазным комбинированным пероральным гормональным
        контрацептивом (КОК). Контрацептивный эффект основывается на взаимодействии различных факторов, наиболее важным
        из
        которых является подавление овуляции и изменение вязкости секрета шейки матки, в результате чего он становится
        непроницаемым для сперматозоидов. При правильном применении индекс Перля (показатель, отражающий число
        беременностей у 100 женщин, применяющих контрацептив в течение 1-го года) составляет менее 1. При пропуске
        таблеток или
        неправильном применении индекс Перля может возрастать.
      </P>
      <P>
        У женщин, принимающих КОК, цикл становится более регулярным, уменьшаются болезненность, интенсивность и
        продолжительность менструальноподобных кровотечений, в результате чего снижается риск железодефицитной анемии.
        Имеются также данные о снижении риска рака эндометрия и яичников
      </P>
      <P>
        Входящий в состав препарата компонент диеногест является активным гестагеном, представляет собой производное
        нортестостерона с антиандрогенной активностью и оказывает позитивное влияние на липидный профиль, повышая
        концентрацию липопротеинов высокой плотности (ЛПВП)
      </P>
      <P><Strong>Фармакокинетика</Strong></P>
      <P><Strong textDecoration={'underline'}>Этинилэстрадиол</Strong></P>
      <P><Strong>Всасывание</Strong></P>
      <P>
        При приеме внутрь быстро и полностью всасывается. Максимальная концентрация в плазме крови (около 67 пг/мл)
        достигается
        через 1,5-4 ч. После всасывания и эффекта «первого прохождения» через печень этинилэстрадиол метаболизируется,
        его
        абсолютная биодоступность при пероральном применении составляет около 44%.
      </P>
      <P><Strong>Распределение</Strong></P>
      <P>
        Этинилэстрадиол в значительной степени связывается с белками плазмы (приблизительно 98%) и индуцирует синтез
        глобулина, связывающего половые гормоны (ГСПГ). Объём распределения этинилэстрадиола составляет около 2,8-8,6
        л/кг.
      </P>
      <P><Strong>Метаболизм</Strong></P>
      <P>
        Этинилэстрадиол подвергается пресистемной конъюгации как в слизистой оболочке тонкого кишечника, так и в печени.
        Основной путь метаболизма ароматическое гидроксилирование с образованием множества гидроксилированных и
        метилированных производных в форме свободных метаболитов, метаболитов глюкуронидов и сульфатов. Скорость
        клиренса из
        плазмы крови составляет приблизительно 2,3-7 мл/мин/кг.
      </P>
      <P><Strong>Выведение</Strong></P>
      <P>
        Снижение концентрации этинилэстрадиола в плазме крови носит двухфазный характер: первая фаза характеризуется
        периодом полувыведения (Т1/2) около 1 ч, вторая 10-20 ч. В неизмененном виде из организма не выводится.
        Метаболиты
        этинилэстрадиола выводятся почками и через кишечник в соотношении 4:6 с Т1/2 около 24 ч.
      </P>
      <P>
        <Strong>Равновесная концентрация</Strong> этинилэстрадиола в плазме крови при регулярном приеме достигается во
        второй половине цикла
        приема препарата
      </P>
      <P><Strong textDecoration={'underline'}>Диеногест</Strong></P>
      <P><Strong>Всасывание</Strong></P>
      <P>
        При приеме внутрь быстро и практически полностью всасывается. Максимальная концентрация диеногеста в плазме
        крови (около 51 нг/мл) достигается через 2,5 ч. Абсолютная биодоступность диеногеста составляет 96%, равновесная
        концентрация в плазме крови при постоянном приеме достигается через 4 дня.
      </P>
      <P><Strong>Распределение</Strong></P>
      <P>
        90 % общей концентрации диеногеста в плазме крови связывается с сывороточным альбумином, но не связывается с
        ГСПГ, или
        кортикостероид связывающим глобулином. В несвязанном виде находится 10% общей концентрации диеногеста в плазме
        крови. Объём распределения диеногеста составляет около 37-45 л.
      </P>
      <P><Strong>Метаболизм</Strong></P>
      <P>
        Метаболизируется преимущественно путем гидроксилирования, но также и путем гидрогенизации, конъюгирования и
        ароматизации с образованием неактивных метаболитов. После приёма однократной дозы общий клиренс диеногеста
        составляет около 3,6 л/ч.
      </P>
      <P><Strong>Выведение</Strong></P>
      <P>
        После приёма в дозе 0,1 мг/кг, соотношение выведения диеногеста в виде метаболитов почками и через кишечник
        составляет
        3:1. Только незначительное количество неизменённого диеногеста выводится почками. Т1/2 диеногеста составляет
        приблизительно 8,5-10,8 ч. После перорального приёма 86 % принятой дозы диеногеста выводится в течение 6 дней;
        значительная ее часть выводится в первые 24 ч после приёма, преимущественно почками.
      </P>
      <P><Strong>Равновесная концентрация</Strong></P>
      <P>
        Индукция этинилэстрадиолом синтеза глобулина не влияет на фармакокинетику диеногеста. При ежедневном применении
        препарата, концентрация диеногеста в плазме увеличивается в 1,5 раза.
      </P>

      <H1 id={'ind-for-use'}>Показания к применению</H1>
      <UL>
        <LI>
          <P>
            Пероральная контрацепция.
          </P>
        </LI>
        <LI>
          <P>
            Лечение акне легкой и средней степени тяжести у женщин, нуждающихся в контрацепции, при неэффективности местного лечения.
          </P>
        </LI>
      </UL>

      <H1 id={'dication'}>Противопоказания</H1>
      <UL>
        <LI>
          <P>
            Повышенная чувствительность к диеногесту и/или этинилэстрадиолу, или к любому из вспомогательных веществ
            препарата;
          </P>
        </LI>
        <LI>
          <P>
            тромбозы (венозные и артериальные) и тромбоэмболии в настоящее время или в анамнезе (в том числе тромбоз
            глубоких
            вен, тромбоэмболия легочной артерии, инфаркт миокарда);
          </P>
        </LI>
        <LI>
          <P>
            состояния, предшествующие тромбозу (в том числе стенокардия) в настоящее время или в анамнезе
          </P>
        </LI>
        <LI>
          <P>
            цереброваскулярная болезнь: инсульт, транзиторные ишемические атаки в настоящее время или в анамнезе
          </P>
        </LI>
        <LI>
          <P>
            множественные или выраженные факторы риска венозного или артериального тромбоза, в том числе осложненные
            поражения клапанного аппарата сердца, фибрилляция предсердий, заболевания сосудов головного мозга или
            коронарных
            артерий сердца, тяжелая дислипопротеинемия, неконтролируемая артериальная гипертензия, объёмное
            хирургическое
            вмешательство, обширная травма, длительная иммобилизация, курение в возрасте старше 35 лет, ожирение с
            индексом
            массы тела `{'>'}` 30 кг/м2;
          </P>
        </LI>
        <LI>
          <P>
            сахарный диабет с диабетической ангиопатией;
          </P>
        </LI>
        <LI>
          <P>
            выявленная предрасположенность к венозному или артериальному тромбозу, включая резистентность к
            активированному
            протеину С (включая фактор 5 Лейдена), дефицит антитромбина III, дефицит протеина С, дефицит протеина S,
            гипергомоцистеинемия, наличие антител к фосфолипидам (антикардиолипин, волчаночный антикоагулянт);
          </P>
        </LI>
        <LI>
          <P>
            печеночная недостаточность, острые и тяжелые заболевания печени (до нормализации лабораторных показателей
            функции
            печени);
          </P>
        </LI>
        <LI>
          <P>
            доброкачественные или злокачественные опухоли печени, в т.ч. в анамнезе;
          </P>
        </LI>
        <LI>
          <P>
            выявленные гормонозависимые злокачественные заболевания половых органов или молочных желез или подозрение на
            них,
            в т.ч. в анамнезе;
          </P>
        </LI>
        <LI>
          <P>
            кровотечение из влагалища неясного генеза;
          </P>
        </LI>
        <LI>
          <P>
            мигрень с очаговой неврологической симптоматикой в настоящее время или в анамнезе;
          </P>
        </LI>
        <LI>
          <P>
            панкреатит, сопровождающийся выраженной гипертриглицеридемией в настоящее время или в анамнезе;
          </P>
        </LI>
        <LI>
          <P>
            период грудного вскармливания;
          </P>
        </LI>
        <LI>
          <P>
            наследственная непереносимость лактозы, дефицит лактазы или синдром глюкозо галактозной мальабсорбции.
          </P>
        </LI>
      </UL>

      <H1 id={'warning'}>С осторожностью</H1>
      <P>
        Факторы риска развития тромбоза и тромбоэмболий: избыточная масса тела (ИМТ `{'<'}` 30 кг/м2), курение,
        дислипопротеинемия,
        контролируемая артериальная гипертензия, мигрень без очаговой неврологической симптоматики, неосложненные пороки
        клапанов сердца, наследственная предрасположенность к тромбозу (тромбозы, инфаркт миокарда или нарушение
        мозгового
        кровообращения в молодом возрасте у кого либо из ближайших родственников).
      </P>
      <P>
        Другие заболевания, при которых могут отмечаться нарушения периферического кровообращения: сахарный диабет без
        диабетической ангиопатии, флебит поверхностных вен, системная красная волчанка (СКВ), гемолитико-уремический
        синдром,
        болезнь Крона, язвенный колит, серповидно клеточная анемия, онкологические заболевания.
      </P>
      <P>
        Заболевания, впервые возникшие или усугубившиеся во время беременности или на фоне предыдущего приема половых
        гормонов, например, желтуха и/или зуд на фоне холестаза, заболевания желчного пузыря, порфирия, хорея Сиденгама,
        герпес
        во время беременности в анамнезе, ухудшение слуха (связанное с отосклерозом).
      </P>
      <P>
        Эндогенная депрессия, эпилепсия.
      </P>
      <P>
        Наследственный ангионевротический отек.
      </P>
      <P>Гипертриглицеридемия.</P>
      <P>Заболевания печени при нормальных лабораторных показателях функции печени.</P>
      <P>Послеродовый период (при отсутствии грудного вскармливания).</P>

      <H1 id={'for-use-feed'}>Применение в период беременности и грудного вскармливания</H1>
      <P>
        Препарат противопоказан во время беременности. В случае выявления беременности следует немедленно прекратить
        прием
        пероральных контрацептивов.</P>
      <P>
        КОК могут влиять на количество и состав грудного молока, а также в небольшом количестве проникать в грудное
        молоко,
        поэтому применение препарата в период грудного вскармливания противопоказано.
      </P>

      <H1 id={'dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</H1>
      <P>
        Внутрь, ежедневно, желательно в одно и то же время, по порядку, указанному на упаковке или в любом другом
        непроизвольном
        порядке, с небольшим количеством воды. Принимают по 1 таблетке в сутки непрерывно в течение 21 дня. Прием
        таблеток из
        каждой следующей упаковки начинается после 7-дневного перерыва, во время которого наблюдается кровотечение
        «отмены»
        (менструальноподобное кровотечение). Оно обычно начинается на 2-3-й день от приема последней таблетки и может
        продолжаться до начала приема таблетки из новой упаковки.
      </P>
      <P><Strong textDecoration={'underline'}>Начало приема препарата Диециклен®</Strong></P>
      <P>
        При отсутствии приема каких-либо гормональных контрацептивов в предыдущем месяце прием препарата Диециклен®
        начинается в первый день менструального цикла (в первый день менструального кровотечения). Допускается начало
        приема на
        2-5-й день менструального цикла, но в этом случае рекомендуется дополнительно использовать барьерный метод
        контрацепции в течение первых 7 дней приема таблеток из первой упаковки.
      </P>
      <P><Strong textDecoration={'underline'}>
        Переход с других комбинированных контрацептивных препаратов (КОК, вагинального кольца или контрацептивного
        пластыря)
      </Strong></P>
      <P>
        При переходе с других КОК прием препарата предпочтительно начинать на следующий день после приема последней
        активной
        таблетки из предыдущей упаковки, но не позднее следующего дня после обычного 7-дневного перерыва в приеме (для
        препаратов, содержащих 21 таблетку) или после приема последней неактивной таблетки (для препаратов, содержащих
        28
        таблеток в упаковке).
      </P>
      <P>
        При переходе с вагинального кольца или трансдермального пластыря прием препарата предпочтительно начинать в день
        удаления кольца или пластыря, но не позднее дня, когда должно быть введено новое кольцо или наклеен новый
        пластырь.
      </P>
      <P>
        <Strong textDecoration={'underline'}>
          Переход с контрацептивов, содержащих только гестагены («мини пили», инъекционные формы, имплантат), или с
          высвобождающего гестаген внутриматочного контрацептива
        </Strong>
      </P>
      <P>
        Переход с контрацептивов, содержащих только гестагены («мини пили», инъекционные формы, имплантат), или с
        высвобождающего гестаген внутриматочного контрацептива
      </P>
      <P>
        <Strong textDecoration={'underline'}>
          После аборта в I триместре беременности
        </Strong>
        прием препарата можно начинать немедленно. При соблюдении этого условия нет
        необходимости в дополнительной контрацептивной защите.
      </P>
      <P>
        <Strong textDecoration={'underline'}>
          После родов или аборта во II триместре беременности
        </Strong>
        рекомендовано начинать прием препарата на 21-28 день после родов
        (при отсутствии грудного вскармливания) или аборта. Если прием начат позднее, необходимо использовать
        дополнительно
        барьерный метод контрацепции в течение первых 7 дней приема таблеток. Однако, если у женщины уже были половые
        контакты до начала приема препарата, должна быть исключена беременность или необходимо дождаться первой
        менструации.
      </P>
      <P>
        <Strong textDecoration={'underline'}>
          Рекомендации в случае нерегулярного приёма препарата
        </Strong>
      </P>
      <P>
        В случае пропуска в приеме препарата, если опоздание в приеме таблетки составило менее 12 ч, контрацептивная
        защита не
        снижается. Женщина должна принять таблетку как можно скорее, следующую таблетку следует принять в обычное время.
      </P>
      <P>Если опоздание в приеме таблетки составило более 12 ч, контрацептивная защита может быть снижена.</P>
      <P>При этом можно руководствоваться следующими двумя основными правилами:</P>
      <P>- перерыв в приеме таблеток никогда не должен превышать 7 дней.</P>
      <P>- 7 дней непрерывного приема таблеток требуются для достижения адекватного подавления гипоталамо гипофизарно
        яичниковой регуляции</P>
      <P>
        <Strong textDecoration={'underline'}>
          Рекомендации в случае, если опоздание в приеме таблеток составило более 12-36 ч.
        </Strong>
      </P>
      <P>
        <Strong textDecoration={'underline'}>
          Пропуск таблеток в течение первой недели приема препарата
        </Strong>
      </P>
      <P>
        Следует принять последнюю пропущенную таблетку как можно скорее, даже если это означает прием двух таблеток
        одновременно. Следующую таблетку принимают в обычное время. Дополнительно должен быть использован барьерный
        метод
        контрацепции (например, презерватив) в течение следующих 7 дней. Если половой контакт имел место в течение 7
        дней перед
        пропуском таблетки, необходимо учитывать возможность наступления беременности.
      </P>
      <P>
        <Strong>
          Пропуск таблеток в течение второй недели приема препарата
        </Strong>
      </P>
      <P>
        Следует принять последнюю пропущенную таблетку как можно скорее даже, если это означает прием двух таблеток
        одновременно. Следующую таблетку принимают в обычное время. При условии, что таблетки принимались правильно в
        течение 7 дней, предшествующих первой пропущенной таблетке, нет необходимости в использовании дополнительных
        контрацептивных мер. В противном случае, а также при пропуске двух и более таблеток, необходимо дополнительно
        использовать барьерные методы контрацепции (например, презерватив) в течение 7 дней.
      </P>
      <P>
        <Strong>
          Пропуск таблеток в течение второй недели приема препарата
        </Strong>
      </P>
      <P>
        Риск снижения контрацептивной эффективности является неизбежным вследствие предыдущего 7-дневного перерыва в
        приеме таблеток. Однако, если придерживаться одного из следующих двух вариантов, то не будет необходимости
        использовать
        какой либо другой метод контрацепции при условии, что в течение 7 дней, предшествующих дню пропуска приема
        таблетки,
        режим дозирования строго соблюдался. В противном случае женщина должна следовать первому из этих двух
        предложенных
        вариантов, и одновременно использовать дополнительный метод контрацепции в течение последующих 7 дней.
      </P>
      <P>
        1. Последнюю пропущенную таблетку следует принять сразу, как только женщина вспомнит об этом, даже если это
        означает
        прием двух таблеток одновременно. Следующие таблетки следует принимать в обычное время. Прием таблеток из каждой
        последующей упаковки следует начинать немедленно после окончания предыдущей, без какого либо перерыва.
        Кровотечение
        после прекращения приема препарата до использования второй упаковки является маловероятным, но могут возникнуть
        «мажущие» кровянистые выделения или «прорывные» кровотечения в период приема таблеток.
      </P>
      <P>
        2. Женщина может также прервать прием таблеток из используемой упаковки на период до 7 дней, включая дни, когда
        прием
        таблеток был пропущен, после чего необходимо начинать прием таблеток из новой упаковки.
      </P>
      <P>
        Если был пропущен прием таблеток и впоследствии не было кровотечений, связанных с отменой препарата во время
        первого
        перерыва в приеме таблеток, то следует исключить беременность.
      </P>
      <P>
        <Strong textDecoration={'underline'}>
          Рекомендации в случае желудочно кишечных расстройств
        </Strong>
      </P>
      <P>
        Риск снижения контрацептивной эффективности является неизбежным вследствие предыдущего 7-дневного перерыва в
        приеме таблеток. Однако, если придерживаться одного из следующих двух вариантов, то не будет необходимости
        использовать
        какой либо другой метод контрацепции при условии, что в течение 7 дней, предшествующих дню пропуска приема
        таблетки,
        режим дозирования строго соблюдался. В противном случае женщина должна следовать первому из этих двух
        предложенных
        вариантов, и одновременно использовать дополнительный метод контрацепции в течение последующих 7 дней.
      </P>
      <P>
        <Strong textDecoration={'underline'}>
          Рекомендации в случае нерегулярного приёма препарата
        </Strong>
      </P>
      <P>
        В случае пропуска в приеме препарата, если опоздание в приеме таблетки составило менее 12 ч, контрацептивная
        защита не
        снижается. Женщина должна принять таблетку как можно скорее, следующую таблетку следует принять в обычное время
      </P>
      <P>
        Если опоздание в приеме таблетки составило более 12 ч, контрацептивная защита может быть снижена.
      </P>
      <P>При этом можно руководствоваться следующими двумя основными правилами:</P>
      <P>- перерыв в приеме таблеток никогда не должен превышать 7 дней.</P>
      <P>
        - 7 дней непрерывного приема таблеток требуются для достижения адекватного подавления гипоталамо гипофизарно
        яичниковой регуляции.
      </P>
      <P>
        <Strong>
          Рекомендации в случае, если опоздание в приеме таблеток составило более 12-36 ч.
        </Strong>
      </P>
      <P>
        <Strong>
          Пропуск таблеток в течение первой недели приема препарата
        </Strong>
      </P>
      <P>
        Следует принять последнюю пропущенную таблетку как можно скорее, даже если это означает прием двух таблеток
        одновременно. Следующую таблетку принимают в обычное время. Дополнительно должен быть использован барьерный
        метод
        контрацепции (например, презерватив) в течение следующих 7 дней. Если половой контакт имел место в течение 7
        дней перед
        пропуском таблетки, необходимо учитывать возможность наступления беременности.
      </P>
      <P>
        <Strong>
          Пропуск таблеток в течение второй недели приема препарата
        </Strong>
      </P>
      <P>
        Следует принять последнюю пропущенную таблетку как можно скорее даже, если это означает прием двух таблеток
        одновременно. Следующую таблетку принимают в обычное время. При условии, что таблетки принимались правильно в
        течение 7 дней, предшествующих первой пропущенной таблетке, нет необходимости в использовании дополнительных
        контрацептивных мер. В противном случае, а также при пропуске двух и более таблеток, необходимо дополнительно
        использовать барьерные методы контрацепции (например, презерватив) в течение 7 дней.
      </P>
      <P>
        <Strong>
          Пропуск таблеток в течение третьей недели приема препарата
        </Strong>
      </P>
      <P>
        Риск снижения контрацептивной эффективности является неизбежным вследствие предыдущего 7-дневного перерыва в
        приеме таблеток. Однако, если придерживаться одного из следующих двух вариантов, то не будет необходимости
        использовать
        какой либо другой метод контрацепции при условии, что в течение 7 дней, предшествующих дню пропуска приема
        таблетки,
        режим дозирования строго соблюдался. В противном случае женщина должна следовать первому из этих двух
        предложенных
        вариантов, и одновременно использовать дополнительный метод контрацепции в течение последующих 7 дней.
      </P>
      <P>
        1. Последнюю пропущенную таблетку следует принять сразу, как только женщина вспомнит об этом, даже если это
        означает
        прием двух таблеток одновременно. Следующие таблетки следует принимать в обычное время. Прием таблеток из каждой
        последующей упаковки следует начинать немедленно после окончания предыдущей, без какого либо перерыва.
        Кровотечение
        после прекращения приема препарата до использования второй упаковки является маловероятным, но могут возникнуть
        «мажущие» кровянистые выделения или «прорывные» кровотечения в период приема таблеток.
      </P>
      <P>
        2. Женщина может также прервать прием таблеток из используемой упаковки на период до 7 дней, включая дни, когда
        прием
        таблеток был пропущен, после чего необходимо начинать прием таблеток из новой упаковки.
      </P>
      <P>
        Если был пропущен прием таблеток и впоследствии не было кровотечений, связанных с отменой препарата во время
        первого
        перерыва в приеме таблеток, то следует исключить беременность.
      </P>
      <P>
        <Strong textDecoration={'underline'}>
          Рекомендации в случае желудочно кишечных расстройств
        </Strong>
      </P>
      <P>
        В случае если у женщины была рвота или диарея в течение 3-4 ч после приема препарата, всасывание может быть
        неполным. В
        этом случае необходимо ориентироваться на рекомендации, касающиеся пропуска приема таблетки. Если женщина не
        хочет
        изменять нормальный режим приема препарата, она должна принять при необходимости дополнительную таблетку из
        другой
        упаковки
      </P>
      <P>
        <Strong textDecoration={'underline'}>
          Изменение дня начала менструалъноподобного кровотечения
        </Strong>
      </P>
      <P>
        Для отсрочки начала менструальноподобного кровотечения необходимо продолжить прием таблеток из новой упаковки
        сразу
        после того, как приняты все таблетки из предыдущей, без перерыва в приеме. Таблетки из новой упаковки могут
        приниматься
        до тех пор, пока упаковка не закончится. На фоне приема препарата из второй упаковки у женщины могут отмечаться
        «мажущие» выделения или маточные кровотечения «прорыва». Возобновить прием препарата из новой упаковки следует
        после обычного 7- дневного перерыва.
      </P>
      <P>
        Для того, чтобы перенести день начала менструальноподобного кровотечения на другой день недели, необходимо
        сократить
        ближайший перерыв в приеме таблеток на столько дней, на сколько необходимо перенести день начала
        менструальноподобного приема таблеток из второй упаковки (так же как в случае отсрочки начала
        менструальноподобного
        кровотечения).
      </P>
      <P>
        <Strong textDecoration={'underline'}>
          Применение у отдельных групп пациенток
        </Strong>
      </P>
      <P>
        Подростки
      </P>
      <P>Препарат Диециклен® может применяться у подростков только после наступления регулярных менструальных циклов
        после
        менархе.</P>
      <P>Пациентки пожилого возраста</P>
      <P>Препарат Диециклен® не показан после наступления менопаузы.</P>
      <P><Strong>Препарат Диециклен® не показан после наступления менопаузы.</Strong></P>
      <P>Препарат Диециклен® не показан после наступления менопаузы.</P>
      <P>
        Препарат Диециклен® специально не изучался у пациенток с нарушением функции почек. Имеющиеся данные не
        предполагают
        изменения режима применения у таких пациенток.
      </P>

      <H1 id={'effect-action'}>Побочное действие</H1>
      <P>
        При применении препарата Диециклен® могут отмечаться нерегулярные кровотечения («мажущие» кровянистые выделения
        или «прорывные» маточные кровотечения), особенно в течение первых месяцев применения. На фоне приема препарата
        Диециклен® у женщин могут наблюдаться и другие нежелательные реакции, указанные ниже.
      </P>
      <P>
        Для определения частоты возникновения нежелательных реакций препарата применяют следующую классификацию: очень
        часто (≥ 1/10), часто (≥ 1/100 и `{'<'}` 1/10), нечасто (≥ 1/1000 и `{'<'}` 1/100), редко (≥ 1/10 000 и `{'<'}`
        1/1000), очень
        редко (
        `{'<'}`
        1/10 000),
        частота неизвестна.
      </P>
      <P>
        <Strong>Нарушения со стороны нервной системы:</Strong> часто головная боль; нечасто мигрень, головокружение;
        редко ишемический
        инсульт, цереброваскулярные расстройства, дистония.
      </P>
      <P>
        <Strong>Нарушения психики:</Strong> нечасто депрессивное настроение; редко депрессия, психические нарушения,
        бессонница,
        нарушения сна, агрессия; частота неизвестна изменение настроения, снижение либидо, повышение либидо.
      </P>
      <P>
        <Strong>Нарушения со стороны органа зрения:</Strong> редко синдром сухого глаза, раздражение слизистой оболочки
        глаз, осциллопсия,
        ухудшение зрения; частота неизвестна непереносимость контактных линз.
      </P>
      <P>
        <Strong>Нарушения со стороны органа слуха и лабиринтные нарушения:</Strong> редко внезапная потеря слуха, шум в
        ушах, вертиго,
        ухудшение слуха.
      </P>
      <P><Strong>Нарушения со стороны сердца:</Strong> редко нарушение функции сердца, тахикардия.</P>
      <P>
        <Strong>Нарушения со стороны сосудов:</Strong> нечасто повышение или понижение артериального давления, редко
        тромбофлебит,
        венозная или артериальная тромбоз/тромбоэмболия, в том числе тромбоэмболия легочной артерии, повышение
        диастолического давления, ортостатическая дистония, «приливы», варикозное расширение вен, заболевания вен, боль
        по ходу
        вен.
      </P>
      <P>
        <Strong>Нарушения со стороны крови и лимфатической системы:</Strong> редко анемия.
      </P>
      <P>
        <Strong>Нарушения со стороны дыхательной системы, органов грудной клетки и средостения:</Strong> редко
        бронхиальная астма,
        гипервентиляция легких.
      </P>
      <P>
        <Strong>Нарушения со стороны желудочно кишечного тракта:</Strong> нечасто боль в животе, дискомфорт, вздутие,
        тошнота, рвота,
        диарея; редко гастрит, энтерит, расстройство пищеварения.
      </P>
      <P>
        <Strong>Нарушения со стороны кожи и подкожных тканей:</Strong> нечасто акне, алопеция, сыпь, включая макулезную,
        зуд (в т.ч.
        генерализованный); редко аллергический дерматит, атопический дерматит, нейродермит, экзема, псориаз,
        гипергидроз,
        хлоазма, нарушение пигментации/гиперпигментация, себорея, перхоть, гирсутизм, заболевания кожи, кожная реакция
        «целлюлит», сосудистые «звездочки»; частота неизвестна крапивница, узловатая эритема, многоформная эритема.
      </P>
      <P>
        <Strong>Нарушения со стороны скелетно мышечной и соединительной ткани:</Strong> редко боль в спине, дискомфорт в
        мышцах и костях,
        миалгия, боли в конечностях.
      </P>
      <P>
        <Strong>Нарушения со стороны половых органови молочной железы:</Strong> часто дискомфорт или боль в молочных
        железах; нечасто
        изменение продолжительности и объема менструальноподобных кровотечений, включая меноррагию, гипоменорею,
        олигоменорею и аменорею; ациклические кровотечения, в т.ч. кровотечения из влагалища и метроррагия; увеличение
        размеров молочных желез, нагрубание и чувство распирания в молочной железе, отек молочных желез, дисменорея,
        выделения из влагалища, боль в области малого таза; редко дисплазия эпителия шейки матки, диспареуния,
        галакторея,
        нарушения менструального цикла; частота неизвестна выделения из молочных желез. Нарушения со стороны эндокринной
        системы: редко вирилизм.
      </P>
      <P><Strong>Нарушения со стороны иммунной системы:</Strong> редко аллергические реакции.</P>
      <P><Strong>Нарушения со стороны обмена веществ и питания:</Strong> нечасто повышение аппетита; редко
        анорексия.</P>
      <P>
        <Strong>Доброкачественные, злокачественные и неуточненные новообразования (включая кисты и
          полипы):</Strong> нечасто кисты
        яичников; редко кисты придатков матки, кисты молочных желез, миома матки, липома молочной железы, фиброзно
        кистозная
        мастопатия.
      </P>
      <P>
        <Strong>Инфекционные и паразитарные заболевания:</Strong> нечасто вагинит/вульвовагинит, вагинальный кандидоз
        или другие грибковые
        вульвовагинальные инфекции; редко сальпингоофорит, инфекции мочевыводящих путей, цистит, мастит, цервицит,
        грибковые
        инфекции, кандидоз, герпетическое поражение полости рта, грипп, бронхит, синусит, инфекции верхних дыхательных
        путей,
        вирусная инфекция.
      </P>
      <P>
        <Strong>Общие расстройства и нарушения в месте введения:</Strong> нечасто повышенная утомляемость, астения,
        плохое самочувствие,
        изменение массы тела (увеличение, снижение и колебания массы тела); редко боли в груди, периферические отеки,
        гриппоподобные состояния, повышение температуры тела; частота неизвестна задержка жидкости.
      </P>
      <P>
        <Strong>Лабораторные и инструментальные данные: </Strong> редко увеличение концентрации триглицеридов в крови,
        гиперхолестеринемия.
      </P>
      <P>Сообщалось о следующих серьезных нежелательных явлениях у женщин, принимавших КОК:</P>
      <UL>
        <LI><P>венозные тромбоэмболические расстройства;</P></LI>
        <LI><P>венозные тромбоэмболические расстройства;</P></LI>
        <LI><P>цереброваскулярные расстройства;</P></LI>
        <LI><P>повышение артериального давления;</P></LI>
        <LI><P>гипертриглицеридемия;</P></LI>
        <LI><P>нарушение толерантности к глюкозе или инсулинорезистентность;</P></LI>
        <LI><P>опухоли печени (доброкачественные и злокачественные);</P></LI>
        <LI><P>нарушение функции печени;</P></LI>
        <LI><P>хлоазма;</P></LI>
        <LI><P>индуцирование или усиление симптомов ангионевротического отека;</P></LI>
        <LI><P>
          появление или усиление заболеваний, при которых связь с приемом КОК не является убедительной: желтуха и/или
          зуд,
          связанный с холестазом; холелитиаз; порфирия; СКВ; гемолитико-уремический синдром; хорея Сиденгама; герпес во
          время
          предшествующей беременности; ухудшение слуха, связанное с отосклерозом; болезнь Крона; язвенный колит; рак
          шейки
          матки;
        </P></LI>
        <LI><P>
          частота диагностирования рака молочной железы у женщин, применяющих КОК, повышена весьма незначительно. Рак
          молочной железы редко наблюдается у женщин до 40 лет, превышение частоты незначительно по отношению к общему
          риску возникновения рака молочной железы. Причинно следственная связь возникновения рака молочной железы с
          применением КОК не установлена. Дополнительную информацию смотрите в разделах «Противопоказания» и «Особые
          указания»;
        </P></LI>
      </UL>

      <H1 id={'over-dozing'}>Передозировка</H1>
      <P>
        Возможные <Strong>симптомы передозировки</Strong> препарата: тошнота, рвота, нерегулярные кровянистые выделения, отсутствие
        менструальноподобного кровотечения.
      </P>
      <P><Strong>Лечение:</Strong> при необходимости проводится симптоматическая терапия.</P>

      <H1 id={'another-medicines'}>Взаимодействие с другими лекарственными средствами</H1>
      <P>
        <Strong textDecoration={'underline'}>
          Влияние других лекарственных средств на препарат Диециклен®
        </Strong>
      </P>
      <P>
        Возможно взаимодействие с лекарственными средствами, индуцирующими микросомальные ферменты печени, в результате
        чего может увеличиваться клиренс половых гормонов, что в свою очередь, может приводить к «прорывным» маточным
        кровотечениям и/или снижению контрацептивного эффекта препарата.
      </P>
      <P>
        Женщинам, которые принимают такие препараты одновременно с препаратом Диециклен®, рекомендуется использовать
        барьерный метод контрацепции или выбрать иной негормональный метод контрацепции. Барьерный метод контрацепции
        следует использовать в течение всего периода приема сопутствующих препаратов, а также в течение 28 дней после их
        отмены.
        Если период применения барьерного метода контрацепции заканчивается позже, чем таблетки в упаковке препарата
        Диециклен®, следует начинать прием таблеток из новой упаковки препарата Диециклен® без перерыва в приеме
        таблеток¦
      </P>

      <UL>
        <LI><P>
          <Strong>Лекарственные средства, увеличивающие клиренс препарата Диециклен® (ослабляющие эффективность путем
            индукции микросомальных ферментов печени):</Strong> фенитоин, барбитураты, примидон, карбамазепин,
          рифампицин и,
          возможно, также окскарбазепин, топирамат, фелбамат, гризеофульвин, а также лекарственные препараты, содержащие
          зверобой продырявленный (Hypericum perforatum)
        </P></LI>
        <LI><P>
          <Strong>Лекарственные средства с различным влиянием на клиренс препарата Диециклен®:</Strong> При совместном
          применении с
          препаратом Диециклен® многие ингибиторы протеаз ВИЧ или вируса гепатита С и ненуклеозидные ингибиторы обратной
          транскриптазы могут как увеличивать, так и уменьшать концентрацию эстрогенов или прогестагенов в плазме крови.
          В
          некоторых случаях такое влияние может быть клинически значимо
        </P></LI>
        <LI><P>
          <Strong>Лекарственные средства, снижающие клиренс препарата Диециклен® (ингибиторы микросомальных ферментов
            печени):</Strong>
          диеногест является субстратом цитохрома Р450 CYP3A4. Сильные и умеренные ингибиторы CYP3A4, такие как
          азольные антимикотики (например, итраконазол, вориконазол, флюконазол), верапамил, макролиды (например,
          кларитромицин, эритромицин), дилтиазем и грейпфрутовый сок могут повышать плазменные концентрации эстрогена
          или
          прогестагена, или их обоих. Было показано, что эторикоксиб в дозах 60 и 120 мг/сутки при совместном приеме с
          КОК,
          содержащими 0,035 мг этинилэстрадиола, повышает концентрации этинилэстрадиола в плазме крови в 1,4 и 1,6 раза,
          соответственно¦
        </P></LI>
        <LI><P>
          <Strong>Влияние препарата Диециклен® на другие лекарственные препараты</Strong> КОК могут влиять на метаболизм
          других
          препаратов, что приводит к повышению (например, циклоспорин) или снижению (например, ламотриджин) их
          концентрации
          в плазме крови и тканях
        </P></LI>
      </UL>

      <P>
        <Strong>In vitro</Strong> этинилэстрадиол является обратимым ингибитором CYP2C19, CYP1A1 и CYP1A2, а также
        необратимым ингибитором
        CYP3A4/5, CYP2C8 и CYP2J2. В клинических исследованиях применение гормонального контрацептива, содержащего
        этинилэстрадиол, не приводило к какому-либо повышению или приводило лишь к слабому повышению концентраций
        субстратов CYP3A4 в плазме крови (например, мидазолама), в то время как плазменные концентрации субстратов
        CYP1A2
        могут возрастать слабо (например, теофиллин) или умеренно (например, мелатонин и тизанидин).
      </P>

      <H1 id={'special-instructions'}>Особые указания</H1>
      <P>
        При наличии любого из перечисленных ниже состояний/заболеваний или факторов риска следует тщательно оценить
        потенциальный риск и ожидаемую пользу от применения препарата и обсудить ее с женщиной до того как она решит
        начать
        прием препарата. В случае усиления симптомов имеющегося заболевания, обострения болезни или появления первых
        признаков этих состояний/заболеваний или факторов риска при применении данного препарата, необходимо
        проконсультироваться с врачом, который может принять решение о необходимости отмены препарата.
      </P>
      <P><Strong>Тромбоз</Strong></P>
      <P>
        Тромбоз образование сгустка крови (тромба), который может закупоривать кровеносный сосуд. При отрыве тромба
        развивается тромбоэмболия. Иногда тромбоз развивается в глубоких венах конечностей (тромбоз глубоких вен),
        сосудах
        сердца (инфаркт миокарда), головного мозга (инсульт) и крайне редко в сосудах других органов. Риск тромбоза
        глубоких вен
        у женщин, принимающих КОК, выше, чем у тех, которые их не принимают, но не так высок, как во время беременности.
      </P>
      <P>
        Результаты эпидемиологических исследований свидетельствуют о наличии взаимосвязи между применением КОК и
        повышенным риском возникновения тромбозов и тромбоэмболических заболеваний, таких как инфаркт миокарда, инсульт,
        тромбоз глубоких вен и эмболии легочной артерии при приеме КОК. Данные осложнения встречаются редко.
      </P>
      <P>
        Риск развития венозной тромбоэмболии (ВТЭ) максимален в первый год приема таких препаратов, преимущественно в
        течение
        первых 3 месяцев. Повышенный риск присутствует после первоначального применения КОК или при возобновлении
        применения одного и того же или разных КОК (после перерыва между приемами препарата в 4 недели и более)
      </P>
      <P>
        Общий риск ВТЭ у пациенток, принимающих низкодозированные КОК (`{'<'}` 50 мкг этинилэстрадиола), в 2-3 раза
        выше, чем у
        небеременных пациенток, которые не принимают КОК, тем не менее, этот риск остается более низким по сравнению с
        риском
        ВТЭ при беременности и родах
      </P>
      <P>
        В очень редких случаях венозная или артериальная тромбоэмболия может привести к летальному исходу.
      </P>
      <P>
        ВТЭ, проявляющаяся в виде тромбоза глубоких вен и/или эмболии легочной артерии, может возникнуть при применении
        любого КОК
      </P>
      <P>
        Крайне редко при применении КОК возникает тромбоз в других кровеносных сосудах, например, венах и артериях
        печени,
        брыжейки, почек, мозга или сетчатки
      </P>
      <P>
        Симптомы тромбоза глубоких вен включают следующее: односторонний отек нижней конечности или вдоль вены на нижней
        конечности, боль и дискомфорт в нижней конечности только в вертикальном положении или при ходьбе, локальное
        повышение
        температуры в пораженной нижней конечности, покраснение или изменение окраски кожных покровов на нижней
        конечности
      </P>
      <P>
        Симптомы тромбоэмболии легочной артерии заключаются в следующем: затрудненное или учащенное дыхание; внезапный
        кашель, в т.ч. с кровохарканьем; острая боль в грудной клетке, которая может усиливаться при глубоком вдохе;
        чувство
        тревоги; сильное головокружение; учащенное сердцебиение. Некоторые из этих симптомов (например, одышка, кашель)
        являются неспецифическими и могут быть истолкованы неверно как признаки других более часто встречающихся и менее
        тяжелых состояний/заболеваний (например, инфекция дыхательных путей)
      </P>
      <P>
        В случае возникновения вышеперечисленных симптомов у женщин, принимающих КОК, необходимо немедленно
        проконсультироваться с врачом.
      </P>

      <P><Strong>Риск развития ВТЭ повышается</Strong></P>
      <UL>
        <LI><P>с возрастом</P></LI>
        <LI><P>
          при курении (при интенсивном курении и с увеличением возраста риск дополнительно повышается, особенно у женщин
          старше 35 лет; женщинам старше 35 лет следует настоятельно рекомендовать отказаться от курения, если они хотят
          принимать препарат Диециклен®)
        </P></LI>
        <LI><P>
          при отягощенном семейном анамнезе (т.е. при наличии в анамнезе случаев венозной тромбоэмболии в относительно
          молодом возрасте у родителей или близких родственников); в случае подозрения на наследственную
          предрасположенность,
          прежде чем принять решение о любой гормональной контрацепции женщине следует проконсультироваться со
          специалистом
        </P></LI>
        <LI><P>
          при длительной иммобилизации, серьезной хирургической операции, любой операции на нижних конечностях или
          обширной травме; в этих ситуациях необходимо прекратить применение КОК (в случае плановой операции не менее,
          чем за
          4 недели) и не возобновлять его до истечения двух недель после полного восстановления двигательной активности;
          если
          применение препарата Диециклен® не было прекращено заранее, следует рассмотреть вопрос об антитромботической
          терапии
        </P></LI>
        <LI><P>
          авиаперелет длительностью более 4 ч
        </P></LI>
        <LI><P>
          при ожирении (индекс массы тела `{'>'}` 30 кг/м2)
        </P></LI>
      </UL>

      <P><Strong>Риск артериальных тромбоэмболических осложнений или нарушения мозгового кровообращения
        повышается</Strong></P>
      <UL>
        <LI><P>с возрастом</P></LI>
        <LI><P>
          при курении (при интенсивном курении и с увеличением возраста риск дополнительно повышается, особенно у женщин
          старше 35 лет; женщинам старше 35 лет следует настоятельно рекомендовать отказаться от курения, если они хотят
          принимать препарат Диециклен®)
        </P></LI>
        <LI><P>
          при дислипопротеинемии
        </P></LI>
        <LI><P>
          при артериальной гипертензии
        </P></LI>
        <LI><P>
          при мигрени
        </P></LI>
        <LI><P>
          при заболеваниях клапанов сердца
        </P></LI>
        <LI><P>
          при мерцательной аритмии
        </P></LI>
        <LI><P>
          при отягощенном семейном анамнезе (т.е. при наличии в анамнезе случаев артериального тромбоза в относительно
          молодом возрасте у родителей или близких родственников). В случае подозрения на наследственную
          предрасположенность, прежде чем принять решение о любой гормональной контрацепции женщине следует
          проконсультироваться со специалистом
        </P></LI>
      </UL>
      <P>
        Нарушения периферического кровообращения также могут отмечаться при сахарном диабете, СКВ,
        гемолитико-уремическом
        синдроме, хронических воспалительных заболеваниях кишечника (болезнь Крона или язвенный колит) и серповидно
        клеточной
        анемии
      </P>
      <P>
        Необходимо учитывать повышенный риск тромбоэмболии в послеродовом периоде.
      </P>
      <P>
        Увеличение частоты и тяжести приступов мигрени во время применения КОК (что может быть предвестником нарушения
        мозгового кровообращения) является причиной для немедленной отмены этих препаратов.
      </P>
      <P>
        В отношении потенциальной роли варикозного расширения вен и поверхностного тромбофлебита в развитии ВТЭ единого
        мнения нет.
      </P>

      <P><Strong>Опухоли</Strong></P>
      <P>
        Наиболее существенным фактором риска возникновения рака шейки матки является персистирующая папилломавирусная
        инфекция. Некоторые эпидемиологические исследования показывают, что длительное применение КОК может внести свой
        вклад в повышение данного риска. Однако до сих пор продолжается дискуссия о том, насколько этот результат
        зависит от
        других факторов, таких как скрининговые исследования шейки матки, более свободное сексуальное поведение и отказ
        от
        барьерных методов контрацепции.
      </P>
      <P>
        Мета анализ результатов 54 эпидемиологических исследований говорит о несколько повышенном относительном риске
        выявления рака молочной железы у женщин, применяющих КОК (относительный риск 1,24). Повышенный риск постепенно
        снижается в течение 10 лет после прекращения приема КОК. Учитывая тот факт, что рак молочной железы редко
        встречается у
        женщин моложе 40 лет, увеличение количества диагностируемого рака молочной железы у женщин, принимающих или
        ранее
        принимавших КОК, мало по сравнению с общим риском этого заболевания. Данные исследования не подтверждают наличие
        причинно следственной связи. Причиной наблюдаемого повышения числа случаев выявления рака молочной железы у
        женщин, принимающих КОК, может быть более ранняя диагностика, биологическое действие препаратов или сочетание
        этих
        факторов. У женщин, принимающих или ранее принимавших КОК, выявляются более ранние стадии рака молочной железы,
        чем
        у женщин, никогда их не принимавших.
      </P>
      <P>
        Были диагностированы отдельные случаи доброкачественных опухолей печени и, значительно реже, злокачественных
        опухолей
        печени у женщин, принимающих КОК. В редких случаях такие опухоли становились причиной угрожающего жизни
        внутрибрюшного кровотечения. В случае возникновения сильной боли в верхней части живота, увеличения печени или
        признаков внутрибрюшного кровотечения у женщин, принимающих КОК, необходимо немедленно проконсультироваться с
        врачом.
      </P>
      <P><Strong>Другие состояния</Strong></P>
      <P>
        У женщин с гипертриглицеридемией или имеющих это заболевание в семейном анамнезе, может быть повышен риск
        возникновения панкреатита при приеме КОК.
      </P>
      <P>
        Несмотря на то, что у многих женщин, принимающих КОК, отмечалось небольшое повышение артериального давления,
        клинически значимое повышение встречается редко. Однако если развивается клинически значимое повышение
        артериального
        давления (выше 140/90 мм рт.ст.) во время приема КОК, прием препарата Диециклен® должен быть прекращен и начато
        лечение артериальной гипертензии. Прием препарата может быть продолжен, если с помощью гипотензивной терапии
        будут
        достигнуты нормальные значения АД.
      </P>
      <P>
        Прием препарата следует прекратить при острых и хронических нарушениях функции печени до тех пор, пока все
        показатели
        функции печени не придут в норму. Рецидивы холестатической желтухи, возникшей впервые во время беременности или
        предшествующего ей периода использования КОК, также требуют прекращения приема препарата.
      </P>
      <P>
        Применение КОК может вызвать появление или ухудшение состояний, для которых связь с применением этих препаратов
        не
        является неоспоримой: желтуха и/или зуд, связанный с холестазом; образование камней в желчном пузыре; порфирия;
        СКВ;
        гемолитико уремический синдром; хорея Сиденгама; герпес во время беременности; ухудшение слуха, связанное с
        отосклерозом.
      </P>
      <P>
        Несмотря на то, что КОК могут оказывать влияние на периферическую инсулинорезистентность и толерантность к
        глюкозе,
        женщинам с сахарным диабетом, принимающим низкодозированные КОК, как правило, не требуется проведения коррекции
        дозы или режима дозирования гипогликемических препаратов. Тем не менее, такие женщины должны находиться под
        тщательным наблюдением.
      </P>
      <P>Болезнь Крона и язвенный колит могут быть связаны с приемом КОК.</P>
      <P>
        Иногда может появляться хлоазма (гиперпигментация кожи лица), особенно если она наблюдалась при беременности.
        Женщины с предрасположенностью к хлоазме во время использования КОК должны остерегаться прямых солнечных лучей и
        воздействия других ультрафиолетовых излучений.
      </P>
      <P>Исчезновение симптомов угревой сыпи (акне) обычно отмечается через 3-4 месяца терапии.</P>
      <P>
        Женщины должны быть предупреждены о том, что препарат Диециклен® не предохраняет их от ВИЧ инфекции и других
        заболеваний, передающихся половым путем.
      </P>

      <P><Strong>Снижение эффективности</Strong></P>
      <P>
        Контрацептивная эффективность КОК может понизиться, например, в случае пропуска приема таблеток, в случае
        расстройств
        со стороны желудочно кишечного тракта (рвота, диарея) или одновременного применения других лекарственных
        препаратов.
      </P>
      <P><Strong>Нерегулярность цикла</Strong></P>
      <P>
        Во время приема любых КОК могут появляться нерегулярные кровотечения («мажущие» выделения или «прорывное»
        кровотечение), особенно в первые месяцы приема таблеток. Кровотечения обычно прекращаются по мере адаптации
        организма к препарату Диециклен® (обычно после трех циклов приема таблеток). Если кровотечения продолжаются и
        степень
        их тяжести возрастает, следует проконсультироваться со специалистом.
      </P>
      <P><Strong>Отсутствие очередного кровотечения «отмены»</Strong></P>
      <P>
        У некоторых женщин кровотечение «отмены» не возникает во время перерыва в приеме таблеток. Если препарат
        принимался в
        соответствии с инструкцией, возникновение беременности маловероятно. Однако если таблетки принимались
        нерегулярно или
        кровотечение «отмены» не возникло 2 раза подряд, то до продолжения применения препарата необходимо исключить
        беременность.
      </P>
      <P><Strong>Лабораторные исследования</Strong></P>
      <P>
        Применение КОК может оказать влияние на результаты лабораторных исследований, включая биохимические показатели
        функции печени, щитовидной железы, надпочечников и почек; на концентрацию белков плазмы крови, например,
        кортикостероид связывающего глобулина, а также на липидный/липопротеиновый состав крови, показатели углеводного
        обмена и показатели свертывающей системы крови. Однако обычно отклонения остаются в пределах диапазона
        нормальных
        лабораторных значений. Медицинские осмотры
      </P>
      <P>
        Перед первым назначением или возобновлением приема препарата Диециклен® необходимо собрать подробный анамнез и
        провести медицинский осмотр с учетом противопоказаний, особых указаний и мер предосторожности. Обследование
        необходимо регулярно повторять. Регулярное медицинское обследование также необходимо из-за наличия
        противопоказаний
        (например, транзиторные ишемические состояния и т.п.) или факторов риска (например, наличие венозного или
        артериального
        тромбоза в семейном анамнезе), которые могут возникнуть впервые только во время приема КОК. Периодичность и
        характер
        таких обследований должны базироваться на утвержденных практических методиках, адаптированных к конкретной
        пациентке,
        но в общем случае они должны включать в себя: исключение беременности; проверку показателей артериального
        давления;
        состояния молочных желез, органов брюшной полости и малого таза, включая цитологическое исследование эпителия
        шейки
        матки; исключение нарушения со стороны системы свертывания крови.
      </P>
      <P>
        В случае длительного применения препарата обследование необходимо проводить не реже 1 раза в 6 месяцев.
      </P>
      <P>Женщина должна проконсультироваться с врачом как можно скорее.</P>
      <UL>
        <LI><P>
          при каких-либо изменениях здоровья, особенно любых состояниях, перечисленных в данной инструкции по применению
          (см. разделы «Противопоказания», «С осторожностью»).
        </P></LI>
        <LI><P>
          при локальном уплотнении в молочной железе.
        </P></LI>
        <LI><P>
          если она собирается применять другие лекарственные препараты (см. раздел «Взаимодействие с другими
          лекарственными
          средствами»).
        </P></LI>
        <LI><P>
          если ожидается длительная иммобилизация (например, на ногу наложен гипс), планируется госпитализация или
          операция
          (необходимо проконсультироваться с врачом за 4-6 недель до нее).
        </P></LI>
        <LI><P>
          при возникновении необычно сильного маточного кровотечения.
        </P></LI>
        <LI><P>
          если женщина забыла принять таблетку в первую неделю приема упаковки (блистера) и имел место половой контакт
          за
          неделю до этого.
        </P></LI>
        <LI><P>
          если у женщины дважды подряд не было очередного кровотечения «отмены» или она подозревает, что беременна
          (нельзя
          начинать прием таблеток из следующей упаковки (блистера), пока она не проконсультируется с врачом).
        </P></LI>
      </UL>

      <H1 id={'managment-transport'}>
        Влияние на способность управления транспортными
        средствами, механизмами
      </H1>
      <P>
        Не выявлено влияние препарата Диециклен® на способность к управлению транспортными средствами и занятиям
        потенциально опасными видами деятельности, требующими повышенной концентрации внимания и быстроты психомоторных
        реакций.
      </P>

      <H1 id={'form-production'}>Форма выпуска</H1>
      <P>
        Таблетки, покрытые пленочной оболочкой 2,0 мг + 0,03 мг. По 21 таблетке помещают в Ал/ПВХ/ПВДХ блистер. По 1 или
        3
        блистера помещают в картонную пачку вместе с инструкцией по медицинскому применению.
      </P>

      <H1 id={'keeper'}>Условия хранения</H1>
      <P>Хранить при температуре не выше 25 C</P>
      <P>Хранить в недоступном для детей месте.</P>

      <H1 id={'deadline'}>Срок годности</H1>
      <P>3 года.</P>
      <P>Не применять препарат по истечении срока годности.</P>

      <H1 id={'holiday'}>Условия отпуска</H1>
      <P>Отпускают по рецепту.</P>

      <H1 id={'owner'}>Владелец (держатель) Регистрационного удостоверения</H1>
      <P>Эксэлтис Хелскеа С.Л.</P>
      <P>Авенида Миралкампо, 7, Полигоно Индустриаль Миралкампо, 19200 Асукеко де Энарес, Гвадалахара, Испания.</P>

      <H1 id={'producer'}>Владелец (держатель) Регистрационного удостоверения</H1>
      <P>Лабораторное Леон Фарма С.А.,</P>
      <P>Провинция Леон, г. Виллаквиламбре 24008, ул. Ла-Валлина, индустриальный полигон Наватехэра, Испания.</P>
      <P><Strong>Претензии потребителей направлять по адресу:</Strong></P>
      <P>Претензии потребителей направлять по адресу:</P>
      <P>119435, г. Москва, Большой Саввинский пер., д.11, офис II, </P>
      <P>Тел./факс: +7 (495) 648 3947, +7 (495) 648 6354.</P>
      <P>Представитель компании Эксэлтис Хелскеа С.Л. Сергачева Е.В</P>

      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Инструкция PDF
      </a>
    </Page>
  )
}
