import {styled} from '@mui/system';
import {PinkLight} from '../../../../styles/color';
import {Box} from '@mui/material';
import AnchorLink from 'react-anchor-link-smooth-scroll'

export const Wrapper = styled(Box)`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1px;
  margin-bottom: 200px;
  color: #FFF;

  & .pink {
    color: ${PinkLight};
  }

  & .white {
    color: #FFF;
  }
  
  & .header {
    font-size: 28px;
    line-height: 33px;
    font-weight: 600;
  }
  
   & .sub-header {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
  }
`

export const StyledButtonAnchorLink = styled(AnchorLink)`
  font-size: 18px;
  text-decoration: underline;
  color: ${PinkLight};
  cursor: pointer;
  position: relative;
  padding-left: 36px;
  border: none;
  background-color: transparent;
  padding: 0;
  padding-left: 30px;
  margin: 0;
  text-align: left;
  
  &.no-border {
    text-decoration: none;
  }
  
  img {
    position: absolute;
    margin-left: -33px;
    margin-top: 1px;
  }
`

