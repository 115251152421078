import {Box} from '@mui/material';

import {StyledAnchorLink, StyledHeader} from '../ui';
import {StyledBoxBorder, StyledBoxInsetShadow, StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {Ticket} from './ticket';
import {HeaderTabs as Tabs} from './header-tabs';

type HeaderTabsProps = {
  tabActive: number
}
const HeaderTabs = ({tabActive}: HeaderTabsProps) => <Tabs tabActive={tabActive} tabLength={3}/>

export const SubpageVidora = () => {
  return (
    <>
      <StyledHeader>Видора® и Видора® Микро</StyledHeader>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <StyledAnchorLink href="#theme1">
          <img src={icons.arrow} alt="*"/>
          Тема #1: Перевод пациенток, которые принимают Ярину и Джес на Видору и Видору микро соответственно
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme2">
          <img src={icons.arrow} alt="*"/>
          Тема #2: Перевод пациенток с акне, принимающих Ярину и Джес на Видора и Видора микро
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme3">
          <img src={icons.arrow} alt="*"/>
          Тема #3: Перевод пациенток с СПКЯ и тяжелой формой ПМС, принимающих Ярину и Джес на Видора и Видора микро
        </StyledAnchorLink>
      </Box>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <HeaderTabs tabActive={0}/>
        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток, которые принимают Ярину и Джес на Видору и Видору микро соответственно.
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила ВИДОРА и ВИДОРА МИКРО <b>10 пациенткам</b> в течение месяца вместо Ярины и
                Джес.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения пациенток, <b>которые используют такие КОК, как Ярина и
                  Джес.”</b>
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Примеры продуктивных вопросов для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с запросом на контрацепцию по более доступной цене, чем Ярина и
                  Джес?
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто в Вашей практике встречаются пациентки, для которых стоимость препарата для контрацепции
                  определяет его выбор?
                </Box>
                <Box sx={{mb: '12px'}}>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких пациенток на приёме около 2–3 в день. А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box>Скольким из этих пациенток Вы назначаете Ярину и Джес?</Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            Назначая ВИДОРЫ вместо Джеса и Ярины в тех же терапевтических нишах, где и оригинальный препарат, вы
            обеспечиваете надёжной и длительной контрацепцией с косметическим эффектом большее количество пациенток.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>ВИДОРА и ВИДОРА МИКРО — это европейские контрацептивы, но в 2 раза дешевле.</Box>
            <Box sx={{mb: '12px'}}>
              Сейчас трудно покупать ежемесячно КОКи по 1 500 –2 000 ₽.
            </Box>
            <Box>
              Чтобы пациентки не отказывались от приёма, нужно предложить снизить затраты, но сохранить высокое
              европейское качество.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme2" sx={{mt: 5}}>
        <HeaderTabs tabActive={1}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток с акне, принимающих Ярину и Джес на Видора и Видора микро.
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила ВИДОРА и ВИДОРА МИКРО 10 пациенткам с акне средней степени тяжести в
                течение месяца вместо Ярины и Джес.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения пациенток с акне.”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Пример продуктивного вопроса для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с акне с запросом на коррекцию данной проблемы?
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с акне средней степени тяжести, для которых стоимость препарата
                  определяет его выбор?
                </Box>
                <Box>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких пациенток на приёме около 1–2 в день. А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box>Скольким из этих пациенток Вы назначаете Ярину и Джес?</Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            Назначая Видоры вместо Джеса и Ярины в тех же терапевтических нишах, где и оригинальный препарат, вы
            обеспечиваете
            надёжной и длительной контрацепцией с косметическим эффектом большее количество пациенток.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              Назначая Видору, Вы обеспечите надежную контрацепцию от нежелательной беременности в дальнейшем и
              дополнительный косметический эффект, повышая приверженность молодой пациентки методу.
            </Box>
            <Box sx={{mb: '12px'}}>
              С учётом того, что Видоры обладают косметическим эффектом, это поможет молодым пациенткам улучшить
              состояние кожи, волос и ногтей. А ведь, как правило, на аборт приходят именно молодые девушки.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme3" sx={{mt: 5}}>
        <HeaderTabs tabActive={2}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток с СПКЯ и тяжелой формой ПМС, принимающих Ярину и Джес на Видора и Видора микро.
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила ВИДОРА и ВИДОРА МИКРО <b>10 пациенткам</b> с СПКЯ и тяжелой формой ПМС в
                течение
                месяца вместо Ярины и Джес.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box>
                  Уделите мне несколько минут для обсуждения пациенток с СПКЯ и симптомами ПМС.”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Пример продуктивного вопроса для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с СПКЯ и тяжелой формой ПМС?
                </Box>
                <Box>
                  — Как часто к Вам обращаются пациентки с СПКЯ и тяжелой формой ПМС, для которых стоимость препарата
                  определяет его выбор?
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box>Скольким из этих пациенток Вы назначаете Ярину и Джес?</Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            <Box>
              Назначая Видоры вместо Джеса и Ярины в тех же терапевтических нишах, где и оригинальный препарат, вы
              обеспечиваете надёжной и длительной контрацепцией большее количество пациенток с СПКЯ и тяжёлой формой
              ПМС, улучшая их качество жизни.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              Видора и Видора микро — это качественные европейские дженерики Ярины и Джеса, по цене в 2 раза доступнее.
            </Box>
            <Box>
              Назначая Видоры вместо Джеса и Ярины в тех же терапевтических нишах, где и оригинальный препарат, вы
              обеспечиваете надёжной и длительной контрацепцией большее количество пациенток с СПКЯ и тяжёлой формой
              ПМС, улучшая их качество жизни.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>
    </>
  )
}
