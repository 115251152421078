import {styled} from '@mui/system';
import {Black, PinkLight} from 'styles/color';

export const WrapperTable = styled('div')({
  '.align-default': {
    '& *> td:not(:first-of-type)': {
      textAlign: 'center',
    }
  },

  '.small': {
    '& *> td': {
      padding: '8px',
    },
  },

  '.align-left': {
    '& *> td': {
      textAlign: 'left',
      verticalAlign: 'top',
    }
  },

  '.border-dark': {
    '& *> td': {
      borderColor: Black,
    }
  },

  '.border-pink': {
    '& *> td': {
      borderColor: PinkLight,
    }
  },

  overflowX: 'auto',
})

export const Table = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  color: '#FFF',
  fontFamily: 'SF-Pro-Text-Regular',

  '& *> td' : {
    padding: '11px',
    border: '1px solid #F7E5E0',
  },

  '.align-left': {
    '& *>tr:not(:first-of-type)': {
      textAlign: 'center',
    },
  },



})

export const Caption = styled('caption')({})

export const Thead = styled('thead')({})

export const Body = styled('tbody')({})

export const Tr = styled('tr')({})

export const Td = styled('td')({
  boxSizing: 'border-box',
})
