import {Div, Img} from 'shared/ui'
import images from 'pages/velledien/img';

type Prop = any;

export const Notice = ({children, ...rest}: Prop) => {
  return (
    <Div
      sx={{borderRadius: '12px', border: '1px solid #E482AE', boxSizing: 'border-box', p: '20px'}}
      {...rest}
    >
      <Img src={images.add}/>
      {children}
    </Div>
  )
}
