import {useState} from 'react';
import {useHistory} from 'react-router-dom'

import {Tab, TabsGroup} from 'components/tabs';
import {ProductModal} from 'components/product-modal/product-modal';
import {ProductModalType} from 'components/product-modal/types';
import {WrapperCouple} from 'components/wrapper-couple';
import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import Page from 'shared/ui/Page';
import {Div, Img, List as UL, ListItem as LI, Tables, Sup, LinkNavigate} from 'shared/ui';
import {Black, PinkLight} from 'styles/color';

import images from './img'
import {H1, H2, Paragraph as P} from './ui';

export const Proginova = () => {
  const history = useHistory();

  const [modal, setModal] = useState<ProductModalType>(null);
  const handleClosePopup = () => {
    setModal(null);
  }

  const handleOpenModal = () => {
    setModal('Материалы');
  }

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  const handleOpenVideo = () => {
    setModal('Видео');
  }

  return (
    <>
      <Page head={'ПРОГИНОВА'} backText="Продукты" onClose={handleRedirectToProducts}>
        <TabsGroup>
          <Tab>
            <LinkNavigate to="/product/proginova/instruction">Инструкция</LinkNavigate>
          </Tab>
          <Tab onClick={handleOpenModal}>Материалы</Tab>
          <Tab onClick={handleOpenVideo}>Видео</Tab>
        </TabsGroup>

        <Div className="text-light" sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <WrapperShell sx={{backgroundImage: `url(${images.main})`, height: 500}}>
            <H1 sx={{color: '#FFF', mt: 42, ml: 6}}>Прогинова® — <br/>помощь новой жизни</H1>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.pregnancy})`}}/>
            <WrapperShell>
              <H2 sx={{fontFamily: 'SF-Pro-Text-Regular', fontWeight: 100}}>
                На сегодняшний день в России существует только один моноэстроген с доказанной эффективностью в ВРТ — это
                Прогинова.
              </H2>
              <H2 sx={{color: PinkLight}}>Позитивный опыт российского использования — более 25 лет.</H2>
              <Div sx={{display: 'flex', gap: 5, mb: 3}}>
                <Img src={images.proginova}/>
                <Div>
                  <H2 sx={{color: PinkLight, mb: 0}}>СОСТАВ:</H2>
                  <P>21 драже белого цвета: 2 мг эстрадиола валерата</P>
                </Div>
              </Div>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight}}>
            <LinkNavigate to="/product/proginova/link1"><H1>Прогинова в ВРТ<Sup>1</Sup></H1></LinkNavigate>
            <P>
              <b>Российская Ассоциация Репродукции Человека ПРИМЕНЕНИЕ ЭСТРОГЕНОВ ПРИ БЕРЕМЕННОСТИ ПРИМЕНЕНИЕ ЭСТРОГЕНОВ
                В ПРОГРАММАХ ВРТ Научно-практические рекомендации, 2015 г.</b>
            </P>
            <P>
              Клиническая практика показывает, что резкая отмена эстрогенов, назначенных пациентке в рамках гормональной
              поддержки, может быть причиной прерывания беременности, так как неизбежно приводит к спаду концентрации и
              дефициту половых гормонов. Рациональной тактикой признана постепенная отмена эстрогенов и прекращение их
              применения в период от 8-й до 12-й недель беременности (время начала формирования и гормональной
              активности плаценты).
            </P>
            <Div sx={{display: 'flex'}}>
              <Div sx={{width: 80, height: 80}}>
                <Img src={images.plus}/>
              </Div>
              <Div sx={{pl: 2}}>
                <H2 sx={{m: 0}}>Назначение Прогиновы:</H2>
                <P>в дозе 15–18 мг — в течение 3 месяцев — с 5-дневным введением прогестерона — 1 раз в 120 дней, —
                  общий период лечения составил 240 дней, — позволило 87 пациенткам из 101 — достичь М-эхо {'>'} 8 мм
                </P>
              </Div>
            </Div>
            <Div sx={{display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2}}>
              <Div sx={{flexBasis: '32%', minWidth: 300}}>
                <Img src={images.girl1}/>
                <P sx={{mt: 1}}>Пациентки на стимуляции овуляции, когда необходима поддержка лютеиновой фазы</P>
              </Div>
              <Div sx={{flexBasis: '32%', minWidth: 300}}>
                <Img src={images.girl2}/>
                <P sx={{mt: 1}}>Пациентки с кровотечениями в цикле ЭКО</P>
              </Div>
              <Div sx={{flexBasis: '32%', minWidth: 300}}>
                <Img src={images.girl3}/>
                <P sx={{mt: 1}}>Пациентки, у которых наступила беременность после разморпереносаоженных эмбрионов</P>
              </Div>
              <Div sx={{flexBasis: '32%', minWidth: 300}}>
                <Img src={images.girl4}/>
                <P sx={{mt: 1}}>Пациентки с тонким эндометрием</P>
              </Div>
              <Div sx={{flexBasis: '32%', minWidth: 300}}>
                <Img src={images.girl5}/>
                <P sx={{mt: 1}}>Пациентки, которым Вы проводите эстрогенный прайминг</P>
              </Div>
              <Div sx={{flexBasis: '32%', minWidth: 300}}>
                <Img src={images.girl6}/>
                <P sx={{mt: 1}}>Пациентки в программе «Донорство ооцитов»</P>
              </Div>
            </Div>
          </WrapperShell>

          <WrapperShell>
            <LinkNavigate to="/product/proginova/link2">
              <H1 sx={{color: PinkLight}}>
                Эстрадиола валерат повышает вероятность наступления беременности в программе ЭКО<Sup>2</Sup>
              </H1>
            </LinkNavigate>
            <P sx={{fontWeight: 'bold'}}>Мета-анализ 15 РКИ. Исходы 2406 циклов ЭКО.&nbsp;
              <LinkNavigate to="/product/proginova/link3">
                Применение эстрадиола<Sup>3</Sup>&nbsp;
              </LinkNavigate>
              <LinkNavigate to="/product/proginova/link2">
                для поддержки лютеиновой фазы<Sup>2</Sup>
              </LinkNavigate></P>
            <WrapperCouple sx={{gap: 3, mt: 5, mb: 2}}>
              <WrapperShell className="small" sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                <Img src={images.plusMedium}/>
                <P>
                  <LinkNavigate to="/product/proginova/link2">
                    Относительная вероятность наступления беременности в группах женщин, принимавших эстрадиол +
                    прогестерон, по сравнению с женщинами, принимавшими только прогестерон, составила 1,18 (95% CI
                    0,98—1,41; p=0,07) для перорального приема<Sup>2</Sup>
                  </LinkNavigate>
                </P>
              </WrapperShell>
              <WrapperShell
                sx={{
                  boxShadow: 'none',
                  border: `1px solid ${PinkLight}`,
                  backgroundImage: `url(${images.chart})`,
                  minWidth: 648,
                  height: 450,
              }}
              />
            </WrapperCouple>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell>
              <H1 sx={{color: PinkLight}}>
                <LinkNavigate to="/product/proginova/link4">
                  Прогинова уменьшает риск кровотечения в цикле ЭКО<Sup>4</Sup>
                </LinkNavigate>
              </H1>
              <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, mb: 2}}>
                <Div sx={{display: 'flex', fontSize: '20px'}}>
                  <Div sx={{width: 100}}><Img src={images.plusMedium}/></Div>
                  <LinkNavigate to="/product/proginova/link4">
                    <Div sx={{ml: 2}}>
                      <P sx={{mt: 0}}>Прием 4 мг Прогинова®<Sup>4</Sup> совместно с прогестероном* в лютеиновую фазу
                        стимулированного цикла:</P>
                      <UL>
                        <LI>статистически значимо повышает частоту имплантации (p=0,035)<Sup>4</Sup></LI>
                        <LI>снижает частоту кровяных выделений (p=0,01)<Sup>4</Sup></LI>
                      </UL>
                      <P sx={{color: PinkLight, fontSize: 16, mt: 6}}>*Со дня забора ооцита до 11 дня после переноса
                        эмбрионов в
                        протоколе с антагонитами ГнРГ.</P>
                    </Div>
                  </LinkNavigate>
                </Div>
              </WrapperShellBox>
            </WrapperShell>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.flasks})`}}/>
          </WrapperCouple>

          <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight}}>
            <H1>
              <LinkNavigate to="/product/proginova/link4">Прогинова®</LinkNavigate><Sup>5</Sup> существенно
              повышает вероятность наступления беременности после переноса размороженных
              эмбрионов
            </H1>
            <P><b>Исходы 5114 циклов переноса размороженных эмбрионов</b></P>
            <LinkNavigate to="/product/proginova/link4">
              <P>Ретроспективное когортное исследование<Sup>5</Sup></P>
            </LinkNavigate>
            <Tables.WrapperTable sx={{overflowX: 'auto'}}>
              <Tables.Table className="text-dark border-dark" sx={{minWidth: 900, borderColor: Black}}>
                <Tables.Thead>
                  <Tables.Tr>
                    <Tables.Td></Tables.Td>
                    <Tables.Td><b>ЕЦ</b></Tables.Td>
                    <Tables.Td><b>полу-ЕЦ</b></Tables.Td>
                    <Tables.Td><b>ЗГТ* (Прогинова)</b></Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td>Кол-во циклов</Tables.Td>
                    <Tables.Td>2216</Tables.Td>
                    <Tables.Td>1180</Tables.Td>
                    <Tables.Td>2018</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Частота имплантации (%)</Tables.Td>
                    <Tables.Td>21,5</Tables.Td>
                    <Tables.Td>25,6</Tables.Td>
                    <Tables.Td>29,3 (p=0,01)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Частота наступления беременности (%)</Tables.Td>
                    <Tables.Td>36,1</Tables.Td>
                    <Tables.Td>42,7</Tables.Td>
                    <Tables.Td>48,7 (p=0,01)</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
            <P sx={{fontSize: 16}}>ЕЦ — естественный цикл.<br/>
              *Прием эстрогенов начинали с 1–4 дня цикла. Доза Прогиновы 2 мг/сутки, 4 мг — 5–8 день, 6 мг — 9–12
              день</P>
          </WrapperShell>

          <WrapperShell sx={{}}>
            <H1 sx={{color: PinkLight}}>
              <LinkNavigate to="/product/proginova/link3">
                Нужно ли дополнительно назначать эстрогены<Sup>3</Sup>&nbsp;
              </LinkNavigate>
              пациенткам с тонким эндометрием?</H1>
            <LinkNavigate to="/product/proginova/link6">
              <P><b>Сравнение исходов беременностей в 85 циклах ЭКО<Sup>6</Sup></b></P>
            </LinkNavigate>
            <Tables.WrapperTable sx={{overflowX: 'auto'}}>
              <Tables.Table sx={{minWidth: 900, borderColor: Black}}>
                <Tables.Thead>
                  <Tables.Tr sx={{color: PinkLight}}>
                    <Tables.Td></Tables.Td>
                    <Tables.Td><b>P</b></Tables.Td>
                    <Tables.Td><b>P + EV</b></Tables.Td>
                    <Tables.Td><b>p</b></Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td>Толщина эндометрия в день введения ЧХГ(мм)</Tables.Td>
                    <Tables.Td>10,74±2,64</Tables.Td>
                    <Tables.Td>12,03±2,81</Tables.Td>
                    <Tables.Td> {'<'} 0,05</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Частота имплантации (%)</Tables.Td>
                    <Tables.Td>10%</Tables.Td>
                    <Tables.Td>26%</Tables.Td>
                    <Tables.Td>{'<'}0,05</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Частота наступления беременности</Tables.Td>
                    <Tables.Td>25,9%</Tables.Td>
                    <Tables.Td>48,3%</Tables.Td>
                    <Tables.Td>{'<'}0,05</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, mt: 5, mb: 2}}>
              <Div sx={{display: 'flex', fontSize: '20px'}}>
                <Div sx={{width: 100}}><Img src={images.plusMedium}/></Div>
                <Div sx={{ml: 2}}>
                  <P sx={{m: 0}}>
                    <LinkNavigate to="/product/proginova/link6">
                      Эстрадиола валерат достоверно увеличивает толщину и чувствительность эндометрия к
                      переносу эмбрионов, что увеличивает частоту наступления беременности<Sup>6</Sup>.
                    </LinkNavigate>
                  </P>
                </Div>
              </Div>
            </WrapperShellBox>
          </WrapperShell>

          <WrapperShell>
            <H1 sx={{color: PinkLight}}>
              <LinkNavigate to="/product/proginova/link7">
                Эстрогенный прайминг у пациенток со слабым ответом яичников на стимуляцию
                снижает риск отмены цикла ЭКО и приводит к повышению частоты наступления беременности<Sup>7</Sup>
              </LinkNavigate>
            </H1>

            <P>
              <LinkNavigate to="/product/proginova/link7">
                Ретроспективное исследование 155 циклов ЭКО у пациенток с предыдущим недостаточным ответом на
                стимуляцию<Sup>7</Sup>
              </LinkNavigate></P>

            <WrapperShell
              sx={{
                boxShadow: 'none',
                backgroundImage: `url(${images.chart2})`,
                minHeight: 322,
                backgroundSize: 'contain'
              }}
            />
            <P sx={{color: PinkLight, fontSize: 16}}>
              *Прогинова® назначалась в дозе 4 мг, начиная с 21-го дня предыдущего цикла стимуляции и до 3-го дня цикла
              стимуляции (28 циклов) либо до дня введения чХГ.</P>
          </WrapperShell>

          <WrapperShell className="text-dark" sx={{backgroundColor: PinkLight}}>
            <H1>Донорство ооцитов и эмбрионов</H1>
            <WrapperCouple sx={{mt: 5}}>
              <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${Black}`, backgroundColor: PinkLight}}>
                <Div sx={{display: 'flex'}}>
                  <Div sx={{minWidth: 50, m: '-6px 0 0 -6px'}}><Img src={images.plusLarge}/></Div>
                  <P sx={{mt: 0, pl: '4px'}}>
                    <LinkNavigate to="/product/proginova/link8">
                      В 2012 г. Изучили исходы 205 беременностей после донации ооцитов и 205
                      беременностей
                      после ИКСИ
                      с аутологичными ооцитами.<Sup>8</Sup>
                    </LinkNavigate>
                  </P>
                </Div>
              </WrapperShellBox>
              <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${Black}`, backgroundColor: PinkLight}}>
                <Div sx={{display: 'flex'}}>
                  <Div sx={{minWidth: 50, m: '-6px 0 0 -6px'}}><Img src={images.plusLarge}/></Div>
                  <P sx={{m: 0}}>
                    <LinkNavigate to="/product/proginova/link8">
                      Не выявлено различий между группами в отношении срока родов, массы плода, длины тела,
                      окружности головы и состояния новорожденных по шкале Апгар.<Sup>8</Sup>
                    </LinkNavigate>
                  </P>
                </Div>
              </WrapperShellBox>
            </WrapperCouple>

            <Div className="media-wrap" sx={{display: 'flex', gap: '20px', mt: '20px', mb: '20px'}}>
              <WrapperShellBox
                sx={{display: 'flex', boxShadow: 'none', border: `1px solid ${Black}`, backgroundColor: PinkLight}}>
                <Div sx={{minWidth: 50, m: '-6px 0 0 -6px'}}><Img src={images.approveLarge}/></Div>
                <P sx={{m: 0}}><b>
                  <LinkNavigate to="/product/proginova/link9">
                    До и после переноса эмбрионов, полученных из донорских ооцитов, требуется ЗГТ
                    препаратами эстрогенов.<Sup>9</Sup>
                  </LinkNavigate>
                </b></P>
              </WrapperShellBox>
              <WrapperShellBox
                sx={{display: 'flex', boxShadow: 'none', border: `1px solid ${Black}`, backgroundColor: PinkLight}}>
                <Div sx={{minWidth: 50, m: '-6px 0 0 -6px'}}><Img src={images.approveLarge}/></Div>
                <P sx={{m: 0}}><b>
                  <LinkNavigate to="/product/proginova/link9">
                    Без адекватной ЗГТ прегравидарная трансформация эндометрия и имплантация эмбрионов
                    невозможны<Sup>9</Sup>
                  </LinkNavigate>
                </b></P>
              </WrapperShellBox>
              <WrapperShellBox
                sx={{display: 'flex', boxShadow: 'none', border: `1px solid ${Black}`, backgroundColor: PinkLight}}>
                <Div sx={{minWidth: 50, m: '-6px 0 0 -6px'}}><Img src={images.approveLarge}/></Div>
                <P sx={{m: 0}}>
                  <b>
                    <LinkNavigate to="/product/proginova/link3">
                      Необходимость и эффективность применения эстрогенов<Sup>3</Sup>
                    </LinkNavigate>
                    <LinkNavigate to="/product/proginova/link9">
                      &nbsp;при беременности, наступившей в
                      программе донорства ооцитов, не вызывает сомнения.<Sup>9</Sup>
                    </LinkNavigate>
                  </b>
                </P>
              </WrapperShellBox>
            </Div>
          </WrapperShell>
        </Div>
      </Page>
      <ProductModal product="proginova/modal" modal={modal} onClose={handleClosePopup}/>
    </>
  )
}
