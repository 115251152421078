import {styled} from '@mui/system';
import {PinkLight} from '../../../../styles/color';

export const Wrapper = styled('div')`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1px;
  margin-bottom: 200px;

  & .pink {
    color: ${PinkLight};
  }

  & .white {
    color: #FFF;
  }
  
  & .header {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  }
  
  & .card {
    max-width: 300px;
    margin-top: 30px;
  }
`;
