import {styled} from '@mui/system';
import {Box} from '@mui/material';
import {PinkLight} from '../../../../styles/color';

export const StyledWrapperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  font-size: 16px;

  & .title {
    margin-top: 4px;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: ${PinkLight}
  }

  & .list {
    padding-left: 30px;

    li {
      margin-bottom: 10px;
    }
  }
  
  & .product-box {
    padding: 16px 0;
    text-align: center;
    width: 293px;
    box-sizing: border-box;
  }
`