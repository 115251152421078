import {useState} from 'react';
import {useHistory} from 'react-router-dom'

import {Tab, TabsGroup} from 'components/tabs';
import {ProductModal} from 'components/product-modal/product-modal';
import {ProductModalType} from 'components/product-modal/types';
import {WrapperCouple} from 'components/wrapper-couple';
import {WrapperShell} from 'components/wrapper-shell';
import Page from 'shared/ui/Page';
import {Img, List as UL, ListItem as LI, Tables, LinkNavigate} from 'shared/ui';
import {Black, Blue, BlueDark, Gray, PinkLight, Yellow} from 'styles/color';

import {H2, Paragraph as P, H1, Span, Div, Sup} from './ui';
import images from './img'

export const Klomonorm = () => {
  const history = useHistory();

  const [modal, setModal] = useState<ProductModalType>(null);
  const handleClosePopup = () => {
    setModal(null);
  }

  const handleOpenModal = () => {
    setModal('Материалы');
  }

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  const handleOpenVideo = () => {
    setModal('Видео');
  }

  return (
    <>
      <Page head={'Климонорм'} backText="Продукты" onClose={handleRedirectToProducts}>
        <TabsGroup>
          <Tab>
            <LinkNavigate to="/product/klomonorm/instruction">Инструкция</LinkNavigate>
          </Tab>
          <Tab onClick={handleOpenModal}>Материалы</Tab>
          <Tab onClick={handleOpenVideo}>Видео</Tab>
        </TabsGroup>

        <Div className="text-dark" sx={{display: 'flex', flexDirection: 'column', gap: '20px', mb: '150px'}}>
          <WrapperShell sx={{backgroundImage: `url(${images.main})`, height: 500}}>
            <H1 sx={{color: Black, mt: 6, ml: 6}}>Климонорм®</H1>
            <H1 sx={{color: '#FFF', mt: 0, ml: 6}}>
              Лечебные <br/>преимущества МГТ
            </H1>
          </WrapperShell>

          <WrapperShell sx={{backgroundColor: PinkLight}}>
            <H1>Демографическая ситуация в мире и России</H1>
            <Div className="wrap-768" sx={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
              <Div sx={{width: 290}}>
                <H2 className="link-black">В мире</H2>
                <P sx={{mt: 8}}>У <Span sx={{fontSize: 48, fontWeight: 'bold', lineHeight: '12px'}}>25</Span> млн.
                  ежегодно наступает менопауза
                  и лишь у <Span sx={{fontWeight: 'bold'}}>10</Span>% из них — без патологических
                  проявлений</P>
                <P sx={{mt: 6}}><Span sx={{fontSize: 48, fontWeight: 'bold', lineHeight: '12px'}}>1,2</Span> млрд. —
                  достигнет эта цифра к 2030 году</P>
              </Div>
              <Div sx={{display: 'flex', alignItems: 'center', flexShrink: 6}}>
                <Img sx={{mt: '30px'}} src={images.globe}/>
              </Div>
              <Div sx={{width: 290}}>
                <Div className="text-blue">
                  <H2 className="link-blue">В России</H2>
                  <P sx={{mt: 8}}>Более <Span sx={{fontSize: 48, fontWeight: 'bold',lineHeight: '12px'}}>42</Span> млн. россиянок уже находятся в пре- и
                    постменопаузальном периоде</P>
                  <P sx={{mt: 6}}>На <Span sx={{fontSize: 48, fontWeight: 'bold', lineHeight: '12px'}}>20</Span> млн. больше ожидают демографы увеличение
                    этой цифры к 2030 году</P>
                </Div>
              </Div>
            </Div>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell sx={{backgroundColor: PinkLight}}>
              <H1>Климакс — новый этап в жизни женщины</H1>
              <P sx={{border: `1px solid ${Black}`, fontWeight: 'bold', p: 2, borderRadius: '12px'}}>
                Менопауза – это биологическое событие, когда на фоне возрастных изменений доминируют инволютивные
                процессы в репродуктивной системе.
              </P>
              <P sx={{fontSize: 48, fontWeight: 'bold', mt: 2, mb: 0}}>40-60%</P>
              <P sx={{mt: 0}}>составляет частота климактерических симптомов</P>
              <P
                sx={{
                  border: `1px solid ${Black}`,
                  p: '16px 16px 16px 70px',
                  borderRadius: '12px',
                  position: 'relative',
                }}
              >
                <Img sx={{position: 'absolute', left: 14, top: 12}} src={images.approveLarge}/>
                <b>Своевременно назначенная МГТ</b>
                с учетом возраста женщины, продолжительности менопаузы, данных личного и семейного анамнеза
                <b>&nbsp;позволяет</b> снизить частоту менопаузальных симптомов, <b>улучшить качество жизни.</b>
              </P>
            </WrapperShell>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.blonde})`}}/>
          </WrapperCouple>

          <WrapperShell>
            <LinkNavigate to="/product/klomonorm/link1">
              <H1 sx={{color: PinkLight}}>Показания для назначения менопаузальной гормональной терапии<Sup>1</Sup></H1>
              <Div className="text-light" sx={{border: `1px solid ${PinkLight}`, borderRadius: '12px', p: 2}}>
                <Img src={images.plus}/>
                <UL sx={{pl: 4}}>
                  <LI><P sx={{mt: 0, mb: 2}}>Вазомоторные симптомы с изменением настроения, нарушением сна</P></LI>
                  <LI><P sx={{mt: 0, mb: 2}}>Симптомы урогенитальной атрофии, сексуальная дисфункция</P></LI>
                  <LI><P sx={{mt: 0, mb: 2}}>Профилактика и лечение остеопороза</P></LI>
                  <LI><P sx={{mt: 0, mb: 2}}>Низкое качество жизни, связанное с климактерием, включая артралгии,
                    мышечные боли, снижение памяти</P></LI>
                  <LI><P sx={{mt: 0, mb: 2}}>Преждевременная и ранняя менопауза</P></LI>
                  <LI><P sx={{mt: 0, mb: 2}}>Овариэктомия</P></LI>
                </UL>
              </Div>
            </LinkNavigate>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell sx={{backgroundColor: PinkLight}}>
              <LinkNavigate to="/product/klomonorm/link2">
                <H1>Влияние МГТ на когнитивные функции<Sup>2</Sup></H1>
              </LinkNavigate>
              <P sx={{position: 'relative', paddingLeft: '45px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plusMedium}/>
                МГТ может оказывать позитивное влияние на когнитивную функцию женщин с хирургической менопаузой, если
                она начата в период проведения овариэктомии
              </P>
              <P sx={{position: 'relative', paddingLeft: '45px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plusMedium}/>
                МГТ, начатая и применяемая после среднего возраста, повышает риск деменции
              </P>
              <P sx={{position: 'relative', paddingLeft: '45px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plusMedium}/>
                МГТ, начатая в среднем возрасте, способствует снижению риска болезни Альцгеймера и деменции
              </P>
              <P sx={{position: 'relative', paddingLeft: '45px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plusMedium}/>
                МГТ может облегчать симптоматику или повышать вероятность ремиссии при депрессиях в период
                менопаузального перехода.
              </P>
            </WrapperShell>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.blondeTwo})`}}/>
          </WrapperCouple>

          <WrapperCouple>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.blondeThree})`}}/>
            <WrapperShell sx={{backgroundColor: PinkLight}}>
              <LinkNavigate to="/product/klomonorm/link3">
                <H1>Влияние МГТ на профилактику Остеопороза<Sup>3</Sup></H1>
              </LinkNavigate>
              <P sx={{position: 'relative', paddingLeft: '45px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plusMedium}/>
                МГТ способствует <b>сохранению качества костей и межпозвонковых дисков</b> у женщин в постменопаузе
              </P>
              <P sx={{position: 'relative', paddingLeft: '45px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plusMedium}/>
                WHI показали <b>снижение риска</b> общих переломов на фоне МГТ на <b>23%</b>, а переломов позвонков и
                шейки бедра на <b>34%</b>
              </P>
              <P sx={{position: 'relative', paddingLeft: '45px'}}>
                <Img sx={{position: 'absolute', left: -6, top: -6}} src={images.plusMedium}/>
                На фоне приема <b>МГТ с левоноргестрелом было отмечено улучшение</b> показателей МПКТ,
                измеренных с помощью денситометрии, на <b>8%</b> по сравнению с группой контроля.
              </P>
            </WrapperShell>
          </WrapperCouple>

          <WrapperShell>
            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none'}}>
                <H2 sx={{color: PinkLight, mt: 0}}>
                  Климонорм® — единственный препарат МГТ с лечебными преимуществами для пациенток
                  с АМК, со снижением когнитивных функций, либидо, ПНЯ и хирургической менопаузой.
                </H2>
                <Img sx={{mt: 4}} src={images.product}/>
                <H2 sx={{color: PinkLight}}>СОСТАВ:</H2>
                <P sx={{color: '#FFF'}}>9 драже желтого цвета: 2 мг эстрадиола валерата</P>
                <P sx={{color: '#FFF'}}>12 драже коричневого цвета: 2 мг эстрадиола валерата + 150 мкг
                  левоноргестрела</P>
              </WrapperShell>
              <WrapperShell className="small" sx={{backgroundImage: `url(${images.blondeFour})`, boxShadow: 'none'}}/>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell className="text-light">
            <LinkNavigate to="/product/klomonorm/link4">
              <H1 sx={{color: PinkLight}}>Терапевтические ниши для назначения Климонорма<Sup>4</Sup></H1>
            </LinkNavigate>
            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none', p: 0, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <Div sx={{border: `1px solid ${PinkLight}`, borderRadius: '12px', pl: 9, pr: 2, position: 'relative'}}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 16,
                      top: 16,
                      backgroundImage: `url(${images.ellipse})`,
                      width: 50,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <H2 sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>1</H2>
                  </Div>
                  <H2 sx={{mt: 3}}>Менопаузальная гормональная терапия:</H2>
                  <P>вазомоторные и психосоматические симптомы у женщин в периоде менопаузального перехода.</P>
                  <P><b>Особые преимущества у женщин с АМК в перименопаузе.</b></P>
                </Div>
                <Div className="clamp"
                     sx={{border: `1px solid ${PinkLight}`, borderRadius: '12px', pl: 9, pr: 2, position: 'relative'}}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 16,
                      top: 16,
                      width: 50,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <Img src={images.plus}/>
                  </Div>
                  <H2 sx={{color: PinkLight}}>Обоснование назначения</H2>
                  <P>В периоде менопаузального перехода рекомендуется МГТ двухфазными препаратами для оптимального
                    контроля менструальноподобных кровотечений. Доза эстрадиола 2 мг позволяет быстро купировать
                    климактерические жалобы.</P>
                  <P>Сильная прогестагенная активность ЛНГ обеспечивает надежный контроль менструального цикла и
                    эндометрия у женщин с АМК и безопасность применения МГТ при гиперплазии эндометрия в недавнем
                    анамнезе.</P>
                </Div>
              </WrapperShell>

              <WrapperShell sx={{boxShadow: 'none', p: 0, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <Div sx={{border: `1px solid ${PinkLight}`, borderRadius: '12px', pl: 9, pr: 2, position: 'relative'}}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 16,
                      top: 16,
                      backgroundImage: `url(${images.ellipse})`,
                      width: 50,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <H2 sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>2</H2>
                  </Div>
                  <H2 sx={{mt: 3}}>Менопаузальная гормональная терапия:</H2>
                  <P>вазомоторные и психосоматические симптомы у женщин в периоде менопаузального перехода.</P>
                  <P><b>Особые преимущества у женщин с астенодепрессивными симптомами.</b></P>
                </Div>
                <Div className="clamp"
                     sx={{border: `1px solid ${PinkLight}`, borderRadius: '12px', pl: 9, pr: 2, position: 'relative'}}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 16,
                      top: 16,
                      width: 50,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <Img src={images.plus}/>
                  </Div>
                  <H2 sx={{color: PinkLight}}>Обоснование назначения</H2>
                  <P>Спектр климактерических расстройств разнообразен и у ряда женщин в периоде менопаузального
                    перехода он включает астенические жалобы и снижение полового влечения, частично отражающие
                    недостаток андрогенных влияний.</P>
                  <P>Остаточная андрогенная активность ЛНГ позволяет дополнительно повысить качество жизни, улучшить
                    когнитивные функции, работоспособность и сексуальное желание.</P>
                </Div>
              </WrapperShell>
            </WrapperCouple>

            <WrapperCouple sx={{mt: '40px', mb: 2}}>
              <WrapperShell sx={{boxShadow: 'none', p: 0, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <Div sx={{border: `1px solid ${PinkLight}`, borderRadius: '12px', pl: 9, pr: 2, position: 'relative'}}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 16,
                      top: 16,
                      backgroundImage: `url(${images.ellipse})`,
                      width: 50,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <H2 sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>3</H2>
                  </Div>
                  <H2 sx={{mt: 3}}>Заместительная гормональная терапия</H2>
                  <P>у женщин с преждевременной недостаточностью яичников.</P>
                </Div>
                <Div className="clamp"
                     sx={{border: `1px solid ${PinkLight}`, borderRadius: '12px', pl: 9, pr: 2, position: 'relative'}}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 16,
                      top: 16,
                      width: 50,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <Img src={images.plus}/>
                  </Div>
                  <H2 sx={{color: PinkLight}}>Обоснование назначения</H2>
                  <P>Преждевременная недостаточность яичников является независимым показанием к длительному назначению
                    ЗГТ до возраста естественной менопаузы.
                  </P>
                  <P>Оптимальная доза эстрадиола — 2 мг, оптимальный режим — циклический, имитирующий менструальный
                    цикл. Преимущества ЛНГ в составе ЗГТ связаны с наблюдаемым у большинства пациенток (в отличие от
                    естественной менопаузы) снижением синтеза всех половых стероидов, в том числе андрогенов.
                  </P>
                </Div>
              </WrapperShell>

              <WrapperShell sx={{boxShadow: 'none', p: 0, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <Div sx={{border: `1px solid ${PinkLight}`, borderRadius: '12px', pl: 9, pr: 2, position: 'relative'}}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 16,
                      top: 16,
                      backgroundImage: `url(${images.ellipse})`,
                      width: 50,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <H2 sx={{m: 0, p: 1, mt: '1px', textAlign: 'center', color: PinkLight}}>4</H2>
                  </Div>
                  <H2 sx={{mt: 3}}>Заместительная гормональная терапия </H2>
                  <P>у женщин с хирургической менопаузой с сохраненной маткой.</P>
                </Div>
                <Div className="clamp"
                     sx={{
                       border: `1px solid ${PinkLight}`,
                       borderRadius: '12px',
                       pl: 9,
                       pr: 2,
                       position: 'relative',
                       height: '100%'
                     }}>
                  <Div
                    sx={{
                      position: 'absolute',
                      left: 16,
                      top: 16,
                      width: 50,
                      backgroundRepeat: 'no-repeat'
                    }}>
                    <Img src={images.plus}/>
                  </Div>
                  <H2 sx={{color: PinkLight}}>Обоснование назначения</H2>
                  <P>Хирургическое выключение овариальной функции всегда сопряжено с дефицитом всех стероидов, поэтому
                    назначение Климонорма, содержащего ЛНГ, предпочтительно у женщин с интактной маткой.</P>
                  <P>Женщинам с удаленной маткой рекомендуется использование чисто эстрогенной терапии (2 мг эстрадиола
                    валерата — Прогинова)</P>
                </Div>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell sx={{backgroundColor: PinkLight}}>
            <LinkNavigate to="/product/klomonorm/link5">
              <H1>Климонорм® — профиль безопасности<Sup>5</Sup></H1>
            </LinkNavigate>
            <Div sx={{border: `1px solid ${Black}`, borderRadius: '12px', pl: 9, pr: 2, position: 'relative'}}>
              <Img sx={{position: 'absolute', left: 14, top: 14}} src={images.approveLarge}/>
              <P>• В ходе постмаркетинговых исследований выявлено, что Климонорм® </P>
              <P>
                <LinkNavigate to="/product/klomonorm/link5">
                  • не оказывает существенного влияния на массу тела<Sup sx={{color: Black}}>5</Sup>
                </LinkNavigate>
              </P>
              <P>
                <LinkNavigate to="/product/klomonorm/link5">
                  • стабилизирует артериальное давление<Sup sx={{color: Black}}>5</Sup>
                </LinkNavigate>
              </P>
              <P>
                <LinkNavigate to="/product/klomonorm/link5">
                  • не нарушает функцию сосудистой стенки и гемостаз<Sup sx={{color: Black}}>5</Sup>
                </LinkNavigate>
              </P>
              <P>
                <LinkNavigate to="/product/klomonorm/link5">
                  • не повышает уровень печеночных ферментов<Sup sx={{color: Black}}>5</Sup>
                </LinkNavigate>
              </P>
              <P>
                <LinkNavigate to="/product/klomonorm/link5">
                  • не вызывает нарушений нормального липидного и углеводного обмена у женщин с исходно нормальными
                  показателями<Sup sx={{color: Black}}>5</Sup>
                </LinkNavigate>
              </P>
              <P>
                <LinkNavigate to="/product/klomonorm/link5">
                  • хорошо переносится пациентками, не вызывает серьезных побочных эффектов<Sup
                  sx={{color: Black}}>5</Sup>.
                </LinkNavigate>
                <LinkNavigate to="/product/klomonorm/link4">
                  Более 92 % женщин
                  оценили переносимость Климонорм® как «очень хорошую» и «хорошую». Около 85 % женщин сообщили о желании
                  продолжать прием Климонорма<Sup sx={{color: Black}}>4</Sup>
                </LinkNavigate>
                </P>
              <P>
                <LinkNavigate to="/product/klomonorm/link5">
                  • не оказывает отрицательного влияния на состояние молочных желез, на липидный баланс при использовании
                  в течение 5 лет<Sup sx={{color: Black}}>5</Sup>
                </LinkNavigate>
              </P>
            </Div>
            <Div sx={{
              border: `1px solid ${Black}`,
              borderRadius: '12px',
              pl: 9,
              pr: 2,
              position: 'relative',
              fontWeight: 'bold',
              mt: '20px'
            }}>
              <Img sx={{position: 'absolute', left: 14, top: 14}} src={images.plusLarge}/>
              <H2>Благодаря оптимальному балансу доз эстрогена и прогестерона Климонорм® оказывает благоприятное влияние
                на липидный профиль крови и показатели углеводного обмена</H2>
            </Div>
          </WrapperShell>

          <WrapperShell sx={{backgroundColor: PinkLight, display: 'flex', gap: '20px', flexWrap: 'nowrap', pt: 5}}>
            <Div sx={{maxWidth: 320, margin: 'auto', mt:0}}>
              <Img src={images.chartOne}/>
              <P><b>Показатели липидного спектра крови на фоне терапии с применением Климонорм®</b></P>
            </Div>
            <Div sx={{maxWidth: 320, margin: 'auto', mt:0}}>
              <Img src={images.chartTwo}/>
              <P><b>Показатели углеводного обмена на фоне терапии с применением Климонорм®</b></P>
            </Div>
            <Div sx={{maxWidth: 320, margin: 'auto', mt:0}}>
              <Img src={images.chartThree}/>
              <P><b>Уровень печеночных ферментов на фоне терапии с применением Климонорм®</b></P>
            </Div>
          </WrapperShell>

          <WrapperShell>
            <LinkNavigate to="/product/klomonorm/link6">
              <H1 sx={{color: PinkLight}}>
                Риски венозных тромбозов у пользователей КОК в зависимости от типа гестагена<Sup>6</Sup>
              </H1>
            </LinkNavigate>

            <Tables.WrapperTable sx={{overflowX: 'auto', mb: 2}}>
              <Tables.Table sx={{minWidth: 900, tableLayout: 'fixed'}}>
                <Tables.Thead>
                  <Tables.Tr sx={{backgroundColor: BlueDark}}>
                    <Tables.Td><b>VTE confirmed</b></Tables.Td>
                    <Tables.Td><b>Vinogradova</b></Tables.Td>
                    <Tables.Td><b>Lidegaard</b></Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr sx={{backgroundColor: Blue}}>
                    <Tables.Td sx={{backgroundColor: Yellow, color: Black}}>Non use</Tables.Td>
                    <Tables.Td>Ireference</Tables.Td>
                    <Tables.Td>Ireference</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>COC levonorgestrel</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Gray}}>3,0 (2,6–3,3)</Tables.Td>
                    <Tables.Td>3,0 (2,2–4,0)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: Gray}}>
                    <Tables.Td>COC norgestimate</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>3,5 (2,9–4,4)</Tables.Td>
                    <Tables.Td>3,5 (2,9–4,3)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>COC desogestrel</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Gray}}>6,2 (5,0–7,7)</Tables.Td>
                    <Tables.Td>6,6 (5,6–7,6)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: Gray}}>
                    <Tables.Td>COC gestodene</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>6,5 (5,0–8,4)</Tables.Td>
                    <Tables.Td>6,2 (5,6–7,0)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>COC drospirenone</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Gray}}>6,1 (4,7–7,8)</Tables.Td>
                    <Tables.Td>6,4 (5,4–7,5)</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: Gray}}>
                    <Tables.Td>COC cyproterone</Tables.Td>
                    <Tables.Td sx={{backgroundColor: Blue}}>6,0 (4,7–7,7)</Tables.Td>
                    <Tables.Td>6,0 (5,1–7,9)</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
          </WrapperShell>

          <WrapperShell>
            <H1 sx={{color: PinkLight}}>Фитоэстрогены</H1>
            <WrapperCouple className="text-light" sx={{mb: 2}}>
              <WrapperShell sx={{boxShadow: 'none', p: 0, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <Div sx={{
                  border: `1px solid ${PinkLight}`,
                  borderRadius: '12px',
                  pl: 9,
                  pr: 2,
                  position: 'relative',
                }}>
                  <Img sx={{position: 'absolute', left: 14, top: 14}} src={images.warn}/>
                  <H2 sx={{mt: 2, mb: 0}}>Оказывают незначительный клинический эффект</H2>

                  <LinkNavigate to="/product/klomonorm/link7">
                    <P sx={{mt: 1}}>Соевые добавки и препараты цимицифуги по своей эффективности не превосходят
                      плацебо для лечения вазомоторных симптомов<Sup>7</Sup></P>
                  </LinkNavigate>
                </Div>

                <Div sx={{
                  border: `1px solid ${PinkLight}`,
                  borderRadius: '12px',
                  pl: 9,
                  pr: 2,
                  position: 'relative',
                }}>
                  <Img sx={{position: 'absolute', left: 14, top: 14}} src={images.warn}/>
                  <H2 sx={{mt: 2, mb: 0}}>Недостаточно изучена эффективность и безопасность</H2>

                  <LinkNavigate to="/product/klomonorm/link8">
                    <P sx={{mt: 1}}>Отдельные клинические случаи подтверждают развитие тяжелых неблагоприятных эффектов,
                      требуют дальнейшего внимания на фоне фитоэстрогенов<Sup>8</Sup></P>
                  </LinkNavigate>
                </Div>
                <Div sx={{
                  border: `1px solid ${PinkLight}`,
                  borderRadius: '12px',
                  pl: 9,
                  pr: 2,
                  position: 'relative',
                }}>
                  <Img sx={{position: 'absolute', left: 14, top: 14}} src={images.warn}/>
                  <LinkNavigate to="/product/klomonorm/link9">
                    <H2 sx={{mt: 2, mb: 0}}>Не содержат «биоидентичные» гормоны<Sup>9</Sup></H2>
                  </LinkNavigate>

                  <P sx={{mt: 1}}>Фитоэстрогены, получаемые из растений и «натуральные» эстрогены, содержащиеся в соевых
                    продуктах (соевое молоко и тофу), структурно не сходны с эндогенными человеческими
                    гормонами</P>
                </Div>
              </WrapperShell>
              <WrapperShell className="small" sx={{
                width: 500,
                p: 0,
                boxShadow: 'none',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end'
              }}>
                <Img src={images.balance}/>
                <P sx={{fontSize: 16, mb: 0}}>
                  Фитоэстрогены — собирательный термин, используемый для обозначения дных веществ растительного
                  происхождения, относящихся к классу полифенольных соединений, обладающих эстрогеноподобным влиянием на
                  организм человека.
                </P>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>
        </Div>
      </Page>
      <ProductModal product="klomonorm" modal={modal} onClose={handleClosePopup}/>
    </>
  )
}
