import {Box} from '@mui/material';

import {StyledAnchorLink, StyledHeader} from '../ui';
import {StyledBoxBorder, StyledBoxInsetShadow, StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {Ticket} from './ticket';
import {HeaderTabs as Tabs} from './header-tabs';

type HeaderTabsProps = {
  tabActive: number
}
const HeaderTabs = ({tabActive}: HeaderTabsProps) => <Tabs tabActive={tabActive} tabLength={3}/>

export const SubpageMicroginon = () => {
  return (
    <>
      <StyledHeader>Микрогинон®</StyledHeader>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <StyledAnchorLink href="#theme1">
          <img src={icons.arrow} alt="*"/>
          Тема #1: Перевод здоровых пациенток, принимающих в качестве контрацепции Ригевидон на Микрогинон
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme2">
          <img src={icons.arrow} alt="*"/>
          Тема #2: Перевод пациенток с обильными менструациями, принимающих Ригевидон на Микрогинон
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme3">
          <img src={icons.arrow} alt="*"/>
          Тема #3: Перевод пациенток с астено-депрессивной симптоматикой, принимающих Ригевидон на Микрогинон
        </StyledAnchorLink>
      </Box>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <HeaderTabs tabActive={0}/>
        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод здоровых пациенток, принимающих в качестве контрацепции Ригевидон на Микрогинон.
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор в течение месяца назначила МИКРОГИНОН® <b>20 здоровым пациенткам</b> вместо
                Ригевидона.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения здоровых пациенток, <b>которые нуждаются в надежной
                  контрацепции.</b>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Как часто к Вам обращаются здоровые пациентки с запросом на контрацепцию?
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box>Примеры вопросов для определения выписки:</Box>
                <ol className="list">
                  <li>
                    Скольким из этих пациенток Вы назначаете Ригевидон?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы препарат для контрацепции не только надежно защищал от нежелательной
                    беременности, но и обладал дополнительными лечебными эффектами?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы препарат для контрацепции не только надежно защищал от нежелательной
                    беременности, но и способствовал улучшению настроения, повышению либидо и качества жизни пациентки?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №1" img={icons.voice}>
            По последним данным, представленным на XIV Конгрессе Европейского общества по контрацепции и репродуктивному
            здоровью в мае 2016 года в Базеле (Швейцария), ЛНГ обладает самым низким риском венозных тромбозов по
            сравнению с любым другим гестагеном, что делает МИКРОГИНОН® контрацептивом выбора для здоровых женщин и для
            женщин с факторами риска.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB I:" img={icons.star}>
            <Box>
              В отличие от любого другого контрацептива, МИКРОГИНОН® может быть назначен пациенткам с неизвестными
              тромбофилическими рисками, т. к. ЛНГ демонстрирует самый лучший профиль безопасности по сравнению с любым
              другим гестагеном.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №2" img={icons.voice}>
            МИКРОГИНОН® обладает дополнительными лечебными эффектами за счёт самого сильного гестагена ЛНГ в составе.
            ЛНГ надёжно защищает эндометрий от рецидивов гиперпластических и неопластических процессов.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB II:" img={icons.star}>
            <Box>
              ЛНГ — самый сильный гестаген. Благодаря ЛНГ в составе МИКРОГИНОН® является препаратом выбора для женщин с
              АМК и гиперплазией. Назначая Вашим пациенткам МИКРОГИНОН® Вы способствуете профилактике гиперпластических
              процессов и железодефицитной анемии при АМК.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №3" img={icons.voice}>
            МИКРОГИНОН® — самый изученный и самый назначаемый в мире ОК. Комбинация ЭЭ+ЛНГ является стандартом сравнения
            всех ОК в клинических исследованиях. А остаточная андрогенная активность ЛНГ делает МИКРОГИНОН® препаратом
            выбора для пациенток с астено-депрессивной симптоматикой, способствуя улучшению настроения, повышению либидо
            и качества жизни.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB III:" img={icons.star}>
            <Box>
              ЛНГ в составе МИКРОГИНОНА® обладает остаточной андрогенной активностью. Таким образом, назначая здоровым
              пациенткам в качестве контрацепции Микрогинон Вы помогаете им улучшить настроение, повысить либидо и
              улучшить качество жизни.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme2" sx={{mt: 5}}>
        <HeaderTabs tabActive={1}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток с обильными менструациями, принимающих Ригевидон на Микрогинон
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                ЦЕЛЬ ВИЗИТА ПО SMART:
                Хочу, чтобы доктор в течение месяца назначила МИКРОГИНОН® <b>20 пациенткам</b> с обильными менструациями
                вместо Ригевидона.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения пациенток репродуктивного возраста с обильными
                  менструациями, которые бывают у Вас на приёме.”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки репродуктивного возраста с обильными менструациями?
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box sx={{mb: '12px'}}>Примеры вопросов для определения выписки:</Box>
                <ol className="list">
                  <li>Скольким из этих пациенток Вы назначаете Ригевидон?</li>
                  <li>
                    Насколько для Вас важно, чтобы эффективный препарат при АМК обладал высокой степенью безопасности и
                    имел большую доказательную базу?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            Благодаря сильному гестагену ЛНГ, МИКРОГИНОН® является препаратом выбора для женщин с АМК, способствуя
            профилактике железодефицитной анемии.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box>
              ЛНГ — самый сильный гестаген. Благодаря ЛНГ в составе МИКРОГИНОН® является препаратом выбора для женщин с
              АМК, способствуя профилактике железодефицитной анемии. Кроме того, МИКРОГИНОН® — самый изученный и самый
              назначаемый в мире ОК. Комбинация ЭЭ+ЛНГ является стандартом сравнения всех ОК в клинических
              исследованиях.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme3" sx={{mt: 5}}>
        <HeaderTabs tabActive={2}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток с астено-депрессивной симптоматикой, принимающих Ригевидон на Микрогинон.
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор в течение месяца назначила МИКРОГИНОН® 20 пациенткам с астено-депрессивной
                симптоматикой, которые нуждаются в контрацепции вместо Ригевидона.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения пациенток с астено-депрессивной симптоматикой, которые
                  нуждаются в контрацепции.”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px;'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box>
                  — Как часто к Вам обращаются пациентки с астено-депрессивной симптоматикой, которые нуждаются в
                  контрацепции?
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <ol className="list">
                  <li>Скольким из этих пациенток Вы назначаете Ригевидон?</li>
                  <li>Насколько для Вас важно, чтобы эффективный препарат при АМК обладал высокой степенью безопасности
                    и имел большую доказательную базу?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            <Box sx={{mb: '12px'}}>
              ЛНГ в составе МИКРОГИНОНА® обладает остаточной андрогенной активностью, способствуя улучшению настроения,
              повышению либидо и качества жизни. При этом МИКРОГИНОН® — это эталонный ОК, с которым сравнивают все
              другие контрацептивы.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box>
              МИКРОГИНОН® — оригинальный эталонный КОК с ЛНГ по доступной цене. ЛНГ в составе МИКРОГИНОНА® обладает
              остаточной андрогенной активностью, способствуя улучшению настроения, повышению либидо и качества жизни.
              Таким образом, назначая МИКРОГИНОН®, Вы обеспечиваете пациенток надежной контрацепцией по доступной цене,
              которая способствует снижению астено-депрессивной симптоматики и улучшает качество жизни.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>
    </>
  )
}
