import {Box} from '@mui/material';

import {StyledWrapperBox} from './ui';
import {StyledBoxShadow, StyledCircle, StyledHeader} from '../../ui';
import {PinkLight} from '../../../../styles/color';
import {icons, portraits} from '../../img';

export const EffectiveSalesPositioning = () => {
  return (
    <>
      <StyledHeader sx={{mb: 5, mt: 7}}>Позиционирование</StyledHeader>

      <StyledWrapperBox>
        <Box className="container">
          <Box sx={{m: '-6px', mb: '-11px'}}>
            <img src={portraits.p_klimonorm} alt="Климонорм"/>
          </Box>
          <StyledBoxShadow>
            <Box className="title">КЛИМОНОРМ</Box>

            <Box className="container__box" sx={{mb: 4}}>
              <StyledCircle className="border" sx={{padding: '2px'}}>
                <img src={icons.star} alt="*"/>
              </StyledCircle>
              <Box sx={{color: PinkLight}}>
                Единственный препарат МГТ
                с лечебными преимуществами
                для пациенток с АМК, со снижением
                когнитивных функций, либидо, ПНЯ
                и хирургической менопаузой.
              </Box>
            </Box>

            <Box className="container__box">
              <StyledCircle className="border border--white" sx={{padding: '2px 2px 2px 4px'}}>
                <img src={icons.person} alt="*"/>
              </StyledCircle>
              <Box>
                <Box sx={{fontSize: 20, fontWeight: 700, mb: 2}}>
                  ДЛЯ КОГО КЛИМОНОРМ:
                </Box>
                <Box>
                  Для врачей акушеров-гинекологов амбулаторного
                  звена КЛИМОНОРМ® является оптимальным
                  препаратом для сохранения здоровья пациенток
                  в зрелом возрасте за счёт преимуществ ЛНГ
                  перед любым другим гестагеном, рекомендованным
                  Минздравом в Протоколах МГТ.
                </Box>
              </Box>
            </Box>

          </StyledBoxShadow>
        </Box>

        <Box className="container">
          <Box sx={{m: '-6px', mb: '-11px'}}>
            <img src={portraits.p_proginova} alt="Прогинова"/>
          </Box>
          <StyledBoxShadow>
            <Box className="title">ПРОГИНОВА</Box>

            <Box className="container__box" sx={{mb: 4}}>
              <StyledCircle className="border" sx={{padding: '2px'}}>
                <img src={icons.star} alt="*"/>
              </StyledCircle>
              <Box sx={{color: PinkLight}}>
                ПРОГИНОВА® является
                оптимальным средством коррекции
                уровня эстрогенов с доказанной
                эффективностью и безопасностью. При длительном
                использовании снижает риски рака молочной железы
                и первичного рака печени, обладает выраженным
                нейропротективным эффектом.
              </Box>
            </Box>

            <Box className="container__box">
              <StyledCircle className="border border--white" sx={{padding: '2px 2px 2px 4px'}}>
                <img src={icons.person} alt="*"/>
              </StyledCircle>
              <Box>
                <Box sx={{fontSize: 20, fontWeight: 700, mb: 2}}>
                  ДЛЯ КОГО ПРОГИНОВА:
                </Box>
                <Box sx={{mb: 2}}>
                  1. Для врачей-репродуктологов в программах
                  вспомогательных репродуктивных технологий
                  и в протоколах контролируемой стимуляции
                  овуляции.
                </Box>
                <Box>
                  2. Для гинекологов амбулаторного звена, у которых
                  на приёме есть пациентки с врождённым
                  и приобретённым гипогонадизмом, пациентки после
                  тотальной гистерэктомии, пациентки с Миреной
                  в период перименопаузы.
                </Box>
              </Box>
            </Box>

          </StyledBoxShadow>
        </Box>

        <Box className="container">
          <Box sx={{m: '-6px', mb: '-11px'}}>
            <img src={portraits.p_micogynom} alt="Микрогинон"/>
          </Box>
          <StyledBoxShadow>
            <Box className="title">МИКРОГИНОН</Box>

            <Box className="container__box" sx={{mb: 4}}>
              <StyledCircle className="border" sx={{padding: '2px'}}>
                <img src={icons.star} alt="*"/>
              </StyledCircle>
              <Box sx={{color: PinkLight}}>
                Для всех женщин репродуктивного
                возраста, для которых важны
                профиль безопасности контрацептива
                и экономическая выгода наряду с высоким качеством
                оригинального препарата.
              </Box>
            </Box>

            <Box className="container__box">
              <StyledCircle className="border border--white" sx={{padding: '2px 2px 2px 4px'}}>
                <img src={icons.person} alt="*"/>
              </StyledCircle>
              <Box>
                <Box sx={{fontSize: 20, fontWeight: 700, mb: 2}}>
                  ДЛЯ КОГО МИКРОГИНОН:
                </Box>
                <Box sx={{mb: 2}}>
                  1. Для гинекологов амбулаторного звена, у которых
                  на приёме есть пациентки с обильными
                  менструациями и с дисфункцией яичников,
                  МИКРОГИНОН® является оптимальным средством
                  за счёт выраженных лечебных эффектов ЛНГ.
                </Box>
                <Box>
                  2. Для всех женщин репродуктивного возраста,
                  кому важна безопасность препарата при его
                  доступной цене.
                </Box>
              </Box>
            </Box>

          </StyledBoxShadow>
        </Box>

        <Box className="container">
          <Box sx={{m: '-6px', mb: '-11px'}}>
            <img src={portraits.p_zoely} alt="Зоэли"/>
          </Box>
          <StyledBoxShadow>
            <Box className="title">ЗОЭЛИ</Box>

            <Box className="container__box" sx={{mb: 4}}>
              <StyledCircle className="border" sx={{padding: '2px'}}>
                <img src={icons.star} alt="*"/>
              </StyledCircle>
              <Box sx={{color: PinkLight}}>
                Для всех женщин репродуктивного
                возраста, которым требуется
                максимально физиологичная надежная
                контрацепция класса Luxe.
              </Box>
            </Box>

            <Box className="container__box">
              <StyledCircle className="border border--white" sx={{padding: '2px 2px 2px 4px'}}>
                <img src={icons.person} alt="*"/>
              </StyledCircle>
              <Box>
                <Box sx={{fontSize: 20, fontWeight: 700, mb: 2}}>
                  ДЛЯ КОГО ЗОЭЛИ:
                </Box>
                <Box>
                  Для гинекологов амбулаторного звена, у которых
                  на приёме есть здоровые пациентки, которые
                  выбирают для себя всё самое лучшее/премиальное,
                  в т.ч. и контрацепцию, ЗОЭЛИ® является оптимальным
                  средством за счёт уникального состава с NOMAC,
                  имеющий высокий уровень доказательной базы,
                  что гарантирует пациентке надежную контрацепцию
                  в сочетании с отличной переносимостью.
                </Box>
              </Box>
            </Box>

          </StyledBoxShadow>
        </Box>

        <Box className="container">
          <Box sx={{m: '-6px', mb: '-11px'}}>
            <img src={portraits.p_alvovizan} alt="Алвовизан"/>
          </Box>
          <StyledBoxShadow>
            <Box className="title">АЛВОВИЗАН</Box>

            <Box className="container__box" sx={{mb: 4}}>
              <StyledCircle className="border" sx={{padding: '2px'}}>
                <img src={icons.star} alt="*"/>
              </StyledCircle>
              <Box sx={{color: PinkLight}}>
                Для всех пациенток
                с эндометриозом, для которых важно
                высокое немецкое качество препарата
                наряду с доступной ценой.
              </Box>
            </Box>

            <Box className="container__box">
              <StyledCircle className="border border--white" sx={{padding: '2px 2px 2px 4px'}}>
                <img src={icons.person} alt="*"/>
              </StyledCircle>
              <Box>
                <Box sx={{fontSize: 20, fontWeight: 700, mb: 2}}>
                  ДЛЯ КОГО АЛВОВИЗАН:
                </Box>
                <Box sx={{mb: 2}}>
                  1. Для гинекологов амбулаторного звена, у которых
                  на приёме есть пациентки с симптомами
                  эндометриоза или с установленным диагнозом
                  эндометриоз, которым нужна эффективная
                  длительная терапия по социально-ответственной
                  цене.
                </Box>
                <Box>
                  2. Для госпитальных гинекологов, у которых на приёме
                  есть пациентки после операции в качестве
                  противорецидивной терапии эндометриоидных
                  очагов.
                </Box>
              </Box>
            </Box>

          </StyledBoxShadow>
        </Box>

        <Box className="container">
          <Box sx={{m: '-6px', mb: '-11px'}}>
            <img src={portraits.p_vidora} alt="Видора"/>
          </Box>
          <StyledBoxShadow>
            <Box className="title">ВИДОРА &nbsp;&nbsp; ВИДОРА Микро</Box>

            <Box className="container__box" sx={{mb: 4}}>
              <StyledCircle className="border" sx={{padding: '2px'}}>
                <img src={icons.star} alt="*"/>
              </StyledCircle>
              <Box sx={{color: PinkLight}}>
                Для всех женщин, которые
                используют в качестве контрацепции
                препараты Байер (Ярина, Джес)
                и для новых пациенток, которые нуждаются
                в контрацепции и/или лечении акне средней тяжести.
              </Box>
            </Box>

            <Box className="container__box">
              <StyledCircle className="border border--white" sx={{padding: '2px 2px 2px 4px'}}>
                <img src={icons.person} alt="*"/>
              </StyledCircle>
              <Box>
                <Box sx={{fontSize: 20, fontWeight: 700, mb: 2}}>
                  ДЛЯ КОГО ВИДОРА И ВИДОРА Микро:
                </Box>
                <Box sx={{mb: 2}}>
                  1. Для гинекологов амбулаторного звена, у которых
                  на приёме есть пациентки, принимающие Джес
                  или Ярину, но которым необходима надежная
                  контрацепция по более доступной цене.
                </Box>
                <Box sx={{mb: 2}}>
                  2. Для гинекологов амбулаторного звена, у которых
                  на приёме есть пациентки, которым необходима
                  контрацепция с дополнительным косметическим
                  эффектом наряду с доступной ценой.
                </Box>
                <Box>
                  3. Для гинекологов амбулаторного звена, у которых
                  на приёме есть пациентки с СПКЯ и тяжелой
                  формой предменструального синдрома (ПМС).
                </Box>
              </Box>
            </Box>

          </StyledBoxShadow>
        </Box>

        <Box className="container">
          <Box sx={{m: '-6px', mb: '-11px'}}>
            <img src={portraits.p_diecyclen} alt="Диекицлен"/>
          </Box>
          <StyledBoxShadow>
            <Box className="title">ДИЕЦИКЛЕН</Box>

            <Box className="container__box" sx={{mb: 4}}>
              <StyledCircle className="border" sx={{padding: '2px'}}>
                <img src={icons.star} alt="*"/>
              </StyledCircle>
              <Box sx={{color: PinkLight}}>
                Для всех женщин, которые
                используют в качестве контрацепции
                препарат Байер (Жанин) и для новых
                пациенток, которые нуждаются в контрацепции и/или
                лечении акне лёгкой/средней тяжести.
              </Box>
            </Box>

            <Box className="container__box">
              <StyledCircle className="border border--white" sx={{padding: '2px 2px 2px 4px'}}>
                <img src={icons.person} alt="*"/>
              </StyledCircle>
              <Box>
                <Box sx={{fontSize: 20, fontWeight: 700, mb: 2}}>
                  ДЛЯ КОГО ДИЕЦИКЛЕН:
                </Box>
                <Box sx={{mb: 2}}>
                  1. Для гинекологов амбулаторного звена, у которых на приёме
                  есть пациентки, принимающие Жанин, но которым необходима надежная контрацепция по более доступной
                  цене.
                </Box>
                <Box sx={{mb: 2}}>
                  2. Для гинекологов амбулаторного звена, у которых
                  на приёме есть новые пациентки, которые нуждаются
                  в контрацепции и/или лечении акне лёгкой/средней
                  тяжести по доступной цене.
                </Box>
                <Box>
                  3. Для гинекологов амбулаторного звена, у которых
                  на приёме есть пациентки с эндометриозом, которым
                  наряду с эффективной терапией необходима контрацепция
                  по социально-ответственной цене.
                </Box>
              </Box>
            </Box>

          </StyledBoxShadow>
        </Box>

        <Box className="container">
          <Box sx={{m: '-6px', mb: '-11px'}}>
            <img src={portraits.p_velledien} alt="Велледиен"/>
          </Box>
          <StyledBoxShadow>
            <Box className="title">ВЕЛЛЕДИЕН</Box>

            <Box className="container__box" sx={{mb: 4}}>
              <StyledCircle className="border" sx={{padding: '2px'}}>
                <img src={icons.star} alt="*"/>
              </StyledCircle>
              <Box sx={{color: PinkLight}}>
                Для пациенток в постменопаузе
                без риска для молочной
                по социально-ответственной цене.
              </Box>
            </Box>

            <Box className="container__box">
              <StyledCircle className="border border--white" sx={{padding: '2px 2px 2px 4px'}}>
                <img src={icons.person} alt="*"/>
              </StyledCircle>
              <Box>
                <Box sx={{fontSize: 20, fontWeight: 700, mb: 2}}>
                  ДЛЯ КОГО ВЕЛЛЕДИЕН:
                </Box>
                <Box sx={{mb: 2}}>
                  1. Для гинекологов амбулаторного звена, у которых
                  на приёме есть пациентки в постменопаузе,
                  принимающие Ливиал, но которым необходима
                  эффективная МГТ по более доступной цене.
                </Box>
                <Box sx={{mb: 2}}>
                  2. Для гинекологов амбулаторного звена, у которых
                  на приёме есть пациентки в постменопаузе,
                  которые в перименопаузу принимали Климонорм
                  и сейчас им необходим благоприятный переход
                  с циклической МГТ.
                </Box>
                <Box>
                  3. Для гинекологов амбулаторного звена, у которых
                  на приёме есть пациентки в постменопаузе, которым
                  необходима эффективная МГТ без риска для молочной
                  железы.
                </Box>
              </Box>
            </Box>

          </StyledBoxShadow>
        </Box>
      </StyledWrapperBox>
    </>
  )
}
