import Page from 'shared/ui/Page';
import {H1} from '../ui';

import {StyledParagraph as P, Link as A} from './ui';
import {PinkLight} from '../../../styles/color';

export const ProginovaLinkTwo = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Meta-analysis of estradiol for luteal phase support in in vitro fertilization/intracytoplasmic sperm injection</H1>
      <P><b>Objective:</b> To evaluate whether the addition of E(2) for luteal phase support (LPS) in IVF/intracytoplasmic sperm injection (ICSI) could improve the outcome of clinical pregnancy.</P>
      <P><b>Design:</b> Meta-analysis.</P>
      <P><b>Setting:</b> University hospital center.</P>
      <P><b>Patient(s):</b> Women underwent IVF or ICSI using the GnRH agonist or GnRH antagonist protocol.</P>
      <P><b>Intervention(s):</b> Progesterone alone or combined with E(2) for LPS.</P>
      <P><b>Main outcome measure(s):</b> Clinical pregnancy rate per patient (CPR/PA), clinical pregnancy rate per ET, implantation rate, ongoing pregnancy rate per patient, clinical abortion rate, and ectopic pregnancy rate.</P>
      <P><b>Result(s):</b> Fifteen relevant randomized controlled trials (RCTs) were identified that included a total of 2,406 patients. There was no statistical difference between E(2) + P group and P-only group regarding the primary outcome of CPR/PA for different routes of administration of E(2) (oral, vaginal, and transdermal) or other relevant outcome measures. No significant effect was observed for different daily doses of E(2) (6, 4, and 2 mg), even through oral medication in CPR/PA.</P>
      <P><b>Conclusion(s):</b>The best available evidence suggests that E(2) addition during the luteal phase does not improve IVF/ICSI outcomes through oral medication, even with different daily doses. Furthermore, RCTs that study other administration routes are needed.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/25492682/">Huang N, et al. Meta-analysis of estradiol for luteal phase support in in vitro fertilization/intracytoplasmic sperm injection. Fertil Steril.2015 Feb;103(2):367-373.e5.</A>]
      </P>
    </Page>
  )
}
