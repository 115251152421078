import {Box} from '@mui/material';

import {StyledAnchorLink, StyledHeader} from '../ui';
import {StyledBoxBorder, StyledBoxInsetShadow, StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {Ticket} from './ticket';
import {HeaderTabs as Tabs} from './header-tabs';

type HeaderTabsProps = {
  tabActive: number
}
const HeaderTabs = ({tabActive}: HeaderTabsProps) => <Tabs tabActive={tabActive} tabLength={4}/>

export const SubpageProginova = () => {
  return (
    <>
      <StyledHeader>Микрогинон®</StyledHeader>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <StyledAnchorLink href="#theme1">
          <img src={icons.arrow} alt="*"/>
          Тема #1: Показания для назначения ПРОГИНОВА® в Программах ВРТ
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme2">
          <img src={icons.arrow} alt="*"/>
          Тема #2: Показания для назначения ПРОГИНОВА® в протоколах контролируемой стимуляции овуляции
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme3">
          <img src={icons.arrow} alt="*"/>
          Тема #3: Показания для назначения ПРОГИНОВА® при функциональной гипоталамической аменорее и при врожденном гипогонадизме.
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme4">
          <img src={icons.arrow} alt="*"/>
          Тема #4: ПРОГИНОВА® — терапия выбора у пациенток с тотальной гистерэктомией
        </StyledAnchorLink>
      </Box>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <HeaderTabs tabActive={0}/>
        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Показания для назначения ПРОГИНОВА®
                в Программах ВРТ
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Я хочу, чтобы Ольга Ивановна не отменяла ПРОГИНОВУ
                пациенткам, у которых наступила беременность, до 8–12 недель
                беременности (Программа ВРТ).
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения
                  пациенток, которые <strong> готовятся к стимуляции овуляции
                  или находятся в процессе программы ВРТ.</strong>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Примеры продуктивных вопросов для определения потенциала:
                </Box>
               <ol className="list">
                 <li>
                   Какое количество пациенток у Вас сейчас находится на этапе
                   подготовки к стимуляции овуляции?
                 </li>
                 <li>А сколько пациенток, у которых беременность уже наступила? </li>
               </ol>
                <Box sx={{mb: '12px'}}>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких пациенток
                  на приёме около 50%. А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box sx={{mb: '12px'}}>Примеры вопросов для определения выписки:</Box>
                <Box>
                  — Скольким из таких пациенток Вы назначаете эстрогены
                  для улучшения качества ооцитов и для сохранения
                  беременности?
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FAB)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            У пациенток с «бедным» ответом (удаётся получить мало ооцитов). Назначение ПРОГИНОВЫ в течение 2–3 циклов накануне стимуляции
            овуляции улучшает качество ооцитов. Постепенная отмена эстрогенов с 8-й по 12-ю неделю беременности необходима в рамках
            гормональной поддержки в протоколе стимуляции овуляции в сочетании с гестагенами.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              Применение ПРОГИНОВЫ в программах ВРТ нашло отражение в научно-практических рекомендациях 2015 г.
            </Box>
            <Box>
              Рациональной тактикой отмены эстрогенов во время наступившей беременности является постепенное снижение дозы не ранее
              8—12 недель гестации.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme2" sx={{mt: 5}}>
        <HeaderTabs tabActive={1}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Показания для назначения ПРОГИНОВА® в протоколах
                контролируемой стимуляции овуляции
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Я хочу, чтобы доктор Ольга Ивановна назначила
                ПРОГИНОВУ всем пациенткам в течение месяца
                в программах стимуляции овуляции для увеличения
                толщины эндометрия (протоколы контролируемой
                стимуляции овуляции).
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения
                  пациенток, которым <strong>необходимо увеличить толщину
                  эндометрия.</strong>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Пример продуктивного вопроса для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто в Вашей практике встречаются пациентки с тонким
                  эндометрием?
                </Box>
                <Box sx={{mb: '12px'}}>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких пациенток
                  на приёме около 50%. А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box sx={{mb: '12px'}}>Примеры вопросов для определения выписки:</Box>
                <Box>
                  — Какую схему назначения эстрогенов Вы обычно используете
                  в этой клинической ситуации?
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FAB)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            Отсутствие адекватной трансформации эндометрия вследствие периферического антиэстрогенного действия кломифена является
            основанием для назначения ПРОГИНОВА® с целью увеличения толщины эндометрия в протоколах контролируемой стимуляции овуляции.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box>
              Назначение ПРОГИНОВЫ у пациенток с «тонким» эндометрием в дозе 15—18 мг в течение 3 месяцев с 5-дневным введением прогестерона
              1 раз в 120 дней, общий период лечения составил 240 дней и позволил у 87 пациенток из 101 достичь М-эхо {'>'} 8 мм. Это позволило
              осуществить перенос эмбрионов в криопротоколе в следующем менструальном цикле.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme3" sx={{mt: 5}}>
        <HeaderTabs tabActive={2}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Показания для назначения ПРОГИНОВА®
                при функциональной гипоталамической аменорее
                и при врожденном гипогонадизме
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Я хочу, чтобы доктор Ольга Ивановна назначила
                ПРОГИНОВУ всем пациенткам при функциональной
                гипоталамической аменорее и при врождённом
                гипогонадизме.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут
                  для обсуждения пациенток <strong> с диагнозом «врождённый
                  или приобретённый гипогонадизм».</strong>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Пример продуктивного вопроса для определения потенциала:
                </Box>
                <ol className="list">
                  <li>
                    Какая патология, требующая назначения моноэстрогенов,
                    преобладает сейчас среди Ваших пациенток?
                  </li>
                  <li>
                    Как часто такие пациентки встречаются в Вашей практике?
                  </li>
                </ol>
                <Box sx={{mb: '12px'}}>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких пациенток
                  на приёме около 50%. А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box sx={{mb: '12px'}}>Примеры вопросов для определения выписки:</Box>
               <ol className="list">
                 <li>Скольким из этих пациенток Вы назначаете эстрогены?</li>
                 <li>
                   Половине пациенток, о которых сейчас говорили,
                   Вы рекомендуете КОКи/ выписываете другие препараты?
                 </li>
               </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FAB)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            <Box>
              Пациенткам с гипоталамической аменореей или с врождённым гипогонадизмом для восстановления нормального уровня половых
              стероидов с целью профилактики снижения минеральной плотности костной ткани, регрессии симптомов гипоэстрогении, нормализации
              метаболических нарушений, положительного влияния на состояние урогенитального тракта, сохранения здоровья женщины показано
              назначение ПРОГИНОВА®.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box>
              Эксперты рекомендуют препарат ПРОГИНОВА®, содержащий 2 мг эстрадиола валерата, при лечении врождённого и приобретённого
              гипогонадизма. По данным исследований, использование моноэстрогенов в течение 7–10 лет снижает риск развития инвазивного рака
              молочной железы. Поэтому ПРОГИНОВА® можно использовать длительно, что важно для пациенток с гипогонадизмом.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme4" sx={{mt: 5}}>
        <HeaderTabs tabActive={3}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                ПРОГИНОВА® — терапия выбора у пациенток
                с тотальной гистерэктомией
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Я хочу, чтобы Ольга Ивановна назначила препарат
                ПРОГИНОВА® всем пациенткам после гистерэктомии.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения
                  пациенток, которые <strong>перенесли тотальную
                  гистерэктомию.</strong>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Пример продуктивного вопроса для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто в Вашей практике встречаются пациентки после
                  гистерэктомии?
                </Box>
                <Box sx={{mb: '12px'}}>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких пациенток
                  на приёме около 50%. А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box sx={{mb: '12px'}}>Примеры вопросов для определения выписки:</Box>
                <ol className="list">
                  <li>
                    Скольким из этих пациенток Вы назначаете МГТ с целью
                    сохранения их здоровья?
                  </li>
                  <li>
                    Половине пациенток, о которых сейчас говорили, Вы назначаете
                    комбинированные препараты МГТ?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FAB)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            <Box>
              Пациенткам с тотальной гистерэктомией Вы можете назначать ПРОГИНОВА® длительно, так как, по данным NHS (n=11 508, длительность
              наблюдения более 15 лет), монотерапия эстрогенами в течение 7–10 лет способствует снижению риска РМЖ ниже популяционного.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box>
              Международное общество по менопаузе декларирует факт, который прописан в клинических рекомендациях, что использование
              моноэстрогенов в качестве МГТ связано со снижением риска инвазивного рака молочной железы, что позволяет использовать препарат
              ПРОГИНОВА® более 10 лет, что, в свою очередь, необходимо для пациенток с тотальной гистерэктомией в анамнезе.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>
    </>
  )
}
