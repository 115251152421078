import {styled} from '@mui/system';
import {Link} from 'react-router-dom';
import {Black, PinkLight} from '../../../styles/color';
import {Box, Button} from '@mui/material';

export const StyledTab = styled(Link)({
  backgroundColor: PinkLight,
  color: Black,
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '19px',
  borderRadius: 60,
  padding: '10px 30px',
  fontFamily: 'SF Pro Text',
  boxSizing: 'border-box',
  border: '1px solid #F7E5E0',
  boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',

  '&.active': {
    backgroundColor: Black,
    color: PinkLight,
  },

  '&:hover': {
    backgroundColor: Black,
    color: PinkLight,
  },
})

export const StyledBtn = styled(Button)`
  padding: 13px 30px;
  border: 1px solid #FFFFFF;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  text-transform: uppercase;
  line-height: 18px;
  font-size: 20px;
  font-family: "SF Pro Text", sans-serif;
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.1px;
  background-color: #FFFFFF;
  color: ${Black};
  position: fixed;
  bottom: 92px;

  &:hover {
    background-color: ${Black};
    color: #FFFFFF;
    
    svg > path {
      fill: #FFF;
      transition: .2s;
    }
  }
  
  svg {
    margin-left: 10px;
  }
`


export const StyledCardBorderLine = styled('div')`
  border: 1px solid #F9D2D6;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`


export const StyledBoxShadow = styled(Box)({
  padding: '20px',
  width: '100%',
  color: '#FFFFFF',
  backgroundColor: Black,
  boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(249, 210, 214, 0.2)',
  borderRadius: 12,
  boxSizing: 'border-box',
})

export const StyledCircle = styled('div')`
  width: 40px;
  min-width: 40px;
  height: 40px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(249, 210, 214, 0.2);
  background-color: ${Black};
  border-radius: 50%;
  box-sizing: border-box;
  
  font-size: 28px;
  line-height: 34px;
  color: ${PinkLight};
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.border {
    border: 3px solid ${PinkLight};
    
    &--white {
      border-color: #FFFFFF;
    }
  }
`;

export const StyledCircle160 = styled(StyledCircle)`
  width: 160px;
  min-width: 160px;
  height: 160px;
  position: relative;
  
  & .badge {
    position: absolute;
    top: 18px;
    left: -16px;
  }
`

export const StyledCircle60 = styled(StyledCircle)`
  width: 60px;
  min-width: 60px;
  height: 60px;

`

export const Sup = styled('sup')({
  color: '#0076BD',
  fontWeight: 'bold',
  fontSize: '0.688em',
  left: '2px',
  verticalAlign: 'top',
  position: 'relative',
  top: '-0.4em',
})

