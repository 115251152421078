import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanLinkFour = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Comparison of dienogest versus triptorelin acetate in premenopausal women with
        adenomyosis: a prospective clinical trial</H1>
      <P><b>Purpose:</b> To compare the efficacy of the oral dienogest versus triptorelin acetate injection for
        treatment of premenopausal menorrhagia and pelvic pains in women with uterine adenomyosis.</P>
      <P><b>Methods:</b> A total of 41 patients with adenomyosis suffering from pelvic pains and menorrhagia were
        recruited. First group was managed with oral dienogest (2 mg/day, orally) while the second group received
        triptorelin acetate (3.75 mg/4 weeks, subcutaneous injection) for 16 weeks. Outpatient follow-up was undertaken
        after 8 weeks but mean values were calculated at baseline and after 16 weeks (end of treatment).</P>
      <P><b>Results:</b> A total of 41 women were allocated to treatment with dienogest (n = 22) or triptorelin acetate
        (n = 19); 19 (86.4 %) and 18 (94.7 %) % of the respective groups completed the trial. Significant reductions in
        pelvic pains after 16 weeks treatment were obtained in both groups demonstrating the equivalence of dienogest
        relative to triptorelin acetate. Triptorelin acetate was more effective in controlling of menorrhagia and
        reduction of uterine volume.</P>
      <P><b>Conclusions:</b> Dienogest may be a valuable alternative to depot triptorelin acetate for treatment of
        premenopausal pelvic pains in women with uterine adenomyosis.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/25990480/">Muhammad Fawzy, Yasser Mesbah Comparison of dienogest versus
        triptorelin acetate in premenopausal women with adenomyosis: a prospective clinical trial Arch Gynecol Obstet
        (2015) 292:1267–1271</A>]
      </P>
    </Page>
  )
}
