import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanThirteenLinkSeven = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>А. В. Соколов. Оригинальные препараты и дженерики: качество, возможные пути
        решения проблемы. Медицинские технологии оценка и выбор, № 3. 2012</H1>
      <P>В соответствии с федеральной целевой программой «Развитие фармацевтической и медицинской промышленности
        Российской Федерации на период до 2020 г. и дальнейшую перспективу» производство отечественных лекарств (в том
        числе и дженериков) в нашей стране должно существенно вырасти. Статья посвящена системному подходу повышения
        качества исследований биоэквивалентности. </P>
      <P>
        [ссылка: <A
        href="https://cyberleninka.ru/article/n/originalnye-preparaty-i-dzheneriki-kachestvo-vozmozhnye-puti-resheniya-problemy/viewer">https://cyberleninka.ru/article/n/originalnye-preparaty-i-dzheneriki-kachestvo-vozmozhnye-puti-resheniya-problemy/viewer</A>]
      </P>
    </Page>
  )
}
