import {styled} from '@mui/system';
import {Box, Button} from '@mui/material';
import {Black, PinkLight} from '../../styles/color';

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  font-family: "SF Pro Text", sans-serif;
  
  & .error {
    color: red;
    background-color: white;
  }

  & .title {
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 0.2px;
    text-align: center;
    color: ${PinkLight};
    margin-bottom: 60px;
    margin-left: -50px;
    margin-right: -50px;
    text-transform: uppercase;
  }

  & .form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 420px;
    height: 440px;
  }
  
  & .pass-icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
    position: absolute;
    right: 30px;
    top: 18px;
    cursor: pointer;
    
    &--open {
      width: 22px;
      height: 22px;
      margin-right: 1px;
      margin-top: 1px;
    }
  }
  
  & .loader {
    background-color: #EEB3B4;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
`

export const StyledTextInput = styled('input')`
  border: 1px solid ${PinkLight};
  background-color: #2F2F34;
  border-radius: 60px;
  box-sizing: border-box;
  height: 60px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  padding: 0px 30px;
  color: white;
  font-family: "SF Pro Text", sans-serif;
`

export const StyledBtn = styled(Button)`
  padding: 13px 30px;
  border: 1px solid ${PinkLight};
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  line-height: 18px;
  font-size: 20px;
  font-family: "SF Pro Text", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.1px;
  background-color: ${PinkLight};
  color: ${Black};
  width: 100%;
  height: 60px;

  &:hover {
    background-color: ${Black};
    color: ${PinkLight};
  }
`