import {Box} from '@mui/material';

import {StyledBoxBorder} from '../../../ui';
import {StyledLink} from './ui';
import {PinkLight} from '../../../../../styles/color';

type Props = {
  img: string,
  productName: string,
  link: string;
  children: React.ReactNode,
}
export const ToDoctorCard = ({img, productName, link, children}: Props) => {
  return (
    <StyledBoxBorder>
      <img src={img} alt={productName}/>
      <Box sx={{fontSize: 18, mt: 2, mb: 2}}>{productName}</Box>
      <Box sx={{mb: 2, lineHeight: '22px'}}>
        <Box sx={{color: PinkLight}}>ЦЕЛЬ ВИЗИТА:</Box>
        {children}
      </Box>
      <StyledLink href={link}>
        Подробнее
      </StyledLink>
    </StyledBoxBorder>
  )
}
