import {styled} from '@mui/system';
import {Black, PinkLight} from '../../styles/color';
export const StyledLink = styled('a')`
  padding: 10px 30px;
  border: 1px solid ${PinkLight};
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  line-height: 18px;
  font-size: 16px;
  font-family: "SF Pro Text", sans-serif;
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.1px;
  background-color: ${Black};
  color: #FFFFFF;
  display: block;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;

  &:hover {
    background-color: ${PinkLight};
    color: ${Black};

    .svg {
      filter: brightness(0%);
    }
  }
  
  &.blue {
    background-color: #030360;
    color: #FFF;
    &:hover {
      background-color: #00B0F0;

     .svg {
        filter: brightness(100%);
      }
    }
  }
`
