import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
// @ts-ignore
import ScrollMemory from 'react-router-scroll-memory';

import ServiseWorker from 'helpers/scripts/ServiseWorker';

import App from './App';
import {Login} from './pages/login';
import Autosave from 'helpers/scripts/Autosave';
import 'assets/fonts/sf-pro/sf-pro.scss'
import 'assets/fonts/SFPro/stylesheet.css'
import './styles/styles.scss';

const auth = localStorage.getItem('auth') || '';

ReactDOM.render(
  <BrowserRouter>
    {auth !== 'true' ? <Login/> : (
     <>
       <ScrollMemory/>
       <App/>
       <ServiseWorker/>
       <Autosave/>
     </>
    )}
  </BrowserRouter>,
  document.getElementById('root')
);
