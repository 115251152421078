import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A, StyledH1 as H1} from './ui';

export const ProginovaSecondLinkFive = () => {
  return (
    <Page isCross>
      <H1>Role of angiogenesis in endometrial repair of patients with severe intrauterine adhesion</H1>
      <P><b>Objective:</b> To detect vascular endothelial growth factor (VEGF) expression and micro-vessel density (MVD)
        in patients with severe intrauterine adhesion before and after therapy, and to preliminarily explore the role of
        angiogenesis in the therapy of severe intrauterine adhesion.</P>
      <P><b>Methods:</b> A total of 36 patients with severe intrauterine adhesion were prospectively recruited into the
        treatment group. In the control group, 20 patients with normal uterine were recruited. Patients with severe
        intrauterine adhesion received transcervical resection of adhesions under hysteroscope and then received
        artificial hormone therapy for 3 months.</P>
      <P><b>Methods:</b> The changes in the organelles of endometrial cells were evaluated under an electric microscope;
        Immunohistochemistry was done to detect the VEGF expression and MVD in patients with severe intrauterine
        adhesion, which was compared with that in the control group; VEGF expression and MVD were compared among
        patients with different prognoses.</P>
      <P><b>Results:</b> Electric microscopy showed vascular closure and hypoxic changes in the endometrial tissues of
        patients with intrauterine adhesion. After treatment, angiogenesis was observed, and the hypoxic changes in the
        endometrial glands and interstitium were also improved. Moreover, the VEGF expression and score of MVD also
        increased significantly when compared with those before treatment and in the control group. The VEGF expression
        and MVD score in intrauterine adhesion patients recovering from treatment were significantly higher than those
        in patients non-responding to treatment.</P>
      <P><b>Conclusion:</b> In patients with intrauterine adhesion, the endometrial tissues present with vascular
        closure, and angiogenesis will be present in the endometrial tissues after treatment. The angiogenesis in the
        endometrial tissues may affect the endometrial repair.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/23826415/">Chen Y, Chang Y, Yao S. Role of angiogenesis in
        endometrial repair of patients with severe intrauterine adhesion. International Journal of Clinical and
        Experimental Pathology. 2013;6(7):1343-1350.</A>]
      </P>
    </Page>
  )
}
