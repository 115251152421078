import {Box} from '@mui/material';
import {StyledCircle} from '../../../ui';

type Props = {
  title: string;
  img: string;
  children: React.ReactNode;
  textTransform?: string
}
export const Ticket = ({title, img, children, textTransform}: Props) => {
  return (
    <Box sx={{display: 'flex', gap: '20px'}}>
      <StyledCircle sx={{padding: '5px'}}>
        <img src={img} alt="*"/>
      </StyledCircle>
      <Box>
        <Box className="title pink"
             sx={{mb: 1, textTransform: textTransform ? textTransform : 'uppercase'}}>{title}</Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  )
}
