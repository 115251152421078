import {useHistory} from 'react-router-dom';

import {WrapperShell} from 'components/wrapper-shell';
import {WrapperCouple} from 'components/wrapper-couple';
import Page from 'shared/ui/Page';
import {List, ListItem, Sup, Tables} from 'shared/ui';
import {PinkLight} from 'styles/color';

import {H2 as Header2, H1 as Header1, Paragraph as P, LinkA, Span, Div} from './ui';

import {styled} from '@mui/system';
import pdf from '../../../pdfs/zoely/Zoely-Sales-Aid-UK_ru.pdf';

const H2 = styled(Header2)({
  color: PinkLight,
  textTransform: 'uppercase'
})

const H1 = styled(Header1)({
  color: PinkLight,
})

const A = styled(LinkA)({
  display: 'block',
  margin: '10px auto',
  letterSpacing: '0.1px',
  fontSize: 17,
  fontFamily: 'SF-Pro-Text-Semibold',
  textDecoration: 'none',
})

const LI = styled(ListItem)({
  '> p': {
    marginTop: 4,
    marginBottom: 4,
  }
})

const UL = styled(List)({
  'list-style-type': 'disc',
})

const Instructions = () => {
  const history = useHistory();

  const handlerRedirectToDiecyclen = () => {
    history.push('/product/eoeli');
  }

  return (
    <Page onClose={handlerRedirectToDiecyclen} isCross>
      <Div sx={{textAlign: 'center'}}>
        <H2 sx={{fontSize: 20, mb: 3}}>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</H2>
        <H1>ИНСТРУКЦИЯ</H1>
        <H2 sx={{fontSize: 20, textTransform: 'none', mb: 3, mt: 2}}>по применению лекарственного препарата для
          медицинского применения</H2>
        <H1 sx={{mb: 3}}>ЗОЭЛИ®</H1>
      </Div>
      <H2 sx={{fontSize: 20}}>РЕГИСТРАЦИОННЫЙ НОМЕР: <Span
        sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>ЛП-001621</Span></H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ТОРГОВОЕ НАИМЕНОВАНИЕ: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>Зоэли®</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        МЕЖДУНАРОДНОЕ НЕПАТЕНТОВАННОЕ ИЛИ ГРУППИРОВОЧНОЕ НАИМЕНОВАНИЕ:
        <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}> номегэстрол + эстрадиол
        </Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ЛЕКАРСТВЕННАЯ ФОРМА:
        <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}> таблетки покрытые пленочной оболочкой, 2,5
          мг + 1,5 мг
        </Span>
      </H2>

      <H2 style={{marginTop: 50}}>СОДЕРЖАНИЕ</H2>
      <WrapperCouple sx={{display: 'flex', justifyContent: 'space-between'}}>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#compound'}>СОСТАВ</A>
          <A href={'#description'}>ОПИСАНИЕ</A>
          <A href={'#farm-group'}>ФАРМАКОТЕРАПЕВТИЧЕСКАЯ ГРУППА</A>
          <A href={'#farm-property'}>ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА</A>
          <A href={'#ind-for-use'}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ</A>
          <A href={'#dication'}>ПРОТИВОПОКАЗАНИЯ</A>
          <A href={'#warning'}>С ОСТОРОЖНОСТЬЮ</A>
          <A href={'#for-use-feed'}>ПРИМЕНЕНИЕ В ПЕРИОД БЕРЕМЕННОСТИ И ГРУДНОГО<br/> ВСКАРМЛИВАНИЯ</A>
          <A href={'#dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</A>
          <A href={'#effect-action'}>ПОБОЧНОЕ ДЕЙСТВИЕ</A>
          <A href={'#over-dozing'}>ПЕРЕДОЗИРОВКА</A>
        </WrapperShell>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#another-medicines'}>ВЗАИМОДЕЙСТВИЕ С ДРУГИМИ ЛЕКАРСТВЕННЫМИ <br/> СРЕДСТВАМИ</A>
          <A href={'#special-instructions'}>ОСОБЫЕ УКАЗАНИЯ</A>
          <A href={'#managment-transport'}>ВЛИЯНИЕ НА СПОСОБНОСТЬ УПРАВЛЕНИЯ <br/>ТРАНСПОРТНЫМИ СРЕДСТВАМИ,
            МЕХАНИЗМАМИ</A>
          <A href={'#form-production'}>ФОРМА ВЫПУСКА</A>
          <A href={'#keeper'}>УСЛОВИЯ ХРАНЕНИЯ</A>
          <A href={'#deadline'}>СРОК ГОДНОСТИ</A>
          <A href={'#holiday'}>УСЛОВИЯ ОТПУСКА</A>
          <A href={'#owner'}>ЮРИДИЧЕСКОЕ ЛИЦО, НА ИМЯ КОТОРОГО ВЫДАНО РЕГИСТРАЦИОННОЕ УДОСТОВЕРЕНИЕ</A>
          <A href={'#producer'}>ПРОИЗВОДИТЕЛЬ</A>
          <A href={'#report'}>ПРЕТЕНЗИИ ПОТРЕБИТЕЛЕЙ НАПРАВЛЯТЬ ПО АДРЕСУ</A>
        </WrapperShell>
      </WrapperCouple>

      <H2 id={'compound'}>СОСТАВ</H2>
      <P><b style={{fontSize: 20}}>Для таблеток, содержащих действующие вещества</b></P>
      <P>1 белая таблетка, покрытая пленочной оболочкой, содержит:</P>
      <P><b>действующие вещества:</b> номегэстрола ацетат 2,50 мг, эстрадиола гемигидрат 1,55 мг (эквивалентно 1,50 мг
        эстрадиола);</P>
      <P><b>вспомогательные вещества:</b> целлюлоза микрокристаллическая 14,00 мг, кросповидон 2,40 мг, тальк 0,70 мг,
        магния стеарат 0,70 мг, кремния диоксид коллоидный 0,44 мг, лактозы моногидрат 57,71 мг;</P>
      <P><b>оболочка таблетки:</b> Опадрай II белый (1,6 мг) содержит поливиниловый спирт 0,64 мг, титана диоксид 0,40
        мг, макрогол-3350 0,32 мг, тальк 0,24 мг.</P>
      <P><b style={{fontSize: 20}}>Для таблеток, не содержащих действующих веществ (плацебо)</b></P>
      <P>1 желтая таблетка, покрытая пленочной оболочкой, содержит:</P>
      <P><b>вспомогательные вещества:</b> целлюлоза микрокристаллическая 14,00 мг, кросповидон 2,40 мг, тальк 0,70 мг,
        магния стеарат 0,70 мг, кремния диоксид коллоидный 0,44 мг, лактозы моногидрат 61,76 мг;</P>
      <P><b>оболочка таблетки:</b> Опадрай II желтый (2,4 мг) содержит поливиниловый спирт 0,96 мг, титана диоксид 0,58
        мг, макрогол-3350 0,48 мг, тальк 0,36 мг, краситель железа оксид желтый 0,016 мг, краситель железа оксид черный
        0,00024 мг.</P>


      <H2 id={'description'}>ОПИСАНИЕ</H2>
      <P><b style={{fontSize: 20}}>Для таблеток, содержащих действующие вещества</b></P>
      <P>Круглые двояковыпуклые таблетки, покрытые пленочной оболочкой от белого до почти белого цвета, с гравировкой
        «ne» с двух сторон, цвет ядра на разрезе от белого до почти белого.</P>
      <P><b>Для таблеток, не содержащих действующих веществ (плацебо)</b></P>
      <P>Круглые двояковыпуклые таблетки, покрытые пленочной оболочкой желтого цвета, с гравировкой «р» с двух сторон,
        цвет ядра на разрезе от белого до почти белого.</P>

      <H2 id={'farm-group'}>Фармакотерапевтическая группа:</H2>
      <P>Контрацептивное средство комбинированное (эстроген+гестаген).</P>
      <P><b style={{fontSize: 20}}>КОД АТХ:</b> G03AA14</P>

      <H2 id={'farm-property'}>Фармакологические свойства</H2>
      <P><b style={{fontSize: 20}}>Фармакодинамика</b></P>
      <P>Номегэстрола ацетат — это высокоселективный прогестаген, являющийся производным естественного стероидного
        гормона прогестерона. Номегэстрола ацетат имеет выраженное сродство к человеческому рецептору прогестерона,
        обладает антигонадотропной активностью, антиэстрогенной активностью, опосредованной рецепторами прогестерона,
        умеренной антиандроген ной активностью и не обладает эстрогенной, андрогенной, глюкокортикоидной и
        минералокортикоидной активностью.
      </P>
      <P>В состав препарата Зоэли® входит 17β-эстрадиол — эстроген, идентичный эндогенному человеческому 17β-эстрадиолу
        (Е2). В отличие от этинилэстрадиола, который входит в состав других комбинированных пероральных контрацептивов
        (КОК), Е2 не имеет этинильной группы в 17ɑ положении. При применении препарата Зоэли® средние концентрации Е2
        сопоставимы с таковыми в начальной фолликулярной фазе и поздней фазе желтого тела менструального цикла (см.
        подраздел «Фармакокинетика»).
      </P>
      <P>Контрацептивный эффект препарата Зоэли® обусловлен комбинацией различных факторов, наиболее важными из которых
        являются подавление овуляции и изменение вязкости секрета шейки матки.
      </P>
      <P>В двух рандомизированных открытых сравнительных исследованиях эффективности и безопасности более чем 3200
        женщин в возрасте 18-50 лет принимали препарат Зоэли® в течение 13 последовательных циклов и более чем 1000
        женщин принимали комбинацию дроспиренон (3 мг) / этинилэстрадиол (30 мкг) по схеме приема 21/7. В группе,
        принимавшей препарат Зоэли®, об увеличении массы тела сообщалось у 8,6% женщин (в группе сравнения — 5,7%), о
        нерегулярных кровотечениях «отмены» (преимущественно об отсутствии кровотечения «отмены») сообщалось у 10,5%
        женщин (в группе сравнения — 0,5%), об акне — у 15,4% женщин (в группе сравнения — 7,9%) (см. раздел «Побочное
        действие»). Оценка развития акне при приеме препарата Зоэли® показала, что у большинства женщин (73,1%) не
        наблюдалось изменений состояния по сравнению с состоянием до начала лечения, у 16,8% женщин наблюдалось
        улучшение состояния и у 10,1 % женщин было отмечено появление или ухудшение течения акне.
      </P>
      <P>В клиническом исследовании препарата Зоэли®, проведенном в Европейском Союзе, Азии и Австралии, были рассчитаны
        следующие показатели индекса Перля для возрастной группы 18-35 лет: неэффективность метода — 0,40 (верхняя
        граница 95% доверительного интервала 1,03); неэффективность метода и ошибка пациента — 0,38 (верхняя граница 95%
        доверительного интервала 0,97).
      </P>
      <P>В клиническом исследовании препарата Зоэли®, проведенном в Соединенных Штатах Америки, Канаде и Латинской
        Америке, были рассчитаны следующие показатели индекса Перля для возрастной группы 18-35 лет: неэффективность
        метода — 1,22 (верхняя граница 95% доверительного интервала 2,18); неэффективность метода и ошибка пациента —
        1,16 (верхняя граница 95% доверительного интервала 2,08).
      </P>
      <P>В рандомизированном открытом исследовании 32 женщины получали препарат Зоэли® на протяжении 6 циклов. После
        прекращения приема препарата Зоэли® овуляция восстанавливалась в среднем через 20,8 дней после последнего приема
        таблетки, при этом наиболее ранняя дата овуляции была зафиксирована на 16-й день.
      </P>
      <P>Фолиевая кислота является важным витамином на ранних сроках беременности. Во время приема препарата Зоэли®
        концентрация фолиевой кислоты в плазме крови не изменяется и остается на базовом уровне в течение 6
        последовательных месяцев приема препарата.
      </P>
      <P>В рандомизированном открытом сравнительном исследовании продолжительностью 2 года при приеме препарата Зоэли®
        женщинами в возрасте 21-35 лет наблюдалось отсутствие клинически значимого эффекта препарата Зоэли® на
        минеральную плотность костной ткани.
      </P>
      <P>Было проведено рандомизированное открытое сравнительное многоцентровое исследование для оценки влияния
        препарата Зоэли® на параметры свертывания крови, липидного профиля, метаболизма углеводов, на функциональное
        состояние надпочечников и щитовидной железы, а также на концентрацию андрогенов.
      </P>
      <P>Шестьдесят женщин в возрасте 18-50 лет принимали препарат Зоэли® в течение 6 последовательных циклов. В
        клинических исследованиях было установлено, что при приеме препарата Зоэли® инсулинорезистентность и
        толерантность к глюкозе не изменялись, а также не было выявлено клинически значимых эффектов на метаболизм
        липидов и гемостаз. Прием препарата Зоэли® увеличивал концентрации белков- переносчиков тироксин-связывающего
        глобулина и кортикостероид-связывающего глобулина (КСГ). При приеме препарата Зоэли® незначительно увеличивалась
        концентрация глобулина, связывающего половые гормоны (ГСПГ), и значительно уменьшались концентрации
        андростендиона, дегидроэпиандростерона, общего и свободного тестостерона. В клиническом исследовании у группы
        женщин (n=32) после 13 циклов приема препарата Зоэли® не наблюдалось патологических изменений при
        гистологическом исследовании эндометрия.
      </P>
      <P><b style={{fontSize: 20}}>Фармакокинетика</b></P>
      <P><b><u>Номегэстрола ацетат</u></b></P>
      <P><b>Всасывание</b></P>
      <P>Номегэстрола ацетат быстро всасывается после приема внутрь. После однократного приема максимальная концентрация
        в плазме составляет около 7 нг/мл и достигается через 2 ч. Абсолютная биодоступность после однократного приема
        составляет 63%. Пища не оказывает клинически значимого влияния на биодоступность номегэстрола ацетата.
      </P>
      <P><b>Распределение
      </b></P>
      <P>Номегэстрола ацетат активно связывается с альбумином (97-98%), но не связывается с ГСПГ или КСГ. Явный объем
        распределения номегэстрола ацетата в равновесном состоянии составляет 1645 ± 576 л.
      </P>
      <P><b>Метаболизм
      </b></P>
      <P>Номегэстрола ацетат метаболизируется до нескольких неактивных гидроксилированных метаболитов под действием
        изоферментов цитохрома Р450 печени, в основном CYPЗА4 и CYPЗА5; также возможно участие в метаболизме
        изоферментов CYP2С8 и CYP2С19. Номегэстрола ацетат и его гидроксилированные производные подвергаются выраженному
        метаболизму второй фазы с образованием глюкуронидных и сульфатных конъюгатов. Клиренс в равновесном состоянии
        составляет 26 л/ч.
      </P>
      <P><b>Выведение
      </b></P>
      <P>Период полувыведения (t1/2) в равновесном состоянии составляет 46 ч (от 28 до 83 ч). Период полувыведения
        метаболитов не установлен.
      </P>
      <P>Номегэстрола ацетат выводится почками и через кишечник. Примерно 80% дозы выводится почками и через кишечник в
        течение 4 дней. Номегэстрола ацетат практически полностью выводится в течение 10 дней. Выведение через кишечник
        превышает выведение почками.
      </P>
      <P><b>Линейность
      </b></P>
      <P>Линейность фармакокинетики в зависимости от дозы отмечалась в диапазоне 0,625-5 мг (оценивалась у женщин
        репродуктивного и постменопаузального возраста).
      </P>
      <P><b>Равновесное состояние
      </b></P>
      <P>ГСПГ не оказывает влияния на фармакокинетику номегэстрола ацетата. Равновесное состояние достигается через 5
        дней. Средняя концентрация в равновесном состоянии составляет 4 нг/мл. Максимальная концентрация номегэстрола
        ацетата в плазме составляет около 12 нг/мл и достигается через 1,5 ч после приема препарата.
      </P>
      <P><b>Взаимодействия
      </b></P>
      <P>In vitro номегэстрола ацетат не оказывает существенного индуцирующего или ингибирующего действия на изоферменты
        цитохрома Р450 и не взаимодействует с гликопротеином Р.
      </P>
      <P><b><u>Эстрадиол (Е2)
      </u></b></P>
      <P><b>Всасывание
      </b></P>
      <P>Эстрадиол подвергается выраженному метаболизму при «первом прохождении» через печень после приема внутрь.
        Абсолютная биодоступность составляет около 1%. Прием пищи не оказывает клинически значимого влияния на
        биодоступность эстрадиола.
      </P>
      <P><b>Распределение
      </b></P>
      <P>Распределение экзогенного и эндогенного эстрадиола сходное. Эстрогены активно распределяются по всему
        организму. Их концентрации обычно выше в органах-мишенях половых гормонов. В крови эстрадиол связывается с ГСПГ
        (37%) и альбумином (61%) и только 1-2% эстрадиола циркулирует в несвязанном виде.
      </P>
      <P><b>Метаболизм
      </b></P>
      <P>Экзогенный эстрадиол активно биотрансформируется после приема внутрь. Метаболизм экзогенного и эндогенного
        эстрадиола сходный. Эстрадиол быстро превращается в несколько метаболитов в кишечнике и печени (в основном в
        эстрон), которые в последующем конъюгируются и подвергаются кишечно-печеночной рециркуляции. Имеется
        динамическое равновесие между эстрадиолом, эстроном и эстрон-сульфатом за счет активности различных ферментов,
        включая эстрадиолдегидрогеназы, сульфотрансферазы и арилсульфатазы. Окисление эстрона и эстрадиола происходит
        под действием изоферментов цитохрома Р450, в основном CYP1А2, CYP1А2 (вне печени), CYPЗА4, CYPЗА5, CYP1В1 и
        CYP2С9.
      </P>
      <P><b>Выведение
      </b></P>
      <P>Эстрадиол быстро выводится из крови. За счет метаболизма и кишечно-печеночной рециркуляции имеется большой пул
        циркулирующих сульфатов и глюкуронидов эстрогенов. В результате период полувыведения эстрадиола,
        скорректированный с учетом исходного показателя, варьирует в широких пределах и составляет 3,6 ± 1,5 ч после
        внутривенного введения.
      </P>
      <P><b>Равновесное состояние
      </b></P>
      <P>Максимальная концентрация эстрадиола в плазме крови составляет около 90 пг/мл и достигается через 6 ч после
        приема. Средние концентрации в плазме крови — 50 пг/мл.
      </P>
      <P>Данные концентрации эстрадиола соответствуют таковым в начальной и поздней фазах менструального цикла.
      </P>
      <P><b><u>Особые группы пациентов
      </u></b></P>
      <P>Дети
      </P>
      <P>Фармакокинетика номегэстрола ацетата (первичная цель) после однократного приема внутрь препарата Зоэли® была
        сопоставима у здоровых девочек-подростков после наступления менархе и у взрослых женщин. Концентрация эстрадиола
        (вторичная цель) у девочек-подростков в сравнении со взрослыми женщинами была сопоставимой в течение первых 24 ч
        и ниже, чем у взрослых женщин, через 24 ч. Клиническая значимость данного результата неизвестна.
      </P>
      <P><b>Нарушение функции почек
      </b></P>
      <P>Влияние заболеваний почек на фармакокинетику препарата Зоэли® не изучалось.
      </P>
      <P><b>Нарушение функции печени
      </b></P>
      <P>Влияние заболеваний печени на фармакокинетику препарата Зоэли® не изучалось. Однако у пациентов с нарушением
        функции печени возможно ухудшение метаболизма половых гормонов.
      </P>
      <P><b>Этнические группы
      </b></P>
      <P>Фармакокинетика препарата у представителей этнических групп специально не изучалась.
      </P>

      <H2 id={'ind-for-use'}>Показания к применению</H2>
      <P>Контрацепция.</P>

      <H2 id={'dication'}>противоПоказания</H2>
      <P>Комбинированные гормональные контрацептивы (КГК) не следует применять при наличии любого из перечисленных ниже
        состояний/заболеваний. Отсутствуют эпидемиологические данные о применении КОК, содержащих 17β-эстрадиол, однако
        противопоказания к применению препарата Зоэли® соответствуют противопоказаниям к применению КГК, содержащих
        этинилэстрадиол, В случае возникновения любого из этих состояний/заболеваний в период применения препарата
        Зоэли® следует немедленно прекратить прием препарата.
      </P>

      <UL>
        <LI><P>Тромбоз глубоких вен или тромбоэмболия легочной артерии, в том числе в анамнезе.
        </P></LI>
        <LI><P>Артериальные тромбозы (инфаркт миокарда) или продромальные состояния (транзиторная ишемическая атака,
          стенокардия), в том числе в анамнезе.
        </P></LI>
        <LI><P>Острые нарушения мозгового кровообращения, в том числе в анамнезе.
        </P></LI>
        <LI><P>Мигрень с очаговыми неврологическими симптомами, в том числе в анамнезе.
        </P></LI>
        <LI><P>Выраженные или множественные факторы риска венозных или артериальных тромбозов (см. раздел «Особые
          указания»), такие как:
        </P>
          <UL>
            <LI><P>сахарный диабет с сосудистыми симптомами;
            </P></LI>
            <LI><P>неконтролируемая артериальная гипертензия;
            </P></LI>
            <LI><P>тяжелая дислипопротеинемия;
            </P></LI>
            <LI><P>ожирение (индекс массы тела более 30 кг/м2);
            </P></LI>
            <LI><P>длительная иммобилизация;
            </P></LI>
            <LI><P>любая операция на нижних конечностях или серьезная травма;
            </P></LI>
            <LI><P>осложненные пороки сердца;
            </P></LI>
            <LI><P>фибрилляция предсердий;
            </P></LI>
            <LI><P>курение в возрасте старше 35 лет.
            </P></LI>
          </UL>
        </LI>
        <LI><P>Обширное оперативное вмешательство с длительной иммобилизацией (см. раздел «Особые указания»).
        </P></LI>
        <LI><P>Наследственная или приобретенная предрасположенность к развитию венозного или артериального тромбоза,
          например, резистентность к активированному протеину С, дефицит антитромбина III, дефицит протеинов С и S,
          гипергомоцистеинемия и антифосфолипидные антитела (антитела к кардиолипину, волчаночный антикоагулянт).
        </P></LI>
        <LI><P>Панкреатит с тяжелой гипертриглицеридемией, в том числе в анамнезе.
        </P></LI>
        <LI><P>Тяжелые заболевания печени, в том числе в анамнезе, до нормализации показателей функции печени.
        </P></LI>
        <LI><P>Опухоли печени (злокачественные или доброкачественные), в том числе в анамнезе.
        </P></LI>
        <LI><P>Известные или предполагаемые гормонозависимые злокачественные опухоли (например, половых органов или
          молочной железы).
        </P></LI>
        <LI><P>Установленная или предполагаемая беременность, период грудного вскармливания.
        </P></LI>
        <LI><P>Гиперчувствительность к любому действующему или вспомогательному веществу.
        </P></LI>
        <LI><P>Дефицит лактазы, непереносимость лактозы, глюкозо-галактозная мальабсорбция.
        </P></LI>
        <LI><P>Кровотечения из влагалища неясной этиологии.
        </P></LI>
        <LI><P>Постменопауза.
        </P></LI>
      </UL>
      <P>Отсутствуют данные об эффективности и безопасности применения препарата Зоэли® у девочек-подростков в
        возрасте до 18 лет. Имеющаяся информация по фармакокинетике представлена в разделе «Фармакокинетика».</P>

      <H2 id={'warning'}>С осторожностью</H2>
      <P>При наличии любых из перечисленных ниже состояний, заболеваний, факторов риска следует оценить пользу от
        применения препарата Зоэли® и возможные риски для каждой отдельной женщины. Это следует обсудить с женщиной еще
        до того, как она начнет прием препарата Зоэли®. Дополнительная информация содержится в разделе «Особые
        указания». В случаях ухудшения, обострения или возникновения впервые любого из этих состояний, заболеваний,
        факторов риска женщине следует обратиться к врачу для решения вопроса о возможности дальнейшего применения
        препарата Зоэли®.
      </P>

      <UL>
        <LI><P>Сахарный диабет без поражения сосудов.
        </P></LI>
        <LI><P>Тяжелая депрессия или наличие этого заболевания в анамнезе.
        </P></LI>
        <LI><P>Системная красная волчанка.
        </P></LI>
        <LI><P>Болезнь Крона.
        </P></LI>
        <LI><P>Язвенный колит.
        </P></LI>
        <LI><P>Нарушения функции печени.
        </P></LI>
        <LI><P>Гипертриглицеридемия, в том числе в семейном анамнезе.
        </P></LI>
        <LI><P>Факторы риска ишемической болезни сердца (ожирение, артериальная гипертензия).
        </P></LI>
        <LI><P>Наличие в семейном анамнезе венозных тромбозов, артериальной эмболии у братьев, сестер или у родителей в
          возрасте `{'<'}`50 лет (см. раздел «Особые указания»).
        </P></LI>
        <LI><P>Послеродовой период.
        </P></LI>
        <LI><P>Мигрень без очаговой неврологической симптоматики.
        </P></LI>
        <LI><P>Не осложненные заболевания клапанов сердца.
        </P></LI>
        <LI><P>Серповидно-клеточная анемия,
        </P></LI>
        <LI><P>Наследственный ангионевротический отек.
        </P></LI>
        <LI><P>Порфирия.
        </P></LI>
        <LI><P>Отосклероз с нарушением слуха.
        </P></LI>
        <LI><P>Герпес во время беременности.
        </P></LI>
        <LI><P>Хорея Сиденгама.
        </P></LI>
        <LI><P>Возраст старше 35 лет.
        </P></LI>
        <LI><P>Желтуха и/или зуд, связанные с холестазом.
        </P></LI>
        <LI><P>Холелитиаз.
        </P></LI>
        <LI><P>Хлоазма.</P></LI>
      </UL>

      <H2 id={'for-use-feed'}>Применение в период беременности и грудного вскармливания</H2>
      <P><b style={{fontSize: 20}}>Беременность
      </b></P>
      <P>Применение препарата Зоэли® противопоказано в период беременности. В случае возникновения беременности при
        применении препарата Зоэли® следует прекратить прием препарата.
      </P>
      <P>В большинстве эпидемиологических исследований не было выявлено увеличения риска развития врожденных пороков у
        детей, чьи матери до беременности принимали КОК, содержащие этинилэстрадиол. При случайном приеме в начале
        беременности КОК, содержащих этинилэстрадиол, не было отмечено тератогенных эффектов.
      </P>
      <P>Имеются ограниченные клинические данные относительно приема препарата Зоэли® беременными женщинами, которые
        свидетельствует об отсутствии нежелательного влияния препарата на состояние плода или новорожденного.
      </P>
      <P>В исследованиях комбинации номегэстрола ацетат / эстрадиол на лабораторных животных была зафиксирована
        репродуктивная токсичность.
      </P>
      <P>Препарат Зоэли® предназначен для предупреждения нежелательной беременности. Если женщина хочет прекратить прием
        препарата Зоэли®, чтобы забеременеть, следует принять во внимание, что овуляция восстанавливается в среднем
        через 20,8 дней после последнего приема таблетки препарата Зоэли® (см. раздел «Фармакологические свойства»,
        подраздел «Фармакодинамика»).
      </P>
      <P><b style={{fontSize: 20}}>Период грудного вскармливания
      </b></P>
      <P>КОК могут оказывать влияние на лактацию, так как они вызывают изменение количества и состава грудного молока.
        Следовательно, прием препарата противопоказан до полного прекращения грудного вскармливания (следует подобрать
        альтернативный метод контрацепции). Небольшие количества контрацептивных половых гормонов и/или их метаболитов
        могут проникать в грудное молоко, однако данных об их нежелательном влиянии на здоровье новорожденного нет.
      </P>

      <H2 id={'dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</H2>
      <P>Препарат предназначен для приема внутрь.
      </P>
      <P><b style={{fontSize: 20}}>Как следует принимать препарат Зоэли®
      </b></P>
      <P>Таблетки принимают ежедневно в одно и то же время дня независимо от приема пищи в порядке, указанном на
        упаковке, при необходимости запивая небольшим количеством воды. Следует принимать по одной таблетке в день в
        течение 28 дней подряд. Прием следует начинать с белых таблеток, содержащих действующие вещества. Белые
        таблетки, содержащие действующие вещества, принимают в течение первых 24 дней. В течение последующих 4 дней
        принимают желтые таблетки, не содержащие действующих веществ (плацебо). Прием таблеток из каждой последующей
        упаковки следует начинать на следующий день после приема последней таблетки из предыдущей упаковки, независимо
        от наличия или отсутствия кровотечения «отмены». Кровотечение «отмены» обычно начинается через 2-3 дня после
        приема последней белой таблетки и может не прекратиться к началу приема таблеток из следующей упаковки.
        Дополнительная информация представлена в разделе «Особые указания», подраздел «Изменения характера менструаций».
      </P>
      <P><b style={{fontSize: 20}}>Особые группы пациентов
      </b></P>
      <P><b>Нарушение функции почек
      </b></P>
      <P>Данные о применении у пациентов с почечной недостаточностью отсутствуют, однако влияние почечной
        недостаточности на выведение номегэстрола ацетата и эстрадиола маловероятно.
      </P>
      <P><b>Нарушение функции печени
      </b></P>
      <P>Влияние заболеваний печени на фармакокинетику препарата Зоэли® не изучалось. Однако поскольку у пациенток с
        нарушением функции печени возможно ухудшение метаболизма половых гормонов, применение препарата Зоэли® у данной
        группы пациенток не рекомендуется до нормализации показателей функции печени (см. раздел «Противопоказания»).
      </P>
      <P><b>Как следует начинать прием препарата Зоэли®
      </b></P>
      <P><b>В предыдущем цикле гормональные контрацептивы не применялись
      </b></P>
      <P>Прием таблеток следует начать в первый день менструального цикла женщины (в первый день менструального
        кровотечения). В этом случае применение дополнительных контрацептивных средств не требуется. Можно начать прием
        таблеток и со 2-5-го дня цикла. В таком случае в течение первых 7 дней приема таблеток рекомендуется
        дополнительно использовать барьерный метод контрацепции.
      </P>
      <P><b>Переход с комбинированного гормонального контрацептива (КОК, вагинального кольца или трансдермалъного
        пластыря)
      </b></P>
      <P>Женщине желательно начать прием препарата Зоэли® на следующий день после приема последней таблетки, содержащей
        действующие вещества, но не позднее чем на следующий день после завершения обычного интервала между циклами или
        приема таблеток плацебо. Если женщина пользовалась вагинальным кольцом или трансдермальным пластырем, то
        начинать прием препарата Зоэли® желательно в день их удаления, но не позднее чем в день, когда следовало бы
        ввести новое кольцо или наклеить очередной пластырь.
      </P>
      <P><b>Переход с препаратов, содержащих только прогестаген (таблетки, имплантаты, инъекционные формы или
        гормоно-содержащие внутриматочные системы (ВМС))
      </b></P>
      <P>Женщина может в любой день прекратить прием таблеток, содержащих только прогестаген, и на следующий день начать
        прием препарата Зоэли®. Имплантат или ВМС можно удалить в любой день. В таком случае прием препарата Зоэли®
        следует начать в день их удаления. Если женщина получала инъекции, то прием препарата Зоэли® начинают в день,
        когда следовало произвести очередную инъекцию. Во всех этих случаях женщине следует рекомендовать дополнительно
        пользоваться барьерным методом контрацепции в течение первых 7 дней приема таблеток, содержащих действующие
        вещества.
      </P>
      <P><b>После аборта в первом триместре беременности
      </b></P>
      <P>Женщина может начать прием препарата сразу же. В этом случае нет необходимости в дополнительном методе
        контрацепции.
      </P>
      <P><b>После родов или аборта во втором триместре беременности
      </b></P>
      <P>Для кормящих грудью женщин см. раздел «Применение при беременности и в период грудного вскармливания».
      </P>
      <P>При отсутствии грудного вскармливания прием препарата можно начать между 21-м и 28-м днем после родов или
        аборта во втором триместре беременности. При более позднем начале приема препарата женщине следует рекомендовать
        применять дополнительный барьерный метод контрацепции в течение первых 7 дней приема таблеток. Однако если после
        родов или аборта уже были половые контакты, перед началом приема препарата Зоэли® необходимо исключить
        беременность или дождаться первой менструации.
      </P>
      <P>При возобновлении приема препарата Зоэли® необходимо принимать во внимание повышение риска развития венозной
        тромбоэмболии (ВТЭ) в послеродовом периоде (см. раздел «Особые указания»).
      </P>
      <P><b style={{fontSize: 20}}>Что делать в случае пропуска таблеток
      </b></P>
      <P>Приведенные ниже рекомендации касаются только пропуска приема белых таблеток, содержащих действующие вещества.
      </P>
      <P>Если женщина принимает очередную таблетку с опозданием менее 24 часов, то контрацептивный эффект не снижается.
        Женщине следует принять таблетку как можно скорее, после того, как она об этом вспомнит. Последующие таблетки
        необходимо принимать в обычное время.
      </P>
      <P>Если женщина принимает таблетку, содержащую действующие вещества, с опозданием 24 часа и более, то
        контрацептивный эффект может снизиться. При пропуске приема таблеток целесообразно выполнять два правила:
      </P>
      <UL>
        <LI><P>чтобы добиться адекватного подавления гипоталамо-гипофизарно-яичниковой системы, белые таблетки,
          содержащие действующие вещества, необходимо принимать в течение по крайней мере 7 дней подряд;
        </P></LI>
        <LI><P>чем больше пропущено белых таблеток, содержащих действующие вещества, и ближе время приема 4 желтых
          таблеток плацебо, тем выше риск наступления беременности.
        </P></LI>
      </UL>
      <P><b style={{fontSize: 20}}>Дни 1-7</b></P>
      <P>Женщина должна принять последнюю пропущенную белую таблетку, как только она об этом вспомнит, даже если ей
        придется принять одновременно две таблетки. Затем таблетки следует принимать как обычно. Кроме того, в течение
        первой недели непрерывного приема белых таблеток необходимо пользоваться барьерным методом контрацепции, таким
        как презерватив. Если в течение предыдущих 7 дней имел место половой акт, то следует учитывать возможность
        наступления беременности.
      </P>
      <P><b style={{fontSize: 20}}>Дни 8-17</b></P>
      <P>Женщина должна принять последнюю пропущенную белую таблетку, как только она об этом вспомнит, даже если ей
        придется принять одновременно две таблетки. Затем таблетки следует принимать как обычно. При условии, что
        женщина правильно принимала таблетки в течение 7 дней, предшествующих дню пропуска первой таблетки, нет
        необходимости применять дополнительные контрацептивные меры. Тем не менее, если женщина пропустила прием более 1
        таблетки, ей следует рекомендовать применять дополнительные контрацептивные меры до тех пор, пока она не
        завершит 7-дневный период непрерывного приема белых таблеток.
      </P>
      <P><b style={{fontSize: 20}}>Дни 18-24
      </b></P>
      <P>Риск снижения контрацептивного эффекта крайне велик из-за приближения начала фазы приема желтых таблеток
        плацебо. Однако изменение схемы приема таблеток позволяет избежать снижения контрацептивного действия. Если
        следовать одному из двух указанных ниже вариантов, необходимость в применении дополнительных контрацептивных мер
        отсутствует, при условии, что в течение 7 дней, предшествующих дню пропуска первой таблетки, женщина принимала
        все таблетки правильно. В ином случае ей необходимо следовать первому из этих двух вариантов, а также применять
        дополнительные контрацептивные меры в течение следующих 7 дней.
      </P>
      <ol style={{paddingLeft: 20}}>
        <LI><P>Женщина должна принять последнюю пропущенную таблетку, как только она об этом вспомнит, даже если ей
          придется принять одновременно две таблетки. Затем таблетки следует принимать как обычно до тех пор, пока не
          закончатся таблетки, содержащие действующие вещества. 4 таблетки плацебо из последнего ряда блистера следует
          выбросить. Следует сразу начать прием таблеток из другого блистера. Наступление у женщины кровотечения
          «отмены» до окончания приема таблеток с действующими веществами из второго блистера маловероятно, но в дни
          приема таблеток у нее могут наблюдаться «прорывные» кровотечения или «мажущие» выделения.
        </P></LI>
        <LI><P>Женщине можно также рекомендовать прекратить прием таблеток, содержащих действующие вещества, из текущего
          блистера. Затем она должна принимать таблетки плацебо из последнего ряда в течение максимум 3 дней так, чтобы
          общее количество таблеток плацебо и пропущенных белых таблеток, содержащих действующие вещества, было не более
          4, а затем продолжить прием таблеток из следующей блистерной упаковки.
        </P></LI>
      </ol>
      <P>Если женщина пропустила прием таблеток и в дальнейшем у нее отсутствует кровотечение «отмены» в период приема
        таблеток плацебо, то следует учитывать возможность наступления беременности.
      </P>
      <P>Если женщина не уверена в числе пропущенных таблеток или их цвете и, соответственно, не знает, какие
        рекомендации ей следует выполнять, то необходимо пользоваться барьерным методом контрацепции до того момента,
        как женщина примет в течение 7 последовательных дней белые таблетки.
      </P>
      <P><b style={{fontSize: 20}}>Если пропущен прием желтых таблеток плацебо
      </b></P>
      <P>Контрацептивный эффект не снижен. Женщина может не принимать желтые таблетки из последнего (четвертого) ряда
        блистера. Однако пропущенные таблетки следует выбросить, чтобы избежать непреднамеренного увеличения
        длительности фазы плацебо.
      </P>
      <P><b style={{fontSize: 20}}>Рекомендации в случае желудочно-кишечных расстройств</b></P>
      <P>В случае желудочно-кишечных расстройств (например, рвоты или диареи) всасывание препарата может быть неполным,
        поэтому следует прибегнуть к дополнительным мерам контрацепции.
      </P>
      <P>Если рвота возникает в течение 3-4 ч после приема таблетки, то ее прием следует считать пропущенным, и
        необходимо как можно скорее принять новую таблетку. Если это возможно, новую таблетку следует принять в течение
        24 часов от обычного времени приема таблетки. Следующую таблетку следует принять в обычное время. Если с момента
        приема последней таблетки прошло 24 часа или более, то необходимо выполнять рекомендации при пропуске приема
        таблеток (см. «Что делать в случае пропуска таблеток»). Если женщина не хочет менять обычную схему приема
        таблеток, то она должна принять дополнительно белую таблетку (или таблетки) из другой упаковки.
      </P>
      <P><b style={{fontSize: 20}}>Как сдвинуть или отсрочить наступление менструальноподобного кровотечения
      </b></P>
      <P>Чтобы отсрочить наступление менструальноподобного кровотечения, женщине следует продолжать прием белых таблеток
        из другой упаковки без приема желтых таблеток. Белые таблетки из второй упаковки можно продолжать принимать до
        тех пор, пока они не закончатся. После завершения приема желтых таблеток из второй упаковки необходимо
        возобновить прием препарата Зоэли® по обычной схеме. При удлиненной схеме приема могут наблюдаться «прорывные»
        кровотечения или «мажущие» выделения.
      </P>
      <P>Для того чтобы сместить день начала менструальноподобного кровотечения на другой день, можно сократить фазу
        приема желтых таблеток плацебо (максимальная длительность приема желтых таблеток плацебо — 4 дня). Чем короче
        перерыв, тем выше риск отсутствия менструальноподобного кровотечения «отмены» и возникновения «прорывных»
        кровотечений или «мажущих» кровянистых выделений во время приема таблеток из второй упаковки (как и в случае
        отсроченного наступления менструальноподобного кровотечения).
      </P>

      <H2 id={'effect-action'}>Побочное действие</H2>
      <P>Безопасность применения препарата Зоэли® оценивалась в ходе семи многоцентровых клинических исследований
        продолжительностью до двух лет. В данные исследования были включены 3490 женщин в возрасте 18-50 лет (суммарно
        35028 циклов).
      </P>
      <P>Переносимость препарата Зоэли® хорошая, профиль безопасности сходен с таковым у других КОК. В таблице
        перечислены возможные нежелательные эффекты, которые были зарегистрированы при применении препарата.
      </P>
      <P>Частота нежелательных явлений указана в терминах «очень часто» (≥1/10), «часто» (`{'<'}`1/10, ≥1/100),
        «нечасто» (`{'<'}`1/100, ≥1/1000) и «редко» (`{'<'}`1/1000, ≥1/10000) в соответствии с классификацией MedDRA
        (синонимы или сопутствующие состояния не перечислены, однако также должны учитываться).</P>

      <Tables.WrapperTable sx={{overflowX: 'auto'}}>
        <Tables.Table sx={{minWidth: 900, tableLayout: 'fixed'}}>
          <Tables.Thead>
            <Tables.Tr sx={{color: PinkLight, fontWeight: 'bold'}}>
              <Tables.Td>Класс система/орган</Tables.Td>
              <Tables.Td>Очень часто</Tables.Td>
              <Tables.Td>Часто</Tables.Td>
              <Tables.Td>Нечасто</Tables.Td>
              <Tables.Td>Редко</Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны обмена веществ и питания</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>повышение аппетита, задержка жидкости</Tables.Td>
              <Tables.Td>снижение аппетита</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения психики</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>снижение либидо, депрессия, перепады настроения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>повышение либидо</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со cтороны нервной системы</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>мигрень, головная боль</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>транзиторная ишемическая атака, цереброваскулярные нарушения, нарушение внимания</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны органа зрения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>непереносимость контактных линз, сухость слизистой глаз</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны сосудов</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>«приливы»</Tables.Td>
              <Tables.Td>венозная тромбоэмболия</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны желудочно-кишечного тракта</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>тошнота</Tables.Td>
              <Tables.Td>вздутие живота</Tables.Td>
              <Tables.Td>сухость во рту</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны гепатобилиарной системы</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>холецистит, желчнокаменная болезнь</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны кожи и подкожных тканей</Tables.Td>
              <Tables.Td>акне<Sup sx={{color: '#FFF'}}>1</Sup></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>гипергидроз, алопеция, зуд, сухость кожи, себорея</Tables.Td>
              <Tables.Td>хлоазма, гипертрихоз</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны скелетно-мышечной системы и соединительной ткани</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>ощущение тяжести</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны половых органов и молочной железы</Tables.Td>
              <Tables.Td>нерегулярные кровотечения «отмены»</Tables.Td>
              <Tables.Td>обильные ациклические кровотечения, обильные менструально- подобные кровотечения, болезненность
                молочных желез, боль в области малого таза</Tables.Td>
              <Tables.Td>скудные менструально-подобные кровотечения, нагрубание молочных желез, галакторея, спазм мышц
                матки, предменструально- подобный синдром, уплотнения в молочных железах, диспареуния, сухость слизистой
                вульвы и влагалища</Tables.Td>
              <Tables.Td>неприятный запах из влагалища, дискомфорт в области влагалища
              </Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Общие расстройствам нарушения в месте введения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>раздражительность, отек</Tables.Td>
              <Tables.Td>чувство голода</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Лабораторные и инструментальные данные</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>увеличение массы тела</Tables.Td>
              <Tables.Td>увеличение активности печеночных ферментов</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P><Sup sx={{color: '#FFF'}}>1</Sup>Акне представляет собой не спонтанно сообщаемое, а запрашиваемое явление,
        поскольку оценка проводилась при каждом визите в ходе исследования.</P>
      <br/>
      <P>Кроме указанных выше нежелательных явлений, при применении препарата Зоэли® сообщалось о развитии артериальной
        тромбоэмболии, реакциях гиперчувствительности (частота возникновения не установлена).
      </P>
      <P>Побочные эффекты, которые возникали при приеме КОК, содержащих этинилэстрадиол, подробно описаны в разделе
        «Особые указания»: венозные и артериальные тромбоэмболии, повышение артериального давления, гормонозависимые
        опухоли (например, опухоли печени, рак молочной железы), хлоазма.
      </P>
      <P>У женщин с гипертриглицеридемией или соответствующим семейным анамнезом повышен риск развития панкреатита при
        приеме КОК.
      </P>
      <P>У женщин с наследственными формами ангионевротического отека экзогенные эстрогены могут вызывать или ухудшать
        симптомы ангионевротического отека.</P>

      <H2 id={'over-dozing'}>Передозировка</H2>
      <P>Повторное применение препарата Зоэли® в дозах, которые в 5 раз превышали рекомендуемые, и однократный прием
        номегэстрола ацетата в дозах, которые в 40 раз превышали рекомендуемые, не сопровождались нежелательными
        явлениями.
      </P>
      <P>Симптомы, которые могут возникнуть при передозировке: тошнота, рвота, кровянистые выделения из влагалища.
        Антидотов не существует. Дальнейшее лечение должно быть симптоматическим.</P>

      <H2 id={'another-medicines'}>Взаимодействие с другими лекарственными средствами</H2>
      <P>Для определения возможного взаимодействия необходимо ознакомиться с инструкцией по применению сопутствующих
        препаратов.
      </P>
      <P><b style={{fontSize: 20}}>Влияние других лекарственных препаратов на препарат Зоэли®
      </b></P>
      <P>Взаимодействие пероральных контрацептивов с другими лекарственными средствами, индуцирующими ферменты, может
        привести к «прорывным» кровотечениям и/или снижению эффективности контрацепции.
      </P>
      <P>Печёночный метаболизм: возможно взаимодействие с лекарственными или растительными препаратами — индукторами
        микросомальных ферментов, прежде всего ферментов цитохрома Р450 (CYP), что может привести к увеличенному
        клиренсу и уменьшению концентрации половых гормонов в плазме крови и к снижению эффективности КОК, в том числе и
        лекарственного препарата Зоэли®. К таким лекарственным препаратам относятся препараты, содержащие фенитоин,
        фенобарбитал, примидон, бозентан, карбамазепин, рифампицин; лекарственные препараты или препараты растительного
        происхождения, содержащие зверобой продырявленный (Hypericum perforatum) в меньшей степени лекарственные
        препараты, содержащие окскарбазепин, топирамат, фелбамат и гризеофульвин; а также некоторые ингибиторы протеазы
        ВИЧ (например, ритонавир и нелфинавир) и ненуклеозидные ингибиторы обратной транскриптазы (например, невирапин и
        эфавиренз).
      </P>
      <P>Индуцирование ферментов может происходить после нескольких дней применения препарата. Максимальное
        индуцирование ферментов обычно наблюдается в течение нескольких недель. После прекращения терапии препаратом
        индуцирование ферментов может продолжаться приблизительно 28 дней.
      </P>
      <P>При совместном применении с гормональными контрацептивами многие комбинации ингибиторов протеазы ВИЧ (например,
        нелфинавира) и ненуклеозидных ингибиторов обратной транскриптазы (например, невирапина) и/или комбинации с
        препаратами для лечения вируса гепатита С (например, боцепревиром, телапревиром) могут увеличивать или уменьшать
        концентрацию прогестагенов, включая номегэстрола ацетат, или эстрогена. В некоторых случаях суммарный эффект
        этих изменений может быть клинически значимым.
      </P>
      <P>Женщинам, получающим лечение одним из вышеуказанных лекарственных или растительных препаратов, индуцирующих
        микросомальные печеночные ферменты, следует сообщить, что эффективность препарата Зоэли® может быть снижена. Во
        время сопутствующего приема препаратов, индуцирующих микросомальные печеночные ферменты, и в течение 28 дней
        после их отмены следует также пользоваться барьерным методом контрацепции.
      </P>
      <P>Если прием сопутствующего препарата продолжается и после окончания в упаковке таблеток, содержащих активные
        вещества, то необходимо пропустить интервал приема таблеток плацебо и начать прием таблеток из следующей
        упаковки препарата.
      </P>
      <P>При длительном лечении лекарственными препаратами, индуцирующими микросомальные печеночные ферменты, необходимо
        рассмотреть использование альтернативного метода контрацепции, не подверженного влиянию лекарственных
        препаратов, индуцирующих микросомальные ферменты.
      </P>
      <P>Совместное применение сильных (например, кетоконазола, итраконазола, кларитромицина) или средних (например,
        флуконазола, дилтиазема, эритромицина) ингибиторов CYP3А может увеличить концентрацию эстрогенов и прогестагенов
        в сыворотке крови.
      </P>
      <P>Для препарата Зоэли® не проводилось исследований лекарственных взаимодействий, однако были проведены два
        исследования применения комбинации номегэстрола ацетата и эстрадиола в более высоких дозах (номегэстрола ацетат
        3,75 мг + эстрадиол 1,5 мг) в сочетании с рифампицином и в сочетании с кетоконазолом в популяции женщин
        постменопаузального возраста. Сопутствующий прием рифампицина снижает AUC0-∞ номегэстрола ацетата на 95% и
        увеличивает AUC0-t(last) эстрадиола на 25%. Сопутствующий прием кетоконазола (однократная доза 200 мг) не
        оказывает влияния на метаболизм эстрадиола, но увеличивает максимальную концентрацию (85%) и AUC0-∞ (115%)
        номегэстрола ацетата, однако данные изменения не являются клинически значимыми. Предполагается, что схожие
        изменения могут иметь место в случае применения данных препаратов у женщин репродуктивного возраста.
      </P>
      <P><b style={{fontSize: 20}}>Влияние препарата Зоэли® на другие лекарственные препараты</b></P>
      <P>Пероральные контрацептивы могут влиять на метаболизм других лекарственных средств. Соответственно, концентрации
        лекарственных препаратов в плазме и в тканях могут повышаться (например, циклоспорина) или снижаться (например,
        ламотриджина).
      </P>
      <P><b style={{fontSize: 20}}>Влияние препарата Зоэли® на лабораторные тесты</b></P>
      <P>Прием КОК может влиять на результаты некоторых лабораторных тестов, включая биохимические показатели функции
        печени, щитовидной железы, надпочечников и почек, концентрации (транспортных) белков в плазме (например, КСГ и
        фракции липидов/липопротеинов), показатели углеводного обмена, показатели свертывания крови и фибринолиза.
        Данные показатели обычно не выходят за границы нормальных значений.
      </P>

      <H2 id={'special-instructions'}>Особые указания</H2>
      <P>При наличии любых из перечисленных ниже состояний, заболеваний, факторов риска следует оценить пользу от
        применения препарата Зоэли® и возможные риски для каждой отдельной женщины. Это следует обсудить с женщиной еще
        до того, как она начнет прием препарата Зоэли®. В случаях ухудшения, обострения или возникновения впервые любого
        из этих состояний, заболеваний, факторов риска женщине следует обратиться к врачу для решения вопроса о
        возможности дальнейшего применения препарата Зоэли®. Приведенные ниже данные были получены в ходе
        эпидемиологических исследований при применении КГК, содержащих этинилэстрадиол. Препарат Зоэли® содержит
        17β-эстрадиол, тем не менее особые указания, касающиеся приема комбинированных контрацептивов, содержащих
        этинилэстрадиол, считаются применимыми и для препарата Зоэли®.
      </P>
      <P><b style={{fontSize: 20}}>Сосудистые нарушения
      </b></P>

      <UL>
        <LI><P>Применение КГК сопровождается повышением риска развития ВТЭ по сравнению с риском развития ВТЭ у
          пациентов, не применяющих КГК. Наибольший дополнительный риск развития ВТЭ наблюдается в первый год применения
          КГК. Риск также повышается в начале применения КГК или при возобновлении применения того же или другого КГК
          после перерыва в 4 и более недель. Результаты эпидемиологических исследований демонстрируют, что частота
          развития ВТЭ у пациенток без известных факторов риска, принимающих низко дозированные (
          `{'<'}`
          50 мкг этинилэстрадиола) КГК, составляет от 3 до 12 случаев на 10000 женщин-лет (ЖЛ). Для сравнения,
          аналогичный параметр у небеременных пациенток, которые не принимают КГК, составляет от 1 до 5 случаев на 10000
          ЖЛ, а у беременных женщин — от 5 до 20 случаев на 10000 ЖЛ (данные о беременности основаны на фактической
          длительности беременности в стандартных исследованиях; на основании положения, что беременность длится 9
          месяцев, риск составляет от 7 до 27 случаев на 10000 ЖЛ). У женщин в послеродовом периоде риск развития ВТЭ
          составляет от 40 до 65 случаев на 10000 ЖЛ. ВТЭ может привести к летальному исходу в 1-2% случаев.
        </P></LI>
        <LI><P>Отсутствуют данные о влиянии препарата Зоэли® на риск развития венозных тромбозов и эмболий по сравнению
          с другими КГК.
        </P></LI>
        <LI><P>В эпидемиологических исследованиях установлена связь между применением КГК и повышением риска развития
          артериальной тромбоэмболии (инфаркт миокарда, транзиторные ишемические атаки).
        </P></LI>
        <LI><P>У пациенток, принимавших КГК, исключительно редко развивался тромбоз других сосудов, в том числе
          печеночных, мезентериальных, почечных, церебральных артерий и вен или сосудов сетчатки.
        </P></LI>
        <LI><P>Симптомы венозных и артериальных тромбозов или острого нарушения мозгового кровообращения могут включать
          в себя следующие состояния: внезапная боль и/или отек нижней конечности; внезапная интенсивная боль в груди,
          иррадиирующая или не иррадиирующая в левую руку; внезапная одышка; внезапный кашель; необычная тяжелая и
          длительная головная боль; внезапная частичная или полная потеря зрения; диплопия; нарушение речи или афазия;
          головокружение; коллапс, сопровождающийся или не сопровождающийся очаговыми судорогами; слабость или
          выраженное онемение, которые внезапно появляются на одной стороне тела; двигательные расстройства; «острый
          живот».
        </P></LI>
        <LI><P>Риск развития венозных тромбоэмболий возрастает при наличии следующих факторов риска:
        </P>
          <UL>
            <LI><P>Возраст.
            </P></LI>
            <LI><P>Наличие заболеваний в семейном анамнезе (венозные тромбозы и эмболии у братьев, сестер или у
              родителей в возрасте `{'<'}`50 лет). Если предполагается наследственная предрасположенность, то перед
              началом
              приема любых гормональных контрацептивов следует проконсультироваться со специалистом.
            </P></LI>
            <LI><P>Длительная иммобилизация, обширное оперативное вмешательство, любая операция на нижних конечностях
              или серьезная травма. В этих случаях следует прекратить прием препарата (по крайней мере за 4 недели до
              планового хирургического вмешательства) и возобновить его только через 2 недели после полного
              восстановления двигательной активности. В случае если применение КОК не было прекращено заранее, следует
              рассмотреть необходимость применения антитромботических средств (см. также раздел «Противопоказания»).
            </P></LI>
            <LI><P>Ожирение (индекс массы тела более 30 кг/м2).
            </P></LI>
            <LI><P>Авиаперелет длительностью более 4 часов может временно увеличить риск образования тромба, особенно у
              женщин с другими факторами риска.
            </P></LI>
          </UL>
        </LI>
        <LI><P>Отсутствует достаточная информация о роли тромбофлебита поверхностных вен и варикозного расширения вен в
          этиологии венозных тромбозов.
        </P></LI>
        <LI><P>Риск развития осложнений артериальных тромбоэмболий или острого нарушения мозгового кровообращения
          возрастает при наличии следующих факторов риска:

          <UL>
            <LI><P>Возраст.
            </P></LI>
            <LI><P>Курение (риск еще в большей степени увеличивается при интенсивном курении, особенно у женщин старше
              35 лет). Женщинам старше 35 лет следует настоятельно рекомендовать отказаться от курения, если они хотят
              принимать КОК.
            </P></LI>
            <LI><P>Дислипопротеинемия.
            </P></LI>
            <LI><P>Ожирение (индекс массы тела более 30 кг/м2).
            </P></LI>
            <LI><P>Артериальная гипертензия.
            </P></LI>
            <LI><P>Мигрень.
            </P></LI>
            <LI><P>Порок клапанов сердца.
            </P></LI>
            <LI><P>Фибрилляция предсердий.
            </P></LI>
            <LI><P>Наличие заболеваний в семейном анамнезе (артериальные тромбозы у братьев, сестер или у родителей в
              возрасте `{'<'}`50 лет). Если предполагается наследственная предрасположенность, то перед началом приема
              любых
              гормональных контрацептивов следует проконсультироваться со специалистом.
            </P></LI>
          </UL>
        </P></LI>
        <LI><P>Другие состояния, которые сопровождались нежелательными сосудистыми нарушениями, включают сахарный
          диабет, системную красную волчанку, гемолитико-уремический синдром, воспалительные заболевания кишечника
          (болезнь Крона и язвенный колит) и серповидно-клеточную анемию.
        </P></LI>
        <LI><P>Необходимо учитывать повышенный риск развития тромбоэмболических осложнений в послеродовом периоде.
        </P></LI>
        <LI><P>Увеличение частоты или тяжести мигрени при применении КОК (которое может предшествовать развитию
          цереброваскулярного осложнения) является основанием для немедленной отмены приема препарата Зоэли®.
        </P></LI>
      </UL>

      <P>Женщинам, принимающим КОК, необходимо обратиться к врачу при появлении возможных симптомов тромбоза. В случаях
        предполагаемого или подтвержденного тромбоза прием КОК следует прекратить. При этом следует начать адекватную
        контрацепцию, учитывая тератогенность терапии антикоагулянтами (кумаринами).
      </P>

      <P><b style={{fontSize: 20}}>Опухоли
      </b></P>

      <UL>
        <LI><P>Наиболее значимый фактор риска развития рака шейки матки — персистирующая инфекция, вызванная вирусом
          папилломы человека (HPV). В эпидемиологических исследованиях было
        </P></LI>
        <LI><P>показано, что длительное применение комбинированных контрацептивов,
        </P></LI>
        <LI><P>содержащих этинилэстрадиол, способствует повышению этого риска, однако остается неясным, в какой степени
          данный эффект связан с другими факторами, такими как более частое исследование шейки матки или особенности
          сексуального поведения, включая применение барьерных контрацептивов, или с комбинацией этих факторов.
          Причинно-следственная связь с приемом КОК не доказана.
        </P></LI>
        <LI><P>При применении КОК в более высоких дозах (50 мкг этинилэстрадиола) риск развития рака эндометрия и
          яичников снижается. Остается неясным, распространяется ли это на КОК, содержащие 17β-эстрадиол.
        </P></LI>
        <LI><P>При мета-анализе 54 эпидемиологических исследований у женщин, получавших КОК, содержащие этинилэстрадиол,
          было выявлено небольшое увеличение относительного риска (ОР) развития рака молочной железы (ОР = 1,24).
          Повышенный риск постепенно исчезает в течение 10 лет после прекращения приема КОК. Рак молочной железы редко
          развивается у женщин в возрасте до 40 лет, поэтому дополнительное число случаев рака молочной железы у женщин,
          которые принимают или принимали КОК, небольшое по сравнению с общим риском развития рака молочной железы. У
          женщин, применяющих КОК, выявляются более ранние стадии рака молочной железы, чем у женщин, никогда их не
          применявших.
        </P></LI>
        <LI><P>В другом эпидемиологическом исследовании, проведенном в Дании с участием 1,8 миллионов женщин, с
          последующим наблюдением в среднем 10,9 лет, было выявлено увеличение ОР развития рака молочной железы у
          женщин, длительно принимавших КОК, по сравнению с женщинами, которые никогда не применяли КОК (общий ОР =
          1,19; ОР варьировался от 1,17 [при применении от 1 года и до менее 5 лет] до 1,46 [при применении более 10
          лет]). Выявленная разница в абсолютном риске (количество случаев рака молочной железы среди женщин, которые
          никогда не принимали КОК, по сравнению с женщинами, которые принимают или недавно принимали КОК) была
          небольшой: 13 случаев на 100 000 женщин-лет.
        </P></LI>
        <LI><P>В эпидемиологических исследованиях доказательства связи не приводятся. Наблюдаемое повышение риска может
          быть следствием более ранней диагностики рака молочной железы у женщин, принимающих КОК, биологическим
          действием КОК или сочетанием обоих этих факторов.
        </P></LI>
        <LI><P>В редких случаях у женщин, принимавших КОК, наблюдалось развитие доброкачественных опухолей печени и еще
          реже — злокачественных. В отдельных случаях эти опухоли приводили к угрожающим жизни внутрибрюшным
          кровотечениям. При появлении интенсивной боли в верхней части живота, увеличения печени или симптомов
          внутрибрюшного кровотечения у женщин, принимающих КОК, необходимо исключить опухоль печени.
        </P></LI>
      </UL>

      <P><b style={{fontSize: 20}}>Гепатит С</b></P>
      <P>В ходе клинических исследований комбинации лекарственных препаратов для лечения вирусного гепатита С
        омбитасвир/паритапревир/ритонавир с дасабувиром или без него, увеличение активности АЛТ более чем в 5 раз от
        верхней границы нормы значительно чаще наблюдалось у женщин, применяющих лекарственные препараты, содержащие
        этинилэстрадиол, такие как КГК. У женщин, применяющих лекарственные препараты, содержащие эстрогены, отличные от
        этинилэстрадиола, такие как эстрадиол, скорость увеличения активности АЛТ была сходна с таковой у женщин, не
        применяющих каких-либо эстрогенов. Тем не менее, в связи с ограниченным числом женщин, принимающих другие
        эстрогены, отличные от этинилэстрадиола, необходимо соблюдать осторожность при совместном применении препарата с
        комбинацией препаратов омбитасвир/паритапревир/ритонавир с дасабувиром или без него (см. раздел
      </P>
      <P>«Взаимодействие с другими лекарственными средствами»).
      </P>
      <P><b style={{fontSize: 20}}>Другие состояния
      </b></P>

      <UL>
        <LI><P>У женщин с гипертриглицеридемией или соответствующим семейным анамнезом повышен риск развития панкреатита
          при приеме КОК.
        </P></LI>
        <LI><P>У многих женщин, получающих КОК, отмечалось небольшое повышение артериального давления, однако клинически
          значимое повышение артериального давления наблюдалось редко. Связь между приемом КОК и артериальной
          гипертензией не установлена. Однако если на фоне приема КОК развивается стойкая артериальная гипертензия, то
          целесообразно отменить прием КОК и назначить гипотензивную терапию. При адекватном контроле артериального
          давления с помощью гипотензивных препаратов возможно возобновление приема КОК. В клинических исследованиях
          длительностью до двух лет не было выявлено клинически значимых изменений артериального давления при применении
          препарата Зоэли®.
        </P></LI>
        <LI><P>На фоне беременности и во время применения КОК было отмечено развитие или ухудшение следующих состояний,
          хотя их связь с приемом контрацептивов окончательно не установлена: желтуха и/или зуд, связанные с холестазом;
          образование камней в желчном пузыре; порфирия; системная красная волчанка; гемолитико-уремический синдром;
          хорея Сиденгама (малая хорея); герпес во время беременности; потеря слуха, связанная с отосклерозом.
        </P></LI>
        <LI><P>У женщин с наследственными формами ангионевротического отека экзогенные эстрогены могут вызывать или
          ухудшать симптомы ангионевротического отека.
        </P></LI>
        <LI><P>При острых и хронических нарушениях функции печени может потребоваться отмена КОК до тех пор, пока не
          нормализуются показатели функции печени. При рецидиве холестатической желтухи, впервые наблюдавшейся во время
          беременности или при предыдущем применении половых гормонов, необходимо прекратить прием КОК.
        </P></LI>
        <LI><P>Несмотря на то, что КОК могут оказывать влияние на инсулинорезистентность и толерантность к глюкозе,
          необходимость в изменении режима дозирования гипогликемических препаратов у пациенток с сахарным диабетом,
          принимающих КОК, содержащие менее 0,05 мг этинилэстрадиола, отсутствует. Однако необходимо тщательно проводить
          периодические осмотры женщин с сахарным диабетом, принимающих КОК, особенно в течение первых месяцев. Препарат
          Зоэли® не оказывает влияния на инсулинорезистентность периферических тканей и толерантность к глюкозе у
          здоровых женщин (см. раздел «Фармакологические свойства», подраздел «Фармакодинамика»).
        </P></LI>
        <LI><P>Болезнь Крона, язвенный колит и ухудшение течения депрессии ассоциировались с приемом КОК.
        </P></LI>
        <LI><P>Иногда развивалась хлоазма, особенно у женщин с данным заболеванием в анамнезе. Женщинам,
          предрасположенным к развитию хлоазмы, следует избегать солнечного облучения или воздействия ультрафиолетового
          света во время приема КОК.
        </P></LI>
      </UL>

      <P><b style={{fontSize: 20}}>Медицинские обследования / консультации
      </b></P>

      <P>Перед назначением или возобновлением приема препарата Зоэли® следует тщательно ознакомиться с медицинским
        анамнезом (включая семейный) женщины и исключить беременность.
      </P>
      <P>Необходимо измерить артериальное давление и при наличии показаний провести физикальное обследование с учетом
        противопоказаний и предостережений. Интервал между контрольными медицинскими осмотрами определяется в каждом
        отдельном случае, но не реже чем 1 раз в 6 месяцев.
      </P>
      <P>Женщины должны быть информированы о том, что КОК не защищают от ВИЧ-инфекции (СПИДа) и других заболеваний,
        передающихся половым путем.
      </P>
      <P><b style={{fontSize: 20}}>Снижение эффективности</b>
      </P>
      <P>Эффективность КОК может снизиться в случае пропуска таблеток (см. раздел «Способ применения и дозы»),
        желудочно- кишечных расстройств во время приема таблеток, содержащих действующие вещества, (см. раздел «Способ
        применения и дозы») или в случае сопутствующей терапии, что снижает концентрацию номегэстрола ацетата в плазме
        крови (см. раздел «Взаимодействие с другими лекарственными средствами»).
      </P>
      <P><b style={{fontSize: 20}}>Изменения характера менструаций
      </b></P>
      <P>При приеме любых КОК могут наблюдаться «прорывные» кровотечения или «мажущие» выделения, особенно в первые
        несколько месяцев. Следовательно, проведение обследования при нерегулярных кровотечениях обосновано только после
        периода адаптации (примерно 3 цикла). У 15-20% женщин при применении препарата Зоэли® наблюдались ациклические
        кровянистые выделения после данного периода адаптации.
      </P>
      <P>Если нерегулярные кровотечения сохраняются или возникают после предыдущих регулярных циклов, необходимо
        предположить негормональные причины и провести диагностические исследования для исключения злокачественной
        опухоли или беременности. Может потребоваться диагностическое выскабливание.
      </P>
      <P>У женщин, принимавших препарат Зоэли®, продолжительность кровотечений «отмены» составила в среднем 3-4 дня.
      </P>
      <P>Некоторые женщины, принимавшие препарат Зоэли®, отмечали отсутствие кровотечения «отмены» во время приема
        желтых таблеток плацебо, хотя они не были беременны. При проведении клинических исследований отсутствие
        кровотечения «отмены» отмечалось в 18-32% случаев (на протяжении циклов с 1 по 12). В подобных случаях
        отсутствие кровотечения «отмены» не было связано с более высокой частотой «прорывных» кровотечений / «мажущих»
        выделений в последующих циклах. У 4,6% женщин наблюдалось отсутствие кровотечения «отмены» в каждом из первых
        трех циклов применения препарата. В данной подгруппе был высокий процент женщин с отсутствием кровотечения
        «отмены» в последующих циклах (76-87%). Из 28% женщин, у которых кровотечение «отмены» отсутствовало по крайней
        мере в одном цикле (на протяжении циклов 2, 3 или 4), у 51-62% пациенток также наблюдалось отсутствие
        кровотечения «отмены» в последующих циклах.
      </P>
      <P>Если при приеме препарата Зоэли® в соответствии с рекомендациями, описанными в разделе «Способ применения и
        дозы», отсутствует кровотечение «отмены», то вероятность беременности низкая. Однако если женщина не принимала
        препарат в соответствии с рекомендациями или если отсутствуют два кровотечения «отмены» подряд, то необходимо
        исключить беременность.
      </P>

      <H2 id={'managment-transport'}>
        ВЛИЯНИЕ НА СПОСОБНОСТЬ УПРАВЛЯТЬ ТРАНСПОРТНЫМИ СРЕДСТВАМИ И РАБОТАТЬ С МЕХАНИЗМАМИ
      </H2>

      <P>Препарат Зоэли® не оказывает влияния на способность управлять транспортными средствами и работать с
        механизмами.</P>

      <H2 id={'form-production'}>Форма выпуска</H2>
      <P>Таблетки покрытые пленочной оболочкой.
      </P>
      <P>По 28 таблеток (24 белые таблетки, содержащие действующие вещества, и 4 желтые таблетки плацебо) в блистере из
        ПВХ / алюминиевой фольги. По 1 или 3 блистера вместе с конвертом для блистера (или без него), стикером с днями
        недели и инструкцией по применению помещают в картонную пачку.
      </P>

      <H2 id={'keeper'}>Условия хранения</H2>
      <P>При температуре от 2 до 30°С. Хранить в недоступном для детей месте.</P>

      <H2 id={'deadline'}>Срок годности</H2>
      <P>3 года. Не применять по истечении срока годности.</P>

      <H2 id={'holiday'}>Условия отпуска</H2>
      <P>По рецепту.</P>

      <H2 id={'owner'}>ЮРИДИЧЕСКОЕ ЛИЦО, НА ИМЯ КОТОРОГО ВЫДАНО РЕГИСТРАЦИОННОЕ УДОСТОВЕРЕНИЕ</H2>
      <P>Н.В. Органон, Нидерланды</P>

      <H2 id={'producer'}>Производитель</H2>

      <P><b style={{fontSize: 20}}>Произведено / Выпускающий контроль качества</b></P>
      <P>Органон (Ирландия) Лтд., Ирландия</P>
      <P>Organon (Ireland) Ltd., Drynam Road, Swords, Co. Dublin, Ireland</P>
      <P>или</P>
      <P><b style={{fontSize: 20}}>Произведено:</b></P>
      <P>Дельфарм Лилль С.А.С., Франция</P>
      <P>Delpharm Lille S.A.S., Zone Industrielle de Roubaix Est, Rue de Toufflers, 29390 Lys Lez Lannoy, France</P>
      <P><b style={{fontSize: 20}}>Выпускающий контроль качества:</b></P>
      <P>Мерк Шарп и Доум Б.В., Нидерланды</P>
      <P>Merck Sharp & Dohme B.V., Waarderweg 39, 2031 BN, Haarlem, the Netherlands
      </P>

      <H2 id={'report'}>ПРЕТЕНЗИИ ПОТРЕБИТЕЛЕЙ НАПРАВЛЯТЬ ПО АДРЕСУ:</H2>
      <P>ООО «МСД Фармасьютикалс»</P>
      <P>ул. Павловская, д. 7, стр. 1, г. Москва, Россия, 115093</P>
      <P>тел.: (495) 916-71-00, факс: (495) 916-70-94</P>
      <P>Специалист отдела по работе с регуляторными органами ООО «МСД Фармасьютикалс»</P>

      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Инструкция PDF
      </a>
    </Page>
  )
}

export default Instructions;
