import {styled} from '@mui/system';
import {Box} from '@mui/material';

export const StyledWrapperBox = styled(Box)`
  font-size: 18px;

  & .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1 1 0;
  }
`