import Page from 'shared/ui/Page';
import {PinkLight} from 'styles/color';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';

export const MicroginonLinkOne = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>
        Newer oral contraceptives and the risk of venous thromboembolism
      </H1>
      <P>
        Research on the relationship between venous thromboembolism and the progestagen content of combined oral
        contraceptives has pointed to an increase in risk associated with products containing desogestrel and gestodene.
        Although many biases must have been at play in these nonexperimental studies, the errors that have been
        suggested and examined are not of a sufficient magnitude to account for the observed results. The most plausible
        explanation of the available data is that combined oral contraceptives containing desogestrel and gestodene
        carry a very small risk of venous thromboembolism, which exceeds the even smaller risk carried by products
        containing levonorgestrel. The position of norgestimate is uncertain.
      </P>
      <P>
        PIP: In October 1995, the UK Committee on the Safety of Medicines announced, in response to still unpublished
        studies, that third-generation oral contraceptives (OCs) carried an elevated risk of venous thromboembolism. The
        research motivating this announcement was subsequently published and criticized widely for its methodology.
        These critiques have focused on the possibility of selective prescribing of third-generation OCs to high-risk
        women, preferential diagnosis of venous thromboembolism in mildly symptomatic users of these OCs, and a tendency
        of high-risk women to shift from older to newer products. This article reviews the available data (World Health
        Organization Collaborative Study, General Practice Research Data Base, Leiden Thrombophilia Study, and the
        Transnational Study), evaluates competing explanations, and offers a summary interpretation. Overall, the review
        suggests that the errors identified are not of sufficient magnitude to account for the observed results. The
        preponderance of scientific evidence supports the proposition that desogestrel and gestodene do, in fact, carry
        a very small risk of venous thromboembolism that exceeds that associated with levonorgestrel.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/9617533/">Walker AM. Newer oral contraceptives and the risk of
        venous thromboembolism. Contraception. 1998;57(3):169–81</A>]
      </P>

      <H1 sx={{color: PinkLight}}>
        Risk of venous thromboembolism from oral contraceptives containing gestodene and desogestrel versus
        levonorgestrel: a meta-analysis and formal sensitivity analysis
      </H1>
      <P>
        Controversy exists regarding whether oral contraceptives (OCs) containing desogestrel and gestodene are
        associated with an increased risk of venous thromboembolism (VTE) versus OCs containing levonorgestrel. We were
        interested in synthesizing the available data, exploring explanations for mixed results, and characterizing the
        degree of uncontrolled confounding that could have produced a spurious association. We performed a meta-analysis
        and formal sensitivity analysis of studies that examined the relative risk of VTE for desogestrel and gestodene
        versus levonorgestrel. Twelve studies, all observational, were included. The summary relative risk (95% CI) was
        1.7 (1.3-2.1; heterogeneity p = 0.09). If real, the incremental risk of VTE would be about 11 per 100,000 women
        per year. An association was present when accounting for duration of use and when restricted to the first year
        of use in new users. However, in the sensitivity analysis, the association abated in many, but not all,
        scenarios in which an unmeasured confounding factor increased the risk of VTE three to fivefold and in nearly
        all examined scenarios in which the factor increased the risk 10-fold. The summary relative risk of 1.7 does not
        appear to be caused by depletion of susceptibles, but is sensitive to a modest degree of unmeasured confounding.
        Whether such confounding occurred is unknown. However, given this sensitivity, this issue probably cannot be
        settled unequivocally with observational data. In the absence of a definitive answer, this apparent increased
        risk, together with its uncertainty and small magnitude and its important consequences, should be considered
        when selecting an OC for a given woman.

      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/11704089/">Hennessy S, Berlin JA, Kinman JL, Margolis DJ,
        Marcus SM, Strom BL. Risk of venous thromboembolism from oral contraceptives containing gestodene and
        desogestrel versus levonorgestrel: a meta-analysis and formal sensitivity analysis. Contraception.
        2001;64(2):125–33</A>]
      </P>

      <H1 sx={{color: PinkLight}}>
        Third generation oral contraceptives and risk of venous thrombosis: meta-analysis
      </H1>
      <P>
        <b>Objective:</b> To evaluate quantitatively articles that compared effects of second and third generation oral
        contraceptives on risk of venous thrombosis.
      </P>
      <P><b>Design:</b> Meta-analysis.
      </P>
      <P><b>Studies:</b> Cohort and case-control studies assessing risk of venous thromboembolism among women using oral
        contraceptives before October 1995.
      </P>
      <P>Main outcome measures: Pooled adjusted odds ratios calculated by a general variance based random effects
        method. When possible, two by two tables were extracted and combined by the Mantel-Haenszel method.
      </P>
      <P><b>Results:</b> The overall adjusted odds ratio for third versus second generation oral contraceptives was 1.7 (95%
        confidence interval 1.4 to 2.0; seven studies). Similar risks were found when oral contraceptives containing
        desogestrel or gestodene were compared with those containing levonorgestrel. Among first time users, the odds
        ratio for third versus second generation preparations was 3.1 (2.0 to 4.6; four studies). The odds ratio was 2.5
        (1.6 to 4.1; five studies) for short term users compared with 2.0 (1.4 to 2.7; five studies) for longer term
        users. The odds ratio was 1.3 (1.0 to 1.7) in studies funded by the pharmaceutical industry and 2.3 (1.7 to 3.2)
        in other studies. Differences in age and certainty of diagnosis of venous thrombosis did not affect the results.
      </P>
      <P>Conclusions: This meta-analysis supports the view that third generation oral contraceptives are associated with
        an increased risk of venous thrombosis compared with second generation oral contraceptives. The increase cannot
        be explained by several potential biases.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/11463678/">Kemmeren JM, Algra A, Grobbee DE. Third generation oral contraceptives and risk of venous
        thrombosis: meta-analysis. BMJ. 2001;323(7305):131–4</A>]
      </P>
    </Page>
  )
}
