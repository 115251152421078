export const PinkLight = '#F9D2D6';
export const Pink = '#E482AE';
export const Black = '#2F2F34';
export const Violet = '#5F216B';
export const Violet2 = '#844BC5';
export const VioletLight = '#9E1F63';
export const Blue = '#29ABE2';
export const BlueDark = '#0076BD';
export const Green = '#00A083';
export const GreenLight = '#92D050';
export const Yellow = '#FFEA8F';
export const YellowDark = '#B6A91C';
export const Red = '#D00000';
export const RedLight = '#FF3146';
export const Gray = 'rgba(249, 210, 214, 0.25)';
export const GrayBlack = 'rgba(47, 47, 52, 0.6)';
export const OpacityRed = 'rgba(208, 0, 0, 0.7)';
export const GrayLight = 'rgb(181, 172, 172, 0.15)';