import {Box} from '@mui/material';

import {StyledAnchorLink, StyledHeader} from '../ui';
import {StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {HeaderTabs as Tabs} from './header-tabs';
import {Message} from './message';

type HeaderTabsProps = {
  tabActive: number
}
const HeaderTabs = ({tabActive}: HeaderTabsProps) => <Tabs tabActive={tabActive} tabLength={2}/>
export const SubpageZoely = () => {
  return (
    <>
      <StyledHeader>Зоэли®</StyledHeader>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <StyledAnchorLink href="#theme1">
          <img src={icons.arrow} alt="*"/>
          Сценарий #1: Зоэли vs Клайра
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme2">
          <img src={icons.arrow} alt="*"/>
          Сценарий #2: Зоэли vs Эстеретта
        </StyledAnchorLink>
      </Box>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <HeaderTabs tabActive={0}/>
        <Box className="title" sx={{mb: 3}}>
          Зоэли vs Клайра
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Мария Ивановна, добрый день! Медицинский представитель компании Зентива (ФИО). Уделите мне несколько минут,
            для обсуждения пациенток, которые пока не готовы стать мамой.
          </Message>
          <Message isQuestion={false}>
            Заходите
          </Message>

          <Message>
            Спасибо, Ольга Ивановна!
          </Message>
          <Message isQuestion={false}>
            Сейчас эта тема более чем актуальна, одни в 18 еще не готовы быть мамой, другие в 35 уже не готовы быть
            мамой
          </Message>

          <Message>
            То есть, пациентки просят у Вас назначить им контрацептив?
          </Message>
          <Message isQuestion={false}>
            Да, и проблема в том что возраст разный у этих пациенток и постоянно приходится думать кому что назначить.
            Как правило, одним назначаю Джес, другим Клайру.
          </Message>

          <Message>
            То есть тем кто помоложе Джес, тем кто старше Клайру я правильно Вас понимаю?
          </Message>
          <Message isQuestion={false}>
            Да, эти КОКи давно себя зарекомендовали, опыт назначения положительный.
          </Message>

          <Message>
            Хочу Вам рассказать про Зоэли, современный КОК, который не требует назначения по возрасту.
          </Message>
          <Message isQuestion={false}>
            Я слышала о нем и даже видела назначения от других коллег, но как-то сама не назначаю.
          </Message>

          <Message>
            Мария Ивановна, правильно ли я Вас понимаю, что Зоэли вы не назначаете из-за недостатка информации?
          </Message>
          <Message isQuestion={false}>
            Да.
          </Message>

          <Message>
            Хочу Вам сказать, что при назначении Зоэли Вам не придется думать о возрасте пациентки, т.к. Зоэли одинаково
            подходит как более молодым так и пациенткам постарше. Например, в многоцентровом исследовании BOLERO
            принимали участие женщины в возрасте от 18-50 лет, исследования проводились в 17 центрах Италии и более 89%
            женщин были удовлетворены его применением, согласитесь это более чем хороший результат?
          </Message>
          <Message isQuestion={false}>
            Да, я согласна с Вами, однако у меня уже многолетняя хорошая практика назначений Джес и Клайры.
          </Message>

          <Message>
            Мария Ивановна, согласна с Вами, что браться за что-то новое всегда сложно, но как Вы сами сказали,
            назначения Зоэли Вы видели от других коллег, более того, Ваша зав. консультации имеет положительный опыт
            назначения Зоэли, я только от нее, она говорит, что Зоэли это не просто современный европейский КОК, но и
            КОК в эффективности которого я не сомневаюсь.
            <Box sx={{mt: '12px', mb: '12px'}}>
              Кроме того, Зоэли® имеет целый ряд неоспоримых преимуществ перед Клайрой за счёт уникальной комбинации
              NOMAC
              и натурального эстрадиола в монофазном режиме, что делает назначение Зоэли более обоснованным с точки
              зрения
              преимуществ для пациентки.
            </Box>
            Зоэли содержит активный компонент, которому не требуется преобразование в организме с помощью ферментов.
            <Box sx={{mt: '12px', mb: '12px'}}>
              Имеет самый большой период полувыведения среди КОК и монофазный режим приема, поэтому удобен в случае
              пропущенной таблетки.
            </Box>
            Не повышает риск венозной и артериальной тромбоэмболии и имеет благоприятный профиль безопасности,
            подтвержденный исследованиями.
            <Box sx={{mt: '12px'}}>
              <b>
                Таким образом, назначая Зоэли, вы обеспечиваете Ваших пациенток максимально физиологичной, надежной и
                качественной контрацепцией класса Luxe.
              </b>
            </Box>
          </Message>
          <Message isQuestion={false}>
            Возможно стоит попробовать и начать назначать Зоэли.
          </Message>

          <Message>
            Мария Ивановна, я предлагаю Вам назначить Зоэли 6-8 пациенткам, для того чтобы у Вас сложилась клиническая
            картина и сразу масса положительных отзывов о его применении, как Вы думаете в течение месяца придет ли к
            Вам столько пациенток с запросом на КОК?
          </Message>
          <Message isQuestion={false}>
            Я думаю даже больше. У меня примерно 3 пациентки в неделю таких.
          </Message>

          <Message>
            В таком случае предлагаю Вам назначить Зоэли всем пациенткам с запросом на КОК, а спустя месяц я думаю более
            80% пациенток Вам дадут обратную связь, в ходе которой у вас уже сложится клинический опыт, которым вы
            поделитесь со мной, как Вам мое предложение?
          </Message>
          <Message isQuestion={false}>
            Давайте попробуем, почему бы и нет, вот Вам мой номер телефона, лучше если Вы со мной спустя месяц
            предварительно договоритесь о встречи, сами понимаете, во время приема не совсем удобно.
          </Message>

          <Message>
            Конечно, Мария Ивановна, огромное спасибо Вам, я с уважением и пониманием отношусь к Вашему времени, поэтому
            держим связь, уверена, что результаты назначений Зоэли будут положительными, кстати вот по этому QR-коду все
            Ваши пациентки получат скидку и приобретут Зоэли по более выгодной цене.
          </Message>
          <Message isQuestion={false}>
            Спасибо, в наше время цена имеет огромное значение.
          </Message>

          <Message>
            Благодарю Вас за уделенное время, до новых встреч!
          </Message>
          <Message isQuestion={false}>
            Большое спасибо, до свидания!
          </Message>
        </Box>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme2" sx={{mt: 5}}>
        <HeaderTabs tabActive={1}/>
        <Box className="title" sx={{mb: 3}}>
          Зоэли vs Эстеретта
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, здравствуйте! Медицинский представитель компании Зентива (имя). Уделите мне несколько минут?
          </Message>
          <Message isQuestion={false}>
            Здравствуйте! Да, я пока свободна.
          </Message>

          <Message>
            Спасибо! Ольга Ивановна, на прошлом визите мы с Вами обсуждали наш инновационный КОК Зоэли- первый
            монофазный КОК, содержащий аналог натурального эстрадиола и назначение его женщинам, которым важна
            максимально физиологичная и надежная контрацепция.
          </Message>
          <Message isQuestion={false}>
            Да, я помню.
          </Message>

          <Message>
            Удалось назначить препарат?
          </Message>
          <Message isQuestion={false}>
            Да, назначила нескольким женщинам, но недавно была на Круглом столе у другой компании, там рассказывали про
            Эстеретту, очень понравился доклад, решила в этом месяце его поназначать.
          </Message>

          <Message>
            Ольга Ивановна, отлично, что вы посещаете мероприятия и любите учиться! Да, на рынке существует КОК
            Эстеретта, но, к сожалению, он не такой безобидный, как Вам могло показаться.
          </Message>
          <Message isQuestion={false}>
            А что с ним не так? Я назначаю только безопасные препараты! Мне зачем лишние проблемы?
          </Message>

          <Message>
            Ольга Ивановна, давайте разберемся: во первых, Эстеретта содержит в своем составе эстетрол- это
            генотоксичный ксенобиотик, он не вырабатывается естественным путем в организме женщины, это чужеродный
            метаболит и его физиологическая роль до сих пор неизвестна. Зато известно, что ксенобиотики вызывают
            генетические изменения, мутации, провоцируют злокачественные опухоли, нарушают обмен веществ. Разве такой
            препарат можно назвать безопасным? И разве можно сравнивать его с Зоэли, где в составе натуральный эстрадиол
            и номегестрол, которые являются физиологичными для организма?
          </Message>
          <Message isQuestion={false}>
            Я и не знала, что эстетрол такой опасный! Он же вырабатывается во время беременности, думала, что он
            безопасен.
          </Message>

          <Message>
            И еще: дозировка эстетрола в 1 таб 15 мг ! А ежедневная безопасная доза для женщины составляет 0,15 мг.
            Учитывая, что его биодоступность примерно 80%, всасывается 11 мг- то есть в 1 таблетке доза эстетрона в 76
            раз превышает допустимую. Теперь представьте какое воздействие оказывается на организм женщины, ведь, как
            правило, женщины пьют контрацептивы годами.
          </Message>
          <Message isQuestion={false}>
            Спасибо за информацию, я еще почитаю про эстетрол, назначения пока начинать не буду.
          </Message>

          <Message>
            Ольга Ивановна, зачем менять Зоэли на неизвестный препарат, ведь он максимально физиологичен, надежен -
            напомню, что период полувыведения составляет 46 часов, индекс Перля самый низкий среди всех КОК. Так же
            Зоэли имеет крупнейшее исследование в мире, которое когда либо проводилось на КОКах (PROE2), где он показал
            свою эффективность и безопасность. Женщина может принимать контрацептив длительно, не опасаясь влияния на
            другие системы организма. И Вы будете уверены, что назначаете безопасную качественную современную
            контрацепцию! Вы согласны со мной?
          </Message>
          <Message isQuestion={false}>
            Абсолютно!
          </Message>

          <Message>
            Спасибо, Ольга Ивановна, приду к Вам через месяц, надеюсь порадуете новыми назначениями. Вы говорили,
            примерно 2-3 пациентки в неделю приходят за подбором контрацепции?
          </Message>
          <Message isQuestion={false}>
            Да, не больше.
          </Message>

          <Message>
            До свидания! Хорошего дня!
          </Message>
        </Box>
      </StyledBoxShadow>
    </>
  )
}
