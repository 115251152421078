import {styled} from '@mui/system';
import {Box} from '@mui/material';
import {Black, PinkLight} from '../../../../styles/color';

export const StyledTab = styled(Box)({
  backgroundColor: PinkLight,
  color: Black,
  fontSize: 20,
  lineHeight: '24px',
  borderRadius: 60,
  padding: '13px 30px',
  fontFamily: 'SF Pro Text',
  boxSizing: 'border-box',
  border: '1px solid #F7E5E0',
  boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',

  '&.active': {
    backgroundColor: Black,
    color: PinkLight,
  },

  '&:hover': {
    backgroundColor: Black,
    color: PinkLight,
  },
})
