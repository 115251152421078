import main from './main.png';
import plus from './plus.png';
import blonde from './blonde.png';
import approveLarge from './approve-large.png';
import balance from './balance.png';
import chartOne from './chart1.png';
import chartThree from './chart3.png';
import chartTwo from './chart2.png';
import ellipse from './ellipse.png';
import globe from './globe.png';
import linkBlack from './link-black.png';
import linkBlue from './link-blue.png';
import warn from './warn.png';
import plusLarge from './plus-large.png';
import plusMedium from './plus-medium.png';
import blondeTwo from './blonde2.png';
import blondeThree from './blonde3.png';
import blondeFour from './blonde4.png';
import product from './product.png';
import clamp from './clamp.png';

export default {
  main,
  plus,
  blonde,
  approveLarge,
  balance,
  chartOne,
  chartThree,
  chartTwo,
  ellipse,
  globe,
  linkBlack,
  linkBlue,
  warn,
  plusLarge,
  plusMedium,
  blondeTwo,
  blondeThree,
  blondeFour,
  product,
  clamp,
}