import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A, StyledH1 as H1} from './ui';

export const ProginovaSecondLinkThree = () => {
  return (
    <Page isCross>
      <H1>Risk factors for endometrial cancer among women with a BRCA1 or BRCA2 mutation: a case control study</H1>
      <P>
        BRCA mutation carriers may use tamoxifen for breast cancer prevention or treatment. Hormone replacement therapy
        is often prescribed after surgical menopause and oral contraceptives are recommended for ovarian cancer
        prevention. The objective of this study was to assess the impact of these medications and other risk factors on
        endometrial cancer risk in BRCA carriers. Women with a BRCA1 or BRCA2 mutation were identified from a registry
        of mutation carriers. Cases were 83 women who had a diagnosis of endometrial cancer. Controls were 1027 matched
        women who did not develop endometrial cancer and who had an intact uterus. All women completed a baseline
        questionnaire, which included questions about ages at menarche and menopause, oral contraceptive use, hormone
        replacement therapy use, hysterectomy, oophorectomy, breast cancer history and tamoxifen use. We estimated the
        odds ratio associated with each risk factor in a multivariate analysis. No differences were found between cases
        and controls in terms of age at menarche, BMI, smoking, or oral contraceptive use. In a multivariate analysis,
        for women taking estrogen-only hormone replacement therapy, the odds ratio was 0.23 (95% CI 0.03-1.78, p =
        0.16), and for women taking progesterone-only hormone replacement therapy the odds ratio was 6.91 (95% CI
        0.99-98.1, p = 0.05). The adjusted odds ratio for endometrial cancer associated with a history of tamoxifen use
        was 3.50 (95% CI 1.51-8.10, p = 0.003). The observed increased risk of endometrial cancer associated with
        progesterone-only therapy merits further study.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/25838159/">Segev Y et al. Risk factors for endometrial cancer among women with a BRCA1 or BRCA2
        mutation: a case control study. Fam Cancer. 2015 Sep;14(3):383-91</A>]
      </P>
    </Page>
  )
}
