import LinkContainer from 'shared/ui/LinkContainer';
import Page from 'shared/ui/Page';

export default () => {
  return (
    <Page isCross>
      <div
        style={{
          fontSize: 36,
          fontWeight: 600,
          color: '#F9D2D6',
          marginBottom: 20,
        }}
      >
        NOMAC — Номегэстрола ацетат
      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
          color: '#fff',
          lineHeight: '22px',
        }}
      >
        Номегэстрола ацетат является высокоселективным прогестагеном, производным естественного стероидного гормона -
        прогестерона. Номегэстрола ацетат имеет выраженное сродство (аффинитет) к человеческому рецептору прогестерона и
        обладает антигонадотопной активностью, опосредованной прогестероновыми рецепторами анти-эстрогенной активностью,
        умеренной антиандрогенной активностью, и при этом лишен какой-либо эстрогенной, андрогенной, глюкокортикоидной
        или минералокортикоидной активности.
      </div>
      <LinkContainer
        text="Schindler A. Maturitas. 2008;61: 171-180"
        link={'https://pubmed.ncbi.nlm.nih.gov/19434889/'}
      />
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
          color: '#fff',
          lineHeight: '22px',
        }}
      >
        Гонадотропины представляют собой гормоны, выделяемые передней долей гипофиза и плацентой. Они стимулируют
        половые железы и контролируют репродуктивную функцию. Гонадотропные гормоны включают ФСГ и ЛГ. Уровни ЛГ и ФСГ,
        как правило, повышаются в середине менструального цикла и «запускают» процесс овуляции. В исследованиях,
        проведенных с участием женщин, получающих ацетат номегэстрола, повышение уровней ЛГ и ФСГ в середине цикла было
        подавлено во всех случаях, если женщины получали по 1,25 мг; 2,5 мг или 5 мг ацетата номегэстрола ежедневно в
        течение 21 дня (n = 13). В течение цикла уровни эстрогена и прогестерона обычно повышаются. В этих же
        исследованиях установлено, что введение 5 мг номегэстрола ацетата в течение 21 дня приводит к подавлению уровней
        [эндогенных] 17β-эстрадиола и прогестерона на протяжении всего цикла. По этой причине ни у одной женщины,
        участвовавшей в этих исследованиях, во время введения номегэстрола ацетата не было зарегистрировано овуляции.
      </div>
      <div style={{color: 'white'}}>
        [ссылки: <br/>
        <br/>
        1. Bazin B et al. Br J Obstet Gynecol. 1987;94:1199-1204. <br/>
        2. Couzinet B et al. J Clin Endocrin Metab. 1999;84:4191-4196 <br/>
        3. Chretien FC et al. Contraception. 1991;43:55-65]
      </div>
    </Page>
  );
};
