import {useHistory} from 'react-router-dom';

import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import {WrapperCouple} from 'components/wrapper-couple';
import {Div, H2, Img, Paragraph as P, List as UL, ListItem as LI, Sup, Span,} from 'shared/ui';
import Page from 'shared/ui/Page';
import {PinkLight} from 'styles/color';

import {AnchorLink, LinkA as A, MiniBox} from './ui';
import * as images from './img';

export const ZoelyMissEffects = () => {
  const history = useHistory();

  const handleToBack = () => {
    history.push('/product/eoeli#survey');
  }

  return (
    <Page onClose={handleToBack} isCross>
      <Div sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
        <H2 sx={{
          fontSize: 36,
          color: '#FFFFFF',
          fontFamily: 'SF-Pro-Text-Regular',
          textAlign: 'center',
          mt: 0,
          mb: 1
        }}>MISS-ЭФФЕКТЫ</H2>
        <P sx={{fontSize: 28, textAlign: 'center', m: 0, lineHeight: 0}}>membrane-initiated steroid signaling</P>
        <P sx={{textAlign: 'center', mb: 3}}>(эффекты, инициированные мембранной передачей сигналов стероидов)</P>
        <WrapperShell sx={{pt: 4, pb: 4}}>
          <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, p: 5, pb: 3}}>
            <Div sx={{display: 'flex', flexWrap: 'wrap'}}>
              <Div>
                <Img src={images.chart} width={320} sx={{ml: 2}}/>
              </Div>
              <Div sx={{position: 'relative', mt: 2, ml: 9}}>
                <Div sx={{display: 'flex'}}>
                  <Img src={images.ellipseViolet}/>
                  <P sx={{ml: 2}}>Мембранная фракция ERα</P>
                  <MiniBox sx={{ml: 53, mt: -2, position: 'absolute'}}>
                    <P sx={{fontSize: 36}}>E2</P>
                  </MiniBox>
                </Div>
                <Div sx={{display: 'flex', mt: 5}}>
                  <Img src={images.ellipsePink}/>
                  <P sx={{ml: 2}}>Ядерная фракция ERα</P>
                  <MiniBox sx={{ml: 53, mt: -2, position: 'absolute'}}>
                    <P sx={{fontSize: 36}}>E4</P>
                  </MiniBox>
                </Div>
                <Img src={images.arrows} sx={{position: 'absolute', top: 5, left: 286}}/>
                <UL sx={{maxWidth: 450, mt: 5}}>
                  <LI>
                    <P>
                      E4 противодействует E2-опосредованным MISS-&nbsp;
                      <AnchorLink href="#group-one-2">эффектам<Sup>2,3</Sup></AnchorLink>
                    </P>
                  </LI>
                  <LI sx={{mt: 2}}>
                    <P>
                      Хотя мембраносвязанная фракция ERα составляет приблизительно 5-10% в зависимости от типа клетки —
                      тем не менее, стимуляция мембранной фракции ERα отвечает за полезные эффекты в различных&nbsp;
                      <AnchorLink href="#group-one-1">органах<Sup>1,6</Sup></AnchorLink>
                    </P>
                  </LI>
                </UL>
              </Div>
            </Div>
          </WrapperShellBox>
          <H2 sx={{color: PinkLight, mt: 6}}>MISS-эффекты 17β-эстрадиола (Е2) в различных органах:</H2>
          <WrapperCouple sx={{mt: 4}}>
            <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <Img src={images.iconOne}/>
              <UL sx={{display: 'flex', flexDirection: 'column', gap: 2, mb: 0}}>
                <LI>
                  <P>
                    сердечно-сосудистая&nbsp;
                    <AnchorLink href="#group-one-4">протекция<Sup>4</Sup></AnchorLink>
                  </P>
                </LI>
                <LI>
                  <P>
                    предотвращение острого повреждения&nbsp;
                    <AnchorLink href="#group-one-10">сосудов<Sup>10</Sup></AnchorLink>
                  </P>
                </LI>
                <LI>
                  <P>
                    быстрое расширение&nbsp;
                    <AnchorLink href="#group-one-1">сосудов<Sup>1</Sup></AnchorLink>
                  </P>
                </LI>
                <LI>
                  <P>
                    ускорение восстановления&nbsp;
                    <AnchorLink href="#group-one-1">эндотелия<Sup>1</Sup></AnchorLink>
                  </P>
                </LI>
                <LI>
                  <P>
                    производство эндотелиальных&nbsp;
                    <AnchorLink href="#group-one-1">NO<Sup>1</Sup></AnchorLink>
                  </P>
                </LI>
              </UL>
            </WrapperShell>
            <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <Img src={images.iconTwo}/>
              <P>
                нейропротекторные эффекты, в том числе в отношении нейронов головного &nbsp;
                <AnchorLink href="#group-one-2">мозга<Sup>2,4,5,6</Sup></AnchorLink>
              </P>
            </WrapperShell>
          </WrapperCouple>

          <WrapperCouple sx={{mt: '20px'}}>
            <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <Img src={images.iconTree}/>
              <UL sx={{display: 'flex', flexDirection: 'column', gap: 2, mb: 0}}>
                <LI>
                  <P>
                    способствует выживанию островков поджелудочной железы от апоптотических&nbsp;
                    <AnchorLink href="#group-one-2">стрессов<Sup>2,12</Sup></AnchorLink>
                  </P>
                </LI>
                <LI>
                  <P>
                    предотвращает избыток островкового&nbsp;
                    <AnchorLink href="#group-one-2">липогенеза<Sup>2,13,15</Sup></AnchorLink>
                  </P>
                </LI>
                <LI>
                  <P>
                    стимулирует синтез&nbsp;
                    <AnchorLink href="#group-one-2">инсулина2<Sup>2, 14</Sup></AnchorLink>
                  </P>
                </LI>
              </UL>
            </WrapperShell>
            <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <Img src={images.iconFour}/>
              <UL sx={{display: 'flex', flexDirection: 'column', gap: 2, mb: 0}}>
                <LI>
                  <P>
                    предотвращение&nbsp;
                    <AnchorLink href="#group-one-9">остеопороза<Sup>9</Sup></AnchorLink>
                  </P>
                </LI>
                <LI>
                  <P>
                    предотвращение кортикальной потери&nbsp;
                    <AnchorLink href="#group-one-4">кости<Sup>4</Sup></AnchorLink>
                  </P>
                </LI>
              </UL>
            </WrapperShell>
          </WrapperCouple>

          <WrapperCouple sx={{mt: '20px'}}>
            <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <Img src={images.iconFive}/>
              <P>
                подавление синтеза липидов в печени, потенциально влияет на гомеостаз холестерина и связанными с
                ним&nbsp;
                <AnchorLink href="#group-one-11">расстройства<Sup>11</Sup></AnchorLink>
              </P>
            </WrapperShell>
            <WrapperShell sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
              <Img src={images.iconSix}/>
              <UL sx={{display: 'flex', flexDirection: 'column', gap: 2, mb: 0}}>
                <LI>
                  <P>
                    сохранение репродуктивных&nbsp;
                    <AnchorLink href="#group-one-7">функций<Sup>7,8</Sup></AnchorLink>
                  </P>
                </LI>
                <LI>
                  <P>
                    Анализ мышиных моделей показал, что отсутствие мембран-инициированной передачи сигналов ERα приводит
                    к бесплодию, связанному с нарушениями функции яичников и нарушением уровня половых&nbsp;
                    <AnchorLink href="#group-one-1">стероидов<Sup>1,4</Sup></AnchorLink>
                  </P>
                </LI>
              </UL>
            </WrapperShell>
          </WrapperCouple>

          <Div sx={{display: 'flex', flexDirection: 'column', justifyContent: 'left', gap: 1, mt: 4, mb: 2}}>
            <A
              id="group-one-1"
              href="https://pubmed.ncbi.nlm.nih.gov/24371309/">
              <Span>1.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Adlanmerini M, et al. Mutation of the palmitoylation site of estrogen receptor α in vivo reveals
                tissue-specific roles for membrane versus nuclear actions. Proc Natl Acad Sci USA. 2014;111:E283–E290
              </Span>
            </A>
            <A
              id="group-one-2"
              href="https://pubmed.ncbi.nlm.nih.gov/30305367/">
              <Span>2.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Allard C, et al. s F. Loss of Nuclear and Membrane Estrogen Receptor-α Differentially Impairs Insulin
                Secretion and Action in Male and Female Mice. Diabetes. 2019 Mar;68(3):490-501.
              </Span>
            </A>
            <A
              id="group-one-3"
              href="https://pubmed.ncbi.nlm.nih.gov/36130859/">
              <Span>3.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Douxfils J, et al. Estetrol is not a SERM but a NEST and has a specific safety profile on coagulation.
                Thromb Res. 2022 Sep 16:S0049-3848(22)00387-5.
              </Span>
            </A>
            <A
              id="group-one-4"
              href="https://pubmed.ncbi.nlm.nih.gov/27388455/">
              <Span>4.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Gustafsson, K., et al. The role of membrane ERα signaling in bone and other major estrogen responsive
                tissues. Sci Rep 6, 29473 (2016).
              </Span>
            </A>
            <A
              id="group-one-5"
              href="https://pubmed.ncbi.nlm.nih.gov/18538919/">
              <Span>5.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Kelly MJ, Rønnekleiv OK. Membrane-initiated estrogen signaling in hypothalamic neurons. Mol Cell
                Endocrinol. 2008 Aug 13;290(1-2):14-23.
              </Span>
            </A>
            <A
              id="group-one-6"
              href="https://pubmed.ncbi.nlm.nih.gov/24008343/">
              <Span>6.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Meitzen J, Luoma JI, Boulware MI, et al.. Palmitoylation of estrogen receptors is essential for neuronal
                membrane signaling. Endocrinology 2013;154:4293–4304.
              </Span>
            </A>
            <A
              id="group-one-7"
              href="https://pubmed.ncbi.nlm.nih.gov/24871949/">
              <Span>7.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Pedram A, et al. Membrane-localized estrogen receptor α is required for normal organ development and
                function. Dev Cell 2014;29:482–490.
              </Span>
            </A>
            <A
              id="group-one-8"
              href="https://pubmed.ncbi.nlm.nih.gov/27145009/">
              <Span>8.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Nanjappa MK, et al. Membrane-localized estrogen receptor 1 is required for normal male reproductive
                development and function in mice. Endocrinology 2016;157:2909–2919.
              </Span>
            </A>
            <A
              id="group-one-9"
              href="https://pubmed.ncbi.nlm.nih.gov/12399595/">
              <Span>9.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Kousteni S, et al. (2002) Reversal of bone loss in mice by nongenotropic signaling of sex steroids.
                Science 298:843–846.
              </Span>
            </A>
            <A
              id="group-one-10"
              href="https://www.jci.org/articles/view/38291/pdf">
              <Span>10.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Chambliss KL, et al. (2010) Non-nuclear estrogen receptor a signaling promotes cardiovascular protection
                but not uterine or breast cancer growth in mice. J Clin Invest 120:2319–2330.
              </Span>
            </A>
            <A
              id="group-one-11"
              href="https://pubmed.ncbi.nlm.nih.gov/23695162/">
              <Span>11.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Pedram A, et al. (2013) Estrogen reduces lipid content in the liver exclusively from membrane receptor
                signaling. Sci Signal 6:RA36.
              </Span>
            </A>
            <A
              id="group-one-12"
              href="https://pubmed.ncbi.nlm.nih.gov/19587358/">
              <Span>12.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Liu S, et al.. Importance of extranuclear estrogen receptor-alpha and membrane G protein-coupled
                estrogen receptor in pancreatic islet survival. Diabetes 2009;58:2292–2302.
              </Span>
            </A>
            <A
              id="group-one-13"
              href="https://pubmed.ncbi.nlm.nih.gov/22564979/">
              <Span>13.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Tiano JP, Mauvais-Jarvis F. Molecular mechanisms of estrogen receptors’ suppression of lipogenesis in
                pancreatic β-cells. Endocrinology 2012;153:2997–3005.
              </Span>
            </A>
            <A
              id="group-one-14"
              href="https://pubmed.ncbi.nlm.nih.gov/20616010/">
              <Span>14.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Wong WP, et al.. Extranuclear estrogen receptor-alpha stimulates NeuroD1 binding to the insulin promoter
                and favors insulin synthesis. Proc Natl Acad Sci U S A 2010;107:13057–13062.
              </Span>
            </A>
            <A
              id="group-one-15"
              href="https://www.jci.org/articles/view/44564?key=e3a715f3d0406a16a2e2">
              <Span>15.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Tiano JP, et al.. Estrogen receptor activation reduces lipid synthesis in pancreatic islets and prevents
                β cell failure in rodent models of type 2 diabetes. J Clin Invest 2011;121:3331–3342.
              </Span>
            </A>
            <A
              id="group-one-16"
              href="https://doi.org/10.1007/s12672-014-0179-9">
              <Span>16.</Span>
              <Span sx={{ml: '4px', textDecoration: 'underline'}}>
                Levin, E.R. Translating Extranuclear Steroid Receptor Signaling to Clinical Medicine. HORM CANC 5,
                140–145 (2014).
              </Span>
            </A>
          </Div>
        </WrapperShell>
      </Div>
    </Page>
  )
}
