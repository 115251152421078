import {Indexed} from './types';

type TMods = Indexed<boolean | string | undefined>;
type TArgs = [] | [string | TMods] | [string | TMods, TMods];

function createModifiers(block: string, mods: TMods) {
  return Object.entries(mods).reduce((acc, [key, val]) => {
    if (typeof val === 'string') {
      return `${acc} ${block}--${key}-${val}`;
    }

    return val ? `${acc} ${block}--${key}` : acc;
  }, '');
}

export function bem(block: string) {
  return function (...args: TArgs) {
    if (typeof args[0] === 'string') {
      const result = `${block}__${args[0]}`;
      return args[1] ? `${result}${createModifiers(result, args[1])}` : result;
    }

    return args[0] ? `${block}${createModifiers(block, args[0])}` : block;
  };
}
