import {styled} from '@mui/system';

export const StyledTab = styled('button')({
  width: '100%',
  maxWidth: 240,
  minWidth: 150,
  height: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F9D2D6',
  border: '1px solid #F7E5E0',
  borderRadius: 60,
  boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
  boxSizing: 'border-box',
  fontWeight: 600,
  color: '#2F2F34',
  fontSize: 20,
  cursor: 'pointer',

  '@media only screen and (max-width: 600px)': {
    maxWidth: '100%',
  }
})

export const StyledTabsGroup = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: 25,
  marginBottom: 30,
});