import {styled} from '@mui/system';

import {WrapperShell} from './wrapper-shell';

type Props = any;

export const WrapperShellBox = ({children, ...rest}: Props) => {
  return <Div {...rest}>{children}</Div>
}

export const Div = styled(WrapperShell)({
  minHeight: 0,

  '@media only screen and (max-width: 600px)': {
  },
})
