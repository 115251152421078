import {styled} from '@mui/system';
import {PinkLight} from 'styles/color';

export const StyledParagraph = styled('p')({
  color: '#FFF',
  fontSize: 16,
  fontFamily: 'SF-Pro-Text-Regular',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  margin: '14px 0',

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const Link = styled('a')({
  color: '#0076BD',
})

export const StyledH2 = styled('h2')({
  color: PinkLight,
  fontSize: 28,
  fontFamily: 'SF-Pro-Text-Semibold',
  letterSpacing: '0.1px',
  marginTop: 40,

  textTransform: 'uppercase',
  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const StyledH1 = styled('h1')({
  color: PinkLight,
  fontSize: 38,
  fontFamily: 'SF-Pro-Text-Semibold',
  lineHeight: '43px',
  letterSpacing: '0.1px',
  marginTop: 0,

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const LinkA = styled('a')({
  display: 'block',
  color: '#0076BD',
  margin: '10px auto',
  letterSpacing: '0.1px',
  fontSize: 17,
  fontFamily: 'SF-Pro-Text-Semibold',
})
