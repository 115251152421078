import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const KlomonormLinkSix = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Risk of venous thromboembolism from use of oral contraceptives containing different progestogens and oestrogen doses: Danish cohort study, 2001-9</H1>
      <P><b>Objective:</b> To assess the risk of venous thromboembolism from use of combined oral contraceptives according to progestogen type and oestrogen dose.</P>
      <P><b>Design:</b> National historical registry based cohort study.</P>
      <P><b>Setting:</b> Four registries in Denmark.</P>
      <P><b>Participants:</b> Non-pregnant Danish women aged 15-49 with no history of thrombotic disease and followed from January 2001 to December 2009.</P>
      <P><b>Main outcome measures:</b> Relative and absolute risks of first time venous thromboembolism.</P>
      <P><b>Results:</b> Within 8,010,290 women years of observation, 4307 first ever venous thromboembolic events were recorded and 4246 included, among which 2847 (67%) events were confirmed as certain. Compared with non-users of hormonal contraception, the relative risk of confirmed venous thromboembolism in users of oral contraceptives containing 30-40 µg ethinylestradiol with levonorgestrel was 2.9 (95% confidence interval 2.2 to 3.8), with desogestrel was 6.6 (5.6 to 7.8), with gestodene was 6.2 (5.6 to 7.0), and with drospirenone was 6.4 (5.4 to 7.5). With users of oral contraceptives with levonorgestrel as reference and after adjusting for length of use, the rate ratio of confirmed venous thromboembolism for users of oral contraceptives with desogestrel was 2.2 (1.7 to 3.0), with gestodene was 2.1 (1.6 to 2.8), and with drospirenone was 2.1 (1.6 to 2.8). The risk of confirmed venous thromboembolism was not increased with use of progestogen only pills or hormone releasing intrauterine devices. If oral contraceptives with desogestrel, gestodene, or drospirenone are anticipated to increase the risk of venous thromboembolism sixfold and those with levonorgestrel threefold, and the absolute risk of venous thromboembolism in current users of the former group is on average 10 per 10,000 women years, then 2000 women would need to shift from using oral contraceptives with desogestrel, gestodene, or drospirenone to those with levonorgestrel to prevent one event of venous thromboembolism in one year.</P>
      <P><b>Conclusion:</b> After adjustment for length of use, users of oral contraceptives with desogestrel, gestodene, or drospirenone were at least at twice the risk of venous thromboembolism compared with users of oral contraceptives with levonorgestrel.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/22027398/">Vinogradova et al. BMJ. 2015; 350: h2135. Lidegaard. Risk of venous thromboembolism from use of oral contraceptives containing different progestogens and oestrogen doses: Danish cohort study, 2001-9. BMJ. 2011; 343: d6423</A>]
      </P>
    </Page>
  )
}
