import {Box} from '@mui/material';

import {StyledHeader} from '../ui';
import {StyledBoxShadow} from '../../ui';
import {Message} from './message';

export const SubpageDiecyclen = () => {
  return (
    <>
      <StyledHeader>Диециклен vs Жанин</StyledHeader>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день! Медицинский представитель компании Зентива (имя, фамилия). Сможете уделить мне
            несколько минут?
          </Message>
          <Message isQuestion={false}>
            Ольга Ивановна, хочу сегодня обсудить с Вами назначение контрацептивных препаратов. Сколько примерно таких
            пациенток у Вас в неделю бывает?
          </Message>

          <Message>
            Каждый день, 1 пациентка в день точно бывает.
          </Message>
          <Message isQuestion={false}>
            Каждый день, 1 пациентка в день точно бывает.
          </Message>

          <Message>
            А есть ли у Вас пациентки принимающие Жанин?
          </Message>
          <Message isQuestion={false}>
            Скажу Вам прямо, есть пациентки которым я назначила Жанин, а вот принимают они его или нет сложно сказать. У
            него цена-то сами знаете какая. Большая часть пациенток даже не возвращается после моего назначения Жанина,
            так что у меня как таковой и клинической картины нет.
          </Message>

          <Message>
            Правильно я Вас поняла, что Вы хотели бы иметь в своем арсенале препарат, аналог Жанина, но по более
            доступной цене?
          </Message>
          <Message isQuestion={false}>
            Конечно. Сейчас, как никогда, цена препарата имеет огромное значение для пациентов.
          </Message>

          <Message>
            А Вы слышали про Диециклен?
          </Message>
          <Message isQuestion={false}>
            Честно говоря нет, какой-то новый препарат?
          </Message>

          <Message>
            Диециклен это препарат давно зарекомендовавший себя на рынке, кок европейского качества, в составе которого
            как и у Жанина диеногест 2 мг и этинилэстрадиол 0,03 мг а средняя цена при этом по нашему городу порядка 480
            ₽
          </Message>
          <Message isQuestion={false}>
            Это же вдвое дешевле, чем Жанин получается.
          </Message>

          <Message>
            Именно об этом я и говорю.
          </Message>
          <Message isQuestion={false}>
            В таком случае надо попробовать перевести пациенток с Жанина на Диециклен.
          </Message>

          <Message>
            Вы можете перевести пациенток на Диециклен, а так же назначить именно его при первичном приеме, таким
            образом у вас появится уверенность в терапии и обратная связь от пациенток, ведь цена более чем доступная, а
            значит Ваши пациентки точно смогут приобрести назначенный препарат.
          </Message>
          <Message isQuestion={false}>
            Да, я с Вами согласна.
          </Message>

          <Message>
            А это аптечная карта, где Вы можете посмотреть цены на препарат в ближайших к Вашему ЛПУ аптеках и в интернет-аптеке (аптека.ру)
            <Box sx={{mt: '12px'}}>
              Может быть у Вас есть ко мне какие то вопросы по Диециклену?
            </Box>
          </Message>
          <Message isQuestion={false}>
            Нет, состав тот же что у Жанина, а цена мне нравится больше, так что спасибо Вам за предоставленную информацию.
          </Message>

          <Message>
            Рада помочь Вам, увидимся через месяц.
          </Message>
          <Message isQuestion={false}>
            До свидания!
          </Message>

        </Box>
      </StyledBoxShadow>
    </>
  )
}
