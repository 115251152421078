import {styled} from '@mui/system';
import {Box} from '@mui/material';
import {Black, PinkLight} from '../../../../styles/color';

export const Wrapper = styled(Box)`
  & .list {
    padding-left: 25px;
    
    li {
      margin-bottom: 10px;
    }
  }
`
export const StyledWrapperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  font-size: 16px;
  font-family: 'SF Pro Text', sans-serif;
  font-weight: 400;
  color: #FFFFFF;
  
  & .container {
    display: flex;
    gap: 20px;
    
    & > * {
      flex: 1;
      height: fit-content;
    }
  }
`

export const StyledBoxCircle = styled(Box)`
  border: 1px solid ${PinkLight};
  border-radius: 60px;
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.3px;
  padding: 10px 30px;
  background-color: ${Black};
`