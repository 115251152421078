import {Box} from '@mui/material';

import {StyledAnchorLink, StyledHeader} from '../ui';
import {StyledBoxBorder, StyledBoxInsetShadow, StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {Ticket} from './ticket';
import {HeaderTabs as Tabs} from './header-tabs';

type HeaderTabsProps = {
  tabActive: number
}
const HeaderTabs = ({tabActive}: HeaderTabsProps) => <Tabs tabActive={tabActive} tabLength={2}/>

export const SubpageAlvovizan = () => {
  return (
    <>
      <StyledHeader>Алвовизан®</StyledHeader>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <StyledAnchorLink href="#theme1">
          <img src={icons.arrow} alt="*"/>
          Тема #1: Перевод пациенток с эндометриозом, принимающих Визанну на Алвовизан
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme2">
          <img src={icons.arrow} alt="*"/>
          Тема #2: Перевод пациенток, принимающих для лечения эндометриоза Зафриллу на Алвовизан
        </StyledAnchorLink>
      </Box>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <HeaderTabs tabActive={0}/>
        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток с эндометриозом, принимающих Визанну на Алвовизан.
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила АЛВОВИЗАН® 10 пациенткам в течение месяца с эндометриозом вместо
                оригинальной Визанны.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут
                  для обсуждения пациенток
                  <strong> с подозрением на эндометриоз.</strong>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Пример продуктивного вопроса для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с тазовой болью, дисменореей и обильными менструациями?
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с эндометриозом и сопровождающей его хронической тазовой болью?
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с эндометриозом?
                </Box>
                <Box sx={{mb: '12px'}}>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких пациенток
                  на приёме около 2–3 в день. А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box sx={{mb: '12px'}}>Примеры вопросов для определения выписки:</Box>
                <ol className="list">
                  <li>
                    Скольким из этих пациенток Вы назначаете Визанну?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы назначаемый препарат был не только эффективным и безопасным, но и
                    имел социально-ответственную стоимость?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            АЛВОВИЗАН® — НЕМЕЦКИЙ ПРЕПАРАТ, НА 30% ДЕШЕВЛЕ ОРИГИНАЛЬНОГО.
            Назначая Алвовизан, Вы гарантируете своим пациенткам эффективное и доступное по цене лечение.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              В современных экономических условиях очень важно, чтобы пациентки не отказывались от лечения эндометриоза
              из-за высокой стоимости препарата, но при этом получали терапию максимального качества.
            </Box>
            <Box sx={{mb: '12px'}}>
              Алвовизан при сопоставимой с оригинальным препаратом биоэквивалентностью по цене на 30% выгоднее. Препарат
              производится в Германии.
            </Box>
            <Box sx={{mb: '12px'}}>
              Мы подготовили фармакоэкономическое обоснование по препаратам для лечения эндометриоза с одинаковым
              составом. Вывод очевиден.
            </Box>
            <Box sx={{mb: '12px'}}>
              Экономия на минимальный курс (6 месяцев) по сравнению с Визанной составляет 8 000 ₽.
            </Box>
            <Box sx={{mb: '12px'}}>
              <b>Таким образом, Назначая Алвовизан, Вы гарантируете своим пациенткам эффективное и доступное по цене
                лечение.
              </b>
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme2" sx={{mt: 5}}>
        <HeaderTabs tabActive={1}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток, принимающих для лечения эндометриоза Зафриллу на Алвовизан
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила АЛВОВИЗАН® <b>10 пациенткам</b> в течение месяца с эндометриозом вместо
                Зафриллы.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения пациенток с эндометриозом.”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с тазовой болью, дисменореей и обильными менструациями?
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с эндометриозом и сопровождающей его хронической тазовой болью?
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с эндометриозом?
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто в Вашей практике встречаются пациентки, для которых стоимость препарата для лечения
                  эндометриоза определяет его выбор?
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <ol className="list">
                  <li>Скольким из этих пациенток Вы назначаете Зафриллу?</li>
                  <li>
                    Насколько для Вас важно, чтобы назначаемый препарат был не только эффективным, но и имел хороший
                    профиль безопасности при доступной цене?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            <Box sx={{mb: '20px;'}}>
              АЛВОВИЗАН® — НЕМЕЦКИЙ ПРЕПАРАТ, полностью биоэквивалентный оригинальному по социально-ответственной цене.
            </Box>
            <Box>
              Назначая Алвовизан, Вы гарантируете своим пациенткам эффективное, безопасное и доступное по цене лечение.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              Алвовизан при сопоставимой с оригинальным препаратом биоэквивалентностью по цене на 30% выгоднее. Полный
              цикл производства препарата Алвовизан находится в Европе (от субстанции до упаковки).
            </Box>
            <Box sx={{mb: '12px'}}>
              Мы подготовили фармакоэкономическое обоснование по препаратам для лечения эндометриоза с одинаковым
              составом. Вывод очевиден.
            </Box>
            <Box sx={{mb: '12px'}}>
              В сравнении с другими препаратами диеногеста, Алвовизан имеет самую социально-ответственную цену при
              высоком Европейском качестве.
            </Box>
            <Box sx={{mb: '12px'}}>
              Кроме того, Алвовизан разрешен к применению детям с 12 лет или с момента наступления менархе, что говорит
              о высоком уровне безопасности.
            </Box>
            <Box sx={{mb: '12px', fontWeight: 600}}>
              Таким образом, назначая Алвовизан, Вы гарантируете своим пациенткам эффективное, безопасное и доступное по
              цене лечение, которое позволит повысить приверженность пациенток к длительной терапии эндометриоза.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>
    </>
  )
}
