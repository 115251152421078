import {Box} from '@mui/material';
import {useHistory} from 'react-router-dom';

import {Black, PinkLight} from '../../../../styles/color';
import Page from 'shared/ui/Page';
import {
  StyledBtn,
  StyledTab,
  StyledBoxShadow,
  StyledCircle,
  StyledCardBorderLine, StyledCircle60,
  Sup
} from '../ui';
import {StyledButtonAnchorLink, Wrapper} from './ui';

import {ListItem, List, Tables} from '../../../../shared/ui';
//TODO вынести в общую
import {icons} from '../../img';
import iconMessage from '../visits-april-july-proginova/img/iconMessage.png'
import icon1 from '../visits-april-july-proginova/img/icon1.png'
import chart1 from './img/chart1.png';
import chart2 from './img/chart2.png';
import chart4 from './img/chart4.png';
import chart5 from './img/chart5.png';
import chartImg from './img/chart6.png';
import screen1 from './img/screen1.png';
import chart3 from './img/chart3.png';
import iconCheck from './img/iconCheck.png';
import {ReactComponent as ArrowIcon} from '../img/arrow.svg';
import iconMessage40 from '../visits-april-july-proginova/img/iconMessage40.png';
import {AlvovizanListModal} from '../../../../components/alvovizan';
import {useState} from 'react';

export const EffectiveSalesVisitsAprilJulyAlvovizan = () => {
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleRedirectToBack = () => {
    history.push('/effective-sales/to-doctor');
  }

  return (
    <>
      <Page
        head={'ВИЗИТЫ: АПРЕЛЬ-ИЮЛЬ'}
        backText="Назад"
        onClose={handleRedirectToBack}
      >
        <Box sx={{display: 'flex', gap: '20px', mb: '50px'}}>
          <StyledTab to="/effective-sales/visits-april-july-proginova">Прогинова</StyledTab>
          <StyledTab className="active" to="/effective-sales/visits-april-july-alvovizan">Алвовизан</StyledTab>
          <StyledTab to="/effective-sales/visits-april-july-dydrogesterone">Исследование: дидрогестерон</StyledTab>
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
          <StyledButtonAnchorLink href="#theme1">
            <img src={icons.arrow} alt="*"/>
            Тема #1: Пациентка с бесплодием, обусловленным эндометриозом
          </StyledButtonAnchorLink>
          <StyledButtonAnchorLink href="#theme2">
            <img src={icons.arrow} alt="*"/>
            Тема #2: Портрет 1.1 — Пациентка с хронической тазовой болью, проявлениями дисменореи, диспареунии при
            эндометриозе (КОК)
          </StyledButtonAnchorLink>
          <StyledButtonAnchorLink href="#theme3">
            <img src={icons.arrow} alt="*"/>
            Тема #3: Портрет 1.2 — Пациентка с хронической тазовой болью, проявлениями дисменореи, диспареунии при
            эндометриозе (Дюфастон)
          </StyledButtonAnchorLink>
          <StyledButtonAnchorLink href="#theme4">
            <img src={icons.arrow} alt="*"/>
            Тема #4: Портрет 2 — Пациентка после хирургического лечения по поводу эндометриоза, которым не назначается
            противорецидивное лечение / назначается на короткий срок
          </StyledButtonAnchorLink>
        </Box>

        <Wrapper>
          <StyledBoxShadow id="theme1" sx={{mt: 4, padding: '40px 20px'}}>
            <Box className="header pink">
              ТЕМА ВИЗИТА #1:
              <Box className="white">Пациентка с бесплодием, обусловленным эндометриозом.</Box>
            </Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={iconMessage}/>
              </StyledCircle>
              <Box>
                <Box sx={{fontWeight: 600}}>
                  М.П: Доктор, хотела обсудить с вами пациенток, которые обращаются с бесплодием, ассоциированным с
                  эндометриозом. Эндометриоз — одна из частых причин бесплодия, по статистике от 25% до 50% случаев.
                </Box>
                <Box sx={{mt: 2}}>
                  К возможным причинам субфертильности можно отнести:
                </Box>

                <ol style={{lineHeight: '28px', margin: '8px 0px', paddingLeft: '24px'}}>
                  <li>Нарушение эндометриальной перистальтики</li>
                  <li>Нарушение рецептивности эндометрия</li>
                  <li>Нарушение имплантации</li>
                  <li>Возникновение хронического эндометрита</li>
                </ol>
              </Box>
            </StyledCardBorderLine>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '20px'}}>
              <StyledCircle>
                <img src={icon1}/>
              </StyledCircle>
              <Box>
                <Box>
                  Ключевые принципы терапии эндометриоз-ассоциированного бесплодия направлены на сохранение
                  фертильности, купирование боли, профилактики рецидивов, улучшение качества жизни и приверженности
                  лечению.
                </Box>
                <Box sx={{mt: 2}}>
                  Согласно клиническим рекомендациям терапия прогестинами, а именно диеногестом рассматривается как
                  терапия первой линии при эндометриоз-ассоциированном бесплодии. По результатам исследования у женщин с
                  аденомиозом, получавших диеногест перед процедурой ЭКО, чаще наступала беременность. Эффективность
                  восстановления фертильности у пациенток с эндометриомами менее 3 см после завершения терапии
                  диеногестом не уступает двухэтапной схеме лечения — лапароскопии с последующим назначением
                  гормональной терапии.
                </Box>
              </Box>
            </StyledCardBorderLine>

            <Box className="sub-header pink" sx={{mt: 5, mb: 3}}>
              У женщин с аденомиозом, получавших диеногест перед процедурой ЭКО, чаще наступала беременность, даже при
              запущенных формах.
            </Box>

            <Box sx={{fontSize: 20, lineHeight: '24px'}}>
              Частота наступления беременности в результате ЭКО у женщин с аденомиозом
            </Box>

            <Box sx={{mt: '20px'}}>
              <img src={chart1} style={{width: '100%'}}/>
            </Box>

            <Box sx={{mt: 4, mb: '20px', fontSize: '20px', lineHeight: '24px'}}>
              Эффективность восстановления фертильности у пациенток с эндометриомами менее 3 см после завершения терапии
              диеногестом (1 группа) не уступает двухэтапной схеме лечения — лапароскопии с последующим назначением
              гормональной терапии* (2 группа)
            </Box>

            <Box sx={{mt: '20px'}}>
              <img src={chart2} style={{width: '100%'}}/>
            </Box>

            <Box className="header pink" sx={{mt: 5, mb: 4, textTransform: 'uppercase'}}>Возможные возражения</Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px'}}>
              <StyledCircle60>
                <img src={iconMessage40} width={37} height={37}/>
              </StyledCircle60>
              <Box>
                <Box className="pink" sx={{mb: 2}}>
                  Д: Я назначаю дюфастон при эндометриозе, т.к. он не влияет на овуляцию.
                </Box>
                <Box sx={{fontWeight: 600, mb: 1}}>
                  М.П: Доктор, понимаю ваше желание назначить безопасный препарат. Но последние исследования о влиянии
                  дидрогестерона на частоту врожденных пороков у новорожденных показали, что:
                </Box>
                <List sx={{fontWeight: 600}}>
                  <ListItem>
                    риск врожденных дефектов при применении Дюфастона был выше в 5,4 раза, по сравнению с любым другим
                    лекарственным средством.
                  </ListItem>
                  <ListItem>
                    доказана репродуктивная токсичность, которая может нанести вред фертильности, нерожденному ребенку,
                    вызвать генетические дефекты.
                  </ListItem>
                </List>
                <Box sx={{fontWeight: 600}}>
                  Данные были представлены на 39 Конгрессе Европейского общества репродукции человека и эмбриологии в 23
                  году.
                </Box>
              </Box>
            </StyledCardBorderLine>

            <Box className="sub-header pink" sx={{mt: 5}}>
              Дидрогестерон был отозван с рынков США, Великобритании, Австралии<Sup sx={{color: PinkLight}}>1</Sup>
            </Box>

            <List>
              <ListItem sx={{mb: 1}}>
                США: дидрогестерон в настоящее время не доступен в Соединенных Штатах; он был выведен с рынка по
                коммерческим причинам. Для США дидрогестерон был зарегистрирован в 1961 году и лицензия передана с
                годами нескольким компаниям. В 1997 году нынешний новый владелец заявки на лекарства, Solvay, отозвал
                продукт, потому что зарегистрированные показания больше не были коммерчески жизнеспособными и/или
                существовал потенциально противоречивый интерес в отношении других продуктов, обладателем лицензии
                которых является Solvay.
              </ListItem>
              <ListItem sx={{mb: 1}}>
                Великобритания: продукт был изъят с рынка Соединенного Королевства в 2008 году. Австралия: был изъят в
                2011 году по коммерческим причинам.
              </ListItem>
              <ListItem sx={{mb: 1}}>
                Для Соединенного Королевства и Австралии низкие продажи сравнительно дешевого препарата и отсутствие
                новых и коммерчески интересных признаков обусловили уход с рынков.
              </ListItem>
            </List>

            <Box sx={{fontSize: 12, lineHeight: '14px'}}>
              Griesinger G, Blockeel C, Tournaye H. Oral dydrogesterone for luteal phase support in fresh in vitro
              fertilization cycles: a new standard? Fertil Steril. 2018 May; 109(5):756-762.
            </Box>

            <Box className="sub-header pink" sx={{mt: 5}}>
              Дидрогестерон был отозван в ОАЭ, причины неизвестны
            </Box>

            <Box sx={{mt: '20px'}}>
              <img src={screen1} style={{width: '100%'}}/>
            </Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={iconMessage}/>
              </StyledCircle>
              <Box>
                <Box className="pink" sx={{mb: 2}}>
                  Д: Почему пациентке при эндометриоз-ассоциированном бесплодии нужно назначить именно Алвовизан?
                </Box>
                <Box>
                  М.П: <br/>
                  <List sx={{mb: 0}}>
                    <ListItem sx={{mb: 1}}>
                      Это первая линия терапии на основании Национальных рекомендаций
                    </ListItem>
                    <ListItem sx={{mb: 1}}>
                      Алвовизан — биоэквивалентный препарат, полностью биоидентичен оригинальному продукту, т.е. это
                      такая
                      же по эффективности терапия, как и на оригинальном препарате.
                    </ListItem>
                    <ListItem sx={{mb: 1}}>
                      Алвовизан при ежедневном приеме в достаточной дозе является доказанной эффективной терапией
                      хронической тазовой боли, связанной с эндометриозом.
                    </ListItem>
                    <ListItem sx={{mb: 1}}>
                      Алвовизан — единственный диеногест, который занимает нишу стоимость-эффективность, что позволяет
                      использовать препарат длительно. А это в свою очередь приведёт к длительной ремиссии.
                    </ListItem>
                    <ListItem>
                      Социально-ответственная цена Алвовизана позволяет пациенткам лечиться длительно до момента
                      планирования беременности.
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </StyledCardBorderLine>

            <Box className="sub-header pink" sx={{mt: '40px'}}>
              Исследование сравнительной фармакокинетики и биоэквивалентности препаратов Алвовизан и Визанна показало,
              что препараты
            </Box>
            <Box sx={{fontSize: 28, lineHeight: '33px', fontWeight: 600, mt: '20px'}}>Алвовизан и Визанна являются
              биоэквивалентными
            </Box>

            <Box sx={{mt: '30px'}}>
              <img src={chart3} style={{width: 588, maxWidth: '100%'}}/>
            </Box>

            <StyledCardBorderLine sx={{mt: '40px', display: 'flex', gap: '20px'}}>
              <StyledCircle sx={{backgroundColor: PinkLight}}>
                <img src={iconCheck} width={42} height={42}/>
              </StyledCircle>
              <Box>
                <Box className="header pink">ВЫВОДЫ:</Box>
                <List sx={{fontSize: 20, lineHeight: '24px', mb: 0}}>
                  <ListItem sx={{mb: 1}}>
                    Исследуемые препараты характеризуются высокой степенью сходства показателей фармакокинетики.
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Профили фармакокинетических кривых препаратов Алвовизан и Визанна имеют совпадающие формы.

                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Препараты характеризуются близкими значениями относительной биодоступности, максимальной
                    концентрации и относительной скорости всасывания.
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Доверительные интервалы для AUC, Cmax и Cmax/AUC соответствуют допустимым пределам 80–125%.
                  </ListItem>
                  <ListItem>
                    Препараты имеют сопоставимый профиль безопасности.
                  </ListItem>
                </List>
              </Box>
            </StyledCardBorderLine>

            <Box className="header" sx={{mt: '40px'}}>
              Алвовизан — немецкий препарат, на 30% дешевле оригинального
            </Box>
            <Box className="sub-header pink" sx={{mt: '20px'}}>
              Назначая Алвовизан, доктор гарантирует своим пациенткам эффективное и доступное по цене лечение.
            </Box>

            <Tables.WrapperTable sx={{mt: 3}}>
              <Tables.Table className="align-default" sx={{fontSize: 18, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr>
                    <Tables.Td sx={{width: '400px'}}>Действующее вещество</Tables.Td>
                    <Tables.Td colSpan={2}>Диеногест 2 мг</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td sx={{backgroundColor: PinkLight, color: Black}}>Препарат</Tables.Td>
                    <Tables.Td sx={{backgroundColor: '#FFF', color: Black}}>Алвовизан №28</Tables.Td>
                    <Tables.Td sx={{backgroundColor: PinkLight, color: Black}}>Визанна №28</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Средняя стоимость в аптеке, ₽</Tables.Td>
                    <Tables.Td>2 500</Tables.Td>
                    <Tables.Td>3 500</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / месяц, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>1 080</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / год — 12 циклов, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>12 000</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
          </StyledBoxShadow>

          <StyledBoxShadow id="theme2" sx={{mt: 4, padding: '40px 20px'}}>
            <Box className="header pink">
              ТЕМА ВИЗИТА #2:
              <Box className="white">
                Портрет 1.1 — Пациентка с хронической тазовой болью, проявлениями дисменореи, диспареунии при
                эндометриозе (КОК)
              </Box>
            </Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={icon1}/>
              </StyledCircle>
              <Box>
                Эндометриоз, являющийся наиболее частой причиной хронической тазовой боли, занимает от 40 до 60% в
                структуре гинекологического приёма. Болевой синдром при эндометриозе многолик. Именно болевой синдром
                меняет качество жизни, резко ухудшает его. Интенсивность болевых ощущений у каждой женщины проявляется
                по-разному. Одни пациентки описывают боль как терпимую, ноющую, другие же говорят о сильной судорожной
                боли. Симптомы эндометриоза включат в себя дисменорею, боль во время полового акта.
              </Box>
            </StyledCardBorderLine>

            <Box className="sub-header pink" sx={{mt: '40px', mb: '20px'}}>
              Симптомы эндометриоза
            </Box>
            Эндометриоз часто сопровождается бесплодием или субфертильностью. Женщины могут узнать о том, что они могут
            страдать эндометриозом только после посещения специалиста по проблемам бесплодия или после лапароскопии.

            <Box sx={{fontWeight: 500, mb: '10px', mt: '20px'}}>Наиболее типичные симптомы:</Box>

            <List sx={{mt: '10px'}}>
              <ListItem sx={{mb: 1}}>диффузная/хроническая тазовая боль;</ListItem>
              <ListItem sx={{mb: 1}}>дисменорея;</ListItem>
              <ListItem sx={{mb: 1}}>предменструальная боль;</ListItem>
              <ListItem sx={{mb: 1}}>диспареуния.</ListItem>
            </List>

            <Box sx={{fontWeight: 500}}>Другие симптомы включают:</Box>
            <List sx={{mt: '10px'}}>
              <ListItem sx={{mb: 1}}>
                симптомы поражения кишечника или мочевого пузыря (часто в перименопаузе);</ListItem>
              <ListItem sx={{mb: 1}}>боль в спине, хроническая усталость.</ListItem>
            </List>
            <Box sx={{fontWeight: 500}}>
              Примечание: у части женщин эндометриоз протекает бессимптомно.
            </Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={icon1}/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2}}>
                  Если пациентка борется с эндометриозом, то важно понимать, что КОК не помогут, в инструкции по
                  медицинскому применению отсутствует показание лечение эндометриоза.
                </Box>
                <Box sx={{mb: 2}}>
                  КОК не показаны для лечения эндометриоза, т.к. при приеме любых КОК возможна стимуляция роста очагов
                  эндометриоза и прогрессирование заболевания.
                </Box>
                <Box>
                  Важно помнить, что эндометриоз требует пожизненного плана ведения, цель которого — максимально
                  использовать возможности медикаментозной терапии для скорейшего наступления ремиссии и предотвращения
                  оперативных вмешательств.
                </Box>
              </Box>
            </StyledCardBorderLine>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={iconMessage}/>
              </StyledCircle>
              <Box>
                <Box className="pink" sx={{mb: 2}}>
                  Д: Почему пациентке с хронической тазовой болью нужно назначить именно Алвовизан?
                </Box>
                М.П: <br/>

                <List style={{margin: '8px 0px', paddingLeft: '28px'}}>
                  <ListItem sx={{mb: 1}}>Это первая линия терапии эндометриоза на основании Национальных
                    рекомендаций</ListItem>
                  <ListItem sx={{mb: 1}}>Алвовизан — биоэквивалентный препарат, полностью биоидентичен оригинальному
                    продукту, т.е. это
                    такая же по эффективности терапия, как и на оригинальном препарате.
                  </ListItem>
                  <ListItem>Алвовизан при ежедневном приеме в достаточной дозе является доказанной эффективной терапией
                    хронической тазовой боли, связанной с эндометриозом.
                  </ListItem>
                </List>
              </Box>
            </StyledCardBorderLine>

            <Box className="sub-header pink" sx={{mt: '40px'}}>
              Исследование сравнительной фармакокинетики и биоэквивалентности препаратов Алвовизан и Визанна показало,
              что препараты
            </Box>
            <Box sx={{fontSize: 28, lineHeight: '33px', fontWeight: 600, mt: '20px'}}>Алвовизан и Визанна являются
              биоэквивалентными
            </Box>

            <Box sx={{mt: '30px'}}>
              <img src={chart3} style={{width: 588, maxWidth: '100%'}}/>
            </Box>

            <StyledCardBorderLine sx={{mt: '40px', display: 'flex', gap: '20px'}}>
              <StyledCircle sx={{backgroundColor: PinkLight}}>
                <img src={iconCheck} width={44} height={44}/>
              </StyledCircle>
              <Box>
                <Box className="header pink">ВЫВОДЫ:</Box>
                <List sx={{fontSize: 20, lineHeight: '24px', mb: 0}}>
                  <ListItem sx={{mb: 1}}>
                    Исследуемые препараты характеризуются высокой степенью сходства показателей фармакокинетики.
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Профили фармакокинетических кривых препаратов Алвовизан и Визанна имеют совпадающие формы.
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Препараты характеризуются близкими значениями относительной биодоступности, максимальной
                    концентрации и относительной скорости всасывания
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Доверительные интервалы для AUC, Cmax и Cmax/AUC соответствуют допустимым пределам 80–125%.
                  </ListItem>
                  <ListItem>
                    Препараты имеют сопоставимый профиль безопасности.
                  </ListItem>
                </List>
              </Box>
            </StyledCardBorderLine>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={iconMessage}/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2}}>
                  М.П:
                </Box>

                <List style={{margin: '8px 0px', paddingLeft: '28px'}}>
                  <ListItem sx={{mb: 1}}>
                    Алвовизан — единственный диеногест, который занимает нишу стоимость-эффективность, что позволяет
                    использовать препарат длительно. А это в свою очередь приведёт к длительной ремиссии.
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Социально-ответственная цена Алвовизана позволяет пациенткам лечиться длительно до момента
                    планирования беременности или менопаузы без ущерба для бюджета.
                  </ListItem>
                </List>
              </Box>
            </StyledCardBorderLine>

            <Box className="header" sx={{mt: '40px'}}>
              Алвовизан — немецкий препарат, на 30% дешевле оригинального
            </Box>
            <Box className="sub-header pink" sx={{mt: '20px'}}>
              Назначая Алвовизан, доктор гарантирует своим пациенткам эффективное и доступное по цене лечение.
            </Box>

            <Tables.WrapperTable sx={{mt: 3}}>
              <Tables.Table className="align-default" sx={{fontSize: 18, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr>
                    <Tables.Td sx={{width: '400px'}}>Действующее вещество</Tables.Td>
                    <Tables.Td colSpan={2}>Диеногест 2 мг</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td sx={{backgroundColor: PinkLight, color: Black}}>Препарат</Tables.Td>
                    <Tables.Td sx={{backgroundColor: '#FFF', color: Black}}>Алвовизан №28</Tables.Td>
                    <Tables.Td sx={{backgroundColor: PinkLight, color: Black}}>Визанна №28</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Средняя стоимость в аптеке, ₽</Tables.Td>
                    <Tables.Td>2 500</Tables.Td>
                    <Tables.Td>3 500</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / месяц, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>1 080</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / год — 12 циклов, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>12 000</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={icon1}/>
              </StyledCircle>
              <Box>
                <Box>
                  В том случае если, пациентка настаивает на КОК с целью контрацепции, то можно рассмотреть Диециклен, в
                  составе которого содержится диеногест.
                </Box>
              </Box>
            </StyledCardBorderLine>
          </StyledBoxShadow>

          <StyledBoxShadow id="theme3" sx={{mt: 4, padding: '40px 20px'}}>
            <Box className="header pink">
              ТЕМА ВИЗИТА #3:
              <Box className="white">
                Портрет 1.2 — Пациентка с хронической тазовой болью, проявлениями дисменореи, диспареунии при
                эндометриозе (Дюфастон)
              </Box>
            </Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={icon1}/>
              </StyledCircle>
              <Box>
                Эндометриоз, являющийся наиболее частой причиной хронической тазовой боли, занимает от 40 до 60% в
                структуре гинекологического приёма. Болевой синдром при эндометриозе многолик. Именно болевой синдром
                меняет качество жизни, резко ухудшает его. Интенсивность болевых ощущений у каждой женщины проявляется
                по-разному. Одни пациентки описывают боль как терпимую, ноющую, другие же говорят о сильной судорожной
                боли. Симптомы эндометриоза включат в себя дисменорею, боль во время полового акта.
              </Box>
            </StyledCardBorderLine>

            <Box className="sub-header pink" sx={{mt: '40px', mb: '20px'}}>
              Симптомы эндометриоза
            </Box>
            Эндометриоз часто сопровождается бесплодием или субфертильностью. Женщины могут узнать о том, что они могут
            страдать эндометриозом только после посещения специалиста по проблемам бесплодия или после лапароскопии.

            <Box sx={{fontWeight: 500, mb: '10px', mt: '20px'}}>Наиболее типичные симптомы:</Box>

            <List sx={{mt: '10px'}}>
              <ListItem sx={{mb: 1}}>диффузная/хроническая тазовая боль;</ListItem>
              <ListItem sx={{mb: 1}}>дисменорея;</ListItem>
              <ListItem sx={{mb: 1}}>предменструальная боль;</ListItem>
              <ListItem sx={{mb: 1}}>диспареуния.</ListItem>
            </List>

            <Box sx={{fontWeight: 500}}>Другие симптомы включают:</Box>
            <List sx={{mt: '10px'}}>
              <ListItem sx={{mb: 1}}>
                симптомы поражения кишечника или мочевого пузыря (часто в перименопаузе);</ListItem>
              <ListItem sx={{mb: 1}}>боль в спине, хроническая усталость.</ListItem>
            </List>
            <Box sx={{fontWeight: 500}}>
              Примечание: у части женщин эндометриоз протекает бессимптомно.
            </Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={icon1}/>
              </StyledCircle>
              <Box>
                Если говорить о доказательной медицине, то имеются ограниченные данные о применении Дюфастона при
                эндометриозе, что касается Алвовизана, то он изучен как в клинических, так и наблюдательных
                исследованиях. Неудобная схема приема дидрогестерона 2-3 раза в день, что увеличивает стоимость лечения,
                в отличие от Алвовизана, где прием препарата 1 раз в день. По сравнению с дидрогестероном диеногест
                обладает мощным прогестагенным эффектом, а следовательно оказывает более выраженный антипролиферативный
                эффект. Кроме того диеногест обладает дополнительно иммунологическим и антиангиогенным эффектом, что
                способствует воздействию на пролиферацию клеток эндометрия.
              </Box>
            </StyledCardBorderLine>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '20px'}}>
              <StyledCircle>
                <img src={icon1}/>
              </StyledCircle>
              <Box>
                Важно помнить, что эндометриоз требует пожизненного плана ведения, цель которого — максимально
                использовать возможности медикаментозной терапии для скорейшего наступления ремиссии и предотвращения
                оперативных вмешательств.
              </Box>
            </StyledCardBorderLine>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={iconMessage}/>
              </StyledCircle>
              <Box>
                <Box className="pink" sx={{mb: 2}}>
                  Д: Почему пациентке с хронической тазовой болью нужно назначить именно Алвовизан?
                </Box>
                М.П: <br/>

                <List sx={{margin: '8px 0px', paddingLeft: '28px', mb: 0}}>
                  <ListItem sx={{mb: 1}}>Это первая линия терапии эндометриоза на основании Национальных
                    рекомендаций</ListItem>
                  <ListItem sx={{mb: 1}}>Алвовизан — биоэквивалентный препарат, полностью биоидентичен оригинальному
                    продукту, т.е. это
                    такая же по эффективности терапия, как и на оригинальном препарате.
                  </ListItem>
                  <ListItem>Алвовизан при ежедневном приеме в достаточной дозе является доказанной эффективной терапией
                    хронической тазовой боли, связанной с эндометриозом.
                  </ListItem>
                </List>
              </Box>
            </StyledCardBorderLine>

            <Box className="sub-header pink" sx={{mt: '40px'}}>
              Исследование сравнительной фармакокинетики и биоэквивалентности препаратов Алвовизан и Визанна показало,
              что препараты
            </Box>
            <Box sx={{fontSize: 28, lineHeight: '33px', fontWeight: 600, mt: '20px'}}>Алвовизан и Визанна являются
              биоэквивалентными
            </Box>

            <Box sx={{mt: '30px'}}>
              <img src={chart3} style={{width: 588, maxWidth: '100%'}}/>
            </Box>

            <StyledCardBorderLine sx={{mt: '40px', display: 'flex', gap: '20px'}}>
              <StyledCircle sx={{backgroundColor: PinkLight}}>
                <img src={iconCheck} width={44} height={44}/>
              </StyledCircle>
              <Box>
                <Box className="header pink">ВЫВОДЫ:</Box>
                <List sx={{fontSize: 20, lineHeight: '24px', mb: 0}}>
                  <ListItem sx={{mb: 1}}>
                    Исследуемые препараты характеризуются высокой степенью сходства показателей фармакокинетики.
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Профили фармакокинетических кривых препаратов Алвовизан и Визанна имеют совпадающие формы.
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Препараты характеризуются близкими значениями относительной биодоступности, максимальной
                    концентрации и относительной скорости всасывания
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Доверительные интервалы для AUC, Cmax и Cmax/AUC соответствуют допустимым пределам 80–125%.
                  </ListItem>
                  <ListItem>
                    Препараты имеют сопоставимый профиль безопасности.
                  </ListItem>
                </List>
              </Box>
            </StyledCardBorderLine>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '40px'}}>
              <StyledCircle>
                <img src={iconMessage}/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2}}>
                  М.П:
                </Box>

                <List style={{margin: '8px 0px', paddingLeft: '28px'}}>
                  <ListItem sx={{mb: 1}}>
                    Алвовизан — единственный диеногест, который занимает нишу стоимость-эффективность, что позволяет
                    использовать препарат длительно. А это в свою очередь приведёт к длительной ремиссии.
                  </ListItem>
                  <ListItem sx={{mb: 1}}>
                    Социально-ответственная цена Алвовизана позволяет пациенткам лечиться длительно до момента
                    планирования беременности или менопаузы без ущерба для бюджета.
                  </ListItem>
                </List>
              </Box>
            </StyledCardBorderLine>

            <Box className="header" sx={{mt: '40px'}}>
              Алвовизан — немецкий препарат, на 30% дешевле оригинального
            </Box>
            <Box className="sub-header pink" sx={{mt: '20px'}}>
              Назначая Алвовизан, доктор гарантирует своим пациенткам эффективное и доступное по цене лечение.
            </Box>

            <Tables.WrapperTable sx={{mt: 3}}>
              <Tables.Table className="align-default" sx={{fontSize: 18, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr>
                    <Tables.Td sx={{width: '400px'}}>Действующее вещество</Tables.Td>
                    <Tables.Td colSpan={2}>Диеногест 2 мг</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td sx={{backgroundColor: PinkLight, color: Black}}>Препарат</Tables.Td>
                    <Tables.Td sx={{backgroundColor: '#FFF', color: Black}}>Алвовизан №28</Tables.Td>
                    <Tables.Td sx={{backgroundColor: PinkLight, color: Black}}>Визанна №28</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Средняя стоимость в аптеке, ₽</Tables.Td>
                    <Tables.Td>2 500</Tables.Td>
                    <Tables.Td>3 500</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / месяц, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>1 080</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / год — 12 циклов, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>12 000</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
          </StyledBoxShadow>

          <StyledBoxShadow id="theme4" sx={{mt: 4, padding: '40px 20px'}}>
            <Box className="header pink">
              ТЕМА ВИЗИТА #4:
              <Box className="white">
                Портрет 2 — Пациентка после хирургического лечения по поводу эндометриоза, которым не назначается
                противорецидивное лечение / назначается на короткий срок.
              </Box>
            </Box>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '20px'}}>
              <StyledCircle>
                <img src={icon1}/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2}}>
                  После удаления очага эндометриоза у большинства больных возникает рецидив заболевания, т.к. в ходе
                  оперативного вмешательства удаляют лишь видимые и доступные очаги, а микроскопические очаги могут
                  оставаться незамеченными и длительно персистировать. Важно помнить, что операция по поводу
                  эндометриоза в жизни женщины должна быть только одна.
                </Box>
                <Box>
                  Вовремя НЕ назначенная терапия ПРИВОДИТ ПАЦИЕНТКУ к серьёзным осложнениям и иногда операция
                  затрагивает соседние органы, заканчивается установкой мочеприёмника и колостомы у молодых пациенток,
                  что в свою очередь снижает качество жизни. Взвешенное отношение к выбору терапии может способствовать
                  сохранению овариального резерва и сохранению репродуктивной функции пациентки.
                </Box>
              </Box>
            </StyledCardBorderLine>

            <StyledCardBorderLine sx={{display: 'flex', gap: '20px', mt: '20px'}}>
              <StyledCircle>
                <img src={icon1}/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2}}>
                  В связи с этим, современным подходом к управлению эндометриозом является только медикаментозная
                  терапия. При этом предпочтение следует отдавать Алвовизану.
                </Box>
                <Box>
                  <List sx={{mb: 0}}>
                    <ListItem sx={{mb: 1}}>
                      Это первая линия терапии эндометриоза на основании Национальных рекомендаций
                    </ListItem>
                    <ListItem sx={{mb: 1}}>
                      Согласно исследованию, через 50 месяцев после операции не отмечено ни одного случая.
                    </ListItem>
                    <ListItem sx={{mb: 1}}>
                      Алвовизан — биоэквивалентный препарат, полностью биоидентичен оригинальному продукту, т.е. это
                      такая же по эффективности терапия, как и на оригинальном препарате
                    </ListItem>
                    <ListItem sx={{mb: 1}}>
                      Алвовизан при ежедневном приеме в достаточной дозе является доказанной эффективной терапией
                      хронической тазовой боли, связанной с эндометриозом
                    </ListItem>
                    <ListItem sx={{mb: 1}}>
                      Алвовизан — единственный диеногест, который занимает нишу стоимость-эффективность, что позволяет
                      использовать препарат длительно. А это в свою очередь приведёт к длительной ремиссии.
                    </ListItem>
                    <ListItem sx={{mb: 1}}>
                      Социально-ответственная цена Алвовизана позволяет пациенткам лечиться длительно до момента
                      планирования беременности или менопаузы без ущерба для бюджета
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </StyledCardBorderLine>

            <Box sx={{display: 'flex', gap: '20px', mt: 5}}>
              <StyledCardBorderLine>
                <Box className="sub-header pink" sx={{mb: '20px'}}>
                  Длительное (более 5 лет)
                  применение Диеногеста
                  после хирургического
                  удаления эндометриомы
                </Box>
                <img src={chart4} style={{maxWidth: 428}}/>
                <img src={chart5} style={{maxWidth: 428, marginTop: '20px'}}/>
                <Box sx={{fontSize: 12, lineHeight: '14px', mt: 2}}>
                  Ota Y et al. J Endometr Pelvic Pain Disord 2015; 7(2): 63–67.
                </Box>
              </StyledCardBorderLine>
              <StyledCardBorderLine>
                <Box className="sub-header pink" sx={{mb: '20px'}}>
                  Частота рецидива
                  эндометриом на фоне лечения Диеногестом
                  более 12 месяцев — 0 ,9%
                  (1 из 114 женщин)
                </Box>
                <img src={chartImg} style={{maxWidth: 428}}/>
                <Box sx={{fontSize: 12, lineHeight: '14px', mt: 2}}>
                  Park, So Yun et al. “Eﬃcacy and safety of dienogest in patients with endometriosis: A single-center
                  observational study over 12 months” Clinical and experimental reproductive medicine vol. 43,4 (2016):
                  215-220.
                </Box>
              </StyledCardBorderLine>
            </Box>

            <Box className="header" sx={{mt: '40px'}}>
              Алвовизан — немецкий препарат, на 30% дешевле оригинального
            </Box>
            <Box className="sub-header pink" sx={{mt: '20px'}}>
              Назначая Алвовизан, доктор гарантирует своим пациенткам эффективное и доступное по цене лечение.
            </Box>

            <Tables.WrapperTable sx={{mt: 3}}>
              <Tables.Table className="align-default" sx={{fontSize: 18, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr>
                    <Tables.Td sx={{width: '400px'}}>Действующее вещество</Tables.Td>
                    <Tables.Td colSpan={2}>Диеногест 2 мг</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td sx={{backgroundColor: PinkLight, color: Black}}>Препарат</Tables.Td>
                    <Tables.Td sx={{backgroundColor: '#FFF', color: Black}}>Алвовизан №28</Tables.Td>
                    <Tables.Td sx={{backgroundColor: PinkLight, color: Black}}>Визанна №28</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Средняя стоимость в аптеке, ₽</Tables.Td>
                    <Tables.Td>2 500</Tables.Td>
                    <Tables.Td>3 500</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / месяц, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>1 080</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Разница в стоимости / год — 12 циклов, ₽</Tables.Td>
                    <Tables.Td colSpan={2}>12 000</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
          </StyledBoxShadow>
        </Wrapper>

        <StyledBtn onClick={() => setIsOpenModal(true)}>
          ПРЕЗЕНТАЦИЯ ПРЕПАРАТА
          <ArrowIcon/>
        </StyledBtn>
      </Page>

      <AlvovizanListModal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)}/>
    </>
  )
}
