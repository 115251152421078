import {Box} from '@mui/material';

import {ToDoctorCard} from './to-doctor-card';
import {StyledWrapperBox, StyledBoxCircle, Wrapper} from './ui';
import {
  StyledBoxBorder,
  StyledBoxInsetShadow,
  StyledBoxShadow,
  StyledCircle,
  StyledHeader,
  StyledInsetCircle
} from '../../ui';
import images, {icons} from '../../img'
import {PinkLight} from '../../../../styles/color';

export const EffectiveSalesToDoctor = () => {
  return (
    <Wrapper>
      <StyledHeader sx={{mb: 3, mt: 7}}>Визит к врачу</StyledHeader>
      <StyledWrapperBox>
        <Box className="container">
          <ToDoctorCard img={images.klimonorm} productName="КЛИМОНОРМ®" link="/effective-sales-doctor/klimonorm">
            Я хочу, чтобы доктор Н. назначил КЛИМОНОРМ® 40 пациенткам 45+ в месяц с целью сохранения здоровья.
          </ToDoctorCard>
          <ToDoctorCard img={images.zoely} productName="ЗОЭЛИ®" link="/effective-sales-doctor/zoely">
            Я хочу, чтобы доктор Н. назначил ЗОЭЛИ® 10 женщинам в месяц, которые привыкли
            выбирать для себя всё самое лучшее, в т. ч. и контрацепцию, для которых важна
            максимально физиологичная надежная и качественная контрацепция.
          </ToDoctorCard>
          <ToDoctorCard img={images.microgynom} productName="МИКРОГИНОН®" link="/effective-sales-doctor/microginon">
            Я хочу, чтобы доктор Н. назначил МИКРОГИНОН® 20 пациенткам
            в месяц, которым нужна эффективная терапия обильных менструаций +
            контрацепция, но при этом важны безопасность препарата и его цена.
          </ToDoctorCard>
          <ToDoctorCard img={images.alvovizan} productName="АЛВОВИЗАН®" link="/effective-sales-doctor/alvovizan">
            Я хочу, чтобы доктор Н. назначил АЛВОВИЗАН® 10 пациенткам в месяц с эндометриозом.
          </ToDoctorCard>
        </Box>
        <Box className="container">
          <ToDoctorCard img={images.vidora} productName="ВИДОРА® и ВИДОРА® Микро" link="/effective-sales-doctor/vidora">
            Я хочу, чтобы доктор Н. назначил ВИДОРА® и ВИДОРА® Микро 10 пациенткам
            в месяц, которые используют препараты Ярина и Джес, но хотят получать более
            доступную контрацепцию с теми же эффектами.
          </ToDoctorCard>
          <ToDoctorCard img={images.diecyclen} productName="ДИЕЦИКЛЕН®" link="/effective-sales-doctor/diecyclen">
            Я хочу, чтобы доктор Н. назначил ДИЕЦИКЛЕН® 10 пациенткам
            в месяц, которые используют Жанин, но хотят получать более доступную
            контрацепцию с теми же эффектами.
          </ToDoctorCard>
          <ToDoctorCard img={images.velledien} productName="ВЕЛЛЕДИЕН" link="/effective-sales-doctor/velledien">
            Я хочу, чтобы доктор Н. назначил ВЕЛЛЕДИЕН 5 пациенткам в месяц,
            которые хотят получать эффективную МГТ в постменопаузе без риска
            для молочной железы.
          </ToDoctorCard>
          <ToDoctorCard img={images.proginova} productName="ПРОГИНОВА®" link="/effective-sales-doctor/proginova">
            Я хочу, чтобы доктор Н. назначил ПРОГИНОВА® всем пациенткам
            в программах ВРТ и смежных нишах.
          </ToDoctorCard>
        </Box>
      </StyledWrapperBox>

      <StyledBoxShadow sx={{mt: 5}}>
        <StyledHeader sx={{fontSize: 30, color: '#FFFFFF', fontWeight: 400}}>
          ОРИЕНТИРОВАННОСТЬ НА РЕЗУЛЬТАТ
        </StyledHeader>

        <Box sx={{display: 'flex', gap: '20px', mt: 6}}>
          <StyledInsetCircle sx={{width: 65, height: 60, p: '9px 11px 9px 7px'}}>
            <img src={icons.hand} alt="*"/>
          </StyledInsetCircle>
          <StyledBoxInsetShadow
            sx={{fontSize: 24, color: PinkLight, borderRadius: '60px', fontWeight: 600, p: '15px 30px'}}
          >
            ПРЕОДОЛЕНИЕ ВОЗРАЖЕНИЙ
          </StyledBoxInsetShadow>
        </Box>

        <Box sx={{fontWeight: 600, fontSize: 24, color: PinkLight, mt: 5, mb: 5}}>
          Стандарт работы с возражениями — 6 этапов
        </Box>

        <Box sx={{display: 'flex', gap: '20px', flexWrap: 'wrap'}}>
          <StyledBoxCircle>
            1. Пауза
          </StyledBoxCircle>
          <StyledBoxCircle>
            2. Активное слушание
          </StyledBoxCircle>
          <StyledBoxCircle>
            3. Позитивная реакция
          </StyledBoxCircle>
          <StyledBoxCircle>
            4. Уточнение
          </StyledBoxCircle>
          <StyledBoxCircle>
            5. Аргументация
          </StyledBoxCircle>
          <StyledBoxCircle>
            6. Подтверждение понимания
          </StyledBoxCircle>
        </Box>

        <Box sx={{display: 'flex', gap: '20px', mt: 6}}>
          <StyledInsetCircle sx={{width: 65, height: 60, p: '16px 7px'}}>
            <img src={icons.hands} alt="*"/>
          </StyledInsetCircle>
          <StyledBoxInsetShadow
            sx={{fontSize: 24, color: PinkLight, borderRadius: '60px', fontWeight: 600, p: '15px 30px'}}
          >
            ЗАКЛЮЧЕНИЕ ДОГОВОРЁННОСТЕЙ
          </StyledBoxInsetShadow>
        </Box>

        <StyledBoxBorder sx={{mt: 5, display: 'flex', gap: '20px'}}>
          <StyledCircle className="border" sx={{padding: '1px'}}>
            1
          </StyledCircle>
          <Box>
            <Box sx={{fontSize: 24, fontWeight: 700, mb: '10px'}}>«Конкретное количество пациенток»:</Box>
            <Box sx={{fontSize: 18}}>
              Кому из 6 пациенток, о которых мы говорили, Вы планируете назначить
              (будете рекомендовать) препарат X в течение следующего месяца?
            </Box>
          </Box>
        </StyledBoxBorder>
        <StyledBoxBorder sx={{mt: '20px', display: 'flex', gap: '20px'}}>
          <StyledCircle className="border" sx={{padding: '1px'}}>
            2
          </StyledCircle>
          <Box>
            <Box sx={{fontSize: 24, fontWeight: 700, mb: '10px'}}>«Препарат выбора»:</Box>
            <Box sx={{fontSize: 18}}>
              Можем ли мы быть уверенными, что при лечении пациенток с патологией Z
              вашим стартовым препаратом будет X?
            </Box>
          </Box>
        </StyledBoxBorder>
        <StyledBoxBorder sx={{mt: 5, display: 'flex', gap: '20px'}}>
          <StyledCircle className="border" sx={{padding: '1px'}}>
            3
          </StyledCircle>
          <Box>
            <Box sx={{fontSize: 24, fontWeight: 700, mb: '10px'}}>«Резюме»:</Box>
            <Box sx={{fontSize: 18}}>
              Мы пришли к единому мнению, что 50% пациенток с патологией Z имеют
              проблему Y. Можем ли мы быть уверенными, что все подобные пациентки
              будут получать именно препарат X?
            </Box>
          </Box>
        </StyledBoxBorder>
        <StyledBoxBorder sx={{mt: '20px', display: 'flex', gap: '20px'}}>
          <StyledCircle className="border" sx={{padding: '1px'}}>
            4
          </StyledCircle>
          <Box>
            <Box sx={{fontSize: 24, fontWeight: 700, mb: '10px'}}>«Утверждение»:</Box>
            <Box sx={{fontSize: 18}}>
              Итак, по итогам нашего разговора мы можем сделать заключение,
              что все 5 новых пациенток с патологией Z получат препарат X?
            </Box>
          </Box>
        </StyledBoxBorder>
        <StyledBoxBorder sx={{mt: '20px', display: 'flex', gap: '20px'}}>
          <StyledCircle className="border" sx={{padding: '1px'}}>
            5
          </StyledCircle>
          <Box>
            <Box sx={{fontSize: 24, fontWeight: 700, mb: '10px'}}> «На кончиках пальцев»:</Box>
            <Box sx={{fontSize: 18}}>
              Какое количество пациенток с патологией Z Вам необходимо пролечить,
              чтобы почувствовать уверенность в эффективности препарата X?
            </Box>
          </Box>
        </StyledBoxBorder>

        <Box sx={{display: 'flex', gap: '20px', mt: 7}}>
          <StyledInsetCircle sx={{width: 65, height: 60, p: '10px 4px 10px 7px'}}>
            <img src={icons.file_ok} alt="*"/>
          </StyledInsetCircle>
          <StyledBoxInsetShadow
            sx={{fontSize: 24, color: PinkLight, borderRadius: '60px', fontWeight: 600, p: '15px 30px'}}
          >
            ЗАВЕРШЕНИЕ ВИЗИТА
          </StyledBoxInsetShadow>
        </Box>

        <Box sx={{fontSize: 24, fontWeight: 600, mt: 5}}>
          Этот раздел несёт сервисную функцию. Это наш имидж и фактор
          для формирования дальнейших отношений с доктором.
        </Box>
        <Box sx={{fontSize: 18}}>
          <ol className="list">
            <li>Подвести итоги визита.</li>
            <li>Ещё раз проговорить все важные стороны визита.</li>
            <li>Закон восприятия говорит о том, что запоминаются первое и последнее. Поэтому можно проговорить ключевое
              сообщение ещё раз.
            </li>
            <li>Предоставить аптечный лист доктору.</li>
            <li>Узнать, какие аптеки посещают пациенты доктора.</li>
            <li>«Привязать» доктора к этим аптекам.</li>
          </ol>
        </Box>

        <StyledBoxBorder sx={{display: 'flex', gap: '20px', mt: 5}}>
          <StyledCircle sx={{padding: '4px'}}>
            <img src={icons.star} alt="*"/>
          </StyledCircle>
          <Box>
            <Box sx={{fontSize: 24, fontWeight: 700, mb: '10px', color: PinkLight}}>ПРИМЕРЫ:</Box>
            <Box sx={{fontSize: 18}}>
              <ul className="list">
                <li>Какие аптеки посещают Ваши пациенты?</li>
                <li>
                  Чтобы у Ваших пациентов, которым Вы назначили КЛИМОНОРМ®, была возможность
                  легко и быстро купить его, мне необходимо понимать, в какую аптеку заложить препарат.
                </li>
              </ul>
            </Box>
          </Box>
        </StyledBoxBorder>

        <Box sx={{display: 'flex', gap: '20px', mt: 7}}>
          <StyledInsetCircle sx={{width: 65, height: 60, p: '10px 4px 10px 7px'}}>
            <img src={icons.file_search} alt="*"/>
          </StyledInsetCircle>
          <StyledBoxInsetShadow
            sx={{fontSize: 24, color: PinkLight, borderRadius: '60px', fontWeight: 600, p: '15px 30px'}}
          >
            АНАЛИЗ ВИЗИТА
          </StyledBoxInsetShadow>
        </Box>

        <Box sx={{fontSize: 24, fontWeight: 600, mt: 4}}>
          Что фиксируем после визита:
        </Box>
        <Box sx={{fontSize: 18}}>
          <ol className="list">
            <li>Дата.</li>
            <li>Потенциал и Выписка (уточнённые).</li>
            <li>Нозология (Портрет пациента, который обсуждался).</li>
            <li>Актуальная клиническая проблема (обсуждённая на визите).</li>
            <li>Выгоды, которые обозначил врач.</li>
            <li>Возражения врача.</li>
            <li>Какие аргументы представляли и/или чьё мнение важно.</li>
            <li>Подписался ли врач на наш Телеграм-канал «Клуб хороших гинекологов».</li>
            <li>Подключился ли врач к проекту с QR-кодами.</li>
            <li>Конкуренты: актуальные для врача, профессиональные предпочтения, при какой нозологии.</li>
            <li>Социальная информация, персональная информация (если выявили что-то новое и полезное для дальнейшей работы).</li>
            <li>
              Свои обещания доктору и срок исполнения.
              Цель на следующий визит.
            </li>
            <li>Дата следующего визита.</li>
          </ol>
        </Box>

      </StyledBoxShadow>
    </Wrapper>
  )
}
