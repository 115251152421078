import alvizan from '../assets/img/products/alvizan.png';
import velledient from '../assets/img/products/velledient.png';
import vidora from '../assets/img/products/vidora.png';
import dieticlen from '../assets/img/products/dieticlen.png';
import eoeli from '../assets/img/products/eoeli.png';
import vidora_2 from '../assets/img/products/vidora_2.png';
import lactacyd from '../assets/img/products/lactacyd.png';

import microginon from '../assets/img/bayer/microginon.png';
import proginova from '../assets/img/bayer/proginova.png';
import klimen from '../assets/img/bayer/klimen.png';
import klimonorm from '../assets/img/bayer/klimonorm.png';

import Alv_2 from '../assets/img/productsPic/Alv_2.png';
import Vel_2 from '../assets/img/productsPic/Vel_2.png';
import Vidl_2 from '../assets/img/productsPic/Vidl_2.png';
import Die_2 from '../assets/img/productsPic/Die_2.png';
import Zoe_2 from '../assets/img/productsPic/Zoe_2.png';
import Liz_2 from '../assets/img/productsPic/Liz_2.png';

export const products = [
  {
    id: 'alvovizan/modal',
    name: 'Алвовизан',
    img: alvizan,
    subtitle: 'Диеногест 2 мг',
    picture: Alv_2,
    description:
      'Алвовизан эффективен в лечении эндометриоза, как аГнРг, считающиеся стандартом терапии данного заболевания. Препарат обладает отличным профилем безопасности и переносимости, поэтому предназначен для длительного применения.',
    materials: [
      {
        name: 'Эффективность и безопасность',
        pdfName: 'alvizan_p_new',
        forceVertical: false,
      },
      {
        name: 'Буклет Диеногест VS AГНРГ',
        pdfName: 'alvizan_m_1',
        forceVertical: false,
      },
      {
        name: 'Буклет Диеногест VS хирургическое вмешательство',
        pdfName: 'alvizan_m_2_new',
        forceVertical: false,
      },
      {
        name: 'Буклет Диеногест VS КОК',
        pdfName: 'alvizan_m_3',
        forceVertical: false,
      },
      {
        name: 'Буклет Диеногест в лечении тазовой боли, связанной с эндометриозом',
        pdfName: 'alvizan_m_4',
        forceVertical: false,
      },
      {
        name: 'Буклет Исследование биоэквивалентности',
        pdfName: 'alvizan_m_5',
        forceVertical: false,
      },
      {
        name: 'Буклет Применение Диеногеста при длительном лечении эндометриоза',
        pdfName: 'alvizan_m_6',
        forceVertical: false,
      },
      {
        name: 'Буклет Протоколы лечения эндометриоза. российские и международные',
        pdfName: 'alvizan_m_7',
        forceVertical: false,
      },
    ],
    prezentations: [
      {
        name: 'Алвовизан 1',
        pdfName: 'alvizan_p_m_1_new',
        forceVertical: false,
      },
      {
        name: 'Алвовизан 2',
        pdfName: 'alvizan_p_m_2_new',
        forceVertical: false,
      },
      {
        name: 'Алвовизан 3',
        pdfName: 'alvizan_p_m_3_new',
        forceVertical: false,
      },
    ],
    instructions: [
      {
        name: 'Инструкция',
        pdfName: 'alvizan_instrr',
        forceVertical: false,
      },
      {
        name: 'Инструкция с микронизированным диеногестом',
        pdfName: 'alvizan_instr_new',
        forceVertical: false,
      },
      {
        name: 'Только у Алвовизана и Визанны разрешено применение с 12 лет!',
        pdfName: 'alvizan_instr_12',
        forceVertical: false,
      },
    ],
  },
  {
    id: 'velledien',
    name: 'Велледиен',
    img: velledient,
    picture: Vel_2,
    subtitle: 'Тиболон 2,5 мг',
    description:
      'Лечение симптомов эстрогенной недостаточности у женщин в постменопаузальном периоде (не ранее\n' +
      'чем через 1 год после последней менструации при наступлении естественной менопаузы или сразу после хирургической менопаузы.',
    materials: [
      {
        name: 'Буклет Велледиен (Тиболон)',
        pdfName: 'Buklet_Velledien_Tibolon',
        forceVertical: false,
      },
      {
        name: 'Велледиен. Клинический случай №1',
        pdfName: 'Velledien_Klinicheskij_sluchaj_1_new',
        forceVertical: false,
      },
      {
        name: 'Велледиен. Клинический случай №2',
        pdfName: 'Velledien_Klinicheskij_sluchaj_2_new',
        forceVertical: false,
      },
      {
        name: 'Велледиен. Клинический случай №3',
        pdfName: 'Velledien_Klinicheskij_sluchaj_3_new',
        forceVertical: false,
      },
      {
        name: 'Велледиен. Клинический случай №4',
        pdfName: 'Velledien_Klinicheskij_sluchaj_4_new',
        forceVertical: false,
      },
    ],
    prezentations: [
      {
        name: 'Велледиен',
        pdfName: 'Velledien_m',
        forceVertical: false,
      },
    ],
    instructions: [
      {
        name: 'Велледиен_ИМП',
        pdfName: 'Velledien_imp',
        forceVertical: false,
      },
    ],
  },
  {
    id: 'vidora',
    name: 'Видора',
    img: vidora,
    picture: Vidl_2,
    subtitle: 'Дроспиренон 3 мг+ ЭЭ 0,03 мг',
    description:
      'Для всех женщин репродуктивного возраста, кому важна безопасность препарата при его\n' +
      'доступной цене.',
    addedDescription: {
      name: 'Видора Микро',
      img: vidora_2,
      subtitle: 'Дроспиренон 3 мг+ ЭЭ 0,02 мг',
      description:
        'Контрацепция, лечение угревой сыпи средней тяжести и лечение тяжелой формы предменструального синдрома (ПМС).',
    },
    materials: [
      {
        name: 'Видора Микро 21+7_клинический разбор',
        pdfName: 'vid_m_1_new',
        forceVertical: false,
      },
      {
        name: 'Видора Микро 24+4_клинический разбор',
        pdfName: 'vid_m_2_new',
        forceVertical: false,
      },
      {
        name: 'Видора_клинический разбор',
        pdfName: 'vid_m_3_new',
        forceVertical: false,
      },
      {
        name: 'Исакова_Видора Микро_клинический разбор',
        pdfName: 'vid_m_4_new',
        forceVertical: false,
      },
      {
        name: 'Исакова_Видора_клинический разбор',
        pdfName: 'vid_m_5_new',
        forceVertical: false,
      },
    ],
    prezentations: [
      {
        name: 'Видора',
        pdfName: 'vid_p_m_1',
        forceVertical: false,
      },
    ],
    instructions: [
      {
        name: 'Видора микро_обновленная ИМП № ЛП-002881-100820',
        pdfName: 'vidara_micro',
        forceVertical: false,
      },
      {
        name: 'Видора_обновленная инструкция_23.09.2020',
        pdfName: 'vidara_micro_2',
        forceVertical: false,
      },
    ],
  },
  {
    id: 'diecyclen',
    // id: 'dieticlen',
    name: 'Диециклен',
    img: dieticlen,
    picture: Die_2,
    subtitle: 'Диеногест 2 мг + ЭЭ 0,03 мг',
    description:
      'Лечение акне легкой и средней степени тяжести у женщин, нуждающихся в контрацепции, при неэффективности местного лечения.',
    materials: [
      {
        name: 'Диециклен_клинический разбор',
        pdfName: 'diec_m_1_new',
        forceVertical: false,
      },
      {
        name: 'Исакова_Диециклен_клинический разбор',
        pdfName: 'diec_m_2_new',
        forceVertical: false,
      },
    ],
    prezentations: [
      {
        name: 'Диециклен',
        pdfName: 'diec_m_pppp',
        forceVertical: false,
      },
    ],
    instructions: [
      {
        name: 'Диециклен_ИМП',
        pdfName: 'diec_imp',
        forceVertical: false,
      },
    ],
  },
  {
    id: 'eoeli',
    name: 'Зоэли',
    img: eoeli,
    picture: Zoe_2,
    subtitle: 'Номегэстрол + эстрадиол',
    description:
      'Инновационный КОК:\n' +
      'первый монофазный эстрадиол, содержащий комбинированный оральный контрацептив.',
    materials: [
      {
        name: 'ZOELY исследования (Канада)',
        pdfName: 'Australian-Zoely-Sales-Aid',
        forceVertical: false,
      },
      {
        name: 'ZOELY исследования (Австралия)',
        pdfName: 'Zoely-Sales-Aid-UK_ru',
        forceVertical: false,
      },
      {
        name: 'Конкурентный анализ Зоэли vs. Эстретта',
        pdfName: 'competitive_analysis_zoely_vs_estretta',
        forceVertical: false,
      },
    ],
    prezentations: [
      {
        name: 'Зоэли',
        pdfName: 'eol_m_new',
        forceVertical: false,
      },
    ],
    instructions: [
      {
        name: 'Зоэли ИМП',
        pdfName: 'eol_in',
        forceVertical: false,
      },
    ],
  },
  {
    id: 'klomonorm',
    name: 'Климонорм',
    img: klimonorm,
    subtitle: '',
    description: '',
    materials: [
      {
        name: 'Проандрогенное действие Климонорм',
        pdfName: 'proandrogenic-effect-of-Klimonorm',
        forceVertical: false,
      },
      {
        name: 'Выводы 39-го Международного Конгресса ЕОРЧиЭ',
        pdfName: 'conclusions-international-congress',
        forceVertical: false,
      },
      {
        name: 'Анти-лнг fake от Эбботт',
        pdfName: 'anti-lng-fake-from-abbott',
        forceVertical: false,
      },
      {
        name: 'Менопаузальная гормональная терапия',
        pdfName: 'klimonorm_prezentation_sogl',
        forceVertical: false,
      },
      {
        name: 'Климонорм - лифлет',
        pdfName: 'klimonorm_liflet_practic',
        forceVertical: false,
      },
      {
        name: 'QR ZWH ТГ для врачей',
        pdfName: 'tg_for_doctors',
        forceVertical: false,
      },
      {
        name: 'Клуб хороших гинекологов',
        pdfName: 'club_gynecologists_v2',
        forceVertical: false,
      },
      {
        name: 'Презентация Хочу андрогены',
        pdfName: 'want_androgen',
        forceVertical: false,
      },
    ],
    prezentations: [],
    instructions: [],
    videos: [
      {
        name: 'Нормативные документы',
        pdfName: 'YcW8ub71aVs',
      },
      {
        name: 'Гормонофобия',
        pdfName: '29pJCXJRMxU',
      },
      {
        name: 'При ПНЯ нужно назначить Климонорм!',
        pdfName: 'eR7USXXidXk',
      },
      {
        name: 'По протоколам 2 мг! Пациенткам без матки Прогинову',
        pdfName: 'aoMCl-p4we4',
      },
      {
        name: 'Климонорм при ПНЯ в сочетании с мастопатией',
        pdfName: 'PQUzQSpDxR0',
      },
      {
        name: 'Климонорм для улучшения когнитивных функций',
        pdfName: 'UL53uGLt3JI',
      },
      {
        name: 'Климонорм — волшебная пуля для тусклого либидо',
        pdfName: 'QzF43oeVHn8',
      },
      {
        name: 'Климонорм и мышечная ткань',
        pdfName: '0BHmmrzxjV0',
      },
      {
        name: 'Климонорм VS. Фемостон',
        pdfName: '_gDLrKd536U',
      },
    ],
  },
  {
    id: 'microginon',
    name: 'Микрогинон',
    img: microginon,
    picture: Liz_2,
    subtitle: 'Левоноргестрел 150 мкг\n' + '+ ЭЭ30 мкг',
    description:
      'Для женщин с обильными\n' + 'менструациями и дисфункцией яичников.',
    materials: [],
    prezentations: [
      {
        name: 'Микрогинон',
        pdfName: 'microginon_new',
        forceVertical: false,
      },
    ],
    instructions: [
      {
        name: 'Инструкция',
        pdfName: 'microginon_instr',
        forceVertical: false,
      },
    ],
  },
  {
    id: 'proginova/modal',
    name: 'Прогинова',
    img: proginova,
    picture: Liz_2,
    subtitle: 'Эстрадиола валерат 2 мг',
    description:
      'Оптимальное средство коррекции уровня эстрогенов с доказанной эффективностью и безопасностью.',
    materials: [],
    prezentations: [],
    instructions: [],
    videos: [
      {
        name: 'Год семьи 2024 — Прогинова',
        pdfName: 'JmA7RXfagzQ',
      },
    ],
  },
  {
    id: 'lactacyd',
    name: 'Лактацид',
    img: lactacyd,
    picture: null,
    subtitle: 'Лактацид',
    description: 'Лактацид',
    materials: [],
    prezentations: [],
    instructions: [],
  },
];

export const bayer = [
  {
    name: 'Климен',
    img: klimen,
    subtitle: 'Ципротерона ацетат 1 мг+ ЭВ 2 мг',
    description:
      'Единственный препарат МГТ с лечебными преимуществами для пациенток с гиперандрогенией, приводящей к эстетической деформации.',
  },
] as const;
