import {Box} from '@mui/material';

import {StyledAnchorLink, StyledHeader} from '../ui';
import {StyledBoxBorder, StyledBoxInsetShadow, StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {Ticket} from './ticket';
import {HeaderTabs as Tabs} from './header-tabs';

type HeaderTabsProps = {
  tabActive: number
}
const HeaderTabs = ({tabActive}: HeaderTabsProps) => <Tabs tabActive={tabActive} tabLength={3}/>
export const SubpageKlimonorm = () => {
  return (
    <>
      <StyledHeader>Климонорм®</StyledHeader>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <StyledAnchorLink href="#theme1">
          <img src={icons.arrow} alt="*"/>
          Тема #1: Перевод пациенток, принимающих в качестве МГТ Фемостон 1/10 на Климонорм
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme2">
          <img src={icons.arrow} alt="*"/>
          Тема #2: Перевод пациенток, принимающих в качестве МГТ Фемостон 2/10 на Климонорм
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme3">
          <img src={icons.arrow} alt="*"/>
          Тема #3: Перевод пациенток с ПНЯ, принимающих в качестве ЗГТ Фемостон 2/10 на Климонорм
        </StyledAnchorLink>
      </Box>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <HeaderTabs tabActive={0}/>
        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток, принимающих в качестве МГТ Фемостон 1/10 на Климонорм
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила КЛИМОНОРМ в течение месяца 20 пациенткам в перименопаузе с целью сохранения
                здоровья вместо Фемостон 1/10
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна! Медицинский представитель компании «Зентива» (имя). Уделите мне
                  несколько минут для обсуждения пациенток, возраст которых 45+.”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container" sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  <ol className="list">
                    <li>
                      Как много у Вас на приёме сейчас пациенток в возрасте 45+?
                    </li>
                    <li>
                      С какой патологией чаще обращаются пациентки в возрасте 45+?
                    </li>
                  </ol>
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <ol className="list">
                  <li>
                    Скажите, рекомендуете ли вы женщинам в период менопаузального перехода МГТ? И что конкретно
                    назначаете?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы препарат не только устранял приливы, но и защищал кости, сердце,
                    мозг, урогенитальный тракт?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы препарат не только устранял приливы, но и способствовал улучшению
                    настроения, повышению либидо и качества жизни пациентки?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы препарат не только устранял приливы, но и способствовал сохранению
                    когнитивных функций и профилактике болезни Альцгеймера?
                  </li>
                  <li>
                    Насколько для Вас важна возможность безопасного назначения МГТ пациенткам с множественными
                    миоматозными узлами и аденомиозом?
                  </li>
                  <li>Насколько для Вас важно при назначении МГТ оставаться в юридическом поле?</li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №1" img={icons.voice}>
            КЛИМОНОРМ® является оптимальным средством сохранения здоровья женщины в зрелом возрасте за счет преимуществ
            ЛНГ для профилактики кровотечений, остеопороза, сердечно-сосудистых заболеваний и сохранения когнитивных
            функций. Именно поэтому КЛИМОНОРМ® рекомендован как препарат № 1 для использования у пациенток в периоде
            менопаузального перехода в Протоколах Минздрава назначения МГТ 2016 года.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB I:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              В состав КЛИМОНОРМА входит 2 мг эстрадиола валерата, а по рекомендациям международных обществ по менопаузе
              именно 2 мг является минимальной эффективной дозой в периоде менопаузального перехода (45 лет — 52 года).
              А сочетание 2 мг эстрадиола валерата c ЛНГ даёт целый набор дополнительных преимуществ по профилактике
              остеопороза, сердечно-сосудистых заболеваний, последствий преждевременной недостаточности яичников,
              снижения когнитивных функций.
            </Box>
            <Box>
              Именно поэтому данная комбинация 2 мг эстрадиола валерата и 0,15 мг ЛНГ стоит на 1-м месте в протоколах
              МГТ для циклического приёма. Что дает Вам, доктор, все основания назначать КЛИМОНОРМ® большинству
              пациенток в возрасте 45 лет — 52 года.
            </Box>

          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №2" img={icons.voice}>
            Сочетание 2 мг эстрадиола валерата c ЛНГ в составе КЛИМОНОРМА® даёт целый набор дополнительных преимуществ
            по профилактике остеопороза, сердечно-сосудистых заболеваний, атрофии урогенитального тракта, последствий
            преждевременной недостаточности яичников, снижения когнитивных функций.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB II:" img={icons.star}>
            <Box>
              В состав КЛИМОНОРМА® входит 2 мг эстрадиола валерата, а по рекомендациям международных обществ по
              менопаузе именно 2 мг является минимальной эффективной дозой в периоде менопаузального перехода (45 лет —
              52 года). А сочетание 2 мг эстрадиола валерата c ЛНГ даёт целый набор дополнительных преимуществ по
              профилактике остеопороза, сердечно-сосудистых заболеваний, атрофии урогенитального тракта, последствий
              преждевременной недостаточности яичников, снижения когнитивных функций.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №3" img={icons.voice}>
            КЛИМОНОРМ® — единственный препарат Менопаузальной Гормональной Терапии для пациенток с астено-депрессивной
            симптоматикой (снижение когнитивных функций, снижение либидо, нарушение сна, вялость, подавленность
            настроения, снижение тургора кожи) за счёт остаточной андрогенной активности левоноргестрела.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB III:" img={icons.star}>
            <Box>
              Тревожно-депрессивное расстройство, которое ассоциировано со снижением либидо, классифицируется как
              гипоактивное расстройство сексуальной функции, что является одним из ведущих симптомов у пациенток с
              дефицитом половых гормонов и проявляется в период менопаузального перехода.
              2 мг эстрадиола валерата в составе КЛИМОНОРМА® позволяют купировать климактерические жалобы, а остаточная
              андрогенная активность ЛНГ позволяет дополнительно повысить качество жизни, улучшить когнитивные функции,
              работоспособность и сексуальное желание.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №4" img={icons.voice}>
            МГТ, начатая в среднем возрасте, способствует снижению риска болезни Альцгеймера и деменции. ЛНГ усиливает
            экспрессию эстрогеновых рецепторов и нейротропного фактора (BDNF), тем самым снижая интенсивность апоптоза и
            увеличивая выживаемость нейронов.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB IV:" img={icons.star}>
            <Box sx={{mb: '20px'}}>
              Недавние, можно сказать революционные исследования, опубликованные в авторитетных международных изданиях,
              продемонстрировали, что нейрогенез в гипокампе происходит всю жизнь. Другими словами, в гипокампе на
              протяжении всей жизни вновь синтезируются нейроны, ответственные за память, обучение, концентрацию
              внимания.
            </Box>
            <Box>
              Показано, что левоноргестрел усиливает экспрессию эстрогеновых рецепторов и BDNF (нейротропного фактора),
              тем самым снижая интенсивность апоптоза и увеличивая выживаемость нейронов. Что, в свою очередь при
              длительном применении снижает риск развития болезни Альцгеймера. Таким образом, назначая КЛИМОНОРМ® вместо
              Фемостон 2/10 пациенткам в перименопаузе, Вы способствуете сохранению когнитивных функций и профилактике
              болезни Альцгеймера.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №5" img={icons.voice}>
            При наличии у пациентки миоматозных узлов (3–4) размером до 3 см в диаметре или диффузного внутреннего
            эндометриоза I–II степени эксперты рекомендуют назначать МГТ с левоноргестрелом — КЛИМОНОРМ®.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB V:" img={icons.star}>
            <Box>
              При наличии у пациентки миоматозных узлов (3–4) размером до 3 см в диаметре, диффузного внутреннего
              эндометриоза I–II степени и при наличии в анамнезе гиперпластических процессов эксперты рекомендуют
              назначать МГТ с левоноргестрелом — КЛИМОНОРМ®, так как ЛНГ — самый сильный гестаген.
              внимания.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №6" img={icons.voice}>
            Комбинация 2 мг эстрадиола валерата и 0,15 мг ЛНГ стоит на 1-м месте в протоколах МГТ для циклического
            приёма. Что дает Вам, доктор, все основания назначать КЛИМОНОРМ® большинству пациенток в возрасте 45 лет —
            52 года, оставаясь в юридическом поле, так как это официальные Протоколы Минздрава.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB VI:" img={icons.star}>
            <Box sx={{mb: '20px'}}>
              В состав КЛИМОНОРМА входит 2 мг эстрадиола валерата, а по рекомендациям международных обществ по менопаузе
              именно 2 мг является минимальной эффективной дозой в периоде менопаузального перехода (45 лет — 52 года).
              А сочетание 2 мг эстрадиола валерата c ЛНГ даёт целый набор дополнительных преимуществ по профилактике
              остеопороза, сердечно-сосудистых заболеваний, последствий преждевременной недостаточности яичников,
              снижения когнитивных функций.
            </Box>

            <Box sx={{mb: '20px'}}>
              Эти преимущества КЛИМОНОРМА зафиксированы в инструкции.
            </Box>
            <Box sx={{mb: '20px'}}>
              Именно поэтому данная комбинация 2 мг эстрадиола валерата и 0,15 мг ЛНГ стоит на 1-м месте в протоколах
              МГТ для циклического приёма. Что дает Вам, доктор, все основания назначать КЛИМОНОРМ® большинству
              пациенток в возрасте 45 лет — 52 года, оставаясь в юридическом поле, так как это официальные Протоколы
              Минздрава.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme2" sx={{mt: 5}}>
        <HeaderTabs tabActive={1}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток, принимающих в качестве МГТ Фемостон 2/10 на Климонорм.
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила КЛИМОНОРМ в течение месяца 20 пациенткам в перименопаузе с целью сохранения
                здоровья вместо Фемостон 2/10
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна! Медицинский представитель компании «Зентива» (имя). Уделите мне
                  несколько минут для обсуждения пациенток, возраст которых 45+.”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container" sx={{flexDirection: 'column'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  <ol className="list">
                    <li>
                      Как много у Вас на приёме сейчас пациенток в возрасте 45+?
                    </li>
                    <li>
                      С какой патологией чаще обращаются пациентки в возрасте 45+?
                    </li>
                  </ol>
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box sx={{mb: '12px'}}>Примеры вопросов для определения выписки:</Box>
                <ol className="list">
                  <li>
                    Скажите, рекомендуете ли вы женщинам в период менопаузального перехода МГТ? И что конкретно
                    назначаете?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы препарат не только устранял приливы, но и защищал кости, сердце,
                    мозг, урогенитальный тракт?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы препарат не только устранял приливы, но и способствовал улучшению
                    настроения, повышению либидо и качества жизни пациентки?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы препарат не только устранял приливы, но и способствовал сохранению
                    когнитивных функций и профилактике болезни Альцгеймера?
                  </li>
                  <li>
                    Насколько для Вас важна возможность безопасного назначения МГТ пациенткам с множественными
                    миоматозными узлами и аденомиозом?
                  </li>
                  <li>Насколько для Вас важно при назначении МГТ оставаться в юридическом поле?</li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №1" img={icons.voice}>
            КЛИМОНОРМ® является оптимальным средством сохранения здоровья женщины в зрелом возрасте за счет преимуществ
            ЛНГ для профилактики кровотечений, остеопороза, сердечно-сосудистых заболеваний и сохранения когнитивных
            функций. Именно поэтому КЛИМОНОРМ® рекомендован как препарат № 1 для использования у пациенток в периоде
            менопаузального перехода в Протоколах Минздрава назначения МГТ 2016 года.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB I:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              В состав КЛИМОНОРМА входит 2 мг эстрадиола валерата, а по рекомендациям международных обществ по менопаузе
              именно 2 мг является минимальной эффективной дозой в периоде менопаузального перехода (45 лет — 52 года).
              А сочетание 2 мг эстрадиола валерата c ЛНГ даёт целый набор дополнительных преимуществ по профилактике
              остеопороза, сердечно-сосудистых заболеваний, последствий преждевременной недостаточности яичников,
              снижения когнитивных функций.
            </Box>
            <Box>
              Именно поэтому данная комбинация 2 мг эстрадиола валерата и 0,15 мг ЛНГ стоит на 1-м месте в протоколах
              МГТ для циклического приёма. Что дает Вам, доктор, все основания назначать КЛИМОНОРМ® большинству
              пациенток в возрасте 45 лет — 52 года.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №2" img={icons.voice}>
            Сочетание 2 мг эстрадиола валерата c ЛНГ в составе КЛИМОНОРМА® даёт целый набор дополнительных преимуществ
            по профилактике остеопороза, сердечно-сосудистых заболеваний, атрофии урогенитального тракта, последствий
            преждевременной недостаточности яичников, снижения когнитивных функций.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB II:" img={icons.star}>
            <Box>
              В состав КЛИМОНОРМА® входит 2 мг эстрадиола валерата, а по рекомендациям международных обществ по
              менопаузе именно 2 мг является минимальной эффективной дозой в периоде менопаузального перехода (45 лет —
              52 года). А сочетание 2 мг эстрадиола валерата c ЛНГ даёт целый набор дополнительных преимуществ по
              профилактике остеопороза, сердечно-сосудистых заболеваний, атрофии урогенитального тракта, последствий
              преждевременной недостаточности яичников, снижения когнитивных функций.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №3" img={icons.voice}>
            КЛИМОНОРМ® — единственный препарат Менопаузальной Гормональной Терапии для пациенток с астено-депрессивной
            симптоматикой (снижение когнитивных функций, снижение либидо, нарушение сна, вялость, подавленность
            настроения, снижение тургора кожи) за счёт остаточной андрогенной активности левоноргестрела.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB III:" img={icons.star}>
            <Box sx={{mb: '20px'}}>
              Тревожно-депрессивное расстройство, которое ассоциировано со снижением либидо, классифицируется как
              гипоактивное расстройство сексуальной функции, что является одним из ведущих симптомов у пациенток с
              дефицитом половых гормонов и проявляется в период менопаузального перехода.
            </Box>
            <Box>
              2 мг эстрадиола валерата в составе КЛИМОНОРМА® позволяют купировать климактерические жалобы, а остаточная
              андрогенная активность ЛНГ позволяет дополнительно повысить качество жизни, улучшить когнитивные функции,
              работоспособность и сексуальное желание.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №4" img={icons.voice}>
            МГТ, начатая в среднем возрасте, способствует снижению риска болезни Альцгеймера и деменции. ЛНГ усиливает
            экспрессию эстрогеновых рецепторов и нейротропного фактора (BDNF), тем самым снижая интенсивность апоптоза и
            увеличивая выживаемость нейронов.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB IV:" img={icons.star}>
            <Box sx={{mb: '20px'}}>
              Недавние, можно сказать революционные исследования, опубликованные в авторитетных международных изданиях,
              продемонстрировали, что нейрогенез в гипокампе происходит всю жизнь. Другими словами, в гипокампе на
              протяжении всей жизни вновь синтезируются нейроны, ответственные за память, обучение, концентрацию
              внимания.
            </Box>
            <Box>
              Показано, что левоноргестрел усиливает экспрессию эстрогеновых рецепторов и BDNF (нейротропного фактора),
              тем самым снижая интенсивность апоптоза и увеличивая выживаемость нейронов. Что, в свою очередь при
              длительном применении снижает риск развития болезни Альцгеймера. Таким образом, назначая КЛИМОНОРМ® вместо
              Фемостон 2/10 пациенткам в перименопаузе, Вы способствуете сохранению когнитивных функций и профилактике
              болезни Альцгеймера.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №5" img={icons.voice}>
            При наличии у пациентки миоматозных узлов (3–4) размером до 3 см в диаметре или диффузного внутреннего
            эндометриоза I–II степени эксперты рекомендуют назначать МГТ с левоноргестрелом — КЛИМОНОРМ®.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB V:" img={icons.star}>
            <Box>
              При наличии у пациентки миоматозных узлов (3–4) размером до 3 см в диаметре, диффузного внутреннего
              эндометриоза I–II степени и при наличии в анамнезе гиперпластических процессов эксперты рекомендуют
              назначать МГТ с левоноргестрелом — КЛИМОНОРМ®, так как ЛНГ — самый сильный гестаген.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №6" img={icons.voice}>
            комбинация 2 мг эстрадиола валерата и 0,15 мг ЛНГ стоит на 1-м месте в протоколах МГТ для циклического
            приёма. Что дает Вам, доктор, все основания назначать КЛИМОНОРМ® большинству пациенток в возрасте 45 лет —
            52 года, оставаясь в юридическом поле, так как это официальные Протоколы Минздрава.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB VI:" img={icons.star}>
            <Box sx={{mb: '20px'}}>
              В состав КЛИМОНОРМА входит 2 мг эстрадиола валерата, а по рекомендациям международных обществ по менопаузе
              именно 2 мг является минимальной эффективной дозой в периоде менопаузального перехода (45 лет — 52 года).
              А сочетание 2 мг эстрадиола валерата c ЛНГ даёт целый набор дополнительных преимуществ по профилактике
              остеопороза, сердечно-сосудистых заболеваний, последствий преждевременной недостаточности яичников,
              снижения когнитивных функций.
            </Box>

            <Box sx={{mb: '20px'}}>
              Эти преимущества КЛИМОНОРМА зафиксированы в инструкции.
            </Box>
            <Box sx={{mb: '20px'}}>
              Именно поэтому данная комбинация 2 мг эстрадиола валерата и 0,15 мг ЛНГ стоит на 1-м месте в протоколах
              МГТ для циклического приёма. Что дает Вам, доктор, все основания назначать КЛИМОНОРМ® большинству
              пациенток в возрасте 45 лет — 52 года, оставаясь в юридическом поле, так как это официальные Протоколы
              Минздрава.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme3" sx={{mt: 5}}>
        <HeaderTabs tabActive={2}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток с ПНЯ, принимающих в качестве ЗГТ Фемостон 2/10 на Климонорм
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила КЛИМОНОРМ в течение месяца 20 пациенткам с ПНЯ Климонорм в качестве ЗГТ
                вместо Фемостон 2/10
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна! Медицинский представитель компании «Зентива» (имя). Уделите мне
                  несколько минут для обсуждения пациенток с ПНЯ.”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container" sx={{flexDirection: 'column'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  <ol className="list">
                    <li>
                      Как много у Вас на приёме сейчас пациенток с ПНЯ?
                    </li>
                    <li>
                      Многие из Ваших коллег жалуются, что после начала эпидемии ковид на приеме возросло число молодых
                      пациенток с ПНЯ, а как у Вас?
                    </li>
                  </ol>
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <ol className="list">
                  <li>
                    Назначаете ли Вы пациенткам с ПНЯ заместительную гормональную терапию? Что именно?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы пациентка с ПНЯ восполняла не только дефицит эстрогенов, но и дефицит
                    андрогенов, которые сопровождают данную патологию, для того, чтобы снизить риск сердечно-сосудистых
                    заболеваний, остеопороза, снижения когнитивной функции, деменции и паркинсонизма?
                  </li>
                  <li>Насколько для Вас важно при назначении ЗГТ пациенткам с ПНЯ оставаться в юридическом поле?</li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №1 и №2" img={icons.voice} textTransform="none!important">
            На основании рекомендаций IMS (Международного общества по менопаузе) 2016 года именно применение
            эстроген-прогестагенных препаратов, которое должно продолжаться до среднего возраста естественной менопаузы,
            является основой лечения ПНЯ. Нелеченная ПНЯ повышает риск сердечно-сосудистых заболеваний, остеопороза,
            снижения когнитивной функции, деменции и паркинсонизма.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB I и II:" img={icons.star} textTransform="none!important">
            <Box sx={{mb: '12px'}}>
              Левоноргестрел, обладая остаточной андрогенной активностью, является прогестагеном выбора для пациенток с
              ПНЯ, так как ПНЯ
              отличается от естественной менопаузы тем, что резко снижается секреция и эстрогенов, и андрогенов.
            </Box>
            <Box>
              На основании рекомендаций IMS (Международного общества по менопаузе) 2016 года именно применение
              эстроген-прогестагенных препаратов, которое должно продолжаться до среднего возраста естественной
              менопаузы, является основой лечения ПНЯ. Нелеченная ПНЯ повышает риск сердечно-сосудистых заболеваний,
              остеопороза, снижения когнитивной функции, деменции и паркинсонизма. Препаратом выбора является КЛИМОНОРМ®
              за счёт остаточной андрогенности ЛНГ.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №3" img={icons.voice}>
            На основании рекомендаций IMS (Международного общества по менопаузе) 2016 года именно применение
            эстроген-прогестагенных препаратов, которое должно продолжаться до среднего возраста естественной менопаузы,
            является основой лечения ПНЯ. Нелеченная ПНЯ повышает риск сердечно-сосудистых заболеваний, остеопороза,
            снижения когнитивной функции, деменции и паркинсонизма. Препаратом выбора является КЛИМОНОРМ® за счёт
            остаточной андрогенности ЛНГ и зарегистрированного показания: показать доктору инструкцию (только у
            КЛИМОНОРМА® есть в инструкции ПНЯ).
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB III:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              Левоноргестрел, обладая остаточной андрогенной активностью, является прогестагеном выбора для пациенток с
              ПНЯ, так как ПНЯ отличается от естественной менопаузы тем, что резко снижается секреция и эстрогенов, и
              андрогенов.
            </Box>
            <Box sx={{mb: '12px'}}>
              При этом на основании рекомендаций IMS (Международного общества по менопаузе) 2016 года именно применение
              эстроген-прогестагенных препаратов, которое должно продолжаться до среднего возраста естественной
              менопаузы, является основой лечения ПНЯ. Нелеченная ПНЯ повышает риск сердечно-сосудистых заболеваний,
              остеопороза, снижения когнитивной функции, деменции и паркинсонизма. Таким образом, препаратом выбора
              является КЛИМОНОРМ® за счёт остаточной андрогенности ЛНГ.
            </Box>
            <Box>
              Показание ПНЯ отражено в инструкции. Таким образом, назначая КЛИМОНОРМ® пациенткам с ПНЯ Вы остаётесь в
              юридическом поле.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>
    </>
  )
}
