import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const Unus = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>
        Menopause hormone therapy: latest developments and clinical practice
      </H1>
      <P>
        Menopause hormone therapy (MHT) is the most efficient treatment for symptoms of acute climacteric syndrome and
        for efficient prevention of long-term estrogen deficiency. Vaginal administration of low doses of estrogen is a
        therapy of choice for treatment and prevention of urogenital atrophy and its consequences. Systemic treatment
        may include estrogen, but an equally efficient alternative is tibolone. Nonhormonal therapy relies on
        phytoestrogens, black cohosh extract, and serotonin reuptake inhibitors.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/30636965/">https://pubmed.ncbi.nlm.nih.gov/30636965/</A>]
      </P>
    </Page>
  )
}
