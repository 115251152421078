import Page from 'shared/ui/Page';

import {StyledParagraph as P, Link as A} from './ui';
import {H1} from '../ui';
import {PinkLight} from '../../../styles/color';

export const VelledienLinkSix = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Menopausal Hormone Therapy and Long-term All-Cause and Cause-Specific Mortality: The Women's Health Initiative Randomized Trials</H1>
      <P><b>Importance:</b> Health outcomes from the Women's Health Initiative Estrogen Plus Progestin and Estrogen-Alone Trials have been reported, but previous publications have generally not focused on all-cause and cause-specific mortality.</P>
      <P><b>Objective:</b> To examine total and cause-specific cumulative mortality, including during the intervention and extended postintervention follow-up, of the 2 Women's Health Initiative hormone therapy trials.</P>
      <P><b>Design, setting, and participants:</b> Observational follow-up of US multiethnic postmenopausal women aged 50 to 79 years enrolled in 2 randomized clinical trials between 1993 and 1998 and followed up through December 31, 2014.</P>
      <P><b>Interventions:</b> Conjugated equine estrogens (CEE, 0.625 mg/d) plus medroxyprogesterone acetate (MPA, 2.5 mg/d) (n = 8506) vs placebo (n = 8102) for 5.6 years (median) or CEE alone (n = 5310) vs placebo (n = 5429) for 7.2 years (median).</P>
      <P><b>Main outcomes and measures:</b> All-cause mortality (primary outcome) and cause-specific mortality (cardiovascular disease mortality, cancer mortality, and other major causes of mortality) in the 2 trials pooled and in each trial individually, with prespecified analyses by 10-year age group based on age at time of randomization.</P>
      <P><b>Results:</b> Among 27 347 women who were randomized (baseline mean [SD] age, 63.4 [7.2] years; 80.6% white), mortality follow-up was available for more than 98%. During the cumulative 18-year follow-up, 7489 deaths occurred (1088 deaths during the intervention phase and 6401 deaths during postintervention follow-up). All-cause mortality was 27.1% in the hormone therapy group vs 27.6% in the placebo group (hazard ratio [HR], 0.99 [95% CI, 0.94-1.03]) in the overall pooled cohort; with CEE plus MPA, the HR was 1.02 (95% CI, 0.96-1.08); and with CEE alone, the HR was 0.94 (95% CI, 0.88-1.01). In the pooled cohort for cardiovascular mortality, the HR was 1.00 (95% CI, 0.92-1.08 [8.9 % with hormone therapy vs 9.0% with placebo]); for total cancer mortality, the HR was 1.03 (95% CI, 0.95-1.12 [8.2 % with hormone therapy vs 8.0% with placebo]); and for other causes, the HR was 0.95 (95% CI, 0.88-1.02 [10.0% with hormone therapy vs 10.7% with placebo]), and results did not differ significantly between trials. When examined by 10-year age groups comparing younger women (aged 50-59 years) to older women (aged 70-79 years) in the pooled cohort, the ratio of nominal HRs for all-cause mortality was 0.61 (95% CI, 0.43-0.87) during the intervention phase and the ratio was 0.87 (95% CI, 0.76-1.00) during cumulative 18-year follow-up, without significant heterogeneity between trials.</P>
      <P><b>Conclusions and relevance:</b> Among postmenopausal women, hormone therapy with CEE plus MPA for a median of 5.6 years or with CEE alone for a median of 7.2 years was not associated with risk of all-cause, cardiovascular, or cancer mortality during a cumulative follow-up of 18 years.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/28898378/">Manson JE, et al.; WHI Investigators. Menopausal Hormone Therapy and Long-term All-Cause and Cause-Specific Mortality: The Women's Health Initiative Randomized Trials. JAMA. 2017 Sep 12;318(10):927-938.</A>]
      </P>
    </Page>
  )
}
