import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';
import pdf1 from '../../../pdfs/klimonorm/менопаузальная_гормонотерапия_и_сохранение_здоровья_женщин_в_зрелом_возрасте.pdf';

export const KlomonormLinkThree = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Менопаузальная гормонотерапия и сохранение здоровья женщин в зреломвозрасте</H1>
      <P sx={{color: PinkLight, fontSize: 20}}>[письмо Минздрава РФ от 2 октября 2015 г. № 15-4/10/2-5804]</P>
      <P>Клинические рекомендации (протокол лечения)
        «Менопаузальная гормонотерапия и сохранение
        здоровья женщин в зрелом возрасте»
        (утв. президентом Российского общества
        акушеров-гинекологов)
      </P>
      <a
        href={pdf1}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '35px 0 40px',
        }}
      >
        Статья PDF
      </a>
      <H1 sx={{color: PinkLight}}>
        Levonorgestrel and 17beta-estradiol given transdermally for the prevention of postmenopausal osteoporosis
      </H1>
      <P>Aim: To evaluate the efficacy and safety of a new transdermal continuous combined hormone replacement therapy (HRT) for the prevention of postmenopausal osteoporosis.</P>
      <P>Methods: 212 osteopenic (lumbar spine and/or hip (femoral neck) bone mineral density (BMD) between -1.0 and -2.5 S.D. of the premenopausal mean value) postmenopausal women aged 45-65 years participated in a 2-year prospective study. Treatments were 45 microg 17beta-estradiol combined with 30 (n = 69) or 40 microg (n = 72) levonorgestrel daily or placebo (n = 71) given as a 7-day patch. All received a daily supplement of 500 mg calcium. BMD at lumbar spine (L2-L4), hip and total body, as well as blood and urinary biochemical markers of bone turnover (serum osteocalcin (sOC), serum bone-specific alkaline phosphatase (sBSAP), urinary calcium (uCa) and urinary CrossLaps (uCTX)) were measured regularly.</P>
      <P>Results: BMD at the lumbar spine, hip and total body increased by 8, 6 and 3% (P `{'<'}` 0.001), respectively, in the hormone groups versus placebo. The bone markers all decreased accordingly (sOC: 37%, sBSAP: 34% and uCTX: 65% from baseline (all P `{'<'}` 0.001)), except for uCa that did not change significantly. No significant dose-related effect of levonorgestrel was found. Vaginal bleeding/spotting decreased from 48 to 25% of the HRT-treated women during the study period. Skin tolerance was good in 84% of the women with no difference between the study groups. No incidences of endometrial hyperplasia, uterine or mammary cancer occurred.</P>
      <P>Conclusion: The transdermal combination of 17beta-estradiol and levonorgestrel has a positive effect on BMD in an osteopenic postmenopausal population. Furthermore, a high safety profile was observed.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/15653003/">Warming L., Raven P., Christiansen C. Levonorgestrel and 17 beta-estradiol given transdermal for the prevention of postmenopausal osteoporosis. Maturitas. 2005 Feb 14; 50(2): 78-8</A>]
      </P>
    </Page>
  )
}
