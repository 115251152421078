import Page from 'shared/ui/Page';

import {Paragraph as P, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanThirteenLinkSix = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>Angioni S et al. Gynecol Endocrinol 2015;31:406 408</H1>
      <P>[ссылка: Angioni S et al. Gynecol Endocrinol 2015;31:406 408]</P>
    </Page>
  )
}
