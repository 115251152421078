import {styled} from '@mui/system';
import {Box} from '@mui/material';
import {Black, PinkLight} from '../../../../styles/color';

export const StyledWrapperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  font-size: 18px;
  font-family: 'SF Pro Text', sans-serif;
  font-weight: 400;
  color: #FFFFFF;
  
  & .title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.3px;
    text-align: center;
    padding: 16px;
  }

  & .tb {
    box-shadow: 1px 0 0 0 #888, 0 1px 0 0 #888, 1px 1px 0 0 #888, 1px 0 0 0 #888 inset, 0 1px 0 0 #888 inset;
  }

  & .tb-th {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.1px;
    color: ${Black};
    box-shadow: 1px 0 0 0 #888, 0 1px 0 0 #888, 1px 1px 0 0 #888, 1px 0 0 0 #888 inset, 0 1px 0 0 #888 inset;
    background-color: ${PinkLight};
    padding: 8px;
    text-align: center;
    
    
    &_addition {
      box-shadow: 1px 0 0 0 #888, 0 1px 0 0 #888, 1px 1px 0 0 #888, 1px 0 0 0 #888 inset, 0 1px 0 0 #888 inset;
      padding: 8px;
      text-align: center;
    }
  }
  
  & .tb-row {
    box-shadow: 1px 0 0 0 #888, 0 1px 0 0 #888, 1px 1px 0 0 #888, 1px 0 0 0 #888 inset, 0 1px 0 0 #888 inset;
    display: flex;
  }

  & .tb-cell {
    padding: 8px;
    box-shadow: 1px 0 0 0 #888, 0 1px 0 0 #888, 1px 1px 0 0 #888, 1px 0 0 0 #888 inset, 0 1px 0 0 #888 inset;
    flex: 1;
  }

  & .bg-light {
    background-color: #F7E5E0;
    color: ${Black};
  }

  & .bg-gray {
    background-color: rgba(249, 210, 214, 0.2);
  }

  & .bg-pink {
    background-color: rgba(249, 210, 214, 0.6);
  }

  & .center {
    text-align: center;
  }

  & .size-1 {
    width: 125px;
    flex: none;
    box-sizing: border-box;
  }
  
  & .size-2 {
    width: 200px;
    flex: none;
    box-sizing: border-box;
  }
  & .size-3 {
    width: 350px;
    flex: 1;
    box-sizing: border-box;
  }
  
  & .stage-box{
    display: flex;
    background-color: ${PinkLight};
    color: ${Black};
    padding: 10px;
  }

  & .list {
    padding-left: 30px;

    li {
      margin-bottom: 10px;
    }
  }
`
export const StyledLink = styled('a')`
  padding: 10px 30px;
  border: 1px solid #FFFFFF;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  line-height: 18px;
  font-size: 16px;
  font-family: "SF Pro Text", sans-serif;
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.1px;
  background-color: ${Black};
  color: #FFFFFF;
  display: block;
  text-align: center;

  &:hover {
    background-color: ${PinkLight};
    color: ${Black};
  }
`

export const StyledBoxCircle = styled(Box)`
  border: 1px solid;
  border-radius: 60px;
  text-align: center;
  color: ${PinkLight};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.1px;
  padding: 10px 30px;
  background-color: ${Black};
  flex: 1;
`