import {useHistory} from 'react-router-dom'
import Page from 'shared/ui/Page';
import {Tables} from 'shared/ui'
import main from './img/main.png';
import {StyledLink} from '../ui';
import arrow from '../img/arrow.svg';
import icon from './img/icon.png';
import icon2 from './img/icon2.png';
import icon3 from './img/icon3.png';
import icon4 from './img/icon4.png';
import icon5 from './img/icon5.png';
import step1 from './img/step1.png';
import step2 from './img/step2.png';
import mainPhone from './img/main-phone.png';
import s1 from './img/s1.png';
import s2 from './img/s2.png';
import s3 from './img/s3.png';
import s4 from './img/s4.png';
import s5 from './img/s5.png';
import s6 from './img/s6.png';
import s7 from './img/s7.png';
import s8 from './img/s8.png';
import s9 from './img/s9.png';
import s10 from './img/s10.png';
import bg_footer from './img/bg-footer.png';
import hand_qr from './img/hand-qr.png';
import kv_apteka from './img/kv_apteka.png';
import {WrapperShell} from '../../../components/wrapper-shell';
import {StyledBoxShadow, StyledCircle, UL, LI, StyledCircleBorder} from './ui';
import {Box} from '@mui/material';

export const SiriuszHelper = () => {
  const history = useHistory();

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  return (
    <>
      <Page head={'SIRIUSZ_HELPER WWQR 3.0'} backText="Назад" onClose={handleRedirectToProducts} className="white">
        <WrapperShell sx={{
          p: 0,
          maxHeight: 417,
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(0, 176, 240, 0.2)'
        }}>
          <img src={main} style={{maxWidth: '100%'}}/>
          <StyledLink className="blue" href="https://t.me/sirius_z_helper_bot" sx={{
            fontSize: 24,
            lineHeight: '38px',
            position: 'absolute',
            m: '-80px 70px',
            borderColor: '#00B0F0',
          }}>
            Начать
            <img className="svg" src={arrow} width={24}/>
          </StyledLink>
        </WrapperShell>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px', mt: '20px'}}>
          <StyledBoxShadow sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <Box className="title" sx={{mb:1}}>ИНФОРМАЦИЯ ДЛЯ ПОЛЬЗОВАТЕЛЯ</Box>
            <Box className="border">
              Основная миссия Компании ООО «Зентива Фарма» — производить высококачественные препараты в самых различных
              сферах здоровья человека. <br/><br/>

              Наиболее важным и сложным направлением производства являются продукты женского здоровья. Именно по этой
              причине компания регулярно разрабатывает социально значимые проекты, которые помогают Пациентам получить
              качественную и доступную терапию Премиальными препаратами в области Женского Здоровья. <br/><br/>

              Очень часто, в силу различных причин бывает непросто донести важную информацию до Потенциальных
              Потребителей. Мы нашли способ как можно это сделать быстро! Представляем вашему вниманию <span
              className="blue"><strong>SiriusZ_Helper
              Бот</strong></span> — онлайн-сервис, который специально разработан для решения подобных задач. <br/><br/>

              Запоминать наиболее выгодные по цене предложения рынка больше нет необходимости. Бот является помощником
              для всех Клиентов. Он предоставляет актуальную информацию о стоимости назначенного препарата и помогает
              выбрать онлайн-площадку для его покупки.
            </Box>

            <Box className="border" sx={{display: 'flex', gap: '26px'}}>
              <StyledCircle>
                <img src={icon}/>
              </StyledCircle>
              <Box>
                После регистрации в сервисе Бот направит вас по ссылке на акции Компании ООО «Зентива Фарма», если Вам
                назначен Климонорм, Алвовизан или Зоэли.
              </Box>
            </Box>

            <Box className="border" sx={{display: 'flex', gap: '26px'}}>
              <StyledCircle>
                <img src={icon}/>
              </StyledCircle>
              <Box>
                Все акции проходят на онлайн-площадках: <a href="https://apteka.ru/" style={{
                color: '#00B0F0',
                textDecoration: 'underline'
              }}>Аптека.ру</a>, <a href="https://www.eapteka.ru/"
                                   style={{color: '#00B0F0', textDecoration: 'underline'}}>ЕАптека</a>, <a
                href="https://zdravcity.ru/" style={{color: '#00B0F0', textDecoration: 'underline'}}>Здравсити</a>.
                <UL sx={{mb: 0}}>
                  <LI>Сервисы удобны для пациентов с точки зрения компенсации затрат времени на поиск препаратов в
                    физических аптеках.</LI>
                  <LI sx={{mt: 2}}>При первой регистрации онлайн-сервисы предоставляют скидку на первый заказ.</LI>
                  <LI sx={{mt: 2}}>Есть возможность выбрать аптеку, из которой будет удобно забрать приобретенный
                    препарат.</LI>
                </UL>
              </Box>
            </Box>

            <Box className="border" sx={{display: 'flex', gap: '26px'}}>
              <StyledCircle>
                <img src={icon}/>
              </StyledCircle>
              <Box>
                Для получения доступа к функциям Бота SiriusZ_Helper всем клиентам необходимо пройти авторизацию.
                Процедура авторизации занимает несколько секунд и является обязательной для корректной работы.
              </Box>
            </Box>

            <Box className="border" sx={{display: 'flex', gap: '26px'}}>
              <StyledCircle>
                <img src={icon}/>
              </StyledCircle>
              <Box>
                Работа в Боте для Врача сводится к предоставлению персонального qr-кода Пациенту для регистрации и
                получения Ссылки или Промокода и Ссылки на онлайн-сервис для покупки препарата Пациентом.
              </Box>
            </Box>

            <Box className="border" sx={{display: 'flex', gap: '26px'}}>
              <StyledCircle>
                <img src={icon}/>
              </StyledCircle>
              <Box>
                Интуитивно простой и понятный интерфейс Бота ускоряет время работы с ним. Сервис не требует установки в
                телефон – работает онлайн!
              </Box>
            </Box>

            <Box className="border" sx={{display: 'flex', gap: '26px'}}>
              <StyledCircle>
                <img src={icon}/>
              </StyledCircle>
              <Box>
                Сервис реализован на самой распространенной кросс-платформенной системе мгновенного обмена сообщениями –
                Телеграмм, что позволяет помочь максимальному количеству пользователей.
              </Box>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow>
            <img src={kv_apteka} style={{maxWidth: '100%'}}/>
            <p style={{marginBottom: 0}}>
              В современных реалиях любой пациент может без труда купить назначенный ему препарат по привлекательной
              цене, сделать заказ онлайн или прийти лично в аптеку. С сервисом <span className="blue">SIRIUSZ_HELPER</span> этот процесс станет
              быстрым, комфортным и управляемым. <br/><br/>
              <span className="blue">SIRIUSZ_HELPER</span> сэкономит ваше время на поиск самых доступных
              терапевтических решений!
            </p>
          </StyledBoxShadow>

          <StyledBoxShadow>
            <Box className="title" sx={{mb: 4}}>ПРЕИМУЩЕСТВА ИСПОЛЬЗОВАНИЯ СЕРВИСА SIRIUSZ_HELPER</Box>
            <Box className="border">
              <StyledCircle>
                <img src={icon2}/>
              </StyledCircle>
              <Box sx={{fontSize: 24, mt: 5}} className="blue">Для Врача</Box>

              <UL sx={{mb: '20px'}}>
                <LI sx={{mb: 2}}>
                  Вы помогаете пациентам своевременно получать актуальную информацию о возможности приобретения
                  применяемого ими лекарственного средства по самой выгодной цене, что помогает увеличивать
                  эффективность и доступность терапии.
                </LI>
                <LI sx={{mb: 2}}>
                  Вы используете современный интерактивный сервис на платформе Телеграмм, который содержит информацию о
                  самых выгодных и актуальных предложениях компании ООО «Зентива Фарма» для пациентов, что повышает
                  преемственность терапии.
                </LI>
                <LI sx={{mb: 2}}>
                  Сервис обновляется автоматически без участия пользователя, что значительно экономит ваше время при
                  работе с Ботом, и предоставляет доступ к актуальным функци
                </LI>
                <LI sx={{mb: 2}}>
                  В ближайшее время в сервисе появится справочник Акций и Скидок, что позволит вам до рекомендации
                  препарата пациенту знать на каком сервисе ему будет выгоднее его приобрести. Это позволит сэкономить
                  Ваше время для выбора правильной рекомендации.
                </LI>
                <LI sx={{mb: 2}}>
                  Сервис активно развивается и скоро Вы увидите много интересных функций внутри Сервиса SiriusZ_Helper,
                  которые будут помогать вам в повседневной работе, в т.ч. чат технической поддержки клиентов сервиса.
                </LI>
              </UL>
            </Box>

            <Box className="border" sx={{mt: '20px'}}>
              <StyledCircle>
                <img src={icon3}/>
              </StyledCircle>
              <Box sx={{fontSize: 24, mt: 5}} className="blue">Для Пациента</Box>

              <UL>
                <LI sx={{mb: 2}}>
                  Бот предназначен для получения Пациентами своевременной и актуальной информации о применяемом ими
                  лекарственном средстве, чтобы помочь увеличить эффективность и доступность терапии.
                </LI>
                <LI sx={{mb: 2}}>
                  Интуитивно понятный и простой интерфейс Бота с пошаговыми подсказками упростит работу и за несколько
                  кликов приведет к покупке рекомендованного Врачом препарата по самой доступной цене.
                </LI>
                <LI sx={{mb: 2}}>
                  Простая и быстрая регистрация в Боте сократит повторный этап получения информации в 2 раза.
                </LI>
                <LI sx={{mb: 2}}>
                  Планируется развитие сервиса SiriusZ_Helper 3.0 так же и для взаимодействия с пациентами. В ближайшее
                  время появится новый функционал, в т.ч. Чат технической поддержки клиентов сервиса.
                </LI>
                <LI sx={{mb: 2}}>
                  Бот работает с тремя основными онлан-сервисами, на которых можно получить дополнительную скидку при
                  регистрации и выбрать удобную аптеку, чтобы забрать приобретенный препарат.
                </LI>
              </UL>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow>
            <Box className="title" sx={{mb: 2}}>ВЗАИМОДЕЙСТВИЕ <span className="blue">ВРАЧА</span> С СЕРВИСОМ СОСТОИТ ИЗ
              СЛЕДУЮЩИХ ЭТАПОВ:</Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Box sx={{maxWidth: 300, boxSizing: 'border-box'}}>
                <p className="blue">РЕГИСТРАЦИЯ:</p>
                необходимо навести камеру телефона на qr-код или перейти по прямой ссылке: <br/> <br/>
                <a style={{color: '#00B0F0', textDecoration: 'underline'}}
                   href="https://t.me/sirius_z_helper_bot?start=become_doctor">
                  https://t.me/sirius_z_helper_bot?<br/>start=become_doctor
                </a>
              </Box>
              <Box>
                <img src={step1}/>
              </Box>
              <Box>
                <img src={step2}/>
              </Box>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow>
            <Box className="title" sx={{mb: 2}}>АЛЬТЕРНАТИВНАЯ РЕГИСТРАЦИЯ <span className="blue">ВРАЧА</span> БЕЗ
              QR-КОДА</Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Box>
                <UL>
                  <LI sx={{mb: 2}}>
                    Необходимо получить от Медицинского Представителя ссылку на Бот в телеграмм, который зарегистрирован
                    на номер телефона, указанный в базе Врачей Бота: <a
                    style={{color: '#00B0F0', textDecoration: 'underline'}}
                    href="https://t.me/sirius_z_helper_bot">https://t.me/sirius_z_helper_bot</a>
                  </LI>
                  <LI sx={{mb: 2}}>
                    После прохождения по ссылке нажать кнопку «СТАРТ» в для запуска Бота
                  </LI>
                  <LI sx={{mb: 2}}>
                    В левом нижнем углу экрана телефона нажать кнопку «МЕНЮ» и выбрать пункт «АВТОРИЗАЦИЯ ВРАЧА»
                  </LI>
                </UL>
              </Box>
              <Box>
                <img src={mainPhone}/>
              </Box>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow>
            <Box className="title" sx={{mb: 2}}>НАЧАЛО РАБОТЫ С БОТОМ</Box>

            <Box sx={{display: 'flex', flexWrap: 'wrap', mt: 6}}>
              <Box sx={{flexBasis: '25%', mb: 6}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', pb: '20px'}}>
                  <img src={s1}/>
                  <StyledCircleBorder sx={{m: '16px'}}>1</StyledCircleBorder>
                  <Box>Нажмите кнопку</Box>
                  <Box sx={{color: '#00B0F0'}}>«СТАРТ»</Box>
                </Box>
              </Box>
              <Box sx={{flexBasis: '25%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', pb: '20px'}}>
                  <img src={s2}/>
                  <StyledCircleBorder sx={{m: '16px'}}>2</StyledCircleBorder>
                  <Box sx={{textAlign: 'center'}}>Лицензионное соглашение</Box>
                  <Box sx={{color: '#00B0F0'}}>«ПРИНЯТЬ»</Box>
                </Box>
              </Box>
              <Box sx={{flexBasis: '25%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', pb: '20px'}}>
                  <img src={s3}/>
                  <StyledCircleBorder sx={{m: '16px'}}>3</StyledCircleBorder>
                  <Box>Нажмите</Box>
                  <Box sx={{textAlign: 'center', color: '#00B0F0'}}> «ПОДЕЛИТЬСЯ ТЕЛЕФОНОМ»</Box>
                </Box>
              </Box>
              <Box sx={{flexBasis: '25%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', pb: '20px'}}>
                  <img src={s4}/>
                  <StyledCircleBorder sx={{m: '16px'}}>4</StyledCircleBorder>
                  <Box sx={{textAlign: 'center'}}>Подтверждение, что вы хотите поделиться номером
                    телефона</Box>
                </Box>
              </Box>
              <Box sx={{flexBasis: '25%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', pb: '20px'}}>
                  <img src={s5}/>
                  <StyledCircleBorder sx={{m: '16px'}}>5</StyledCircleBorder>
                  <Box sx={{textAlign: 'center'}}>Ввод ФИО при первом использовании сервиса</Box>
                </Box>
              </Box>
              <Box sx={{flexBasis: '25%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', pb: '20px'}}>
                  <img src={s6}/>
                  <StyledCircleBorder sx={{m: '16px'}}>6</StyledCircleBorder>
                  <Box sx={{textAlign: 'center'}}>Ввод данных о возрасте пациента. Отправка запроса
                    врачу для подтверждения
                    считывания qr-кода пациентом</Box>
                </Box>
              </Box>
              <Box sx={{flexBasis: '25%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', pb: '20px'}}>
                  <img src={s7}/>
                  <StyledCircleBorder sx={{m: '16px'}}>7</StyledCircleBorder>
                  <Box sx={{textAlign: 'center'}}>Анкета пациента: Выберите необходимый препарат</Box>
                </Box>
              </Box>
              <Box sx={{flexBasis: '25%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', pb: '20px'}}>
                  <img src={s8}/>
                  <StyledCircleBorder sx={{m: '16px'}}>8</StyledCircleBorder>
                  <Box sx={{textAlign: 'center'}}>Анкета пациента:
                    Укажите, были ли побочные эффекты или применяю препарат впервые</Box>
                </Box>
              </Box>
              <Box sx={{flexBasis: '25%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', pb: '20px'}}>
                  <img src={s9}/>
                  <StyledCircleBorder sx={{m: '16px'}}>9</StyledCircleBorder>
                  <Box sx={{textAlign: 'center'}}>Анкета пациента:
                    Выберите предпочитаемый онлайн-сервис</Box>
                </Box>
              </Box>
              <Box sx={{flexBasis: '25%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', pb: '20px'}}>
                  <img src={s10}/>
                  <StyledCircleBorder sx={{m: '16px'}}>10</StyledCircleBorder>
                  <Box sx={{textAlign: 'center'}}>
                    Анкета пациента:
                    Получение Ссылки на скидку или Промокод
                  </Box>
                </Box>
              </Box>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow>
            <Box className="title" sx={{mb: 4}}>СПИСОК СЕРВИСОВ И СКИДОК</Box>

            <Tables.WrapperTable>
              <Tables.Table className="align-default" sx={{fontSize: 18, minWidth: 900}}>
                <Tables.Thead>
                  <Tables.Tr
                    sx={{backgroundColor: '#00B0F0', fontWeight: 'bold', textTransform: 'uppercase', lineHeight: '42px'}}>
                    <Tables.Td sx={{textTransform: 'none'}}>Препарат / Аптека</Tables.Td>
                    <Tables.Td>apteka.ru</Tables.Td>
                    <Tables.Td>здравсити</Tables.Td>
                    <Tables.Td>Сбер ЕАптека</Tables.Td>
                  </Tables.Tr>
                </Tables.Thead>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td>Алвовизан</Tables.Td>
                    <Tables.Td>2 уп. - 15%, 3 уп. - 20%</Tables.Td>
                    <Tables.Td>2 уп. - 15%</Tables.Td>
                    <Tables.Td>—</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr sx={{backgroundColor: 'rgb(0, 176, 240, .3)',}}>
                    <Tables.Td>Климонорм</Tables.Td>
                    <Tables.Td>2 уп. - 20%</Tables.Td>
                    <Tables.Td>2 уп. - 20%</Tables.Td>
                    <Tables.Td>—</Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td>Зоэли</Tables.Td>
                    <Tables.Td>-50%</Tables.Td>
                    <Tables.Td>-50%</Tables.Td>
                    <Tables.Td>-50%</Tables.Td>
                  </Tables.Tr>
                </Tables.Body>
              </Tables.Table>
            </Tables.WrapperTable>
          </StyledBoxShadow>

          <StyledBoxShadow>
            <Box className="title" sx={{mb: 4}}>ОТЛИЧИЯ ОТ ПРЕДЫДУЩИХ ВЕРСИЙ</Box>
            <Box className="border">
              <StyledCircle>
                <img src={icon4}/>
              </StyledCircle>
              <Box sx={{fontSize: 24, mt: 5}} className="blue">Повышена безопасность работы Бота</Box>

              <UL sx={{mb: '20px'}}>
                <LI sx={{mb: 2}}>
                  Воспользоваться функционалом могут только зарегистрированные;
                </LI>
                <LI sx={{mb: 2}}>
                  Программа поддерживает режим работы «только в кабинете», за счет оповещения Врача о считывании его
                  персонального qr-кода (по таймеру);
                </LI>
                <LI sx={{mb: 2}}>
                  Количество запросов на считывание qr-кода врача от одного пациента ограничено. Максимально 2 запроса
                  в день;
                </LI>
                <LI sx={{mb: 2}}>
                  Добавлена политика Конфиденциальности: https://telegra.ph/Politika-konfidencialnosti-02-16-7
                </LI>
              </UL>
            </Box>

            <Box className="border" sx={{mt: '20px'}}>
              <StyledCircle>
                <img src={icon5}/>
              </StyledCircle>
              <Box sx={{fontSize: 24, mt: 5}} className="blue">Удобство использования</Box>

              <UL>
                <LI sx={{mb: 2}}>
                  Программа гибко взаимодействует с пользователем на любом уровне доступа. Сопровождает
                  результирующими сообщениями на каждом этапе работы и предлагает следующие шаги для максимально
                  комфортного и быстрого получения информации по акциям компании ООО «Зентива Фарма»;
                </LI>
                <LI sx={{mb: 2}}>
                  Работает через систему qr-кодов для активации клиентов и функционала;
                </LI>
                <LI sx={{mb: 2}}>
                  Идентифицирует клиента для ускорения работы и взаимодействует с ним через сервис обязательной
                  авторизации;
                </LI>
                <LI sx={{mb: 2}}>
                  Пошаговое ведение пациента по анкете завершается предложением выбранного сервиса и скидки
                  (Промокода);
                </LI>
                <LI sx={{mb: 2}}>
                  Значительно сокращена анкета для Пациента за счет автоматизации многих процессов и сервиса
                  авторизации.
                </LI>
              </UL>
            </Box>
          </StyledBoxShadow>

          <StyledBoxShadow sx={{display: 'flex'}}>
            <Box>
              <Box className="title" sx={{mb: 4}}>ПЛАНЫ НА БЛИЖАЙШЕЕ БУДУЩЕЕ</Box>

              <ol>
                <LI sx={{mb: 2}}>
                  Разработка чата технической поддержки клиентов
                </LI>
                <LI sx={{mb: 2}}>
                  Рассылки напоминаний пациентам о повторном визите к Врачу и напоминаний о текущих акциях компании
                  Пациентам и Врачам.
                </LI>
                <LI sx={{mb: 2}}>
                  Организация школ для Пациентов среди авторизированных пользователей сервиса.
                </LI>
              </ol>
            </Box>
            <Box>
              <img src={hand_qr}/>
            </Box>
          </StyledBoxShadow>

          <WrapperShell sx={{
            p: 0,
            maxHeight: 417,
            mb: 10,
            boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(0, 176, 240, 0.2)'
          }}>
            <img style={{maxWidth: '100%'}} src={bg_footer}/>
            <StyledLink className="blue" href="https://t.me/sirius_z_helper_bot" sx={{
              fontSize: 24,
              lineHeight: '38px',
              position: 'absolute',
              marginTop: '-94px', right: '240px',
              borderColor: '#00B0F0',
            }}>
              Начать
              <img className="svg" src={arrow} width={24}/>
            </StyledLink>
          </WrapperShell>
        </Box>
      </Page>
    </>
  )
}
