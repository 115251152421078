import {useHistory} from 'react-router-dom';

import {WrapperShell} from 'components/wrapper-shell';
import {WrapperCouple} from 'components/wrapper-couple';
import Page from 'shared/ui/Page';
import {List as UL, ListItem as LI} from 'shared/ui';
import {PinkLight} from 'styles/color';

import {H2 as Header2, H1 as Header1, Paragraph as P, LinkA} from './ui';
import {Span, Div} from '../ui';
import {styled} from '@mui/system';
import pdf from '../../../pdfs/klimonorm/Климонорм_ИМП.pdf';

const H2 = styled(Header2)({
  color: PinkLight
})

const H1 = styled(Header1)({
  color: PinkLight
})

const A = styled(LinkA)({
  display: 'block',
  margin: '10px auto',
  letterSpacing: '0.1px',
  fontSize: 17,
  fontFamily: 'SF-Pro-Text-Semibold',
  textDecoration: 'none',
})

export const KlomonormInstruction = () => {
  const history = useHistory();

  const handlerRedirectToDiecyclen = () => {
    history.push('/product/klomonorm');
  }

  return (
    <Page onClose={handlerRedirectToDiecyclen} isCross>
      <Div sx={{textAlign: 'center'}}>
        <H2 sx={{fontSize: 20, mb: 3}}>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</H2>
        <H1>ИНСТРУКЦИЯ</H1>
        <H2 sx={{fontSize: 20, textTransform: 'none', mb: 3, mt: 2}}>по применению лекарственного препарата для
          медицинского применения</H2>
        <H1 sx={{mb: 3}}>КЛИМОНОРМ®</H1>
      </Div>
      <H2 sx={{fontSize: 20}}>РЕГИСТРАЦИОННЫЙ НОМЕР:</H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ТОРГОВОЕ НАИМЕНОВАНИЕ: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>Климонорм®</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ЛЕКАРСТВЕННАЯ ФОРМА: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>драже</Span>
      </H2>

      <H2 style={{marginTop: 50}}>СОДЕРЖАНИЕ</H2>
      <WrapperCouple sx={{display: 'flex', justifyContent: 'space-between'}}>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#compound'}>СОСТАВ</A>
          <A href={'#description'}>ОПИСАНИЕ</A>
          <A href={'#farm-group'}>ФАРМАКОТЕРАПЕВТИЧЕСКАЯ ГРУППА</A>
          <A href={'#farm-property'}>ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА</A>
          <A href={'#ind-for-use'}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ</A>
          <A href={'#dication'}>ПРОТИВОПОКАЗАНИЯ</A>
          <A href={'#warning'}>С ОСТОРОЖНОСТЬЮ</A>
          <A href={'#for-use-feed'}>ПРИМЕНЕНИЕ В ПЕРИОД БЕРЕМЕННОСТИ И ГРУДНОГО<br/> ВСКАРМЛИВАНИЯ</A>
          <A href={'#dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</A>
          <A href={'#effect-action'}>ПОБОЧНОЕ ДЕЙСТВИЕ</A>
          <A href={'#over-dozing'}>ПЕРЕДОЗИРОВКА</A>
        </WrapperShell>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#another-medicines'}>ВЗАИМОДЕЙСТВИЕ С ДРУГИМИ ЛЕКАРСТВЕННЫМИ <br/> СРЕДСТВАМИ</A>
          <A href={'#special-instructions'}>ОСОБЫЕ УКАЗАНИЯ</A>
          <A href={'#managment-transport'}>ВЛИЯНИЕ НА СПОСОБНОСТЬ УПРАВЛЕНИЯ <br/>ТРАНСПОРТНЫМИ СРЕДСТВАМИ,
            МЕХАНИЗМАМИ</A>
          <A href={'#form-production'}>ФОРМА ВЫПУСКА</A>
          <A href={'#keeper'}>УСЛОВИЯ ХРАНЕНИЯ</A>
          <A href={'#deadline'}>СРОК ГОДНОСТИ</A>
          <A href={'#holiday'}>УСЛОВИЯ ОТПУСКА</A>
          <A href={'#producer'}>ПРОИЗВОДИТЕЛЬ</A>
        </WrapperShell>
      </WrapperCouple>

      <H2 id={'compound'}>СОСТАВ</H2>
      <P><b>Активные компоненты:</b></P>
      <P>1 желтое драже содержит 2,0 мг эстрадиола валерата</P>
      <P>1 коричневое драже содержит 2,0 мг эстрадиола валерата и 0,15 мг левоноргестрела.</P>
      <P><b>Вспомогательные вещества:</b></P>
      <P>
        лактозы моногидрат, крахмал картофельный, поливидон К 25, тальк, магния стеарат, сахароза, глюкоза (декстроза),
        желатин, макрогол 35000, кальция карбонат, титана диоксид Е171, железа оксид гидрат, железа оксид красный,
        железа оксид коричневый, воск карнаубский, магния карбонат основной, вода очищенная.
      </P>

      <H2 id={'description'}>ОПИСАНИЕ</H2>
      <P>Круглые драже желтого цвета (9 драже) и коричневого цвета (12 драже).</P>

      <H2 id={'farm-group'}>Фармакотерапевтическая группа: </H2>
      <P>Противоклимактерическое средство (эстроген+прогестоген)</P>
      <P><b>Код ATX:</b> G03FB09</P>

      <H2 id={'farm-property'}>Фармакологические свойства</H2>
      <P><b>Фармакодинамика</b></P>
      <P>Климонорм содержит эстроген - эстрадиола валерат, который в организме человека превращается в естественный 17β-эстрадиол. Также в состав препарата Климонорм входит производное 19-нортестостерона - левоноргестрел. Добавление левоноргестрела в течение 12 дней каждого цикла снижает риск развития гиперплазии и рака эндометрия.</P>
      <P>Благодаря составу и циклической схеме приема Климонорм, (приём только эстрогена в течение 9 дней, затем - комбинации эстрогена и гестагена в течение 12 дней, и, наконец, 7- дневный перерыв), у женщин с неудаленной маткой при регулярном приеме препарата устанавливается менструальный цикл.</P>
      <P>Эстрадиол восполняет дефицит эстрогенов в женском организме после наступления менопаузы и обеспечивает эффективное лечение психо-эмоциональных и вегетативных климактерических симптомов (таких как «приливы», повышенное потоотделение, нарушение сна, повышенная нервная возбудимость, раздражительность, сердцебиения, кардиалгии, головокружение, головная боль, снижение либидо, мышечные и суставные боли); инволюции кожи и слизистых оболочек, особенно слизистых мочеполовой системы (недержание мочи, сухость и раздражение слизистой влагалища, болезненность при половом сношении).</P>
      <P>Эстрадиол предупреждает потерю костной массы, вызванную дефицитом эстрогенов. Главным образом это связано с подавлением функции остеокластов и сдвигом процесса костного ремоделирования в сторону образования кости. Было доказано, что длительное применение заместительной гормональной терапии (ЗГТ) позволяет снизить риск переломов периферических костей у женщин после наступления менопаузы. При отмене ЗГТ темпы снижения костной массы сравнимы с показателями, характерными для периода непосредственно после менопаузы. Не доказано, что, применяя ЗГТ, можно добиться восстановления костной массы до предклимактерического уровня.</P>
      <P>ЗГТ также оказывает благотворное действие на содержание коллагена в коже, равно как и на ее плотность, и также может замедлить процесс образования морщин.</P>
      <P>Приём Климонорма ведет к снижению уровня общего холестерина, липопротеидов низкой плотности (ЛПНП) и к повышению липопротеидов высокой плотности (ЛПВП), в результате чего значительно повышается соотношение ЛПВП/ЛПНП, а также повышению уровня триглицеридов.</P>
      <P>Наблюдательные исследования дают основания полагать, что среди женщин в постменопаузе при использовании ЗГТ снижается показатель заболеваемости раком толстой кишки. Механизм действия до настоящего времени неясен.</P>
      <P><b>Фармакокинетика</b></P>
      <P>После приёма внутрь эстрадиола валерат быстро и полностью абсорбируется из желудочно-кишечного тракта (ЖКТ). После поступления в организм он быстро метаболизирется с образованием 17β-эстрадиола и эстрона, которые в дальнейшем подвергаются стандартным метаболическим превращениям. После перорального приема становится биодуступным около 3% эстрадиола. Максимальная концентрация эстрадиола достигается через 2-3 часа, соотношение эстрон-эстрадиол равно 4:1. Эстрадиол выводится в виде метаболитов, главным образом с мочой (90%) и, в меньшей степени - с желчью.</P>
      <P>После приема внутрь левоноргестрел быстро и практически полностью абсорбируется из ЖКТ. Максимальная концентрация в сыворотке крови достигается через 1-2 часа. 93-95 % левоноргестрела связывается с альбумином и глобулином, связывающим половые гормоны (ГСПГ). Метаболиты левоноргестрела выводятся с мочой и желчью, главным образом, в виде сульфатов и глюкуронидов.</P>

      <H2 id={'ind-for-use'}>Показания к применению</H2>
      <UL>
        <LI><P>
          Заместительная гормональная терапия (ЗГТ) при климактерических расстройствах, инволюционных изменениях кожи и мочеполового тракта, депрессивных состояниях в климактерическом периоде, а также симптомах дефицита эстрогенов вследствие естественной менопаузы или гипогонадизма, стерилизации или первичной дисфункции яичников у женщин с неудаленной маткой.
        </P></LI>
        <LI><P>Профилактика постменопаузального остеопороза.</P></LI>
        <LI><P>Нормализация нерегулярных менструальных циклов.</P></LI>
        <LI><P>Лечение первичной или вторичной аменореи.</P></LI>
      </UL>

      <H2 id={'dication'}>противоПоказания</H2>
      <P>Не рекомендуется начинать заместительную гормональную терапию (ЗГТ), при наличии любого из перечисленных ниже состояний. Если какое-либо из данных состояний возникнет во время ЗГТ, то следует немедленно прекратить использование препарата.</P>
      <UL>
        <LI><P>Беременность и лактация</P></LI>
        <LI><P>Влагалищное кровотечение неясного происхождения</P></LI>
        <LI><P>Подтверждённый или предполагаемый диагноз рака молочной железы</P></LI>
        <LI><P>Подтверждённый или предполагаемый диагноз гормонозависимого предракового заболевания или гормонозависимой злокачественные опухоли</P></LI>
        <LI><P>Опухоли печени в настоящее время или в анамнезе (доброкачественные или злокачественные)</P></LI>
        <LI><P>Тяжелые заболевания печени</P></LI>
        <LI><P>Острый или недавно перенесенный артериальный тромбоз или тромбоэмболия (такие как инфаркт миокарда, инсульт)</P></LI>
        <LI><P>Тромбоз глубоких вен в стадии обострения, тромбоэмболии в настоящее время или в анамнезе</P></LI>
        <LI><P>Выраженная гипертриглицеридемия</P></LI>
        <LI><P>Повышенная чувствительность к компонентам препарата Климонорм.</P></LI>
      </UL>
      <H2 id={'warning'}>С осторожностью</H2>
      <P>Климонорм следует назначать с осторожностью при следующих заболеваниях: артериальная гипертензия, врожденные гипербилирубинемии (синдромы Жильбера, Дубина-Джонсона и Ротора), холестатическая желтуха или холестатический зуд во время беременности, эндометриоз, миома матки, сахарный диабет (см. «Особые указания»).</P>

      <H2 id={'for-use-feed'}>Применение в период беременности и грудного вскармливания</H2>
      <P>ЗГТ не назначается во время беременности или кормления грудью.</P>
      <P>Крупномасштабные эпидемиологические исследования стероидных гормонов, используемых для контрацепции или ЗГТ, не выявили увеличения риска развития врожденных дефектов у детей, родившихся у женщин, которые принимали такие гормоны до беременности, а также тератогенного воздействия гормонов при их случайном приеме в ранние сроки беременности.</P>
      <P>Небольшое количество половых гормонов может выделяться с материнским молоком.</P>

      <H2 id={'dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</H2>
      <P>Если у пациентки все еще продолжаются менструации, лечение следует начинать на 5-ый день менструального цикла (1-й день менструального кровотечения соответствует 1-му дню менструального цикла).
      </P>
      <P>Пациентки с аменореей или очень редкими менструациями, а также женщины в постменопаузе, могут начинать прием препарата в любое время, при условии, что исключена беременность (см. раздел «Беременность и лактация»).
      </P>
      <P>Каждая упаковка рассчитана на 21-дневный приём.
      </P>
      <P>Ежедневно в течение первых 9 дней принимают по одному желтому драже, а затем в течение 12 дней - ежедневно по одному коричневому драже. После 21-дневного приема препарата следует 7-дневный перерыв в приеме препарата, во время которого наступает менструальноподобное кровотечение, вызванное отменой препарата (обычно на 2-3 день после приема последнего драже).
      </P>
      <P>После 7-дневного перерыва в приеме препарата начинают новую упаковку Климонорма, принимая первое драже в тот же день недели, что и первое драже из предыдущей упаковки.
      </P>
      <P>Драже проглатывают целиком, запивая небольшим количеством жидкости. Время суток, когда женщина принимает препарат, не имеет значения, однако, если она начала принимать драже в какое-либо конкретное время, она должна придерживаться этого времени и дальше. Если женщина забыла принять драже, она может принять его в течение ближайших 12–24 часов. Если лечение прервано на более длительное время, возможно возникновение вагинального кровотечения.</P>

      <H2 id={'effect-action'}>Побочное действие</H2>
      <P><b>Со стороны репродуктивной системы и молочных желез:</b></P>
      <P>изменения частоты и интенсивности маточных кровотечений, прорывные кровотечения, межменструальные кровянистые выделения (обычно ослабляющиеся в ходе терапии), дисменорея, изменения вагинальных выделений, состояние, подобное предменструальному синдрому; болезненность, напряжение и/или увеличение молочных желез.</P>
      <P><b>Со стороны желудочно-кишечного тракта</b></P>
      <P>диспепсия, вздутие живота, тошнота, рвота, боль в животе, рецидив холестатической желтухи
      </P>
      <P><b>Со стороны кожи и подкожной клетчатки</b></P>
      <P>кожная сыпь, кожный зуд, хлоазма, узловатая эритема
      </P>
      <P><b>Со стороны ЦНС</b></P>
      <P>головная боль, мигрень, головокружение, тревожность или депрессивные симптомы, повышенная утомляемость</P>
      <P><b>Прочие</b></P>
      <P>учащенное сердцебиение, отеки, повышение артериального давления, венозный тромбоз и тромбоэмболия, мышечные судороги, изменения массы тела, изменения либидо, нарушения зрения, непереносимость контактных линз, аллергические реакции</P>

      <H2 id={'over-dozing'}>Передозировка</H2>
      <P>Исследования острой токсичности не выявили риска острых побочных эффектов при случайном приёме препарата Климонорм в количестве, многократно превышающем суточную терапевтическую дозу. Симптомы, которые могут отмечаться при передозировке: тошнота, рвота, вагинальное кровотечение. Специфического антидота нет, лечение симптоматическое.</P>

      <H2 id={'another-medicines'}>Взаимодействие с другими лекарственными средствами</H2>
      <P>При начале ЗГТ необходимо прекратить применение гормональных контрацептивов. При необходимости пациентке следует рекомендовать негормональные контрацептивы.</P>
      <P>Длительное лечение препаратами, индуцирующими ферменты печени (например, некоторыми противосудорожными и антимикробными препаратами) может увеличивать клиренс половых гормонов и снижать их клиническую эффективность. Подобное свойство индуцировать ферменты печени было обнаружено у гидантоинов, барбитуратов, примидона, карбамазепина и рифампицина, наличие этой особенности также предполагается у окскарбазепина, топирамата, фелбамата и гризеофульвина. Максимальная индукция ферментов обычно наблюдается не раньше, чем через 2-3 недели, но затем она может сохраняться еще, по крайней мере, в течение 4 недель после прекращения приема препарата.</P>
      <P>В редких случаях на фоне сопутствующего приема некоторых антибиотиков</P>
      <P>(например, пенициллиновой и тетрациклиновой групп) наблюдалось снижение уровня эстрадиола.</P>
      <P>Вещества, в значительной степени подвергающиеся конъюгации (например, парацетамол), могут увеличивать биодоступность эстрадиола вследствие конкурентного ингибирования системы конъюгации в процессе всасывания.</P>
      <P>Вследствие влияния ЗГТ на толерантность к глюкозе в отдельных случаях может измениться потребность в пероральных противодиабетических средствах или инсулине.</P>
      <P><b>Взаимодействие с алкоголем</b></P>
      <P>Чрезмерное потребление алкоголя во время ЗГТ может привести к увеличению уровня циркулирующего эстрадиола.</P>

      <H2 id={'special-instructions'}>Особые указания</H2>
      <P>Климонорм не применяется с целью контрацепции.</P>
      <P>При наличии или ухудшении какого-либо указанных ниже из состояний или факторов риска, прежде чем начать или продолжить ЗГТ следует оценить соотношение индивидуального риска и пользы лечения.</P>
      <P>При необходимости контрацепции, следует применять негормональные методы (за исключением календарного и температурного методов). При подозрении на беременность, следует приостановить прием драже до тех пор, пока беременность не будет исключена (см. раздел «Беременность и лактация»).</P>
      <P><b>•	Венозная тромбоэмболия</b></P>
      <P>В ряде контролируемых рандомизированных, а также эпидемиологических исследований выявлен повышенный относительный риск развития венозной тромбоэмболии (ВТЭ) на фоне ЗГТ, т.е. тромбоза глубоких вен или эмболии легочной артерии. Поэтому, при назначении ЗГТ женщинам с факторами риска ВТЭ соотношение риска и пользы от лечения должно быть тщательно взвешено и обсуждено с пациенткой.</P>
      <P>Факторы риска развития ВТЭ включают индивидуальный и семейный анамнез (наличие ВТЭ у ближайших родственников в относительно молодом возрасте может указывать на генетическую предрасположенность) и тяжелое ожирение. Риск ВТЭ также повышается с возрастом. Вопрос о возможной роли варикозного расширения вен в развитии ВТЭ остается спорным.</P>
      <P>Риск ВТЭ может временно увеличиваться при продолжительной иммобилизации, «больших» плановых и травматологических операциях или массивной травме. В зависимости от причины или продолжительности иммобилизации следует решить вопрос о целесообразности временного прекращения ЗГТ</P>
      <P>Следует немедленно прекратить лечение при появлении симптомов тромботических нарушений или при подозрении на их возникновение.</P>
      <P><b>•	Артериальная тромбоэмболия</b></P>
      <P>В ходе рандомизированных контролируемых исследований при длительном применении комбинированных конъюгированных эстрогенов и медроксипрогестерон ацетата не было получено доказательств положительного влияния на сердечно-сосудистую систему. В широкомасштабных клинических испытаниях этого соединения было выявлено возможное возрастание риска коронарной болезни в первый год применения. Также был обнаружен возросший риск возникновения инсульта. До настоящего времени с другими препаратами для ЗГТ не проводилось долгосрочных рандомизированных контролируемых испытаний с целью выявления положительного эффекта в отношении показателей заболеваемости и смертности, касающихся сердечно-сосудистой системы. Поэтому неизвестно, распространяется ли этот повышенный риск на препараты для ЗГТ, содержащие другие виды эстрогенов и прогестагенов.</P>
      <P><b>•	Рак эндометрия</b></P>
      <P>При длительной монотерапии эстрогенами повышается риск развития гиперплазии или карциномы эндометрия. Исследования подтвердили, что добавление гестагенов снижает риск гиперплазии и рака эндометрия.</P>
      <P><b>•	Рак молочной железы</b></P>
      <P>По данным клинических испытаний и результатам наблюдательных исследований было обнаружено увеличение относительного риска развития рака молочной железы у женщин, использующих ЗГТ в течение нескольких лет. Это может быть связано с более ранней диагностикой, биологическим действием ЗГТ или сочетанием обоих факторов. Относительный риск возрастает с увеличением продолжительности лечения (на 2.3 % за год использования) и возможно еще больше возрастает при сочетании эстрогенов с прогестагенами. Это возрастание сопоставимо с увеличением риска возникновения рака молочных желез у женщин с каждым годом задержки наступления естественной менопаузы (на 2.8% за год задержки), а также при ожирении и злоупотреблении алкоголем. Повышенный риск постепенно снижается до обычного уровня в течение первых 5 нескольких лет после прекращения ЗГТ.</P>
      <P>По данным исследований рак молочной железы, выявленный у женщин, принимающих ЗГТ, обычно более дифференцированный, чем у женщин ее не принимающих.</P>
      <P>ЗГТ увеличивает маммографическую плотность молочных желез, что в некоторых случаях может оказывать негативное влияние на рентгенологическое выявление рака молочной железы.</P>
      <P><b>•	Опухоль печени</b></P>
      <P>На фоне применения половых стероидов, к которым относятся и средства для ЗГТ, в редких случаях наблюдались доброкачественные, и еще реже - злокачественные опухоли печени. В отдельных случаях эти опухоли приводили к представляющему угрозу для жизни внутрибрюшному кровотечению. При болях в верхней части живота, увеличенной печени или признаках внутрибрюшного кровотечения при дифференциальной диагностике следует учесть вероятность наличия опухоли печени.</P>
      <P><b>•	Желчнокаменная болезнь
      </b></P>
      <P>Известно, что эстрогены увеличивают литогенность желчи. Некоторые женщины предрасположены к развитию желчнокаменной болезни при лечении с использованием эстрогенов.</P>
      <P><b>•	Другие состояния</b></P>
      <P>Следует немедленно прекратить лечение, при появлении впервые мигренеподобных или частых и необычайно сильных головных болях, а также при появлении других симптомов - возможных предвестников тромботического инсульта головного мозга.</P>
      <P>Взаимосвязь между ЗГТ и развитием клинически выраженной артериальной гипертензии не установлена. У женщин, принимающих ЗГТ, описано небольшое повышение артериального давления, клинически значимое повышение отмечается редко. Однако, в отдельных случаях, при развитии на фоне приема ЗГТ стойкой клинически значимой артериальной гипертензии может быть рассмотрена отмена ЗГТ.</P>
      <P>При нетяжелых нарушениях функции печени, в том числе различных формах гипербилирубинемии, таких как синдром Дубина-Джонсона или синдром Ротора, необходимы наблюдение врача, а также периодические исследования функции печени. При ухудшении показателей функции печени ЗГТ следует отменить.</P>
      <P>Необходимо особое наблюдение за женщинами с умеренно-повышенным уровнем триглицеридов. В подобных случаях применение ЗГТ может вызвать дальнейшее возрастание уровня триглицеридов в крови, что повышает риск острого панкреатита.</P>
      <P>При рецидиве холестатической желтухи или холестатического зуда, наблюдавшихся в первый раз во время беременности или предшествующего лечения половыми стероидными гормонами, необходимо немедленно прекратить ЗГТ.</P>
      <P>Хотя ЗГТ может влиять на периферическую инсулинорезистентность и толерантность к глюкозе, необходимости изменять схему лечения больных сахарным диабетом при проведении ЗГТ, обычно не возникает. Тем не менее, женщины, страдающие сахарным диабетом, при проведении ЗГТ должны находиться под наблюдением.</P>
      <P>У некоторых пациенток под действием ЗГТ могут развиться нежелательные проявления стимуляции эстрогенами, например патологическое маточное кровотечение. Частые или персистирующие патологические маточные кровотечения на фоне лечения являются показанием для исследования эндометрия.</P>
      <P>Если лечение нерегулярных менструальных циклов не даст результатов, следует провести обследование для исключения заболевания органического характера.</P>
      <P>Под влиянием эстрогенов миомы матки могут увеличиться в размерах. В этом случае лечение должно быть прекращено.</P>
      <P>Рекомендуется прекратить лечение при развитии рецидива эндометриоза на фоне ЗГТ.</P>
      <P>При подозрении на наличие пролактиномы перед началом лечения следует исключить это заболевание.</P>
      <P>В некоторых случаях может наблюдаться хлоазма, особенно у женщин с хлоазмой беременных в анамнезе. Во время проведения ЗГТ женщины со склонностью к возникновению хлоазмы должны избегать длительного пребывания на солнце или ультрафиолетового излучения.</P>
      <P>Следующие состояния могут могут возникать или усугубляться на фоне ЗГТ. Хотя их взаимосвязь с ЗГТ не доказана, женщины с этими состояниями при проведении ЗГТ должны находиться под наблюдением врача: эпилепсия; доброкачественная опухоль молочной железы; бронхиальная астма; мигрень; порфирия; отосклероз; системная красная волчанка, малая хорея.</P>
      <P><b><u>Медицинское обследование и консультирование</u></b></P>
      <P>Перед началом или возобновлением ЗГТ женщине рекомендуется пройти тщательное общемедицинское и гинекологическое обследование (включая исследование молочных желез и цитологическое исследование цервикальной слизи), исключить беременность. Кроме того, следует исключить нарушения системы свертывания крови. Периодически следует проводить контрольные обследования.</P>
      <P><b><u>Влияние на результаты лабораторных исследований</u></b></P>
      <P>Прием половых стероидов может влиять на биохимические показатели функции печени, щитовидной железы, надпочечников и почек, на содержание в плазме транспортных белков, таких как кортикостероидсвязывающий глобулин и липидные/липопротеиновые фракции, показатели углеводного обмена, коагуляции и фибринолиза.</P>

      <H2 id={'managment-transport'}>
        Не влияет.
      </H2>

      <H2 id={'form-production'}>Форма выпуска</H2>
      <P>Календарная упаковка (блистер), содержащая 9 драже желтого цвета и 12 драже коричневого цвета. По 21 драже или 3 блистера по 21 драже вместе с инструкцией по применению помещают в картонную коробку.</P>

      <H2 id={'keeper'}>Условия хранения</H2>
      <P>При температуре не выше 25°С в недоступном для детей месте.</P>


      <H2 id={'deadline'}>Срок годности</H2>
      <P>5 лет. Нельзя применять по истечению срока годности, указанного на упаковке!</P>


      <H2 id={'holiday'}>Условия отпуска</H2>
      <P>По рецепту. Список Б.</P>


      <H2 id={'producer'}>Производитель</H2>
      <P>Йенафарм ГмбХ и Ко., КГ, произведено Шеринг ГмбХ и Ко. Продукционс КГ, Германия</P>
      <P>Jenapharm GmbH & Со., KG, manufactured by Schering GmbH & Co. Productions KG, Germany</P>
      <P>Döbereinerstr. 20</P>
      <P>D-99427 Weimar Germany</P>
      <P>www.schermg.ru</P>
      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Инструкция PDF
      </a>
    </Page>
  )
}
