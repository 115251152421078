import {Link, useLocation} from 'react-router-dom';

import {StyledTab} from './ui';

type Props = {
  children: React.ReactNode;
  link: string;
  fullLink?: boolean;
}

export const TabsItem = ({children, link, fullLink = false}: Props) => {
  const {pathname} = useLocation();
  const cls = `${pathname.split('/')[2]}` === link ? 'active' : '';

  const linkUrl = fullLink ? link : `/effective-sales/${link}`;

  return (
    <Link to={linkUrl}>
      <StyledTab className={cls}>
        {children}
      </StyledTab>
    </Link>
  )
}
