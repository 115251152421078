import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanSecondLinkFive = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>Management of endometriomas in women requiring IVF: to touch or not to touch</H1>
      <P>The classic, unproven dogma that ovarian endometrioma should be removed in all infertile women prior to IVF has been recently questioned. There is currently insufficient data to clarify whether the endometrioma-related damage to ovarian responsiveness precedes or follows surgery. Both endometrioma-related injury and surgery-mediated damage may be claimed to be involved and the relative importance of these two insults remains to be clarified. Convincing evidence has emerged showing that responsiveness to gonadotrophins after ovarian cystectomy is reduced. Conversely, the impact of surgery on pregnancy rates is unclear since no deleterious effect has been reported. Of relevance here is that surgery exposes women to risk related to a demanding procedure whereas risks associated with expectant management are mostly anecdotal or of doubtful clinical relevance. We recommend proceeding directly to IVF to reduce time to pregnancy, to avoid potential surgical complications and to limit patient costs. Surgery should be envisaged only in presence of large cysts (balancing the threshold to operate with the cyst location within the ovary), or to treat concomitant pain symptoms which are refractory to medical treatments, or when malignancy cannot reliably be ruled out.</P>
      <P>
        [ссылка: <A
        href="https://pubmed.ncbi.nlm.nih.gov/19056774/">Juan A. Garcia-Velasco, Edgardo Somigliana; Management of endometriomas in women requiring IVF: to touch or not to touch, Human Reproduction, Volume 24, Issue 3, 1 March 2009, Pages 496–501</A>]
      </P>
    </Page>
  )
}
