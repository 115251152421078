import {Box} from '@mui/material';

import {StyledHeader} from '../ui';
import {StyledBoxBorder, StyledBoxInsetShadow, StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {Ticket} from './ticket';

export const SubpageDiecyclen = () => {
  return (
    <>
      <StyledHeader>Диециклен®</StyledHeader>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток, которые принимают Жанин на Диециклен.
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила ДИЕЦИКЛЕН <b>10 пациенткам</b> в течение месяца вместо Жанин.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box>
                  Уделите мне несколько минут для обсуждения пациенток, <b>которые используют такие КОК, как Жанин.</b>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Примеры продуктивных вопросов для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются пациентки с запросом на контрацепцию по более доступной цене, чем Жанин?
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто в Вашей практике встречаются пациентки, для которых стоимость препарата для контрацепции
                  определяет его выбор?
                </Box>
                <Box>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких пациенток на приёме около 2–3 в день. А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box>Скольким из этих пациенток Вы назначаете Жанин?</Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            Назначая Диециклен вместо Жанина в тех же терапевтических нишах, где и оригинальный препарат, Вы
            обеспечиваете качественной помощью бо́льшее количество пациенток.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              Терапевтические ниши Диециклена:
            </Box>
            <ul className="list">
              <li>Контрацепция с Диеногестом после аборта</li>
              <li>Контрацепция для пациенток с эндометриозом, которым нужна контрацепция</li>
              <li>Контрацепция для пациенток с лёгкой и средней степени тяжести акне, нуждающихся в контрацепции</li>
            </ul>
            <Box sx={{mb: '12px'}}>
              Диециклен — это европейский контрацептив, но в 2 раза дешевле.
            </Box>
            <Box sx={{mb: '12px'}}>
              Сейчас трудно покупать ежемесячно КОКи по 1 500–2 000 ₽.
            </Box>
            <Box sx={{mb: '12px'}}>
              Чтобы пациентки не отказывались от приёма, нужно предложить снизить затраты, но сохранить высокое
              европейское качество.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>
    </>
  )
}
