import {Box} from '@mui/material';

import {StyledAnchorLink, StyledHeader} from '../ui';
import {StyledBoxBorder, StyledBoxInsetShadow, StyledBoxShadow} from '../../ui';
import {icons} from '../../img';
import {Ticket} from './ticket';
import {HeaderTabs as Tabs} from './header-tabs';

type HeaderTabsProps = {
  tabActive: number
}
const HeaderTabs = ({tabActive}: HeaderTabsProps) => <Tabs tabActive={tabActive} tabLength={2}/>
export const SubpageZoely = () => {
  return (
    <>
      <StyledHeader>Зоэли®</StyledHeader>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <StyledAnchorLink href="#theme1">
          <img src={icons.arrow} alt="*"/>
          Тема #1: Перевод пациенток, принимающих в качестве контрацепции Эстеретту на Зоэли
        </StyledAnchorLink>
        <StyledAnchorLink href="#theme2">
          <img src={icons.arrow} alt="*"/>
          Тема #2: Перевод пациенток, принимающих в качестве контрацепции Клайру на Зоэли
        </StyledAnchorLink>
      </Box>

      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <HeaderTabs tabActive={0}/>
        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток, принимающих в качестве контрацепции Эстеретту на Зоэли
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила ЗОЭЛИ
                <strong> 10 пациенткам</strong> в течение месяца, которым
                требуется максимально физиологичная надежная
                и качественная контрацепция вместо Эстеретты.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения
                  пациенток, которым <strong>требуется максимально
                  физиологичная надежная контрацепция.</strong>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Примеры продуктивных вопросов для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются здоровые пациентки, которые
                  выбирают для себя всё самое лучшее/премиальное,
                  в т.ч. и контрацепцию?
                </Box>
                <Box sx={{mb: '12px'}}>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких
                  пациенток на приёме около 2–3 в день.
                  А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box sx={{mb: '12px'}}>Примеры вопросов для определения выписки:</Box>
                <ol className="list">
                  <li>
                    Скольким из этих пациенток Вы назначаете Эстеретту?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы назначаемый препарат был безопасным и имел большую доказательную
                    базу?
                  </li>
                  <li>Насколько для Вас важно, чтобы назначаемый контрацептив хорошо переносился пациентками с
                    минимальным риском побочных эффектов?
                  </li>
                  <li>Как часто к Вам обращаются пациентки с запросом на эффективную хорошо переносимую контрацепцию?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FAB)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ" img={icons.voice}>
            Зоэли® — единственный монофазный контрацептив с натуральным эстрадиолом в уникальной комбинации с NOMAC,
            имеющий
            высокий уровень доказательной базы и большой опыт применения в реальной клинической практике, что
            гарантирует
            пациентке надежную контрацепцию в сочетании с отличной переносимостью.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              За счёт уникальной комбинации NOMAC и эстрадиола в монофазном режиме, Зоэли метаболически нейтрален,
              не повышает риск ВТЭ, не влияет на вес, не вызывает депрессию и снижение либидо, улучшает связанные с
              циклом
              симптомы, что подтверждено самым крупным за всю историю КОК исследованием в реальной клинической практике
              (PRO E2 — 101 498 пациенток).
            </Box>
            <Box sx={{fontWeight: 600}}>
              Таким образом, назначая Зоэли, вы обеспечиваете Ваших пациенток максимально физиологичной, надежной и
              качественной
              контрацепцией класса Luxe.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>

      <StyledBoxShadow id="theme2" sx={{mt: 5}}>
        <HeaderTabs tabActive={1}/>

        <Box className="title" sx={{mb: 3}}>
          ПОДГОТОВКА К ВИЗИТУ
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ТЕМА ВИЗИТА:" img={icons.chat}>
                Перевод пациенток, принимающих в качестве контрацепции Клайру на Зоэли
              </Ticket>
            </StyledBoxInsetShadow>
            <StyledBoxInsetShadow>
              <Ticket title="ЦЕЛЬ ВИЗИТА ПО SMART:" img={icons.target}>
                Хочу, чтобы доктор назначила ЗОЭЛИ
                <strong> 10 пациенткам</strong> в течение месяца, которые
                принимают Клайру.
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ОТКРЫТИЕ ВИЗИТА" img={icons.people}>
                <Box sx={{mb: '12px'}}>
                  КОРПОРАТИВНЫЙ СТАНДАРТ ПРИВЕТСТВИЯ
                </Box>
                <Box sx={{mb: '12px'}}>
                  “Добрый день, Ольга Ивановна!
                  Медицинский представитель компании
                  «Зентива» (имя).
                </Box>
                <Box sx={{mb: '12px'}}>
                  Уделите мне несколько минут для обсуждения
                  пациенток, которым <strong> требуется максимально
                  физиологичная надежная контрацепция.</strong>”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ДИАГНОСТИКА
        </Box>

        <Box className="container">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ПОТЕНЦИАЛ" img={icons.search}>
                <Box sx={{mb: '12px'}}>
                  Примеры продуктивных вопросов для определения потенциала:
                </Box>
                <Box sx={{mb: '12px'}}>
                  — Как часто к Вам обращаются здоровые пациентки, которые
                  выбирают для себя всё самое лучшее/премиальное,
                  в т.ч. и контрацепцию?
                </Box>
                <Box sx={{mb: '12px'}}>
                  <strong className="pink">ССЫЛКИ НА КОЛЛЕГ: </strong>
                  “Обычно Ваши коллеги говорят, что таких
                  пациенток на приёме около 2–3 в день.
                  А как у Вас?”
                </Box>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <StyledBoxInsetShadow sx={{height: '100%'}}>
              <Ticket title="ВЫПИСКА" img={icons.edit}>
                <Box sx={{mb: '12px'}}>Примеры вопросов для определения выписки:</Box>
                <ol className="list">
                  <li>Скольким из этих пациенток Вы назначаете Клайру?</li>
                  <li>
                    Насколько для Вас важно, чтобы назначаемый контрацептив был не только надежным и безопасным, но и
                    имел удобный режим в случае необходимости сдвига менструаций?
                  </li>
                  <li>
                    Насколько для Вас важно, чтобы назначаемый контрацептив был не только надежным и безопасным, но и
                    имел удобный режим в случае пропущенной таблетке?
                  </li>
                </ol>
              </Ticket>
            </StyledBoxInsetShadow>
          </Box>
        </Box>

        <Box className="title" sx={{mb: 3, mt: 5}}>
          ПРЕЗЕНТАЦИЯ ПРОДУКТА (FABS)
        </Box>

        <StyledBoxBorder>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №1" img={icons.voice}>
            Зоэли® имеет целый ряд неоспоримых преимуществ перед Клайрой за счёт уникальной комбинации NOMAC и
            натурального
            эстрадиола в монофазном режиме, что делает назначение Зоэли более обоснованным с точки зрения преимуществ
            для пациентки.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB I:" img={icons.star}>
            <Box sx={{mb: '12px'}}>
              Зоэли содержит активный компонент, которому не требуется преобразование в организме с помощью ферментов.
            </Box>
            <Box sx={{mb: '12px'}}>
              Имеет самый большой период полувыведения среди КОК и монофазный режим приема, поэтому удобен в случае
              пропущенной таблетки.
            </Box>
            <Box sx={{mb: '12px'}}>
              Не повышает риск венозной и артериальной тромбоэмболии и имеет благоприятный профиль безопасности,
              подтвержденный исследованиями.
            </Box>
            <Box sx={{mb: '12px', fontWeight: 600}}>
              Таким образом, назначая Зоэли, вы обеспечиваете Ваших пациенток максимально физиологичной, надежной
              и качественной контрацепцией класса Luxe.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №2" img={icons.voice}>
            Благодаря монофазному режиму приёма Зоэли® в случае необходимости пациентка может управлять периодами
            кровотечений.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="FAB II:" img={icons.star}>
            <Box>
              Препарат Зоэли® имеет монофазный режим приёма 24+4. При необходимости пациентка может отдалить или
              сдвинуть период кровотечений, что позволит повысить приверженность к данному методу контрацепции.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px'}}>
          <Ticket title="КЛЮЧЕВОЕ СООБЩЕНИЕ №3" img={icons.voice}>
            Благодаря 46-часовому периоду полувыведения Зоэли® пациентка может самостоятельно безопасно продолжить прием
            со следующей таблетки в упаковке, сохраняя контрацептивный эффект.
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{mt: '20px', mb: '20px'}}>
          <Ticket title="FAB III:" img={icons.star}>
            <Box>
              Период полувыведения Зоэли® составляет 46 часов. Это больше, чем у любого другого КОК, представленного
              сегодня на рынке контрацептивов. Таким образом, Ваши пациентки могут не беспокоиться и самостоятельно
              продолжить приём препарата при случайном пропуске таблетки, сохраняя контрацептивный эффект, что позволит
              повысить приверженность к данному методу контрацепции.
            </Box>
          </Ticket>
        </StyledBoxBorder>
      </StyledBoxShadow>
    </>
  )
}
