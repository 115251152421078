import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanThirteenLinkTwo = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>ESHRE guideline: management of women with endometriosis</H1>
      <P><b>Study question:</b> What is the optimal management of women with endometriosis based on the best available evidence
        in the literature?
      </P>
      <P><b>Summary answer:</b> Using the structured methodology of the Manual for ESHRE Guideline Development, 83
        recommendations were formulated that answered the 22 key questions on optimal management of women with
        endometriosis.
      </P>
      <P><b>What is known already:</b> The European Society of Human Reproduction and Embryology (ESHRE) guideline for the
        diagnosis and treatment of endometriosis (2005) has been a reference point for best clinical care in
        endometriosis for years, but this guideline was in need of updating.
      </P>
      <P><b>Study design, size, duration:</b> This guideline was produced by a group of experts in the field using the
        methodology of the Manual for ESHRE Guideline Development, including a thorough systematic search of the
        literature, quality assessment of the included papers up to January 2012 and consensus within the guideline
        group on all recommendations. To ensure input from women with endometriosis, a patient representative was part
        of the guideline development group. In addition, patient and additional clinical input was collected during the
        scoping and review phase of the guideline.
      </P>
      <P><b>Participants/materials, setting, methods:</b> NA.
      </P>
      <P><b>Main results and the role of chance:</b> The guideline provides 83 recommendations on diagnosis of endometriosis
        and on the treatment of endometriosis-associated pain and infertility, on the management of women in whom the
        disease is found incidentally (without pain or infertility), on prevention of recurrence of disease and/or
        painful symptoms, on treatment of menopausal symptoms in patients with a history of endometriosis and on the
        possible association of endometriosis and malignancy.
      </P>
      <P><b>Limitations, reasons for caution:</b> We identified several areas in care of women with endometriosis for which
        robust evidence is lacking. These areas were addressed by formulating good practice points (GPP), based on the
        expert opinion of the guideline group members.
      </P>
      <P><b>Wider implications of the findings:</b> Since 32 out of the 83 recommendations for the management of women with
        endometriosis could not be based on high level evidence and therefore were GPP, the guideline group formulated
        research recommendations to guide future research with the aim of increasing the body of evidence.
      </P>
      <P><b>Study funding/competing interest(s):</b> The guideline was developed and funded by ESHRE, covering expenses
        associated with the guideline meetings, with the literature searches and with the implementation of the
        guideline. The guideline group members did not receive payment. All guideline group members disclosed any
        relevant conflicts of interest (see Conflicts of interest).
      </P>
      <P>
        [ссылка: <A
        href="https://pubmed.ncbi.nlm.nih.gov/24435778/">Dunselman
        et al. Human Reproduction 2014; 29:400 412.</A>]
      </P>
    </Page>
  )
}
