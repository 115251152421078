import {useEffect, useState} from 'react';
import {Box} from '@mui/material';

import {StyledBtn, StyledTextInput, Wrapper} from './ui';
import images from './img';
import Fade from '@mui/material/Fade';

export const Login = () => {
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isShowPass, setIsShowPass] = useState(false);
  const [isError, setError] = useState('');
  const {eye, eyeClose, logo} = images;
  const [isShowLogo, setShowLogo] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLogo(false);
    }, 2000)
  }, [])

  const handleSubmit = async () => {
    if(login !== 'WeAreTogether') {
      setLogin('Неверный логин')
      setError('login');
    } else if(password !== 'GM-4878935-4889178') {
      setPassword('Неверный пароль')
      setError('password');
    } else {
      localStorage.setItem('auth', 'true');
      window.location.reload();
    }
  }

  const handleToggleIsShowPass = () => {
    setIsShowPass(!isShowPass);
  }

  const handleResetError = () => {
    if(isError) {
      setLogin('');
      setPassword('');
      setError('');
    }
  }

  return (
    <Wrapper>
      <Fade in={isShowLogo} timeout={800}>
        <Box className="loader">
          <img src={logo}/>
        </Box>
      </Fade>
      {!isShowLogo && (
        <Box className="form-container">
          <Box className="title">Zentiva Woman Health</Box>
          <StyledTextInput
            value={login} type="text"
            placeholder="Логин"
            onChange={(e)=> setLogin(e.target.value)}
            onFocus={handleResetError}
            className={isError === 'login' ? 'error' : ''}
          />

          <Box sx={{position: 'relative'}}>
            <StyledTextInput
              value={password}
              type={isShowPass || isError === 'password' ? "text" : "password"}
              placeholder="Пароль"
              onChange={(e)=> setPassword(e.target.value)}
              className={isError === 'password' ? 'error' : ''}
              onFocus={handleResetError}
            />
            <img
              className={`pass-icon ${isShowPass ? 'pass-icon--open' : ''}`}
              onClick={handleToggleIsShowPass}
              src={isShowPass ? eyeClose : eye}
              alt="X"
            />
          </Box>

          <StyledBtn onClick={handleSubmit}>Вход</StyledBtn>
          {isError && (
            <Box sx={{mt: 3, textAlign: 'left', letterSpacing: '0.1px'}}>
              Если Вы забыли логин или пароль, пожалуйста, обратитесь к вашему региональному менеджеру.
            </Box>
          )}
        </Box>
      )}
    </Wrapper>
  )
}
