import Popup from 'shared/ui/Popup';
import {products} from 'shared/contants';
import PrezentationItem from '../Product/PrezentationItem';
import {ProductModalType} from './types';

type Props = {
  product: string;
  modal: ProductModalType;
  onClose: () => void;
}

export const ProductModal = ({product, modal, onClose}: Props) => {
  const {
    prezentations,
    materials,
    instructions,
    videos = [],
  } = products.find(i => i.id === product)!;

  return (
    <Popup opened={Boolean(modal)} togglePopup={onClose} title={modal || ''}>
      {modal === 'Презентации' &&
        prezentations.map(i => (
          <PrezentationItem
            key={i.pdfName}
            name={i.name}
            forceVertical={i.forceVertical}
            pdfName={i.pdfName}
            type={modal}
          />
        ))}
      {modal === 'Материалы' &&
        (materials.length === 0 ? <span style={{color: 'white'}}>Материалы отсутствуют</span> :
          materials.map(i => (
            <PrezentationItem
              key={i.pdfName}
              name={i.name}
              forceVertical={i.forceVertical}
              pdfName={i.pdfName}
              type={modal}
            />
          )))
      }
      {modal === 'Иструкции' &&
        instructions.map(i => (
          <PrezentationItem
            key={i.pdfName}
            name={i.name}
            forceVertical={i.forceVertical}
            pdfName={i.pdfName}
            type={modal}
          />
        ))}
      {modal === 'Видео' &&
        videos.map(i => (
          <PrezentationItem
            key={i.pdfName}
            name={i.name}
            pdfName={i.pdfName}
            type={modal}
            icon={'video'}
          />
        ))}
    </Popup>
  )
}
