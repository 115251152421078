import Page from 'shared/ui/Page';
import {H1} from '../ui';

import {StyledParagraph as P, Link as A} from './ui';
import {PinkLight} from '../../../styles/color';

export const ProginovaLinkOne = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Factors Associated with Effectiveness of Treatment and Reproductive Outcomes in Patients with Thin Endometrium Undergoing Estrogen Treatment</H1>
      <P><b>Background:</b> Thin endometrium is associated with poor reproductive outcomes; estrogen treatment can increase endometrial thickness (EMT). The aim of this retrospective cohort study was to investigate the factors influencing the effectiveness of estrogen treatment and reproductive outcomes after the treatment in patients with thin endometrium.</P>
      <P><b>Methods:</b> Relevant clinical data of 101 patients with thin endometrium who had undergone estrogen treatment were collected. Possible factors influencing the effectiveness of treatment were analyzed retrospectively by logistic regression analysis. Eighty-seven infertile women without thin endometrium who had undergone assisted reproduction served as controls. The cases and controls were matched for age, assisted reproduction method, and number of embryos transferred. Reproductive outcomes of study and control groups were compared using Student's t-test and the Chi-square test.</P>
      <P><b>Results:</b> At the end of estrogen treatment, EMT was ≥8 mm in 93/101 patients (92.1%). Effectiveness of treatment was significantly associated with maximal pretreatment EMT (P = 0.017) and treatment duration (P = 0.004). The outcomes of assisted reproduction were similar in patients whose treatment was successful in increasing EMT to ≥8 mm and the control group. The rate of clinical pregnancy in patients was associated with the number of good-quality embryos transferred in both fresh (P = 0.005) and frozen-thawed (P = 0.000) embryo transfer cycles.</P>
      <P><b>Conclusions:</b> Thinner EMT before estrogen treatment requires longer treatment duration and predicts poorer treatment outcomes. The effectiveness of treatment depends on the duration of estrogen administration. Assisted reproductive outcomes of patients whose treatment is successful (i.e., achieves an EMT ≥8 mm) are similar to those of controls. The quality of embryos transferred is an important predictor of assisted reproductive outcomes in patients treated successfully with exogenous estrogen.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/26612292/">S.M. Liu.et al. Factors Associated with Effectiveness of Treatment and Reproductive Outcomes in Patients with Thin Endometrium Undergoing Estrogen Treatment. Chinese medical journal 2015-11-28.</A>]
      </P>
    </Page>
  )
}
