import {styled} from '@mui/system';

export const Sup = styled('sup')`
  color: #0076BD;
  font-weight: bold;
  font-size: 22px;
  left: 2px;
  vertical-align: top; position: relative; top: -0.4em;
`

export const P = styled('p')({
  color: '#FFFFFF',
  fontSize: 20,
  fontFamily: 'SF-Pro-Text-Regular',
  lineHeight: '24px',
  letterSpacing: '0.1px',
  margin: '5px 0',

  '@media only screen and (max-width: 600px)': {
    //wordBreak: 'break-all',
  }
})