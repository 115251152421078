import main from './main.png';
import approve from './approve.png';
import blonde from './blonde.png';
import plus from './plus.png';
import warn from './warn.png';
import numOne from './num1.png';
import numTwo from './num2.png';

export default {
  main,
  approve,
  blonde,
  plus,
  warn,
  numOne,
  numTwo,
}