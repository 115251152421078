import {styled} from '@mui/system';
import {PinkLight} from 'styles/color';

export const H1 = styled('h1')({
  fontSize: 36,
  fontFamily: 'SF-Pro-Text-Bold',
  lineHeight: '43px',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const H2 = styled('h2')({
  fontSize: 28,
  fontFamily: 'SF-Pro-Text-Semibold',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const Paragraph = styled('p')({
  fontSize: 20,
  fontFamily: 'SF-Pro-Text-Regular',
  letterSpacing: '0.1px',

  '@media only screen and (max-width: 600px)': {}
})

export const Circle = styled('div')({
  width: 70,
  height: 70,
  fontSize: 36,
  fontFamily: 'SF-Pro-Text-Semibold',
  color: PinkLight,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
})