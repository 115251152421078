import {useHistory} from 'react-router-dom';
import cn from 'classnames';

import {WrapperCouple} from 'components/wrapper-couple';
import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import {Img, Paragraph as P} from 'shared/ui';
import { PinkLight, Violet} from 'styles/color';
import {bem} from 'shared/bem';

import {Sup} from './ui';
import './styles.scss';
import {
  depositphotos,
  quotationMark,
  blackMan,
  whoOrganization,
  girl,
  doctor,
  quotationMarkWhite,
  addNew,
  exclamationMark,
  approve, downArrow,
  upperArrow,
  addLight
} from './img';

import images2 from '../img';

const b = bem('diecyclen-second');

export const SectionBlock = () => {
  const history = useHistory()

  const handleRedirectToLink = () => {
    history.push('/product/vidora/link')
  }

  const handleRedirectToInstruction = () => {
    history.push('/product/vidora/instruction')
  }

  return (
    <>
      <div className={b('container')}>
        <div className={cn(b('container-medium'), b('bg-light'))}>
          <div className={b('container-medium_cell')}>
            <img src={quotationMark}/>
          </div>
          <div>
            <p className={cn(b('text-large'), b('text_indent-bot15'))}>
              По сравнению с четвертым кварталом 2021 года спад доходов ускорился в 4 раза.
            </p>
            <p className={cn(b('text-large'), b('text_indent-bot20'))}>
              В среднем, согласно Росстату, граждане РФ прожили первые три месяца 2022 года на 36 234 рублей в месяц.
            </p>
            <p className={cn(b('text-medium'))}>— сообщает finanz.ru</p>
          </div>
        </div>
        <div className={b('container-small')}>
          <img className={b('container-small_img-top')} src={depositphotos}/>
        </div>
      </div>

      <div className={b('container')}>
        <div className={b('container-small')}>
          <img src={blackMan}/>
        </div>
        <div className={cn(b('container-medium'), b('bg-light'))}>
          <div>
            <img width={200} src={whoOrganization}/>
            <p className={cn(b('text-large'), b('mx-10'), b('mt-30'), b('text-violet'))}>
              29 Мая 2021 года <br/> World Health Organization <br/> провозгласила:
            </p>
            <p className={cn(b('text-medium'))}>
              Выбор женщин и их права на охрану сексуального и репродуктивного здоровья, включая доступ к
              контрацепции,
              должны уважать независимо от статуса СОVID-19.
            </p>
          </div>
        </div>
      </div>

      <div className={b('container-wrapper')}>
        <div className={b('container-wrapper-inner')}>
          <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
            Многие пациентки не могут позволить себе терапию оригинальным препаратом.
          </p>
          <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
            Назначая оригинальный препарат, доктор в половине случаев не назначает ничего, так как пациентка,
            услышав в аптеке цену, не купит его.
          </p>
          <div
            style={{boxShadow: 'none'}}
            className={cn(b('container-small'), b('container-small_img-mh'), b('visible-mobile'), b('mb-20'))}
          >
            <img src={girl}/>
          </div>
          <p className={cn(b('text-large'), b('text-pink'), b('mb-20'), b('pr-40'))}>
            Сейчас трудно покупать оригинальные КОКи по цене 1000-1500 руб. в месяц при средней зарплате в 36 000
            рублей.
          </p>
          <div
            style={{boxShadow: 'none'}}
            className={cn(b('container-small'), b('container-small_img-mh'), b('visible-mobile'), b('mb-20'))}
          >
            <img src={doctor}/>
          </div>
          <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
            Чтобы пациентки совсем не бросили принимать контрацепцию и не пришли к Вам за направлением на аборт,
            ухудшая эту ужасную статистику на Вашем участке, им нужно предложить снизить затраты, но при этом,
            сохранить
            высокое качество контрацептива.
          </p>
        </div>
        <div className={cn(b('container-wrapper-inner'), b('visible-desktop'))}>
          <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
            <div style={{boxShadow: 'none'}} className={cn(b('container-small'), b('container-small_img-mh'))}>
              <img src={girl}/>
            </div>
            <div style={{boxShadow: 'none'}} className={cn(b('container-small'), b('container-small_img-mh'))}>
              <img src={doctor}/>
            </div>
          </div>
        </div>
      </div>

      <div className={b('container')}>
        <div className={cn(b('container-small'), b('fairy'))}>
          <div style={{width: 60, height: 60, marginBottom: 4}} className={b('container-medium_cell')}>
            <img src={quotationMarkWhite}/>
          </div>
          <p style={{maxWidth: 292}} className={cn(b('text-medium'), b('text-white'))}>
            Владимир Путин берет под контроль цены на продукты и лекарства
          </p>
        </div>
        <div className={cn(b('container-medium'), b('bg-light'))}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <h2 className={cn(b('text-large'), b('text-violet'), b('mb-10'))}>
              Доктору в арсенале необходим препарат, который обладал бы двумя характеристиками:
            </h2>
            <div style={{display: 'flex'}}>
              <span style={{marginTop: 12, marginRight: 12}}>
                <img src={addNew}/>
              </span>
              <h2 className={cn(b('text-large'), b('mx-15'))}>
                <span>Эффективностью оригинального препарата</span>
              </h2>
            </div>
            <div style={{display: 'flex'}}>
              <span style={{marginTop: 12, marginRight: 12}}>
                <img src={addNew}/>
              </span>
              <h2 className={cn(b('text-large'), b('mx-15'))}>
                <span>Социально-ответственной ценой</span>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div style={{padding: '30px 20px'}} className={b('container-wrapper')}>
        <p onClick={handleRedirectToLink}
           style={{fontSize: 36, lineHeight: '43px', fontFamily: 'SF Pro Bold', cursor: 'pointer', margin: 0}}
           className={b('text-pink')}>
          Основное достоинство дженериков —
          <br/> относительно низкая стоимость при той же <br/>
          терапевтической эффективности,
          что и у <br/> оригинального препарата<Sup>1</Sup>
        </p>
      </div>

      <WrapperCouple>
        <WrapperShell className="small" sx={{height: 464, minWidth: 412}}>
          <div style={{display: 'flex'}}>
              <span style={{marginTop: 12, marginRight: 12}}>
                <img src={exclamationMark}/>
              </span>
            <div className={cn(b('text-large'), b('mx-15'))}>
              <h2 style={{marginTop: 0}} className={cn(b('text-large'), b('text-pink'), b('mb-10'))}>
                ДЖЕНЕРИК
              </h2>
              <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
                Препарат, который является терапевтическим эквивалентом бренда.
              </p>
              <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
                Выпускается только после истечения срока действия патента на оригинальный препарат.
              </p>
            </div>
          </div>
          <div className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
            <div style={{marginTop: 0}} className={cn(b('mb-20'), b('mt-20'))}>
               <span
                 style={{fontSize: 60, fontFamily: 'SF Pro Bold'}}
                 className={b('text-pink')}
               >
              80
            </span>
              <span className={cn(b('text-large'), b('text-pink'))}>% рынка</span> <br/>
            </div>
            лекарственных препаратов в нашей стране составляют <strong>дженерики</strong>
          </div>
        </WrapperShell>

        <WrapperShell sx={{boxShadow: 'none', p: 0, borderRadius: 0, textAlign: 'center'}}>
          <WrapperCouple>
            <WrapperShell
              sx={{boxShadow: 'none', p: 0, borderRadius: 0, display: 'flex', flexDirection: 'column', gap: '10px'}}>
              <WrapperShellBox sx={{
                backgroundColor: Violet,
                height: 64,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <P sx={{textTransform: 'uppercase', m: 0}}>Дженерик</P>
              </WrapperShellBox>
              <WrapperShellBox
                sx={{height: '190px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div>
                  <Img sx={{m: '0px 0 8px'}} src={approve}/>
                  <P sx={{m: 0}}>НЕТ ЗАТРАТ НА ДОПОЛНИТЕЛЬНЫЕ ИССЛЕДОВАНИЯ</P>
                </div>
              </WrapperShellBox>
              <WrapperShellBox sx={{height: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <P sx={{m: 0}}>Исследования биоэквивалентности</P>
              </WrapperShellBox>
              <WrapperShellBox sx={{backgroundColor: Violet, p: 1}}>
                <P sx={{textTransform: 'uppercase', m: 0, mb: '6px'}}>Стоимость</P>
                <Img src={downArrow}/>
              </WrapperShellBox>
            </WrapperShell>

            <WrapperShell
              sx={{boxShadow: 'none', p: 0, borderRadius: 0, display: 'flex', flexDirection: 'column', gap: '10px'}}>
              <WrapperShellBox sx={{
                backgroundColor: PinkLight,
                p: '0 20px',
                height: 64,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <P className="text-dark" sx={{textTransform: 'uppercase', m: 0, fontWeight: 'bold'}}>Оригинальный
                  препарат</P>
              </WrapperShellBox>
              <WrapperShellBox sx={{height: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <P sx={{m: 0}}>Синтез молекулы</P>
              </WrapperShellBox>
              <WrapperShellBox sx={{
                height: '90px',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Img sx={{position: 'absolute', top: '-23px'}} src={addLight}/>
                <P sx={{m: 0}}>Разработка лекарственной формы</P>
              </WrapperShellBox>
              <WrapperShellBox sx={{
                height: '90px',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Img sx={{position: 'absolute', top: '-22px'}} src={addLight}/>
                <P sx={{m: 0}}>Доклинические и клинические исследования</P>
              </WrapperShellBox>
              <WrapperShellBox sx={{backgroundColor: PinkLight, p: 1}}>
                <Img src={upperArrow}/>
                <P className="text-dark"
                   sx={{textTransform: 'uppercase', m: 0, mb: '6px', fontWeight: 'bold'}}>Стоимость</P>
              </WrapperShellBox>
            </WrapperShell>
          </WrapperCouple>
        </WrapperShell>
      </WrapperCouple>

      <div className={b('container')}>
        <div
          style={{display: 'flex', flexDirection: 'column', padding: '10px 20px'}}
          className={cn(b('container-medium'), b('bg-light'))}
        >
          <h2 style={{fontSize: 36}} className={cn(b('text-violet'), b('text-large'), b('mb-15'))}>ВИДОРА</h2>
          <h2 style={{fontSize: 27, marginBottom: 10, marginTop: 10}} className={b('mb-30')}>
            ИМЕЕТ ТОТ ЖЕ СОСТАВ И ТЕ ЖЕ ПОКАЗАНИЯ, ЧТО И ОРИГИНАЛЬНЫЙ ПРЕПАРАТ
          </h2>

          <h2 style={{marginBottom: 0}} className={cn(b('text-violet'), b('mb-10'))}>СОСТАВ:</h2>
          <p style={{fontFamily: 'SF Pro', fontSize: 20, marginBottom: 0, marginTop: 8, lineHeight: '26px'}}>
            21 активная таблетка желтого цвета. <br/>
            1 таблетка содержит: дроспиренон – 3,00 мг, <br/>
            этинилэстрадиол – 0,03 мг. <br/>
            7 таблеток плацебо белого цвета.
          </p>
          <h2 style={{cursor: 'pointer'}} onClick={handleRedirectToInstruction}
              className={cn(b('text-violet'), b('mb-10'))}>ПОКАЗАНИЯ К
            ПРИМЕНЕНИЮ<Sup>2 </Sup>:</h2>
          <p style={{fontFamily: 'SF Pro', fontSize: 20, marginTop:4, marginBottom: 20, lineHeight: '26px'}}>Контрацепция </p>
        </div>
        <div className={b('container-small')}>
          <img className={b('container-small_img-top')} src={images2.girlFriday}/>
        </div>
      </div>
    </>
  )
}
