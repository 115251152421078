import Page from 'shared/ui/Page';
import {H1} from '../ui';

import {StyledParagraph as P, Link as A} from './ui';
import {PinkLight} from '../../../styles/color';

export const ProginovaLinkFour = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Luteal estradiol supplementation in gonadotropin-releasing hormone antagonist cycles for infertile patients in
        vitro fertilization</H1>
      <P><b>Objective:</b> To evaluate the effect of the addition of estradiol to luteal progesterone supplementation in
        GnRH antagonist cycles for infertile patients undergoing IVF/ICSI.</P>
      <P><b>Methods:</b> One hundred and ten infertile patients, aged 28 to 39 years, were recruited for this
        prospective randomized study. They were randomly assigned to receive vaginal progesterone gel (Crinone) along
        with 4 mg estradiol valerate (group 1, n=55) or only Crinone (group 2, n=55) for luteal support. A GnRH
        antagonist multiple dose protocol using recombinant human FSH was used for controlled ovarian stimulation (COS)
        in all of the subjects. The COS results and pregnancy outcomes of the two groups were compared.</P>
      <P><b>Results:</b> Group 1 and 2 were comparable with respect to the patient characteristics. The COS and IVF
        results were also comparable between the two groups. There were no differences in the clinical pregnancy rate
        (PR) and multiple PR between the two groups. However, the embryo implantation rate were significantly higher in
        group 1 than that in group 2 (22.2% vs. 13.3%, p=0.035). The incidence of luteal vaginal bleeding (LVB) was
        significantly lower in group 1 (7.4% vs. 27.8%, p=0.010).</P>
      <P><b>Conclusion:</b> The addition of estradiol to luteal progesterone supplementation in GnRH antagonist cycles
        reduces the incidence of LVB and increases the embryo implantation rate in infertile patients undergoing
        IVF/ICSI.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/24179871/">Kwon SK, et al. Luteal estradiol supplementation in gonadotropinreleasing hormone antagonist
        cycles for infertile patients in vitro fertilization. Clin Exp Reprod Med. 2013 Sep;40(3):131-134.</A>]
      </P>
    </Page>
  )
}
