import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const KlomonormLinkTwo = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>2016 IMS Recommendations on women's midlife health and menopause hormone therapy</H1>
      <P>
        The International Menopause Society (IMS) has produced these new 2016 recommendations on women's midlife health
        and menopause hormone therapy (MHT) to help guide health-care professionals in optimizing their management of
        women in the menopause transition and beyond. The term MHT has been used to cover therapies including estrogens,
        progestogens and combined regimens. For the first time, the 2016 IMS recommendations now include grades of
        recommendations, levels of evidence and 'good practice points', in addition to section-specific references.
        Where possible, the recommendations are based on and linked to the evidence that supports them, unless
        good-quality evidence is absent. Particular attention has been paid to published evidence from 2013 onwards, the
        last time the IMS recommendations were updated. Databases have been extensively searched for relevant
        publications using key terms specific to each specialist area within menopause physiology and medicine.
        Information has also been drawn from international consensus statements published by bodies such as the IMS, the
        European Menopause and Andropause Society and the North American Menopause Society. The recommendations have
        been produced by experts derived mainly from the IMS, with the assistance of key collaborators where deemed
        advantageous. In preparing these international recommendations, experts have taken into account geographical
        variations in medical care, prevalence of diseases, and country-specific attitudes of the public, medical
        community and health authorities towards menopause management. The variation in availability and licensing of
        MHT and other products has also been considered.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/26872610/">Baber R.J. et al. IMS Recomendations on women`s midlife health and menopause hormon therapy.
        Climacteric. 2016; 19: 109–150.</A>]
      </P>
    </Page>
  )
}
