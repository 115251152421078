import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const KlomonormLinkSeven = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Updated 2013 International Menopause Society recommendations on menopausal hormone therapy and preventive strategies for midlife health</H1>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/23672656/">de Villiers T.J., Pines A., Panay N. et al. on behalf of the International Menopause Society. Updated 2013 International Menopause Society recommendations on menopausal hormone therapy and preventive strategies for midlife health. Climacteric. 2013; 16: 316</A>]
      </P>
    </Page>
  )
}
