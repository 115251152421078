import {useHistory} from 'react-router-dom';

import {WrapperShell} from 'components/wrapper-shell';
import {WrapperCouple} from 'components/wrapper-couple';
import Page from 'shared/ui/Page';
import {List as UL, ListItem as LI, Tables} from 'shared/ui';
import {PinkLight} from 'styles/color';

import {StyledH2 as H2, StyledH1 as H1, StyledParagraph as P, LinkA as A} from './ui';
import {Div, Span} from '../ui';
import pdf from '../../../pdfs/vidora/Видора_инструкция.pdf';

export const VidoraInstruction = () => {
  const history = useHistory();

  const handlerRedirectToDiecyclen = () => {
    history.push('/product/vidora');
  }

  return (
    <Page onClose={handlerRedirectToDiecyclen} isCross>
      <Div sx={{textAlign: 'center'}}>
        <H2 sx={{fontSize: 20, mb: 3}}>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</H2>
        <H1>ИНСТРУКЦИЯ</H1>
        <H2 sx={{fontSize: 20, textTransform: 'none', mb: 3, mt: 2}}>по применению лекарственного препарата для
          медицинского применения</H2>
        <H1 sx={{marginBottom: 20}}>ВИДОРА</H1>
      </Div>
      <H2 sx={{fontSize: 20}}>РЕГИСТРАЦИОННЫЙ НОМЕР: ЛП-002870</H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ТОРГОВОЕ НАИМЕНОВАНИЕ: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}> Видора®</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        МЕЖДУНАРОДНОЕ НЕПАТЕНТОВАННОЕ НАИМЕНОВАНИЕ: <Span
        sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>дроспиренон + этинилэстрадиол</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ЛЕКАРСТВЕННАЯ ФОРМА: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>
        таблетки, покрытие пленочной оболочкой
      </Span>
      </H2>

      <H2 style={{marginTop: 50}}>СОДЕРЖАНИЕ</H2>
      <WrapperCouple sx={{display: 'flex', justifyContent: 'space-between'}}>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#compound'}>СОСТАВ</A>
          <A href={'#description'}>ОПИСАНИЕ</A>
          <A href={'#farm-group'}>ФАРМАКОТЕРАПЕВТИЧЕСКАЯ ГРУППА</A>
          <A href={'#farm-property'}>ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА</A>
          <A href={'#ind-for-use'}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ</A>
          <A href={'#dication'}>ПРОТИВОПОКАЗАНИЯ</A>
          <A href={'#warning'}>С ОСТОРОЖНОСТЬЮ</A>
          <A href={'#for-use-feed'}>ПРИМЕНЕНИЕ В ПЕРИОД БЕРЕМЕННОСТИ И ГРУДНОГО<br/> ВСКАРМЛИВАНИЯ</A>
          <A href={'#dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</A>
          <A href={'#effect-action'}>ПОБОЧНОЕ ДЕЙСТВИЕ</A>
          <A href={'#over-dozing'}>ПЕРЕДОЗИРОВКА</A>
        </WrapperShell>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#another-medicines'}>ВЗАИМОДЕЙСТВИЕ С ДРУГИМИ ЛЕКАРСТВЕННЫМИ <br/> СРЕДСТВАМИ</A>
          <A href={'#special-instructions'}>ОСОБЫЕ УКАЗАНИЯ</A>
          <A href={'#managment-transport'}>ВЛИЯНИЕ НА СПОСОБНОСТЬ УПРАВЛЕНИЯ <br/>ТРАНСПОРТНЫМИ СРЕДСТВАМИ,
            МЕХАНИЗМАМИ</A>
          <A href={'#form-production'}>ФОРМА ВЫПУСКА</A>
          <A href={'#keeper'}>УСЛОВИЯ ХРАНЕНИЯ</A>
          <A href={'#deadline'}>СРОК ГОДНОСТИ</A>
          <A href={'#holiday'}>УСЛОВИЯ ОТПУСКА</A>
          <A href={'#owner'}>ВЛАДЕЛЕЦ (ДЕРЖАТЕЛЬ) РЕГИСТРАЦИОННОГО<br/>УДОСТОВЕРЕНИЯ</A>
          <A href={'#producer'}>ПРОИЗВОДИТЕЛЬ</A>
        </WrapperShell>
      </WrapperCouple>

      <H2 id={'compound'}>СОСТАВ</H2>
      <P><b><u>1 активная таблетка, покрытая пленочной оболочкой, содержит:</u></b></P>
      <P>Действующее вещества:</P>
      <P>дроспиренон - 3,00 мг, этинилэстрадиол (микронизированный) - 0,03 мг.</P>
      <P><b>Вспомогательные вещества:</b></P>
      <P>
        лактозы моногидрат (Fast Flo) - 62,00 мг, крахмал кукурузный - 14,00 мг, крахмал кукурузный
        прежелатинизированный (Sepistab ST200) - 12,57 мг, кросповидон (Plasdone XL-10) - 1,00 мг, кросповидон (Plasdone
        XL) - 2,50 мг, повидон-КЗО (Kollidon К-30) - 3,40 мг, полисорбат 80 (Tween 80) - 1,00 мг, магния стеарат - 0,50
        мг, пленочная оболочка Опадрай II желтый - 3,00 мг.
      </P>
      <P><b>Состав пленочной оболочки:</b></P>
      <P>Поливиниловый спирт частично гидролизованный - 40,000%; титана диоксид (Е171) - 24,150%; макрогол - 20,200%;
        тальк - 14,800%; железа оксид желтый (Е172) - 0,850%.</P>
      <P><b><u>1 таблетка плацебо, покрытая пленочной оболочкой, содержит:</u></b></P>
      <P><b>Вспомогательные вещества:</b></P>
      <P>Лактоза безводная - 89,50 мг, повидон К-30 - 10,00 мг, магния стеарат - 0,50 мг, пленочная оболочка Опадрай II
        белый - 4,00 мг.</P>
      <P><b>Состав пленочной оболочки: </b></P>
      <P>поливиниловый спирт - 40,00%; титана диоксид (Е171) - 25,00%; макрогол/PEG 3350 - 20,20%; тальк (Е553Ь) -
        14,80%.</P>

      <H2 id={'description'}>ОПИСАНИЕ</H2>
      <P><b>Активные таблетки</b></P>
      <P>Круглые двояковыпуклые таблетки, покрытые пленочной оболочкой от светло-желтого до желтого цвета.</P>
      <P><b>Таблетки плацебо:</b></P>
      <P>Круглые двояковыпуклые таблетки, покрытые пленочной оболочкой белого цвета.</P>

      <H2 id={'farm-group'}>Фармакотерапевтическая группа: </H2>
      <P>Контрацептивное средство комбинированное (эстроген+гестаген).</P>
      <P><b>Код ATX</b>: G03AA12</P>

      <H2 id={'farm-property'}>Фармакологические свойства</H2>
      <P><b>Фармакодинамика</b></P>
      <P>
        Контрацептивный эффект комбинированных пероральных контрацептивных препаратов (КОК) основан на взаимодействии
        различных факторов, наиболее важными из которых являются подавление овуляции, повышение вязкости секрета шейки
        матки и изменения в эндометрии.
      </P>
      <P>
        При правильном применении препарата Видора® индекс Перля (показатель, отражающий число беременностей у 100
        женщин, применяющих контрацептив в течение года) составляет менее 1. При пропуске таблеток или неправильном
        применении индекс Перля может возрастать.
      </P>
      <P>
        У женщин, принимающих КОК, менструальный цикл становится более регулярным, реже наблюдаются болезненные
        менструальноподобные кровотечения, уменьшается интенсивность и продолжительность кровотечения, в результате чего
        снижается риск железодефицитной анемии. Также имеются данные о снижении риска развития рака эндометрия и
        яичников при приеме КОК.
      </P>
      <P>
        Дроспиренон, входящий в состав препарата Видора®, обладает антиминералокортикоидной активностью и способен
        предупреждать увеличение массы тела и появление других симптомов (например, отеков), связанных с
        эстрогензависимой задержкой жидкости. Дроспиренон оказывает положительное воздействие на предменструальный
        синдром. В сочетании с этинилэстрадиолом дроспиренон демонстрирует благоприятный эффект на липидный профиль,
        характеризующийся повышением липопротеинов высокой плотности. Дроспиренон также обладает антиандрогенной
        активностью и способствует уменьшению угрей (акне), жирности кожи и волос (себореи). Эти особенности
        дроспиренона следует учитывать при выборе контрацептива женщинам с гормонозависимой задержкой жидкости, а также
        женщинам с акне и себореей.
      </P>
      <P>
        Дроспиренон не обладает андрогенной, эстрогенной, глюкокортикоидной и антиглюкокортикоидной активностью. Все это
        в сочетании с антиминералокортикоидным и антиандрогенным действием обеспечивает дроспиренону биохимический и
        фармакологический профиль, сходный с естественным прогестероном.
      </P>
      <P><b>Фармакокинетика</b></P>
      <P><b><u>Дроспиренон</u></b></P>
      <P><b>Абсорбция</b></P>
      <P>При приеме внутрь дроспиренон быстро и почти полностью абсорбируется. После однократного приёма максимальная
        концентрация (Сmах) дроспиренона в плазме крови, равная 38 нг/мл, достигается через 1-2 часа. Прием пищи не
        влияет на биодоступность, которая колеблется в диапазоне от 76 до 85%.
      </P>
      <P><b>Распределение</b></P>
      <P>
        Дроспиренон связывается с альбумином плазмы крови и не связывается с глобулином, связывающим половые гормоны
        (ГСПГ), или кортикостероид-связывающим глобулином (КСГ). Лишь 3-5% от общей концентрации вещества в плазме крови
        присутствует в виде свободного гормона, 95-97% вещества неспецифически связываются с альбумином. Индуцированное
        этинилэстрадиолом повышение ГСПГ не влияет на связывание дроспиренона с белками плазмы крови. Средний кажущийся
        объем распределения составляет 3,7±1,2 л/кг.
      </P>
      <P><b>Метаболизм</b></P>
      <P>
        После перорального приема дроспиренон полностью метаболизируется. Большинство метаболитов в плазме крови
        представлены кислотными формами дроспиренона. Дроспиренон также является субстратом для окислительного
        метаболизма, катализируемого изоферментом CYP3A4. Скорость метаболического клиренса дроспиренона из плазмы крови
        составляет 1,5±0,2 мл/мин/кг.
      </P>
      <P><b>Выведение</b></P>
      <P>
        Концентрация дроспиренона в плазме крови снижается двухфазно. Вторая, окончательная фаза имеет период
        полувыведения (Т1/2) около 31 часа. В неизмененном виде дроспиренон экскретируется в следовых количествах. Его
        метаболиты выводятся через желудочно-кишечный тракт и почками в соотношении примерно 1,2:1,4. Период
        полувыведения метаболитов дроспиренона составляет примерно 40 часов.
      </P>
      <P><b>Равновесная концентрация</b></P>
      <P>
        Концентрация ГСПГ не оказывает влияния на показатели фармакокинетики дроспиренона. При ежедневном применении
        препарата внутрь концентрация дроспиренона в плазме крови повышается в 2-3 раза, равновесная концентрация
        достигается через 8 дней приема препарата.
      </P>
      <P><b>При нарушении функции почек</b></P>
      <P>
        Исследования показали, что концентрация дроспиренона в плазме крови женщин с почечной недостаточностью легкой
        степени (клиренс креатинина (КК) - 50-80 мл/мин) при достижении равновесного состояния и у женщин с нормальной
        функцией почек (КК - более 80 мл/мин) сопоставимы. Тем не менее, у женщин с почечной недостаточностью средней
        степени тяжести (КК - 30-50 мл/мин) средняя концентрация дроспиренона в плазме крови была на 37% выше, чем у
        пациенток с нормальной функцией почек. Не отмечено изменения концентрации калия в плазме крови при применении
        дроспиренона.
      </P>
      <P>Фармакокинетика дроспиренона не изучалась у пациенток с почечной недостаточностью тяжелой степени.</P>
      <P><b>При нарушении функции печени</b></P>
      <P>
        У женщин с печеночной недостаточностью средней степени тяжести (класс В по шкале Чайлд-Пью) площадь под кривой
        «концентрация-время» (AUC) сопоставима с соответствующим показателем у здоровых женщин с близкими значениями
        Сmax в фазе абсорбции и распределения, Т1/2 дроспиренона у больных с печеночной недостаточностью средней степени
        тяжести оказался в 1,8 раз выше, чем у здоровых добровольцев.
      </P>
      <P>
        У больных с печеночной недостаточностью средней степени тяжести отмечено снижение клиренса дроспиренона
        приблизительно на 50% по сравнению со здоровыми женщинами, при этом не отмечено различий в концентрации калия в
        плазме крови в изучаемых группах. При выявлении сахарного диабета и сопутствующем применении спиронолактона (оба
        состояния расцениваются как факторы, предрасполагающие к развитию гиперкалиемии), повышения концентрации калия в
        плазме крови не установлено. Таким образом, можно заключить, что переносимость дроспиренона у женщин с
        печеночной недостаточностью легкой и средней степени тяжести хорошая (класс В по шкале Чайлд-Пью).
      </P>
      <P>
        Фармакокинетика дроспиренона не изучалась у пациенток с печеночной недостаточностью тяжелой степени.
      </P>
      <P><b>Этническая принадлежность</b></P>
      <P>Не установлено влияния этнической принадлежности (исследование проведено на когортах женщин европеоидной расы и
        японок) на параметры фармакокинетики дроспиренона и этинилэстрадиола.</P>
      <P><b><u>Этинилэстрадиол</u></b></P>
      <P>
        После приёма внутрь этинилэстрадиол быстро и полностью абсорбируется. Сmах - 100 нг/мл достигается в течение 1-2
        часов. Во время всасывания и «первого прохождения» через печень этинилэстрадиол метаболизируется, в результате
        чего его биодоступность при приеме внутрь составляет в среднем около 45% при высокой межиндивидуальной
        вариабельности - от 20 до 65%. Одновременный приём пищи в отдельных случаях сопровождается снижением
        биодоступности этинилэстрадиола на 25%.
      </P>
      <P><b>Абсорбция</b></P>
      <P></P>
      <P><b>Распределение</b></P>
      <P>
        Этинилэстрадиол неспецифически, но прочно связывается с альбумином плазмы крови (около 98%) и индуцирует
        повышение концентрации в плазме крови ГСПГ. Предполагаемый объем распределения составляет 5 л/кг.
      </P>
      <P><b>Метаболизм</b></P>
      <P>
        Этинилэстрадиол подвергается значительному первичному метаболизму в кишечнике и печени. Этинилэстрадиол и его
        окислительные метаболиты первично конъюгированы с глюкуронидами или сульфатом. Скорость метаболического клиренса
        этинилэстрадиола составляет около 5 мл/мин/кг.
      </P>
      <P><b>Выведение</b></P>
      <P>
        Снижение концентрации этинилэстрадиола в плазме крови носит двухфазный характер; первая фаза характеризуется
        периодом полувыведения около 1 часа, вторая - 20 часов. Этинилэстрадиол практически не выводится в неизмененном
        виде. Метаболиты этинилэстрадиола выводятся почками и через кишечник в соотношении 4:6. Период полувыведения
        метаболитов составляет примерно 24 ч.
      </P>
      <P><b>Равновесная концентрация</b></P>
      <P>Равновесное состояние достигается во второй половине цикла приема препарата, когда концентрация
        этинилэстрадиола в плазме крови повышается на 40-110% по сравнению с применением разовой дозы.</P>

      {/*<P sx={{mt: 4}}><b>Таблица 1. Фармакокинетические параметры тиболона (доза 2,5 мг)</b></P>*/}

      {/*<Tables.WrapperTable>*/}
      {/*  <Tables.Table className="align-left" sx={{m: '10px 0', minWidth: 900}}>*/}
      {/*    <Tables.Thead sx={{backgroundColor: 'transparent'}}>*/}
      {/*      <Tables.Tr sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Bold'}}>*/}
      {/*        <Tables.Td/>*/}
      {/*        <Tables.Td colSpan={2}>*/}
      {/*          <Span sx={{width: 185, display: 'block'}}>Тиболон</Span>*/}
      {/*        </Tables.Td>*/}
      {/*        <Tables.Td colSpan={2}>*/}
      {/*          <Span sx={{width: 185, display: 'block'}}>3-альфа- гидрокситиболон</Span>*/}
      {/*        </Tables.Td>*/}
      {/*        <Tables.Td colSpan={2}>*/}
      {/*          <Span sx={{width: 185, display: 'block'}}>3-бета- гидрокситиболон</Span>*/}
      {/*        </Tables.Td>*/}
      {/*        <Tables.Td colSpan={2}>*/}
      {/*          <Span sx={{width: 185, display: 'block'}}>дельта-4-изомер</Span>*/}
      {/*        </Tables.Td>*/}
      {/*      </Tables.Tr>*/}
      {/*    </Tables.Thead>*/}
      {/*    <Tables.Body>*/}
      {/*      <Tables.Tr>*/}
      {/*        <Tables.Td sx={{minWidth: 185}}/>*/}
      {/*        <Tables.Td>ОД</Tables.Td>*/}
      {/*        <Tables.Td>МД</Tables.Td>*/}
      {/*        <Tables.Td>ОД</Tables.Td>*/}
      {/*        <Tables.Td>МД</Tables.Td>*/}
      {/*        <Tables.Td>ОД</Tables.Td>*/}
      {/*        <Tables.Td>МД</Tables.Td>*/}
      {/*        <Tables.Td>ОД</Tables.Td>*/}
      {/*        <Tables.Td>МД</Tables.Td>*/}
      {/*      </Tables.Tr>*/}
      {/*      <Tables.Tr>*/}
      {/*        <Tables.Td>Максимальная концентрация Сmах (НГ/МЛ)</Tables.Td>*/}
      {/*        <Tables.Td>1,37</Tables.Td>*/}
      {/*        <Tables.Td>1,72</Tables.Td>*/}
      {/*        <Tables.Td> 14,23</Tables.Td>*/}
      {/*        <Tables.Td>14,15</Tables.Td>*/}
      {/*        <Tables.Td>3,43</Tables.Td>*/}
      {/*        <Tables.Td>3,75</Tables.Td>*/}
      {/*        <Tables.Td>0,47</Tables.Td>*/}
      {/*        <Tables.Td>0,43</Tables.Td>*/}
      {/*      </Tables.Tr>*/}
      {/*      <Tables.Tr>*/}
      {/*        <Tables.Td>Средняя концентрация Ссредняя</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td></Tables.Td>*/}
      {/*        <Tables.Td>1,88</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*      </Tables.Tr>*/}
      {/*      <Tables.Tr>*/}
      {/*        <Tables.Td>Время достижения максимальной концентрации Тmах (ч)</Tables.Td>*/}
      {/*        <Tables.Td>1,08</Tables.Td>*/}
      {/*        <Tables.Td>1,19</Tables.Td>*/}
      {/*        <Tables.Td>1,21</Tables.Td>*/}
      {/*        <Tables.Td>1,15</Tables.Td>*/}
      {/*        <Tables.Td>1,37</Tables.Td>*/}
      {/*        <Tables.Td>1,35</Tables.Td>*/}
      {/*        <Tables.Td>1,64</Tables.Td>*/}
      {/*        <Tables.Td>1,65</Tables.Td>*/}
      {/*      </Tables.Tr>*/}
      {/*      <Tables.Tr>*/}
      {/*        <Tables.Td>Период полувыведения Т1/2 (ч)</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>5,78</Tables.Td>*/}
      {/*        <Tables.Td>7,71</Tables.Td>*/}
      {/*        <Tables.Td>5,87</Tables.Td>*/}
      {/*        <Tables.Td></Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*      </Tables.Tr>*/}
      {/*      <Tables.Tr>*/}
      {/*        <Tables.Td>Минимальная концентрация Cmin (НГ/МЛ)</Tables.Td>*/}
      {/*        <Tables.Td></Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td></Tables.Td>*/}
      {/*        <Tables.Td>0,23</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*      </Tables.Tr>*/}
      {/*      <Tables.Tr>*/}
      {/*        <Tables.Td>Площадь под кривой AUC0-24 (НГ/МЛХЧ)</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>53,23</Tables.Td>*/}
      {/*        <Tables.Td>44,73</Tables.Td>*/}
      {/*        <Tables.Td>16,23</Tables.Td>*/}
      {/*        <Tables.Td>9,20</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*        <Tables.Td>—</Tables.Td>*/}
      {/*      </Tables.Tr>*/}
      {/*    </Tables.Body>*/}
      {/*  </Tables.Table>*/}
      {/*</Tables.WrapperTable>*/}

      {/*<P>ОД - однократная доза; МД - многократная доза.</P>*/}
      {/*<P>*/}
      {/*  Выведение тиболона в основном происходит в форме конъюгированных метаболитов (в основном сульфатированных).*/}
      {/*  Часть принятого тиболона выводится почками, большая часть - через кишечник.*/}
      {/*</P>*/}
      {/*<P> Фармакокинетические параметры тиболона не зависят от функции почек. </P>*/}

      <H2 id={'ind-for-use'}>Показания к применению</H2>
      <P>Контрацепция</P>

      <H2 id={'dication'}>противопоказания</H2>
      <P>
        Препарат Видора® противопоказан при наличии любого из состояний/заболеваний/ факторов риска, перечисленных ниже.
        Если какие-либо из этих состояний/заболеваний развиваются впервые на фоне приема, прием препарата должен быть
        немедленно прекращен.
      </P>
      <UL>
        <LI><P>
          Тромбозы (венозные и артериальные) и тромбоэмболии (в том числе тромбоз глубоких вен, тромбоэмболия легочной
          артерии, инфаркт миокарда, инсульт), цереброваскулярные нарушения - в настоящее время или в анамнезе.
        </P></LI>
        <LI><P>
          Выявленная приобретенная или наследственная предрасположенность к венозному или артериальному тромбозу,
          включая резистентность к активированному протеину С, дефицит антитромбина III, дефицит протеина С, дефицит
          протеина S, гипергомоцистеинемия, антитела к фосфолипидам (антитела к кардиолипину, волчаночный
          антикоагулянт).
        </P></LI>
        <LI><P>Наличие высокого риска венозного или артериального тромбоза (см. раздел «Особые указания»).</P></LI>
        <LI><P>Мигрень с очаговыми неврологическими симптомами в настоящее время или в анамнезе.</P></LI>
        <LI><P>Сахарный диабет с диабетической ангиопатией.</P></LI>
        <LI><P>Панкреатит с выраженной гипертриглицеридемией в настоящее время или в анамнезе.</P></LI>
        <LI><P>Печеночная недостаточность и тяжелые заболевания печени (до нормализации печеночных проб).</P></LI>
        <LI><P>
          Совместное применение с противовирусными препаратами прямого действия (ПППД), содержащими омбитасвир,
          паритапревир, дасабувир или комбинацию этих веществ (см. раздел «Взаимодействие с другими лекарственными
          средствами и иные виды взаимодействия»).
        </P></LI>
        <LI><P>Опухоли печени (доброкачественные или злокачественные) в настоящее время или в анамнезе.</P></LI>
        <LI><P>Тяжелая или острая почечная недостаточность.</P></LI>
        <LI><P>Выявленные гормонозависимые злокачественные заболевания (в том числе половых органов или молочных желез)
          или подозрение на них.</P></LI>
        <LI><P>Кровотечение из половых путей неясной этиологии.</P></LI>
        <LI><P>Беременность, в том числе предполагаемая.</P></LI>
        <LI><P>Период грудного вскармливания.</P></LI>
        <LI><P>Повышенная чувствительность к любому из компонентов препарата Видора® .</P></LI>
        <LI><P>Непереносимость лактозы, дефицит лактазы, глюкозогалактозная мальабсорбция (в состав препарата входит
          лактозы моногидрат).</P></LI>
      </UL>

      <H2 id={'warning'}>С осторожностью</H2>
      <P>Следует тщательно взвешивать потенциальный риск и ожидаемую пользу применения КОК в каждом индивидуальном
        случае при наличии следующих заболеваний/состояний и факторов риска:</P>

      <UL>
        <LI><P>
          Факторы риска развития тромбоза и тромбоэмболий: курение; ожирение; дислипопротеинемия, контролируемая
          артериальная гипертензия; мигрень без очаговой неврологической симптоматики; пороки клапанов сердца;
          наследственная предрасположенность к тромбозу (тромбозы, инфаркт миокарда или нарушение мозгового
          кровообращения в возрасте менее 50 лет у кого-либо из ближайших родственников);
        </P></LI>
        <LI><P>
          Другие заболевания, при которых могут отмечаться нарушения периферического кровообращения: сахарный диабет;
          системная красная волчанка; гемолитико-уремический синдром; болезнь Крона и язвенный колит;
          серповидно-клеточная анемия; флебит поверхностных вен;
        </P></LI>
        <LI><P>Наследственный ангионевротический отек;</P></LI>
        <LI><P>Гипертриглицеридемия;</P></LI>
        <LI><P>Заболевания печени легкой и средней степени тяжести в анамнезе при нормальных показателях функциональных
          проб печени;</P></LI>
        <LI><P>
          Заболевания, впервые возникшие или усугубившиеся во время беременности или на фоне предыдущего приема половых
          гормонов (например, желтуха и/или зуд, связанные с холестазом, холелитиаз, отосклероз с ухудшением слуха,
          порфирия, герпес во время беременности, хорея Сиденгама);
        </P></LI>
        <LI><P>Послеродовый период;</P></LI>
        <LI><P>Эпилепсия;</P></LI>
        <LI><P></P>Депрессия.</LI>
      </UL>

      <H2 id={'for-use-feed'}>Применение в период беременности и грудного вскармливания</H2>
      <P>Препарат Видора® противопоказан во время беременности и в период грудного вскармливания.</P>
      <P>
        Если беременность выявляется во время приема препарата Видора®, прием препарата должен быть немедленно
        прекращен. Обширные эпидемиологические исследования не выявили повышенного риска дефектов развития у детей,
        рожденных женщинами, получавшими половые гормоны до беременности или тератогенного действия в случаях приема
        половых гормонов по неосторожности в ранние сроки беременности. В то же время, имеющиеся данные о применении
        препарата Видора® во время беременности ограничены, что не позволяет сделать какие-либо выводы о негативном
        влиянии препарата на беременность, здоровье плода и новорожденного ребенка. В настоящее время какие-либо
        значимые эпидемиологические данные отсутствуют. Прием препарата может уменьшать количество грудного молока и
        изменять его состав, поэтому применение препарата противопоказано до прекращения грудного вскармливания.
        Небольшое количество половых гормонов и/или их метаболитов может проникать в грудное молоко и оказывать влияние
        на здоровье ребенка.
      </P>

      <H2 id={'dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</H2>
      <P>
        Таблетки следует принимать внутрь, целиком, не разжевывая, с небольшим количеством воды, по 1 таблетке в день,
        по порядку, указанному на блистере, начиная с активных таблеток (21 таблетка желтого цвета) и заканчивая
        таблетками плацебо (7 таблеток белого цвета) примерно в одно и то же время суток, запивая небольшим количеством
        воды, непрерывно в течение 28 дней. Во время приёма таблеток плацебо (таблетки белого цвета) обычно развивается
        менструальноподобное кровотечение (кровотечение «отмены»). Как правило, оно начинается на 2-3 день после приема
        последней активной таблетки и может не закончиться до начала приема таблеток из новой упаковки. Прием препарата
        из каждой последующей упаковки начинается без перерыва на следующий день, как только заканчиваются таблетки в
        предыдущей упаковке.
      </P>
      <P><b>Переход с другого комбинированного гормонального контрацептива (КОК, вагинального кольца или
        трансдермального пластыря).</b></P>
      <P><b>При отсутствии приема каких-либо гормональных контрацептивных препаратов в предыдущем месяце приём препарата
        Видора® следует начинать в первый день менструального цикла (в первый день менструального кровотечения).
        Допускается начало приёма на 2-5 день менструального цикла, но в этом случае рекомендуется дополнительно
        использовать барьерный метод контрацепции в течение первых 7 дней приёма таблеток из первой упаковки.</b></P>
      <P>
        Предпочтительно начинать приём препарата на следующий день после приёма последней активной таблетки/драже, но не
        позднее следующего дня после обычного 7-дневного перерыва в приёме (для препаратов, содержащих 21
        таблетку/драже) или после приема последней неактивной таблетки/драже (для препаратов, содержащих 28
        таблеток/драже в упаковке).
        Прием таблеток препарата Видора® следует начинать в день удаления вагинального кольца или пластыря, но не
        позднее дня, когда должно быть введено новое кольцо или наклеен новый пластырь.
      </P>
      <P>
        <b>Переход с гормональных контрацептивов, содержащих только гестагены («мини-пили», инъекционных форм, подкожных
          имплантатов и внутриматочных систем с контролируемым высвобождением гестагена).</b>
      </P>
      <P>
        При переходе с «мини-пили» можно начинать прием препарата в любой день (без перерыва), с имплантатов или
        внутриматочной системы с гестагеном - в день удаления имплантата или внутриматочной системы, с инъекционной
        формы - со дня, когда должна была быть сделана следующая инъекция. Во всех случаях необходимо дополнительно
        использовать барьерный метод контрацепции в течение первых 7 дней приема таблеток препарата Видора®.
      </P>
      <P><b>После аборта в 1 триместре беременности.</b></P>
      <P>Можно начинать прием препарата немедленно. При соблюдении этого условия нет необходимости в дополнительной
        контрацептивной защите.</P>
      <P>После родов или аборта во II триместре беременности.</P>
      <P>
        Рекомендуется начинать прием препарата на 21-28 день после родов, при отсутствии грудного вскармливания или
        аборта. Если прием начат позднее, необходимо использовать барьерный метод контрацепции в течение первых 7 дней
        приема таблеток препарата Видора®. В случае наличия полового контакта до начала приема препарата должна быть
        исключена беременность или необходимо дождаться первой менструации.
      </P>
      <P><b>Прием пропущенных таблеток плацебо</b></P>
      <P>
        Если пропущен прием таблеток плацебо (таблетки белого цвета из последнего ряда блистерной упаковки), никаких мер
        принимать не требуется. Непринятые таблетки необходимо выбросить, чтобы избежать непреднамеренного увеличения
        продолжительности периода приема таблеток плацебо (таблетки белого цвета).
        Прием пропущенных активных таблеток
      </P>
      <P>
        Если пропущен прием активных таблеток (таблетки желтого цвета) и опоздание в приеме очередной таблетки составило
        менее 12 часов, контрацептивная защита не снижается. Пропущенную таблетку следует принять как можно скорее.
        Далее таблетки следует принимать в обычном режиме.
      </P>
      <P>
        Если опоздание в приеме очередной таблетке составило более 12 часов (интервал с момента приема последней
        таблетки больше 36 часов), контрацептивная защита может быть снижена. Чем больше таблеток пропущено подряд, и
        чем ближе этот пропуск к 7 дневному перерыву в приеме активных таблеток (таблетки желтого цвета) препарата, тем
        выше вероятность наступления беременности, поскольку 7 дней непрерывного приема препарата требуются для
        достижения адекватного подавления гипоталамо-гипофизарно-яичниковой системы. Для таких ситуаций могут быть даны
        следующие рекомендации:
      </P>
      <P><b>• На 1 неделе приема препарата</b></P>
      <P>Пропущенную активную таблетку (таблетка желтого цвета) следует принять как можно скорее (как только женщина
        вспомнит), даже если это означает прием двух таблеток одновременно. Далее таблетки следует принимать в обычном
        режиме. Дополнительно должен быть использован барьерный метод контрацепции в течение последующих 7 дней. Если
        половой контакт имел место в течение недели перед пропуском в приеме очередной таблетки, необходимо учитывать
        вероятность наступления беременности.</P>
      <P>• На 2 неделе приема препарата</P>
      <P>
        Пропущенную активную таблетку (таблетка желтого цвета) следует принять как можно скорее (как только женщина
        вспомнит), даже если это означает прием двух таблеток одновременно. Далее таблетки следует принимать в обычном
        режиме. Если в течение 7 дней, предшествующих первому пропуску в приеме, все таблетки принимались правильно, нет
        необходимости использовать дополнительные методы контрацепции. В противном случае, а также в случае пропуска
        двух и более таблеток, необходимо использовать дополнительный барьерный метод контрацепции в течение последующих
        7 дней.
      </P>
      <P>• На 3 неделе приема препарата</P>
      <P>В случае пропуска на 3 неделе приема препарата, необходимо принять последнюю пропущенную таблетку как можно
        скорее (даже если это означает прием 2 таблеток одновременно).</P>
      <P>В случае пропуска таблеток не следует принимать более двух активных таблеток (таблетки желтого цвета) в один
        день.</P>
      <P>
        Далее таблетки следует принимать в обычном режиме, пока не закончатся активные таблетки в упаковке. Таблетки
        плацебо (таблетки белого цвета) следует выбросить и незамедлительно начать прием таблеток из следующей упаковки,
        т.е. без перерыва. Дополнительно должен быть использован барьерный метод контрацепции в течение следующих 7
        дней.
      </P>
      <P>
        Вероятнее всего, кровотечения «отмены» не будет до конца второй упаковки, но могут наблюдаться «мажущие»
        кровянистые выделения или маточное кровотечение «отмены» в дни приема препарата из второй упаковки. Если женщина
        пропускала прием активных таблеток, и во время приема таблеток плацебо кровотечения «отмены» не наступило,
        необходимо исключить беременность.
      </P>
      <P><b>Рекомендация при желудочно-кишечных расстройствах</b></P>
      <P>При тяжелых желудочно-кишечных расстройствах всасывание может быть неполным, поэтому следует принять
        дополнительные меры контрацепции.</P>
      <P>
        Если в течение 4 часов после приема активной таблетки (таблетки желтого цвета) произошла рвота или была диарея,
        следует ориентироваться на рекомендации при пропуске таблеток. Если женщина не хочет менять обычную схему приема
        и переносить начало менструальноподобного кровотечения на другой день недели, дополнительную активную таблетку
        следует принять из другой упаковки.
      </P>
      <P>Изменение дня менструальноподобного кровотечения</P>
      <P>
        Чтобы отсрочить наступление менструальноподобного кровотечения, женщине следует продолжить прием таблеток из
        следующей упаковки препарата Видора®, пропустив таблетки плацебо (таблетки белого цвета) из текущей упаковки.
        Таким образом, цикл приема препарата может быть продлен, по желанию, на любой срок, пока не закончатся активные
        таблетки из второй упаковки. На фоне приема препарата из второй упаковки у женщины могут отмечаться «мажущие»
        выделения или «прорывные» маточные кровотечения. Регулярный прием препарата Видора® возобновляется после
        окончания приема таблеток плацебо.
      </P>
      <P>Чтобы перенести начало менструальноподобного кровотечения на другой день недели, женщине следует сократить
        длительность приема таблеток плацебо на желаемое количество дней. Чем короче интервал, тем выше риск, что у
        женщины не будет кровотечения «отмены», и, в дальнейшем будут «мажущие» выделения и «прорывные» кровотечения во
        время приема таблеток из второй упаковки.</P>
      <P><b>Дополнительная информация о применении в особых клинических группах</b></P>
      <P><b>Применение у девочек-подростков</b></P>
      <P>Препарат Видора®1 показан только после наступления менархе. Имеющиеся данные не предполагают коррекции дозы у
        данной группы пациенток.</P>
      <P><b>Применение в пожилом возрасте</b></P>
      <P>Препарат Видора® не показан после наступления менопаузы.</P>
      <P><b>Применение при нарушении функции печени</b></P>
      <P>Препарат Видора® противопоказан женщинам с тяжелыми заболеваниями печени до тех пор, пока показатели
        функциональных проб печени не придут в норму.</P>
      <P><b>Применение при нарушении функции почек</b></P>
      <P>Препарат Видора® противопоказан женщинам с тяжелой почечной недостаточностью.</P>

      <H2 id={'effect-action'}>Побочное действие</H2>
      <P>
        Нежелательные реакции, возможные при применении комбинации дроспиренона и этинилэстрадиола, распределены по
        системно-органным классам с указанием частоты их возникновения согласно рекомендациям ВОЗ: часто (≥ 1/100
        и {'<'} 1/10); нечасто (≥ 1/1000 и {'<'} 1/100); редко (≥ 1/10000 и {'<'} 1/1000). Серьезные нежелательные
        явления при приеме
        КОК описаны также в разделе «Особые указания».
      </P>

      <P sx={{mt: 4}}>
        <b>
          При приеме препарата Видора® отмечались описанные ниже побочные реакции.
        </b>
      </P>

      <Tables.WrapperTable>
        <Tables.Table className="align-left" sx={{m: '10px 0', tableLayout: 'fixed', minWidth: 900}}>
          <Tables.Thead>
            <Tables.Tr sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Bold'}}>
              <Tables.Td sx={{width: 108}}>Системно-органные классы (MedDRA)</Tables.Td>
              <Tables.Td sx={{width: 108}}>
                Часто (от ≥ 1/100 до {'<'}1/10)
              </Tables.Td>
              <Tables.Td sx={{width: 108}}>Нечасто (от ≥ 1/1000 до {'<'}1/100) </Tables.Td>
              <Tables.Td sx={{width: 108}}>
                Редко (от ≥ 1/10000 до {'<'}1/1000)
              </Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны иммунной системы</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Реакция гиперчувствительности <br/>
                Бронхиальная астма</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения психики</Tables.Td>
              <Tables.Td>Нарушения психики <br/> Подавленное настроение</Tables.Td>
              <Tables.Td>Подавленное настроение <br/> Повышение либидо <br/>
                Снижение либидо</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны нервной системы</Tables.Td>
              <Tables.Td>Головная боль</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны органа слуха и лабиринтные нарушения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Гипоакузия</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны сосудов</Tables.Td>
              <Tables.Td>Мигрень</Tables.Td>
              <Tables.Td>Повышение артериального давления (АД) Снижение АД</Tables.Td>
              <Tables.Td>Венозная <br/> тромбоэмболия <br/> Артериальная тромбоэмболия</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны желудочно-кишечного тракта</Tables.Td>
              <Tables.Td>Тошнота</Tables.Td>
              <Tables.Td>Рвота <br/> Диарея</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны кожи и подкожных тканей</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Акне <br/> Экзема <br/> Зуд <br/> Алопеция</Tables.Td>
              <Tables.Td>Узловатая эритема
                Многоформная эритема</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны половых органов и молочной железы</Tables.Td>
              <Tables.Td>Нарушения менструального цикла <br/> Межменструальные кровотечения <br/> Боль в молочных
                железах <br/> Нагрубание молочных желез <br/> Выделения из влагалища <br/> Кандидозный
                вульвовагинит</Tables.Td>
              <Tables.Td>Увеличение молочных желез Вагинальная инфекция</Tables.Td>
              <Tables.Td>Выделения из молочных желез</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Общие расстройства</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>
                Задержка жидкости <br/>
                Увеличение массы тела <br/>
                Снижение массы тела
              </Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P><b>Описание отдельных побочных реакций</b></P>
      <P>У пациенток, принимающих КОК, имеется повышенный риск развития артериальных и венозных тромботических и
        тромбоэмболических нарушений, включая инфаркт миокарда, инсульт, транзиторные ишемические атаки, тромбоз
        глубоких вен и тромбоэмболию лёгочной артерии, более подробно описанные в разделе «Особые указания». Сообщалось
        о следующих серьезных побочных реакциях у женщин, применяющих КОК. Данные побочные реакции описаны в разделе
        «Особые указания»:
        Венозные тромбоэмболические нарушения;</P>
      <UL>
        <LI><P>Артериальные тромбоэмболические нарушения;</P></LI>
        <LI><P>Повышение АД;</P></LI>
        <LI><P>Опухоли печени;</P></LI>
        <LI><P>
          Развитие или ухудшение состояний, для которых связь с применением КОК не является неоспоримой: болезнь Крона,
          язвенный колит, эпилепсия, миома матки, порфирия, системная красная волчанка, герпес во время беременности,
          хорея Сиденгама, гемолитико-уремический синдром, холестатическая желтуха;
        </P></LI>
        <LI><P>Хлоазма;</P></LI>
        <LI><P>Острые или хронические нарушения функции печени, при которых может потребоваться отмена КОК до
          нормализации функциональных проб печени;</P></LI>
        <LI><P>У женщин с наследственным ангионевротическим отеком экзогенные эстрогены могут вызвать или усилить
          симптомы ангионевротического отека;</P></LI>
      </UL>
      <P>
        Частота диагностирования рака молочной железы у женщин, применяющих пероральные контрацептивные препараты,
        повышена весьма незначительно. Рак молочной железы редко наблюдается у женщин до 40 лет, превышение частоты
        незначительно по отношению к общему риску возникновения рака молочной железы. Причинная связь возникновения
        рака молочной железы с применением КОК не установлена. Дополнительную информацию смотрите в разделе
        «Противопоказания» и «Особые указания».
      </P>
      <P><b>Взаимодействие</b></P>
      <P>
        Взаимодействия других препаратов (индукторов ферментов) с пероральными контрацептивами могут приводить к
        «прорывным» кровотечениям и/или снижению контрацептивного эффекта (см. раздел «Взаимодействие с другими
        лекарственными средствами и иные виды взаимодействия»).
      </P>

      <H2 id={'over-dozing'}>Передозировка</H2>
      <P>
        О серьезных нарушениях при передозировке не сообщалось. На основании суммарного опыта применения КОК симптомы,
        которые могут отмечаться при передозировке: тошнота, рвота и кровотечение «отмены». Последнее может возникать у
        девочек, не достигших возраста менархе, при приеме препарата по неосторожности. Специфического антидота нет,
        следует проводить симптоматическое лечение.
      </P>

      <H2 id={'another-medicines'}>Взаимодействие с другими лекарственными средствами</H2>
      <P><b><u>Влияние других лекарственных препаратов на препарат Видора®</u></b></P>
      <P>Возможно взаимодействие с лекарственными препаратами, индуцирующими микросомальные ферменты печени, в
        результате чего может увеличиваться клиренс половых гормонов, что, в свою очередь, может приводить к «прорывным»
        маточным кровотечениям и/или снижению контрацептивного эффекта.</P>
      <P>Индукция микросомальных ферментов печени может наблюдаться уже через несколько дней лечения. Максимальная
        индукция микросомальных ферментов печени обычно наблюдается в течение нескольких недель. После отмены препарата
        индукция микросомальных ферментов печени может сохраняться в течение 4 недель.
      </P>
      <P><b>Краткосрочная терапия</b></P>
      <P>Женщинам, которые получают лечение такими препаратами в дополнение к препарату Видора®, рекомендуется
        использовать барьерный метод контрацепции или выбрать иной негормональный метод контрацепции. Барьерный метод
        контрацепции следует использовать в течение всего периода приема сопутствующих препаратов, а также в течение 28
        дней после их отмены. В случае необходимости продолжения терапии препаратом-индуктором после того как закончен
        прием таблеток из текущей упаковки препарата Видора®, следует начинать прием таблеток из новой упаковки
        препарата Видора® без обычного перерыва в приеме.</P>
      <P><b>Долгосрочная терапия</b></P>
      <P>Женщинам, длительно принимающим препараты - индукторы микросомальных ферментов печени, рекомендуется
        использовать другой надежный негормональный метод контрацепции.</P>

      <UL>
        <LI><P>
          Вещества, увеличивающие клиренс препарата Видора® (ослабляющие эффективность путем индукции ферментов):
          фенитоин, барбитураты, примидон, карбамазепин, рифампицин и, возможно, также окскарбазепин, топирамат,
          фелбамат, гризеофульвин, а также препараты, содержащие зверобой продырявленный.
        </P></LI>
        <LI><P>
          Вещества с различным влиянием на клиренс препарата Видора® При совместном применении с препаратом Видора®
          многие ингибиторы протеаз ВИЧ или вируса гепатита С и ненуклиозидные ингибиторы обратной транскриптазы могут
          как увеличивать, так и уменьшать концентрацию эстрогена или прогестагена в плазме крови. В некоторых случаях
          такое влияние может быть клинически значимо. В период терапии такими препаратами женщина должна дополнительно
          использовать барьерный метод контрацепции;
        </P></LI>
        <LI><P>
          Вещества, снижающие клиренс КОК (ингибиторы ферментов) Сильные и умеренные ингибиторы CYP3A4, такие как
          азольные антимикотики (например, итраконазол, вориконазол, флуконазол), верапамил, макролиды (например,
          кларитромицин, эритромицин), дилтиазем и грейпфрутовый сок могут повышать плазменные концентрации эстрогена
          или прогестагена, или их обоих. Было показано, что эторикоксиб в дозах 60 и 120 мг/сутки при совместном приеме
          с КОК, содержащими 0,035 мг этинилэстрадиола, повышает концентрацию этинилэстрадиола в плазме крови в 1,4 и
          1,6 раза соответственно.
        </P></LI>
      </UL>

      <P><b><u>Влияние препарата Видора® на другие лекарственные препараты</u></b></P>
      <P>
        КОК могут влиять на метаболизм других препаратов, что приводит к повышению (например, циклоспорин) или снижению
        (например, ламотриджин) их концентрации в плазме крови и тканях.
      </P>
      <P>In vitro дроспиренон способен слабо или умеренно ингибировать ферменты цитохрома Р450 CYP1A1, CYP2C9, CYP2C19 и
        CYP3A4.</P>
      <P>
        На основании исследований взаимодействия in vivo у женщин-добровольцев, принимавших омепразол, симвастатин или
        мидазолам в качестве маркерных субстратов, можно заключить, что клинически значимое влияние 3 мг дроспиренона на
        метаболизм лекарственных препаратов, опосредованный ферментами цитохрома Р450, маловероятно. In vitro
        этинилэстрадиол является обратимым ингибитором CYP2C19, CYP1A1 и CYP1A2, а также необратимым ингибитором
        CYP3A4/5, CYP2C8 и CYP2J2. В клинических исследованиях назначение гормонального контрацептива, содержащего
        этинилэстрадиол, не приводило к какому-либо повышению или приводило лишь к слабому повышению концентраций
        субстратов CYP3A4 в плазме крови (например, мидазолама), в то время как плазменные концентрации субстратов
        CYP1A2 могут возрастать слабо (например, теофиллин) или умеренно (например, мелатонин и тизанидин).
      </P>
      <P><b><u>Фармакодинамические взаимодействия</u></b></P>
      <P>
        Было показано, что совместное применение этинилэстрадиолсодержащих препаратов и противовирусных препаратов
        прямого действия, содержащих омбитасвир, паритапревир, дасабувир или их комбинацию, ассоциируется с повышением
        концентрации АЛТ (аланинаминотрансферазы) более чем в 20 раз по сравнению с верхней границей нормы у здоровых и
        инфицированных вирусом гепатита С женщин (см. Раздел «Противопоказания»).
      </P>
      <P><b><u>Другие формы взаимодействия</u></b></P>
      <P>
        У пациенток с нормальной функцией почек сочетанное применение дроспиренона и ингибиторов
        ангиотензинпревращающего фермента или нестероидных противовоспалительных препаратов не оказывает значимого
        эффекта на концентрацию калия в плазме крови.
      </P>
      <P>
        Одновременное применение комбинации дроспиренон+этинилэстрадиол с антагонистами альдостерона или
        калийсберегающими диуретиками не изучено. При совместном приеме с данными препаратами концентрацию калия в
        плазме крови необходимо контролировать в течение первого цикла приема контрацептива (см. раздел «Особые
        указания»).
      </P>

      <H2 id={'special-instructions'}>Особые указания</H2>
      <P>
        Если какие-либо из состояний, заболеваний и факторов риска, указанных ниже, имеются в настоящее время, то
        следует тщательно взвешивать потенциальный риск и ожидаемую пользу применения препарата Видора® в каждом
        индивидуальном случае и обсудить его с женщиной до того, как она решит начинать прием препарата. В случае
        утяжеления, усиления или первого проявления любого из этих состояний, заболеваний или факторов риска, женщина
        должна проконсультироваться со своим врачом, который может принять решение о необходимости отмены препарата.
      </P>
      <P>Заболевания сердечно-сосудистой системы</P>
      <P>
        Результаты эпидемиологических исследований указывают на наличие взаимосвязи между применением КОК и повышением
        частоты развития венозных и артериальных тромбозов и тромбоэмболий (таких как тромбоз глубоких вен,
        тромбоэмболия легочной артерии, инфаркт миокарда, цереброваскулярные нарушения) при приёме КОК. Данные
        заболевания отмечаются редко.
      </P>
      <P>
        Риск развития венозной тромбоэмболии (ВТЭ) максимален в первый год приема КОК. Повышенный риск присутствует
        после первоначального применения КОК или возобновления применения одного и того же или другого КОК (после
        перерыва между приемами препарата в 4 недели и более). Данные крупномасштабного проспективного исследования с
        участием 3 групп пациенток показывают, что этот повышенный риск присутствует преимущественно в течение первых 3
        месяцев.
      </P>
      <P>
        Общий риск ВТЭ у женщин, принимающих низкодозированные КОК (
        {'<'}
        0,05 мг этинилэстрадиола) в два-три раза выше, чем у небеременных пациенток, которые не принимают КОК, тем не
        менее, этот риск остается более низким по сравнению с риском ВТЭ во время беременности и родов.
      </P>
      <P>ВТЭ может оказаться жизнеугрожающей или привести к летальному исходу (в 1-2% случаев).</P>
      <P>ВТЭ, проявляющаяся в виде тромбоза глубоких вен или эмболии легочной артерии, может произойти при применении
        любых КОК.</P>
      <P>Крайне редко при применении КОК возникает тромбоз других кровеносных сосудов например, печеночных, брыжеечных,
        почечных, мозговых вен и артерий или сосудов сетчатки глаза.</P>
      <P>
        Симптомы тромбоза глубоких вен: односторонний отек нижней конечности или отек вдоль вены на нижней конечности,
        боль или дискомфорт в нижней конечности только в вертикальном положении или при ходьбе, локальное повышение
        температуры в пораженной нижней конечности, покраснение или изменение окраски кожных покровов на нижней
        конечности.
      </P>
      <P>
        Симптомы тромбоэмболии легочной артерии: затрудненное или учащенное дыхание; внезапный кашель, в том числе с
        кровохарканьем; острая боль в грудной клетке, которая может усиливаться при глубоком вдохе; чувство тревоги;
        сильное головокружение; учащенное или нерегулярное сердцебиение. Некоторые из этих симптомов (например, одышка,
        кашель) являются неспецифическими и могут быть истолкованы неверно как признаки других более часто встречающихся
        и менее тяжелых состояний/заболеваний (например, инфекции дыхательных путей).
      </P>
      <P>Артериальная тромбоэмболия может привести к инсульту, окклюзии сосудов или инфаркту миокарда.</P>
      <P>
        Симптомы инсульта: внезапная слабость или потеря чувствительности лица, конечностей, особенно с одной стороны
        тела, внезапная спутанность сознания, проблемы с речью и пониманием; внезапная одно- или двухсторонняя потеря
        зрения; внезапное нарушение походки, головокружение, потеря равновесия или координации движений; внезапная,
        тяжелая или продолжительная головная боль без видимой причины; потеря сознания или обморок с приступом судорог
        или без него. Другие признаки окклюзии сосудов: внезапная боль, отечность и незначительная синюшность
        конечностей, «острый» живот. Симптомы инфаркта миокарда: боль, дискомфорт, давление, тяжесть, чувство сжатия или
        распирания в груди или за грудиной, с иррадиацией в спину, челюсть, левую верхнюю конечность, область
        эпигастрия; холодный пот, тошнота, рвота или головокружение, сильная слабость, тревога или одышка; учащенное или
        нерегулярное сердцебиение. Артериальная тромбоэмболия может оказаться жизнеугрожающей или привести к летальному
        исходу.
      </P>
      <P>
        У женщин с сочетанием нескольких факторов риска или высокой выраженностью одного из них следует рассматривать
        возможность их взаимоусиления. В подобных случаях суммарное значение имеющихся факторов риска повышается. В этом
        случае прием препарата Видора® противопоказан (см. раздел «Противопоказания»).
      </P>
      <P>Риск развития тромбоза (венозного и/или артериального) и тромбоэмболии или цереброваскулярных нарушений
        повышается:</P>
      <P>- с возрастом;</P>
      <P>- у курящих (с увеличением количества выкуриваемых сигарет или повышением возраста риск нарастает, особенно у
        женщин старше 35 лет);</P>
      <P>- при наличии:</P>

      <UL>
        <LI><P> ожирения (индекс массы тела более чем 30 кг/м2);</P></LI>
        <LI><P>
          семейного анамнеза (например, венозной или артериальной тромбоэмболии когда- либо у близких родственников или
          родителей в возрасте менее 50 лет). В случае наследственной или приобретенной предрасположенности женщина
          должна быть осмотрена соответствующим специалистом для решения вопроса о возможности приема препарата Видора®;
        </P></LI>
        <LI><P>длительной иммобилизации, обширного хирургического вмешательства, любой операции на нижних конечностях
          или обширной травмы. В этих случаях прием препарата Видора® необходимо прекратить (в случае планируемой
          операции по крайней мере за четыре недели до нее) и не возобновлять прием в течение двух недель после
          окончания иммобилизации. Временная иммобилизация (например, авиаперелет длительностью более 4 часов) может
          также являться фактором риска развития венозной тромбоэмболии, особенно при наличии других факторов
          риска; </P></LI>
        <LI><P> дислипопротеинемии;</P></LI>
        <LI><P> артериальной гипертензии;</P></LI>
        <LI><P> мигрени;</P></LI>
        <LI><P> заболеваний клапанов сердца; </P></LI>
        <LI><P>фибрилляции предсердий. </P></LI>
      </UL>

      <P>Применение любых комбинированных гормональных контрацептивов повышает риск развития ВТЭ. Применение препаратов,
        содержащих левоноргестрел, норгестимат или норэтистерон, вызывает наименьший риск развития ВТЭ. Применение
        других препаратов, таких как Видора®, может привести к двукратному увеличению риска. Выбор в пользу применения
        КОК с более высоким риском развития ВТЭ может быть сделан только после консультации пациентки, позволяющей
        убедиться, что она полностью понимает риск развития ВТЭ, связанный с применением препарата Видора®, влияние
        препарата на существующие у нее факторы риска и то, что риск развития ВТЭ максимален в течение первого года
        применения препарата. Вопрос о возможной роли варикозного расширения вен и поверхностного тромбофлебита в
        развитии ВТЭ остается спорным. Следует учитывать повышенный риск развития тромбоэмболий в послеродовом
        периоде.</P>
      <P>Нарушения периферического кровообращения также могут отмечаться при сахарном диабете, системной красной
        волчанке, гемолитико-уремическом синдроме хронических воспалительных заболеваниях кишечника (болезнь Крона или
        язвенный колит и серповидно-клеточной анемии.
        Увеличение частоты и тяжести мигрени во время применения КОК (что может предшествовать цереброваскулярным
        нарушениям) является основанием для немедленной отмены приема этих препаратов.</P>
      <P>К биохимическим показателям, указывающим на наследственную или приобретенную предрасположенность к венозному
        или артериальному тромбозу относится следующее: резистентность к активированному протеину С,
        гипергомоцистеинемия, дефицит антитромбина III, дефицит протеина С, дефицит протеина S, антифосфолипидные
        антитела (антитела к кардиолипину, волчаночный антикоагулянт).</P>
      <P></P>
      <P>соответствующего состояния может уменьшить связанный с ним риск тромбоза. Также следует учитывать, что риск
        тромбозов и тромбоэмболий при беременности выше, чем при приёме низкодозированных пероральных контрацептивов (
        {'<'}
        0,05 мг этинил эстрадиола).
        Опухоли</P>
      <P>Наиболее существенным фактором риска развития рака шейки матки является персистирующая папилломовирусная
        инфекция. Имеются сообщения о некотором повышении риска развития рака шейки матки при длительном применении КОК.
        Однако связь с приемом КОК не доказана. Обсуждается возможность взаимосвязи этих данных со скринингом
        заболеваний шейки матки или с особенностями полового поведения (более редкое применение барьерных методов
        контрацепции).</P>
      <P>Мета-анализ 54 эпидемиологических исследований показал, что имеется несколько повышенный относительный риск
        развития рака молочной железы, диагностированного у женщин, принимающих КОК в настоящее время (относительный
        риск 1,24). Повышенный риск постепенно исчезает в течение 10 лет после прекращения приёма этих препаратов. В
        связи с тем, что рак молочной железы отмечается редко у женщин до 40 лет, увеличение числа диагнозов рака
        молочной железы у женщин, принимающих КОК в настоящее время или принимавших недавно, является незначительным по
        отношению к общему риску этого заболевания. Его связь с приемом КОК не доказана. Наблюдаемое повышение риска
        может быть также следствием тщательного наблюдения и более ранней диагностики рака молочной железы у женщин,
        применяющих КОК, биологическим действием половых гормонов или сочетанием обоих факторов. У женщин, когда-либо
        использовавших КОК, выявляются более ранние стадии рака молочной железы, чем у женщин, никогда их не
        применявших.</P>
      <P>В редких случаях на фоне применения КОК наблюдалось развитие доброкачественных, а в крайне редких -
        злокачественных опухолей печени, которые в отдельных случаях приводили к угрожающему жизни внутрибрюшному
        кровотечению. В случае появления сильных болей в области живота, увеличения печени или признаков внутрибрюшного
        кровотечения это следует учитывать при проведении дифференциального диагноза. Злокачественные опухоли могут
        оказаться жизнеугрожающими или привести к летальному исходу.</P>
      <P>Другие состояния</P>
      <P>Прогестиновый компонент в препарате Видора® является антагонистом альдостерона, обладающим калийсберегающими
        свойствами. В большинстве случаев не должно наблюдаться повышения концентрации калия в плазме крови. В
        клинических исследованиях у некоторых пациенток с почечной недостаточностью легкой или средней степени тяжести и
        сопутствующем приеме калийсберегающих препаратов концентрация калия в плазме крови незначительно повышалась во
        время приема дроспиренона. Поэтому концентрацию калия в плазме крови необходимо контролировать в течение первого
        цикла приема препарата у пациенток с почечной недостаточностью и при изначальной концентрации калия на верхней
        границе нормы, особенно при сопутствующем приеме калийсберегающих препаратов (см. раздел «Взаимодействие с
        другими лекарственными средствами и иные виды взаимодействия»).</P>
      <P>У женщин с гипертриглицеридемией (или наличием этого состояния в семейном анамнезе) возможно повышение риска
        развития панкреатита во время приема КОК. Несмотря на то, что небольшое повышение артериального давления было
        описано у многих женщин, принимающих КОК, клинически значимые повышения отмечались редко. Тем не менее, если во
        время приема препарата развивается стойкое, клинически значимое повышение артериального давления, следует
        отменить эти препараты и начать лечение артериальной гипертензии. Прием препарата может быть продолжен, если с
        помощью гипотензивной терапии достигнуты нормальные значения артериального давления.</P>
      <P>Следующие состояния, как сообщалось, развиваются или ухудшаются как во время беременности, так и при приеме
        КОК, но их связь с приемом КОК не доказана: желтуха и/или зуд, связанный с холестазом; формирование камней в
        желчном пузыре; порфирия; системная красная волчанка; гемолитико-уремический синдром; хорея Сиденгама; герпес во
        время беременности; потеря слуха, связанная с отосклерозом. Также описаны случаи ухудшения течения эндогенной
        депрессии, эпилепсии, болезни Крона и язвенного колита на фоне применения КОК.</P>
      <P>У женщин с наследственными формами ангионевротического отека экзогенные эстрогены могут вызывать или ухудшать
        симптомы ангионевротического отека.</P>
      <P>Острые или хронические нарушения функции печени могут потребовать отмены препарата до тех пор, пока показатели
        функции печени не вернутся в норму. Рецидив холестатической желтухи, развившейся впервые во время предшествующей
        беременности или предыдущего приема половых гормонов, требует прекращения приема препарата.</P>
      <P>Хотя КОК могут оказывать влияние на инсулинорезистентность и толерантность к глюкозе, необходимости в коррекции
        дозы гипогликемических препаратов у пациенток с сахарным диабетом, использующих низкодозированные КОК (
        {'<'}
        0,05 мг этинилэстрадиола), как правило, не возникает. Тем не менее, женщины с сахарным диабетом должны тщательно
        наблюдаться во время приема КОК.</P>
      <P>Иногда может развиваться хлоазма, особенно у женщин с наличием в анамнезе хлоазмы беременных. Женщины со
        склонностью к хлоазме во время приема препарата Видора® должны избегать длительного пребывания на солнце и
        воздействия ультрафиолетового излучения.</P>
      <P>Лабораторные тесты</P>
      <P>Прием препарата Видора® может влиять на результаты некоторых лабораторных тестов, включая показатели функции
        печени, почек, щитовидной железы, надпочечников, концентрацию транспортных белков в плазме, показатели
        углеводного обмена, параметры свертывания крови и фибринолиза. Изменения обычно не выходят за границы нормальных
        значений. Дроспиренон увеличивает активность ренина плазмы и концентрацию альдостерона, что связано с его
        антиминералокортикоидным эффектом.</P>
      <P>Снижение эффективности</P>
      <P>Эффективность препарата Видора® может быть снижена в следующих случаях: при пропуске таблеток,
        желудочно-кишечных расстройствах или в результате лекарственного взаимодействия.</P>
      <P>Частота и выраженность менструалъноподобных кровотечений</P>
      <P>На фоне приема препарата Видора® могут наблюдаться нерегулярные (ациклические) кровотечения из влагалища
        («мажущие» кровянистые выделения и/или «прорывные» маточные кровотечения), особенно в течение первых месяцев
        применения. Поэтому оценка любых нерегулярных менструальноподобных кровотечений должна проводиться после периода
        адаптации, составляющего около 3 циклов приема препарата. Если нерегулярные менструальноподобные кровотечения
        повторяются или развиваются после предшествующих регулярных циклов, следует провести тщательное обследование для
        исключения злокачественных новообразований или беременности.</P>
      <P>У некоторых женщин во время перерыва в приеме таблеток может не развиться кровотечение «отмены». Если препарат
        Видора® принимался согласно рекомендациям, маловероятно, что женщина беременна. Тем не менее, при нерегулярном
        применении препарата и отсутствии двух подряд менструальноподобных кровотечений, прием препарата не может быть
        продолжен до исключения беременности.</P>
      <P>Медицинские осмотры</P>
      <P>Перед началом или возобновлением применения препарата Видора® необходимо ознакомиться с анамнезом жизни,
        семейным анамнезом женщины, провести тщательное общемедицинское и гинекологическое обследование, исключить
        беременность. Объем исследований и частота контрольных осмотров должны основываться на существующих нормах
        медицинской практики при необходимом учете индивидуальных особенностей каждой пациентки. Как правило, измеряется
        артериальное давление, частота сердечных сокращений, определяется индекс массы тела, проверяется состояние
        молочных желез, брюшной полости и органов малого таза, включая цитологическое исследование эпителия шейки матки
        (тест по Папаниколау). Обычно контрольные обследования следует проводить не реже 1 раза в 6 месяцев.</P>
      <P>Необходимо иметь в виду, что препарат Видора® не предохраняет от ВИЧ-инфекции (СПИД) и других заболеваний,
        передающихся половым путем!</P>

      <H2 id={'managment-transport'}>
        Влияние на способность управления транспортными
        средствами, механизмами
      </H2>
      <P> Не изучалось. </P>
      <P>Случаев неблагоприятного влияния на способность к управлению транспортными средствами и механизмами при приеме
        препарата не выявлено.</P>
      <P><b><u>Состояния, требующие консультации врача:</u></b></P>
      <UL>
        <LI><P>Какие-либо изменения в здоровье, особенно возникновение состояний, перечисленных в разделах
          «Противопоказания» и «Применение с осторожностью»;</P></LI>
        <LI><P>Локальное уплотнение в молочной железе;</P></LI>
        <LI><P>Одновременный прием других лекарственных препаратов (см. также раздел «Взаимодействие с другими
          лекарственными средствами и иные виды взаимодействия»);</P></LI>
        <LI><P>Если ожидается длительная неподвижность (например, на нижнюю конечность наложен гипс), планируется
          госпитализация или операция (по крайней мере, за 4 недели до предполагаемой операции);</P></LI>
        <LI><P>Необычно сильное кровотечение из влагалища;</P></LI>
        <LI><P>Пропущена таблетка в первую неделю приёма упаковки и был половой контакт за семь или менее дней до
          этого;</P></LI>
        <LI><P>Отсутствие очередного менструальноподобного кровотечения два раза подряд или подозрение на беременность
          (не следует начинать приём таблеток из следующей упаковки до консультации с врачом).</P></LI>
      </UL>

      <P>Следует прекратить приём таблеток и немедленно обратиться за медицинской помощью, если имеются возможные
        признаки тромбоза, инфаркта миокарда или инсульта: необычный кашель; необычно сильная боль за грудиной, отдающая
        в левую руку; неожиданно возникшая одышка, необычная, сильная и длительная головная боль или приступ мигрени;
        частичная или полная потеря зрения или двоение в глазах; нечленораздельная речь; внезапные изменения слуха,
        обоняния или вкуса; головокружение или обморок; слабость или потеря чувствительности в любой части тела; сильная
        боль в животе; сильная боль в нижней конечности или внезапно возникший отёк любой из нижних конечностей.</P>

      <H2 id={'form-production'}>Форма выпуска</H2>
      <P>
        Таблетки, покрытые пленочной оболочкой 3 мг + 0,03 мг.
      </P>
      <P>По 21 активной таблетке и 7 таблеток плацебо в блистер из ПВХ/ПВДХ/Алюминиевой фольги. По 1 или 3 блистера
        вместе с инструкцией по применению в пачку картонную.</P>

      <H2 id={'keeper'}>Условия хранения</H2>
      <P>Хранить при температуре не выше 25 C</P>
      <P>Хранить в недоступном для детей месте.</P>

      <H2 id={'deadline'}>Срок годности</H2>
      <P>3 года.</P>
      <P>Не применять препарат по истечении срока годности.</P>

      <H2 id={'holiday'}>Условия отпуска</H2>
      <P>Отпускают по рецепту.</P>

      <H2 id={'owner'}>Владелец (держатель) Регистрационного удостоверения</H2>
      <P>Эксэлтис Хелскеа С.Л.</P>
      <P>Авенида Миралкампо, 7, Полигоно Индустриаль Миралкампо, 19200 Асукеко де Энарес, Гвадалахара, Испания.</P>

      <H2 id={'producer'}>Производитель</H2>
      <P>
        Лабораторное Леон Фарма С.А., <br/> Кайе Ла Валинья (б/н), Полигоне Индустриаль Наватехера, 24008-Виллаквиламбре (Леон, Испания).
      </P>
      <P><b>Организация, принимающая претензии потребителей</b></P>
      <P>
        Представительство компании с ограниченной ответственностью «Эксэлтис Хелскеа, С.Л.» <br/> 119049, г. Москва, Якиманский пер., дом 6.<br/> Тел./факс: +7 (495) 648 39 47.</P>

      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Инструкция PDF
      </a>
    </Page>
  )
}
