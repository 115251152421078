import main from './main.png'
import warn from './warn.png'
import chartOne from './chart.png'
import chartTwo from './chart2.png'
import chartThree from './chart4.png'
import plus from './plus.png'
import approveRed from './approve-red.png'
import mainBottom from './main-bottom.png'
import quotation from './quotation.png'
import warnRed from './warn-red.png'
import approve from './approve.png'
import cross from './cross.png'
import ellips from './ellips.png'
import ellipsSmall from './ellips-small.png'
import girl1 from './girl1.png'
import girl2 from './girl2.png'
import girl3 from './girl3.png'
import minus from './minus.png'
import operation from './operation.png'
import pair from './pair.png'
import professor from './professor.png'
import professorSecond from './professor2.png'
import quotationLarge from './quotation-large.png'
import seud from './seud.png'
import plusSmall from './plus-small.png'
import approveMin from './approve-min.png'

export default {
  main,
  warn,
  chartOne,
  chartTwo,
  chartFour: chartThree,
  plus,
  approveRed,
  mainBottom,
  quotation,
  warnRed,
  approve,
  cross,
  ellips,
  girl1,
  girl2,
  girl3,
  minus,
  operation,
  pair,
  professor,
  professorSecond,
  quotationLarge,
  seud,
  plusSmall,
  ellipsSmall,
  approveMin,
}