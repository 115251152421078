import {TABS} from '../constants';
import {StyledTabs} from './ui';
import {TabsItem} from './tabs-item';

export const Tabs = ({...props}: any) => {
  return (
    <StyledTabs {...props}>
      <TabsItem link={TABS.KLIMONORM}>Климонорм</TabsItem>
      <TabsItem link={TABS.ZOELY}>Зоэли</TabsItem>
      <TabsItem link={TABS.MICROGINON}>Микрогинон</TabsItem>
      <TabsItem link={TABS.ALVOVIZAN}>Алвовизан</TabsItem>
      <TabsItem link={TABS.VIDORA}>Видора</TabsItem>
      <TabsItem link={TABS.DIECYCLEN}>Диециклен</TabsItem>
      <TabsItem link={TABS.VELLEDIEN}>Велледиен</TabsItem>
    </StyledTabs>
  )
}
