import {StyledBoxCircle, StyledLink, StyledWrapperBox} from './ui';
import {StyledBoxBorder, StyledBoxInsetShadow, StyledBoxShadow, StyledCircle, StyledHeader} from '../../ui';
import {Box} from '@mui/material';
import {MarketDivideTitle} from './market-divide-title';
import {Black, PinkLight} from '../../../../styles/color';
import {icons} from '../../img';

export const EffectiveSalesMarket = () => {
  return (
    <>
      <StyledHeader sx={{mb: 3, mt: 7}}>Рынок половых стероидов в России</StyledHeader>
      <Box sx={{display: 'flex', gap: '20px'}}>
        <StyledLink href="#straw">STRAW+10</StyledLink>
        <StyledLink href="#market-plan">ПЛАН ПРОДАЖ</StyledLink>
        <StyledLink href="#database">БАЗА ДАННЫХ</StyledLink>
      </Box>
      <StyledWrapperBox>
        <Box sx={{mb: 2}}>
          <Box id="straw" sx={{fontSize: 30, mt: 5, mb: 1}}>STRAW+10 — ПЕРЕХОД К МЕНОПАУЗЕ</Box>
          <Box>STRAW — Stages of reproductive aging workshop</Box>
        </Box>

        <MarketDivideTitle>МЕНАРХЕ</MarketDivideTitle>

        <StyledBoxShadow className="title">
          РЕПРОДУКТИВНЫЙ ПЕРИОД
        </StyledBoxShadow>

        <StyledBoxShadow className="stage-box">
          <Box>Стадия:</Box>
          <Box sx={{display: 'flex', justifyContent: 'space-around', width: '100%', fontWeight: 700}}>
            <Box sx={{ml: -10}}>-5</Box>
            <Box sx={{ml: -10}}>-4</Box>
          </Box>
        </StyledBoxShadow>

        <Box sx={{display: 'flex', gap: '20px'}}>
          <StyledBoxCircle>Ранний</StyledBoxCircle>
          <StyledBoxCircle>Расцвет</StyledBoxCircle>
        </Box>

        <Box className="tb">
          <Box className="tb-th">EE+Drospirenone</Box>
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>7 439 366</strong>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-light">Оригинальные</Box>
            <Box className="tb-cell bg-gray">Дженерики</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Ярина: 968 380</Box>
            <Box className="tb-cell">Димиа: 1 027 504</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Джес: 1 683 203</Box>
            <Box className="tb-cell">Лея: 96 273</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Ярина плюс: 833 281</Box>
            <Box className="tb-cell">Мидиана: 878 354</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Джес плюс: 1 883 456</Box>
            <Box className="tb-cell bg-pink">Видора: 28 232</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell"></Box>
            <Box className="tb-cell bg-pink">Видора микро: 40 683</Box>
          </Box>
        </Box>

        <Box className="tb">
          <Box className="tb-th">EE+Cyproterone</Box>
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>493 422</strong>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-light">Оригинальные</Box>
            <Box className="tb-cell bg-gray">Дженерики</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Диане-35: 375 875</Box>
            <Box className="tb-cell">Хлое: 117 547</Box>
          </Box>
        </Box>

        <Box className="tb">
          <Box className="tb-th">ЕЕ+Desogestrel</Box>
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>2 229 425</strong>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-light">Оригинальные</Box>
            <Box className="tb-cell bg-gray">Дженерики</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Марвелон: 49 503</Box>
            <Box className="tb-cell">Регулон: 1 375 736</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Мерсилон: 29 038</Box>
            <Box className="tb-cell">Новинет: 751 988</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Три-мерси: 23 160</Box>
            <Box className="tb-cell"></Box>
          </Box>
        </Box>

        <Box className="tb">
          <Box className="tb-th">EE+Dienogest</Box>
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>1 634 564</strong>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-light">Оригинальные</Box>
            <Box className="tb-cell bg-gray">Дженерики</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Жанин: 750 296</Box>
            <Box className="tb-cell">Силует: 707 499</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell"></Box>
            <Box className="tb-cell">Бонадэ: 49 894</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell"></Box>
            <Box className="tb-cell bg-pink">Диециклен: 126 875</Box>
          </Box>
        </Box>

        <Box className="tb">
          <Box className="tb-th">Dienogest</Box>
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>445 416</strong>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-light">Оригинальные</Box>
            <Box className="tb-cell bg-gray">Дженерики</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Визанна: 258 728</Box>
            <Box className="tb-cell bg-pink">Алвовизан: 71 355</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell"></Box>
            <Box className="tb-cell">Зафрилла: 115 333</Box>
          </Box>
        </Box>

        <StyledBoxShadow className="title">
          РЕПРОДУКТИВНЫЙ ПЕРИОД
        </StyledBoxShadow>

        <StyledBoxShadow className="stage-box">
          <Box>Стадия:</Box>
          <Box sx={{display: 'flex', justifyContent: 'space-around', width: '100%', fontWeight: 700}}>
            <Box sx={{ml: -10}}>-3A</Box>
            <Box sx={{ml: -10}}>-3B</Box>
          </Box>
        </StyledBoxShadow>

        <Box sx={{display: 'flex'}}>
          <StyledBoxCircle>Поздний</StyledBoxCircle>
        </Box>

        <Box className="tb">
          <Box className="tb-th">ЕЕ+Levonorgestrel</Box>
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>1 606 819</strong>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-light">Оригинальные</Box>
            <Box className="tb-cell bg-gray">Дженерики</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-pink">Микрогинон: 102 148</Box>
            <Box className="tb-cell">Ригевидон: 966 432</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Минизистон 20 фем: 45 207</Box>
            <Box className="tb-cell">Три-регол: 491 863</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell"></Box>
            <Box className="tb-cell">Три-регол 21+7: 1169</Box>
          </Box>
        </Box>

        <Box className="tb">
          <Box className="tb-th">ЕЕ+Gestoden</Box>
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>2 489 740</strong>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-light">Оригинальные</Box>
            <Box className="tb-cell bg-gray">Дженерики</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Фемоден: 99 003</Box>
            <Box className="tb-cell">Линдинет 30: 862 908</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Логест: 255 143</Box>
            <Box className="tb-cell">Линдинет 20: 1 250 690</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell"></Box>
            <Box className="tb-cell">Гестарелла: 21 996</Box>
          </Box>
        </Box>

        <Box className="tb">
          <Box className="tb-th">Levonorgestrel</Box>
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>1 465 646</strong>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-light">Экстренная контрацепция</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Эскапел: 639 345</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Постинор: 826 301</Box>
          </Box>
        </Box>

        <StyledBoxShadow className="title">
          ПЕРЕХОД В МЕНОПАУЗУ
        </StyledBoxShadow>

        <StyledBoxShadow className="stage-box">
          <Box>Стадия:</Box>
          <Box sx={{display: 'flex', justifyContent: 'space-around', width: '100%', fontWeight: 700}}>
            <Box sx={{ml: -10}}>-2</Box>
            <Box sx={{ml: -10}}>-1</Box>
          </Box>
        </StyledBoxShadow>

        <Box sx={{display: 'flex', gap: '20px'}}>
          <StyledBoxCircle>Ранний</StyledBoxCircle>
          <StyledBoxCircle>Расцвет</StyledBoxCircle>
        </Box>

        <Box className="tb">
          <Box className="tb-th">Циклическая МГТ:</Box>
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>2 661 571</strong>
          </Box>
        </Box>

        <Box className="tb">
          <Box className="tb-th">EV+LNG</Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-light">Оригинальные</Box>
            <Box className="tb-cell bg-gray">Дженерики</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-pink">Климонорм: 50 535</Box>
            <Box className="tb-cell">ДляЖенс Климо: 17 242</Box>
          </Box>
        </Box>

        <Box className="tb">
          <Box className="tb-th">EV+Dydrogesteron</Box>
          <Box className="tb-row">
            <Box className="tb-cell">Фемостон 1:10: 1 391 968</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Фемостон 2:10: 1 201 826</Box>
          </Box>
        </Box>

        <Box className="tb">
          <Box className="tb-th">LNG</Box>
          <Box className="tb-row">
            <Box className="tb-cell">Внутриматочные системы: 73 393</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Мирена: 56 554</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Кайлина: 16 839</Box>
          </Box>
        </Box>

        <Box className="tb">
          <Box className="tb-th">EV+SAHC</Box>
          <Box className="tb-row">
            <Box className="tb-cell">Клайра: 1 925 661</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-pink">Зоэли: 176 937</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Эстеретта: 16 661</Box>
          </Box>
        </Box>

        <Box className="tb" sx={{mb: 3}}>
          <Box className="tb-th">Estradiol</Box>
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>1 071 138</strong>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-pink">Прогинова: 422 962</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Эстрожель: 240 749</Box>
          </Box>
        </Box>

        <MarketDivideTitle>МЕНАРХЕ (0)</MarketDivideTitle>

        <StyledBoxShadow className="title">
          ПОСТМЕНОПАУЗА
        </StyledBoxShadow>

        <StyledBoxShadow className="stage-box">
          <Box>Стадия:</Box>
          <Box sx={{display: 'flex', justifyContent: 'space-around', width: '100%', fontWeight: 700}}>
            <Box sx={{ml: -10, display: 'flex'}}>
              <Box sx={{pr: 8}}>+1A</Box>
              <Box>+1B</Box>
            </Box>
            <Box sx={{ml: -10, display: 'flex'}}>
              <Box sx={{pr: 8}}>+1C</Box>
              <Box>+2</Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <Box sx={{display: 'flex', gap: '20px'}}>
          <StyledBoxCircle>Ранний</StyledBoxCircle>
          <StyledBoxCircle>Поздний</StyledBoxCircle>
        </Box>


        <Box className="tb">
          <Box className="tb-th_addition">
            Объём в упаковках: <strong>2 949 420</strong>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Анжелик микро: 396 591</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Фемостон конти: 1 367 947</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Фемостон мини: 315 515</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Ливиал: 57 881</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Ледибон: 42 437</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell bg-pink">Велледиен: 47496</Box>
          </Box>
          <Box className="tb-row">
            <Box className="tb-cell">Анжелик: 721 553</Box>
          </Box>
        </Box>

        <StyledBoxShadow id="market-plan">
          <Box sx={{fontSize: 30, letterSpacing: '0.1px', mt: 1, mb: 2}}>
            ПЛАН ПРОДАЖ
          </Box>
          <StyledBoxInsetShadow sx={{fontSize: 24, color: PinkLight, fontWeight: 700}}>
            Выполнение плана продаж за счет чёткого следования маркетинговой стратегии!
          </StyledBoxInsetShadow>

          <Box sx={{display: 'flex', gap: 2}}>
            <Box sx={{display: 'flex', gap: 2, mt: 5, flex: 1}}>
              <StyledCircle className="border border--white" sx={{padding: '4px 4px 5px 5px'}}>
                <img src={icons.doctor} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                  <strong>ВРАЧИ:</strong>
                </Box>
                <Box>
                  <ol className="list">
                    <li>
                      Выбор только потенциальных клиентов в базу
                      (только прескрайберов).
                    </li>
                    <li>
                      Продажа на каждом визите
                      и в каждом кабинете (смотри
                      цели визитов и свой план
                      продаж).
                    </li>
                  </ol>
                </Box>
              </Box>
            </Box>

            <Box sx={{display: 'flex', gap: 2, mt: 5, flex: 1}}>
              <StyledCircle className="border border--white" sx={{padding: '3px 4px 5px 4px'}}>
                <img src={icons.plus} alt="File"/>
              </StyledCircle>
              <Box>
                <Box sx={{mb: 2, textTransform: 'uppercase'}}>
                  <strong>АПТЕКИ:</strong>
                </Box>
                <Box>
                  <ol className="list">
                    <li>Динамика продаж ZWH в 20 аптеках города.</li>
                    <li>
                      <span style={{color: PinkLight}}>5 упаковок </span>
                      неснижаемый остаток ZWH в 20 аптеках.
                    </li>
                  </ol>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledBoxShadow>

        <StyledBoxShadow id="database">
          <Box sx={{fontSize: 30, letterSpacing: '0.1px', mt: 1, mb: 2}}>
            БАЗА ДАННЫХ
          </Box>
          <Box sx={{mb: 5}}>
            База специалистов для работы с АЛВОВИЗАНОМ, КЛИМОНОРМОМ, ЗОЭЛИ,
            ПРОГИНОВОЙ, МИКРОГИНОНОМ, ВИДОРОЙ, ВИДОРОЙ МИКРО,
            ДИЕЦИКЛЕНОМ, ВЕЛЛЕДИЕНОМ.
          </Box>
          <StyledBoxInsetShadow>
            <Box sx={{fontSize: 24, color: PinkLight, fontWeight: 700}}>ВАЖНО!</Box>
            <ul className="list">
              <li>
                Общее количество гинекологов в базе зависит от территории и индивидуального плана продаж в упаковках.
              </li>
              <li>
                К общему количеству врачей в базе необходимо добавить 20% врачей с учётом изменения места работы,
                ухода на больничный лист или в отпуск. Если индивидуальная база врачей включает
                <strong style={{color: PinkLight}}> 160 гинекологов</strong>,
                то необходимо ещё найти <strong style={{color: PinkLight}}>32 высокопотенциальных гинекологов, </strong>
                чтобы общее количество было равно <strong style={{color: PinkLight}}>192.</strong>
              </li>
            </ul>
          </StyledBoxInsetShadow>

          <Box sx={{fontSize: 24, color: PinkLight, fontWeight: 700, textTransform: 'uppercase', mt: 5}}>
            Пример активности по специализированной линии
          </Box>

          <Box className="tb" sx={{mt: 3, minWidth: 800, backgroundColor: Black}}>
            <Box className="tb-row">
              <Box className="tb-cell bg-gray center size-2">Вид активности</Box>
              <Box className="tb-cell bg-gray center size-1">Кол-во за период</Box>
              <Box className="tb-cell bg-gray center size-1">Кол-во участников</Box>
              <Box className="tb-cell bg-gray center size-3">Формат</Box>
              <Box className="tb-cell bg-gray center size-2">Кол-во визитов- эквивалентов в месяц</Box>
            </Box>
            <Box className="tb-row">
              <Box className="tb-cell size-2">Круглые столы для врачей</Box>
              <Box className="tb-cell center size-1"> 2 в месяц</Box>
              <Box className="tb-cell center size-1"> 7 и более</Box>
              <Box className="tb-cell size-3">Презентация МП (ответы на вопросы)</Box>
              <Box className="tb-cell center size-2">6</Box>
            </Box>
            <Box className="tb-row">
              <Box className="tb-cell size-2">Конференция для врачей</Box>
              <Box className="tb-cell center size-1">1 в квартал</Box>
              <Box className="tb-cell center size-1">15 и более</Box>
              <Box className="tb-cell size-3">Доклад лидера (дискуссия, клинический разбор)</Box>
              <Box className="tb-cell center size-2">4</Box>
            </Box>
            <Box className="tb-row">
              <Box className="tb-cell size-2">Выполнение прочих задач МП</Box>
              <Box className="tb-cell center size-1">1 день в месяц</Box>
              <Box className="tb-cell center size-1">1</Box>
              <Box className="tb-cell size-3">Подготовка отчётов, планов, работа с почтой и выполнение прочих
                заданий</Box>
              <Box className="tb-cell center size-2">12</Box>
            </Box>
          </Box>

          <Box sx={{fontSize: 24, color: PinkLight, fontWeight: 700, textTransform: 'uppercase', mt: 5, mb: 3}}>
            ВИЗИТЫ + МЕРОПРИЯТИЯ
          </Box>

          <Box sx={{display: 'flex', gap: '20px'}}>
            <StyledBoxBorder sx={{display: 'flex', gap: '16px', flex: 1}}>
              <StyledCircle className="border" sx={{mb: 2}}>1</StyledCircle>
              <Box>
                Таким образом, если в месяце 20 рабочих дней, то при норме
                <strong style={{color: PinkLight}}> 8 визитов в день</strong> план по активности на месяц — 160
              </Box>
            </StyledBoxBorder>
            <StyledBoxBorder sx={{display: 'flex', gap: '16px', flex: 1}}>
              <StyledCircle className="border" sx={{mb: 2}}>2</StyledCircle>
              <Box>
                <strong style={{color: PinkLight}}> 160 визитов,</strong> 2 КС в месяц и 1 конференция в квартал —
                обязательная активность. В сумме это 170
              </Box>
            </StyledBoxBorder>
          </Box>

          <Box sx={{display: 'flex', gap: '20px', mt: '20px'}}>
            <StyledBoxBorder sx={{display: 'flex', gap: '16px', flex: 1}}>
              <StyledCircle className="border" sx={{mb: 2}}>3</StyledCircle>
              <Box>
                12 визитов-эквивалентов предоставляется на подготовку отчётности и исполнение прочих обязанностей.
              </Box>
            </StyledBoxBorder>
            <StyledBoxBorder sx={{display: 'flex', gap: '16px', flex: 1}}>
              <StyledCircle className="border" sx={{mb: 2}}>4</StyledCircle>
              <Box>
                1 конференция в квартал — 12 визитов-эквивалентов (4 с каждого месяца).
              </Box>
            </StyledBoxBorder>
          </Box>

          <Box sx={{fontSize: 24, fontWeight: 700, textTransform: 'uppercase', mt: 3, mb: 3}}>
            ИТОГО: 182
          </Box>
        </StyledBoxShadow>

      </StyledWrapperBox>
    </>
  )
}
