import {useLayoutEffect} from 'react';
import Page from 'shared/ui/Page';

import one from './img/1.jpg';
import second from './img/2.jpg';
import tree from './img/3.jpg';
import four from './img/4.jpg';
import graph from './img/graph.png';
import fon from './img/fon.png';
import prod from './img/prod.png';
import finish from './img/finish.jpg';
import plus from './img/plus.png';
import q from './img/q.svg';
import BlockAccord from './BlockAccord';
import BlockOral from './BlockOral';
import ItemPlus from './ItemPlus';
import BlockWhite from './BlockWhite';
import Tabs from './Tabs';
import {H1, LinkNavigate, Paragraph, Sup} from 'shared/ui';
import {WrapperShell} from 'components/wrapper-shell';
import {Button, Tape} from './ui';
import {Div} from 'components/wrapper-couple/ui';
import {useHistory} from 'react-router-dom';

export default () => {
  const history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      const {hash} = window.location;
      const elementToScroll = document.getElementById(hash?.replace('#', ''));

      if (!elementToScroll) return;

      window.scrollTo({
        top: elementToScroll.offsetTop + 40,
      });
    }, 0);
  }, [])

  const handleBack = () => {
    history.push('/');
  }

  const handleClickBtn = (url: string) => {
    history.push(`/product/zoely/${url}`);
  }

  return (
    <Page head={'ZOELY®'} backText="Продукты" onClose={handleBack}>
      <Tabs/>
      <div className="row row-2">
        <div
          style={{
            height: 220,
          }}
          className="box"
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            <img width={60} height={60} src={q} alt=""/>
            <div
              style={{
                marginLeft: 20,
              }}
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 400,
                  color: '#fff',
                }}
              >
                Женщины часто отказываются от приёма контрацепции, т.к. считают,
                что нет идеального безопасного контрацептива…
              </div>
              <div
                style={{
                  fontSize: 28,
                  fontWeight: 400,
                  color: '#fff',
                  marginTop: 10,
                }}
              >
                Но такой контрацептив уже создан!
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            height: 220,
            background: `url(${one})`,
            backgroundSize: 'cover',
          }}
          className="box"
        />
      </div>
      <div style={{height: 20}}/>
      <div
        style={{
          background: '#F9D2D6',
          position: 'relative',
        }}
        className="box"
      >
        <div
          style={{
            width: 380,
            padding: 34,
          }}
        >
          <img
            style={{
              width: 260,
              height: 170,
            }}
            src={prod}
            alt=""
          />
          <div
            style={{
              fontSize: 28,
              fontWeight: 600,
              color: '#2F2F34',
              marginTop: 20,
            }}
          >
            Zoely<sup style={{fontSize: 16}}>®</sup>
          </div>
          <div
            style={{
              fontSize: 27,
              fontWeight: 700,
              color: '#fff',
              marginTop: 20,
            }}
          >
            ИННОВАЦИОННЫЙ КОК
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: 400,
              color: '#2F2F34',
              marginTop: 10,
            }}
          >
            Первый монофазный комбинированный <b>контрацептив</b>, содержащий <b>аналог натурального эстрадиола</b>
          </div>
        </div>
        <img
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
          }}
          src={fon}
          alt=""
        />
      </div>

      <div className="box" style={{marginTop: 20, padding: '25px 20px'}}>
        <ItemPlus
          isWhite
          icon={plus}
          text={
            <LinkNavigate to="/product/eoeli/effects">
              <Paragraph sx={{color: 'white', fontSize: 27, lineHeight: '32px'}}>
                Использование эстрогенов и прогестагенов, которые демонстрируют наибольшее сходство с эндогенными
                гормонами, представляет собой высочайшую ступень в эволюции контрацептивов<Sup>1</Sup>.
              </Paragraph>
            </LinkNavigate>
          }
        />
      </div>

      <div className="box" style={{marginTop: 20, paddingBottom: 8, paddingTop: 30}}>
        <H1>
          Zoely® — первый монофазный комбинированный контрацептив, содержащий аналог натурального эстрадиола и
          производное натурального прогестерона
        </H1>
      </div>

      <BlockAccord/>
      <BlockOral/>
      <div
        style={{
          display: 'flex',
          marginTop: 20
        }}
      >
        <div
          style={{
            height: 350,
            width: 412,
            background: `url(${second})`,
            marginRight: 20,
            flexShrink: 0,
          }}
          className="box"
        />
        <div className="box">
          <h3
            style={{
              fontSize: 36,
              fontWeight: 600,
              color: '#F9D2D6',
              marginTop: 14,
              marginBottom: 10,
            }}
          >
            <LinkNavigate to="/product/eoeli/nomak">
              NOMAC<Sup>2</Sup>
            </LinkNavigate>
          </h3>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <ItemPlus
              isWhite
              text={
                <LinkNavigate to="/product/eoeli/venous">
                  <Paragraph sx={{mt: 0}}>
                    После шести циклов лечения NOMAC/E2 оказывали минимальное влияние на параметры, связанные с
                    гемостазом, липидами и углеводным обменом<Sup>3</Sup>.
                  </Paragraph>
                </LinkNavigate>
              }
            />
          </div>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <ItemPlus
              isWhite
              text={
                <Paragraph sx={{mt: 0}}>
                  <LinkNavigate to="/product/eoeli/pharm">
                    NOMAC оказывает нейтральное влияние на сердечно-сосудистую функцию<Sup>4</Sup> и <br/>
                  </LinkNavigate>
                  <LinkNavigate to="/product/eoeli/effects">
                    метаболические риски<Sup>1</Sup>.
                  </LinkNavigate>
                </Paragraph>
              }
            />
          </div>
        </div>
      </div>
      <div style={{height: 20}}/>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div className="box">
          <h3
            style={{
              fontSize: 28,
              fontWeight: 600,
              color: '#F9D2D6',
            }}
          >
            Zoely® удобен в случае пропущенной таблетки из-за длительного
            периода полувыведения
          </h3>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <ItemPlus
              isPlus
              isWhite
              text={
                'Период полувыведения zoely® составляет 46 часов. Это больше, чем у любого другого КОК, представленного сегодня на рынке контрацептивов'
              }
            />
          </div>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <ItemPlus
              isPlus
              isWhite
              text={
                'Восстановление фертильности после его применения составляет в среднем 21 день, что согласуется с данными по другим оральным контрацептивам'
              }
            />
          </div>
        </div>
        <div
          style={{
            height: 350,
            width: 412,
            background: `url(${tree})`,
            marginLeft: 20,
            flexShrink: 0,
            backgroundSize: 'cover',
          }}
          className="box"
        />
      </div>
      <div style={{height: 20}}/>
      <BlockWhite/>
      <div style={{height: 20}}/>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div className="box">
          <h3
            style={{
              fontSize: 28,
              fontWeight: 600,
              color: '#F9D2D6',
            }}
          >
            <LinkNavigate to="/product/eoeli/treatment">
              89,2% женщин были удовлетворены применением Zoely® в течение 12
              месяцев использования<Sup>5</Sup>
            </LinkNavigate>
          </h3>
          <img
            style={{
              height: 380,
            }}
            src={graph}
            alt=""
          />
        </div>
        <div
          style={{
            width: 412,
            background: `url(${four})`,
            marginLeft: 20,
            flexShrink: 0,
            backgroundSize: 'cover',
          }}
          className="box"
        />
      </div>
      <div style={{height: 20}}/>
      <div className="box" style={{paddingBottom: 32}}>
        <h3
          style={{
            fontSize: 36,
            fontWeight: 600,
            color: '#F9D2D6',
            marginBottom: 30,
            marginTop: 0,
          }}
        >
          Достоинства препарата Zoely®
        </h3>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr 1fr',
            gridGap: 20,
          }}
        >
          <ItemPlus
            isPlus
            isWhite
            text={
              'Содержит номегэстрола ацетат — высокоселективный прогестаген на основе прогестерона, и 17β-эстрадиол, который структурно идентичен эндогенному эстрогену'
            }
          />
          <ItemPlus
            isPlus
            isWhite
            text={
              'Отсутствие влияния на показатели артериального давления, углеводный обмен, систему гемостаза, минеральную плотность костей, появление акне'
            }
          />
          <ItemPlus
            isPlus
            isWhite
            text={
              'Монофазный режим дозирования: 24 активных таблетки и 4 таблетки плацебо'
            }
          />
          <ItemPlus
            isPlus
            isWhite
            text={
              'Высокая контрацептивная эффективность — более 99% (индекс Перля 0,38)'
            }
          />
          <ItemPlus
            isPlus
            isWhite
            text={
              'Более короткое и менее интенсивное кровотечение отмены по сравнению с другими КОК'
            }
          />
          <ItemPlus
            isPlus
            isWhite
            text={
              'Удобен в случае пропущенной таблетки из-за длительного периода полувыведения (46 часов)'
            }
          />
        </div>
      </div>
      <WrapperShell id="survey" sx={{margin: '20px 0 20px', pt: 5, pb: 4}}>
        <H1>ИССЛЕДОВАНИЯ</H1>
        <Div sx={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: 5}}>
          <Tape sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Div>Сравнение влияния на гемостаз среди КОК</Div>
            <Button onClick={() => handleClickBtn('compare')}>Узнать больше</Button>
          </Tape>
          <Tape sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Div>Эффекты NOMAC/E2 в отношении молочной железы и матки</Div>
            <Button onClick={() => handleClickBtn('effects')}>Узнать больше</Button>
          </Tape>
          <Tape sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Div>MISS-эффекты</Div>
            <Button onClick={() => handleClickBtn('miss-effects')}>Узнать больше</Button>
          </Tape>
        </Div>
      </WrapperShell>
      <div
        style={{
          height: 580,
          background: `url(${finish})`,
          width: '100%',
          backgroundSize: 'cover',
        }}
        className="box"
      />
    </Page>
  );
};
