import {styled} from '@mui/system';
import {Box} from '@mui/material';
import {Black, PinkLight} from '../../styles/color';
import { LinkNavigate } from '../../shared/ui';

export const StyledBoxShadow = styled(Box)({
  width: '100%',
  fontFamily:  'SF-Pro-Text-Regular',
  color: '#FFFFFF',
  backgroundColor: Black,
  boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(249, 210, 214, 0.2)',
  borderRadius: 12,
  padding: 20,
  boxSizing: 'border-box',
})

export const StyledBoxBorder = styled(Box)`
  border: 1px solid ${PinkLight};
  box-sizing: border-box;
  padding: 20px;
  border-radius: 12px;
`;

export const H2 = styled('h2')({
  fontSize: 24,
  fontFamily: 'SF-Pro-Text-Bold',
  color: '#F9D2D6',
  marginBottom: 10,
  fontWeight: 400,

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const H1 = styled('h1')({
  fontSize: 30,
  fontFamily: 'SF-Pro-Text-Regular',
  lineHeight: '35px',
  letterSpacing: '0.1px',
  color: '#F9D2D6',
  fontWeight: 400,
  textTransform: 'uppercase',

  '@media only screen and (max-width: 600px)': {
    wordBreak: 'break-all',
  }
})

export const Paragraph = styled('p')({
  fontSize: 18,
  fontFamily: 'SF-Pro-Text-Regular',
  letterSpacing: '0.1px',
  lineHeight: '21px',

  '@media only screen and (max-width: 600px)': {}
})
export const Div = styled('div')({})

export const Span = styled('span')({
  wordBreak: 'normal'
})

export const StyledLink = styled('a')`
  padding: 0 30px;
  height: 60px;
  border: 1px solid ${PinkLight};
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  font-size: 24px;
  font-family: "SF Pro Text", sans-serif;
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.1px;
  background-color: ${Black};
  color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;

  &:hover {
    background-color: ${PinkLight};
    color: ${Black};

    .svg {
      filter: brightness(20%);
    }
  }
`

export const StyledLinkNavigate = styled(LinkNavigate)({
  textDecoration: 'underline',
  fontSize: 18,
})
