import {useState} from 'react';
import {useHistory} from 'react-router-dom'

import {Tab, TabsGroup} from 'components/tabs';
import {ProductModal} from 'components/product-modal/product-modal';
import {ProductModalType} from 'components/product-modal/types';
import {WrapperCouple} from 'components/wrapper-couple';
import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import Page from 'shared/ui/Page';
import {Img, List as UL, ListItem as LI, LinkNavigate} from 'shared/ui';
import { PinkLight, Red} from 'styles/color';

import {H2, Paragraph as P, H1, Span, Div, Sup} from './ui';
import images from './img'

export const Alvovizan = () => {
  const history = useHistory();

  const [modal, setModal] = useState<ProductModalType>(null);
  const handleClosePopup = () => {
    setModal(null);
  }

  const handleOpenModal = () => {
    setModal('Материалы');
  }

  const handleRedirectToProducts = () => {
    history.push('/');
  }

  const handleOpenVideo = () => {
    history.push('/video/Видео/QjffUXwlqsQ');
  }

  return (
    <>
      <Page head={'Алвовизан'} backText="Продукты" onClose={handleRedirectToProducts}>
        <TabsGroup>
          <Tab>
            <LinkNavigate to="/product/alvovizan/instruction">Инструкция</LinkNavigate>
          </Tab>
          <Tab onClick={handleOpenModal}>Материалы</Tab>
          <Tab onClick={handleOpenVideo}>Видео</Tab>
        </TabsGroup>

        <Div className="text-light" sx={{display: 'flex', flexDirection: 'column', gap: '20px', mb: '150px'}}>
          <WrapperShell sx={{backgroundImage: `url(${images.main})`, height: 500}}>
            <H1 sx={{color: '#FFF', mt: 36, ml: 6}}>
              Расширение<br/> терапевтического взгляда<br/> <Span sx={{color: Red}}>на эндометриоз</Span>
            </H1>
          </WrapperShell>

          <WrapperShell className="text-light">
            <H1 sx={{color: Red, mb: 1, mt: 2}}>Статистика</H1>

            <Div sx={{display: 'flex', gap: '20px', mb: '20px'}}>
              <WrapperShellBox
                sx={{
                  boxShadow: 'none',
                  border: `1px solid ${PinkLight}`,
                  pl: '74px',
                  position: 'relative',
                  mt: '20px'
                }}>
                <Img sx={{position: 'absolute', left: 14, top: 14}} src={images.approve}/>
                <LinkNavigate to="/product/alvovizan/link6">
                  <P sx={{mb: 0, mt: 0}}>
                    Среди женщин, страдающих эндометриозом, распространенность бесплодия составляет&nbsp;
                    <Span sx={{color: Red, fontWeight: 'bold'}}>до 50%</Span>,&nbsp;
                    хронической тазовой боли — <Span sx={{color: Red, fontWeight: 'bold'}}>до 87%</Span><Sup>7</Sup>.
                  </P>
                </LinkNavigate>
              </WrapperShellBox>
              <WrapperShellBox
                sx={{
                  boxShadow: 'none',
                  border: `1px solid ${PinkLight}`,
                  pl: '74px',
                  position: 'relative',
                  mt: '20px'
                }}>
                <Img sx={{position: 'absolute', left: 14, top: 14}} src={images.approve}/>
                <LinkNavigate to="/product/alvovizan/link6">
                  <P sx={{mb: 0, mt: 0}}>
                    При наличии эндометриоза фертильность&nbsp;
                    <Span sx={{color: Red, fontWeight: 'bold'}}>в 10 раз</Span>&nbsp;
                    меньше, чем у женщин без эндометриоза: при эндометриозе&nbsp;
                    вероятность наступления беременности составляет&nbsp;
                    <Span sx={{color: Red, fontWeight: 'bold'}}>2-10%</Span> в месяц<Sup>7</Sup>.
                  </P>
                </LinkNavigate>
              </WrapperShellBox>

              <WrapperShellBox
                sx={{
                  boxShadow: 'none',
                  border: `1px solid ${PinkLight}`,
                  pl: '74px',
                  position: 'relative',
                  mt: '20px'
                }}>
                <Img sx={{position: 'absolute', left: 14, top: 14}} src={images.approve}/>
                <LinkNavigate to="/product/alvovizan/link6">
                  <P sx={{mb: 0, mt: 0}}>
                    Эндометриоз способствует повышению риска неблагоприятных последствий во время беременности:&nbsp;
                    сниженный вес младенцев <Span sx={{color: Red, fontWeight: 'bold'}}>на 15,9%,</Span>&nbsp;
                    преждевременные роды — <Span sx={{color: Red, fontWeight: 'bold'}}>на 12,7%,</Span>&nbsp;
                    предлежание плаценты <Span sx={{color: Red, fontWeight: 'bold'}}>на 1,6%</Span><Sup>7</Sup>
                  </P>
                </LinkNavigate>
              </WrapperShellBox>
            </Div>
            <WrapperCouple sx={{mb: 2}}>
              <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                <P sx={{color: Red, fontWeight: 'bold', mt: 0}}>Эндометриоз</P>
                <P>Диагноз обычно устанавливают у женщин в возрате 25–40 лет (в зависимости от того, обращаются они с
                  бесплодием или в связи с тазовой болью), однако это заболевание может развиваться уже в подростковом
                  возрасте.</P>
              </WrapperShellBox>
              <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`}}>
                <P sx={{mt: 0}}>Неспецифическая природа симптомов, связанных с эндометриозом, делает диагноз сложным и
                  может привести
                  к задержке в установлении диагноза примерно на 8 лет.
                </P>

                <P sx={{color: Red, fontWeight: 'bold'}}>Окончательный диагноз устанавливается при лапароскопии!</P>
              </WrapperShellBox>
            </WrapperCouple>
          </WrapperShell>

          <WrapperCouple>
            <WrapperShell>
              <H1 sx={{color: Red}}>Задержка постановки диагноза у пациенток с эндометриозом</H1>
              <P sx={{
                fontSize: 28,
                position: 'relative',
                p: 2,
                pl: '80px',
                border: `1px solid ${PinkLight}`,
                borderRadius: '12px'
              }}>
                <Img sx={{position: 'absolute', left: 18, top: 20}} src={images.quotation}/>
                Согласно данным 5 исследований с вовлечением <b>1187 женщин,</b> между первыми симптомами заболевания и
                диагнозом проходит
                <LinkNavigate to="/product/alvovizan/link1">
                  <Span sx={{color: Red, fontWeight: 'bold'}}> 8,6 лет</Span><Sup>1</Sup>
                </LinkNavigate>
              </P>
            </WrapperShell>
            <WrapperShell className="small" sx={{backgroundImage: `url(${images.face})`}}/>
          </WrapperCouple>

          <WrapperShell>
            <H1 sx={{color: Red}}>Актуализация проблемы</H1>
            <H2 sx={{color: PinkLight}}>
              Задержка с постановкой диагноза приводит к тому, что эти женщины с
              неустановленным диагнозом так и будут ходить на прием к гинекологу каждый месяц с различными жалобами,
              основной из которых является боль.
            </H2>
            <WrapperShellBox sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, mt: 3}}>
              <P sx={{color: Red, fontSize: 24, fontWeight: 'bold', mt: 0, mb: 2}}>Симптомы эндометриоза</P>
              <P sx={{mt: 0, mb: 0}}>Эндометриоз часто сопровождается бесплодием или субфертильностью. Женщины могут
                узнать о том, что они
                могут страдать эндометриозом, только после посещения специалиста по проблемам бесплодия или после
                лапароскопии.</P>
            </WrapperShellBox>
            <WrapperCouple sx={{mt: '20px'}}>
              <WrapperShellBox
                sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative'}}>
                <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.plus}/>
                <P sx={{fontSize: 24, fontWeight: 'bold', mt: 1, color: Red, mb: 2}}>Наиболее
                  типичные <br/> симптомы:</P>
                <UL>
                  <LI><P sx={{m: 0}}>дисменорея;</P></LI>
                  <LI><P sx={{m: 0}}>предменструальная боль;</P></LI>
                  <LI><P sx={{m: 0}}>диспареуния;</P></LI>
                  <LI><P sx={{m: 0}}>диффузная / хроническая тазовая боль</P></LI>
                </UL>
              </WrapperShellBox>
              <WrapperShellBox
                sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative'}}>
                <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.plus}/>
                <P sx={{fontSize: 24, fontWeight: 'bold', mt: 1, color: Red, mb: 2}}>Другие симптомы включают:</P>
                <UL>
                  <LI><P sx={{m: 0}}>симптомы поражения кишечника или мочевого пузыря (часто в перименопаузе);</P></LI>
                  <LI><P sx={{m: 0}}>боль в спине, хроническую усталость.</P></LI>
                </UL>
              </WrapperShellBox>
            </WrapperCouple>
            <WrapperShellBox
              sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative', mt: '20px'}}>
              <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.warn}/>
              <P sx={{fontSize: 24, fontWeight: 'bold', mt: 0, color: Red, mb: 2}}>Примечание:</P>
              <P sx={{mb: 0, mt: 1}}>у части женщин эндометриоз протекает бессимптомно.</P>
            </WrapperShellBox>
            <LinkNavigate to="/product/alvovizan/link2">
              <H2 sx={{color: PinkLight}}>
                Согласно рекомендациям NICE 2017, чтобы заподозрить эндометриоз, достаточно одного или нескольких из
                перечисленных ниже симптомов<Sup>2</Sup>
              </H2>
            </LinkNavigate>
            <UL sx={{border: `1px solid ${PinkLight}`, borderRadius: '12px', p: 2, pl: '50px'}}>
              <LI><P sx={{mb: 1, mt: 1}}>
                Хроническая тазовая боль — боль в области малого таза, которая длится 6 месяцев и более.
              </P></LI>
              <LI><P sx={{mb: 1, mt: 1}}>
                Дисменорея, негативно влияющая на качество жизни и повседневную активность.
              </P></LI>
              <LI><P sx={{mb: 1, mt: 1}}>
                Боль, появляющаяся во время и/или после полового акта в области малого таза, — диспареуния.
              </P></LI>
              <LI><P sx={{mb: 1, mt: 1}}>
                Гастроинтестинальные симптомы, связанные с менструацией: боль в кишечнике, запоры или поносы.
              </P></LI>
              <LI><P sx={{mb: 1, mt: 1}}>
                Симптомы со стороны мочевыводящей системы, связанные с менструацией: например,
                боль при мочеиспускании, появление крови в моче.
              </P></LI>
              <LI><P sx={{mb: 1, mt: 1}}>Бесплодие в сочетании с 1 или более симптомами.</P></LI>
            </UL>
          </WrapperShell>

          <WrapperShell>
            <H1 sx={{color: Red}}>Решение</H1>
            <WrapperShellBox
              sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '74px', position: 'relative', mt: '20px'}}>
              <Img sx={{position: 'absolute', left: 14, top: 14}} src={images.plus}/>
              <P sx={{mb: 0, mt: 0}}>
                В Российских клинических рекомендациях 2020 г. для эмпирической терапии рекомендуются КОК и
                нейромодуляторы. Прогестагены фигурируют как терапия первой линии — то есть, при подтвержденном
                диагнозе. Согласно Kalaitzopoulos D.R., с соавт. (2021) КОК рекомендованы в качестве эмпирической
                терапии в 6 национальных и 2 международных рекомендациях.
              </P>
            </WrapperShellBox>

            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none', p: 0}}>
                <H2 sx={{color: PinkLight, mb: 2}}>Клинические рекомендации по лечению эндометриоза. <Span
                  sx={{color: Red}}>Хронология</Span></H2>
                <Img src={images.chartOne}/>
                <P sx={{fontSize: 16}}>
                  ASRM — Американское общество репродуктивной медицины<br/>
                  ESHRE — Европейское общество репродукции человека и эмбриологии<br/>
                  RCOG — Королевская коллегия акушеров-гинекологов<br/>
                  SOGC — Ассоциация акушеров и гинекологов Канады
                </P>
              </WrapperShell>
              <WrapperShell className="extra-small" sx={{boxShadow: 'none', p: 0, textAlign: 'center'}}>
                <H2 sx={{color: PinkLight, textAlign: 'left'}}>Хирургическая или медикаментозная терапия?</H2>
                <Img src={images.balance}/>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell>
            <H1 sx={{color: Red}}>АЛВОВИЗАН</H1>
            <P>
              <LinkNavigate to="/product/alvovizan/link3">
                Содержит диеногест, который значительно уменьшает тазовую боль<Sup>3</Sup>.&nbsp;
              </LinkNavigate>
              При длительном применении диеногеста
              <LinkNavigate to="/product/alvovizan/link3">
                &nbsp;снижается частота и интенсивность генитального кровотечения<Sup>3</Sup>.&nbsp;
              </LinkNavigate>
              Таким образом, назначая Алвовизан своим
              пациенткам с дисменореей и обильными менструациями, Вы помогаете им избавиться от неприятных симптомов и
              повысить качество их жизни.
            </P>
            <P>
              Уменьшается частота и интенсивность генитального кровотечения (при длительном применении), так как у
              женщин в этом исследовании метроррагии (межменструальные кровотечения) встречались в более чем 90%
              случаев. Кроме того, согласно инструкции, маточные кровотечения могут быть побочным эффектом диеногеста.
            </P>
            <LinkNavigate to="/product/alvovizan/link3">
              <H2 sx={{color: PinkLight, mb: 3}}>
                Диеногест является эффективным препаратом для долговременной терапии аденомиоза, эффективно купируя
                боль, а при длительном применении — интенсивность менструальных кровотечений.<Sup>3</Sup>
              </H2>
            </LinkNavigate>
            <WrapperCouple>
              <WrapperShell
                sx={{
                  backgroundImage: `url(${images.chartTwo})`,
                  backgroundSize: 'contain',
                  minWidth: 588,
                  height: 438,
                  boxShadow: 'none'
                }}
              />
              <WrapperShell sx={{boxShadow: 'none', p: 0, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <WrapperShellBox
                  sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative', pb: 0}}>
                  <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.plus}/>
                  <P sx={{mt: 0, mb: 2}}>
                    Через год после терапии аденомиоза Диеногестом 2 мг наблюдались:
                  </P>
                  <UL>
                    <LI><P sx={{m: 0}}>Снижение показателей боли по ВАШ в среднем на 86%.</P></LI>
                    <LI><P sx={{m: 0, mt: 2}}>Уменьшение размера матки на 30%.</P></LI>
                  </UL>
                </WrapperShellBox>
                <WrapperShellBox
                  sx={{
                    boxShadow: 'none',
                    border: `1px solid ${PinkLight}`,
                    pl: '80px',
                    position: 'relative',
                    pb: '14px'
                  }}>
                  <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.plus}/>
                  <P sx={{m: 0}}>
                    <LinkNavigate to="/product/alvovizan/link3">
                      На фоне приема диеногеста уровень аменореи составил 58,3% в первые 12 недель и увеличился до 86,4%
                      через 72 недели.<Sup>3</Sup>
                    </LinkNavigate>
                  </P>
                </WrapperShellBox>
              </WrapperShell>
            </WrapperCouple>
            <WrapperShellBox
              sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '74px', position: 'relative', mt: '20px'}}>
              <Img sx={{position: 'absolute', left: 14, top: 14}} src={images.warnRed}/>
              <LinkNavigate to="/product/alvovizan/link4">
                <H2 sx={{mb: 0, mt: 0, fontSize: 24, color: PinkLight, fontWeight: 'bold'}}>
                  Даже кратковременное применение Диеногеста 2 мг продемонстрировало сопоставимую с агонистами
                  ГнРГ*<br/>
                  эффективность в отношении всех видов болей при аденомиозе**.
                  <Sup>4</Sup>
                </H2>
              </LinkNavigate>
            </WrapperShellBox>
            <P sx={{fontSize: '16px'}}>
              * ГнРГ — гонадотропин-рилизинг-гормон<br/>
              **У женщин пременопаузального периода
            </P>
            <Img sx={{mt: 3}} src={images.chartThree}/>
          </WrapperShell>

          <WrapperShell>
            <H2 sx={{color: PinkLight}}>Исследование сравнительной фармакокинетики и биоэквивалентности препаратов
              Алвовизан и Визанна показало,
              что препараты
            </H2>
            <LinkNavigate to="/product/alvovizan/link5">
              <H1 sx={{color: Red, fontSize: 35}}>Алвовизан и Визанна являются биоэквивалентными<Sup>5</Sup></H1>
            </LinkNavigate>
            <Img sx={{mb: 5}} src={images.chartFour}/>

            <WrapperShellBox
              sx={{boxShadow: 'none', border: `1px solid ${PinkLight}`, pl: '80px', position: 'relative', mb: 2}}>
              <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.approve}/>
              <P sx={{fontSize: 24, fontWeight: 'bold', mt: 1, color: Red, mb: 2}}>ВЫВОДЫ:</P>
              <UL>
                <LI><P>Исследуемые препараты характеризуются высокой степенью сходства показателей фармакокинетики.</P></LI>
                <LI><P>Профили фармакокинетических кривых препаратов Алвовизин и Визанна имеют совпадающие
                  формы.</P></LI>
                <LI><P>Препараты характеризуются близкими значениями относительной биодоступности, максимальной
                  концентрации и относительной скорости всасывания.</P></LI>
                <LI><P>Доверительные интервалы для AUC, Cmax и Cmax/AUC соответствуют допустимым пределам
                  80–125%.</P></LI>
                <LI><P>Препараты имеют сопоставимый профиль безопасности.</P></LI>
              </UL>
            </WrapperShellBox>
          </WrapperShell>

          <WrapperShell>
            <WrapperCouple>
              <WrapperShell sx={{boxShadow: 'none  '}}>
                <H1 sx={{color: Red, mt: 0}}>АЛВОВИЗАН</H1>
                <P>Диеногест микронизированный 2 мг</P>
                <UL>
                  <LI><P sx={{mt: 1, mb: 1}}>Лекарственная форма: таблетки 2 мг</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Фармакотерапевтическая группа: гестаген</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Форма выпуска: таблетки № 28, № 84 и № 168</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Срок годности: 2 года</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Условия отпуска: по рецепту</P></LI>
                  <LI><P sx={{mt: 1, mb: 1}}>Производитель: Хаупт Фарма Мюнстер ГмбХ (Германия).</P></LI>
                </UL>
                <LinkNavigate to="/product/alvovizan/instruction">
                  <H2 sx={{color: Red}}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ:<Sup>6</Sup></H2>
                </LinkNavigate>
                <P sx={{mt: 1}}>Лечение эндометриоза</P>
              </WrapperShell>
              <WrapperShell className="small" sx={{
                boxShadow: 'none',
                border: `1px solid ${PinkLight}`,
                pl: '80px',
                position: 'relative',
                mb: 2
              }}>
                <Img sx={{position: 'absolute', left: 18, top: 18}} src={images.approveRed}/>
                <P sx={{mt: 0}}>Применение препарата в особых клинических группах пациенток.</P>
                <P>Препарат разрешен к применению детям с 12 лет или с момента наступления менархе.</P>
                <P sx={{mb: 0}}>Эффективность препарата была продемонстрирована при лечении эндометриозассоциированной
                  тазовой боли у подростков (12–18 лет) с общей благоприятной безопасностью и переносимостью.
                </P>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>

          <WrapperShell sx={{backgroundImage: `url(${images.mainBottom})`, height: 500}}/>
        </Div>
      </Page>
      <ProductModal product="alvovizan/modal" modal={modal} onClose={handleClosePopup}/>
    </>
  )
}
