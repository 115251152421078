import {styled} from '@mui/system';
import {Box} from '@mui/material';
import {PinkLight} from '../../../../styles/color';

export const StyledWrapperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  
  font-size: 18px;
  font-family: 'SF Pro Text', sans-serif;
  font-weight: 400;
  color: #FFFFFF;

  & .title {
    margin-top: 14px;
    margin-bottom: 18px;
    font-weight: 700;
    font-size: 24px;
    text-align: left;
    color: ${PinkLight}
  }
  
  & .container {
    display: flex;
    gap: 20px;
    
    &__box {
      display: flex;
      gap: 20px;
    }
  }
`