import {styled} from '@mui/system';
import { PinkLight} from '../../../../styles/color';


export const Wrapper = styled('div')`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1px;
  margin-bottom: 200px;

  & .pink {
    color: ${PinkLight};
  }

  & .white {
    color: #FFF;
  }
  
  & .header {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  
  & .card {
    max-width: 300px;
    margin-top: 30px;
  }
`;


export const StyledCardHeader = styled('div')`
  border: 1px solid #F9D2D6;
  border-radius: 12px;
  background-color: #f9d2d633;
  color: #FFF;
  line-height: 28px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  gap: 20px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  
  & .head-circle {
    background-color: #f9d2d633;
  }
`

