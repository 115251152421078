import {useHistory} from 'react-router-dom';

import {WrapperShell} from 'components/wrapper-shell';
import {WrapperCouple} from 'components/wrapper-couple';
import Page from 'shared/ui/Page';
import {List as UL, ListItem as LI, Tables} from 'shared/ui';
import {PinkLight} from 'styles/color';

import {StyledH2 as H2, StyledH1 as H1, StyledParagraph as P, LinkA as A} from './ui';
import {Div, Span} from '../ui';
import pdf from '../../../pdfs/vidora/Видора_микро_ИМП.pdf';

export const VidoraMicroInstruction = () => {
  const history = useHistory();

  const handlerRedirectToDiecyclen = () => {
    history.push('/product/vidora');
  }

  return (
    <Page onClose={handlerRedirectToDiecyclen} isCross>
      <Div sx={{textAlign: 'center'}}>
        <H2 sx={{fontSize: 20, mb: 3}}>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</H2>
        <H1>ИНСТРУКЦИЯ</H1>
        <H2 sx={{fontSize: 20, textTransform: 'none', mb: 3, mt: 2}}>по применению лекарственного препарата для
          медицинского применения</H2>
        <H1 sx={{marginBottom: 20}}>Видора® Микро</H1>
      </Div>
      <H2 sx={{fontSize: 20}}>РЕГИСТРАЦИОННЫЙ НОМЕР: ЛП-002881</H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ТОРГОВОЕ НАИМЕНОВАНИЕ: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}> Видора® Микро</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        МЕЖДУНАРОДНОЕ НЕПАТЕНТОВАННОЕ НАИМЕНОВАНИЕ: <Span
        sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>дроспиренон + этинилэстрадиол</Span>
      </H2>
      <H2 sx={{fontSize: 20, mt: 0}}>
        ЛЕКАРСТВЕННАЯ ФОРМА: <Span sx={{color: '#FFF', fontWeight: 400, textTransform: 'none'}}>
        таблетки, покрытие пленочной оболочкой
      </Span>
      </H2>

      <H2 style={{marginTop: 50}}>СОДЕРЖАНИЕ</H2>
      <WrapperCouple sx={{display: 'flex', justifyContent: 'space-between'}}>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#compound'}>СОСТАВ</A>
          <A href={'#description'}>ОПИСАНИЕ</A>
          <A href={'#farm-group'}>ФАРМАКОТЕРАПЕВТИЧЕСКАЯ ГРУППА</A>
          <A href={'#farm-property'}>ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА</A>
          <A href={'#ind-for-use'}>ПОКАЗАНИЯ К ПРИМЕНЕНИЮ</A>
          <A href={'#dication'}>ПРОТИВОПОКАЗАНИЯ</A>
          <A href={'#warning'}>С ОСТОРОЖНОСТЬЮ</A>
          <A href={'#for-use-feed'}>ПРИМЕНЕНИЕ В ПЕРИОД БЕРЕМЕННОСТИ И ГРУДНОГО<br/> ВСКАРМЛИВАНИЯ</A>
          <A href={'#dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</A>
          <A href={'#effect-action'}>ПОБОЧНОЕ ДЕЙСТВИЕ</A>
          <A href={'#over-dozing'}>ПЕРЕДОЗИРОВКА</A>
        </WrapperShell>
        <WrapperShell sx={{flexDirection: 'column', boxShadow: 'none'}}>
          <A href={'#another-medicines'}>ВЗАИМОДЕЙСТВИЕ С ДРУГИМИ ЛЕКАРСТВЕННЫМИ <br/> СРЕДСТВАМИ</A>
          <A href={'#special-instructions'}>ОСОБЫЕ УКАЗАНИЯ</A>
          <A href={'#managment-transport'}>ВЛИЯНИЕ НА СПОСОБНОСТЬ УПРАВЛЕНИЯ <br/>ТРАНСПОРТНЫМИ СРЕДСТВАМИ,
            МЕХАНИЗМАМИ</A>
          <A href={'#form-production'}>ФОРМА ВЫПУСКА</A>
          <A href={'#keeper'}>УСЛОВИЯ ХРАНЕНИЯ</A>
          <A href={'#deadline'}>СРОК ГОДНОСТИ</A>
          <A href={'#holiday'}>УСЛОВИЯ ОТПУСКА</A>
          <A href={'#owner'}>ВЛАДЕЛЕЦ (ДЕРЖАТЕЛЬ) РЕГИСТРАЦИОННОГО<br/>УДОСТОВЕРЕНИЯ</A>
          <A href={'#producer'}>ПРОИЗВОДИТЕЛЬ</A>
        </WrapperShell>
      </WrapperCouple>

      <H2 id={'compound'}>СОСТАВ</H2>
      <P><b><u>1 таблетка, покрытая пленочной оболочкой, с действующими веществами содержит:</u></b></P>
      <P>Действующее вещества:</P>
      <P>дроспиренон - 3,00 мг, этинилэстрадиол (микронизированный) - 0,02 мг.</P>
      <P><b>Вспомогательные вещества:</b></P>
      <P>
        Лактозы моногидрат - 44,00 мг, крахмал кукурузный прежелатинизированный - 38,40 мг, повидон К-30 - 6,00 мг,
        полисорбат 80 - 0,90 мг, кроскармеллоза натрия - 1,20 мг, магния стеарат - 0,50 мг, пленочная оболочка Опадрай
        II розовый - 2,82 мг.
      </P>
      <P><b>Состав пленочной оболочки:</b></P>
      <P>Поливиниловый спирт частично гидролизованный - 40,000 %; титана диоксид (Е171) - 24,240 %; макрогол 3350 -
        20,200 %; тальк - 14,800 %; краситель железа оксид желтый (Е172) - 0,370 %; краситель железа оксид красный (Е
        172) - 0,360 %; краситель железа оксид черный (Е172) — 0,030 %.</P>
      <P><b><u>1 таблетка плацебо содержит:</u></b></P>
      <P><b>Вспомогательные вещества:</b></P>
      <P>Лактоза безводная - 89,50 мг, повидон К-30 - 10,00 мг, магния стеарат - 0,50 мг, пленочная оболочка Опадрай II
        белый - 4,00 мг.</P>
      <P><b>Состав пленочной оболочки: </b></P>
      <P>поливиниловый спирт - 40,00 %; титана диоксид (Е171) - 25,00 %; макрогол/PEG 3350 - 20,20 %; тальк (Е553Ь) -
        14,80 %.</P>

      <H2 id={'description'}>ОПИСАНИЕ</H2>
      <P><b>Таблетки с действующими веществами:</b></P>
      <P>Круглые двояковыпуклые таблетки, покрытые пленочной оболочкой от светло-розового до розового цвета.</P>
      <P><b>Таблетки плацебо:</b></P>
      <P>Круглые двояковыпуклые таблетки, покрытые пленочной оболочкой белого цвета.</P>

      <H2 id={'farm-group'}>Фармакотерапевтическая группа: </H2>
      <P>Контрацептивное средство комбинированное (эстроген+гестаген).</P>
      <P><b>Код ATX</b>: G03AA12</P>

      <H2 id={'farm-property'}>Фармакологические свойства</H2>
      <P><b>Фармакодинамика</b></P>
      <P>
        Препарат Видора® микро - комбинированное гормональное контрацептивное средство с антиминералокортикоидным и
        антиандрогенным действием.
      </P>
      <P>
        Контрацептивный эффект комбинированных пероральных контрацептивных препаратов (КОК) основан на взаимодействии
        различных факторов, наиболее важными из которых являются подавление овуляции и изменения в эндометрии.
      </P>
      <P>
        При правильном применении индекс Перля (число беременностей на 100 женщин в год) составляет менее 1. При
        пропуске таблеток или неправильном применении индекс Перля может возрастать.
      </P>
      <P>
        У женщин, принимающих КОК, менструальный цикл становится более регулярным, реже наблюдаются болезненные
        менструации, уменьшается интенсивность и продолжительность кровотечения, что снижает риск развития анемии.
      </P>
      <P>
        Дроспиренон, содержащийся в препарате Видора® микро, обладает слабым антиминералокортикоидным действием.
        Предупреждает увеличение массы тела и появление отеков, связанных с вызываемой эстрогенами задержкой жидкости,
        что обеспечивает хорошую переносимость препарата. Дроспиренон оказывает положительное воздействие на
        предменструальный синдром (ПМС). Показана клиническая эффективность препарата Видора® микро в облегчении
        симптомов тяжелой формы ПМС, таких как выраженные психоэмоциональные нарушения, нагрубание молочных желез,
        головная боль, боль в мышцах и суставах, увеличение массы тела и другие симптомы, ассоциированные с
        менструальным циклом. Дроспиренон также обладает антиандрогенной активностью и способствует уменьшению симптомов
        акне (угрей), жирности кожи и волос (себореи). Это действие дроспиренона подобно действию естественного
        прогестерона, вырабатываемого организмом. Дроспиренон не обладает андрогенной, эстрогенной, глюкокортикоидной и
        антиглюкокортикоидной активностью. Все это в сочетании с антиминералокортикоидным и антиандрогенным действием
        обеспечивает дроспиренону биохимический и фармакологический профиль, сходный с естественным прогестероном.
      </P>
      <P>В сочетании с этинилэстрадиолом дроспиренон демонстрирует благоприятный эффект на липидный профиль,
        характеризующийся повышением липопротеинов высокой плотности.</P>
      <P><b>Фармакокинетика</b></P>
      <P><b><u>Дроспиренон</u></b></P>
      <P><b>Абсорбция</b></P>
      <P>
        При пероральном приеме дроспиренон быстро и почти полностью абсорбируется. После однократного приёма внутрь
        максимальная концентрация дроспиренона в плазме крови, равная около 38 нг/мл, достигается примерно через 1-2 ч.
        Биодоступность колеблется от 76 до 85%. Прием пищи не влияет на биодоступность дроспиренона.
      </P>
      <P><b>Распределение</b></P>
      <P>
        После перорального приема наблюдается двухфазное снижение концентрации дроспиренона в плазме крови, с периодами
        полувыведения, соответственно, 1,6 ± 0,7 ч и 27,0±7,5 ч. Дроспиренон связывается с альбумином плазмы крови и не
        связывается с глобулином, связывающим половые гормоны (ГСПГ), или кортикостероид-связывающим глобулином. Лишь
        3-5 % от общей концентрации вещества в плазме крови присутствует в качестве свободного гормона. Индуцированное
        этинилэстрадиолом повышение ГСПГ не влияет на связывание дроспиренона белками плазмы. Средний кажущийся объем
        распределения составляет 3,7 ± 1,2 л/кг.
      </P>
      <P><b>Метаболизм</b></P>
      <P>
        После перорального приема дроспиренон экстенсивно метаболизируется. Большинство метаболитов в плазме
        представлены кислотными формами дроспиренона. Дроспиренон также является субстратом для окислительного
        метаболизма, катализируемого изоферментом цитохрома Р450 CYP ЗА4.
      </P>
      <P><b>Выведение</b></P>
      <P>
        Скорость метаболического клиренса дроспиренона в плазме крови составляет 1,5 ± 0,2 мл/мин/кг. В неизмененном
        виде дроспиренон выводится только в следовых количествах.
      </P>
      <P>Метаболиты дроспиренона выводятся через кишечник и почками в соотношении примерно 1,2:1,4. Период полувыведения
        при выведении метаболитов почками и через кишечник составляет примерно 40 ч.</P>
      <P><b>Равновесная концентрация</b></P>
      <P>
        Во время циклового приема максимальная равновесная концентрация дроспиренона в плазме достигается между 7 и 14
        днем приема препарата и составляет приблизительно 70 нг/мл. Отмечалось повышение концентрации дроспиренона в
        плазме крови примерно в 2- 3 раза (за счет кумуляции), что обуславливалось соотношением периода полувыведения в
        терминальной фазе и интервала дозирования.
      </P>
      <P><b><u>Особые группы пациенток</u></b></P>
      <P><b>Влияние почечной недостаточности</b></P>
      <P>Равновесные концентрации дроспиренона в плазме крови у женщин с почечной недостаточностью легкой степени
        тяжести (клиренс креатинина (КК) 60-90 мл/мин) были сравнимы с соответствующими показателями у женщин с
        нормальной функцией почек (КК {'>'} 90 мл/мин). У женщин с почечной недостаточностью средней степени тяжести (КК
        30-59 мл/мин) концентрация дроспиренона в плазме крови была в среднем на 37% выше, чем у женщин с нормальной
        функцией почек. Лечение дроспиреноном хорошо переносилось во всех группах. Прием дроспиренона не оказывал
        клинически значимого влияния на концентрацию калия в плазме крови. Фармакокинетика дроспиренона не изучалась у
        пациенток с почечной недостаточностью тяжелой степени.</P>


      <P><b>Влияние печеночной недостаточности</b></P>
      <P>Дроспиренон хорошо переносится пациентками с печеночной недостаточностью легкой или средней степени тяжести
        (класс В по шкале Чайлд-Пью). Фармакокинетика дроспиренона не изучалась у пациенток с печеночной
        недостаточностью тяжелой степени.</P>


      <P><b><u>Этинилэстрадиол</u></b></P>

      <P><b>Абсорбция</b></P>
      <P><b>Распределение</b></P>
      <P>
        Концентрация этинилэстрадиола в плазме крови снижается двухфазно, терминальная фаза характеризуется периодом
        полувыведения, составляющим приблизительно 24 ч. Этинилэстрадиол в значительной степени, но неспецифически
        связывается с альбумином плазмы крови (примерно 98,5%) и вызывает возрастание концентрации ГСПГ в плазме.
        Кажущийся объем распределения составляет около 5 л/кг.
      </P>
      <P><b>Метаболизм</b></P>
      <P>
        Этинилэстрадиол подвергается значительному первичному метаболизму в кишечнике и печени. Этинилэстрадиол и его
        окислительные метаболиты первично конъюгированы с глюкуронидами или сульфатом. Скорость метаболического клиренса
        этинилэстрадиола составляет около 5 мл/мин/кг.
      </P>
      <P><b>Выведение</b></P>
      <P>
        Этинилэстрадиол практически не выводится в неизмененном виде. Метаболиты этинилэстрадиола выводятся почками и
        через кишечник в соотношении 4:6. Период полувыведения метаболитов составляет примерно 24 ч.
      </P>
      <P><b>Равновесная концентрация</b></P>
      <P>Состояние равновесной концентрации достигается в течение второй половины цикла приема препарата, причем
        концентрация этинилэстрадиола в плазме крови увеличивается примерно в 1,5-2,3 раза.</P>

      <H2 id={'ind-for-use'}>Показания к применению</H2>
      <P>Контрацепция</P>
      <P>Контрацепция и лечение угревой сыпи (acne vulgaris) средней степени тяжести.</P>
      <P>Контрацепция и лечение тяжелой формы предменструального синдрома (ПМС).</P>

      <H2 id={'dication'}>противопоказания</H2>
      <P>
        Прием препарата Видора® микро противопоказан при наличии следующих заболеваний/состояний/факторов риска:
      </P>
      <UL>
        <LI><P>Венозный тромбоз или тромбоэмболия (ВТЭ), в том числе, тромбоз глубоких вен (ТГВ) и тромбоэмболия
          легочной артерии (ТЭЛА), в настоящее время или в анамнезе.</P></LI>
        <LI><P>Артериальный тромбоз или тромбоэмболия (АТЭ) - в настоящее время или в анамнезе, в том числе инфаркт
          миокарда и инсульт; продромальные состояния (транзиторная ишемическая атака, стенокардия).</P></LI>
        <LI><P>Выявленная наследственная или приобретенная предрасположенность к ВТЭ или АТЭ, включая резистентность к
          активному протеину С, гипергомоцистеинемию, дефицит антитромбина III, дефицит протеина С, дефицит протеина S,
          антифосфолипидные антитела (антитела к кардиолипину, волчаночный антикоагулянт).</P></LI>
        <LI><P>Наличие выраженного или множественных факторов риска ВТЭ или АТЭ (см. раздел «Особые указания») или
          наличие одного из таких факторов риска, как: - сахарный диабет с диабетической ангиопатией; - неконтролируемая
          артериальная гипертензия; - тяжелая дислипопротеинемия.</P></LI>
        <LI><P>Объемные оперативные вмешательства с длительной иммобилизацией или обширная травма.</P></LI>
        <LI><P>Печеночная недостаточность, острые или хронические заболевания печени тяжелой степени (до нормализации
          показателей функции печени).</P></LI>
        <LI><P>Наличие опухолей печени (доброкачественные или злокачественные) в настоящее время или в
          анамнезе.</P></LI>
        <LI><P>Наличие гормонозависимых злокачественных заболеваний, в том числе половых органов и молочной железы, или
          подозрение на них.</P></LI>
        <LI><P>Мигрень с очаговой неврологической симптоматикой в настоящее время или в анамнезе.</P></LI>
        <LI><P>Кровотечение из половых путей неясной этиологии.</P></LI>
        <LI><P>Гиперчувствительность к дроспиренону, этинилэстрадиолу или какому-либо из вспомогательных веществ
          препарата.</P></LI>
        <LI><P>Беременность, в том числе предполагаемая.</P></LI>
        <LI><P>Период грудного вскармливания.</P></LI>
        <LI><P>Хроническая почечная недостаточность тяжелой степени или острая почечная недостаточность.</P></LI>
        <LI><P>Совместное применение с противовирусными препаратами прямого действия, содержащими омбитасвир,
          паритапревир, дасабувир или комбинацию этих веществ.</P></LI>
        <LI><P>Непереносимость лактозы, дефицит лактазы, синдром глюкозо-галактозной мальабсорбции (в состав препарата
          входит лактозы моногидрат).</P></LI>
      </UL>
      <P>
        В случае выявления или развития впервые какого-либо из этих заболеваний/состояний или факторов риска на фоне
        применения препарата, прием препарата следует немедленно прекратить.
      </P>

      <H2 id={'warning'}>С осторожностью</H2>
      <P>Если какие-либо из состояний/заболеваний или факторов риска, указанных ниже, имеются в настоящее время, то
        следует тщательно взвешивать потенциальный риск и ожидаемую пользу применения КОК, в том числе и препарата
        Видора® микро, в каждом случае индивидуально:</P>

      <UL>
        <LI><P>
          Факторы риска развития тромбоза и тромбоэмболий: курение; тромбозы, инфаркт миокарда или нарушение мозгового
          кровообращения в возрасте менее 50 лет у кого- либо из ближайших родственников; ожирение; дислипопротеинемия;
          контролируемая артериальная гипертензия; мигрень; неосложненные пороки клапанов сердца; нарушение сердечного
          ритма.
        </P></LI>
        <LI><P>
          Другие заболевания, при которых могут отмечаться нарушения периферического кровообращения: сахарный диабет;
          системная красная волчанка; гемолитико- уремический синдром; болезнь Крона и язвенный колит;
          серповидно-клеточная анемия, а также флебит поверхностных вен.
        </P></LI>
        <LI><P>Наследственный ангионевротический отек.
          Гипертриглицеридемия.</P></LI>
        <LI><P>Заболевания печени легкой и средней степени тяжести в анамнезе при нормальных показателях функциональных
          проб печени.</P></LI>
        <LI><P>Заболевания, впервые возникшие или усугубившиеся во время беременности или на фоне предыдущего приема
          половых гормонов (например, желтуха, холестаз, холелитиаз, </P></LI>
        <LI><P>
          отосклероз с ухудшением слуха, порфирия, гестационный герпес, хорея Сиденгама).
          Послеродовый период.
        </P></LI>
        <LI><P>Эпилепсия, депрессия.</P></LI>
      </UL>

      <H2 id={'for-use-feed'}>Применение в период беременности и грудного вскармливания</H2>
      <P><b>Беременность</b></P>
      <P>
        Прием комбинации дроспиренон+этинилэстраднол в период беременности противопоказан. В случае диагностирования
        беременности на фоне применения препарата Видора® микро, следует немедленно прекратить его прием. Многочисленные
        эпидемиологические исследования не выявили ни увеличения риска дефектов развития у детей, рожденных женщинами,
        получавшими половые гормоны до беременности, ни наличия тератогенного действия, когда половые гормоны
        принимались по неосторожности в ранние сроки беременности.
      </P>
      <P><b>Период грудного вскармливания</b></P>
      <P>Прием препарата Видора® микро, как и других КОК, может уменьшать количество грудного молока и изменять его
        состав, поэтому применение препарата противопоказано до прекращения грудного вскармливания. Небольшое количество
        половых гормонов и/или их метаболитов может проникать в грудное молоко и оказывать влияние на здоровье
        ребенка.</P>

      <H2 id={'dozing'}>СПОСОБ ПРИМЕНЕНИЯ И ДОЗЫ</H2>
      <P>
        Внутрь, целиком, не разжевывая, с небольшим количеством воды, по 1 таблетке в сутки, по порядку, обозначенному
        на блистере, начиная с таблеток, содержащих гормоны (таблетки розового цвета), желательно в одно и тоже время
        суток, непрерывно в течение 28 дней. Для препарата Видора® микро, содержащего 21 таблетку с гормонами и 7
        таблеток плацебо, последовательность приема: 21 день - таблетки с гормонами (таблетки розового цвета), затем 7
        дней - таблетки плацебо (таблетки белого цвета). Для препарата Видора® микро, содержащего 24 таблетки с
        гормонами и 4 таблетки плацебо, последовательность приема: 24 таблетки с гормонами, затем 4 дня - таблетки
        плацебо.
      </P>
      <P>Во время приема таблеток плацебо, не содержащих гормоны (белого цвета), наблюдается менструальноподобное
        кровотечение. Оно обычно начинается на 2-3 день после приема последней таблетки с гормонами и может не
        закончиться до начала приема таблеток из новой упаковки. Прием препарата из каждой последующей упаковки
        начинается без перерыва на следующий день, как только заканчиваются таблетки в предыдущей упаковке.</P>
      <P>Режимы приема препарата Видора® микро, включающего 21 таблетку с гормонами и 7 таблеток плацебо, и препарата
        Видора® микро, включающего 24 таблетки с гормонами и 4 таблетки плацебо, различаются и определяются
        индивидуальными особенностями женщины и длительностью фолликулярной фазы менструального цикла. Нарушение схемы
        приема препарата повышает риск возникновения нежелательной беременности.</P>
      <P>
        При отсутствии приема каких-либо гормональных контрацептивных препаратов в предыдущем месяце приём препарата
        Видора® микро следует начинать в 1-й день менструального цикла (в 1-й день менструального кровотечения).
        Допускается начало приёма на 2-5 день менструального цикла, но в этом случае рекомендуется дополнительно
        использовать барьерный метод контрацепции в течение первых 7 дней приёма таблеток из первой упаковки.
      </P>
      <P>
        <b>Переход с одного режима применения препарата Видора® микро на другой режим</b>
      </P>
      <P>
        Препарат Видора® микро, включающий 21 таблетку с гормонами и 7 таблеток плацебо, и препарат Видора® микро,
        включающий 24 таблетки с гормонами и 4 таблетки плацебо, не являются терапевтически идентичными, так как при их
        приеме различается курсовая (цикловая) доза действующих веществ.
      </P>
      <P>Рекомендации при переходе с одного режима дозирования препарата Видора® микро на другой режим аналогичны
        рекомендациям при переходе с другого КОК на прием препарата Видора® микро и представлены ниже.</P>
      <P>Крайне важно придерживаться схемы приема препарата Видора® микро для обеспечения оптимальных эффективности и
        безопасности.</P>
      <P>Переход с другого комбинированного гормонального контрацептива (КОК, вагинального кольца или трансдермалъного
        пластыря).</P>
      <P>
        Предпочтительно начинать приём препарата на следующий день после приёма последней таблетки с гормонами, но не
        позднее следующего дня после обычного 7-дневного перерыва в приёме (для препаратов, содержащих 21
        таблетку/драже) или после приема последней таблетки плацебо (не содержащей гормоны, для препаратов, содержащих
        28 таблеток/драже в упаковке).
      </P>
      <P>Прием таблеток препарата Видора® микро следует начинать в день удаления вагинального кольца или пластыря, но
        не позднее дня, когда должно быть введено новое кольцо или наклеен новый пластырь.</P>
      <P>
        Переход с гормональных контрацептивов, содержащих только гестагены («мини-пили», инъекционных форм, подкожных
        имплантатов и внутриматочных систем с контролируемым высвобождением гестагена).
      </P>
      <P>
        При переходе с «мини-пили» можно начинать прием препарата в любой день (без перерыва), с имплантатов или
        внутриматочной системы с гестагеном - в день удаления имлантата или внутриматочной системы, с инъекционной формы
        - со дня, когда должна была быть сделана следующая инъекция. Во всех случаях необходимо дополнительно
        использовать барьерный метод контрацепции в течение первых 7 дней приема таблеток препарата Видора® микро.
      </P>
      <P>
        После аборта в I триместре беременности.
      </P>
      <P>Женщина может начинать прием препарата немедленно после самопроизвольного или медицинского аборта в I триместре
        беременности. При соблюдении этого условия женщина не нуждается в дополнительных мерах контрацепции.</P>
      <P>После родов (при отсутствии грудного вскармливания) или прерывания беременности во II триместре.</P>
      <P>Начинать прием препарата Видора® микро следует не ранее 21-28 дня после родов (при отсутствии грудного
        вскармливания) или после прерывания беременности (в том числе самопроизвольного) во II триместре. Если прием
        начат позднее, необходимо дополнительно использовать барьерный метод контрацепции в течение первых 7 дней приема
        таблеток препарата Видора® микро. Однако если половой контакт имел место до начала приема препарата, должна быть
        исключена беременность или следует дождаться первой менструации.</P>
      <P>
        Прекращение приема препарата Видора® микро
      </P>
      <P>Прекратить прием препарата можно в любое время. Если женщина не планирует беременность или женщине
        противопоказана беременность, потому что она принимает потенциально опасные для плода препараты, нужно обсудить
        с врачом другие методы контрацепции.</P>
      <P>Если женщина планирует беременность, рекомендуется прекратить прием препарата и подождать естественного
        менструального кровотечения, и уже потом пытаться забеременеть. Это поможет более точно рассчитать срок
        беременности и время родов.</P>
      <P>Пропуск приема таблеток, не содержащих гормоны (плацебо)</P>
      <P>
        Пропуск таблеток плацебо (белых) можно проигнорировать, никаких мер принимать не требуется. Непринятые таблетки
        необходимо выбросить, чтобы избежать непреднамеренного увеличения продолжительности периода приема таблеток
        плацебо (таблетки белого цвета).
      </P>
      <P>
        Прием пропущенных таблеток, содержащих гормоны
      </P>
      <P>Если пропущен прием таблетки с гормонами (розового цвета) и опоздание в приеме очередной таблетки составило
        менее 12 часов, контрацептивная защита не снижается.</P>
      <P>Пропущенную таблетку следует принять как можно скорее. Далее таблетки следует принимать в обычном режиме.</P>
      <P>
        Если опоздание в приеме очередной таблетки составило более 12 часов (интервал с момента приема последней
        таблетки больше 36 часов), контрацептивная защита может быть снижена. Чем больше таблеток пропущено подряд, и
        чем ближе этот пропуск к 7 дневному перерыву в приеме таблеток с гормонами (розового цвета) препарата, тем выше
        вероятность наступления беременности, поскольку 7 дней непрерывного приема препарата требуются для достижения
        адекватного подавления гипоталамо-гипофизарно-яичниковой системы. Для таких ситуаций могут быть даны следующие
        рекомендации:
      </P>
      <P><b>• На 1 неделе приема препарата</b></P>
      <P>
        Пропущенную таблетку с гормонами (розового цвета) следует принять как можно скорее (как только женщина
        вспомнит), даже если это означает прием двух таблеток одновременно. Далее таблетки следует принимать в обычном
        режиме. Дополнительно должен быть использован барьерный метод контрацепции в течение последующих 7 дней. Если
        половой контакт имел место в течение недели перед пропуском в приеме очередной таблетки, необходимо учитывать
        вероятность наступления беременности.
      </P>
      <P><b>• На 2 неделе приема препарата</b></P>
      <P>Пропущенную таблетку с гормонами (розового цвета) следует принять как можно скорее (как только женщина
        вспомнит), даже если это означает прием двух таблеток одновременно. Далее таблетки следует принимать в обычном
        режиме. Если в течение 7 дней, предшествующих первому пропуску в приеме, все таблетки принимались правильно, нет
        необходимости использовать дополнительные методы контрацепции. В противном случае, а также в случае пропуска
        двух и более таблеток, необходимо использовать дополнительный барьерный метод контрацепции в течение последующих
        7 дней.</P>
      <P><b>• На 3 и 4 неделях приема препарата</b></P>
      <P>В случае пропуска на 3 или 4 неделях приема препарата, необходимо принять последнюю пропущенную таблетку как
        можно скорее (даже если это означает прием 2 таблеток одновременно).</P>
      <P><b>• Нельзя принимать более 2 таблеток с гормонами (таблетки розового цвета) в течение 1 суток!</b></P>
      <P>Далее таблетки следует принимать в обычном режиме, пока не закончатся таблетки, содержащие гормоны, в упаковке.
        Таблетки плацебо (белые) следует выбросить и без перерыва начинать прием таблеток из следующей упаковки
        препарата Видора® микро. Дополнительно, должен быть использован барьерный метод контрацепции в течение следующих
        7 дней.</P>
      <P>Женщина может также прервать прием таблеток, содержащих гормоны (розовых) из текущей упаковки. Затем она должна
        сделать перерыв на 7 дней (для препарата Видора® микро, содержащего 21 таблетку с гормонами) или на 4 дня (для
        препарата Видора® микро с 24 таблетками с гормонами) соответственно, включая в него и дни пропуска таблеток,
        затем начинать прием препарата Видора® микро из новой упаковки.</P>
      <P>Вероятнее всего, кровотечения «отмены» не будет до конца второй упаковки, но могут наблюдаться «мажущие»
        кровянистые выделения или маточное кровотечение «отмены» в дни приема препарата из второй упаковки. Если женщина
        пропускала прием таблеток, содержащих гормоны, и во время приема таблеток плацебо кровотечения «отмены» не
        наступило, необходимо исключить беременность.</P>
      <P><b>Рекомендация при желудочно-кишечных расстройствах</b></P>
      <P>При тяжелых желудочно-кишечных расстройствах всасывание может быть неполным, поэтому следует принять
        дополнительные меры контрацепции.</P>
      <P>Если в течение 4 часов после приема таблетки, содержащей гормоны (розового цвета) произошла рвота или была
        диарея, следует ориентироваться на рекомендации при пропуске таблеток. Если женщина не хочет менять обычную
        схему приема и переносить начало менструальноподобного кровотечения на другой день недели, дополнительную
        таблетку, содержащую гормоны следует принять из другой упаковки.</P>
      <P>Изменение дня менструалъноподобного кровотечения</P>
      <P>
        Чтобы отсрочить наступление менструальноподобного кровотечения, женщине следует продолжить прием таблеток из
        следующей упаковки препарата Видора® микро, пропустив таблетки плацебо (таблетки белого цвета) из текущей
        упаковки. Таким образом, цикл приема препарата может быть продлен, по желанию, на любой срок, пока не закончатся
        таблетки, содержащие гормоны из второй упаковки. На фоне приема препарата из второй упаковки у женщины могут
        отмечаться «мажущие» выделения или «прорывные» маточные кровотечения. Регулярный прием препарата Видора® микро
        возобновляется после окончания приема таблеток плацебо.
      </P>
      <P>Чтобы перенести начало менструальноподобного кровотечения на другой день недели, женщине следует сократить
        длительность приема таблеток плацебо на желаемое количество дней. Чем короче интервал, тем выше риск, что у
        женщины не будет кровотечения «отмены», и, в дальнейшем будут «мажущие» выделения и «прорывные» кровотечения во
        время приема таблеток из второй упаковки.</P>
      <P>Дополнительная информация о применении в особых клинических группах</P>
      <P>Применение у девочек-подростков до 18 лет</P>
      <P>Препарат Видора® микро показан только после наступления менархе. Имеющиеся данные не предполагают коррекции
        дозы у данной группы пациенток.</P>
      <P>У женщин в пожилом возрасте</P>
      <P>Препарат Видора® микро не показан после наступления менопаузы.</P>
      <P>Пациентки с нарушением функции печени</P>
      <P>Препарат Видора® микро противопоказан женщинам с острыми или хроническими заболеваниями печени тяжелой степени,
        печеночной недостаточностью (до нормализации показателей функции печени) (см. также разделы «Противопоказания» и
        «Фармакологические свойства»).</P>
      <P>Пациентки с нарушением функции почек</P>
      <P>
        Препарат Видора® микро противопоказан женщинам с почечной недостаточностью тяжелой степени или с острой почечной
        недостаточностью (см. также разделы «Противопоказания» и «Фармакологические свойства»).</P>
      <P></P>

      <H2 id={'effect-action'}>Побочное действие</H2>
      <P>
        Сообщалось о следующих наиболее распространенных нежелательных реакциях у женщин, применяющих комбинацию
        дроспиренон+этинилэстрадиол по показаниям «Контрацепция» и «Контрацепция и лечение угревой сыпи (acne vulgaris)
        средней степени тяжести»: тошнота, боль в молочной железе, нерегулярные маточные кровотечения, кровотечения из
        половых путей неуточненного генеза. Данные нежелательные реакции встречались более чем у 3% женщин. У пациенток,
        применяющих комбинацию дроспиренон+этинилэстрадиол по показанию «Контрацепция и лечение тяжелой формы
        предменструального синдрома» сообщалось о следующих наиболее распространенных нежелательных реакциях (более чем
        у 10% женщин): тошнота, боль в молочной железе, нерегулярные маточные кровотечения.
      </P>
      <P>Серьезными нежелательными реакциями являются АТЭ (артериальная тромбоэмболия) и ВТЭ (венозная
        тромбоэмболия).</P>
      <P>Нежелательные реакции возможные при применении комбинации дроспиренона и этинилэстрадиола распределены по
        системно-органным классам с указанием частоты их возникновения согласно рекомендациям ВОЗ: часто (≥ 1/100
        и {'<'} 1/10), нечасто (≥ 1/1000 и {'<'} 1/100), редко (≥ 1/10000 и {'<'} 1/1000) и частота неизвестна (частоту
        возникновения на основании имеющихся данных установить невозможно).</P>

      <Tables.WrapperTable>
        <Tables.Table className="align-left" sx={{m: '10px 0', tableLayout: 'fixed', minWidth: 900}}>
          <Tables.Thead>
            <Tables.Tr sx={{color: PinkLight, fontFamily: 'SF-Pro-Text-Bold'}}>
              <Tables.Td sx={{width: 108}}>Системно-органный класс (версия MedDRA: 9.1)</Tables.Td>
              <Tables.Td sx={{width: 108}}>
                Часто (≥ 1/100 - {'<'} 1/10)
              </Tables.Td>
              <Tables.Td sx={{width: 108}}>Нечасто (≥ 1/1000 - {'<'} 1/100)</Tables.Td>
              <Tables.Td sx={{width: 108}}>
                Редко (≥ 1/10000 - {'<'} 1/1000)
              </Tables.Td>
              <Tables.Td sx={{width: 108}}>
                Частота неизвестна
              </Tables.Td>
            </Tables.Tr>
          </Tables.Thead>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Td>Инфекционные и паразитарные заболевания</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Кандидоз</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны крови и лимфатической системы</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Анемия <br/>
                Тромбоцитемия</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны иммунной системы</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Аллергическая реакция</Tables.Td>
              <Tables.Td>Гиперчувствительность</Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны обмена веществ и питания</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Повышение аппетита<br/> Анорексия<br/> Гиперкалиемия<br/>Гипонатриемия</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения психики</Tables.Td>
              <Tables.Td>Эмоциональная лабильность</Tables.Td>
              <Tables.Td>Нервозность<br/> Сонливость<br/> Депрессия</Tables.Td>
              <Tables.Td>Аноргазмия<br/> Бессонница</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны нервной системы</Tables.Td>
              <Tables.Td>Головная боль</Tables.Td>
              <Tables.Td>Головокружение<br/> Парестезия</Tables.Td>
              <Tables.Td>Вертиго<br/> Тремор</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны органов зрения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Конъюнктивит<br/> Сухость слизистой оболочки глаз<br/> Нарушения зрения</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны сердца</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Тахикардия</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны сосудов</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Варикозное расширение вен<br/> Повышение артериального давления<br/> Мигрень</Tables.Td>
              <Tables.Td>Флебит<br/> Носовое кровотечение<br/> Обморок<br/> Венозная<br/> тромбоэмболия
                (ВТЭ)<br/> Артериальная тромбоэмболия
                (АТЭ)</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны желудочно-кишечного тракта</Tables.Td>
              <Tables.Td>Тошнота</Tables.Td>
              <Tables.Td>Боль в животе<br/> Рвота<br/> Диспепсия<br/> Метеоризм <br/>Гастрит<br/> Диарея</Tables.Td>
              <Tables.Td>Вздутие живота<br/> Чувство тяжести в животе<br/> Грыжа пищеводного отверстия диафрагмы <br/>Кандидоз
                полости
                рта <br/>Запор <br/>Сухость слизистой оболочки полости рта <br/>Нарушения со стороны желудочно-
                кишечного
                тракта</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны печени и желчевыводящих путей</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Дискинезия желчевыводя щих путей Холецистит</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны кожи и подкожных тканей</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Акне <br/>Зуд<br/> Сыпь</Tables.Td>
              <Tables.Td>Хлоазма <br/>Экзема <br/>Алопеция <br/>Дерматит угревой <br/>Сухость кожи <br/>Узловатая
                эритема <br/>Гипертрихоз <br/>Стрии
                Контактный дермати<br/>т <br/>Фотодерматит <br/>Кожный узелок
                Нарушения со стороны кожи</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны скелетно-мышечной и соединительной ткани</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Боль в спине <br/>Боль в конечностях <br/>Мышечные судороги</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Нарушения со стороны половых органов и молочной железы</Tables.Td>
              <Tables.Td>Боль в молочной железе Метроррагия* Отсутствие менструальноподобного кровотечения</Tables.Td>
              <Tables.Td>Вагинальный кандидоз<br/> Боль в области малого таза<br/> Увеличение молочных
                желез<br/> Фибрознокистоз ные
                образования в молочной железе <br/>Кровянистые выделения/ кровотечение из половых путей*<br/> Выделения
                из половых
                путей «Приливы» с ощущением жара<br/> Вагинит<br/> Болезненное менструальноподобное
                кровотечение<br/> Скудное
                менструальноподобное кровотечение<br/> Обильное менструальноподобное кровотечение<br/> Сухость слизистой
                оболочки
                влагалища <br/>Патологические результаты теста по Папаниколау</Tables.Td>
              <Tables.Td>Диспареуния <br/>Вульвовагинит<br/> Посткоитальное кровотечение<br/> Кровотечение
                «отмены» <br/>Киста молочной
                железы <br/>Гиперплазия молочной железы <br/>Новообразование в молочной железе
                Полип шейки матки <br/>Атрофия эндометрия <br/>Киста яичника <br/>Увеличение матки</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Общие расстройства и нарушения в месте введения</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Астения <br/>Усиленное потоотделение <br/>Отеки (генерализованная отечность, периферические
                отеки, отек
                лица)</Tables.Td>
              <Tables.Td>Недомогание</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
            <Tables.Tr>
              <Tables.Td>Лабораторные и инструментальные данные</Tables.Td>
              <Tables.Td></Tables.Td>
              <Tables.Td>Увеличение массы тела</Tables.Td>
              <Tables.Td>Снижение массы тела</Tables.Td>
              <Tables.Td></Tables.Td>
            </Tables.Tr>
          </Tables.Body>
        </Tables.Table>
      </Tables.WrapperTable>

      <P>* частота нерегулярных кровотечений уменьшается по мере увеличения длительности приема комбинации
        дроспиренон+этинилэстрадиол.</P>
      <P>Дополнительную информацию о ВТЭ и АТЭ, мигрени, раке молочной железы (РМЖ) и фокальной нодулярной гиперплазии
        печени см. также в разделах «Противопоказания» и «Особые указания».</P>
      <P><b>Ниже перечислены серьезные нежелательные реакции, встречающиеся у женщин на фоне приема КОК:</b></P>
      <P><b>Опухоли</b></P>
      <UL>
        <LI><P>Частота диагностирования РМЖ у женщин, принимающих КОК, несколько повышена. В связи с тем, что РМЖ
          отмечается редко у женщин до 40 лет, увеличение числа диагнозов РМЖ у женщин, принимающих КОК, является
          незначительным по отношению к общему риску этого заболевания.</P></LI>
        <LI><P>Опухоли печени (доброкачественные и злокачественные).</P></LI>
      </UL>
      <P>
        <b>Другие состояния</b>
      </P>
      <UL>
        <LI><P>Гипертриглицеридемия (повышенный риск панкреатита во время приема КОК).</P></LI>
        <LI><P>Повышение артериального давления.</P></LI>
        <LI><P>Состояния, развивающиеся или ухудшающиеся во время приема КОК, но их связь не доказана: желтуха и/или
          зуд, связанный с холестазом; холелитиаз; порфирия; системная красная волчанка; гемолитико-уремический синдром;
          хорея Сиденгама; гестационный герпес; снижение слуха, связанное с отосклерозом.</P></LI>
        <LI><P>У женщин с наследственным ангионевротическим отёком прием эстрогенов может вызывать или усугублять его
          симптомы.</P></LI>
        <LI><P>Нарушения функции печени.</P></LI>
        <LI><P>Болезнь Крона, язвенный колит.</P></LI>
        <LI><P>Хлоазма.</P></LI>
        <LI><P></P></LI>
      </UL>
      <P><b>Взаимодействие</b></P>
      <P>
        Взаимодействие КОК с другими лекарственными препаратами (индукторами ферментов) может привести к «прорывным»
        кровотечениям и/или снижению контрацептивной эффективности (см. раздел «Взаимодействие с другими лекарственными
        средствами»).
      </P>

      <H2 id={'over-dozing'}>Передозировка</H2>
      <P>
        Данные о передозировке препарата Видора® микро отсутствуют.
      </P>
      <P>Симптомы: тошнота, рвота, у девочек-подростков при случайном приеме - кровянистые выделения из влагалища до
        наступления менархе.</P>
      <P>Лечение: специфического антидота нет, следует проводить симптоматическое лечение.</P>

      <H2 id={'another-medicines'}>Взаимодействие с другими лекарственными средствами</H2>
      <P><b><u>Влияние других лекарственных препаратов на препарат Видора® микро</u></b></P>
      <P>Возможно взаимодействие с лекарственными препаратами, индуцирующими микросомальные ферменты, в результате чего
        может увеличиваться клиренс половых гормонов, что, в свою очередь, может приводить к «прорывным» маточным
        кровотечениям и/или снижению контрацептивного эффекта.</P>
      <P>Индукция микросомальных ферментов печени может наблюдаться уже через несколько дней совместного применения.
        Максимальная индукция микросомальных ферментов печени обычно наблюдается в течение нескольких недель. После
        отмены препарата индукция микросомальных ферментов печени может сохраняться в течение 4 недель.
      </P>
      <P><b>Краткосрочная терапия</b></P>
      <P>Женщинам, которые получают лечение такими препаратами в дополнение к препарату Видора® микро, рекомендуется
        использовать барьерный метод контрацепции или выбрать иной негормональный метод контрацепции. Барьерный метод
        контрацепции следует использовать в течение всего периода приема сопутствующих препаратов, а также в течение 28
        дней после их отмены. Если применение препарата-индуктора микросомальных ферментов печени необходимо продолжить
        после того, как закончен прием содержащих гормоны (розовых) таблеток, следует пропустить прием не содержащих
        гормоны (белых) таблеток и начинать прием таблеток препарата Видора® микро из новой упаковки.</P>
      <P><b>Долгосрочная терапия</b></P>
      <P>Женщинам, длительно принимающим препараты ферментов печени, рекомендуется использовать другой надежный
        негормональный метод контрацепции.
      </P>

      <UL>
        <LI><P>
          Вещества, увеличивающие клиренс комбинации дроспиренон+этинилэстрадиол (ослабляющие эффективность путем
          индукции ферментов): фенитоин, барбитураты, примидон, карбамазепин, рифампицин, бозентан, лекарственные
          средства для лечения ВИЧ-инфекции (ритонавир, невирапин и эфавиренз) и, возможно, также окскарбазепин,
          топирамат, фелбамат, гризеофульвин, а также препараты, содержащие зверобой продырявленный.
        </P></LI>
        <LI><P>
          Вещества с различным влиянием на клиренс комбинации дроспиренон+этинилэстрадиол. При совместном применении с
          комбинацией дроспиренон+этинилэстрадиол многие ингибиторы протеаз ВИЧ или вируса гепатита С и ненуклиозидные
          ингибиторы обратной транскриптазы могут как увеличивать, так и уменьшать концентрацию эстрогена или
          прогестагена в плазме крови. В некоторых случаях такое влияние может быть клинически значимо.
        </P></LI>
        <LI><P>
          Вещества, снижающие клиренс КОК (ингибиторы ферментов) Сильные и умеренные ингибиторы CYP3A4, такие как
          азольные антимикотики (например, итраконазол, вориконазол, флуконазол), верапамил, макролиды (например,
          кларитромицин, эритромицин), дилтиазем и грейпфрутовый сок могут повышать плазменные концентрации эстрогена
          или прогестагена, или их обоих. Было показано, что эторикоксиб в дозах 60 и 120 мг/сутки при совместном приеме
          с КОК, содержащими 0,035 мг этинилэстрадиола, повышает концентрацию этинилэстрадиола в плазме крови в 1,4 и
          1,6 раза соответственно.
        </P></LI>
      </UL>

      <P><b><u>Влияние препарата Видора® микро на другие лекарственные препараты</u></b></P>
      <P>
        КОК могут влиять на метаболизм других препаратов, что приводит к повышению (например, циклоспорин) или снижению
        (например, ламотриджин) их концентрации в плазме крови и тканях.
      </P>
      <P>In vitro дроспиренон способен слабо или умеренно ингибировать ферменты цитохрома Р450 CYP1A1, CYP2C9, CYP2C19 и
        CYP3A4.</P>
      <P>
        На основании исследований взаимодействия in vivo у женщин-добровольцев, принимавших омепразол, симвастатин или
        мидазолам в качестве маркерных субстратов, можно заключить, что клинически значимое влияние 3 мг дроспиренона на
        метаболизм лекарственных препаратов, опосредованный ферментами цитохрома Р450, маловероятно. In vitro
        этинилэстрадиол является обратимым ингибитором CYP2C19, CYP1A1 и CYP1A2, а также необратимым ингибитором
        CYP3A4/5, CYP2C8 и CYP2J2. В клинических исследованиях назначение гормонального контрацептива, содержащего
        этинилэстрадиол, не приводило к какому-либо повышению или приводило лишь к слабому повышению концентраций
        субстратов CYP3A4 в плазме крови (например, мидазолама), в то время как плазменные концентрации субстратов
        CYP1A2 могут возрастать слабо (например, теофиллин) или умеренно (например, мелатонин и тизанидин).
      </P>
      <P><b><u>Фармакодинамические взаимодействия</u></b></P>
      <P>
        Было показано, что совместное применение этинилэстрадиолсодержащих препаратов и противовирусных препаратов
        прямого действия, содержащих омбитасвир, паритапревир, дасабувир или их комбинацию, ассоциируется с повышением
        концентрации АЛТ (аланинаминотрансферазы) более чем в 5 раз в сравнении с верхней границей нормы у здоровых и
        инфицированных вирусом гепатита С женщин (см. раздел «Противопоказания»).
        Другие формы взаимодействия
      </P>
      <P><b><u>Другие формы взаимодействия</u></b></P>
      <P>
        У пациенток с ненарушенной функцией почек сочетанное применение дроспиренона и ингибиторов
        ангиотензинпревращающего фермента или нестероидных противовоспалительных препаратов не оказывает значимого
        эффекта на концентрацию калия в плазме крови. Тем не менее, сочетанное применение комбинации
        дроспиренон+этинилэстрадиол с антагонистами альдостерона или калийсберегающими диуретиками не изучено. В таких
        случаях концентрацию калия в плазме крови необходимо контролировать в течение первого цикла приема препарата
        (см. раздел «Особые указания»).
      </P>

      <H2 id={'special-instructions'}>Особые указания</H2>
      <P>Если какие-либо из состояний, заболеваний и факторов риска, указанных ниже, имеются в настоящее время, то
        следует тщательно взвешивать потенциальный риск и ожидаемую пользу применения КОК в каждом индивидуальном случае
        и обсудить его с женщиной до того, как она решит начинать прием препарата. В случае усугубления, усиления или
        первого проявления любого из этих состояний, заболеваний или факторов риска, женщина должна проконсультироваться
        со своим врачом для решения вопроса о прекращении применения препарата.</P>
      <P>Риск развития ВТЭ и АТЭ</P>
      <P>Результаты эпидемиологических исследований указывают на наличие взаимосвязи между применением КОК и повышением
        частоты развития ВТЭ и АТЭ (таких как ТГВ, ТЭЛА, инфаркт миокарда, цереброваскулярные нарушения). Данные
        заболевания отмечаются редко. Повышенный риск присутствует после первоначального применения КОК или
        возобновления применения после перерыва между приемами препарата в 4 недели и более. Данные крупного
        проспективного исследования показывают, что этот повышенный риск присутствует преимущественно в течение первых 3
        месяцев. Лекарственные препараты, в состав которых входит прогестаген левоноргестрел, норгестимат или
        норэтистерон, имеют самый низкий риск развития ВТЭ. Риск ВТЭ при приеме других КОК, включая комбинацию
        дроспиренон+этинилэстрадиол, может в 2 раза превышать риск развития данного осложнения. Выбор в пользу КОК с
        более высоким риском развития ВТЭ может быть сделан только после консультации с женщиной, позволяющей убедиться,
        что она полностью понимает риск ВТЭ, связанный с приемом комбинации дроспиренон+этинилэстрадиол, влияние
        препарата на существующие у нее факторы риска и то, что риск развития ВТЭ максимален в первый год приема таких
        препаратов.</P>
      <P>Общий риск ВТЭ у пациенток, принимающих низкодозированные КОК (
        {'<'}
        0,05 мг этинилэстрадиола), в 2-3 раза выше, чем у небеременных пациенток, которые не принимают КОК, тем не
        менее, этот риск остается более низким по сравнению с риском ВТЭ при беременности и родах.</P>
      <P>ВТЭ может угрожать жизни или привести к летальному исходу (в 1-2 % случаев).</P>
      <P>ВТЭ, проявляющаяся как ТГВ или ТЭЛА, может произойти при применении любых КОК. Крайне редко при применении КОК
        возникает тромбоз других кровеносных сосудов, например, печеночных, брыжеечных, почечных, мозговых вен и артерий
        или сосудов сетчатки глаза.</P>
      <P>Симптомы ТГВ включают следующее: односторонний отек нижней конечности или вдоль вены на нижней конечности, боль
        или дискомфорт в нижней конечности только в вертикальном положении или при ходьбе, локальное повышение
        температуры в пораженной нижней конечности, покраснение или изменение окраски кожных покровов на нижней
        конечности.</P>
      <P>Симптомы ТЭЛА заключаются в следующем: затрудненное или учащенное дыхание, внезапный кашель, в том числе с
        кровохарканием, острая боль в грудной клетке, которая может усиливаться при глубоком вдохе, чувство тревоги,
        сильное головокружение, учащенное или нерегулярное сердцебиение. Некоторые из этих симптомов (например, одышка,
        кашель) являются неспецифическими и могут быть истолкованы неверно как признаки других более часто встречающихся
        и менее тяжелых состояний/заболеваний (например, инфекция дыхательных путей).</P>
      <P>АТЭ может привести к инсульту, окклюзии сосудов или инфаркту миокарда. Симптомы инсульта состоят в следующем:
        внезапная слабость или потеря чувствительности лица, конечностей, особенно с одной стороны тела, внезапная
        спутанность сознания, проблемы с речью и пониманием, внезапная одно- или двухсторонняя потеря зрения, внезапное
        нарушение походки, головокружение, потеря равновесия или координации движений, внезапная, тяжелая или
        продолжительная головная боль без видимой причины, потеря сознания или обморок с приступом судорог или без него.
        Другие признаки окклюзии сосудов: внезапная боль, отечность и незначительная синюшность конечностей, «острый»
        живот.</P>
      <P>Симптомы инфаркта миокарда включают: боль, дискомфорт, давление, тяжесть, чувство сжатия или распирания в
        груди, в руке или за грудиной, дискомфорт с иррадиацией в спину, челюсть, гортань, руку, желудок, холодный пот,
        тошнота, рвота или головокружение, сильная слабость, чувство тревоги или одышка, учащенное или нерегулярное
        сердцебиение.
        АТЭ может угрожать жизни или привести к летальному исходу.</P>
      <P>У женщин с сочетанием нескольких факторов риска или высокой выраженностью одного из них следует рассматривать
        возможность их взаимоусиления. В подобных случаях степень повышения риска может оказаться более высокой, чем при
        простом суммировании факторов. В этом случае прием препарата Видора® микро противопоказан (см. раздел
        «Противопоказания»).</P>
      <P>Риск развития тромбоза (венозного и/или артериального) и тромбоэмболии повышается:</P>
      <P>- с возрастом;</P>
      <P>- у курящих (с увеличением количества сигарет или повышением возраста риск нарастает, особенно у женщин старше
        35 лет);</P>
      <P>- при наличии:</P>
      <UL>
        <LI><P>ожирения (ИМТ более чем 30 кг/м2);</P></LI>
        <LI><P>семейного анамнеза (например, при наличии в семейном анамнезе венозного или артериального
          тромбоза/тромбоэмболии у близких родственников или родителей в возрасте менее 50 лет). В случае наследственной
          или приобретенной предрасположенности женщина должна быть осмотрена соответствующим специалистом для решения
          вопроса о возможности приема КОК;</P></LI>
        <LI><P>в случае длительной иммобилизации, серьезного хирургического вмешательства, любой операции на нижних
          конечностях, в области таза или нейрохирургического оперативного вмешательства, обширной тяжелой травмы. В
          этих ситуациях следует прекратить прием КОК (в случае планируемой операции, по крайней мере, за четыре недели
          до нее) и не возобновлять его в течение двух недель после окончания иммобилизации.</P></LI>
      </UL>
      <P>Временная иммобилизация (например, авиаперелет длительностью более 4 часов) может также являться фактором риска
        развития ВТЭ, особенно при наличии других факторов риска </P>
      <UL>
        <LI><P>дислипопротеинемии;</P></LI>
        <LI><P>артериальной гипертензии;</P></LI>
        <LI><P>мигрени;</P></LI>
        <LI><P>заболеваний клапанов сердца;</P></LI>
        <LI><P>фибрилляции предсердий.</P></LI>
      </UL>
      <P>Примерно у 9-12 из 10 000 женщин, принимающих КОК, содержащие дроспиренон, может развиться ВТЭ в течение года,
        тогда как для КОК, содержащих левоноргестрел, этот показатель составил около 6 из 10 000 женщин.</P>
      <P>Вопрос о возможной роли варикозного расширения вен и поверхностного тромбофлебита в развитии ВТЭ остается
        спорным.
        Следует учитывать повышенный риск развития тромбоэмболий в послеродовом периоде. Нарушения периферического
        кровообращения также могут отмечаться при сахарном диабете, системной красной волчанке, гемолитико-уремическом
        синдроме, хронических воспалительных заболеваниях кишечника (болезнь Крона или язвенный колит) и серповидно-
        клеточной анемии.</P>
      <P>Увеличение частоты и тяжести мигрени во время применения КОК (что может предшествовать цереброваскулярным
        нарушениям) является основанием для немедленного прекращения приема этих препаратов.</P>
      <P>
        К биохимическим показателям, указывающим на наследственную или приобретенную предрасположенность к венозному или
        артериальному тромбозу, относится следующее: резистентность к активированному белку С, гипергомоцистеинемию,
        дефицит антитромбина III, дефицит протеина С, дефицит протеина S, антифосфолипидные антитела (антитела к
        кардиолипину, волчаночный антикоагулянт). </P>
      <P>При оценке соотношения риска и пользы следует учитывать, что адекватное лечение соответствующего состояния
        может уменьшить связанный с ним риск тромбоза. Также следует учитывать, что риск тромбозов и тромбоэмболий при
        беременности выше, чем при приеме низкодозированных КОК (
        {'<'}
        0,05 мг этинилэстрадиола).</P>
      <P>Опухоли</P>
      <P>Наиболее существенным фактором риска развития рака шейки матки является персистирующая папилломовирусная
        инфекция. Имеются сообщения о некотором повышении риска развития рака шейки матки при длительном применении КОК.
        Однако связь с приемом КОК не доказана. Обсуждается возможность взаимосвязи этих данных со скринингом
        заболеваний шейки матки или с особенностями полового поведения (более редкое применение барьерных методов
        контрацепции).</P>
      <P>Мета-анализ 54 эпидемиологических исследований показал, что имеется несколько повышенный относительный риск
        развития рака молочной железы (РМЖ) диагностированного у женщин, принимающих КОК в настоящее время
        (относительный риск 1,24). Повышенный риск постепенно исчезает в течение 10 лет после прекращения приёма этих
        препаратов. В связи с тем, что РМЖ отмечается редко у женщин до 40 лет, увеличение числа диагнозов РМЖ у женщин,
        принимающих КОК в настоящее время или принимавших недавно, является незначительным по отношению к общему риску
        этого заболевания. Наблюдаемое повышение риска может быть также следствием более ранней диагностики РМЖ у
        женщин, применяющих КОК, биологическим действием их половых гормонов или сочетанием обоих факторов. У женщин,
        когда-либо применявших КОК, выявляются более ранние стадии РМЖ, чем у женщин, никогда их не применявших.</P>
      <P>В редких случаях на фоне применения КОК наблюдалось развитие доброкачественных, а в крайне редких -
        злокачественных опухолей печени, которые в отдельных случаях приводили к угрожающему жизни внутрибрюшному
        кровотечению. В случае появления сильных болей в области живота, увеличения печени или признаков внутрибрюшного
        кровотечения это следует учитывать при проведении дифференциального диагноза. Злокачественные опухоли могут
        оказаться жизнеугрожающими или привести к летальному исходу.
        Другие состояния</P>
      <P>Дроспиренон является антагонистом альдостерона, обладающим калийсберегающими свойствами. В большинстве случаев
        не должно наблюдаться повышения концентрации калия в плазме крови. В клинических исследованиях у некоторых
        пациенток с почечной недостаточностью легкой или средней степени тяжести и сопутствующем приеме калийсберегающих
        препаратов концентрация калия в плазме крови во время приема дроспиренона незначительно повышалась. Поэтому
        необходимо контролировать концентрацию калия в плазме крови в течение первого цикла приема препарата у пациенток
        с почечной недостаточностью и при изначальной концентрации калия на верхней границе нормы, особенно при
        сопутствующем приеме калийсберегающих препаратов.</P>
      <P>У женщин с гипертриглицеридемией (или наличием этого состояния в семейном анамнезе) во время приема КОК
        возможно повышение риска развития панкреатита.</P>
      <P>Несмотря на то, что незначительное повышение артериального давления (АД) было описано у многих женщин,
        принимающих КОК, клинически значимое повышение отмечалось редко. Тем не менее, если на фоне применения КОК
        развивается стойкое клинически значимое повышение АД, прием КОК следует прекратить и начинать лечение
        артериальной гипертензии. Если с помощью гипотензивной терапии достигнуты нормальные значения АД, прием КОК
        может быть продолжен.</P>
      <P>Следующие состояния, как сообщалось, развиваются или ухудшаются как во время беременности, так и при приеме
        КОК, но их связь с приемом КОК не доказана: холестатическая желтуха и/или зуд, связанный с холестазом;
        формирование камней в желчном пузыре; порфирия; системная красная волчанка; гемолитико-уремический синдром;
        хорея Сиденгама; гестационный герпес; потеря слуха, связанная с отосклерозом. Также описаны случаи болезни Крона
        и язвенного колита на фоне применения КОК.</P>
      <P>У женщин с наследственными формами ангионевротического отека экзогенные эстрогены могут вызывать или ухудшать
        симптомы ангионевротического отека.</P>
      <P>Острые или хронические нарушения функции печени могут потребовать отмены КОК до нормализации показателей
        функции печени.
      </P>
      <P>Рецидив холестатической желтухи, развившейся впервые во время предшествующей беременности или предыдущего
        приема половых гормонов, требует прекращения приема КОК.</P>
      <P>Хотя КОК могут оказывать влияние на инсулинорезистентность и толерантность к глюкозе, у пациенток с сахарным
        диабетом, применяющих низкодозированные КОК, как правило, коррекции дозы гипергликемических препаратов не
        требуется. Тем не менее, женщины с сахарным диабетом должны тщательно наблюдаться во время приема КОК.</P>
      <P>Иногда может развиваться хлоазма, особенно у женщин с наличием в анамнезе хлоазмы беременных. Женщины со
        склонностью к хлоазме во время приема КОК должны избегать длительного пребывания на солнце и ультрафиолетового
        облучения.</P>
      <P>Подавленное настроение и депрессия являются известными нежелательными реакциями при использовании гормональных
        контрацептивов. Депрессия может быть серьезной и является известным фактором риска суицидального поведения и
        суицида. Женщинам следует рекомендовать обратится к врачу в случае изменения настроения и появления симптомов
        депрессии, в том числе, вскоре после начала приема препарата Видора® микро.</P>
      <P>Влияние на показатели функции печени:</P>
      <P>При проведении клинических исследований с участием пациенток, получающих курс терапии вирусного гепатита С
        (комбинацию лекарственных препаратов, содержащих омбитасвир, паритапревир, ритонавир, дисабувир в сочетании с
        рибавирином или без) повышение активности АЛТ более чем в 5 раз выше верхней границы нормы было чаще
        зарегистрировано у пациенток, применяющих этинилэстрадиолсодержащие КОК. В случае необходимости проведения курса
        терапии данной комбинацией противовирусных препаратов пациентка, принимающая КОК дроспиренон+этинилэстрадиол,
        должна быть переведена на альтернативные методы контрацепции (негормональные или контрацептивы, содержащие
        только гестаген) до начала лечения. Возобновить прием комбинации дроспиренон+этинилэстрадиол можно не раньше,
        чем через 2 недели после окончания курса терапии противовирусными препаратами.</P>
      <P>Лабораторные тесты</P>
      <P>Прием КОК может влиять на результаты некоторых лабораторных тестов, включая показатели функции печени, почек,
        щитовидной железы, надпочечников, концентрацию транспортных белков в плазме, показатели углеводного обмена,
        параметры свертывания крови и фибринолиза. Изменения обычно не выходят за границы нормальных значений.
        Дроспиренон увеличивает активность ренина плазмы и концентрацию альдостерона, что связано с его
        антиминералокортикоидным эффектом.</P>
      <P>Медицинские осмотры</P>
      <P>Перед началом или возобновлением применения комбинации дроспиренон+этинилэстрадиол необходимо ознакомиться с
        анамнезом жизни, семейным анамнезом женщины, провести тщательное общемедицинское (включая измерение
        артериального давления, частоты сердечных сокращений, определение индекса массы тела) и гинекологическое
        обследование (включая обследование молочных желез и цитологическое исследование эпителия шейки матки), исключить
        беременность. Объем дополнительных исследований и частота контрольных осмотров определяется индивидуально.
        Обычно контрольные обследования следует проводить не реже 1 раза в 6 месяцев.</P>
      <P>Следует предупредить женщину, что КОК не предохраняют от ВИЧ-инфекции (СПИД) и других заболеваний, передающихся
        половым путем!</P>
      <P>Снижение эффективности</P>
      <P>
        Эффективность КОК может быть снижена в следующих случаях: при пропуске таблеток, содержащих гормоны (розового
        цвета), при рвоте и диарее или в результате лекарственного взаимодействия.</P>
      <P>Недостаточный контроль менструального цикла</P>
      <P>На фоне приема КОК могут отмечаться нерегулярные кровотечения («мажущие» кровянистые выделения и/или
        «прорывные» маточные кровотечения), особенно в течение первых месяцев применения. Поэтому оценка любых
        нерегулярных менструальноподобных кровотечений должна проводиться после периода адаптации, составляющего около 3
        циклов приема препарата. Если нерегулярные менструальноподобные кровотечения повторяются или развиваются после
        предшествующих регулярных циклов, следует провести тщательное диагностическое обследование для исключения
        злокачественных новообразований или беременности.</P>
      <P>У некоторых женщин во время перерыва в приеме таблеток, содержащих гормоны может не развиться кровотечение
        «отмены». Если комбинация дроспиренон+этинилэстрадиол принималась согласно рекомендациям, маловероятно, что
        женщина беременна. Тем не менее, при нерегулярном применении препарата и отсутствии двух подряд
        менструальноподобных кровотечений, до продолжения приема препарата должна быть исключена беременность.</P>

      <H2 id={'managment-transport'}>
        Влияние на способность управления транспортными
        средствами, механизмами
      </H2>
      <P>Случаев неблагоприятного влияния на способность к управлению транспортными средствами и механизмами при приеме
        препарата не выявлено.</P>

      <H2 id={'form-production'}>Форма выпуска</H2>
      <P>
        Таблетки, покрытые пленочной оболочкой, 3 мг + 0,02 мг.
      </P>
      <P>По 24 таблетки, содержащей действующие вещества и 4 таблетки плацебо в блистер из ПВХ/ПВДХ/Алюминиевой фольги;
        по 1 или 3 блистера вместе с инструкцией по применению в пачку картонную.</P>
      <P>По 21 таблетке, содержащей действующие вещества и 7 таблеток плацебо в блистер из ПВХ/ПВДХ/Алюминиевой фольги;
        по 1 или 3 блистера вместе с инструкцией по применению в пачку картонную.</P>

      <H2 id={'keeper'}>Условия хранения</H2>
      <P>Хранить при температуре не выше 25 C</P>
      <P>Хранить в недоступном для детей месте.</P>

      <H2 id={'deadline'}>Срок годности</H2>
      <P>3 года.</P>
      <P>Не применять препарат по истечении срока годности.</P>

      <H2 id={'holiday'}>Условия отпуска</H2>
      <P>Отпускают по рецепту.</P>

      <H2 id={'owner'}>Владелец (держатель) Регистрационного удостоверения</H2>
      <P>Эксэлтис Хелскеа С.Л.</P>
      <P>Авенида Миралкампо, 7, Полигоно Индустриаль Миралкампо, 19200 Асукеко де Энарес, Гвадалахара, Испания.</P>

      <H2 id={'producer'}>Производитель</H2>
      <P>
        Лабораторное Леон Фарма С.А., <br/> Кайе Ла Валинья (б/н), Полигоне Индустриаль Наватехера, 24008-Виллаквиламбре
        (Леон, Испания).
      </P>
      <P><b>Организация, принимающая претензии потребителей</b></P>
      <P>
        Представительство компании с ограниченной ответственностью «Эксэлтис Хелскеа, С.Л.» <br/> 119049, г. Москва,
        Якиманский пер., дом 6.<br/> Тел./факс: +7 (495) 648 39 47.</P>

      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Инструкция PDF
      </a>
    </Page>
  )
}
