import {styled} from '@mui/system';
import {Black, PinkLight} from '../../../styles/color';
import {Box, Button} from '@mui/material';

export const WrapperBox = styled(Box)`
  color: #FFFFFF;
  font-size: 18px;
  font-family: 'SF Pro Text', sans-serif;
  position: relative;
  
  & .container {
    display: flex;
    gap: 20px;

    & > * {
      flex: 1;
    }
  }

  & .list {
    padding-left: 30px;

    li {
      margin-bottom: 10px;
    }
  }
  
  & .title {
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    font-weight: 600;
  }

  & .pink {
    color: ${PinkLight};
  }
  
  & .title-tab {
    font-size: 20px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0.1px;
    color: rgba(249, 210, 214, 0.3);
    
    &--active {
      color: ${PinkLight};
    }
  }
`

export const StyledHeader = styled('h1')`
  font-size: 36px;
  line-height: 43px;
  font-weight: 600;
  color: ${PinkLight};
`

export const StyledAnchorLink = styled('a')`
  font-size: 18px;
  text-decoration: underline;
  color: ${PinkLight};
  cursor: pointer;
  position: relative;
  padding-left: 36px;
  
  &.no-border {
    text-decoration: none;
  }
  
  img {
    position: absolute;
    margin-left: -33px;
    margin-top: 1px;
  }
`

export const StyledBtn = styled(Button)`
  padding: 13px 30px;
  border: 1px solid #FFFFFF;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  text-transform: uppercase;
  line-height: 18px;
  font-size: 20px;
  font-family: "SF Pro Text", sans-serif;
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.1px;
  background-color: #FFFFFF;
  color: ${Black};
  position: fixed;
  bottom: 92px;

  &:hover {
    background-color: ${Black};
    color: #FFFFFF;
  }
`