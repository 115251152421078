import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import cn from 'classnames';

import {WrapperCouple} from 'components/wrapper-couple';
import {WrapperShell, WrapperShellBox} from 'components/wrapper-shell';
import {ProductModalType} from 'components/product-modal/types';
import {ProductModal} from 'components/product-modal';
import {Tab, TabsGroup} from 'components/tabs';
import {Img, LinkNavigate, Tables} from 'shared/ui';
import Page from 'shared/ui/Page';
import {bem} from 'shared/bem';
import {Black, PinkLight, Violet} from 'styles/color';

import {Sup, P} from './ui';
import './diecyclen.scss';
import {
  depositphotos,
  quotationMark,
  blackMan,
  whoOrganization,
  girl,
  doctor,
  quotationMarkWhite,
  addNew,
  exclamationMark,
  approve, downArrow,
  upperArrow,
  smilingGirl,
  map, document,
  giveMeMoney,
  approveBold,
  addLight
} from './img';

const b = bem('diecyclen');

export const Diecyclen = () => {
  const history = useHistory()

  const [modal, setModal] = useState<ProductModalType>(null);
  const handleClosePopup = () => {
    setModal(null);
  }

  const handleOpenModal = () => {
    setModal('Материалы');
  }

  const handleRedirectToLink = () => {
    history.push('/product/diecyclen/link')
  }

  const handleRedirectToInstruction = () => {
    history.push('/product/diecyclen/instruction')
  }

  const handleRedirectToProducts = () => {
    history.push('/')
  }

  return (
    <>
      <Page head={'ДИЕЦИКЛЕН'} backText="Продукты" onClose={handleRedirectToProducts}>
        <TabsGroup>
          <Tab>
            <LinkNavigate to="/product/diecyclen/instruction">Инструкция</LinkNavigate>
          </Tab>
          <Tab onClick={handleOpenModal}>Материалы</Tab>
          <Tab>Видео</Tab>
        </TabsGroup>
        <div className={b('topic')}>
          <h2 className={b('head-text')}>
            Социально-ответственные врачи сейчас назначают качественные дженерики
          </h2>
          <p className={b('text-border')}>
            <span className={b('text-white')}>ДИЕЦИКЛЕН</span>
            <span className={cn(b('text-border--medium'), b('text-white'))}>
            Диеногест + Этинилэстрадиол <br/> 2мг + 0,03 мг
          </span>
          </p>
        </div>

        <div className={b('container')}>
          <div className={cn(b('container-medium'), b('bg-light'))}>
            <div className={b('container-medium_cell')}>
              <img src={quotationMark}/>
            </div>
            <div>
              <p className={cn(b('text-large'), b('text_indent-bot15'))}>
                По сравнению с четвертым кварталом 2021 года спад доходов ускорился в 4 раза.
              </p>
              <p className={cn(b('text-large'), b('text_indent-bot20'))}>
                В среднем, согласно Росстату, граждане РФ прожили первые три месяца 2022 года на 36 234 рублей в месяц.
              </p>
              <p className={cn(b('text-medium'))}>— сообщает finanz.ru</p>
            </div>
          </div>
          <div className={b('container-small')}>
            <img className={b('container-small_img-top')} src={depositphotos}/>
          </div>
        </div>

        <div className={b('container')}>
          <div className={b('container-small')}>
            <img src={blackMan}/>
          </div>
          <div className={cn(b('container-medium'), b('bg-light'))}>
            <div>
              <img width={200} src={whoOrganization}/>
              <p className={cn(b('text-large'), b('mx-10'), b('mt-30'), b('text-violet'))}>
                29 Мая 2021 года <br/> World Health Organization <br/> провозгласила:
              </p>
              <p className={cn(b('text-medium'))}>
                Выбор женщин и их права на охрану сексуального и репродуктивного здоровья, включая доступ к
                контрацепции,
                должны уважать независимо от статуса СОVID-19.
              </p>
            </div>
          </div>
        </div>

        <div className={b('container-wrapper')}>
          <div className={b('container-wrapper-inner')}>
            <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
              Многие пациентки не могут позволить себе терапию оригинальным препаратом.
            </p>
            <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
              Назначая оригинальный препарат, доктор в половине случаев не назначает ничего, так как пациентка,
              услышав в аптеке цену, не купит его.
            </p>
            <div
              style={{boxShadow: 'none'}}
              className={cn(b('container-small'), b('container-small_img-mh'), b('visible-mobile'), b('mb-20'))}
            >
              <img src={girl}/>
            </div>
            <p className={cn(b('text-large'), b('text-pink'), b('mb-20'), b('pr-40'))}>
              Сейчас трудно покупать оригинальные КОКи по цене 1000-1500 руб. в месяц при средней зарплате в 36 000
              рублей.
            </p>
            <div
              style={{boxShadow: 'none'}}
              className={cn(b('container-small'), b('container-small_img-mh'), b('visible-mobile'), b('mb-20'))}
            >
              <img src={doctor}/>
            </div>
            <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
              Чтобы пациентки совсем не бросили принимать контрацепцию и не пришли к Вам за направлением на аборт,
              ухудшая эту ужасную статистику на Вашем участке, им нужно предложить снизить затраты, но при этом,
              сохранить
              высокое качество контрацептива.
            </p>
          </div>
          <div className={cn(b('container-wrapper-inner'), b('visible-desktop'))}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
              <div style={{boxShadow: 'none'}} className={cn(b('container-small'), b('container-small_img-mh'))}>
                <img src={girl}/>
              </div>
              <div style={{boxShadow: 'none'}} className={cn(b('container-small'), b('container-small_img-mh'))}>
                <img src={doctor}/>
              </div>
            </div>
          </div>
        </div>

        <div className={b('container')}>
          <div className={cn(b('container-small'), b('fairy'))}>
            <div style={{width: 60, height: 60, marginBottom: 4}} className={b('container-medium_cell')}>
              <img src={quotationMarkWhite}/>
            </div>
            <p style={{maxWidth: 292}} className={cn(b('text-medium'), b('text-white'))}>
              Владимир Путин берет под контроль цены на продукты и лекарства
            </p>
          </div>
          <div className={cn(b('container-medium'), b('bg-light'))}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <h2 className={cn(b('text-large'), b('text-violet'), b('mb-10'))}>
                Доктору в арсенале необходим препарат, который обладал бы двумя характеристиками:
              </h2>
              <div style={{display: 'flex'}}>
              <span style={{marginTop: 12, marginRight: 12}}>
                <img src={addNew}/>
              </span>
                <h2 className={cn(b('text-large'), b('mx-15'))}>
                  <span>Эффективностью оригинального препарата</span>
                </h2>
              </div>
              <div style={{display: 'flex'}}>
              <span style={{marginTop: 12, marginRight: 12}}>
                <img src={addNew}/>
              </span>
                <h2 className={cn(b('text-large'), b('mx-15'))}>
                  <span>Социально-ответственной ценой</span>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div style={{padding: '30px 20px'}} className={b('container-wrapper')}>
          <p onClick={handleRedirectToLink}
             style={{fontSize: 36, lineHeight: '43px', fontFamily: 'SF Pro Bold', cursor: 'pointer'}}
             className={b('text-pink')}>
            Основное достоинство дженериков —
            <br/> относительно низкая стоимость при той же <br/>
            терапевтической эффективности,
            что и у <br/> оригинального препарата<Sup>1</Sup>
          </p>
        </div>

        <WrapperCouple sx={{marginTop: '20px'}}>
          <WrapperShell className="small" sx={{height: 464, minWidth: 412}}>
            <div style={{display: 'flex'}}>
              <span style={{marginTop: 12, marginRight: 12}}>
                <img src={exclamationMark}/>
              </span>
              <div className={cn(b('text-large'), b('mx-15'))}>
                <h2 style={{marginTop: 0}} className={cn(b('text-large'), b('text-pink'), b('mb-10'))}>
                  ДЖЕНЕРИК
                </h2>
                <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
                  Препарат, который является терапевтическим эквивалентом бренда.
                </p>
                <p className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
                  Выпускается только после истечения срока действия патента на оригинальный препарат.
                </p>
              </div>
            </div>
            <div className={cn(b('text-medium'), b('text-white'), b('mb-20'), b('pr-40'))}>
              <div style={{marginTop: 0}} className={cn(b('mb-20'), b('mt-20'))}>
               <span
                 style={{fontSize: 60, fontFamily: 'SF Pro Bold'}}
                 className={b('text-pink')}
               >
              80
            </span>
                <span className={cn(b('text-large'), b('text-pink'))}>% рынка</span> <br/>
              </div>
              лекарственных препаратов в нашей стране составляют <strong>дженерики</strong>
            </div>
          </WrapperShell>

          <WrapperShell sx={{boxShadow: 'none', p: 0, borderRadius: 0, textAlign: 'center'}}>
            <WrapperCouple>
              <WrapperShell
                sx={{boxShadow: 'none', p: 0, borderRadius: 0, display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <WrapperShellBox sx={{
                  backgroundColor: Violet,
                  height: 64,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <P sx={{textTransform: 'uppercase', m: 0}}>Дженерик</P>
                </WrapperShellBox>
                <WrapperShellBox
                  sx={{height: '190px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <div>
                    <Img sx={{m: '0px 0 8px'}} src={approve}/>
                    <P sx={{m: 0}}>НЕТ ЗАТРАТ НА ДОПОЛНИТЕЛЬНЫЕ ИССЛЕДОВАНИЯ</P>
                  </div>
                </WrapperShellBox>
                <WrapperShellBox sx={{height: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <P sx={{m: 0}}>Исследования биоэквивалентности</P>
                </WrapperShellBox>
                <WrapperShellBox sx={{backgroundColor: Violet, p: 1}}>
                  <P sx={{textTransform: 'uppercase', m: 0, mb: '6px'}}>Стоимость</P>
                  <Img src={downArrow}/>
                </WrapperShellBox>
              </WrapperShell>

              <WrapperShell
                sx={{boxShadow: 'none', p: 0, borderRadius: 0, display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <WrapperShellBox sx={{
                  backgroundColor: PinkLight,
                  p: '0 20px',
                  height: 64,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <P className="text-dark" sx={{textTransform: 'uppercase', m: 0, fontWeight: 'bold'}}>Оригинальный
                    препарат</P>
                </WrapperShellBox>
                <WrapperShellBox sx={{height: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <P sx={{m: 0}}>Синтез молекулы</P>
                </WrapperShellBox>
                <WrapperShellBox sx={{
                  height: '90px',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <Img sx={{position: 'absolute', top: '-23px'}} src={addLight}/>
                  <P sx={{m: 0}}>Разработка лекарственной формы</P>
                </WrapperShellBox>
                <WrapperShellBox sx={{
                  height: '90px',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <Img sx={{position: 'absolute', top: '-22px'}} src={addLight}/>
                  <P sx={{m: 0}}>Доклинические и клинические исследования</P>
                </WrapperShellBox>
                <WrapperShellBox sx={{backgroundColor: PinkLight, p: 1}}>
                  <Img src={upperArrow}/>
                  <P className="text-dark"
                     sx={{textTransform: 'uppercase', m: 0, mb: '6px', fontWeight: 'bold'}}>Стоимость</P>
                </WrapperShellBox>
              </WrapperShell>
            </WrapperCouple>
          </WrapperShell>
        </WrapperCouple>

        <div className={b('container')}>
          <div
            style={{display: 'flex', flexDirection: 'column'}}
            className={cn(b('container-medium'), b('bg-light'))}
          >
            <h2 style={{fontSize: 36}} className={cn(b('text-violet'), b('text-large'), b('mb-15'))}>ДИЕЦИКЛЕН</h2>
            <h2 style={{fontSize: 27}} className={b('mb-30')}>
              ИМЕЕТ ТОТ ЖЕ СОСТАВ И ТЕ ЖЕ ПОКАЗАНИЯ, ЧТО И ОРИГИНАЛЬНЫЙ ПРЕПАРАТ
            </h2>

            <h2 className={cn(b('text-violet'), b('mb-10'))} style={{marginBottom: 0}}>СОСТАВ:</h2>
            <p style={{fontFamily: 'SF Pro', fontSize: 20, marginBottom: 20, lineHeight: '26px'}}>
              21 таблетка белого цвета. <br/>
              1 таблетка содержит: диеногест – 2,00 мг, <br/>
              этинилэстрадиол – 0,03 мг. <br/>
              7 таблеток плацебо белого цвета.
            </p>
            <h2 style={{cursor: 'pointer', marginBottom:0}} onClick={handleRedirectToInstruction}
                className={cn(b('text-violet'), b('mb-10'))}>ПОКАЗАНИЯ К
              ПРИМЕНЕНИЮ<Sup>2 </Sup>:</h2>
            <ul style={{fontFamily: 'SF Pro Display Light', fontSize: 20, marginLeft: 25, paddingLeft: 2}}>
              <li style={{fontFamily: 'SF Pro', marginBottom: 6}}>
                Пероральная контрацепция.
              </li>
              <li style={{fontFamily: 'SF Pro', marginBottom: 6}}>
                Лечение акне легкой и средней степени тяжести у женщин, нуждающихся в контрацепции,
                при неэффективности местного лечения.
              </li>
            </ul>
          </div>
          <div className={b('container-small')}>
            <img className={b('container-small_img-top')} src={smilingGirl}/>
          </div>
        </div>

        <div style={{padding: '30px 20px', flexDirection: 'column'}} className={b('container-wrapper')}>
          <h2
            style={{
              fontSize: 36,
              lineHeight: '43px',
              fontFamily: 'SF Pro Display Semibold',
              display: 'flex',
              alignItems: 'center',
              marginTop: 10
            }}
            className={cn(b('text-pink'), b('text-mob'))}>
            <img style={{marginRight: 20}} src={exclamationMark}/>
            ЖАНИН® — ОРИГИНАЛЬНЫЙ ПРЕПАРАТ
          </h2>
          <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', color: 'white'}}>
            <div style={{fontFamily: 'SF Pro Text'}}>
              <h2 style={{fontFamily: 'SF Pro Text'}} className={cn(b('text-pink'), b('mb-10'))}>СОСТАВ</h2>
              <p style={{fontFamily: 'SF Pro Display Light', fontSize: 20, marginBottom: 20, lineHeight: '26px'}}>
                21 таблетка белого цвета. <br/>
                1 таблетка содержит: диеногест – 2,00 мг, <br/>
                этинилэстрадиол – 0,03 мг. <br/>
                7 таблеток плацебо белого цвета.
              </p>
            </div>
            <div style={{color: 'white', width: '50%'}}>
              <h2 style={{fontFamily: 'SF Pro Text'}} className={cn(b('text-pink'), b('mb-10'))}>
                ПОКАЗАНИЯ К ПРИМЕНЕНИЮ:
              </h2>
              <p style={{fontFamily: 'SF Pro Display Light', fontSize: 20, marginBottom: 20}}>
                Контрацепция
              </p>
              <p style={{fontFamily: 'SF Pro Display Light', fontSize: 20, marginBottom: 20}}>
                *Инструкция по медицинскому применению препарата Жанин
              </p>
            </div>
          </div>
        </div>

        <div className={b('container-triple')}>
          <div>
            <img src={map}/>
            <p>
              Компания ZENTIVA обеспечивает доступ на Российский рынок качественных Европейских препаратов и
              контролирует
              цены на них.
            </p>
          </div>
          <div>
            <img src={giveMeMoney}/>
            <p>
              В современных экономических условиях очень важно, чтобы пациентки не отказывались от метода из-за высокой
              стоимости препаратов. А в таких сегментах рынка, как контрацепция и менопауза — это недопустимо, так как
              отказ от метода приведёт к росту гинекологической заболеваемости и абортов.
            </p>
          </div>
          <div>
            <img src={document}/>
            <p>
              Мы подготовили фармако-экономическое обоснование по препаратам с одинаковым составом.
            </p>
            <p style={{marginTop: 20, fontSize: 22}}><strong>Вывод очевиден</strong></p>
          </div>
        </div>

        <div style={{flexDirection: 'column'}} className={b('container-wrapper')}>
          <h2
            style={{
              fontSize: 36,
              lineHeight: '43px',
              fontFamily: 'SF Pro Display Semibold',
              display: 'flex',
              alignItems: 'center',
              marginTop: 10,
              color: '#F9D2D6'
            }}
          >
            Сравнительный анализ стоимости
          </h2>
          <Tables.WrapperTable>
            <Tables.Table className="align-default" sx={{fontSize: 18}}>
              <Tables.Thead>
                <Tables.Tr>
                  <Tables.Td>Состав</Tables.Td>
                  <Tables.Td colSpan={6}>диеногест – 2,00 мг, этинилэстрадиол – 0,03 мг.</Tables.Td>
                </Tables.Tr>
                <Tables.Tr sx={{backgroundColor: PinkLight}}>
                  <Tables.Td sx={{color: Black, width: 250}}>Препарат</Tables.Td>
                  <Tables.Td sx={{backgroundColor: Violet, width: 125}}>Диециклен №21</Tables.Td>
                  <Tables.Td sx={{color: Black, width: 125}}>Силуэт №21</Tables.Td>
                  <Tables.Td sx={{color: Black, width: 125}}>Жанин №21</Tables.Td>
                  <Tables.Td sx={{color: Black, width: 125}}>Диециклен №63</Tables.Td>
                  <Tables.Td sx={{color: Black, width: 125}}>Силуэт №63</Tables.Td>
                  <Tables.Td sx={{color: Black, width: 125}}>Жанин №63</Tables.Td>
                </Tables.Tr>
              </Tables.Thead>
              <Tables.Body>
                <Tables.Tr>
                  <Tables.Td>Средняя стоимость в аптеке, ₽</Tables.Td>
                  <Tables.Td>615</Tables.Td>
                  <Tables.Td>990</Tables.Td>
                  <Tables.Td>1 100</Tables.Td>
                  <Tables.Td>1 700</Tables.Td>
                  <Tables.Td>2 320</Tables.Td>
                  <Tables.Td>2 800</Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>Разница в стоимости / месяц, ₽</Tables.Td>
                  <Tables.Td>0</Tables.Td>
                  <Tables.Td>375</Tables.Td>
                  <Tables.Td>485</Tables.Td>
                  <Tables.Td>0</Tables.Td>
                  <Tables.Td>620</Tables.Td>
                  <Tables.Td>1100</Tables.Td>
                </Tables.Tr>
                <Tables.Tr>
                  <Tables.Td>Разница в стоимости / год — 12 циклов, ₽</Tables.Td>
                  <Tables.Td>0</Tables.Td>
                  <Tables.Td><b>4 500</b></Tables.Td>
                  <Tables.Td><b>5 820</b></Tables.Td>
                  <Tables.Td>0</Tables.Td>
                  <Tables.Td><b>2 480</b></Tables.Td>
                  <Tables.Td><b>4 400</b></Tables.Td>
                </Tables.Tr>
              </Tables.Body>
            </Tables.Table>
          </Tables.WrapperTable>
        </div>

        <div style={{marginBottom: 150}} className={b('container')}>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%', flexGrow: 14}}
               className={cn(b('container-medium'), b('bg-light'))}>
            <h2 style={{fontFamily: 'SF Pro Text'}} className={cn(b('text-violet'), b('mb-30'), b('text-large'))}>
              Назначая Диециклен вместо Жанина в тех же терапевтических нишах, где и оригинальный препарат:
            </h2>
            <ul style={{fontFamily: 'SF Pro', fontSize: 20, marginLeft: 25, paddingLeft: 2}}>
              <li style={{fontFamily: 'SF Pro', marginBottom: 6}}>
                Контрацепция с Диеногестом после аборта
              </li>
              <li style={{fontFamily: 'SF Pro', marginBottom: 6}}>
                Контрацепция для пациенток с эдометриозом, которым нужна контрацепция
              </li>
              <li style={{fontFamily: 'SF Pro', marginBottom: 6}}>
                Контрацепция для пациенток с лёгкой и средней степени тяжести акне, нуждающихся в контрацепции
              </li>
            </ul>
          </div>
          <div style={{padding: 20, flexGrow: 0, width: 412}}
               className={cn(b('container-small-last'), b('box-width'), b('bg-light'))}>
            <img style={{height: 40, width: 40}} src={approveBold}/>
            <p style={{fontSize: 36, lineHeight: '42px', fontFamily: 'SF Pro Text', marginTop: 5, paddingBottom: 0}}
               className={cn(b('mx-15'), b('text-violet'))}>
              Вы обеспечиваете качественной помощью бо́льшее количество пациенток.
            </p>
          </div>
        </div>
      </Page>
      <ProductModal product="diecyclen" modal={modal} onClose={handleClosePopup}/>
    </>
  )
}
