import {bem} from 'shared/bem';

import './styles.scss';
import {
  map, document,
  giveMeMoney,
} from './img';

const b = bem('diecyclen-second');
export const SectionCompany = () => {
  return (
    <div style={{marginTop: 0}} className={b('container-triple')}>
      <div>
        <img src={map}/>
        <p>
          Компания ZENTIVA обеспечивает доступ на Российский рынок качественных Европейских препаратов и
          контролирует
          цены на них.
        </p>
      </div>
      <div>
        <img src={giveMeMoney}/>
        <p>
          В современных экономических условиях очень важно, чтобы пациентки не отказывались от метода из-за высокой
          стоимости препаратов. А в таких сегментах рынка, как контрацепция и менопауза — это недопустимо, так как
          отказ от метода приведёт к росту гинекологической заболеваемости и абортов.
        </p>
      </div>
      <div>
        <img src={document}/>
        <p>
          Мы подготовили фармако-экономическое обоснование по препаратам с одинаковым составом.
        </p>
        <p style={{marginTop: 20, fontSize: 22}}><strong>Вывод очевиден</strong></p>
      </div>
    </div>
  )
}
