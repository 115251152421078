import {styled} from '@mui/system';
import {Black} from 'styles/color';

export const Div = styled('div')({
  width: '100%',
  minHeight: 360,
  backgroundColor: '#2F2F34',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5), -2px -3px 3px rgba(249, 210, 214, 0.2)',
  borderRadius: 12,
  padding: 20,
  boxSizing: 'border-box',

  '@media only screen and (max-width: 600px)': {
    position: 'relative',
    minHeight: 90,
  },

  '.text-dark': {
    color: Black,
  },

  '.image-abroad': {
    '@media only screen and (max-width: 600px)': {
      position: 'static',
      maxWidth: '100%',
    }
  },
})
