import {Box} from '@mui/material';

import {StyledHeader} from '../ui';
import {StyledBoxShadow} from '../../ui';
import {Message} from './message';

export const SubpageVidora = () => {
  return (
    <>
      <StyledHeader>Видоры vs Джес, Ярина</StyledHeader>
      <StyledBoxShadow id="theme1" sx={{mt: 5}}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <Message>
            Ольга Ивановна, добрый день! Медицинский представитель компании Зентива (имя, фамилия). Хочу с вами
            поговорить о пациентках с угревой сыпью, скажите, пожалуйста, обращаются к Вам такие?
          </Message>
          <Message isQuestion={false}>
            Вы знаете, за последнее время ко мне немало обратилось таких пациенток, причем пациентки которые в салоны
            красоты ходят как на работу.
          </Message>

          <Message>
            А сколько за неделю примерно таких пациенток у Вас бывает?
          </Message>
          <Message isQuestion={false}>
            1-2 точно приходит.
          </Message>

          <Message>
            Поделитесь опытом пожалуйста, что в таком случае назначаете?
          </Message>
          <Message isQuestion={false}>
            Ну а что тут можно назначить, только Джес.
          </Message>

          <Message>
            Нравится ли Вам результат назначений, какие отзывы от пациенток?
          </Message>
          <Message isQuestion={false}>
            Знаю что Джес эффективный, вот только нередко сталкиваюсь с тем, что пациентки возвращаются с нулевым
            результатом, т.к. цена Джеса для них недоступна, хотя по салонам ходить у них возможность есть.
          </Message>

          <Message>
            Как раз в таком случае есть альтернатива — Видора микро — европейский препарат с высокой эффективностью и
            при этом по стоимости практически вдвое дешевле чем Джес, я для Вас приготовила аптечную карту, в которой
            указаны цены в крупных аптечных сетях нашего города.
          </Message>
          <Message isQuestion={false}>
            Для меня это решение проблемы, знаете не приятно слышать от пациенток, что я якобы специально назначаю
            дорогой препарат.
          </Message>

          <Message>
            В нашем портфеле есть еще препарат Видора - аналог Ярины, который также в 2 раза доступнее по цене, чем
            оригинальный при такой же высокой эффективности.
            <Box sx={{mt: '12px'}}>
              Назначая Видору микро вместо Джеса, а Видору вместо Ярины Вы назначаете своим пациенткам эффективную
              контрацепцию с дополнительным косметическим эффектом, и таким образом Ваши пациентки экономят более 8 000
              ₽
              в год.
            </Box>
          </Message>
          <Message isQuestion={false}>
            Хорошо, убедили. Лишних денег сейчас ни у кого нет. Буду назначать Видоры.
          </Message>

          <Message>
            В таком случае я оставлю Вам свою визитку, если будут какие либо вопросы по Видорам или другим нашим
            препаратам, то, пожалуйста, звоните-пишите. Увидимся через месяц.
          </Message>
          <Message isQuestion={false}>
            Огромное спасибо, держим связь.
          </Message>
        </Box>
      </StyledBoxShadow>
    </>
  )
}
