import Page from 'shared/ui/Page';

import {Paragraph as P, H1} from './ui';
import {PinkLight} from 'styles/color';
import pdf from '../../../pdfs/alvovizan/Guidelines for diagnosis and treatment of endometriosis Busacca2018.pdf';

export const AlvovizanSecondLinkFour = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Guidelines for diagnosis and treatment of endometriosis</H1>

      <P>
        BACKGROUND
      </P>
      <P>
        In recent years, several scientific societies have
        produced consensus guidelines/documents
        or recommendations for the treatment of
        endometriosis. In Italy, guidelines were produced
        for the treatment of pelvic endometriosis in
        the late ‘90, using the Delphi’ method by the
        collaborative Group of Italian Endometriosis
        Study Group (GISE). Many recommendations/
        guidelines published are similar to each other
        and without any special changes over the years,
        an aspect that indicates the shortage of highquality and innovative recent studies. However,
        the therapeutic scenario has in part changed in
        recent years, also following the introduction of
        new therapeutic diagnostic methodologies or
        molecules.
        Objective of this document is to provide Italian
        gynecologists a useful tool in clinical practice,
        based on updated evidences.
      </P>
      <a
        href={pdf}
        target="_blank"
        style={{
          width: 240,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          border: '1px solid #F9D2D6',
          boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: 12,
          color: '#FFF',
          fontSize: 20,
          margin: '50px 0 100px',
        }}
      >
        Статья PDF
      </a>
    </Page>
  )
}
