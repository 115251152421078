import Page from 'shared/ui/Page';

import {Paragraph as P, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanThirteenLinkFive = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight, mb: 0}}>Sagsveen M et al., Cochrane Database Syst Rev. 2003;(4):CD001297.</H1>
      <P>[ссылка: Sagsveen M et al., Cochrane Database Syst Rev. 2003;(4):CD001297.]</P>
    </Page>
  )
}
