import {Box} from '@mui/material';

import {StyledBoxBorder} from '../../ui';
import {Black, PinkLight} from '../../../../styles/color';

type Props = {
  children: React.ReactNode;
}
export const MarketDivideTitle = ({children}: Props) => {
  return (
    <Box
      sx={
        {
          display: 'flex',
          justifyContent: 'center',
          borderTop: '1px solid',
          borderColor: PinkLight,
          position: 'relative',
          mt: '30px',
          mb: '30px',
        }
      }
    >
      <StyledBoxBorder
        sx={
          {
            borderRadius: '60px',
            textAlign: 'center',
            color: PinkLight,
            fontSize: 30,
            fontWeight: 600,
            padding: '10px 30px',
            width: 'fit-content',
            position: 'absolute',
            top: '-30px',
            backgroundColor: Black,
          }
        }
      >
        {children}
      </StyledBoxBorder>
    </Box>
  )
}
