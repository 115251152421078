import Page from 'shared/ui/Page';

import {Paragraph as P, LinkA as A, H1} from './ui';
import {PinkLight} from 'styles/color';

export const AlvovizanLinkThree = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Long-term use of dienogest in the treatment of painful symptoms in adenomyosis</H1>
      <P><b>Aim:</b> We aimed to investigate the safety and efficacy of dienogest (DNG), a progestational 19-norsteroid,
        administered for 52 weeks in patients with symptomatic adenomyosis.</P>
      <P><b>Methods:</b> A total of 130 patients with adenomyosis received 2 mg of DNG orally each day for 52 weeks. In
        cases of complicated anemia, patients were treated for anemia prior to receiving the medication. Adverse events
        and adverse drug reactions were evaluated. The patients' pain symptoms (dysmenorrhea and pelvic pain from
        adenomyosis) were assessed using a pain-scoring tool. This was a verbal rating scale comprising a 0-3-point
        pain-severity score measuring disability to work, and an analgesics-usage score measuring need for
        analgesics.</P>
      <P><b>Results:</b> The most common adverse drug reactions included metrorrhagia (96.9%) and hot flush (7.7%).
        However, in most cases, metrorrhagia was tolerable and no clinically significant changes were observed
        concerning the incidence or severity of reactions during the 52-week treatment period. There were no serious
        adverse events. Both the pain-severity score and analgesics-usage score decreased after the start of treatment
        with DNG. The mean ± standard deviation changes from baseline for the pain score were -3.4 ± 1.8 at 24 weeks and
        -3.8 ± 1.5 at 52 weeks, respectively.</P>
      <P><b>Conclusion:</b> The long-term use of DNG was well-tolerated and effective in patients with symptomatic
        adenomyosis.</P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/28737239/">Y.Yosuga, M.Watanabe, A.Having Long-term use of
        dienogest in the treatment of painful symptoms in adenomyosis J. Obstet. Gynaecol. Res. Vol. 43, No. 9:
        1441-1448, September 2017</A>]
      </P>
    </Page>
  )
}
