import doctorMain from './doctor-main.png';
import girlFriday from './girl-friday.png';
import blonde from './blonde.png';
import medArt from './kepa-art.png';
import longHairedGirl from './long-haired-girl.png';
import redHead from './redhead.png';
import schema from './schema.png';
import mark from './mark.png';
import markYellow from './mark-yellow.png';
import plus from './plus.png';
import approve from './approve.png';
import one from './one.png';
import two from './two.png';
import tree from './tree.png';
import group from './group.png';
import vector from './vector.png';
import add from './add.png';
import warnMark from './warn-mark.png';
import selfie from './selfie.png';

export default {
  doctorMain,
  girlFriday,
  medArt,
  blonde,
  longHairedGirl,
  redHead,
  schema,
  mark,
  markYellow,
  plus,
  approve,
  one,
  two,
  tree,
  group,
  vector,
  add,
  warnMark,
  selfie,
}