import Page from 'shared/ui/Page';
import {H1} from '../ui';

import {StyledParagraph as P, Link as A} from './ui';
import {PinkLight} from '../../../styles/color';

export const ProginovaLinkFive = () => {
  return (
    <Page isCross>
      <H1 sx={{color: PinkLight}}>Hormonal replacement treatment improves clinical pregnancy in frozen-thawed embryos transfer cycles: a
        retrospective cohort study</H1>
      <P>This study aimed to assess frozen-thawed embryo transfer (FET) outcomes in natural, hormone replacement
        treatment (HRT) and semi-HRT cycles. This was a retrospective cohort study of 5414 cycles of patients in an
        academic hospital. Patients were grouped as 2216 natural cycles, 1180 semi-HRT cycles, and 2018 HRT cycles.
        Primary outcome measures were implantation rate, clinical pregnancy rate and live birth rate. Other parameters,
        such as peak endometria-thickness, were also analyzed. Patients undergoing FET with HRT obtained higher
        implantation rate and clinical pregnancy rate than patients with natural or semi-HR cycles (29.3% vs. 21.5% vs.
        25.6%, P=0.01, and 48.7% vs. 42.7% vs. 36.1%, P=0.01, respectively). This finding was not changed in patients
        with thin endometrium (≤8 mm). A Subanalysis in patients with HRT showed that the implantation and clinical
        pregnancy rate was higher in patients without ovulation than ovulatory patients (29.8% vs. 16.9%, P
        {'<'} 0.01, and 49.5% vs. 26.3%, P
        {'<'} 0.01, respectively). This study suggests that HRT increases the possibility of pregnancy. Further, our data
        showed that ovulation in HRT cycle has a detrimental effect on pregnancy. Therefore, we recommend that HRT
        should be used in FET cycles, and ovulation of patients should be evaluated during the treatment.
      </P>
      <P>
        [ссылка: <A href="https://pubmed.ncbi.nlm.nih.gov/24349625/">Zheng Y, Li Z, Xiong M, Luo T, Dong X, Huang B, Zhang H, Ai J. Hormonal replacement
        treatment improves clinical pregnancy in frozen-thawed embryos transfer cycles: a retrospective cohort study. Am
        J Transl Res. 2013 Dec 1;6(1):85-90.</A>]
      </P>
    </Page>
  )
}
